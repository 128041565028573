import { ProjectEditorContext } from 'pages/project/ProjectEditor/contexts';
import { ProjectEditorContextProps } from 'pages/project/ProjectEditor/contexts/types';
import React, { ReactNode } from 'react';
import { Route } from 'react-router-dom';

type Props = {
  path: string;
  exact?: boolean;
  children?: ReactNode;
};

const ContextRoute: React.FC<Props & ProjectEditorContextProps> = ({
  children,
  path,
  exact,
  ...rest
}) => {
  return (
    <Route path={path} exact={exact}>
      <ProjectEditorContext.Provider value={rest}>
        {children}
      </ProjectEditorContext.Provider>
    </Route>
  );
};

export default ContextRoute;
