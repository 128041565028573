import { FieldMetadata, FieldOptions } from 'components/form/types';
import { AsyncSource } from 'utils';
import { readAttr } from 'utils/object';

const useAsyncOptions = (metadata?: FieldMetadata) => {
  const options: FieldOptions = readAttr('options', metadata);
  const loading = options instanceof AsyncSource;
  const active = !loading && Array.isArray(options) && options.length;

  return { options, loading, active };
};

export default useAsyncOptions;
