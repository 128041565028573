import React from 'react';

const Check = ({
  size = 1,
  color = 'currentColor',
}: {
  size?: number;
  color?: string;
}) => (
  <svg width={`${size}em`} viewBox="0 0 5.66 4.44">
    <g transform="translate(-69.6 -111)">
      <path
        d="m74.5 111-2.89 2.86-1.19-1.17-0.793 0.777 1.98 1.97 3.68-3.64z"
        fill={color}
        strokeWidth=".265"
      />
    </g>
  </svg>
);

export default Check;
