import React, { useCallback, useMemo, useRef, useState } from 'react';
import { MdCheck as Check, MdInfoOutline as Info } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';

import { useTranslation, useTranslationX } from 'i18n';

import { InfoDialog, Modal, ModalPDFViewer, TableList } from 'components/containers';
import { ColumnConfig, ColumnLeaf } from 'components/containers/TableList/types';
import { Instance, ListType } from 'components/containers/TableList/TableList';
import { ResponseExtras, useFetchWithEnable, useRequest } from 'apis';
import { Profile, RemappedProfile } from 'pages/register/profile';
import { DocumentFile, Eye, PDF, X } from 'components/icons';
import { ConfirmDialog } from 'pages/supervisor/homologacao/project';
import { useProjectEditor } from 'pages/project/ProjectEditor/contexts';
import {
  ProjectRequestStatus,
  projectRequestStatusColors,
  projectRequestTypesColors,
  Solicitation,
} from 'pages/project/types';

import { useAuthState } from 'store/auth';
import { TableAction } from 'components/containers/TableList/TableListItem';
import { getMonthNameShort, getYearShort } from 'utils/calendarUtils';

import { Loader } from 'components/elements';
import { getMasked } from 'utils/inputMask';
import { stripTags } from 'utils/stringUtils';
import { downloadBlob } from 'utils/file';

import styles from './RequestTable.module.scss';

type Props = {
  maxHeight?: number | string;
  hideFields?: string[];
  filter?: any;
  general?: boolean;
};

type TableActionsMap = {
  [key: string]: TableAction[];
};

type FiltersData = {
  fonteRecurso: {
    id: number;
    nome: string;
  }[];
};

const RequestTable: React.FC<Props> = props => {
  const history = useHistory();
  const { state, dispatch } = useProjectEditor();
  const { profile: oldProfile } = useAuthState();
  const profile = useMemo(() => {
    if (oldProfile !== undefined && oldProfile in RemappedProfile) {
      return RemappedProfile[oldProfile];
    }
    return oldProfile;
  }, [oldProfile]);

  const isSupervisor = profile === Profile.SUPERVISOR;

  const { id: paramId } = useParams<{ id: string }>();

  const projetoId = paramId || state.id;

  const { filter, hideFields, maxHeight, general = false } = props;
  const { section } = state;

  const [pdfData, setPdfData] = useState<string | ArrayBuffer | null>(null);
  const [requestSelected, setRequestSelected] = useState<Solicitation>();
  const [showRequestForm, setShowRequestForm] = useState<boolean>(false);
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [showInfoRefuseDialog, setShowInfoRefuseDialog] = useState(false);

  const request = useRequest();
  const table = useRef<Instance>();
  const { tx } = useTranslationX('request', 'project');
  const { t } = useTranslation();

  const isHome = !state.section;

  const actionsArray = useRef<TableActionsMap>();

  const { data: filtersData } = useFetchWithEnable<FiltersData>(
    `/projetos/${projetoId}/projeto/filter-options`,
    !general
  );

  const downloadDocs = useCallback(
    (data: Solicitation) => {
      request<Blob>({
        url: `/projeto/${data.projetoId}/recursos-humanos/${data.id}/documentos`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
        onSuccess: (blob: Blob, extras?: ResponseExtras) => {
          downloadBlob(blob, 'documentos.zip', extras);
        },
        onError: data => console.error(data),
      });
    },
    [request]
  );

  const getOficio = useCallback(
    (data: Solicitation) => {
      request<any>({
        url: `/projeto/${data.projetoId}/execucao/${
          state.section ? state.section : 'servicos'
        }/solicitacao/${data.id}/pdf`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
        onSuccess: (blob: any) => {
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            let base64data = reader.result;
            setPdfData(base64data);
          };
          if (data.estado !== 'REQ') {
            table.current?.reload();
          }
          if (!isSupervisor && data.tipo === 'rh' && data.estado !== 'EXE') {
            setTimeout(() => {
              setShowInfoDialog(true);
            }, 1000);
          }
        },
        onError: data => console.error(data),
      });
    },
    [request, state.section, isSupervisor]
  );

  const getAceite = useCallback(
    (data: Solicitation) => {
      request<any>({
        url: `/projeto/${data.projetoId}/execucao/${
          state.section ? state.section : 'servicos'
        }/solicitacao/${data.id}/pdf`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
        onSuccess: (blob: any) => {
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            let base64data = reader.result;
            setPdfData(base64data);
          };
          if (data.estado !== 'REQ') {
            table.current?.reload();
          }
        },
        onError: data => console.error(data),
      });
    },
    [request, state.section]
  );

  const onRequestRequired = useCallback(
    (data: Solicitation) => {
      request<any>({
        url: `projetos/solicitacao/${data.id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        onSuccess: (res: any) => {
          setRequestSelected({ ...data, notification_type: res.notification_type });
          setShowRequestForm(true);
        },
        onError: res => console.error(res),
      });
    },
    [request]
  );

  const pushToDetails = useCallback(
    ({ id: solicitationId, ...rest }: Solicitation) => {
      if (actionsArray.current) {
        const actions: TableAction[] = actionsArray.current[solicitationId]?.map(
          (action: TableAction) => {
            return {
              color: action.color,
              label: action.label,
              identifier: action.identifier,
            };
          }
        );

        if (actions) {
          history.push(`/dashboard/projetos/solicitacoes/${solicitationId}`, {
            actions,
            section,
            solicitation: {
              id: solicitationId,
              ...rest,
            },
          });
        }
      }
    },
    [history, section]
  );

  const getActions = useCallback(
    (data: Solicitation) => {
      const actions: TableAction[] = [];

      if (data.estado === 'ACC') {
        const exclusiveTypes = ['aceiteMacro'];
        if (exclusiveTypes.includes(data.tipo)) {
          actions.push({
            icon: PDF,
            onClick: () => getAceite(data),
            color: '#333333',
            label: tx('list.actions.download_aceite'),
            identifier: 'getAceite',
          });
        }
      }

      if (data.estado === 'ACC' || data.estado === 'REQ' || data.estado === 'EXE') {
        const exclusionTypes = ['aceiteMacro', 'transferencia'];
        if (data.estado === 'EXE' && profile !== Profile.SUPERVISOR) {
          exclusionTypes.push('alteracao-pt');
        }
        if (!exclusionTypes.includes(data.tipo) && data.tipo !== 'rh') {
          actions.push({
            icon: PDF,
            onClick: () => getOficio(data),
            color: '#333333',
            label: tx('list.actions.download_office'),
            identifier: 'getOficio',
          });
        }

        if (data.tipo === 'transferencia' && profile !== Profile.SUPERVISOR) {
          actions.push({
            icon: PDF,
            onClick: () => getOficio(data),
            color: '#333333',
            label: tx('list.actions.download_office'),
            identifier: 'getOficio',
          });
        }
      }

      if (
        data.tipo === 'rh' &&
        (data.estado === 'ACC' || data.estado === 'REQ' || data.estado === 'EXE')
      ) {
        actions.push({
          icon: DocumentFile,
          onClick: () => {
            downloadDocs(data);
            getOficio(data);
          },
          color: '#333333',
          label: tx('list.actions.download_docs'),
          identifier: 'getOficio',
        });
      }

      if (!isSupervisor) {
        if (data.estado === 'ANS' || data.estado === 'ACC' || data.estado === 'REQ') {
          actions.push({
            icon: X,
            onClick: () => pushToDetails(data),
            color: '#E61616',
            label: tx('list.actions.cancellation_request'),
            identifier: 'cancelRequest',
          });
        }
      } else if (profile === Profile.SUPERVISOR) {
        if (data.estado === 'ANC') {
          actions.push({
            icon: Check,
            onClick: () => pushToDetails(data),
            color: '#E61616',
            label: tx('list.actions.confirm_cancellation_request'),
            identifier: 'updateRequestAccept',
          });
          actions.push({
            icon: X,
            onClick: () => pushToDetails(data),
            color: '#333333',
            label: tx('list.actions.refuse_cancellation_request'),
            identifier: 'updateRequestRefuse',
          });
        } else if (data.estado === 'ANS' && data.tipo !== 'alteracao-pt') {
          actions.push({
            icon: Check,
            onClick: () => pushToDetails(data),
            color: '#333333',
            label: tx('list.actions.accept_request'),
            identifier: 'updateRequestAccept',
          });
          actions.push({
            icon: X,
            onClick: () => pushToDetails(data),
            color: projectRequestStatusColors.REF,
            label: tx('list.actions.refuse_request'),
            identifier: 'updateRequestRefuse',
          });
        } else if (data.estado === 'REQ' && data.tipo !== 'alteracao-pt') {
          const exclusionTypes = [
            'rh',
            'transferencia',
            'desligamento',
            'ferias',
            'prorrogacao',
          ];
          if (!exclusionTypes.includes(data.tipo)) {
            actions.push({
              icon: Check,
              onClick: () => {
                onRequestRequired(data);
              },
              color: projectRequestStatusColors.EXE,
              label: tx('list.actions.execute_request'),
              identifier: 'onRequestRequired',
            });
          }
        } else if (data.estado === 'ANS' && data.tipo === 'alteracao-pt') {
          const updateWpPath = data.titulo?.includes('Aditivo')
            ? 'aditivo'
            : 'alteracao-pt';

          actions.push({
            icon: Eye,
            onClick: () => {
              history.push(`/dashboard/projetos/${data.projetoId}/${updateWpPath}`);
            },
            color: '#333333',
            label: tx('list.actions.see_changes'),
            identifier: 'seeChanges',
          });
        }
      }
      if (data.estado === 'REF') {
        actions.push({
          icon: Info,
          onClick: () => {
            setRequestSelected(data);
            setShowInfoRefuseDialog(true);
          },
          color: '#333333',
          label: tx('list.actions.refuse_reason'),
          identifier: 'updateRequestAccept',
        });
      }
      return actions;
    },
    [
      isSupervisor,
      profile,
      tx,
      getAceite,
      getOficio,
      downloadDocs,
      pushToDetails,
      onRequestRequired,
      history,
    ]
  );

  let config: ColumnConfig<ListType> = [
    {
      title: tx('list.source'),
      field: 'fonte_nome',
      align: 'left',
      type: 'html',
      capitalize: true,
      search: true,
      weight: isHome ? 0.35 : 0.5,
      parse: (value: string, data) => {
        return isHome
          ? `${value || ''}`
          : `<strong>${value}</strong><br/><p>${data.titulo}</p>`;
      },
      searchType: filtersData ? 'status' : 'text',
      metadata: {
        options: filtersData?.fonteRecurso.map(bolsa => {
          return {
            text: bolsa.nome,
            value: bolsa.nome,
          };
        }),
      },
    },
    {
      title: tx('list.gpf'),
      field: 'gpf',
      weight: 0.3,
      search: true,
    },
    {
      title: tx('list.requestDate'),
      field: 'data',
      type: 'date',
      sortDir: 'desc',
      sort: true,
      weight: 0.51,
      searchType: 'date',
      search: true,
      parse: requestDate => requestDate || tx('fields.none_verbose_alt'),
    },
    {
      title: tx('list.status'),
      field: 'estado',
      type: 'tag',
      weight: 0.4,

      search: true,
      metadata: {
        options: Object.keys(projectRequestStatusColors)
          .filter(status => {
            const isMainHome = isHome && !filter;
            return !isMainHome || status !== 'EXE';
          })
          .map(status => {
            return {
              value: status,
              text: tx(`status.${status}`),
            };
          }),
      },
      searchType: 'status',
      parse: status => {
        return {
          value: tx(`status.${status}`),
          bgColor: projectRequestStatusColors[status as ProjectRequestStatus],
        };
      },
    },
    {
      title: tx('list.officeNumber'),
      field: 'numOficio',
      weight: 0.51,
      search: true,
      parse: numOficio => numOficio || tx('fields.none_verbose_alt'),
    },
    {
      title: tx('list.officeDate'),
      field: 'dataOficio',
      weight: 0.45,
      type: 'date',
      searchType: 'date',
      search: true,
      parse: dataOficio => dataOficio || tx('fields.none_verbose_alt'),
    },
    {
      title: tx('list.value'),
      field: 'valor',
      align: 'right',
      fieldProps: {
        align: 'right',
      },
      weight: 0.3,
      type: 'html',
      searchType: 'range',
      search: true,
      parse: value =>
        value
          ? `<strong>${t('currency')}  ${getMasked(value.toFixed(2), {
              pattern: 'currency',
            })}</strong>`
          : '',
    },
    {
      title: isSupervisor ? tx('list.supervisorAction') : tx('list.coordinatorAction'),
      weight: 0.6,
      align: 'left',
      field: 'coordinatorAction',
      type: 'actions',
      sort: false,

      parse(_, data) {
        const actions = getActions(data);
        actionsArray.current = {
          ...actionsArray.current,
          [data.id]: actions,
        };
        return actionsArray.current[data.id];
      },
    },
  ];

  if (isHome) {
    config.unshift({
      title: tx('list.requestType'),
      field: 'tipo',
      align: 'left',
      type: 'html',
      capitalize: true,
      search: true,
      weight: 0.51,
      metadata: {
        options: Object.keys(projectRequestTypesColors).map(status => {
          return {
            value: status,
            text: tx(`type.${status}`),
          };
        }),
      },
      searchType: 'status',
      parse: (
        value: string,
        data: {
          titulo?: string;
          tipo: string;
          aditivo?: boolean;
          anoPagamento?: number;
          mesPagamento?: number;
        }
      ) => {
        const isUpdateWp =
          data.titulo?.includes('Aditivo') || data.titulo?.includes('Alteração');

        if (isUpdateWp) {
          return `<strong>${data.aditivo ? tx('aditivo') : tx('updateWp')}</strong>`;
        }

        if (data.tipo === 'pagamento') {
          return `<strong>${tx(`type.${value}`)}</strong><br/><p>${getMonthNameShort(
            data.mesPagamento ? data.mesPagamento - 1 : 0
          )}/${getYearShort(data.anoPagamento ?? 2000)}</p>`;
        }

        return `<strong>${tx(`type.${value}`)}</strong><br/><p>${data.titulo}</p>`;
      },
    });
  }

  if (hideFields) {
    let newConfig: ColumnConfig<ListType> = [];
    config.forEach((leaf: ColumnLeaf<ListType>) => {
      if (leaf.field && !hideFields?.includes(leaf.field)) {
        newConfig.push(leaf);
      }
    });
    config = newConfig;
  }

  if (!filtersData && !general) {
    return <Loader size={60} />;
  }

  return (
    <>
      <Modal
        visible={pdfData !== null}
        width="auto"
        onBlur={() => setPdfData(null)}
        onClose={() => setPdfData(null)}
      >
        <div className={styles.popup}>
          <ModalPDFViewer pdfData={pdfData} />
        </div>
      </Modal>
      <InfoDialog
        visible={showInfoDialog}
        onClose={() => setShowInfoDialog(false)}
        title={tx('list.solicitationWarning')}
        width={550}
        buttonLabel={tx('list.ok')}
        volatile
      />
      <InfoDialog
        visible={showInfoRefuseDialog}
        onClose={() => setShowInfoRefuseDialog(false)}
        title={stripTags(requestSelected?.motivoRejeicao ?? '')}
        width={550}
        buttonLabel={tx('list.ok')}
        volatile
      />

      {showRequestForm && (
        <ConfirmDialog
          data={{
            id: requestSelected?.id || 0,
            type: String(requestSelected?.notification_type),
            granted: true,
            title: 'Confirmar execução de solicitação',
            createdAt: new Date(),
            read: false,
            state: requestSelected?.estado,
          }}
          needFile={false}
          accept={true}
          execution={true}
          needValorExecutado={
            String(requestSelected?.tipo).indexOf('rh') === -1 &&
            String(requestSelected?.tipo).indexOf('transferencia') === -1 &&
            String(requestSelected?.tipo).indexOf('prorrogacao') === -1 &&
            String(requestSelected?.tipo).indexOf('desligamento') === -1 &&
            String(requestSelected?.tipo).indexOf('ferias') === -1 &&
            String(requestSelected?.tipo).indexOf('alteracao-pt') === -1 &&
            String(requestSelected?.tipo).indexOf('aceiteMacro') === -1
          }
          valorExecutado={Number(requestSelected?.valor)}
          onClose={(processed?: boolean) => {
            if (processed) {
              table.current?.reload();
              dispatch({
                type: 'SET_UPDATE_COUNT',
                payload: state.updateCount ? state.updateCount + 1 : 1,
              });
            }
            setShowRequestForm(false);
          }}
        />
      )}
      <TableList<Solicitation>
        defaultSearchProps={{
          sort: 'data,desc',
          filters: {},
        }}
        listHeader={{
          title: section ? tx(`section.${section}`) : undefined,
          subtitle: tx('subtitle'),
        }}
        style={{
          root: {
            maxWidth: 'calc(100% - 20px)',
            maxHeight: maxHeight || 'calc(100vh - 150px)',
          },
        }}
        baseUrl={
          isHome
            ? 'projetos/solicitacoes/'
            : `projeto/${projetoId}/execucao/${section}/solicitacoes`
        }
        filterFixed={filter}
        reference={table}
        filter
        config={config}
        textSize="small"
        theme="light"
        editIcon={null}
        editAction={pushToDetails}
        notFoundProps={{}}
      />
    </>
  );
};

export default RequestTable;
