import React, { useCallback, useRef, useState } from 'react';
import _ from 'lodash';

import { useTranslationX } from 'i18n';
import { Conclusion, MilestoneConclusion } from '../../types';
import { useFetch, useRequest } from 'apis';
import { useHistory, useParams } from 'react-router-dom';
import { Milestone } from '../../../types';
import { getDateWithOffset, toSQLTimestamp } from 'utils/calendarUtils';
import { messageService } from 'services';
import { ErrorDialog } from 'components/containers';
import { capitalize } from 'utils/stringUtils';
import { ConclusionNode } from '..';
import { ProgressModal } from './ProgressModal';

import styles from './ConclusionRow.module.scss';

type RowProps = {
  milestoneConclusion: MilestoneConclusion;
  maxMonth?: number;
  offset: number;
  inicioRelativo: Date;
  onUpdateConclusion: () => void;
  meses: string[];
};

const ConclusionRow: React.FC<RowProps> = ({
  milestoneConclusion,
  maxMonth,
  offset,
  inicioRelativo,
  onUpdateConclusion,
  meses,
}) => {
  const { tx, t } = useTranslationX('milestoneConclusionPanel', 'project');
  const { tx: tx2 } = useTranslationX('request', 'project');
  const { tx: tx3 } = useTranslationX('milestoneConclusionPanel', 'project');

  const { nome, conclusoes } = milestoneConclusion;

  const [openProgressModal, setOpenProgressModal] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [currentConclusion, setCurrentConclusion] = useState<number>();
  const [currentConclusionMonth, setCurrentConclusionMonth] = useState<number>();

  const request = useRequest();

  const { id: projectId } = useParams<{ id: string }>();
  const history = useHistory<{ reload: any }>();

  const { data: milestone, reload } = useFetch<Milestone>(
    `/projeto/${projectId}/macroentregas/${milestoneConclusion.id}`
  );

  const milestoneDate = useRef<any>();

  const getConclusionByMonth = (mes: number) => {
    return _.find(conclusoes, { mes });
  };

  const handleClosePopup = () => {
    setError(undefined);
  };

  const handleOpenModal = (conclusion: Conclusion) => {
    if (
      conclusion.estado === 'LAT' ||
      conclusion.estado === 'UPT' ||
      conclusion.estado === 'TUP'
    ) {
      milestoneDate.current = getDateWithOffset(inicioRelativo, conclusion.mes - 1);
      setOpenProgressModal(true);
      setCurrentConclusionMonth(conclusion.mes);
      setCurrentConclusion(conclusion.evolucao);
    }

    if (conclusion.estado === 'PLA') {
      if (
        new Date(milestone?.dataEntrega || '').getMonth() + 1 <=
        new Date().getMonth() + 1
      ) {
        milestoneDate.current = new Date();
        setOpenProgressModal(true);
        setCurrentConclusionMonth(conclusion.mes);

        setCurrentConclusion(conclusion.evolucao);
      }
    }
  };

  const submitConclusion = useCallback(
    (body: object) => {
      const source = request({
        url: `/projeto/${projectId}/macroentregas/${milestoneConclusion.id}/evolucao`,
        method: 'POST',
        data: {
          ...body,
          dataRegistro: toSQLTimestamp(milestoneDate.current),
        },
        onSuccess: () => {
          reload();
          messageService.success(tx2('success.milestoneUpdated'), {
            duration: 7000,
          });
          setOpenProgressModal(false);
          history.replace({
            ...history.location,
            state: {
              ...history.location.state,
              reload: body,
            },
          });
        },
        onError: error => {
          if (error.response?.status === 400) {
            const errorMessage = (
              error.response.data as {
                error?: string;
              }
            )?.error;

            setError(errorMessage || t('milestoneEditor.error.generic'));
          } else {
            setError(t('milestoneEditor.error.generic'));
          }
        },
        onComplete: onUpdateConclusion,
      });

      return () => source.cancel();
    },

    [
      request,
      projectId,
      milestoneConclusion.id,
      onUpdateConclusion,
      reload,
      tx2,
      history,
      t,
    ]
  );

  const renderConclusionNode = (month: number) => {
    const conclusion = getConclusionByMonth(month);
    if (!conclusion) {
      return null;
    }
    return (
      <ConclusionNode
        conclusion={conclusion}
        milestone={milestone}
        handleClick={() => handleOpenModal(conclusion)}
      />
    );
  };

  function closeProgressModal() {
    setOpenProgressModal(false);
  }

  function renderDuration(relativeMonth: number) {
    return {
      label: `${tx3('table.month', { month: relativeMonth })}`,
      value: `${meses[relativeMonth - 1]}`,
    };
  }

  return (
    <>
      <ErrorDialog
        visible={error !== undefined}
        title={tx('error.title')}
        message={error}
        volatile
        onClose={handleClosePopup}
        onBlur={handleClosePopup}
      />
      <tr>
        <td
          style={{
            height: '60px',
          }}
        >
          <div className={styles.container}>
            <strong>{capitalize(nome)}</strong>
          </div>
        </td>
        <td />
        {Array.from(new Array(maxMonth), (_el, index) => (
          <td
            key={index}
            style={{
              fontSize: '10px',
            }}
          >
            {renderConclusionNode(index + offset + 1)}
          </td>
        ))}
        <td />
      </tr>
      <ProgressModal
        visble={openProgressModal}
        onClose={closeProgressModal}
        milestone={milestone as Milestone}
        onSubmit={submitConclusion}
        currentConclusion={{
          value: currentConclusion,
          dates: {
            relativeDate: renderDuration(currentConclusionMonth as number).label,
            realDate: renderDuration(currentConclusionMonth as number).value,
          },
        }}
      />
    </>
  );
};

export default ConclusionRow;
