import React from 'react';

import { useTranslation } from 'i18n';
import { useAuthState } from 'store/auth';
import { MenuItem } from '../types';
import { ScrollPanel } from 'components/containers';
import SubMenu from '../SubMenu/SubMenu';
import ContentSwitcher from '../ContentSwitcher/ContentSwitcher';

import styles from './ConfigPanel.module.scss';

type Props = {
  titleKey: string;
  items: MenuItem[];
};

const ConfigPanel: React.FC<Props> = ({ titleKey, items }) => {
  const state = useAuthState();
  const { t } = useTranslation(state.profile);

  return (
    <div className={styles.configPanel}>
      <SubMenu
        title={t(`menu.${titleKey}.children.title`)}
        child={items.map(({ key, path }) => ({
          text: t(`menu.${titleKey}.children.${key}`),
          path: path || key,
        }))}
      />

      <div className={styles.content}>
        <ScrollPanel>
          <ContentSwitcher items={items} />
        </ScrollPanel>
      </div>
    </div>
  );
};

export default ConfigPanel;
