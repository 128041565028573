import { call } from './index';

type Mask = {
  pattern: string;
  reverse?: boolean;
  regex?: boolean;
};

type ParsedMask = {
  pattern: Pattern;
  options?: Options;
  beforeShow?: Filter;
  beforeSave?: Filter;
};

type Translation = {
  pattern: RegExp;
  optional?: boolean;
  recursive?: boolean;
  fallback?: string;
};

type Pattern = string | ((value: string) => string);
type Options = { reverse?: boolean; translations?: { [key: string]: Translation } };
type Filter = (value: any, mask: (value: string) => string) => any;

const translations: { [key: string]: Translation } = {
  0: { pattern: /\d/ },
  9: { pattern: /\d/, optional: true },
  '#': { pattern: /\d/, recursive: true },
  A: { pattern: /[a-zA-Z0-9]/ },
  S: { pattern: /[a-zA-Z]/ },
};

const masks: { [key: string]: ParsedMask } = {
  account: {
    pattern: (value: string) => {
      const valueReplaced = value.replace('-', '');
      return valueReplaced.length === 4
        ? '0000'
        : valueReplaced.length === 5
        ? '0000-0'
        : '00000-0';
    },
  },
  currency: {
    pattern: '#.##0,00',
    options: { reverse: true },
    beforeShow: (value: any, mask: (value: string) => string) => {
      if (typeof value !== 'number') {
        value = value ? Number(value) : '';
      }

      return typeof value === 'number' ? mask(String(value.toFixed(2))) : null;
    },
    beforeSave: (value: any, mask: (value: string) => string) => {
      switch (typeof value) {
        case 'number':
          return value;

        case 'string':
          value = value.replace(/\D/g, '');

          if (value) {
            value = String(Number(value)); // remove leading zeros
            const size = value.length;

            if (size === 1) {
              value = '0,0' + value;
            } else if (size === 2) {
              value = '0' + value;
            }

            return Number(
              mask(value)
                .replace(/[^0-9,]/g, '')
                .replace(',', '.')
            );
          } else {
            return 0;
          }

        default:
          return 0;
      }
    },
  },
  phone: {
    pattern: (value: string) =>
      value.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009',
  },
};

export function pushCursorToEnd(
  event: React.SyntheticEvent<HTMLInputElement>,
  mask: Mask
) {
  if (!mask.pattern || mask.pattern !== 'currency') {
    return;
  }
  const input = event.currentTarget;
  const inputLength = input.value.length;

  input.setSelectionRange(inputLength, inputLength);
}

export function inputMask(mask: Mask, props: any, value: any) {
  const filters = buildFilters(mask);

  if (filters) {
    const newValue = filters.show(value);
    return props?.onChange
      ? {
          ...props,
          value: newValue !== null && newValue !== undefined ? newValue : undefined,
          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            call(props.onChange, filters.save(event.target.value));
          },
          onClick: () => {
            call(props.onClick);
          },
        }
      : filters;
  }

  return { value, ...props };
}

export function getMasked(value: string, mask: Mask) {
  return apply(value, mask, false);
}

export function clearMask(value: string, mask: Mask) {
  return apply(value, mask, true);
}

export function validateMask(value: string, mask: Mask) {
  if (!value) {
    return true;
  }

  const parsedMask = parse(mask);

  if (!parsedMask) {
    return true;
  }

  let { pattern, options, beforeShow } = parsedMask;

  if (typeof pattern === 'function') {
    pattern = pattern(value);
  }

  if (beforeShow) {
    value = beforeShow(value, val => getMaskedVal(val, pattern, options));
  }

  return getRegexMask(pattern, options).test(value);
}

export function parse(mask: Mask): ParsedMask | undefined {
  if (!mask || !mask.pattern) {
    return;
  }

  const { pattern, ...options } = mask;

  if (mask.regex) {
    return {
      pattern: 'M',
      options: {
        ...options,
        translations: {
          M: { pattern: new RegExp(pattern), recursive: true },
        },
      },
    };
  } else {
    const parsedMask = masks[pattern];

    if (parsedMask) {
      parsedMask.options = { ...options, ...parsedMask.options };
      return parsedMask;
    }

    return { pattern, options };
  }
}

function apply(value: string, mask: Mask, clear: boolean) {
  const parsedMask = parse(mask);

  if (!parsedMask) {
    return value;
  }

  const { pattern, options } = parsedMask;
  return getMaskedVal(value, pattern, options, clear);
}

// https://github.com/igorescobar/jQuery-Mask-Plugin
function getMaskedVal(
  value: string,
  mask: Pattern,
  options: Options = {},
  skipMaskChars?: boolean
) {
  if (typeof mask === 'function') {
    mask = mask(value);
  }

  const buf: string[] = [];
  const maskLen = mask.length;
  const valLen = value.length;

  let offset = 1;
  let add: (value: string) => void;
  let resetPos = -1;

  let lastMaskChar;
  let check;
  let m = 0;
  let v = 0;
  let lastUntranslatedMaskChar;

  if (options.reverse) {
    add = val => buf.unshift(val);
    offset = -1;
    lastMaskChar = 0;
    m = maskLen - 1;
    v = valLen - 1;
    check = () => m > -1 && v > -1;
  } else {
    add = val => buf.push(val);
    lastMaskChar = maskLen - 1;
    check = () => m < maskLen && v < valLen;
  }

  while (check()) {
    const maskDigit = mask.charAt(m);
    const valDigit = value.charAt(v);

    const translation = translate(maskDigit, options);

    if (translation) {
      if (valDigit.match(translation.pattern)) {
        add(valDigit);

        if (translation.recursive) {
          if (resetPos === -1) {
            resetPos = m;
          } else if (m === lastMaskChar && m !== resetPos) {
            m = resetPos - offset;
          }

          if (lastMaskChar === resetPos) {
            m -= offset;
          }
        }

        m += offset;
      } else if (valDigit === lastUntranslatedMaskChar) {
        lastUntranslatedMaskChar = undefined;
      } else if (translation.optional) {
        m += offset;
        v -= offset;
      } else if (translation.fallback) {
        add(translation.fallback);

        m += offset;
        v -= offset;
      }

      v += offset;
    } else {
      if (!skipMaskChars) {
        add(maskDigit);
      }

      if (valDigit === maskDigit) {
        v += offset;
      } else {
        lastUntranslatedMaskChar = maskDigit;
      }

      m += offset;
    }
  }

  const lastMaskCharDigit = mask.charAt(lastMaskChar);

  if (maskLen === valLen + 1 && !translate(lastMaskCharDigit, options)) {
    buf.push(lastMaskCharDigit);
  }
  return buf.join('');
}

// https://github.com/igorescobar/jQuery-Mask-Plugin
function getRegexMask(mask: string, options: Options = {}) {
  const chunks = [];

  let oRecursive;

  for (const ch of mask) {
    const translation = translate(ch, options);

    if (translation) {
      const pattern = translation.pattern.toString().replace(/.{1}$|^.{1}/g, '');
      const optional = translation.optional;
      const recursive = translation.recursive;

      if (recursive) {
        chunks.push(ch);
        oRecursive = { digit: ch, pattern };
      } else {
        chunks.push(optional || recursive ? pattern + '?' : pattern);
      }
    } else {
      chunks.push(ch.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'));
    }
  }

  let r = chunks.join('');

  if (oRecursive) {
    r = r
      .replace(
        new RegExp('(' + oRecursive.digit + '(.*' + oRecursive.digit + ')?)'),
        '($1)?'
      )
      .replace(new RegExp(oRecursive.digit, 'g'), oRecursive.pattern);
  }

  return new RegExp(r);
}

function translate(value: string, options: Options) {
  return options.translations
    ? options.translations[value] || translations[value]
    : translations[value];
}

function buildFilters(mask: Mask) {
  const parsedMask = parse(mask);

  if (parsedMask) {
    const { pattern, options, beforeShow, beforeSave } = parsedMask;

    const parseValue = (val: any, filter?: Filter) =>
      filter
        ? filter(val, val => getMaskedVal(val, pattern, options))
        : getMaskedVal(typeof val === 'string' ? val : String(val), pattern, options);

    return {
      show: (val: any) => parseValue(val, beforeShow),
      save: (val: any) => parseValue(val, beforeSave),
    };
  }
}
