import React, { createRef, useEffect, useRef, useState } from 'react';

import { Card } from 'components/containers';
import { DatePicker, Form, RadioGroup, SubmitButton, TextArea } from 'components/form';

import { Button, Loader } from 'components/elements';
import { FormInstance, Pageable } from 'components/form/types';
import { useTranslationX } from 'i18n';
import { useHistory } from 'react-router-dom';
import { QuestionType } from 'components/containers/Questions/Questions';
import { CancelTokenSource } from 'apis';
import { useRequest } from 'apis';
import { useProjectEditor } from 'pages/project/ProjectEditor/contexts';

import styles from './Termination.module.scss';

const TerminationForm: React.FC = () => {
  const { state } = useProjectEditor();
  const { tx } = useTranslationX('request.form', 'project');
  const [questions, setQuestions] = useState<Pageable<QuestionType>>();
  const history = useHistory();
  const request = useRequest();
  const source = useRef<CancelTokenSource>();

  useEffect(() => {
    source.current = request<Pageable<QuestionType>>({
      url: 'projetos/avaliacao/coordenador',
      onSuccess: setQuestions,
    });

    return () => source.current?.cancel();
  }, [request]);

  if (!questions) {
    return <Loader size={60} />;
  }

  const form = createRef<FormInstance>();

  return (
    <Form
      ref={form}
      baseUrl={`projeto/${state.id}/recursos-humanos/desligamento/${state.contract?.projetoRHId}`}
      onDone={() => history.goBack()}
    >
      <div className={styles.card}>
        <h2>{tx('terminationTitle')}</h2>
        <DatePicker
          theme="classic"
          style={{ root: { maxWidth: 200 } }}
          name="dataDesligamento"
          label={tx('dataDesligamento')}
        />
        <Card width={{ max: '50%' }}>
          <div className={styles.container}>
            {questions.content.map(question => {
              if (question.tipo === 'MULTIPLE_CHOICE') {
                return (
                  <section key={question.id}>
                    <RadioGroup
                      label={question.pergunta}
                      name={'question_' + question.id}
                      columns={1}
                      autosave={false}
                    />
                  </section>
                );
              } else {
                return (
                  <section key={question.id}>
                    <TextArea
                      minRows={3}
                      label={question.pergunta}
                      name={'question_' + question.id}
                    />
                  </section>
                );
              }
            })}

            <div
              style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}
            >
              <Button type="secondary" onClick={() => history.goBack()}>
                {tx('cancel')}
              </Button>
              <SubmitButton style={{ root: { width: 250 } }}>
                {tx('scholarship.submit')}
              </SubmitButton>
            </div>
          </div>
        </Card>
      </div>
    </Form>
  );
};
export default TerminationForm;
