import React, { useContext, useEffect, useState } from 'react';

import Button, { ButtonProps } from 'components/elements/Button/Button';
import { useForm, useFormSection } from '..';
import { FormObserverContext } from '../FormContext';

type Props = ButtonProps & {
  clearStash?: boolean;
  autoDisable?: boolean;
};

const SubmitButton: React.FC<Props> = ({ clearStash, autoDisable, ...rest }) => {
  const [enabled, setEnabled] = useState(!autoDisable);

  const form = useForm();
  const formSection = useFormSection();
  const subscriber = useContext(FormObserverContext);

  useEffect(() => {
    if (autoDisable) {
      const callback = () => {
        form.isValid(setEnabled);
      };

      subscriber.subscribe(callback);
      return () => subscriber.unsubscribe(callback);
    }
  }, [autoDisable, form, subscriber]);

  return (
    <Button
      {...rest}
      type={enabled ? rest.type : 'disabled'}
      onClick={() => {
        form.isValid((valid: boolean) => {
          if (!valid) {
            return;
          }
          if (clearStash) {
            form.stash('clear');
            form.setEditable(false);
          }
        });
        form.submit(formSection.name);
      }}
    />
  );
};

export default SubmitButton;
