import React from 'react';

const CheckBoxFrame = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 18 18">
    <rect
      x="1"
      y="1"
      width="16"
      height="16"
      rx="3"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
    />
  </svg>
);

export default CheckBoxFrame;
