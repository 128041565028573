import React from 'react';

const OthersExpenditure = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">

    <path d="M15 4.5H22.5V6H15V4.5Z" fill="currentColor"/>
    <path d="M15 9H20.25V10.5H15V9Z" fill="currentColor"/>
    <path d="M15 13.5H22.5V15H15V13.5Z" fill="currentColor"/>
    <path d="M15 18H20.25V19.5H15V18Z" fill="currentColor"/>
    <path d="M10.5 3H12V21H10.5V3Z" fill="currentColor"/>
    <path d="M2.88752 16.875L1.83002 15.84L6.54752 11.25L1.83002 6.66L2.88752 5.625L8.67002 11.25L2.88752 16.875Z" fill="currentColor"/>

    <g mask="url(#mask0)">
      <rect width={`${size}em`} fill="currentColor"/>
    </g>
  </svg>
);

export default OthersExpenditure;



