import React from 'react';

const Supervisor = ({ size = 1 }: { size?: number }) => (
  <svg height={`${size}em`} viewBox="0 0 32 50" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.1568 22.4609C22.329 22.4609 27.3504 17.4229 27.3504 11.2305C27.3504 5.03799 22.329 0 16.1568 0C9.9846 0 4.96313 5.03799 4.96313 11.2305C4.96313 17.4229 9.9846 22.4609 16.1568 22.4609Z"
      fill="currentColor"
    />
    <path
      d="M18.5902 25.1793C17.7994 25.3178 16.9865 25.3906 16.1568 25.3906C15.3271 25.3906 14.5141 25.3178 13.7234 25.1793V29.2969H14.5934L13.7685 40.5518L16.1568 42.9479L18.5451 40.5518L17.7203 29.2969H18.5902V25.1793Z"
      fill="currentColor"
    />
    <path
      d="M25.9901 21.3776C24.7077 22.6289 23.1899 23.6381 21.5103 24.3315V29.2969H20.6482L21.5553 41.6748L16.1568 47.0911L10.7582 41.6748L11.6654 29.2969H10.8033V24.3315C9.12376 23.6382 7.6059 22.6289 6.3235 21.3776C2.87946 23.5358 0.583008 27.3728 0.583008 31.7383V50H31.7305V31.7383C31.7305 27.3728 29.4341 23.5358 25.9901 21.3776Z"
      fill="currentColor"
    />
  </svg>
);

export default Supervisor;
