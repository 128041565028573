import React from 'react';

import { classes } from 'utils/components';
import styles from './Loader.module.scss';

type Props = {
  size?: number;
  type?: 'bubbles' | 'spin' | 'grid';
  message?: string;
  color?: string;
  delay?: boolean;
};

const Loader: React.FC<Props> = props => {
  const { size, type = 'bubbles', message, color = 'primary', delay = true } = props;

  return (
    <div
      className={classes(styles.loader, styles[color], message ? styles.message : null)}
    >
      <div className={classes(delay ? styles.delay : null)}>
        {loaders[type](size)}
        <span>{message}</span>
      </div>
    </div>
  );
};

export default Loader;

const loaders = {
  bubbles: (size: number = 32) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={size}
      height={size}
      fill="currentColor"
    >
      {[1, 2, 3].map(i => (
        <circle key={i} transform={`translate(${i * 8} 0)`} cx="0" cy="16" r="0">
          <animate
            attributeName="r"
            values="0; 4; 0; 0"
            dur="1.2s"
            repeatCount="indefinite"
            begin={`${(i - 1) * 0.3}`}
            keyTimes="0;0.2;0.7;1"
            keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
            calcMode="spline"
          />
        </circle>
      ))}
    </svg>
  ),

  spin: (size: number = 32) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={size}
      height={size}
      fill="currentColor"
    >
      <path
        opacity=".25"
        d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4"
      />
      <path d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z">
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 16 16"
          to="360 16 16"
          dur="0.8s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  ),

  grid: (size: number = 68) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 68 68"
      width={size}
      height={size}
      fill="currentColor"
    >
      {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(i => {
        const col = i % 3;
        const row = Math.floor(i / 3);

        return (
          <rect
            key={i}
            rx="2"
            height="20"
            width="20"
            y="-10"
            x="-10"
            transform={`translate(${col * 24 + 10} ${row * 24 + 10})`}
          >
            <animateTransform
              attributeName="transform"
              type="scale"
              values="1; 0; 1; 1"
              dur="1.3s"
              repeatCount="indefinite"
              additive="sum"
              begin={`${(col + 2 - row) / 10}s`}
            />
          </rect>
        );
      })}
    </svg>
  ),
};
