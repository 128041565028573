import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useRequest } from 'apis';
import { useTranslationX } from 'i18n';
import { Loader, WrapperCard } from 'components/elements';
import { Grid, Row, Tab, Tabs } from 'components/containers';

import { FieldSearch, FieldSet, HiddenField, TextField, useForm } from 'components/form';
import { FormObserverResult } from 'components/form/types';
import { FormObserverContext } from 'components/form/FormContext';
import { ProposalTabError } from '../ProposalEditor/types';
import { Info } from 'components/icons';

import styles from './CompanyList.module.scss';

type Props = {
  name: string;
  propostaId: number;
  showErrors?: boolean;
  tabsErrors?: ProposalTabError;
};

type Empresa = {
  id: number;
  razaoSocial?: string;
  cnpj?: string;
};

type Parte = {
  id: number;
  nome: string;
};

type PropostaEmpresa = {
  id: number;
  coordenador?: string;
  cargo?: string;
  email?: string;
  telefone?: string;
  empresa?: Empresa;
  parte: Parte;
};

type State = {
  modalidadeId?: number | null;
  partes?: PropostaEmpresa[];
  error?: any;
};

const CompanyList = (props: Props) => {
  const [modalidadeId, setModalidadeId] = useState<number>();
  const [simulationChange, setSimulationChange] = useState<boolean>();
  const form = useForm();
  const formContext = useContext(FormObserverContext);
  const request = useRequest();

  const [state, setState] = useState<State>({});

  const setModalidade = useCallback(
    (modalidadeId: number | null) => {
      const source = request<State>({
        url: `/proposta/${props.propostaId}/modalidade`,
        method: 'put',
        data: { id: modalidadeId },
        onSuccess: setState,
        onError: error => setState({ error }),
      });

      return () => source.cancel();
    },
    [props.propostaId, request]
  );

  const loadModalidadeEmpresas = useCallback(() => {
    const source = request<PropostaEmpresa[]>({
      url: `/proposta/${props.propostaId}/modalidade`,
      onSuccess: partes => setState(state => ({ ...state, partes })),
      onError: error => setState({ error }),
    });

    return () => source.cancel();
  }, [props.propostaId, request]);

  useEffect(() => {
    if (modalidadeId !== undefined) {
      if (state.modalidadeId === undefined) {
        if (modalidadeId === null) {
          setState({ modalidadeId: null });
        } else {
          setModalidade(modalidadeId);
        }
      } else if (modalidadeId !== state.modalidadeId) {
        setModalidade(modalidadeId);
      } else {
        loadModalidadeEmpresas();
      }
    }
  }, [loadModalidadeEmpresas, modalidadeId, setModalidade, state.modalidadeId]);

  useEffect(() => {
    setModalidadeId(form.getFieldValue('modalidade'));
    setState(state => ({ ...state, modalidadeId: form.getFieldValue('modalidade') }));
    setSimulationChange(form.getFieldValue('simulacao'));
    const callback = (res: FormObserverResult) => {
      if (res.name.endsWith('modalidade')) {
        setModalidadeId(() => form.getFieldValue('modalidade'));
      }
      if (res.name.endsWith('simulacao')) {
        setSimulationChange(() => form.getFieldValue('simulacao'));
      }
    };

    formContext.subscribe(callback);
    return () => formContext.unsubscribe(callback);
  }, [formContext, form]);

  const createAdornment = (item: string) => {
    let adornment = <></>;
    const tabErrors = props.tabsErrors;

    if (tabErrors && tabErrors['identificacao'] && tabErrors['identificacao'][item]) {
      let className = tabErrors['identificacao'][item].warnings
        ? styles.adornmentWarn
        : '';

      className = tabErrors['identificacao'][item].errors
        ? styles.adornmentError
        : className;

      adornment = (
        <div className={className}>
          <Info />
        </div>
      );
    }

    return adornment;
  };
  const groupPartsWithSameId = (partes: PropostaEmpresa[]) => {
    const group: { [key: string]: PropostaEmpresa[] } = {};
    partes.forEach(parte => {
      if (!group[parte.parte.id]) {
        group[parte.parte.id] = [];
      }
      group[parte.parte.id].push(parte);
    });
    return group;
  };

  const addIndexToRepeatParts = (partes: PropostaEmpresa[]) => {
    const groupedPartsWithSameId = groupPartsWithSameId(partes);

    return partes.map(parte => {
      if (groupedPartsWithSameId[parte.parte.id].length > 1) {
        return {
          ...parte,
          parte: {
            ...parte.parte,
            nome:
              parte.parte.nome +
              ' #' +
              (groupedPartsWithSameId[parte.parte.id].indexOf(parte) + 1),
          },
        };
      }
      return parte;
    });
  };

  return state.partes ? (
    <WrapperCard padding="0 20px 5px" width="916px" maxWidth="" childrenWidth="100%">
      <div className={styles.companyList}>
        <Tabs selectedIndex={0}>
          {addIndexToRepeatParts(state.partes).map((item, index) => (
            <Tab
              title={item.parte.nome}
              key={index}
              adornment={
                props.showErrors ? createAdornment(`empresas[${index}]`) : undefined
              }
            >
              <FieldSet
                name="empresas"
                index={index}
                key={item.id}
                disabled={simulationChange}
              >
                <CompanySection data={item} />
              </FieldSet>
            </Tab>
          ))}
        </Tabs>
      </div>
    </WrapperCard>
  ) : null;
};

const CompanySection = ({ data }: { data: PropostaEmpresa }) => {
  const { tx } = useTranslationX('identification.company', 'proposal');

  return (
    <Grid className={styles.section}>
      <HiddenField name="id" value={data.id} omit />

      <Row width={[0.46, 0.54]} align="center">
        <TextField
          name="cnpj_search"
          label={tx('manager.cnpj')}
          value={data.empresa?.cnpj}
          omit
          metadata={{
            rules: {
              cnpj: true,
              mask: { pattern: '00.000.000/0000-00' },
              required: true,
            },
          }}
        />
        <FieldSearch<Empresa>
          url="/empresa"
          target="cnpj_search"
          param="cnpj"
          initialValue={data.empresa?.cnpj}
          errorMessage={tx('errorMessage')}
        >
          {({ status, data: empresa }) => {
            switch (status) {
              case 'success':
                return <Company data={{ ...data, empresa }} />;

              case 'invalid':
                return <Company data={{ ...data, empresa: undefined }} />;

              default:
                return <Company data={data} loading={status === 'searching'} />;
            }
          }}
        </FieldSearch>
      </Row>
      <CompanyInfo data={data} />
    </Grid>
  );
};

const CompanyInfo = ({ data }: { data?: PropostaEmpresa }) => {
  const { tx } = useTranslationX('identification.company.manager', 'proposal');

  return (
    <div className={styles.company}>
      <span className={styles.managerTitle}>{tx('title')}</span>
      <Grid className={styles.manager} spacing="8px 18px" maxWidth={650}>
        <Row>
          <TextField name="coordenador" label={tx('name')} value={data?.coordenador} />
          <TextField name="email" label={tx('email')} value={data?.email} />
        </Row>
        <Row>
          <TextField name="telefone" label={tx('phone')} value={data?.telefone} />
          <TextField name="cargo" label={tx('occupation')} value={data?.cargo} />
        </Row>
      </Grid>
    </div>
  );
};

const Company = ({ data, loading }: { data?: PropostaEmpresa; loading?: boolean }) => {
  const { tx } = useTranslationX('identification.company.manager', 'proposal');

  return (
    <div className={styles.company}>
      <HiddenField name="empresa" value={data?.empresa?.id || null} />
      {loading ? (
        <div>
          <Loader size={22} type="spin" />
        </div>
      ) : (
        data?.empresa?.razaoSocial && (
          <div className={styles.name}>
            <span>{tx('company')}</span>
            <span>{data?.empresa?.razaoSocial}</span>
          </div>
        )
      )}
    </div>
  );
};

export default CompanyList;
