import React, { useState } from 'react';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
import { Tag } from '..';
import arcDrawer from 'utils/arcDrawer';

import styles from './DonutChart.module.scss';

type Props = {
  data: {
    label: string;
    value: number;
    color: string;
  }[];
  graph: {
    size?: number;
    radius?: number;
    borderRadius?: number;
    lineWidth?: number;
    space?: number;
  };
  legend?: {
    show: boolean;
    position?: 'top' | 'bottom' | 'left' | 'right';
    fontSize?: number;
    padding?: number | string | number;
    radius?: number;
    dotSize?: number | string;
  };
  parseTippy?(value: number, percentage: number): string;
  onClickLegend?: (label: string, value: number) => void;
  onClickDonut?: (label: string, value: number) => void;
  onHoverDonut?: (label: string, value: number) => void;
};

const DonutChart: React.FC<Props> = props => {
  const { data, legend, graph } = props;
  const { size = 30, radius = 80, borderRadius = 10, lineWidth = 20, space = 4 } = graph;
  const {
    show = true,
    position = 'left',
    fontSize = 12,
    padding,
    dotSize,
    radius: legendRadius = 50,
  } = legend || {};
  const [selected, setSelected] = useState<number | undefined>();

  const calculatePercentage = (value: number) => {
    const total = data.reduce((acc, cur) => acc + cur.value, 0);
    return (value / total) * 100;
  };

  const calculateAngle = (percentage: number) => {
    return (percentage / 100) * 360;
  };

  const drawGraph = (
    index = 0,
    percentageAccumulator = 0,
    graphs: JSX.Element[] = []
  ): JSX.Element[] => {
    if (index >= data.length) {
      return graphs;
    }
    const value = data[index].value;
    if (value > 0) {
      const percentage = calculatePercentage(value);
      let graph = (
        <Tippy
          key={index}
          className={styles.popup}
          content={
            props.parseTippy
              ? props.parseTippy(value, percentage)
              : `${percentage.toFixed(0)}%`
          }
          animation="perspective"
          touch={false}
          followCursor="horizontal"
          plugins={[followCursor]}
        >
          <path
            key={index}
            className={selected === index ? styles.selected : ''}
            fill={data[index].color}
            d={arcDrawer(
              [100, 100],
              radius,
              percentageAccumulator + (percentage >= 100 ? 1 : space),
              calculateAngle(percentage) + percentageAccumulator,
              lineWidth,
              borderRadius
            )}
            onClick={() => {
              if (props.onClickDonut) {
                props.onClickDonut(data[index].label, value);
              }
            }}
          />
        </Tippy>
      );
      graphs.push(graph);
    }

    return drawGraph(
      index + 1,
      calculateAngle(calculatePercentage(value)) + percentageAccumulator,
      graphs
    );
  };

  return (
    <div
      className={styles.graph}
      style={{
        flexDirection: position === 'top' || position === 'bottom' ? 'column' : 'row',
        justifyContent:
          position === 'top' || position === 'bottom' ? 'center' : 'space-between',
        alignItems: position === 'top' || position === 'bottom' ? 'center' : 'flex-start',
      }}
    >
      <div className={styles.pieChart}>
        <svg width={size} viewBox="0 0 200 200">
          {drawGraph()}
        </svg>
      </div>
      {show && (
        <div className={styles.legend} style={{ ['--height' as any]: `${size}px` }}>
          {data.map((item, index) => (
            <Tag
              key={index}
              onHover={() => {
                setSelected(index);
              }}
              onMouseLeave={() => {
                setSelected(undefined);
              }}
              radius={legendRadius}
              dotSize={dotSize}
              padding={padding}
              text={item.label}
              color={item.color}
              fontSize={fontSize}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default DonutChart;
