export type ConclusionStatus = 'LAT' | 'UPT' | 'TUP' | 'PLA' | 'ALT';
export const conclusionStatusColors: { [key in ConclusionStatus]: string } = {
  LAT: '#E51616',
  UPT: '#37A64A',
  TUP: '#BBEBC4',
  PLA: '#C4C4C4',
  ALT: '#f2b73f',
};

export type Conclusion = {
  id: string | number;
  estado: ConclusionStatus;
  mes: number;
  evolucao: number;
};

export type MilestoneConclusion = {
  id: number;
  nome: string;
  conclusoes: Conclusion[];
};

export type MilestoneConclusions = {
  inicioRelativo: Date;
  duracao: number;
  meses: string[];
  macroentregas: MilestoneConclusion[];
};
