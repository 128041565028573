import React from 'react';

const Team = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24">
    <g fill="currentColor">
      <path d="m9.27 8.77c-0.00633-0.365 0.0599-0.728 0.195-1.07 0.135-0.339 0.335-0.647 0.59-0.905 0.255-0.258 0.558-0.462 0.892-0.599 0.334-0.137 0.691-0.204 1.05-0.198 0.36-0.00644 0.717 0.0608 1.05 0.198 0.334 0.137 0.637 0.341 0.891 0.599 0.254 0.258 0.455 0.566 0.59 0.905 0.135 0.339 0.201 0.702 0.195 1.07 0.0064 0.365-0.0598 0.728-0.195 1.07-0.135 0.339-0.335 0.647-0.59 0.905-0.254 0.258-0.558 0.462-0.891 0.599-0.334 0.137-0.691 0.204-1.05 0.198-0.36 0.0065-0.717-0.0607-1.05-0.198-0.334-0.137-0.637-0.341-0.892-0.599-0.255-0.258-0.455-0.566-0.59-0.905-0.135-0.339-0.201-0.702-0.195-1.07zm2.73 4.62c-4.18 0-5.45 3.05-5.45 3.05v1.57h10.9v-1.57s-1.27-3.05-5.46-3.05z" />
      <path d="m17.9 10.6c1.28 0 2.32-1.02 2.32-2.28 0-1.26-1.04-2.28-2.32-2.28s-2.32 1.02-2.32 2.28c0 1.26 1.04 2.28 2.32 2.28z" />
      <path d="m17.9 12.5c-0.904-0.0366-1.8 0.222-2.55 0.739 2.09 1.02 2.91 2.77 2.91 2.95v0.0924h3.73v-1.2c0-0.0931-1-2.58-4.09-2.58z" />
      <path d="m17.9 10.6c1.28 0 2.32-1.02 2.32-2.28 0-1.26-1.04-2.28-2.32-2.28s-2.32 1.02-2.32 2.28c0 1.26 1.04 2.28 2.32 2.28z" />
      <path d="m17.9 12.5c-0.904-0.0366-1.8 0.222-2.55 0.739 2.09 1.02 2.91 2.77 2.91 2.95v0.0924h3.73v-1.2c0-0.0931-1-2.58-4.09-2.58z" />
      <path d="m6.09 10.6c1.28 0 2.32-1.02 2.32-2.28 0-1.26-1.04-2.28-2.32-2.28-1.28 0-2.32 1.02-2.32 2.28 0 1.26 1.04 2.28 2.32 2.28z" />
      <path d="m6.09 12.5c0.904-0.0366 1.8 0.222 2.55 0.739-2.09 1.02-2.91 2.77-2.91 2.95v0.0924h-3.73v-1.2c0-0.0931 1-2.58 4.09-2.58z" />
    </g>
  </svg>
);

export default Team;
