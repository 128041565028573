export type MilestoneStatus = 'EXE' | 'PRE' | 'ATR' | 'ACE' | 'ANS';

export type MilestoneReduced = {
  id: number;
  titulo: string;
  status: MilestoneStatus;
  mesInicio: number;
  mesFim: number;
  conclusao?: number;
  alteracaoExclusao: boolean;
  ordem: number;
};

export type MilestoneDeliverable = {
  id: number;
  titulo: string;
  ordem: number;
};

export type UpdateWPInEdition = {
  solicitacao: {
    aditivo: boolean;
    id: number;
    estado: string;
    perfil: string;
    usuario: {
      id: number;
    };
  } | null;
};

export type Milestone = {
  id: number;
  titulo: string;
  conclusao?: number;
  duracao: number;
  ordem: number;
  inicio: Date;
  dataEntrega: Date;
  dataUploadTermo?: Date;
  entregaveis: MilestoneDeliverable[];
  arquivoTermo?: {
    id: number;
  };
  estado: MilestoneStatus;
};

export type MilestoneEvolution = {
  id: number;
  conclusao: number;
  data: Date;
};

export const milestoneStatusColors: { [key in MilestoneStatus]: string } = {
  EXE: '#617cf2',
  PRE: '#7A13D6',
  ATR: '#ed5b00',
  ACE: '#3FC75C',
  ANS: '#C98CFF',
};
