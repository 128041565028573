import React from 'react';

import FileDownloader from '../FileDownloader/FileDownloader';
import styles from './FileList.module.scss';

type Props = {
  label?: string;
  values: { nome: string; id: number }[];
};

const FileList = ({ label, values }: Props) => {
  return (
    <div className={styles.fileList}>
      {label && <span className={styles.label}>{label}</span>}

      {values &&
        values.map(({ id, nome }) => (
          <FileDownloader key={id} id={id}>
            <div className={styles.link}>{nome}</div>
          </FileDownloader>
        ))}
    </div>
  );
};

export default FileList;
