import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { useRequest } from 'apis';
import { useTranslationX } from 'i18n';
import { ErrorMessage, Loader, Spacer } from 'components/elements';
import { ErrorDialog, HStack, SuccessDialog, VStack } from 'components/containers';
import { Form, SubmitButton } from 'components/form';
import ResetPasswordToken from './ResetPasswordForm/ResetPasswordToken';

type State = {
  status: 'init' | 'idle' | 'error' | 'success';
  action?: string;
};

type Props = {
  token: string;
};

const TokenDispatcher = (props: Props) => {
  const [{ status, action }, setState] = useState<State>({ status: 'init' });

  const { token } = props;
  const { tx } = useTranslationX('token');

  const request = useRequest();
  const history = useHistory();

  useEffect(() => {
    if (token) {
      const source = request<string>({
        url: `/token/${token}`,
        onSuccess: action => setState({ action, status: 'idle' }),
        onError: () => setState({ status: 'error' }),
      });

      return () => source.cancel();
    }
  }, [request, token]);

  if (status === 'init') {
    return <Loader size={60} />;
  }

  if (status === 'error') {
    return (
      <ErrorDialog
        visible={true}
        message={tx('expired.message')}
        title={tx('expired.title')}
        onClose={() => history.push('/login')}
      />
    );
  }

  if (status === 'success') {
    return (
      <SuccessDialog
        visible={true}
        message={tx(`success.${action}`)}
        onClose={() => history.push('/login')}
      />
    );
  }

  let content;

  switch (action) {
    case 'password.reset':
      content = <ResetPasswordToken />;
      break;

    default:
      content = null;
      break;
  }

  if (!content) {
    return <ErrorMessage />;
  }

  return (
    <Form
      baseUrl={`/token/${token}`}
      onError={() => {
        setState({ status: 'error' });
        return 'handled';
      }}
      onDone={() => setState({ action, status: 'success' })}
    >
      <VStack align="stretch" style={{ margin: 'auto', width: 300 }}>
        {content}

        <Spacer length={20} />

        <HStack align="right">
          <SubmitButton autoDisable>{tx('submitButton')}</SubmitButton>
        </HStack>
      </VStack>
    </Form>
  );
};

export default TokenDispatcher;
