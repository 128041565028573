import React from 'react';

import { useTranslationX, useTranslation } from 'i18n';
import { Grid } from 'components/containers';
import { TextField, MultiSelect, FieldSet, HiddenField } from 'components/form';

const Supervisor = ({ index }: { index: number }) => {
  const { t } = useTranslation();
  const { tx } = useTranslationX('supervisor', 'register');

  return (
    <Grid>
      <FieldSet name="supervisor" index={index}>
        <HiddenField name="id" />
        <TextField name="emailNotificacao" label={tx('email-notification')} />
        <MultiSelect
          name="tiposNotificacao"
          label={tx('type-notification')}
          parseOption={value => t(`notification.${value}`)}
        />
      </FieldSet>
    </Grid>
  );
};

export default Supervisor;
