import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dispatch, EditorState } from '../types';
import { useRequest } from 'apis';
import { classes } from 'utils/components';

import HistoryItem from './HistoryItem';
import { ScrollPanel } from 'components/containers';
import { FormInstance, FormMetadata } from 'components/form/types';
import { IconButton } from 'components/elements';
import { XAlt } from 'components/icons';
import { Profile } from 'pages/register/profile';
import { useAuthState } from 'store/auth';

import styles from './History.module.scss';
import useCallbackDebounce from 'components/hooks/useCallbackDebounce';

type Props = {
  state: EditorState;
  dispatch: Dispatch;
  instances?: FormInstance[];
};

export type Change = {
  name: string;
  type: 'modified' | 'removed' | 'added';
  from?: unknown;
  to?: unknown;
  display?: string;
  metadata?: FormMetadata;
  fonteRecursoId?: number;
  fonteRecursoStatus?: number;
  fonteRecurso?: string;
  path?: string[];
  replacers?: {
    [key: string]: string;
  };
};

export type CompareData = {
  [key: string]: Change[];
};

type ParentSections = {
  [key: string]: {
    sections: string[];
    translationKey?: string;
  };
};

const parentSections: ParentSections = {
  identificacao: {
    sections: ['identificacao'],
  },
  descritivo: {
    sections: ['descritivo'],
  },
  cronograma: {
    sections: ['aporte'],
  },
  eap: {
    sections: ['eap'],
    translationKey: 'eap',
  },
  'metas-macro': {
    sections: ['macroentrega'],
    translationKey: 'macroentregas',
  },
  orcamento: {
    sections: [
      'rh',
      'passagem',
      'equipamentos',
      'outros',
      'servico',
      'material',
      'suporte',
      'diaria',
    ],
    translationKey: 'financial',
  },
  economico: {
    sections: ['economico'],
  },
};

const History = ({ state, dispatch, instances }: Props) => {
  const request = useRequest();

  const [compareData, setCompareData] = useState<CompareData>();

  const { profile } = useAuthState();

  const debounceTime = useMemo(() => {
    return (instances?.length || 0) > 1 ? 400 : 0;
  }, [instances?.length]);

  const handleData = useCallback(
    (data: CompareData) => {
      dispatch({
        type: 'SET_HISTORIC_DATA',
        payload: data,
      });
      setCompareData(data);
    },
    [dispatch]
  );

  const compareCallback = useCallback(() => {
    if (profile === Profile.SUPERVISOR && state.showDiff) {
      const source = request<CompareData>({
        url: `/projeto/${state.id}/alteracao-pt/compare`,
        onSuccess: handleData,
      });
      return () => source.cancel();
    }
  }, [profile, state.showDiff, state.id, request, handleData]);

  const observerCallback = useCallbackDebounce(compareCallback, debounceTime);

  useEffect(() => {
    compareCallback();
  }, [compareCallback, state.section]);

  useEffect(() => {
    if (instances) {
      instances.forEach(instance =>
        instance.observer.subscribe(observerCallback, { onSave: true })
      );
    }
    return () => {
      instances?.forEach(instance => instance.observer.unsubscribe(observerCallback));
    };
  }, [instances, state.id, observerCallback]);

  function hasHistoryData(object: CompareData) {
    for (const key in object) {
      if (object.hasOwnProperty(key) && object[key].length > 0) {
        return true;
      }
    }
    return false;
  }

  const isHistoryEmpty = !hasHistoryData(compareData || {});

  if (isHistoryEmpty) return null;

  return (
    <div
      className={classes(
        styles.history,
        styles[state.historyVisible ? 'visible' : 'hidden']
      )}
    >
      <IconButton
        size={25}
        type="dark"
        shape="square"
        icon={XAlt}
        onClick={() => {
          dispatch({
            type: 'CLOSE_HISTORY',
          });
        }}
      />
      <ScrollPanel vBar={{ overlay: true }}>
        <div className={styles.items}>
          {Object.entries(parentSections).map(([parent, sections]) => {
            return (
              sections &&
              sections.sections.map(section => {
                const existsSection = compareData && compareData[section].length > 0;
                if (!existsSection) return null;
                return (compareData as any)[section].map((change: any, idx: number) => (
                  <HistoryItem
                    key={idx}
                    parentSection={parent}
                    element={change}
                    translationKey={sections.translationKey}
                  />
                ));
              })
            );
          })}
        </div>
      </ScrollPanel>
    </div>
  );
};

export default History;
