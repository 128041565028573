import React from 'react';
import { TotalLabel } from 'pages/proposal';
import { CompositionTable, EstimationTable } from '..';
import { useTranslationX } from 'i18n';
import { SectionPropsWithSupport } from '../DisbursementSchedule';

import Form from 'components/form/Form';

import styles from './BudgetSummary.module.scss';

const BudgetSummary = ({
  forceUpdate,
  data,
  formProps,
  state: { id },
}: SectionPropsWithSupport) => {
  const { total, partes, composicao, estimativa } = data;
  const { tx } = useTranslationX('proposal:financial', ['translation', 'proposal']);

  return (
    <div className={styles.summary}>
      <TotalLabel
        value={total}
        title={tx('composition.total-value')}
        spacing="11px 10px"
      />
      <CompositionTable partes={partes} composicao={composicao} />
      <Form
        {...formProps}
        baseUrl={`/proposta/${id}/operational-support`}
        name={`${formProps.name}.operational-support`}
      >
        <EstimationTable
          partes={partes}
          estimativa={estimativa}
          forceUpdate={forceUpdate}
        />
      </Form>
    </div>
  );
};

export default BudgetSummary;
