import { useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useForm, useFieldSet, useFormSection } from '../index';
import { FieldMetadata, FieldType } from '../types';

type Props<T = any> = {
  name: string;
  type?: FieldType;
  value?: T;
  lastValue?: T;
  metadata?: FieldMetadata;
  forceActive?: boolean;
};

const useField = <T = any>(props: Props<T>) => {
  const form = useForm();

  const fieldSet = useFieldSet();
  const formSection = useFormSection();

  const name = `${fieldSet.prefix}${props.name}`;

  const value = props.value === undefined ? form.getFieldValue<T>(name) : props.value;

  const lastValue = form.showDiff
    ? props.lastValue === undefined
      ? form.getLastFieldValue<T>(name)
      : props.lastValue
    : undefined;

  const type =
    form.editable || props.forceActive
      ? (fieldSet.disabled && 'disabled') || props.type
      : 'readonly';

  const [, refreshType] = useState<any>({});
  const [validationToken, refreshValidation] = useState<any>({});

  const [metadata, setMetadata] = useState(
    _.merge(form.getFieldMetadata(name), props.metadata)
  );

  const valueRef = useRef(value);
  const lastValueRef = useRef(lastValue);

  const updatedValue = valueRef.current;
  valueRef.current = undefined;

  const updatedLastValue = lastValueRef.current;
  lastValueRef.current = undefined;

  const setValue = useCallback((value: T) => {
    valueRef.current = value;

    refreshType({});
  }, []);

  useEffect(() => {
    if (props.metadata) {
      const prevMetadata = form.getFieldMetadata(name);
      if (prevMetadata) {
        if (prevMetadata.rules) {
          props.metadata.rules = _.merge(prevMetadata.rules, props.metadata.rules);
        }
        if (prevMetadata.options) {
          props.metadata.options = _.merge(prevMetadata.options, props.metadata.options);
        }
      }
      form.setFieldMetadata(name, props.metadata);
    }
  }, [form, name, props.metadata]);

  useEffect(() => () => form.unmountField(name), [form, name]);

  return {
    form,
    name,
    type,
    value: updatedValue !== undefined ? updatedValue : value,
    lastValue: updatedLastValue !== undefined ? updatedLastValue : lastValue,
    setValue,
    section: formSection.name,
    metadata,
    setMetadata,
    refreshType,
    validationToken,
    refreshValidation,
  };
};

export default useField;
