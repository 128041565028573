import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { PeopleListView, PeopleEditor } from 'pages/people';

import RouteData from 'pages/utils/RouteData';

const Pessoal = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <PeopleListView />
      </Route>
      <Route path={`${path}/:id`}>
        <RouteData>{props => <PeopleEditor {...props} />}</RouteData>
      </Route>
    </Switch>
  );
};

export default Pessoal;
