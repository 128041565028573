import React, { Fragment, CSSProperties, useState, useRef } from 'react';
import { useTranslationX } from 'i18n';
import { FieldSet, useField, useForm, useDiff } from 'components/form';
import { parseTableConfig } from './helpers';
import { ColumnConfig, TextSize } from './types';
import { randomKey, randomNumber, splitValueUnit } from 'utils';
import { classes } from 'utils/components';
import { ScrollPanel } from '..';
import HeaderCell from './HeaderCell';
import TableDataItem from './TableDataItem';

import styles from './TableData.module.scss';

export type DataType =
  | 'text'
  | 'select'
  | 'check'
  | 'computed'
  | 'date'
  | 'dateRange'
  | 'hidden';

type Entry = {
  id: number;
};

type Props = {
  name: string;
  boldHeaders?: boolean;
  groupBy?: (string | number)[][];
  hiddenFields?: string[];
  config: ColumnConfig<DataType>;
  value?: Entry[];
  minRows?: number;
  maxRows?: number;
  textSize?: TextSize;
  width?: [number, number];
  style?: { root?: CSSProperties; table?: CSSProperties };
  addLabel?: string;
  defaultData?: any;
  fixedList?: boolean;
  refField?: string;
};

export type Action = 'ADD' | 'REMOVE';

const TableData = (props: Props) => {
  const form = useForm();

  const {
    config,
    minRows,
    boldHeaders,
    maxRows,
    textSize = 'normal',
    style,
    addLabel,
    fixedList = false,
    refField = 'referencia',
  } = props;
  const { name, value } = useField({
    ...props,
  });

  const { groupBy, hiddenFields } = props;
  const { header, body } = parseTableConfig(config);
  const { value: inputHeight } = splitValueUnit(styles.inputHeight);
  const { tx } = useTranslationX('components.tableData');

  const [headerH, setHeaderH] = useState(0);
  const [numRows, setNumRows] = useState(() => {
    let rows = value?.length || 0;

    if (minRows && rows < minRows) {
      rows = minRows;
    }

    if (maxRows && rows > maxRows) {
      rows = maxRows;
    }

    return rows;
  });

  const coldReload = useRef(numRows === 0);

  const data = form.getCollection(name);

  const canAdd = !maxRows || maxRows > numRows;
  const canRemove = !minRows || minRows < numRows;
  const editable = form.editable;

  const { getDiff, getRespectiveRowByFonteRecurso } = useDiff(name);

  const dispatch = (action: Action, index: number) => {
    switch (action) {
      case 'ADD':
        if (canAdd) {
          form.addListItem(
            name,
            index + 1,
            success => {
              if (success) {
                setNumRows(c => c + 1);
              }
            },

            groupBy
              ? {
                  [groupBy[0][0]]: groupBy[0][1],
                  [groupBy[1][0]]: groupBy[1][1],
                }
              : undefined
          );
        }

        break;

      case 'REMOVE':
        if (canRemove) {
          form.removeListItem(name, index, success => {
            if (success) {
              if (coldReload.current && index < numRows - 1) {
                form.reload();
              }
              coldReload.current = false;
              setNumRows(c => c - 1);
            }
          });
        }

        break;
    }
  };

  const fonteRecursoLabel = groupBy?.[0][0] as string;
  const fonteRecursoValue = groupBy?.[0][1];
  const fonteRecursoStatusLabel = groupBy?.[1][0] as string;
  const fonteRecursoStatusValue = groupBy?.[1][1];

  const removedRespectiveRows = getRespectiveRowByFonteRecurso(
    fonteRecursoValue as number,
    fonteRecursoStatusValue as number
  );

  const fieldsHidden = [...(hiddenFields || []), refField];

  return (
    <div className={classes(styles.tableData, styles[textSize])} style={style?.root}>
      <ScrollPanel vBar={{ start: headerH, overlay: true, placeholder: true }}>
        <table style={style?.table}>
          <colgroup>
            {body.map(item => (
              <col key={item.field} style={{ width: `${item.width}%` }} />
            ))}

            <col style={{ width: editable ? inputHeight + 27 : 5 }} />
          </colgroup>

          <thead ref={ref => (ref ? setHeaderH(ref.offsetHeight) : null)}>
            {header.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row
                  .filter(cell => cell.type !== 'hidden')
                  .map((cell, cellIndex) => (
                    <HeaderCell
                      key={cellIndex}
                      level={rowIndex}
                      config={cell}
                      boldHeaders={boldHeaders}
                    />
                  ))}

                {rowIndex ? null : <th rowSpan={header.length}></th>}
              </tr>
            ))}
          </thead>

          <tbody>
            {numRows ? (
              <>
                {Array.from(new Array(numRows), (_el, index) => {
                  const section = data?.[name];
                  const sectionData = section?.[index];

                  const seq = '';
                  (data && section && sectionData ? sectionData?.['_seq_'] : 0) ||
                    randomKey(10);

                  if (data && section && sectionData && groupBy !== undefined) {
                    const row = sectionData;

                    if (
                      row[fonteRecursoLabel] !== fonteRecursoValue ||
                      row[fonteRecursoStatusLabel] !== fonteRecursoStatusValue
                    ) {
                      return null;
                    }
                  }
                  const rowReference =
                    data && section && sectionData ? sectionData?.[refField] : 0;
                  const rowId = data && section && sectionData ? sectionData?.['id'] : 0;

                  let rowDiff = getDiff(rowReference);
                  if (!rowDiff) rowDiff = getDiff(rowId);

                  const removivel = sectionData?.['removivel'] ?? true;

                  const editavel = sectionData?.['editavel'] ?? true;

                  const removeableRow = canRemove && editable && !fixedList && removivel;

                  return (
                    <Fragment key={`${seq}_${index}`}>
                      <FieldSet name={name} index={index}>
                        <TableDataItem
                          seq={seq}
                          groupBy={groupBy}
                          hiddenFields={fieldsHidden}
                          metadata={body}
                          dispatch={action => dispatch(action, index)}
                          canRemove={removeableRow}
                          diff={rowDiff}
                          editable={editavel}
                          immutable={!removivel}
                        />
                      </FieldSet>
                    </Fragment>
                  );
                })}
              </>
            ) : null}
            {removedRespectiveRows?.map((row, index) => (
              <FieldSet name={name} index={randomNumber(4000, 10000)} key={row.data.id}>
                <TableDataItem
                  seq=""
                  groupBy={groupBy}
                  hiddenFields={fieldsHidden}
                  metadata={body}
                  dispatch={action => dispatch(action, index)}
                  canRemove={false}
                  diff={row}
                  populate={row.data}
                />
              </FieldSet>
            ))}
          </tbody>
        </table>
        {editable && !fixedList ? (
          <div className={styles.addData} onClick={() => dispatch('ADD', numRows - 1)}>
            {addLabel ?? tx('add')}
          </div>
        ) : null}
      </ScrollPanel>
    </div>
  );
};

export default TableData;
