import React from 'react';

import { useTranslationX } from 'i18n';
import { MenuItem } from 'pages/dashboard/types';
import { Tab, Tabs } from 'components/containers';
import { Form } from 'components/form';
import { Info } from 'components/icons';
import { renderComponent } from 'utils/components';
import { SectionProps } from '../ProposalEditor/ProposalEditor';

import HumanResources from './HumanResources';
import Consumables from './Consumables';
import Travels from './Travels';
import Services from './Services';
import Others from './Others';
import Equipments from './Equipments';

import styles from './Financial.module.scss';
import { WrapperCard } from 'components/elements';

const items: MenuItem[] = [
  { key: 'hr', component: HumanResources },
  { key: 'consumables', component: Consumables },
  { key: 'travels', component: Travels },
  { key: 'services', component: Services },
  { key: 'other', component: Others },
  { key: 'equipments', component: Equipments },
];

const Financial = ({ state: { id, showErrors, tabErrors }, formProps }: SectionProps) => {
  const { tx } = useTranslationX('financial.sections', 'proposal');

  const createAdornment = (item: string) => {
    let adornment = <></>;

    if (tabErrors && tabErrors['orcamento'] && tabErrors['orcamento'][item]) {
      let className = !!tabErrors['orcamento'][item].warnings ? styles.adornmentWarn : '';

      className = !!tabErrors['orcamento'][item].errors
        ? styles.adornmentError
        : className;

      adornment = (
        <div className={className}>
          <Info />
        </div>
      );
    }

    return adornment;
  };
  return (
    <WrapperCard padding="5px 15px 15px" margin="0 0 1rem" responsive>
      <Tabs selectedIndex={0} cascade="cascade">
        {items.map(({ key, component }) => (
          <Tab
            title={tx(key)}
            key={key}
            adornment={showErrors ? createAdornment(key) : undefined}
          >
            <Form
              baseUrl={`/proposta/${id}/${key}`}
              {...formProps}
              name={`${formProps.name}.${key}`}
            >
              {renderComponent(component)}
            </Form>
          </Tab>
        ))}
      </Tabs>
    </WrapperCard>
  );
};

export default Financial;
