import { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';

import { useRequest } from '.';
import { Method } from 'axios';

const useFetch = <T>(
  url?: string,
  params?: object,
  initialValue?: T,
  enable = true,
  method: Method = 'GET'
) => {
  const [data, setData] = useState<T | undefined | Error>(initialValue);
  const [attr, setAttr] = useState(params);

  const request = useRequest();

  const fetch = useCallback(() => {
    if (!enable) {
      return;
    }
    if (url) {
      const source = request<T>({
        url,
        method,
        params: attr,
        onSuccess: setData,
        onError: setData,
      });

      return () => source.cancel();
    }
  }, [enable, url, request, method, attr]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    if (!_.isEqual(attr, params)) {
      setAttr(params);
    }
  }, [attr, params]);

  if (data instanceof Error) {
    return { request, error: data, reload: fetch };
  }

  return { request, data, reload: fetch };
};

export const useFetchWithEnable = <T>(url?: string, enable?: boolean) =>
  useFetch<T>(url, undefined, undefined, enable);

export default useFetch;
