import React from 'react';

import { SectionProps } from '../../UpdateWpEditor';
import { MilestoneContribution, RangedMilestone } from 'pages/project/types';

import { ApportionmentTable, PaymentTable } from '..';

import { useTranslationX } from 'i18n';
import { renderComponent } from 'utils/components';
import { Tab, Tabs } from 'components/containers';
import { UseDiffReturn } from 'components/form/useDiff';

import styles from './ScheduleTab.module.scss';

type Props = {
  contributions: MilestoneContribution[];
  parts: string[];
  milestones: RangedMilestone[];
  partIdx: number;
  removedRows: UseDiffReturn['removedRows'];
  getDiff: UseDiffReturn['getDiff'];
  currTab: number;
  onChangeTab: (index: number) => void;
} & SectionProps;

export type PaymentProps = Props;
export type ApportionmentProps = Props & {
  milestones: never;
  milestone: RangedMilestone;
};

const ScheduleTab = (props: Props) => {
  const { milestones, currTab, onChangeTab } = props;

  const { tx } = useTranslationX('schedule.milestone', 'update_wp');

  return (
    <div className={styles.content}>
      <PaymentTable {...props} />

      <Tabs cascade="cascade" selectedIndex={currTab} onChangeTab={onChangeTab}>
        {milestones.map((milestone, index) => (
          <Tab
            key={milestone.id}
            title={tx('label', {
              number: index + 1,
            })}
          >
            {renderComponent(ApportionmentTable, {
              index,
              milestone,
              ...props,
            })}
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default ScheduleTab;
