import React from 'react';

const ArchiveGraduationCap = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none">
    <path
      d="M19 7.20026H12.7199L12.1798 5.56985C12.0474 5.17098 11.7927 4.82396 11.4519 4.57805C11.1111 4.33214 10.7015 4.19985 10.2812 4.19995H4.99996C4.73731 4.1999 4.47722 4.2516 4.23455 4.35209C3.99188 4.45258 3.77139 4.5999 3.58567 4.78562C3.39994 4.97134 3.25263 5.19183 3.15214 5.4345C3.05165 5.67717 2.99995 5.93726 3 6.19991V18.2011C2.99995 18.4638 3.05165 18.7239 3.15214 18.9665C3.25263 19.2092 3.39994 19.4297 3.58567 19.6154C3.77139 19.8011 3.99188 19.9485 4.23455 20.0489C4.47722 20.1494 4.73731 20.2011 4.99996 20.2011H19C19.2627 20.2011 19.5228 20.1494 19.7654 20.0489C20.0081 19.9485 20.2286 19.8011 20.4143 19.6154C20.6001 19.4297 20.7474 19.2092 20.8479 18.9665C20.9484 18.7239 21 18.4638 21 18.2011V9.20022C21 8.93756 20.9484 8.67747 20.8479 8.43481C20.7474 8.19214 20.6001 7.97165 20.4143 7.78592C20.2286 7.6002 20.0081 7.45289 19.7654 7.3524C19.5228 7.25191 19.2627 7.20021 19 7.20026V7.20026ZM14.8915 16.3475L11.6493 17.9695L8.40787 16.3475V13.4691L11.6508 15.09L14.8929 13.4691L14.8915 16.3475ZM18.1479 19.0346H17.3371V12.1299L11.6511 14.9729L5.15217 11.7238L11.6511 8.47392L18.1479 11.7238V19.0346Z"
      fill="currentColor"
    />
  </svg>
);

export default ArchiveGraduationCap;
