import React from 'react';

import { Data as PersonData } from './Person';

import * as updating from '.';
import { renderComponent } from 'utils/components';

type Data = PersonData & {
  matricula: string;
  curso: string;
  nivelCurso: string;
  instituicao: string;

  lattes: string;
  areas: string;
  arquivos: { nome: string; id: number }[];
};

const Wrapper = ({ data }: { data: Data }) => {
  const { perfil } = data;

  const profiles: { [key: string]: React.ElementType<any> | undefined } = {
    pesquisador: updating.Researcher,
    aluno: updating.Student,
    colaborador: updating.Contributor,
  };
  return renderComponent(profiles[perfil], {
    data,
  });
};

export default Wrapper;
