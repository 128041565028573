import React, { useCallback, useState, useEffect, useMemo } from 'react';

import { MdCircle as Radio } from 'react-icons/md';

import { Row, ScrollPanel } from 'components/containers';
import { FieldSet, HiddenField, TextField, useDiff, useForm } from 'components/form';
import { IconButton } from 'components/elements';
import { XAlt } from 'components/icons';

import { renderComponent } from 'utils/components';
import { useTranslationX } from 'i18n';
import { ProposalDeliverables } from './Milestones';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';

import styles from './Milestones.module.scss';

type Props = {
  count: number;
  pathDeliverables: string;
  editable: boolean;
  milestoneIndex?: number;
  source?: ProposalDeliverables[];
  overlayType?: 'modified' | 'added' | 'removed' | 'unchanged';
  reference?: number | null;
};

const Deliverables = ({
  count,
  pathDeliverables,
  editable,
  milestoneIndex,
  source,
  reference,
}: Props) => {
  const { tx } = useTranslationX('macroentregas', 'proposal');

  const { tx: tx2 } = useTranslationX('components.tableData');

  const form = useForm();

  const [deliverables, setDeliverables] = useState(count);

  const [focusIndex, setFocusIndex] = useState<number | undefined>();

  const deliverablesKey = `macroentregas[${milestoneIndex}].entregaveis`;

  const data = form.getCollection(deliverablesKey);

  useEffect(() => {
    setDeliverables(count);
  }, [count]);

  const updateDeliverables = useCallback(
    (deliverable: ProposalDeliverables) => {
      const path = `${pathDeliverables}[${deliverable.ordem}]`;

      form.setFieldValue(`${path}.id`, deliverable.id, true);
      form.setFieldValue(`${path}.titulo`, deliverable.titulo, true);
    },
    [form, pathDeliverables]
  );

  const handleAddDeliverable = useCallback(() => {
    const payload = { titulo: tx('text-deliverable') };
    setFocusIndex(deliverables);
    form.addListItem(
      pathDeliverables,
      deliverables,
      (success, res) => {
        if (success && res) {
          const { id, ordem } = res.response as { id: number; ordem: number };

          updateDeliverables({ ...payload, id, ordem });
          setDeliverables(deliverable => deliverable + 1);
        }
      },
      payload
    );
  }, [tx, form, deliverables, updateDeliverables, pathDeliverables]);

  const handleDeleteDeliverables = useCallback(
    (indexDeliverable: number) => {
      form.removeListItem(pathDeliverables, indexDeliverable, success => {
        if (success) {
          setDeliverables(deliverable => deliverable - 1);
        }
      });
    },
    [form, pathDeliverables]
  );

  const { getDiff, getAloneRows } = useDiff(deliverablesKey);

  const removedRowsCandidates = getAloneRows();

  const removedRows = useMemo(() => {
    if (!reference) {
      return [];
    }
    return removedRowsCandidates;
  }, [reference, removedRowsCandidates]);

  const removedRowsRenderer = useMemo(() => {
    return removedRows.map(row => (
      <Tippy
        key={row.data.id}
        content={tx2(row.type)}
        className={styles.popup}
        animation="perspective"
        touch={false}
        followCursor="horizontal"
        plugins={[followCursor]}
      >
        <li className={styles.removed}>
          <div className={styles.icon}>{renderComponent(Radio)}</div>
          <Row>
            <div
              style={{
                pointerEvents: 'none',
              }}
            >
              <TextField
                name={`deliverables-${row.data.id}-titulo`}
                theme="transparent"
                omit
                value={row.data.titulo}
              />
            </div>
          </Row>
        </li>
      </Tippy>
    ));
  }, [removedRows, tx2]);

  if (!editable && !deliverables) {
    return null;
  }

  return (
    <>
      <span className={styles.title}>{tx('deliverables')}</span>

      <ScrollPanel
        style={{
          root: {
            height: (deliverables + removedRows?.length) * 48,
            maxHeight: '48%',
          },
        }}
      >
        <ul className={styles.deliverables}>
          {
            <>
              {Array.from(new Array(deliverables), (_, index) => {
                const section =
                  data?.['macroentregas']?.[milestoneIndex ?? 0]?.['entregaveis'];
                const sectionData = section?.[index];

                const rowReference =
                  data && section && sectionData ? sectionData?.['referencia'] : 0;
                const rowId = data && section && sectionData ? sectionData?.['id'] : 0;

                let rowDiff = getDiff(rowReference);
                if (!rowDiff) rowDiff = getDiff(rowId);

                const hasTituloDiff =
                  rowDiff && rowDiff.type === 'modified' && rowDiff.changes['titulo'];

                const rowType = rowDiff && rowDiff.type;

                const renderItem = () => {
                  return (
                    <li
                      onKeyPress={event => {
                        if (event.key === 'Enter') {
                          handleAddDeliverable();
                        }
                      }}
                      className={rowDiff ? styles[rowDiff.type] : ''}
                    >
                      <div className={styles.icon}>{renderComponent(Radio)}</div>
                      <Row>
                        <div
                          ref={ref => {
                            if (index === focusIndex) {
                              setFocusIndex(undefined);
                              ref?.querySelector('input')?.focus();
                            }
                          }}
                        >
                          <TextField
                            name="titulo"
                            theme="transparent"
                            overlay={hasTituloDiff ? 'modified' : undefined}
                            {...(!editable && { type: 'readonly' })}
                            value={source?.[index]?.titulo}
                          />
                        </div>
                      </Row>

                      {editable && (
                        <IconButton
                          icon={XAlt}
                          onClick={() => handleDeleteDeliverables(index)}
                          shape="circle"
                          type="danger"
                          rate={0.43}
                        />
                      )}
                    </li>
                  );
                };

                return (
                  <FieldSet name="entregaveis" index={index} key={index}>
                    <HiddenField omit name="id" />
                    <HiddenField omit name="ordem" />
                    <HiddenField omit name="referencia" />
                    {rowType === 'added' ? (
                      <Tippy
                        content={tx2(rowType)}
                        className={styles.popup}
                        animation="perspective"
                        touch={false}
                        followCursor="horizontal"
                        plugins={[followCursor]}
                      >
                        {renderItem()}
                      </Tippy>
                    ) : (
                      renderItem()
                    )}
                  </FieldSet>
                );
              })}
              {removedRowsRenderer}
            </>
          }
        </ul>
      </ScrollPanel>

      {editable && (
        <div className={styles.newDeliverable} onClick={handleAddDeliverable}>
          {tx('new-deliverable')}
        </div>
      )}
    </>
  );
};

export default Deliverables;
