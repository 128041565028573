import React from 'react';

import styles from './LegendItem.module.scss';

type Props = {
  label: string;
  color: string;
};

const LegendItem: React.FC<Props> = props => {
  const { label, color } = props;
  return (
    <div
      className={styles.legendItem}
      style={{
        ['--color' as any]: color,
      }}
    >
      <span>{label}</span>
    </div>
  );
};

export default LegendItem;
