import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useRequest } from 'apis';
import { Loader } from 'components/elements';
import { useTranslation } from 'i18n';
import { profileTransition, useAuthState } from 'store/auth';
import {
  initProfile,
  State as ProfileState,
  setDashboardConfig,
  useProfileState,
} from 'store/profile';

import ChangeProfile from '../ChangeProfile/ChangeProfile';
import Content from '../Content/Content';
import SideMenu from '../SideMenu/SideMenu';
import Topbar from '../Topbar/Topbar';
import { DashBoardConfig } from '../types';
import ProfileError from './ProfileError';
import DynamicHeaderProvider from 'components/containers/DynamicHeader/contexts/DynamicHeaderProvider';
import { getProfileConfig } from '../helpers';
import { Profile, RemappedProfile } from 'pages/register/profile';

import styles from './Dashboard.module.scss';

const Dashboard = () => {
  const authState = useAuthState();
  const request = useRequest();
  const dispatch = useDispatch();
  const history = useHistory();

  const { dashboardConfig } = useProfileState();

  const setConfig = useCallback(
    (data: DashBoardConfig | Error) => {
      dispatch(setDashboardConfig(data));
    },
    [dispatch]
  );

  const filterAndMountSideMenu = useCallback(
    (data: ProfileState) => {
      const remappedProfile = RemappedProfile[authState.profile!];
      const alwaysSeeProjectsMenu =
        remappedProfile === Profile.SUPERVISOR || remappedProfile === Profile.RESEARCHER;

      getProfileConfig(authState.profile).then(profileData => {
        const remmapedProfileData = profileData as DashBoardConfig;

        const hasManagedProjects = !!data?.managedProjects?.length;
        const filteredProfileData = {
          ...profileData,
          sideMenu:
            hasManagedProjects || alwaysSeeProjectsMenu
              ? remmapedProfileData.sideMenu
              : remmapedProfileData.sideMenu.filter(
                  item => item.key !== 'projetos' && authState.profile
                ),
        };
        setConfig(filteredProfileData);
      });
    },
    [authState.profile, setConfig]
  );

  useEffect(() => {
    if (authState.profile) {
      const source = request<ProfileState>({
        url: '/me',
        onSuccess: data => {
          dispatch(initProfile(data));
          dispatch(profileTransition());
          filterAndMountSideMenu(data);
          history.push('/dashboard');
        },
        onError: setConfig,
      });

      return () => source.cancel();
    }
  }, [dispatch, request, authState.profile, history, setConfig, filterAndMountSideMenu]);

  if (!dashboardConfig) {
    return <Loader size={60} />;
  }

  if (dashboardConfig instanceof Error) {
    return <ProfileError profile={authState.profile} />;
  }

  const finishedTransition = authState.transitionStatus !== 'loading';

  return (
    <div className={styles.dashboard}>
      {dashboardConfig.translations?.map(ns => (
        <TranslationFile key={ns} ns={ns} />
      ))}

      {finishedTransition ? (
        <DynamicHeaderProvider>
          <Topbar config={dashboardConfig} />

          <div className={styles.center}>
            <SideMenu config={dashboardConfig} />
            <Content config={dashboardConfig} />
          </div>
        </DynamicHeaderProvider>
      ) : null}

      <ChangeProfile />
    </div>
  );
};

const TranslationFile = ({ ns }: { ns: string }) => {
  useTranslation(ns);
  return null;
};

export default Dashboard;
