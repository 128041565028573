import { DashBoardConfig } from './types';

export const getProfileConfig = async (
  profile?: string | 'supervisor' | 'pesquisador'
): Promise<DashBoardConfig | Error> => {
  try {
    const data = (await import(`pages/${profile}/menu`)).default as DashBoardConfig;
    return data;
  } catch (error) {
    return new Error('Profile not found');
  }
};
