import React, { useState } from 'react';

import { useTranslationX } from 'i18n';
import { MenuItem } from 'pages/dashboard/types';
import { Tab, Tabs } from 'components/containers';
import { Form } from 'components/form';
import { Info } from 'components/icons';
import { renderComponent } from 'utils/components';

import { WrapperCard } from 'components/elements';
import { Milestones, Schedule } from '../';
import { SectionProps } from 'pages/project/update-wp/UpdateWpEditor';

import { useFetch } from 'apis';
import { MilestoneReduced } from 'pages/project/Sections/Milestones/types';

import styles from './MilestoneSection.module.scss';

const MilestoneSection = ({
  state: { id, editable, showErrors, tabErrors, forms, section },
  formProps,
}: SectionProps) => {
  const { tx } = useTranslationX('macroentregas.sections', 'update_wp');

  const [currTab, setCurrTab] = useState(0);

  const items: MenuItem[] = [
    { key: 'schedule', component: Schedule },
    { key: 'milestones', component: Milestones },
  ];

  const onChangeTab = (index: number) => {
    setCurrTab(index);
  };

  const createAdornment = (item: string) => {
    let adornment = <></>;

    if (tabErrors && tabErrors['metas-macro'] && tabErrors['metas-macro'][item]) {
      let className = !!tabErrors['metas-macro'][item].warnings
        ? styles.adornmentWarn
        : '';

      className = !!tabErrors['metas-macro'][item].errors
        ? styles.adornmentError
        : className;

      adornment = (
        <div className={className}>
          <Info />
        </div>
      );
    }

    return adornment;
  };

  const { data: milestones } = useFetch<MilestoneReduced[]>(
    `/projeto/${id}/macroentregas`
  );

  const disabledItems = milestones
    ?.map(milestone => {
      if (milestone.status === 'ACE' || milestone.status === 'ANS') {
        return milestone.id;
      }
      return null;
    })
    .filter(Boolean);

  return (
    <WrapperCard padding="5px 15px 15px" margin="0 0 1rem">
      <Form
        baseUrl={`/projeto/${id}/alteracao-pt/milestones`}
        name={formProps.name}
        hotReload
        {...formProps}
      >
        <Tabs selectedIndex={currTab} onChangeTab={onChangeTab}>
          {items.map(({ key, component }) => (
            <Tab
              title={tx(key)}
              key={key}
              adornment={showErrors ? createAdornment(key) : undefined}
            >
              {renderComponent(component, {
                editable,
                instance: section ? forms[section] : null,
                setCurrTab,
                disabledItems,
              })}
            </Tab>
          ))}
        </Tabs>
      </Form>
    </WrapperCard>
  );
};

export default MilestoneSection;
