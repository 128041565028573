import React from 'react';
import styles from './CircularProgress.module.scss';

type Props = {
  value: number;
  size?: number;
};

const CircularProgress = ({ value, size = 30 }: Props) => {
  value = Math.min(Math.max(value, 0), 100);

  const radius = 80;
  const circ = Math.PI * (radius * 2);
  const percent = ((100 - value) / 100) * circ;

  return (
    <div className={styles.circularProgress}>
      <svg width={size} viewBox="0 0 200 200" style={{ strokeDasharray: circ }}>
        <circle
          r={radius}
          cx="100"
          cy="100"
          shapeRendering="geometricPrecision"
          stroke="#696969"
        />
        <circle
          className={styles.bar}
          r={radius}
          cx="100"
          cy="100"
          style={{ strokeDashoffset: `${percent}px` }}
          stroke="url(#gradient)"
          strokeLinecap="round"
          shapeRendering="geometricPrecision"
        />
        <defs>
          <linearGradient
            id="gradient"
            x1="24"
            y1="0.5"
            x2="-0.821048"
            y2="9.24826"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0E8154" />
            <stop offset="1" stopColor="#3FC75C" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default CircularProgress;
