import React from 'react';

import { useTranslationX } from 'i18n';
import { Grid, Row } from 'components/containers';
import { PlainField } from 'components/elements';

type Data = {
  titulo: string;
  sigla: string;
  coordenador: string;
  modalidade: string;
  duracao: number;
};

const Proposal = ({ data }: { data: Data }) => {
  const { tx } = useTranslationX('identification', 'proposal');
  const { titulo, sigla, coordenador, duracao, modalidade } = data;

  return (
    <Grid spacing={0}>
      <Row width={[6, 4]} align="top">
        <PlainField label={tx('title')} value={titulo} />
        <PlainField label={tx('initials')} value={sigla} />
      </Row>

      <Row width={[6, 4]} align="top">
        <PlainField label={tx('manager')} value={coordenador} />
        <PlainField label={tx('duration')} value={`${duracao}`} />
      </Row>

      <PlainField label={tx('modality')} value={modalidade} />
    </Grid>
  );
};

export default Proposal;
