import React from 'react';

const Bars = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none">
    <path
      d="m2.67 7.33c-0.642 0-1.17-0.525-1.17-1.17 0-0.642 0.525-1.17 1.17-1.17h18.7c0.642 0 1.17 0.525 1.17 1.17 0 0.642-0.525 1.17-1.17 1.17zm0 5.83h18.7c0.642 0 1.17-0.525 1.17-1.17s-0.525-1.17-1.17-1.17h-18.7c-0.642 0-1.17 0.525-1.17 1.17s0.525 1.17 1.17 1.17zm0 5.83h18.7c0.642 0 1.17-0.525 1.17-1.17 0-0.642-0.525-1.17-1.17-1.17h-18.7c-0.642 0-1.17 0.525-1.17 1.17 0 0.642 0.525 1.17 1.17 1.17z"
      clipRule="evenodd"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default Bars;
