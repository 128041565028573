import React, { useReducer } from 'react';
import { PlannerHeader, WrapperCard } from 'components/elements';

import { isNotDeepEquals, sanitizeObject } from 'utils/object';
import { ActionFilter, StateFilter } from 'components/containers/TableList/types';
import { useTranslationX } from 'i18n';
import { ConclusionPlanner } from '.';
import { Form } from 'components/form';

import styles from './ConclusionPanel.module.scss';

const reducer = (state: StateFilter, action: ActionFilter): StateFilter => {
  switch (action.type) {
    case 'FILTER_UPDATE':
      if (isNotDeepEquals(state.filters, action.payload)) {
        return state;
      }
      return {
        ...state,
        status: 'update',
        filters: sanitizeObject({ ...state.filters, ...action.payload }),
      };

    case 'FILTER_RESET':
      return { ...state, status: 'clean', filters: {} };
    case 'UPDATE_SORT':
      return { ...state, status: 'update', sort: action.payload };
    case 'FINISH_UPDATE':
      return { ...state, status: 'idle' };

    default:
      return state;
  }
};

const ConclusionPanel = () => {
  const { tx } = useTranslationX('milestoneConclusionPanel', 'project');

  const [stateFilter, dispatchFilter] = useReducer(reducer, {
    status: 'init',
    filters: {},
    sort: 'name,asc',
  });

  return (
    <WrapperCard maxWidth="90%" minWidth="90%" padding="15px 20px 20px 0px">
      <div className={styles.conclusionPanel}>
        <Form>
          <PlannerHeader
            subtitle={tx('subtitle')}
            filter={{
              options: [
                {
                  value: '3',
                  text: tx('range.3'),
                },
                {
                  value: '6',
                  text: tx('range.6'),
                },
                {
                  value: '12',
                  text: tx('range.12'),
                },
              ],
              value: '12',
            }}
            legends={[
              {
                label: tx('status.upt'),
                color: '#37a64a',
              },
              {
                label: tx('status.tup'),
                color: '#BBEBC4',
              },
              {
                label: tx('status.atr'),
                color: '#E61616',
              },
              {
                label: tx('status.pla'),
                color: '#C4C4C4',
              },
            ]}
          />

          <ConclusionPlanner
            maxHeight="100%"
            dispatchFilter={dispatchFilter}
            stateFilter={stateFilter}
          />
        </Form>
      </div>
    </WrapperCard>
  );
};

export default ConclusionPanel;
