import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { useTranslation } from 'i18n';
import { RequestError } from 'apis';
import { login, LoginResponse } from 'store/auth';
import { Button } from 'components/elements';
import { Form, TextField, Password, SubmitButton } from 'components/form';
import { InfoDialog, Dialog } from 'components/containers';
import { classes } from 'utils/components';
import { FormInstance } from 'components/form/types';

import styles from './LoginForm.module.scss';

const LoginForm = () => {
  const [error, setError] = useState<RequestError<any>>();
  const [openDialog, setOpenDialog] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const onError = (error: RequestError<any>, instance: FormInstance) => {
    if (error.response?.status === 401) {
      instance.context.reset();
      setError(error);
      return 'handled';
    }
  };

  const onDone = (data: { responseBody?: LoginResponse }) => {
    if (data.responseBody) {
      dispatch(login(data.responseBody));
    }
  };

  return (
    <div className={styles.loginForm}>
      <Form baseUrl="/login" onError={onError} onDone={onDone}>
        <div className={styles.inputs}>
          <TextField
            name="username"
            label={t('login.username')}
            placeholder={t('login.usernamePlaceholder')}
          />
          <Password name="password" label={t('login.password')} />
        </div>

        <div className={classes(styles.link, styles.forgot)}>
          <Link to={{ pathname: '/login', state: { resetPassword: true } }}>
            <span>{t('login.forgot')}</span>
          </Link>
        </div>

        <div className={styles.buttons}>
          <SubmitButton>{t('login.button.submit')}</SubmitButton>
        </div>

        <div className={styles.link}>
          <span>{t('login.link.title1')}</span>

          <Link to={{ pathname: '/login', state: { getCredentials: true } }}>
            <span>{t('login.link.title2')}</span>
          </Link>
        </div>
      </Form>

      <InfoDialog
        visible={!!error}
        title={t('login.dialog.title')}
        buttonLabel={t('login.dialog.button')}
        message={t('login.dialog.message')}
        onClose={() => setError(undefined)}
      />

      <Dialog
        visible={openDialog}
        closeButton={true}
        title={t('login.internal.dialog.title')}
        message={t('login.internal.dialog.message')}
        width={480}
        onClose={() => setOpenDialog(false)}
        actions={
          <div style={{ display: 'flex', padding: '10px 20px' }}>
            <Button
              type="secondary"
              style={{ root: { marginRight: 20, width: 210 } }}
              onClick={() => history.push('/cadastro')}
            >
              {t('login.internal.dialog.cancel')}
            </Button>
            <Button
              style={{ root: { width: 210 } }}
              onClick={() => history.push('/cadastro/pesquisador')}
            >
              {t('login.internal.dialog.confirm')}
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default LoginForm;
