import React, { Fragment, useCallback, useRef, useState } from 'react';
import { followCursor } from 'tippy.js';
import { useTranslationX } from 'i18n';
import {
  FieldSet,
  HiddenField,
  TextField,
  useField,
  useForm,
  useFormObserver,
} from 'components/form';
import { OpenPadLock, Padlock, XCircle as Remove } from 'components/icons';
import { IconButton, Loader } from 'components/elements';

import { PaymentProps } from '../ScheduleTab/ScheduleTab';

import { MilestoneContribution, RangedMilestone } from 'pages/project/types';
import { classes } from 'utils/components';
import Tippy from '@tippyjs/react';

import { RowDiff } from 'components/form/useDiff';

import styles from './PaymentTable.module.scss';
import { readAttr } from 'utils/object';

type ContributionProps = {
  milestone: RangedMilestone;
  index: number;
};

type ContributionRowProps = ContributionProps & {
  overallIndex: number;
  blocked: boolean;
  numLocalRows: number;
  isFirstContribution: boolean;
  ghost?: boolean;
  diff?: RowDiff;
};

const PaymentTable = (props: PaymentProps) => {
  const { milestones, partIdx, removedRows, getDiff } = props;
  const form = useForm();

  const { name, value } = useField({
    name: 'aportes',
  });

  const [numRows, setNumRows] = useState<number>(value?.length || 0);

  const data = form.getCollection(name);

  const contributions = value as MilestoneContribution[];
  const { tx } = useTranslationX('update_wp:financial', ['translation', 'update_wp']);

  const isValidMilestones = milestones.find(milestone => milestone.range);

  useFormObserver(new RegExp(`${name}.*\\.*.blocked`));
  useFormObserver({ onSave: true });

  const handleAdd = useCallback(
    (milestoneIdx: number) => {
      const firstMilestoneMonth = milestones[milestoneIdx].range[0] + 1;
      const payload = {
        mesExecucao: firstMilestoneMonth,
        data: new Date().toISOString(),
      };

      form.addListItem(
        `aportes`,
        numRows,
        (success, res) => {
          if (success && res) {
            if (success) {
              setNumRows(c => c + 1);
              form.reload(true);
            }
          }
        },
        payload
      );
    },
    [form, milestones, numRows]
  );

  const handleDeleteSupport = useCallback(
    (index: number) => {
      form.removeListItem(`aportes`, index, success => {
        if (success) {
          if (success) {
            setNumRows(c => c - 1);
            form.reload(true);
          }
        }
      });
    },
    [form]
  );

  const handleLock = (index: number) => {
    form.setFieldValue(`aportes[${index}].blocked`, true, true);
  };

  const handleUnlock = (index: number) => {
    form.setFieldValue(`aportes[${index}].blocked`, false, true);
  };

  const isContributionIn = (
    contribution: MilestoneContribution,
    milestone: RangedMilestone,
    milestoneIdx: number
  ) => {
    return (
      ((contribution.mesExecucao || 1) > milestone.range[0] &&
        (contribution.mesExecucao || 1) <= milestone.range[1]) ||
      (milestoneIdx === numRows - 1 &&
        (contribution.mesExecucao || 1) > milestone.range[1])
    );
  };

  const getMilestonesContribution = (
    source: MilestoneContribution[],
    milestone: RangedMilestone,
    milestoneIdx: number,
    rows: number,
    sort: boolean = true
  ) => {
    const milestoneContributionsIdx = Array.from(new Array(rows))
      .map((_el, index) => {
        const contribution = source?.[index] as MilestoneContribution;
        if (!contribution) return index;
        return isContributionIn(contribution, milestone, milestoneIdx) ? index : -1;
      })
      .filter(v => v !== -1);
    if (sort) {
      return milestoneContributionsIdx.sort((a, b) => {
        const contributionA = source?.[a] || {};
        const contributionB = source?.[b] || {};

        const contributionAMonth = contributionA?.mesExecucao;
        const contributionBMonth = contributionB?.mesExecucao;

        return contributionAMonth - contributionBMonth;
      });
    }
    return milestoneContributionsIdx;
  };

  if (!isValidMilestones && !contributions) {
    return <Loader />;
  }

  const Contribution = ({ milestone, index: milestoneIdx }: ContributionProps) => {
    const milestoneContributionsIdx = getMilestonesContribution(
      data[name],
      milestone,
      milestoneIdx,
      numRows
    );

    const removedRowsData = removedRows.map(row => row.data) as MilestoneContribution[];

    const removedRowsIdx = getMilestonesContribution(
      removedRowsData,
      milestone,
      milestoneIdx,
      removedRowsData.length,
      false
    );

    const numLocalRows = milestoneContributionsIdx.length + removedRowsIdx.length;

    return (
      <>
        {milestoneContributionsIdx.map((overallIndex, index) => {
          let contribution = data[name]?.[overallIndex];

          const { blocked = false } = contribution || {};
          const isFirstContribution = index === 0;

          return (
            <ContributionRow
              key={index}
              blocked={blocked}
              isFirstContribution={isFirstContribution}
              index={milestoneIdx}
              overallIndex={overallIndex}
              numLocalRows={numLocalRows}
              milestone={milestone}
            />
          );
        })}
      </>
    );
  };

  const ContributionGhost = ({ milestone, index: milestoneIdx }: ContributionProps) => {
    const removedRowsData = removedRows.map(row => row.data) as MilestoneContribution[];

    const milestoneContributionsIdx = getMilestonesContribution(
      removedRowsData,
      milestone,
      milestoneIdx,
      removedRowsData.length
    );

    const numLocalRows = milestoneContributionsIdx.length;

    return (
      <>
        {milestoneContributionsIdx.map((overallIndex, index) => {
          const diff = removedRows[overallIndex];
          let contribution = removedRowsData?.[overallIndex];

          const { blocked = false } = contribution || {};
          const isFirstContribution = index === 0;

          return (
            <ContributionRow
              key={index}
              blocked={blocked}
              isFirstContribution={isFirstContribution}
              index={milestoneIdx}
              overallIndex={overallIndex}
              numLocalRows={numLocalRows}
              milestone={milestone}
              diff={diff}
              ghost
            />
          );
        })}
      </>
    );
  };

  const ContributionRow = (props: ContributionRowProps) => {
    const {
      overallIndex,
      isFirstContribution,
      index: milestoneIdx,
      numLocalRows,
      blocked,
      ghost = false,
      diff,
    } = props;
    const paymentIndex = overallIndex;

    const overlayRef = useRef<HTMLTableDataCellElement>(null);

    let month = {};
    let value = {};
    let rowDiff = diff;

    const populateValues: {
      [name: string]: number;
    } = {};

    if (!ghost) {
      const rowReference =
        data && data[name] && data[name][overallIndex]
          ? data[name][overallIndex]['referencia']
          : 0;

      const rowId =
        data && data[name] && data[name][overallIndex]
          ? data[name][overallIndex]['id']
          : 0;

      rowDiff = getDiff(rowReference);
      if (!rowDiff) rowDiff = getDiff(rowId);

      if (rowDiff && rowDiff.type === 'modified') {
        if (rowDiff.changes?.['partes']) {
          const partToValue = rowDiff.changes['partes'].to[partIdx]?.valor;
          const partFromValue = rowDiff.changes['partes'].from[partIdx]?.valor;
          if (partFromValue && partToValue && partFromValue !== partToValue) {
            value = {
              lastValue: partFromValue,
              overlay: 'modified',
            };
          }
        }
        if (rowDiff.changes?.['mesExecucao']) {
          const toMonth = rowDiff.changes['mesExecucao'].to;
          const fromMonth = rowDiff.changes['mesExecucao'].from;
          if (toMonth && fromMonth && toMonth !== fromMonth) {
            month = {
              lastValue: fromMonth,
              overlay: 'modified',
            };
          }
        }
      }
    }
    if (ghost && diff?.data) {
      populateValues['mesExecucao'] = readAttr(`mesExecucao`, diff.data);
      populateValues['valor'] = readAttr(`partes[${partIdx}].valor`, diff.data);
    }
    const isRemovedItem = diff?.type === 'removed';

    function toggleOverlay() {
      if (!isRemovedItem) {
        if (overlayRef.current) {
          overlayRef.current.style.pointerEvents = 'none';
          reactivateOverlay();
        }
      }
    }

    function reactivateOverlay() {
      setTimeout(() => {
        if (overlayRef.current) {
          overlayRef.current.style.pointerEvents = 'all';
        }
      }, 1000);
    }

    const hideRemoveIcon = blocked || isFirstContribution || ghost;

    return (
      <tr>
        <FieldSet name={name} index={paymentIndex} key={paymentIndex}>
          {!ghost && isFirstContribution ? (
            <>
              <td rowSpan={numLocalRows}>{milestoneIdx + 1}</td>
            </>
          ) : null}
          <td className={styles.mes}>
            <HiddenField name="id" omit />
            <HiddenField name="ordem" omit />
            <HiddenField name="referencia" omit />
            <HiddenField name="blocked" />
            <HiddenField name="mesMinimo" omit />
            <HiddenField name="mesMaximo" omit />
            <TextField
              align="center"
              omit={ghost}
              type={blocked ? 'disabled' : undefined}
              label={tx('payment.month')}
              name="mesExecucao"
              value={populateValues['mesExecucao']}
              theme="platinum"
              metadata={{
                rules: {
                  minWithExpression: 'mesMinimo',
                  maxWithExpression: 'mesMaximo',
                },
              }}
              {...month}
            />
          </td>
          <FieldSet name="partes" index={partIdx}>
            <HiddenField name="id" omit />
            <td>
              <TextField
                align="right"
                omit={ghost}
                type={blocked ? 'readonly' : undefined}
                label={tx('payment.month')}
                name="valor"
                theme="platinum"
                {...value}
                value={populateValues['valor']}
              />
            </td>
          </FieldSet>
          <td>
            {!ghost &&
              (blocked ? (
                <IconButton
                  icon={Padlock}
                  size={30}
                  rate={0.5}
                  shape="circle"
                  type="dark"
                  onClick={() => handleUnlock(paymentIndex)}
                  hint={tx('payment.unblock')}
                />
              ) : (
                <IconButton
                  icon={OpenPadLock}
                  size={24}
                  rate={0.5}
                  shape="circle"
                  type="dark"
                  onClick={() => handleLock(paymentIndex)}
                  hint={tx('payment.block')}
                />
              ))}
          </td>
          <td>
            {hideRemoveIcon ? null : (
              <IconButton
                icon={Remove}
                size={28}
                rate={0.5}
                shape="circle"
                type="danger"
                onClick={() => handleDeleteSupport(overallIndex)}
                hint={tx('payment.remove')}
              />
            )}
          </td>
          {isFirstContribution && !ghost ? (
            <>
              <td rowSpan={numLocalRows}>
                <div className={styles.addData} onClick={() => handleAdd(milestoneIdx)}>
                  {tx('payment.add')}
                </div>
              </td>
            </>
          ) : null}
        </FieldSet>
        {rowDiff && rowDiff.complete ? (
          <Tippy
            content={tx(rowDiff.type)}
            className={styles.popup}
            animation="perspective"
            touch={false}
            followCursor="horizontal"
            plugins={[followCursor]}
          >
            <td
              className={classes(styles.overlay, styles[rowDiff.type])}
              ref={overlayRef}
              onClick={toggleOverlay}
            />
          </Tippy>
        ) : null}
      </tr>
    );
  };

  return (
    <div>
      <div className={styles.table}>
        <h4>{tx('payment.title')}</h4>
        <table className={styles.financial}>
          <colgroup>
            <col style={{ width: '10%' }} />
            <col style={{ width: '25%' }} />
            <col style={{ width: '25%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '20%' }} />
          </colgroup>
          <thead>
            <tr>
              <th>{tx('payment.macro')}</th>
              <th>{tx('payment.month')}</th>
              <th className={styles.payTableHead}>{tx('payment.pay')}</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {milestones.map((milestone, index) => (
              <Fragment key={index}>
                <Contribution milestone={milestone} index={index} />
                {removedRows.length > 0 && (
                  <ContributionGhost milestone={milestone} index={index} />
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PaymentTable;
