import React, { ElementType, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useTranslationX } from 'i18n';
import { ConfirmDialog, ErrorDialog, TableList } from 'components/containers';
import { ProposalStatus } from './types';
import { useRequest } from 'apis';
import { ColumnConfig, FilterManagerConfig } from 'components/containers/TableList/types';
import {
  Archive,
  Copy,
  Padlock,
  Person,
  PlusCircle,
  Supervisor,
  Trash,
  Unarchive,
} from 'components/icons';
import { IconButton } from 'components/elements';
import { Instance } from 'components/containers/TableList/TableList';
type ActionType = 'arquivar' | 'desarquivar' | 'apagar' | 'duplicar' | 'criar';
type ActionConfig = { icon: ElementType; confirm?: boolean; open?: boolean };

type ActionExec = {
  type: ActionType;
  id: number;
  confirm?: boolean;
  titulo?: string;
  open?: boolean;
};

type Item = {
  id: number;
  sigla: string;
  modalidade: string;
  titulo: string;
  estado: string;
  valorFinanceiro: string;
  valorTotal: string;
  dataCriacao: Date;
  dataModificacao: Date;
  empresa: string;
  arquivada: boolean;
  coordenador?: string;
  acoes?: ActionType[];
};

type Props = {
  canCreate?: boolean;
  showManager?: boolean;
};

const colors: { [key in ProposalStatus]: string } = {
  EDT: '#FF6347',
  ANS: '#5F9EA0',
  RJA: '#712F26',
  RPV: '#FF2400',
  DEV: '#FF8295',
  ACC: '#0014A8',
  APE: '#F2B73F',
  APA: '#FF69B4',
  APR: '#44CF5C',
  ECT: '#EE82EE',
  CTD: '#00CCEE',
  PRJ: '#9932CC',
  FIN: '#6B8E23',
  AQV: '#ffcc66',
};

const actions: { [key in ActionType]: ActionConfig } = {
  arquivar: { icon: Archive },
  desarquivar: { icon: Unarchive },
  apagar: { icon: Trash, confirm: true },
  duplicar: { icon: Copy, open: true },
  criar: { icon: PlusCircle, open: true },
};

const ProposalListView = (props: Props) => {
  const [action, setAction] = useState<ActionExec>();
  const [error, setError] = useState<Error>();

  const { canCreate, showManager } = props;
  const { tx, t } = useTranslationX('list', 'proposal');

  const history = useHistory();
  const location = useLocation();
  const request = useRequest();

  const table = useRef<Instance>();

  useEffect(() => {
    if (action && !action.confirm) {
      const { id, type, open } = action;
      const create = type === 'criar';

      request<{ id: number }>({
        url: create ? '/proposta' : `/proposta/${id}/${type}`,
        method: create ? 'post' : 'put',
        onSuccess: res => {
          if (open) {
            history.push(`${location.pathname}/${res.id}`);
          } else {
            table.current?.resetAfterDelete();
            setAction(undefined);
          }
        },
        onError: err => {
          setAction(undefined);
          setError(err);
        },
      });
    }
  }, [action, history, location.pathname, request]);

  const filterConfig: FilterManagerConfig = [
    {
      field: 'researcherEdit',
      icon: Person,
      size: 24,
      label: tx('filters.researcherEdit'),
    },
    {
      field: 'supervisorReview',
      icon: Supervisor,
      size: 24,
      label: tx('filters.supervisorReview'),
    },
    {
      field: 'editionBlocked',
      icon: Padlock,
      label: tx('filters.editionBlocked'),
    },
    {
      field: 'onlyArchived',
      icon: Archive,
      label: tx('filters.onlyArchived'),
    },
  ];
  const config: ColumnConfig = [
    {
      title: tx('projectTitle'),
      field: 'titulo',
      weight: 1.8,
      type: 'html',
      align: 'center',
      search: true,
      parse: value => {
        let title =
          value === null
            ? tx('fields.none_verbose')
            : value.length >= 35
            ? `${value.substring(0, 35)}...`
            : value;
        return `<strong>${title}</strong>`;
      },
    },
    {
      title: tx('acronym'),
      field: 'sigla',
      align: 'center',
      weight: 1.0,
      search: true,
      parse: value =>
        value === null
          ? tx('fields.none_verbose_alt')
          : value.length >= 35
          ? `${value.substring(0, 35)}...`
          : value,
    },
    {
      title: tx('company'),
      field: 'empresa',
      align: 'center',
      search: true,
      weight: 1.6,
      parse: value =>
        value === null
          ? tx('fields.none_verbose')
          : value.length >= 35
          ? `${value.substring(0, 35)}...`
          : value,
    },
    {
      title: tx('createdAt'),
      field: 'dataCriacao',
      align: 'center',
      weight: 1.3,
      search: true,
      searchType: 'date',
      type: 'date',
      sortDir: 'desc',
    },
    {
      title: tx('updatedAt'),
      align: 'center',
      weight: 1.4,
      field: 'dataModificacao',
      search: true,
      searchType: 'date',
      type: 'date',
      sortDir: 'desc',
    },
    {
      title: tx('status'),
      field: 'estado',
      type: 'tag',
      weight: 1.5,
      search: true,
      metadata: {
        options: Object.keys(colors)
          .filter(key => !['AQV', 'PRJ', 'FIN'].includes(key))
          .map(status => {
            return {
              value: status,
              text: t(`status.${status}`),
            };
          }),
      },
      searchType: 'status',
      parse: (status, data: Item) => {
        const newStatus = !!data.arquivada ? 'AQV' : status;
        return {
          value: t(`status.${newStatus}`),
          bgColor: colors[newStatus as ProposalStatus],
        };
      },
    },
  ];

  if (showManager) {
    config.splice(3, 0, {
      title: tx('manager'),
      field: 'coordenador',
      search: true,
      weight: 1.5,
    });
  }

  const actionBuilder = ({ id, acoes, titulo }: Item) => {
    if (acoes) {
      const container = [];

      for (const type of acoes) {
        const actionConfig = actions[type];

        if (actionConfig) {
          const { icon, confirm, open } = actionConfig;

          container.push(
            <IconButton
              key={type}
              type={action ? 'disabled' : 'dark'}
              icon={icon}
              onClick={() =>
                setAction(action => action || { type, id, titulo, confirm, open })
              }
              hint={tx(`actions.${type}.hint`)}
            />
          );
        }
      }

      return container.length ? <div>{container}</div> : null;
    }

    return null;
  };

  return (
    <>
      <ConfirmDialog
        visible={!!action?.confirm}
        message={tx(`actions.${action?.type}.confirm`, { title: action?.titulo })}
        onSelect={value => {
          setAction(action =>
            value && action ? { ...action, confirm: false } : undefined
          );
        }}
      />

      {error && (
        <ErrorDialog
          message={tx('actions.error')}
          visible={!!error}
          onClose={() => setError(undefined)}
        />
      )}
      <TableList<Item>
        baseUrl="/propostas"
        listHeader={{
          title: tx('title'),
          subtitle: tx('subtitle'),
        }}
        addButton={{
          label: canCreate ? tx('add') : undefined,
          type: 'green',
        }}
        defaultSearchProps={{
          sort: 'dataModificacao,desc',
          filters: {},
        }}
        config={config}
        filterConfig={filterConfig}
        style={{
          root: {
            maxWidth: '100%',
            maxHeight: 'calc(100vh - 150px)',
          },
        }}
        textSize="small"
        theme="light"
        actionBuilder={actionBuilder}
        reference={table}
        editAction={({ id, titulo, sigla, estado }) => {
          if (id === undefined) {
            if (canCreate) {
              setAction(action => action || { type: 'criar', id, open: true });
            }
          } else {
            history.push(`${location.pathname}/${id}`, { titulo, sigla, estado });
          }
        }}
        keyComposer={(item, index) => `${item.id}-${index}`}
        editIcon={null}
        refreshesOn={95}
        pageSize={20}
        notFoundProps={{
          title: tx('error.not_found.title'),
          description: tx('error.not_found.description'),
        }}
        filter
        infinityScroll
      />
    </>
  );
};

export default ProposalListView;
