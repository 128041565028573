import React, { useMemo, useState } from 'react';
import Tippy from '@tippyjs/react';

import { followCursor } from 'tippy.js';
import arcDrawer from 'utils/arcDrawer';
import { Tag } from '..';

import styles from './PieGraph.module.scss';
import { useTranslationX } from 'i18n';

export type PieGraphData = {
  label: string;
  value: number;
  color: string;
  legendColor?: string;
  format?: (value: number) => string;
};
type Props = {
  data: PieGraphData[];
  graph: {
    size?: number;
    radius?: number;
    space?: number;
    reduction?: number;
  };
  legend?: {
    show: boolean;
    position?: 'top' | 'bottom' | 'left' | 'right';
    fontSize?: number;
    padding?: number | string | number;
    radius?: number;
    dotSize?: number | string;
  };
  title: {
    show?: boolean;
    text?: string;
    fontSize?: number;
    padding?: number | string;
  };

  onClickLegend?: (label: string, value: number) => void;
  onClickDonut?: (label: string, value: number) => void;
  onHoverDonut?: (label: string, value: number) => void;
};

const PieGraph: React.FC<Props> = props => {
  const { data, legend, graph, title } = props;

  const { size = 30, radius = 80, space = 0, reduction = 0.1 } = graph;

  const { show: showTitle = false, text: titleText = '' } = title;
  const {
    show = true,
    position = 'top',
    fontSize = 12,
    padding,
    dotSize,
    radius: legendRadius = 50,
  } = legend || {};

  const { tx } = useTranslationX('components', 'translation');

  const reductionValidated = useMemo(
    () => Math.max(0, Math.min(reduction, 0.2)),
    [reduction]
  );

  const sortedData = useMemo(() => {
    return data.sort((a, b) => b.value - a.value);
  }, [data]);

  const [selected, setSelected] = useState<number | undefined>();

  const calculatePercentage = (value: number) => {
    const total = data.reduce((acc, cur) => acc + cur.value, 0);
    return (value / total) * 100;
  };

  const calculateAngle = (percentage: number) => {
    return (percentage / 100) * 360;
  };

  const drawGraph = (
    index = 0,
    percentageAccumulator = 0,
    graphs: JSX.Element[] = []
  ): JSX.Element[] => {
    if (index >= sortedData.length) {
      return graphs;
    }
    const value = sortedData[index].value;
    const format = sortedData[index].format;
    if (value > 0) {
      const percentage = calculatePercentage(value);
      let graph = (
        <Tippy
          key={index}
          className={styles.popup}
          content={format ? format(value) : `${percentage.toFixed(2)}%`}
          animation="perspective"
          touch={false}
          followCursor="horizontal"
          plugins={[followCursor]}
        >
          <path
            key={index}
            className={selected === index ? styles.selected : ''}
            fill={data[index].color}
            d={arcDrawer(
              [100, 100],
              radius - index * reductionValidated * radius,
              percentageAccumulator + (percentage >= 100 ? 1 : space),
              calculateAngle(percentage) + percentageAccumulator,
              radius - index * reductionValidated * radius,
              0
            )}
            onClick={() => {
              if (props.onClickDonut) {
                props.onClickDonut(data[index].label, value);
              }
            }}
          />
        </Tippy>
      );
      graphs.push(graph);
    }

    return drawGraph(
      index + 1,
      calculateAngle(calculatePercentage(value)) + percentageAccumulator,
      graphs
    );
  };

  const isEmptyData = useMemo(() => {
    return data.reduce((acc, cur) => acc + Number(cur.value || 0), 0) === 0;
  }, [data]);

  return (
    <div
      className={styles.graph}
      style={{
        flexDirection: position === 'top' || position === 'bottom' ? 'column' : 'row',
        justifyContent:
          position === 'top' || position === 'bottom' ? 'center' : 'space-between',
        alignItems: position === 'top' || position === 'bottom' ? 'center' : 'flex-start',
      }}
    >
      {showTitle && (
        <h2
          className={styles.title}
          style={{
            fontSize: title.fontSize,
            padding: title.padding,
          }}
        >
          {titleText}
        </h2>
      )}

      <div className={styles.pieChart}>
        <svg
          className={isEmptyData ? styles.empty : ''}
          width={size}
          viewBox="0 0 200 200"
        >
          {isEmptyData ? (
            <text x="50%" y="50%" textAnchor="middle" alignmentBaseline="middle">
              {tx('pieGraph.noData')}
            </text>
          ) : (
            drawGraph()
          )}
        </svg>
      </div>
      {show && (
        <div className={styles.legend}>
          {data.map((item, index) => (
            <Tag
              key={index}
              onHover={() => {
                setSelected(index);
              }}
              onMouseLeave={() => {
                setSelected(undefined);
              }}
              radius={legendRadius}
              dotSize={dotSize}
              padding={padding}
              text={item.label}
              color={item.legendColor || item.color}
              fontSize={fontSize}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default PieGraph;
