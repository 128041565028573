import React, { ReactNode } from 'react';

import Hexagon from './Hexagon';
import styles from './HexagonGrid.module.scss';

type Props = {
  columns?: number;
  edge?: number;
  spacing?: number;
  onSelect: (value: number | string) => void;
  children?: ReactNode;
};

const HexagonGrid: React.FC<Props> = props => {
  const { columns = 3, edge = 80, spacing = 5, onSelect, children } = props;

  const rows: React.ReactNode[][] = [];
  const width = edge * 1.732;
  const height = edge * 2;

  React.Children.forEach(children, (child, index) => {
    if (React.isValidElement(child) && (child as React.ReactElement).type === Hexagon) {
      const row = Math.floor(index / columns);

      if (!rows[row]) {
        rows[row] = [];
      }

      rows[row].push(
        <div
          className={styles.hexagon}
          key={index}
          onClick={() => onSelect(child.props.value)}
        >
          {hexagon(width, height)}
          <div className={styles.content}>{child}</div>
        </div>
      );
    }
  });

  return (
    <div
      className={styles.hexagonGrid}
      style={{ ['--hex-spacing' as any]: `${spacing}px` }}
    >
      {rows.map((row, index) => (
        <div
          key={index}
          className={styles.row}
          style={{
            paddingLeft: index % 2 === 1 ? (width + spacing) / 2 : undefined,
            marginTop: index ? -edge / 2 + spacing : undefined,
          }}
        >
          {row}
        </div>
      ))}
    </div>
  );
};

function hexagon(width: number, height: number) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 173.20508075688772 200"
    >
      <path
        fill="currentColor"
        d="M81.4 3Q86.6 0 91.8 3L168 47Q173.2 50 173.2 56L173.2 144Q173.2 150 168 153L91.8 197Q86.6 200 81.4 197L5.196 153Q0 150 0 144L0 56Q0 50 5.196 47Z"
      ></path>
    </svg>
  );
}

export default HexagonGrid;
