import React from 'react';

import { useTranslationX } from 'i18n';
import { Grid, Row } from 'components/containers';
import { Checkbox, Form, RadioGroup, TextField } from 'components/form';
import { SectionProps } from '../ProposalEditor/ProposalEditor';
import CompanyList from './CompanyList';
import { Spacer, WrapperCard } from 'components/elements';

import styles from './Identification.module.scss';

const Identification = ({
  state: { id, showErrors, tabErrors },
  formProps,
}: SectionProps) => {
  const { tx } = useTranslationX('identification', 'proposal');

  return (
    <Form baseUrl={`/proposta/${id}/identification`} {...formProps}>
      <div className={styles.identification}>
        <Grid minWidth={640} maxWidth={900}>
          <WrapperCard padding="25px 30px" maxWidth="100%" width="900px">
            <Row width={[0.8, 0.2]}>
              <TextField name="titulo" label={tx('title')} placeholder={tx('title')} />
              <TextField
                name="sigla"
                label={tx('initials')}
                placeholder={tx('initials')}
                metadata={{
                  validationUrl: `/proposta/${id}/validate/identification`,
                  rules: { async: true },
                }}
              />
            </Row>

            <Row width={[0.4, 0.6]}>
              <TextField name="coordenador.nome" label={tx('manager')} type="readonly" />
              <TextField
                style={{
                  body: {
                    maxWidth: '100px',
                  },
                }}
                name="duracao"
                label={tx('duration')}
                placeholder="00"
                adornment={{ right: tx('months') }}
              />
            </Row>

            <RadioGroup
              name="modalidade"
              label={tx('modality')}
              columns={2}
              autosave={false}
            />
            <div className={styles.simulationCheck}>
              <Checkbox
                name="simulacao"
                label={
                  <span>
                    {tx('simulation.beforeBold')}
                    <strong>{tx('simulation.bold')}</strong>
                    {tx('simulation.afterBold')}
                  </span>
                }
              />
            </div>
          </WrapperCard>
          <Spacer length={23} />
          <CompanyList
            name="empresas"
            propostaId={id}
            showErrors={showErrors}
            tabsErrors={tabErrors}
          />
          <Spacer length={23} />
          <WrapperCard padding="0px" maxWidth="" width="956px">
            <div className={styles.radioGroup}>
              <RadioGroup name="subArea" label={tx('sub-area')} />
            </div>
          </WrapperCard>
          <Spacer length={23} />
          <WrapperCard padding="0px" maxWidth="" width="956px">
            <div className={styles.radioGroup}>
              <RadioGroup
                name="maturidade"
                label={tx('trl')}
                parseOption={op => `${op.value} - ${op.text}`}
              />
            </div>
          </WrapperCard>
        </Grid>
      </div>
    </Form>
  );
};

export default Identification;
