import React, { useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router';

import { Grid, Row, ScrollPanel, Tab, Tabs } from 'components/containers';
import {
  DatePicker,
  Form,
  SubmitButton,
  MonthYearPicker,
  Autocomplete,
  TextField,
  FormSection,
  FieldSet,
  HiddenField,
} from 'components/form';
import { Search } from 'components/icons';
import { Button, WrapperCard } from 'components/elements';
import { useTranslationX } from 'i18n';
import { useRequest } from 'apis';
import {
  FieldObserverResult,
  FormInstance,
  FormObserverResult,
} from 'components/form/types';
import { ModalidadeParte, Proposal } from 'pages/proposal/types';
import { BankDataForm, ContributionEditor } from '.';
import { toSQLTimestamp } from 'utils/calendarUtils';
import { messageService } from 'services';

import style from './CreateProject.module.scss';

type Errors = {
  message: {
    [key: string]: string;
  };
};

const CreateProject: React.FC = () => {
  const { tx } = useTranslationX('create', 'project');
  const form = React.createRef<FormInstance>();

  const [data, setData] = useState<Proposal>();
  const request = useRequest();
  const history = useHistory();

  const select = undefined;

  const computeDates = useCallback(
    (currentForm: FormInstance | null) => {
      type DateMonth = {
        year: number;
        month: number;
      };

      const value = form.current?.context.getFieldValue('mesInicio') as DateMonth;
      const initialDate = new Date(value.year, value.month - 1, 1);
      const auxDate = new Date(initialDate);
      const datesMacro:
        | {
            macroentregaId: number;
            init: DateMonth;
            delivery: string;
          }[]
        | undefined = data?.macroentregas.map(macroentrega => {
        let dateMacro: {
          macroentregaId: number;
          init: DateMonth;
          delivery: string;
        } = {
          macroentregaId: macroentrega.id,
          init: { year: auxDate.getFullYear(), month: auxDate.getMonth() + 1 },
          delivery: toSQLTimestamp(auxDate),
        };
        auxDate.setMonth(auxDate.getMonth() + macroentrega.duracao);
        let deliveryDate = new Date(auxDate);
        deliveryDate.setDate(deliveryDate.getDate() - 1);

        while (deliveryDate.getDay() === 6 || deliveryDate.getDay() === 0) {
          deliveryDate.setDate(deliveryDate.getDate() - 1);
        }

        dateMacro.delivery = toSQLTimestamp(deliveryDate);

        return dateMacro;
      });
      form.current?.context.setFieldValue('datesMacro', datesMacro);
      data?.macroentregas.forEach(macroentrega => {
        const aportes = macroentrega.aportes;
        data?.modalidade.partes.forEach(parte => {
          aportes.forEach((aporte, indexAportes) => {
            const newDate = new Date(initialDate);
            newDate.setMonth(newDate.getMonth() + aporte.mesExecucao - 1);
            newDate.setDate(5);
            while (newDate.getDay() === 6 || newDate.getDay() === 0) {
              newDate.setDate(newDate.getDate() + 1);
            }

            const mesInicio = datesMacro?.find(
              d => d.macroentregaId === macroentrega.id
            )?.init;
            const dataEntrega = datesMacro?.find(
              d => d.macroentregaId === macroentrega.id
            )?.delivery;

            currentForm?.context.setFieldValue(
              `aporteData.${
                parte.id
              }.${macroentrega.id.toString()}.aportes[${indexAportes}].mesInicio`,
              mesInicio
            );

            currentForm?.context.setFieldValue(
              `aporteData.${
                parte.id
              }.${macroentrega.id.toString()}.aportes[${indexAportes}].dataEntrega`,
              dataEntrega
            );

            currentForm?.context.setFieldValue(
              `aporteData.${
                parte.id
              }.${macroentrega.id.toString()}.aportes[${indexAportes}].dataAporte`,
              toSQLTimestamp(newDate)
            );
          });
        });
      });
    },
    [data, form]
  );

  useEffect(() => {
    if (form) {
      const currentForm = form?.current;
      const callback = (result: FormObserverResult) => {
        result = result as FieldObserverResult;

        if (!result.data?.value) {
          setData(undefined);
          return;
        }

        const filter = request<Proposal>({
          url: `propostas/${result.data?.value}`,
          onSuccess: data => {
            setData(data);
          },
        });

        currentForm?.context.clearSection('bankData');
        currentForm?.context.clearSection('aporteData');
        return () => filter.cancel();
      };

      currentForm?.observer.subscribe(callback, { field: 'proposal' });
      return () => currentForm?.observer.unsubscribe(callback);
    }
  }, [form, request]);

  useEffect(() => {
    if (form) {
      const currentForm = form?.current;
      const mesInicioCb = (result: FormObserverResult) => {
        result = result as FieldObserverResult;
        if (result.data?.value) {
          computeDates(currentForm);
        }
      };
      currentForm?.observer.subscribe(mesInicioCb, { field: 'mesInicio' });

      const dataPublicacaoCb = (result: FormObserverResult) => {
        result = result as FieldObserverResult;
        if (result.data?.value) {
          // Set planned end date automatically
          if (data && data.duracao && data.finalizacao) {
            const newDate = new Date(result.data?.value);

            // Date add months
            newDate.setMonth(newDate.getMonth() + data.duracao + data.finalizacao);
            currentForm?.context.setFieldValue(
              'dataFinalizacao',
              toSQLTimestamp(newDate)
            );
          }
        }
      };
      currentForm?.observer.subscribe(dataPublicacaoCb, {
        field: 'dataPublicacao',
      });
      return () => {
        currentForm?.observer.unsubscribe(mesInicioCb);
        currentForm?.observer.unsubscribe(dataPublicacaoCb);
      };
    }
  }, [form, data, computeDates]);

  function onCreateProject(data: {
    requestBody?: any;
    responseBody?: any;
    section?: string | undefined;
  }) {
    if (data.responseBody.status === 'failed') {
      const errorsObject = Object.values(
        data.responseBody?.rejected?.section?.errors
      ) as Errors[];
      errorsObject.forEach(error => {
        Object.values(error.message).forEach((message: string) => {
          messageService.error(tx(message), {
            duration: 4000,
          });
        });
        setTimeout(() => {
          history.replace('resumo');
        }, 4000);
      });
    }

    if (data.responseBody.status === 'success') {
      history.replace('resumo');
    }
  }

  return (
    <ScrollPanel>
      <WrapperCard minWidth="55vw">
        <h2 className={style.title}>{tx('title')}</h2>
        <Form onDone={onCreateProject} ref={form} baseUrl="/projetos/projeto">
          <HiddenField name="datesMacro" valid />
          <Grid maxWidth="500px">
            <Row width={[0.7, 0.3]}>
              <Autocomplete
                name="proposal"
                placeholder={tx('selectProposal.placeholder')}
                label={tx('selectProposal.label')}
                adornment={{ right: Search }}
                value={select}
                url="propostas"
                method="post"
                pageable={true}
                fieldQuery={['search']}
                preFilter={{ estado: ['ECT'] }}
                formatOption={option => option.titulo + ' - ' + option.sigla}
              />
              <HiddenField name="propostaId" />
            </Row>
          </Grid>
          <Grid maxWidth="650px">
            <Row width={[6, 3]} align="center">
              <TextField
                label={tx('identification')}
                name="srinfo"
                metadata={{
                  rules: {
                    maxlength: 10,
                    case: 'upper',
                  },
                }}
              />
              <DatePicker
                theme="classic"
                label={tx('boardApprovalDate')}
                name="dataAprovacaoConselho"
              />
            </Row>
          </Grid>

          <Grid maxWidth="650px">
            <Row>
              <MonthYearPicker
                metadata={{ rules: { required: true } }}
                label={tx('startMonth')}
                name="mesInicio"
                theme="classic"
              />
              <DatePicker
                theme="classic"
                label={tx('publicationDOUDate')}
                name="dataPublicacao"
              />
              <DatePicker
                theme="classic"
                label={tx('finalizationDOUDate')}
                name="dataFinalizacao"
              />
            </Row>
          </Grid>
          {data?.modalidade.partes ? <CompanyTabs data={data} /> : null}

          <div className={style.buttons}>
            <Button type="secondary" onClick={() => history.replace('resumo')}>
              {tx('cancel')}
            </Button>
            <SubmitButton style={{ root: { width: 195 } }}>
              {tx('createNewProject')}
            </SubmitButton>
          </div>
        </Form>
      </WrapperCard>
    </ScrollPanel>
  );
};

const CompanyTabs: React.FC<{ data: Proposal }> = ({ data }) => {
  return (
    <>
      <div className={style.tabs}>
        <FormSection name="aporteData">
          <FieldSet name="aporteData">
            <Tabs selectedIndex={0}>
              {data?.modalidade.partes.map((parte: ModalidadeParte) => {
                return (
                  <Tab title={parte.nome} key={parte.id}>
                    <ContributionEditor
                      macroentregas={data.macroentregas}
                      parte={parte}
                    />
                  </Tab>
                );
              })}
            </Tabs>
          </FieldSet>
        </FormSection>
      </div>

      <FormSection name="bankData">
        <FieldSet name="bankData">
          <BankDataForm partes={data?.modalidade.partes || []} />
        </FieldSet>
      </FormSection>
    </>
  );
};

export default CreateProject;
