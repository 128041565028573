import React from 'react';

import { Profile } from '../profile';
import { SectionProps } from '../types';
import Address from '../components/Address';

const AddressWrapper = ({ state }: SectionProps) => {
  const prefix = state.profile === Profile.COMPANY ? 'endereco' : 'pessoa.endereco';
  return <Address prefix={prefix} />;
};

export default AddressWrapper;
