import React from 'react';

import { useTranslationX } from 'i18n';

import { FieldSet, Form, HiddenField, TextEditor } from 'components/form';
import { ScrollPanel, Tab, Tabs } from 'components/containers';
import { SectionProps } from '../ProposalEditor/ProposalEditor';
import { Info } from 'components/icons';
import { WrapperCard } from 'components/elements';

import styles from './Description.module.scss';

const items = [
  'introducao',
  'escopo',
  'originalidade',
  'premissas',
  'restricoes',
  'riscos',
  'propriedadeIntelectual',
  'resumoPublico',
];

const Description = ({ state: { id, showErrors, tabErrors }, formProps }: SectionProps) => {
  const { tx } = useTranslationX('description', 'proposal');

  const createAdornment = (item: string) => {
    let adornment = <></>;

    if (tabErrors && tabErrors['descricao'] && tabErrors['descricao'][item]) {
      let className = !!tabErrors['descricao'][item].warnings ? styles.adornmentWarn : '';

      className = !!tabErrors['descricao'][item].errors
        ? styles.adornmentError
        : className;

      adornment = (
        <div className={className}>
          <Info />
        </div>
      );
    }

    return adornment;
  };

  return (
    <Form baseUrl={`/proposta/${id}/description`} {...formProps}>
      <WrapperCard padding="5px 15px 15px" maxWidth="80%" height="95%" responsive>
        <ScrollPanel>
          <FieldSet name="descritivo">
            <HiddenField name="id" />
            <Tabs selectedIndex={0}>
              {items.map(item => (
                <Tab
                  title={tx(`${item}`)}
                  key={item}
                  adornment={showErrors ? createAdornment(item) : undefined}
                >
                  <TextEditor name={item} />
                </Tab>
              ))}
            </Tabs>
          </FieldSet>
        </ScrollPanel>
      </WrapperCard>
    </Form>
  );
};

export default Description;
