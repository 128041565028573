import React from 'react';
import moment from 'moment';

import { useTranslationX } from 'i18n';
import { UserBadge } from 'components/elements';
import { classes } from 'utils/components';
import { NotificationData } from './types';

import styles from './Notification.module.scss';

type Props = {
  data: NotificationData;
  selected: boolean;
  onClick: () => void;
};

const Notification = ({ data, selected, onClick }: Props) => {
  const { tx } = useTranslationX('notification');

  return (
    <div
      className={classes(
        styles.notification,
        selected ? styles.selected : '',
        data.granted ? '' : styles.readonly
      )}
      onClick={onClick}
    >
      <table>
        <tbody>
          <tr>
            <td>
              <div className={styles.badge}>
                {!data.read && <span />}
                <UserBadge
                  name={
                    data.itemType
                      ? tx(`itemType.${data.itemType}`)
                      : data.title ?? data.type
                  }
                  size={26}
                />
              </div>
            </td>
            <td className={styles.title}>{data.requestor || data.title}</td>
          </tr>
          <tr>
            <td></td>
            <td className={styles.type}>
              {data.itemType ? tx(`itemType.${data.itemType}`) : tx(data.type)}
            </td>
          </tr>

          {data.subtitle && (
            <tr>
              <td></td>
              <td className={styles.subtitle}>{data.subtitle}</td>
            </tr>
          )}

          {data.createdAt && (
            <tr>
              <td></td>
              <td className={styles.date}>
                {moment(data.createdAt).format('DD/MM/YYYY')}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Notification;
