import React from 'react';

import { useTranslationX } from 'i18n';

import { FieldSet, Form, HiddenField, TextEditor } from 'components/form';
import { Tab, Tabs } from 'components/containers';

import { Info } from 'components/icons';

import { WrapperCard } from 'components/elements';
import { SectionProps } from 'pages/project/update-wp/UpdateWpEditor';
import { Profile } from 'pages/register/profile';
import { useHistoricDescription } from './useDescription';

import styles from './Description.module.scss';

const items = [
  'introducao',
  'escopo',
  'originalidade',
  'premissas',
  'restricoes',
  'riscos',
  'propriedadeIntelectual',
  'resumoPublico',
];

const Description = ({
  state: { id, showErrors, tabErrors, historicData, showDiff },
  profile,
  formProps,
  type,
}: SectionProps) => {
  const { tx } = useTranslationX('description', 'update_wp');

  const aditivo = type === 'aditivo';

  const createAdornment = (item: string) => {
    let adornment = <></>;

    if (tabErrors && tabErrors['descricao'] && tabErrors['descricao'][item]) {
      let className = !!tabErrors['descricao'][item].warnings ? styles.adornmentWarn : '';

      className = !!tabErrors['descricao'][item].errors
        ? styles.adornmentError
        : className;

      adornment = (
        <div className={className}>
          <Info />
        </div>
      );
    }

    return adornment;
  };

  const showHistoric = Profile.SUPERVISOR === profile;

  const { findSectionChanges, getSectionHistoric, getTextEditorType } =
    useHistoricDescription({ historicData, showHistoric });

  return (
    <Form baseUrl={`/projeto/${id}/alteracao-pt/description`} showDiff {...formProps}>
      <WrapperCard padding="5px 15px 15px" maxWidth="80%" height="95%" responsive>
        <FieldSet name="descritivo">
          <HiddenField name="id" />
          <Tabs selectedIndex={0}>
            {items.map(item => {
              return (
                <Tab
                  title={tx(`${item}`)}
                  key={item}
                  adornment={showErrors ? createAdornment(item) : undefined}
                  changed={showDiff && findSectionChanges(item)}
                >
                  <TextEditor
                    name={item}
                    type={getTextEditorType(item, aditivo)}
                    historic={getSectionHistoric(item)}
                  />
                </Tab>
              );
            })}
          </Tabs>
        </FieldSet>
      </WrapperCard>
    </Form>
  );
};

export default Description;
