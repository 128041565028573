import React, { ReactNode } from 'react';

import { classes } from 'utils/components';
import styles from './Message.module.scss';

type Props = {
  visible?: boolean;
  children?: ReactNode;
};

const Message: React.FC<Props> = ({ visible = true, children }) => {
  return (
    <div className={classes(styles.message, visible ? styles.visible : styles.hidden)}>
      <span>{children}</span>
    </div>
  );
};

export default Message;
