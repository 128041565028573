import { getSections, Profile } from './profile';
import { RegisterAction, RegisterState } from './types';

export const initialState: RegisterState = {
  status: 'select',
  step: 0,
  sections: [],
  availability: {},
  direction: 'next',
};

const reducer = (state: RegisterState, action: RegisterAction): RegisterState => {
  switch (action.type) {
    case 'INIT': {
      const profile = action.payload;

      return {
        ...initialState,
        profile,
        sections: getSections(profile).map(({ name }, index) => ({
          name,
          visited: !index,
        })),
        status: profile ? 'active' : 'select',
        ifce: profile === Profile.RESEARCHER,
      };
    }

    case 'PREVIOUS_SECTION':
      return { ...state, direction: 'previous', step: Math.max(0, state.step - 1) };

    case 'NEXT_SECTION': {
      const newState = { ...state };

      let step = state.step;
      newState.sections[step].done = !state.sections[step].locked;

      ++step;

      if (step < state.sections.length) {
        newState.sections[step].visited = true;
        newState.step = step;
      } else {
        newState.status = 'done';
      }

      return newState;
    }

    case 'INVALIDATE_SECTION': {
      const section = state.sections[action.payload];

      if (section) {
        section.done = false;
        return { ...state };
      }

      return state;
    }

    case 'AVAILABILITY': {
      const { status, value, person } = action.payload;

      if (
        status !== state.availability.status ||
        value !== state.availability.value ||
        person?.id !== state.availability.person?.id ||
        person?.initials !== state.availability.person?.initials
      ) {
        for (const section of state.sections) {
          if (section.name === 'address') {
            section.locked = !!state.availability.person;
            break;
          }
        }

        return { ...state, availability: { status, value, person } };
      }

      return state;
    }

    case 'BOUND':
      return { ...state, ifce: action.payload };

    default:
      return state;
  }
};

export default reducer;
