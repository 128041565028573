import React, { ReactNode } from 'react';

import { Loader } from 'components/elements';
import { useBuffer } from 'components/hooks';
import { classes } from 'utils/components';

import styles from './Data.module.scss';

type Props<T> = {
  data: T;
  loading: boolean;
  className?: string;
  showLoader?: boolean;
  children: (data: NonNullable<T>) => ReactNode;
};

const Data = function <T = any>(props: Props<T>) {
  const data = useBuffer(props.data, 400);
  const { loading, className, showLoader = true, children } = props;

  return (
    <div className={classes(styles.data, className, loading ? styles.loading : '')}>
      <div className={styles.wrapper}>{data && children(data as NonNullable<T>)}</div>

      {!data && showLoader && (
        <div className={styles.loader}>
          <Loader size={60} />
        </div>
      )}
    </div>
  );
};

export default Data;
