import React from 'react';

const Home = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none">
    <path
      d="M20.3813 10.8751L18.7503 9.24497V5.24988C18.7494 4.9518 18.6306 4.66618 18.4198 4.4554C18.209 4.24462 17.9234 4.12582 17.6253 4.12494H16.5004C16.2023 4.12582 15.9167 4.24462 15.7059 4.4554C15.4951 4.66618 15.3763 4.9518 15.3755 5.24988V5.87205L13.1256 3.62407C12.9924 3.44681 12.8233 3.29961 12.6294 3.19208C12.4355 3.08455 12.2211 3.0191 12.0002 3C11.7793 3.01922 11.565 3.08478 11.3711 3.19239C11.1773 3.3 11.0083 3.44725 10.8752 3.62454L3.61852 10.8751C3.44142 11.0079 3.29466 11.1769 3.18804 11.3709C3.08141 11.5648 3.01736 11.7793 3.00016 12C2.99765 12.1484 3.02503 12.2958 3.08068 12.4334C3.13632 12.5711 3.21909 12.6961 3.32405 12.801C3.42902 12.906 3.55403 12.9888 3.69165 13.0444C3.82927 13.1001 3.97668 13.1274 4.1251 13.1249H5.25004V19.8751C5.25092 20.1736 5.37005 20.4595 5.58134 20.6704C5.79263 20.8812 6.07886 20.9997 6.37736 21H9.75456V15.3753C9.75544 15.0772 9.87424 14.7916 10.085 14.5808C10.2958 14.37 10.5814 14.2512 10.8795 14.2504H13.1294C13.4275 14.2512 13.7131 14.37 13.9239 14.5808C14.1346 14.7916 14.2534 15.0772 14.2543 15.3753V21H17.6315C17.9296 20.9991 18.2152 20.8803 18.426 20.6695C18.6368 20.4588 18.7556 20.1731 18.7565 19.8751V13.1249H19.8814C20.0298 13.1274 20.1772 13.1001 20.3148 13.0444C20.4525 12.9888 20.5775 12.906 20.6824 12.801C20.7874 12.6961 20.8702 12.5711 20.9258 12.4334C20.9815 12.2958 21.0089 12.1484 21.0063 12C20.9883 11.7788 20.9233 11.564 20.8155 11.3699C20.7077 11.1759 20.5597 11.0072 20.3813 10.8751Z"
      fill="currentColor"
    />
  </svg>
);

export default Home;
