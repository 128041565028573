import React, { ReactNode, useEffect, useState } from 'react';

import { Checkbox } from 'components/elements';
import useField from '../Field/useField';
import { FieldType } from '../types';

type Props = {
  name: string;
  value?: any;
  label?: string | ReactNode;
  type?: FieldType;
  omit?: boolean;
  checkRequired?: boolean;
};

const CheckboxField: React.FC<Props> = props => {
  const { name, type, value, form, section, refreshType } = useField(props);
  const { omit, label, checkRequired } = props;

  const [selected, setSelected] = useState(value || false);

  useEffect(() => {
    form.updateFieldData(name, {
      value: selected,
      valid: !checkRequired || selected,
      section,
      omit,
      setValue: value => setSelected(value || false),
      refreshType,
      disabled: type === 'disabled',
    });
  }, [form, name, omit, section, selected, type, checkRequired, refreshType]);

  return (
    <Checkbox
      value={name}
      label={label}
      selected={selected}
      onChange={setSelected}
      type={type}
    />
  );
};

export default CheckboxField;
