import React from 'react';

const Padlock = ({ size = 1 }: { size?: number }) => (
  <svg
    width={`${size}em`}
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.25 5.50488H10.625V4.25488C10.625 2.52988 9.225 1.12988 7.5 1.12988C5.775 1.12988 4.375 2.52988 4.375 4.25488V5.50488H3.75C3.0625 5.50488 2.5 6.06738 2.5 6.75488V13.0049C2.5 13.6924 3.0625 14.2549 3.75 14.2549H11.25C11.9375 14.2549 12.5 13.6924 12.5 13.0049V6.75488C12.5 6.06738 11.9375 5.50488 11.25 5.50488ZM7.5 11.1299C6.8125 11.1299 6.25 10.5674 6.25 9.87988C6.25 9.19238 6.8125 8.62988 7.5 8.62988C8.1875 8.62988 8.75 9.19238 8.75 9.87988C8.75 10.5674 8.1875 11.1299 7.5 11.1299ZM9.4375 5.50488H5.5625V4.25488C5.5625 3.18613 6.43125 2.31738 7.5 2.31738C8.56875 2.31738 9.4375 3.18613 9.4375 4.25488V5.50488Z"
      fill="currentColor"
    />
  </svg>
);

export default Padlock;
