import React from 'react';
import { ProposalSummary } from '../SummaryAndActions';
import { SectionProps } from 'pages/proposal/ProposalEditor/ProposalEditor';

import Form from 'components/form/Form';
import { ApportionmentTable, PaymentTable } from '.';

import styles from './DisbursementSchedule.module.scss';

export type SectionPropsWithSupport = {
  data: ProposalSummary;
  partes?: ProposalSummary['partes'];
  cronograma?: ProposalSummary['cronograma'];
  updateKey: number;
  forceUpdate: () => void;
} & SectionProps;

const DisbursementSchedule: React.FC<SectionPropsWithSupport> = props => {
  const { formProps, updateKey, state } = props;

  const newForwardProps: SectionPropsWithSupport = {
    partes: props.data.partes,
    cronograma: props.data.cronograma,
    ...props,
  };

  return (
    <div className={styles.payment} key={updateKey}>
      <Form
        {...formProps}
        baseUrl={`/proposta/${state.id}/aporte`}
        name={`${formProps.name}.aporte`}
      >
        <PaymentTable {...newForwardProps} />
        <ApportionmentTable {...newForwardProps} />
      </Form>
    </div>
  );
};

export default DisbursementSchedule;
