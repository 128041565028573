import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';

import { useRequest } from 'apis';
import { useTranslationX } from 'i18n';
import { Info } from 'components/icons';
import { Dialog } from 'components/containers';
import {
  FieldObserverResult,
  FormInstance,
  FormObserverResult,
} from 'components/form/types';

import styles from './SectionHeader.module.scss';
import { useDynamicHeader } from 'components/containers/DynamicHeader';

type Item = {
  id: string;
  informativo: string;
};

type Props = {
  title?: string;
  acronym?: string;
  section?: string;
  form?: FormInstance | null;
  showInfoButton?: boolean;
};

const SectionHeader = (props: Props) => {
  const { section, form, showInfoButton = true } = props;

  const [showInfo, setShowInfo] = useState(false);
  const [info, setInfo] = useState<Item>();

  const [title, setTitle] = useState(props.title);
  const [acronym, setAcronym] = useState(props.acronym);

  const request = useRequest();

  const { setFooter } = useDynamicHeader();

  const { tx } = useTranslationX('editor', 'proposal');

  useEffect(() => {
    if (section) {
      const source = request<Item>({
        url: `/proposta/${section}/informativo`,
        onSuccess: setInfo,
      });

      return () => source.cancel();
    }
  }, [request, section]);

  useEffect(() => {
    setAcronym(props.acronym);
  }, [props.acronym]);

  useEffect(() => {
    setTitle(props.title);
  }, [props.title]);

  useEffect(() => {
    setFooter(tx('footer', { title, sigla: acronym }));
  }, [title, acronym, tx, setFooter]);

  useEffect(() => {
    if (form) {
      setTitle((form.context.getFieldValue('titulo') as string) || title);
      setAcronym((form.context.getFieldValue('sigla') as string) || acronym);

      const acronymCallback = (data: FormObserverResult) =>
        setAcronym((data as FieldObserverResult).data?.value);

      form.observer.subscribe(acronymCallback, 'sigla');

      const titleCallback = (data: FormObserverResult) =>
        setTitle((data as FieldObserverResult).data?.value);

      form.observer.subscribe(titleCallback, 'titulo');

      return () => {
        form.observer.unsubscribe(acronymCallback);
        form.observer.unsubscribe(titleCallback);
      };
    }
  }, [acronym, form, setFooter, title]);

  return (
    <>
      {showInfoButton && section !== 'divisao-orcamento' && (
        <div className={styles.header}>
          <div className={styles.info} onClick={() => setShowInfo(true)}>
            <div>
              <div className={styles.icon}>
                <Info />
              </div>
              <span>{tx('info')}</span>
            </div>
          </div>
        </div>
      )}

      <Dialog
        visible={showInfo}
        onClose={() => setShowInfo(false)}
        closeButton={true}
        titleAlign="left"
        title={section ? tx(`sections.${section}`, { context: 'long' }) : ''}
      >
        {
          <div className={styles.infoText}>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(info?.informativo || ''),
              }}
            />
          </div>
        }
      </Dialog>
    </>
  );
};

export default SectionHeader;
