import React, { useCallback, useEffect, useState } from 'react';

import { useTranslationX } from 'i18n';
import {
  FieldSet,
  HiddenField,
  TextField,
  useField,
  useForm,
  useFormObserver,
} from 'components/form';
import {
  Padlock,
  PlusCircle as Add,
  XCircle as Remove,
  OpenPadLock,
  Replay,
} from 'components/icons';
import { IconButton, Loader } from 'components/elements';
import { RequestConfig, useRequest } from 'apis';

import { SectionPropsWithSupport } from '../../DisbursementSchedule';
import { MilestoneWithSupport } from '../ApportionmentTable/ApportionmentTable';
import { useFirstRender } from 'components/hooks';

import styles from './PaymentTable.module.scss';

export type ProposalSupportPart = {
  id: number;
  valor: number;
  partId: number;
  partNome: string;
  ordem: number;
};

type ContributionRowProps = ContributionProps & {
  blocked: boolean;
  numLocalRows: number;
  isFirstContribution: boolean;
  milestoneIdx: number;
};

type ContributionProps = {
  milestone: MilestoneWithSupport;
  index: number;
};

const PaymentTable = (props: SectionPropsWithSupport) => {
  const { partes = [], state } = props;
  const { id } = state;

  const { tx } = useTranslationX('proposal:financial', ['translation', 'proposal']);

  const form = useForm();
  const request = useRequest();
  const firstRender = useFirstRender();

  const editable = form.editable;

  const { name, value } = useField({
    name: 'macroentregas',
  });

  const [, setNumRows] = useState<number>(value?.length || 0);
  const [aportesSize, setAportesSize] = useState<{
    milestoneId: number;
    size: number;
  }>();

  useFormObserver(new RegExp(`${name}.*\\.aportes.*\\.blocked`));
  useFormObserver({ onSave: true });

  useEffect(() => {
    if (!firstRender) {
      form.notifySave();
    }
  }, [form, firstRender]);

  const milestones = value as MilestoneWithSupport[];

  const handleAdd = useCallback(
    (index: number, milestoneIdx: number) => {
      const firstMilestoneMonth =
        milestones[milestoneIdx].aportes[index - 1]?.mesExecucao;
      const payload = {
        mesExecucao: firstMilestoneMonth || 1,
      };

      form.addListItem(
        `macroentregas[${milestoneIdx}].aportes`,
        index,
        (success, res) => {
          if (success && res) {
            setNumRows(c => c + 1);
            form.stealthReload();
            setAportesSize({
              milestoneId: milestoneIdx,
              size: milestones[milestoneIdx].aportes.length + 1,
            });
          }
        },
        payload
      );
    },
    [form, milestones]
  );

  useEffect(() => {
    if (!aportesSize?.size || !aportesSize?.milestoneId) return;

    if (aportesSize.milestoneId && aportesSize.size) {
      const currentMilestone = milestones[aportesSize?.milestoneId as number];

      currentMilestone.aportes.slice(aportesSize.size).forEach((item, index) => {
        form.clearField(
          `macroentregas[${aportesSize?.milestoneId}].aportes[${
            index + (aportesSize?.size as number)
          }]`
        );
      });
    }
  }, [aportesSize, form, milestones]);

  const handleDelete = useCallback(
    (index: number, milestoneIdx: number) => {
      form.removeListItem(`macroentregas[${index}].aportes`, milestoneIdx, success => {
        if (success) {
          setNumRows(c => c - 1);
          form.stealthReload();
        }
      });
    },
    [form]
  );

  const handleLock = (milestoneIdx: number, ordem: number) => {
    form.setFieldValue(
      `macroentregas[${milestoneIdx}].aportes[${ordem}].blocked`,
      true,
      true
    );
  };
  const handleUnlock = (milestoneIdx: number, ordem: number) => {
    form.setFieldValue(
      `macroentregas[${milestoneIdx}].aportes[${ordem}].blocked`,
      false,
      true
    );
  };

  const restore = useCallback(() => {
    const options: RequestConfig<any> = {
      url: `proposta/${id}/aporte/clean`,
      onSuccess: res => {
        if (res.length > 0 && res[0].status === 'success') {
          form.reload();
          form.notifySave();
        }
      },
      onError: console.log,
      method: 'POST',
    };
    request<any>(options);
  }, [form, id, request]);

  const ContributionRow = (props: ContributionRowProps) => {
    const { isFirstContribution, milestoneIdx, index, numLocalRows, blocked } = props;

    return (
      <tr>
        <FieldSet name="aportes" index={index}>
          <td className={styles.mes}>
            <HiddenField name="id" omit />
            <HiddenField name="ordem" omit />
            <HiddenField name="blocked" omit />
            <TextField
              align="center"
              type={blocked ? 'disabled' : undefined}
              label={tx('payment.month')}
              name="mesExecucao"
              theme="platinum"
            />
          </td>
          {partes.slice(0, -1).map((_, index) => (
            <FieldSet name="partes" index={index} key={index}>
              <HiddenField name="id" omit />
              <td>
                <TextField
                  align="center"
                  type={blocked ? 'readonly' : undefined}
                  label={tx('payment.month')}
                  name="valor"
                  theme="platinum"
                />
              </td>
            </FieldSet>
          ))}
          <td>
            {editable ? (
              blocked ? (
                <IconButton
                  icon={Padlock}
                  size={30}
                  rate={0.5}
                  shape="circle"
                  type="dark"
                  onClick={() => handleUnlock(milestoneIdx, index)}
                  hint={tx('payment.unblock')}
                />
              ) : (
                <IconButton
                  icon={OpenPadLock}
                  size={24}
                  rate={0.5}
                  shape="circle"
                  type="dark"
                  onClick={() => handleLock(milestoneIdx, index)}
                  hint={tx('payment.block')}
                />
              )
            ) : null}
          </td>
          <td>
            {editable ? (
              blocked || index === 0 ? null : (
                <IconButton
                  icon={Remove}
                  size={28}
                  rate={0.5}
                  shape="circle"
                  type="danger"
                  onClick={() => handleDelete(milestoneIdx, index)}
                  hint={tx('payment.remove')}
                />
              )
            ) : null}
          </td>
          {isFirstContribution ? (
            <td rowSpan={numLocalRows}>
              {editable ? (
                <IconButton
                  icon={Add}
                  size={24}
                  rate={0.5}
                  shape="circle"
                  onClick={() => handleAdd(numLocalRows, milestoneIdx)}
                  hint={tx('payment.add')}
                />
              ) : null}
            </td>
          ) : null}
        </FieldSet>
      </tr>
    );
  };

  const Contribution = ({ milestone, index: milestoneIdx }: ContributionProps) => {
    const orderedContributions = milestone.aportes.sort((a, b) => a.ordem - b.ordem);
    const contributionsLength = orderedContributions.length;

    return (
      <FieldSet name="macroentregas" index={milestoneIdx}>
        <HiddenField name="id" />
        {orderedContributions.map((contribution, index) => {
          const { blocked = false } = contribution || {};
          const isFirstContribution = index === 0;

          return (
            <ContributionRow
              key={index}
              blocked={blocked}
              isFirstContribution={isFirstContribution}
              index={index}
              milestoneIdx={milestoneIdx}
              numLocalRows={contributionsLength}
              milestone={milestone}
            />
          );
        })}
      </FieldSet>
    );
  };

  if (!milestones) return <Loader />;

  return (
    <div className={styles.financial}>
      <div className={styles.table}>
        <div className={styles.headers}>
          <h4>{tx('payment.title')}</h4>
          {editable ? (
            <div className={styles.restore}>
              <IconButton
                size={25}
                type="dark"
                shape="square"
                icon={Replay}
                onClick={restore}
              >
                <div className={styles.label}>{tx('payment.restore')}</div>
              </IconButton>
            </div>
          ) : null}
        </div>

        <table className={styles.financial}>
          <thead>
            <tr>
              <th>{tx('payment.macro')}</th>
              {partes.slice(0, -1).map(p => (
                <th key={p}>{p}</th>
              ))}
              <th />
            </tr>
          </thead>
          <tbody>
            {milestones.map((milestone, index) => {
              return <Contribution key={index} milestone={milestone} index={index} />;
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PaymentTable;
