import React from 'react';

import Field, { Props as FieldProps } from '../Field/Field';
import Input, { Props as InputProps } from 'components/inputs/Text/TextInput';

const TextField: React.FC<FieldProps & InputProps> = props => (
  <Field {...props} component={Input} />
);

export default TextField;
