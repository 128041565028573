import { useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';

type Props<P, S> = {
  children: (data: P & S) => JSX.Element;
};

const RouteData = function <P = any, S = any>({ children }: Props<P, S>) {
  const params = useParams<P>();
  const location = useLocation<S>();

  const data = useRef({ ...params, ...location.state });

  if (data.current !== undefined) {
    data.current = {
      ...data.current,
      ...params,
      ...location.state,
    };
  }

  const cleanRef = () => {
    data.current = {
      ...params,
      ...location.state,
    };
  };

  return children({
    ...data.current,
    cleanRef,
  });
};

export default RouteData;
