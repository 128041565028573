import React, { ReactNode, useEffect, useRef } from 'react';

import { Transition } from 'react-transition-group';
import { getScrollingParent } from 'utils/components';
import { useFirstRender, useResizeObserver } from 'components/hooks';

import styles from './Collapse.module.scss';

type Props = {
  visible: boolean;
  appear?: boolean;
  fixedHeight?: string;
  children?: ReactNode;
  ignoreContentInsets?: boolean;
};

const Collapse: React.FC<Props> = ({
  visible,
  appear,
  fixedHeight,
  children,
  ignoreContentInsets,
}) => {
  const firstRender = useFirstRender();

  const parent = useRef<HTMLDivElement>(null);
  const child = useRef<HTMLDivElement>(null);

  useResizeObserver({
    ref: child,
    onResize(size) {
      const { height } = size;
      if (parent.current) {
        const childHeight = child.current?.style.height || 0;
        if (height !== childHeight && !firstRender && visible) {
          parent.current.style.height = `${height}px`;
        }
      }
    },
  });

  useEffect(() => {
    if (
      visible &&
      child.current &&
      parent.current &&
      child.current.scrollHeight &&
      parent.current.offsetHeight !== child.current.scrollHeight
    ) {
      parent.current.style.height = `${child.current.scrollHeight}px`;
    }
  });

  useEffect(() => {
    const scrolContainer = getScrollingParent(parent.current);

    if (scrolContainer) {
      let timer: NodeJS.Timeout;

      const refresh = (count: number) =>
        setTimeout(() => {
          scrolContainer.dispatchEvent(new CustomEvent('scroll'));

          if (count < 10) {
            timer = refresh(count + 1);
          }
        }, 30);

      timer = refresh(0);

      return () => clearTimeout(timer);
    }
  }, [visible]);

  return (
    <Transition
      in={visible}
      timeout={300}
      appear
      onEnter={(node: HTMLElement) =>
        (node.style.height = !firstRender || appear ? '0px' : 'auto')
      }
      onEntering={(node: HTMLElement) =>
        (node.style.height = fixedHeight ?? `${node.scrollHeight}`)
      }
      onExiting={node => (node.style.height = '0px')}
    >
      <div className={styles.collapse} ref={parent}>
        <div
          ref={child}
          style={
            !ignoreContentInsets
              ? {
                  width: `fit-content`,
                  height: `fit-content`,
                }
              : {}
          }
        >
          {children}
        </div>
      </div>
    </Transition>
  );
};

export default Collapse;
