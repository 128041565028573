import React, { useCallback, useEffect, useState } from 'react';

import { Loader, Spacer } from 'components/elements';
import { ScrollPanel, Tab, Tabs } from 'components/containers';
import { PartData } from 'pages/project/types';
import { PaymentPanel, RolesList } from 'pages/project';
import { useFetch, useRequest } from 'apis';
import { useProjectEditor } from 'pages/project/ProjectEditor/contexts';
import { ListPeople } from '.';
import { PaymentContracts } from './PaymentPanel/types';

const Dashboard = () => {
  const { state } = useProjectEditor();
  const request = useRequest();
  const [paymentsContracts, setPaymentsContracts] = useState<PaymentContracts>(
    {} as PaymentContracts
  );
  const { data: area } = useFetch<PartData[]>(
    `/projeto/${state.id}/execucao/${state.section}`
  );

  const reload = useCallback(() => {
    request<PaymentContracts>({
      url: `/projeto/${state.id}/${state.section}/pagamentos`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      onSuccess: (data: PaymentContracts) => {
        setPaymentsContracts(data);
      },
      onError: data => console.error(data),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reload();
  }, [reload]);

  if (!area) {
    return <Loader type="spin" />;
  }

  return (
    <Tabs selectedIndex={0} type="floating">
      {area.map((item: PartData, indexArea: number) => {
        return (
          <Tab title={item.nome} key={indexArea}>
            <ScrollPanel vBar={{ overlay: true }}>
              <Spacer direction="vertical" length={20} />
              <RolesList item={item} />
              {!!item.ucs.length && <Spacer direction="vertical" length={40} />}
              <PaymentPanel fonte={item.nome} data={paymentsContracts} reload={reload} />
              <Spacer direction="vertical" length={40} />
              <ListPeople />
              <Spacer direction="vertical" length={40} />
            </ScrollPanel>
          </Tab>
        );
      })}
    </Tabs>
  );
};

export default Dashboard;
