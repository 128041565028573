import React, { FC, useCallback, useEffect, useState } from 'react';

import { useTranslationX } from 'i18n';

import { useRequest } from 'apis';
import { Modal } from 'components/containers';
import { FileUploader } from 'components/elements';

type ImageUploadModalProps = {
  visible: boolean;
  onChange: (url: string) => void;
  onClose: () => void;
};

type UploadResponse = {
  error: number;
  payload: {
    id: string;
    url: string;
  };
};

const ImageUploadModal: FC<ImageUploadModalProps> = ({ visible, onChange, onClose }) => {
  const { tx } = useTranslationX('components', 'translation');
  const [isVisible, setIsVisible] = useState(visible);
  const request = useRequest();

  const onChangeFile = useCallback(
    (files: File[]) => {
      function handleUpload(response: UploadResponse) {
        onChange(`http://localhost:7755${response.payload.url}`);
      }

      function uploadImage(files: File[]) {
        if (files.length === 0) return;

        const form = new FormData();
        form.set('file', files[0]);
        request<UploadResponse>({
          method: 'POST',
          data: form,
          url: '/arquivo',
          onSuccess: handleUpload,
        });
      }

      uploadImage(files);
    },
    [onChange, request]
  );

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  return (
    <Modal
      visible={isVisible}
      onBlur={() => {
        setIsVisible(false);
        onClose();
      }}
    >
      <div style={{ padding: '2rem' }}>
        <FileUploader
          type="sneaky"
          message={tx('richEditor.image-upload')}
          metadata={{
            rules: {
              maxfiles: 1,
            },
          }}
          onChange={onChangeFile}
        />
      </div>
    </Modal>
  );
};

export default ImageUploadModal;
