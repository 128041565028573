import React from 'react';

import { ScrollPanel } from 'components/containers';
import * as icons from 'components/icons';

const IconGallery = () => {
  const rows = [];

  for (const key in icons) {
    const Icon = (icons as any)[key];

    rows.push(
      <tr key={key}>
        <td>{key}</td>
        <td>
          <Icon size={4} />
        </td>
      </tr>
    );
  }

  return (
    <div
      style={{
        width: '80%',
        height: '80%',
        display: 'flex',
        margin: 'auto',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div></div>
      <ScrollPanel>
        <table>
          <tbody>{rows}</tbody>
        </table>
      </ScrollPanel>
    </div>
  );
};

export default IconGallery;
