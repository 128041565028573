import React from 'react';

export const Eye = ({ size = 1 }: { size?: number }) => (
  <svg
    width={`${size}em`}
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99999 0.75C5.83333 0.75 2.27499 3.34167 0.833328 7C2.27499 10.6583 5.83333 13.25 9.99999 13.25C14.1667 13.25 17.725 10.6583 19.1667 7C17.725 3.34167 14.1667 0.75 9.99999 0.75ZM9.99999 11.1667C7.7 11.1667 5.83333 9.3 5.83333 7C5.83333 4.7 7.7 2.83333 9.99999 2.83333C12.3 2.83333 14.1667 4.7 14.1667 7C14.1667 9.3 12.3 11.1667 9.99999 11.1667ZM7.49999 7C7.49999 5.61667 8.61666 4.5 9.99999 4.5C11.3833 4.5 12.5 5.61667 12.5 7C12.5 8.38333 11.3833 9.5 9.99999 9.5C8.61666 9.5 7.49999 8.38333 7.49999 7Z"
      fill="currentColor"
    />
  </svg>
);

export const EyeSlash = ({ size = 1 }: { size?: number }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={`${size}em`} viewBox="0 0 15 11">
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor" fillRule="nonzero">
        <path
          d="M7.63672 1C1.80796 1 0.246131 4.9858 0.17741 5.15447C0.123155 5.29735 0.123155 5.45516 0.17741 5.59803C0.246131 5.76046 1.80796 9.74626 7.63672 9.74626C13.4655 9.74626 15.0273 5.76046 15.096 5.59179C15.1503 5.44891 15.1503 5.2911 15.096 5.14823C15.0273 4.9858 13.4655 1 7.63672 1ZM7.63672 8.49679C7.01892 8.49679 6.41499 8.31359 5.9013 7.97036C5.38762 7.62713 4.98725 7.13928 4.75083 6.5685C4.51441 5.99773 4.45255 5.36966 4.57308 4.76373C4.6936 4.1578 4.9911 3.60122 5.42796 3.16437C5.86481 2.72751 6.42139 2.43001 7.02732 2.30949C7.63325 2.18896 8.26132 2.25082 8.83209 2.48724C9.40287 2.72366 9.89072 3.12403 10.234 3.63771C10.5772 4.1514 10.7604 4.75533 10.7604 5.37313C10.7604 6.20158 10.4313 6.99609 9.84548 7.58189C9.25968 8.16769 8.46517 8.49679 7.63672 8.49679Z"
          transform="translate(-563 -507) translate(81 153) translate(0 6) translate(2 297) translate(19 48) translate(461 3)"
        />
        <path
          d="M2.67955 9.8458L7.71737 5.42297L12.7552 1.00013"
          stroke="#E61616"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);
