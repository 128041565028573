/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';

import { uniqueId } from 'lodash';

import { useTranslationX } from 'i18n';
import { MenuItem } from 'pages/dashboard/types';
import { Tab, Tabs } from 'components/containers';
import { Form } from 'components/form';
import { Info } from 'components/icons';
import { renderComponent } from 'utils/components';
import { SectionProps } from 'pages/project/update-wp/UpdateWpEditor';

import HumanResources from './HumanResources';
import Consumables from './Consumables';
import Tickets from './Tickets';
import Dailys from './Dailys';
import Services from './Services';
import Others from './Others';
import OperationSupport from './Support';
import Equipments from './Equipments';
import { Loader, WrapperCard } from 'components/elements';
import { useFinancial } from './useFinancial';

import styles from './Financial.module.scss';

const items: MenuItem[] = [
  { key: 'hr', component: HumanResources },
  { key: 'consumables', component: Consumables },
  { key: 'tickets', component: Tickets },
  { key: 'dailys', component: Dailys },
  { key: 'services', component: Services },
  { key: 'other', component: Others },
  { key: 'equipments', component: Equipments },
  { key: 'support', component: OperationSupport },
];

const Financial = ({
  state: { id, showErrors, tabErrors, historicData, showDiff },
  formProps,
}: SectionProps) => {
  const { tx } = useTranslationX('financial.sections', 'update_wp');

  const {
    duplicatedPartes,
    findChangedParts,
    findChangedSections,
    fonteRecursoStatus,
    updateFonteRecursoStatus,
    parteId,
    updateParteId,
    partes,
  } = useFinancial({
    id,
    historicData,
    sections: items,
  });

  const createAdornment = (item: string) => {
    let adornment = <></>;

    if (tabErrors && tabErrors['orcamento'] && tabErrors['orcamento'][item]) {
      let className = !!tabErrors['orcamento'][item].warnings ? styles.adornmentWarn : '';

      className = !!tabErrors['orcamento'][item].errors
        ? styles.adornmentError
        : className;

      adornment = (
        <div className={className}>
          <Info />
        </div>
      );
    }

    return adornment;
  };

  const handleChangeTab = useCallback(
    (index: number) => {
      if (duplicatedPartes) {
        updateParteId(duplicatedPartes?.[index].id.toString());
        updateFonteRecursoStatus(duplicatedPartes?.[index].fonteRecursoStatus ?? 1);
      }
    },
    [duplicatedPartes, updateParteId, updateFonteRecursoStatus]
  );

  useEffect(() => {
    handleChangeTab(0);
  }, [duplicatedPartes]);

  if (!partes || !parteId) return <Loader />;

  return (
    <WrapperCard padding="5px 0px 15px" margin="0 0 1rem" responsive>
      <Tabs selectedIndex={0} onChangeTab={handleChangeTab}>
        {duplicatedPartes?.map(parte => (
          <Tab
            title={parte.nome}
            key={uniqueId()}
            adornment={showErrors ? createAdornment(parte.nome) : undefined}
            changed={showDiff && findChangedParts(parte)}
          />
        ))}
      </Tabs>
      <Tabs type="ghost" selectedIndex={0} cascade="cascade">
        {items.map(({ key: section, component }) => {
          return (
            <Tab
              title={tx(section)}
              key={section}
              adornment={showErrors ? createAdornment(section) : undefined}
              changed={showDiff && findChangedSections(section)}
            >
              <Form
                baseUrl={`/projeto/${id}/alteracao-pt/${section}`}
                {...formProps}
                name={`${formProps.name}.${section}`}
                hotReload
              >
                {renderComponent(component, {
                  parteId,
                  fonteRecursoStatus,
                })}
              </Form>
            </Tab>
          );
        })}
      </Tabs>
    </WrapperCard>
  );
};

export default Financial;
