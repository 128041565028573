import React from 'react';
import { Stroke } from './types';

import styles from './Joint.module.scss';

const hOff = '0.5px';

type Props = {
  top?: Stroke;
  middle?: Stroke;
  bottom?: Stroke;
};

const Joint = ({ top, middle, bottom }: Props) => {
  return (
    <div className={styles.joint}>
      <svg width="100%" height="100%">
        {top ? (
          <line className={styles[top]} x1={hOff} y1="0" x2={hOff} y2="50%" />
        ) : null}

        {middle ? (
          <line className={styles[middle]} x1={hOff} y1="50%" x2="100%" y2="50%" />
        ) : null}

        {bottom ? (
          <line className={styles[bottom]} x1={hOff} y1="50%" x2={hOff} y2="100%" />
        ) : null}
      </svg>
    </div>
  );
};

export default Joint;
