import React from 'react';

const SuccessAlt = ({
  size = 1,
  color = 'currentColor',
}: {
  size?: number;
  color?: string;
}) => (
  <svg width={`${size}em`} viewBox="0 0 24 24">
    <path
      d="m20.6 2.43 0.982 1.01-12 12.3-3.67-3.77 0.982-1.01 2.69 2.76 0.982-1.01zm0-2.01-11 11.3-2.69-2.76-2.94 3.02 5.64 5.78 14-14.3z"
      fill={color}
    />
    <path
      d="m12 23.6c-6.39 0-11.6-5.2-11.6-11.6 0-6.39 5.2-11.6 11.6-11.6 1.02 0 2.06 0.144 3.09 0.429l-0.386 1.4c-0.904-0.25-1.81-0.377-2.71-0.377-5.59 0-10.1 4.55-10.1 10.1 0 5.59 4.55 10.1 10.1 10.1 5.59 0 10.1-4.55 10.1-10.1 0-0.891-0.127-1.8-0.377-2.71l1.4-0.386c0.285 1.03 0.429 2.07 0.429 3.09 0 6.39-5.2 11.6-11.6 11.6z"
      fill={color}
    />
  </svg>
);

export default SuccessAlt;
