import React from 'react';

import { LegendItem } from 'components/elements';
import { classes } from 'utils/components';
import styles from './PlannerHeader.module.scss';
import { Select } from 'components/form';

type Props = {
  title?: string;
  subtitle?: string;
  legends: {
    label: string;
    color: string;
  }[];
  filter?: {
    options: {
      value: string;
      text: string;
    }[];
    value: string;
  };
  style?: React.CSSProperties;
};

const PlannerHeader: React.FC<Props> = ({ filter, title, subtitle, legends, style }) => {
  return (
    <div className={styles.header} style={style}>
      <div className={styles.title}>
        {title && <span>{title}</span>}
        <strong>{subtitle}</strong>
      </div>
      <div className={styles.side}>
        <div
          className={
            legends.length > 3
              ? classes(styles.legend, styles.biggerLegend)
              : styles.legend
          }
        >
          {legends.map((legend, index) => (
            <LegendItem key={index} label={legend.label} color={legend.color} />
          ))}
        </div>
        {filter && (
          <div>
            <Select
              name="range"
              theme="compact"
              metadata={{
                options: filter.options,
              }}
              value={filter.value}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PlannerHeader;
