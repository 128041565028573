import { useEffect } from 'react';

import useField from '../Field/useField';
import { FieldType } from '../types';

type Props = {
  name: string;
  value?: any;
  valid?: boolean;
  type?: FieldType;
  omit?: boolean;
};

const HiddenField = (props: Props) => {
  const { name, type, value, form, section, refreshType, setValue } = useField(props);
  const { valid = true, omit } = props;

  useEffect(() => {
    form.updateFieldData(name, {
      value,
      valid,
      section,
      omit,
      refreshType,
      setValue,
      disabled: type === 'disabled',
    });
  }, [form, name, omit, refreshType, section, setValue, type, valid, value]);

  return null;
};

export default HiddenField;
