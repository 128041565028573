import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslationX } from 'i18n';
import IconButton from '../IconButton/IconButton';

import styles from './GoBackButton.module.scss';
import { ArrowBackIcon } from 'consts/icons';

type Props = {
  hint?: string;
};

const GoBackButton: React.FC<Props> = props => {
  const { hint } = props;
  const { tx } = useTranslationX('components', 'translation');
  const history = useHistory();

  return (
    <div className={styles.back}>
      <IconButton
        hint={hint || tx('goback.default-hint')}
        shape="circle"
        onClick={history.goBack}
        icon={ArrowBackIcon}
      />
    </div>
  );
};

export default GoBackButton;
