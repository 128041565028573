import React from 'react';

// import { Container } from './styles';

const CheckAlt = ({ size = 1 }: { size?: number }) => {
  return (
    <svg
      width={`${size}em`}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.19 9.6075C3.97 10.3875 5.23 10.3875 6.01 9.6175L13.01 2.6875C13.56 2.1475 13.56 1.2675 13.02 0.727501L12.98 0.687501C12.44 0.147501 11.57 0.147501 11.03 0.687501L4.6 7.1175L2.95 5.4675C2.43 4.9475 1.57 4.9275 1.03 5.4475C0.46 5.9775 0.45 6.8675 1 7.4175L3.19 9.6075ZM13 13.7175H1C0.45 13.7175 0 14.1675 0 14.7175C0 15.2675 0.45 15.7175 1 15.7175H13C13.55 15.7175 14 15.2675 14 14.7175C14 14.1675 13.55 13.7175 13 13.7175Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CheckAlt;
