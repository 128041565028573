import React, { useEffect } from 'react';
import { useTranslationX } from 'i18n';
import { getMasked } from 'utils/inputMask';
import { FieldSet, HiddenField, TextField, useFormObserver } from 'components/form';
import { useFirstRender } from 'components/hooks';

import styles from './EstimationTable.module.scss';

type Props = {
  partes: string[];
  estimativa: any;
  forceUpdate: () => void;
};

type KeyProps = {
  index: number;
  element: number;
};

const EstimationTable = ({ partes, estimativa, forceUpdate }: Props) => {
  const { t, tx } = useTranslationX('proposal:financial', ['translation', 'proposal']);

  const observed = useFormObserver({
    onSave: true,
  });

  const first = useFirstRender();

  let i = 0;

  const keys = [
    'rh',
    'materiais',
    'equipamentos',
    'servicos',
    'passagens',
    'diarias',
    'outros',
    'subtotal',
    'suporte',
    'total',
  ];

  useEffect(() => {
    if (!first) {
      forceUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observed]);

  const supportOrOtherColumnBuilder = (sectorName: string, keyProps: KeyProps) => {
    if (sectorName === 'suporte') {
      return (
        <FieldSet name="suportes" index={keyProps.index}>
          <HiddenField name="id" />
          <TextField name="valor" theme="compact" align="center" />
        </FieldSet>
      );
    } else {
      return keyProps.element !== 0
        ? getMasked(keyProps.element.toFixed(2), {
            pattern: 'currency',
          })
        : '';
    }
  };

  const estimationList = keys.map(key => {
    let ests = estimativa[key];

    return (
      <tr key={i++}>
        <td className={styles.labelKeys}>{tx(`estimation.${key}`)}</td>
        {ests.map((e: number, index: number) => (
          <td key={i++}>
            {supportOrOtherColumnBuilder(key, {
              element: e,
              index: index,
            })}
          </td>
        ))}
      </tr>
    );
  });

  let k = 0;
  const partList = partes.map(p =>
    k++ === partes.length - 1 ? (
      <th key={i++} className={styles.partItem}>{`${p} (${tx(
        'composition.economic'
      )})`}</th>
    ) : (
      <th key={i++} className={styles.partItem}>
        {p}
      </th>
    )
  );

  const partLabelVal = (
    <>
      <tr key={i++}>
        <td>{tx('estimation.label')}</td>

        {partes.map(p => (
          <React.Fragment key={i++}>
            <td key={i++}>{`${tx('composition.currency')} (${t('currency')})`}</td>
          </React.Fragment>
        ))}
      </tr>
    </>
  );

  return (
    <>
      <h4>{tx('estimation.title')}</h4>
      <table className={styles.financial}>
        <thead>
          <tr>
            <th></th>
            {partList}
          </tr>
          {partLabelVal}
        </thead>
        <tbody>{estimationList}</tbody>
      </table>
    </>
  );
};

export default EstimationTable;
