import { useEffect } from 'react';
import { useForm, useFormObserver } from 'components/form';

type Props = {
  id: string;
  fieldName: string;
  onChange: (id: string, value: any, params?: string[]) => void;
  hasParams?: boolean;
};

const Source = ({ id, fieldName, onChange, hasParams }: Props) => {
  const form = useForm();
  const result = useFormObserver({ field: fieldName, prop: 'value' }, true, true);

  useEffect(() => {
    if (result?.data) {
      const value = result.data.value;
      let params: string[] | undefined;

      if (hasParams) {
        const options = form.getFieldMetadata(fieldName).options;

        if (Array.isArray(options) && typeof value === 'number') {
          for (const option of options) {
            if (option.value === value) {
              if (option.params) {
                params = option.params.split(',');
              }

              break;
            }
          }
        }
      }

      onChange(id, value, params);
    }
  }, [fieldName, form, hasParams, id, onChange, result]);

  return null;
};

export default Source;
