import React from 'react';

import { BUSCA_CEP_URL } from 'consts';
import { useTranslationX } from 'i18n';
import { Grid, Row } from 'components/containers';
import { FieldSet, HiddenField, Select, TextField } from 'components/form';
import { CEP, Localidades } from 'components/form';
import { FieldType } from 'components/form/types';

import styles from './Address.module.scss';

type Props = {
  prefix?: string;
  theme?: 'compact';
  type?: FieldType;
  omit?: boolean;
};

const Address = ({ prefix = 'endereco', theme, type, omit }: Props) => {
  const { tx } = useTranslationX('address', 'register');
  const params = theme ? [30, 10, 30, 30] : [46, 13, 41];
  const props = { type, omit };

  return (
    <Grid>
      <FieldSet name={prefix}>
        <HiddenField name="id" omit={omit} />

        <Row width={[20, 60, 20]}>
          <div className={styles.helper}>
            <a
              className={styles[type || '']}
              href={BUSCA_CEP_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              {tx('helper')}
            </a>

            <TextField name="cep" label={tx('code')} {...props} />
          </div>

          <TextField name="logradouro" label={tx('street')} {...props} />
          <TextField name="numero" label={tx('number')} {...props} />
        </Row>

        <Row width={params}>
          <TextField name="bairro" label={tx('district')} {...props} />
          <Select
            name="uf"
            label={tx('province')}
            style={{ root: { minWidth: 55 } }}
            {...props}
          />
          <Select name="cidade" label={tx('city')} {...props} />

          {theme && <TextField name="complemento" label={tx('complement')} {...props} />}
        </Row>

        {!theme && <TextField name="complemento" label={tx('complement')} {...props} />}
      </FieldSet>

      <Localidades state="uf" city="cidade" prefix={prefix} />

      <CEP
        cep="cep"
        logradouro="logradouro"
        bairro="bairro"
        uf="uf"
        cidade="cidade"
        prefix={prefix}
      />
    </Grid>
  );
};

export default Address;
