import React, { Fragment, useMemo } from 'react';
import { useTranslationX } from 'i18n';
import {
  AutoCompute,
  FieldSet,
  HiddenField,
  TextField,
  useForm,
  useFormObserver,
} from 'components/form';
import { ApportionmentProps } from '../ScheduleTab/ScheduleTab';

import { currencyDefault } from 'components/form/helpers';

import { MilestoneContribution } from 'pages/project/types';
import { AlertMessage } from 'components/elements';

import styles from './ApportionmentTable.module.scss';

const ApportionmentTable = (props: ApportionmentProps) => {
  const { milestone, partIdx } = props;

  const labels = [
    'humanResources',
    'services',
    'daily',
    'tickets',
    'materials',
    'equipments',
    'otherSpents',
    'subTotal',
    'support',
    'total',
  ];

  const fieldsMap: { [x: string]: string } = {
    humanResources: 'recursosHumanos',
    services: 'servicosTerceiros',
    daily: 'diarias',
    tickets: 'passagens',
    materials: 'materiais',
    equipments: 'equipamentos',
    otherSpents: 'outrasDespesas',
    subTotal: 'subtotal',
    support: 'suporteOperacional',
    total: 'total',
  };

  const { tx } = useTranslationX('disbursementSchedule.tabs', 'update_wp');
  const form = useForm();

  const data = form.getCollection('aportes');

  useFormObserver(new RegExp(`aportes.*\\.*.mesExecucao`));

  const contributions = data?.['aportes'] as MilestoneContribution[];

  const contributionsInMilestone = useMemo(
    () =>
      contributions
        ?.map(a => a)
        .sort((a, b) => a.mesExecucao - b.mesExecucao)
        .filter(
          a => a.mesExecucao > milestone.range[0] && a.mesExecucao <= milestone.range[1]
        ) || [],
    [contributions, milestone]
  );

  return contributionsInMilestone?.length > 0 ? (
    <table className={styles.content}>
      <thead>
        <tr>
          <th className={styles.title}></th>
          {contributionsInMilestone.map(aportes => {
            return (
              <th key={aportes.id} colSpan={2}>
                {tx('firstMonth', { month: aportes.mesExecucao })}
              </th>
            );
          })}
        </tr>
        <tr>
          <td>{tx('rubrica')}</td>

          {contributionsInMilestone.map(_ => (
            <Fragment key={_.id}>
              <td align="center">{tx('value')}</td>
              <td align="center">{tx('percentage')}</td>
            </Fragment>
          ))}
        </tr>
      </thead>
      <tbody>
        {labels.map((label: string) => {
          return (
            <tr key={label}>
              <td className={styles.labelKeys}>{tx(label)}</td>
              {contributionsInMilestone.map(_el => (
                <FieldSet key={_el.id} name="aportes" index={contributions?.indexOf(_el)}>
                  <HiddenField name="id" omit />
                  <FieldSet name="partes" index={partIdx}>
                    <FieldSet name="rateio">
                      <HiddenField name="id" omit />
                      <td className={styles.value}>
                        {fieldsMap[label] === 'subtotal' && (
                          <AutoCompute
                            align="center"
                            type="disabled"
                            label={tx('value')}
                            name="subtotal"
                            theme="platinum"
                            expression={
                              '(recursosHumanos + servicosTerceiros + diarias + passagens + outrasDespesas + materiais + equipamentos)'
                            }
                            {...currencyDefault()}
                          />
                        )}
                        {fieldsMap[label] === 'total' && (
                          <AutoCompute
                            align="center"
                            type="disabled"
                            label={tx('value')}
                            name="total"
                            theme="platinum"
                            expression="(subtotal + suporteOperacional)"
                            {...currencyDefault()}
                          />
                        )}
                        {fieldsMap[label] !== 'total' &&
                          fieldsMap[label] !== 'subtotal' && (
                            <TextField
                              {...currencyDefault()}
                              align="center"
                              label={tx('value')}
                              name={fieldsMap[label]}
                              theme="platinum"
                              clientSideDiff
                            />
                          )}
                      </td>
                    </FieldSet>
                    <td className={styles.percentage}>
                      <AutoCompute
                        align="center"
                        omit
                        type="disabled"
                        label={tx('percentage')}
                        name="percentage"
                        theme="platinum"
                        expression={`(rateio.${fieldsMap[label]}  / valor) * 100`}
                        metadata={{ rules: { mask: { pattern: 'currency' } } }}
                      />
                    </td>
                  </FieldSet>
                </FieldSet>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  ) : (
    <div className={styles.alertMessage}>
      <AlertMessage
        title={tx('sharedResources')}
        message={tx('sharedResourcesEmpty')}
      ></AlertMessage>
    </div>
  );
};

export default ApportionmentTable;
