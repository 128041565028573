import React, { useRef } from 'react';
import { BsFillQuestionCircleFill as Icon } from 'react-icons/bs';

import Dialog, { DialogProps } from './Dialog';
import { useVisible } from 'components/hooks';
import { Button } from 'components/elements';

type Props = {
  visible: boolean;
  options: { text: string; value: string | number }[];
  onSelect: (value: string | number) => void;
};

const OptionDialog: React.FC<Props & DialogProps> = props => {
  const { options, onSelect, ...rest } = props;
  const { visible, setVisible } = useVisible(props.visible);
  const selected = useRef<string | number>();

  return (
    <Dialog
      {...rest}
      visible={visible}
      icon={Icon}
      onClose={() => (selected.current ? onSelect(selected.current) : null)}
      actions={
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {options.map(({ text, value }, index) => (
            <Button
              key={value}
              onClick={() => {
                selected.current = value;
                setVisible(false);
              }}
              type={index < options.length - 1 ? 'secondary' : 'primary'}
            >
              {text}
            </Button>
          ))}
        </div>
      }
    />
  );
};

export default OptionDialog;
