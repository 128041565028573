import React from 'react';
import Tippy from '@tippyjs/react';
import { AiOutlineCheckCircle } from 'react-icons/ai';

import { useTranslationX } from 'i18n';

import { KeyValueRow, Tag } from 'components/elements';
import { ItemCard } from 'components/containers';

import { getMasked } from 'utils/inputMask';
import { appendDots } from 'utils/stringUtils';

import { classes, renderComponent } from 'utils/components';
import { hiringStatusColors, RoleItem } from 'pages/project/types';
import { Pencil } from 'components/icons';

import styles from './CardRoleHire.module.scss';

type Props = {
  role: RoleItem;
  showHiringRequest?: boolean;
  onItemSelected?: any;
  alteracao_exclusao?: boolean;
  cardStyle?: React.CSSProperties;
};

const RolesList: React.FC<Props> = ({
  role,
  onItemSelected,
  showHiringRequest = false,
  alteracao_exclusao,
  cardStyle,
}) => {
  const { cargo, duracao, categoria, tipo, remuneracao, status, vinculo } = role;
  const { tx } = useTranslationX('rolesCards', 'project');

  const getCardAction = () => {
    if (showHiringRequest && status === 'AVA') {
      return {
        label: tx('generateHiringRequest'),
        icon: AiOutlineCheckCircle,
        onClick: onItemSelected,
      };
    }

    if (showHiringRequest && status === 'EDT') {
      return {
        label: tx('editHiring'),
        icon: Pencil,
        iconProps: {
          size: 0.8,
        },
        onClick: onItemSelected,
      };
    }
  };

  const renderCardAction = () => {
    const action = getCardAction();
    return (
      action &&
      !alteracao_exclusao && (
        <div className={styles.order}>
          {renderComponent(action.icon, action.iconProps)}
          <span onClick={action.onClick} className={styles.label}>
            {action.label}
          </span>
        </div>
      )
    );
  };

  return (
    <ItemCard
      width="350px"
      title={
        <Tippy
          content={cargo + (alteracao_exclusao ? tx('deleted') : '')}
          animation="perspective"
          touch={false}
          className={styles.popup}
        >
          <div>
            <KeyValueRow label={tx('role')} value={appendDots(cargo, 20)} size="medium" />
          </div>
        </Tippy>
      }
      style={cardStyle}
    >
      <div
        className={classes(
          styles.container,
          alteracao_exclusao ? styles.deleted : undefined
        )}
      >
        <div className={styles.content}>
          <div className={styles.description}>
            {role.tipo !== 'CLT' ? (
              <>
                <div>
                  <KeyValueRow label={tx('duracao')} value={duracao} margin="small" />
                  <KeyValueRow label={tx('type')} value={tipo} margin="small" />
                  <KeyValueRow label={tx('vinculo')} value={vinculo} margin="small" />
                </div>
                <div>
                  {role.tipo !== 'Estágio' && (
                    <KeyValueRow
                      label={tx('category')}
                      value={categoria}
                      margin="small"
                    />
                  )}
                  <KeyValueRow
                    label={tx('remuneration')}
                    value={getMasked(Number(remuneracao).toFixed(2), {
                      pattern: 'currency',
                    })}
                    margin="small"
                  />
                </div>
              </>
            ) : (
              <>
                <div>
                  <KeyValueRow label={tx('duracao')} value={duracao} margin="small" />
                  <KeyValueRow label={tx('type')} value={tipo} margin="small" />
                </div>
                <div>
                  <KeyValueRow label={tx('vinculo')} value={vinculo} margin="small" />
                  <KeyValueRow
                    label={tx('remuneration')}
                    value={getMasked(Number(remuneracao).toFixed(2), {
                      pattern: 'currency',
                    })}
                    margin="small"
                  />
                </div>
              </>
            )}
          </div>

          {onItemSelected && (
            <div className={styles.footer}>
              {renderCardAction()}

              <Tag text={tx(`status.${status}`)} color={hiringStatusColors[status]} />
            </div>
          )}
        </div>
      </div>
    </ItemCard>
  );
};

export default RolesList;
