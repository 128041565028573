import React from 'react';

import { Tab, Tabs } from 'components/containers';
import { renderComponent } from 'utils/components';
import { useField } from 'components/form';
import { useTranslationX } from 'i18n';
import { ScheduleTab } from '..';

import { Loader } from 'components/elements';

import { SectionPropsWithSupport } from '../DisbursementSchedule';

import styles from './ApportionmentTable.module.scss';

export type ProposalSupportPartRateio = {
  diarias: number;
  outras_despesas: number;
  passagens: number;
  recursos_humanos: number;
  servicos_terceiros: number;
  subtotal: number;
  suporte_operacional: number;
  total: number;
};

export type ProposalSupportPart = {
  id: number;
  valor: number;
  partId: number;
  rateio: ProposalSupportPartRateio;
  partNome: string;
  ordem: number;
};

export type ProposalSupport = {
  id: number;
  ordem: number;
  mesExecucao: number;
  blocked: boolean;
  partes: ProposalSupportPart[];
};

export type MilestoneWithSupport = {
  id: number;
  aportes: ProposalSupport[];
};

const ApportionmentTable = (props: SectionPropsWithSupport) => {
  const { partes } = props;
  const { tx } = useTranslationX('proposal:financial', ['translation', 'proposal']);

  const { value: milestones } = useField({
    name: 'macroentregas',
  });

  if (!milestones) return <Loader />;

  return (
    <div className={styles.financial}>
      <h4>{tx('rateio.title')}</h4>
      <Tabs selectedIndex={0}>
        {partes?.slice(0, -1).map((key, index) => (
          <Tab title={key} key={key}>
            {renderComponent(ScheduleTab, {
              companyType: key,
              milestones,
              partIdx: index,
            })}
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default ApportionmentTable;
