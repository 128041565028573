import React, { Fragment } from 'react';

import { useTranslationX } from 'i18n';

import { Tab, Tabs } from 'components/containers';
import { AutoCompute, FieldSet, HiddenField, TextField, useForm } from 'components/form';
import { renderComponent } from 'utils/components';
import { MilestoneWithSupport } from '../ApportionmentTable';
import { useAuthState } from 'store/auth';

import styles from './ScheduleTab.module.scss';

type Props = {
  companyType: string;
  milestones: MilestoneWithSupport[];
  partIdx: number;
};

type ApportionmentProps = {
  milestone: MilestoneWithSupport;
  index: number;
} & Props;

const ScheduleTab = (props: Props) => {
  const { milestones, partIdx } = props;

  const { tx } = useTranslationX('schedule.milestone', 'proposal');

  return (
    <div className={styles.content}>
      <div className={styles.macrosTable}>
        <Tabs selectedIndex={0} cascade="cascade">
          {milestones.map((milestone, index) => (
            <Tab
              key={index}
              title={tx('label', {
                number: index + 1,
              })}
            >
              {renderComponent(Apportionment, {
                ...props,
                index,
                partIdx,
                milestone,
              })}
            </Tab>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

const Apportionment = ({ milestone, index, partIdx }: ApportionmentProps) => {
  const form = useForm();
  const editable = form.editable;

  const { profile } = useAuthState();

  const labels = [
    'humanResources',
    'materials',
    'equipments',
    'services',
    'tickets',
    'daily',
    'otherSpents',
    'subTotal',
    'support',
    'total',
  ];

  const fieldsMap: { [x: string]: string } = {
    humanResources: 'recursosHumanos',
    services: 'servicosTerceiros',
    daily: 'diarias',
    tickets: 'passagens',
    materials: 'materiais',
    equipments: 'equipamentos',
    otherSpents: 'outrasDespesas',
    subTotal: 'subtotal',
    support: 'suporteOperacional',
    total: 'total',
  };

  const { tx } = useTranslationX('disbursementSchedule.tabs', 'update_wp');

  return (
    <FieldSet name="macroentregas" index={index}>
      <table>
        <thead>
          <tr>
            <th></th>
            {milestone.aportes.map(aporte => {
              return (
                <th key={aporte.id} className={styles.partItem} colSpan={2}>
                  {tx('firstMonth', { month: aporte.mesExecucao })}
                </th>
              );
            })}
          </tr>
          <tr>
            <td>{tx('rubrica')}</td>
            {milestone.aportes.map(_ => (
              <Fragment key={_.id}>
                <td>{tx('value')}</td>
                <td>{tx('percentage')}</td>
              </Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {labels.map((label: string, labelIndex: number) => {
            return (
              <tr key={labelIndex}>
                <td className={styles.labelKeys}>{tx(label)}</td>
                {milestone.aportes.map((_, i) => (
                  <FieldSet name="aportes" index={i} key={i}>
                    <FieldSet name="partes" index={partIdx}>
                      <HiddenField name="id" omit />
                      <FieldSet name="rateio">
                        <HiddenField name="id" omit />
                        <td className={styles.value}>
                          {fieldsMap[label] === 'subtotal' && (
                            <AutoCompute
                              align="center"
                              type="disabled"
                              label={tx('value')}
                              name="subtotal"
                              theme="platinum"
                              expression="(recursosHumanos + servicosTerceiros + diarias + passagens + outrasDespesas + materiais + equipamentos)"
                              omit
                            />
                          )}
                          {fieldsMap[label] === 'total' && (
                            <AutoCompute
                              align="center"
                              type="disabled"
                              label={tx('value')}
                              name="total"
                              theme="platinum"
                              expression="subtotal + suporteOperacional"
                            />
                          )}
                          {fieldsMap[label] !== 'total' &&
                            fieldsMap[label] !== 'subtotal' && (
                              <TextField
                                type={
                                  profile === 'pesquisador' || !editable
                                    ? 'disabled'
                                    : undefined
                                }
                                align="center"
                                name={fieldsMap[label]}
                                theme="compact"
                              />
                            )}
                        </td>
                      </FieldSet>
                      <td className={styles.percentage}>
                        <AutoCompute
                          align="center"
                          type="disabled"
                          label={tx('percentage')}
                          name="percentage"
                          theme="platinum"
                          expression={`(rateio.${fieldsMap[label]} / valor) * 100`}
                          metadata={{ rules: { mask: { pattern: 'currency' } } }}
                          omit
                        />
                      </td>
                    </FieldSet>
                  </FieldSet>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </FieldSet>
  );
};

export default ScheduleTab;
