import React, { useCallback, useEffect } from 'react';
import { Form, MultiSelect, TextField, useForm, useFormObserver } from 'components/form';
import { Search } from 'components/icons';
import DatePicker from 'components/form/modules/DatePicker';
import CurrencyRange from 'components/form/modules/CurrencyRange';
import { sanitizeValue } from 'utils/object';
import { useTranslationX } from 'i18n';
import { FieldMetadata } from 'components/form/types';
import { DispatchFilter, StateFilter } from 'components/containers/TableList/types';
import useDebounce from 'components/hooks/useDebounce';
import { Instance } from 'components/containers/TableList/TableList';

type Props = {
  name: string;
  observerName?: string;
  dispatchFilter: DispatchFilter;
  instance?: React.MutableRefObject<Instance>;
  stateFilter: StateFilter;
  type?: SearchType;
  metadata?: FieldMetadata;
  pattern?: string;
};

export type SearchType = 'text' | 'date' | 'range' | 'status';

const TableFilterLabel: React.FC<Props> = props => {
  return (
    <Form>
      <Content {...props} />
    </Form>
  );
};

const Content = (props: Props) => {
  const { tx } = useTranslationX('components', 'translation');
  const {
    type = 'text',
    dispatchFilter,
    metadata,
    name,
    observerName,
    stateFilter,
    instance,
    pattern,
  } = props;
  const result = useFormObserver(observerName || name);
  const filter = result?.data?.value;

  const form = useForm();

  const debouncedFilter = useDebounce(sanitizeValue(filter), 300);

  const dispatchUpdate = useCallback(() => {
    dispatchFilter({
      type: 'FILTER_UPDATE',
      payload: { [name]: debouncedFilter },
    });
    instance?.current?.reset();
  }, [debouncedFilter, dispatchFilter, name, instance]);
  useEffect(() => {
    dispatchUpdate();
    dispatchFilter({
      type: 'FINISH_UPDATE',
    });
  }, [dispatchFilter, dispatchUpdate]);

  useEffect(() => {
    if (stateFilter.status === 'clean') {
      dispatchFilter({
        type: 'FINISH_UPDATE',
      });
      form.reset();
    }
  }, [dispatchFilter, form, stateFilter.status]);

  return (
    <>
      {type === 'date' && (
        <DatePicker name={name} metadata={metadata} theme="sneaky" range />
      )}
      {type === 'status' && (
        <MultiSelect
          name={name}
          metadata={metadata}
          displayOptions="dropdown"
          theme="sneaky"
          fitWidth
          fitHeight
        />
      )}
      {type === 'range' && (
        <CurrencyRange name={name} metadata={metadata} theme="sneaky" pattern={pattern} />
      )}
      {type === 'text' && (
        <TextField
          name={name}
          metadata={metadata}
          icon={Search}
          placeholder={tx('tableList.searchPlaceholder')}
          theme="sneaky"
        />
      )}
    </>
  );
};

export default TableFilterLabel;
