import { useState, useEffect, useContext, useRef, useCallback } from 'react';

import { FormObserverContext } from './FormContext';
import { getObserverInitialValue } from './helpers';
import { useFirstRender } from 'components/hooks';

import {
  FieldObserverOptions,
  FieldObserverResult,
  FormObserverOptions,
  FormObserverResult,
  PropObserverOptions,
  PropObserverResult,
  SectionObserverOptions,
  SectionObserverResult,
} from './types';

function useFormObserver(
  options: FieldObserverOptions,
  initialValue?: boolean,
  clearResult?: boolean,
  currentValue?: boolean
): FieldObserverResult | undefined;

function useFormObserver(
  options: SectionObserverOptions,
  initialValue?: boolean,
  clearResult?: boolean,
  currentValue?: boolean
): SectionObserverResult | undefined;

function useFormObserver(
  options: PropObserverOptions,
  initialValue?: boolean,
  clearResult?: boolean,
  currentValue?: boolean
): PropObserverResult | undefined;

function useFormObserver(
  options: FormObserverOptions,
  initialValue?: boolean,
  clearResult?: boolean,
  currentValue?: boolean
): FormObserverResult | undefined {
  const [, update] = useState<{ name: string }>();
  const form = useContext(FormObserverContext);

  const optionsRef = useRef(options);
  const resultRef = useRef<FormObserverResult | undefined>(
    initialValue
      ? getObserverInitialValue(form.values, options, form.initialValues)
      : undefined
  );

  const callback = useCallback((res: FormObserverResult) => {
    resultRef.current = res;
    update({ name: res.name });
  }, []);

  const firstRender = useFirstRender();

  if (firstRender) {
    form.subscribe(callback, optionsRef.current);
  }

  useEffect(() => {
    return () => form.unsubscribe(callback);
  }, [callback, form]);

  const result = resultRef.current;

  if (clearResult || currentValue) {
    resultRef.current = undefined;
  }

  if (currentValue && result === undefined) {
    return getObserverInitialValue(form.values, optionsRef.current, form.initialValues);
  }

  return result;
}

export default useFormObserver;
