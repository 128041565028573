import React, { useCallback, useState } from 'react';
import { MdAdd as Add } from 'react-icons/md';
import { useTranslationX } from 'i18n';
import { Grid, Row, ScrollPanel } from 'components/containers';
import { FieldSet, HiddenField, TextField, useDiff, useForm } from 'components/form';

import Deliverables from './Deliverables';
import { classes } from 'utils/components';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';

import styles from './Milestones.module.scss';
import { GenericArray } from 'components/inputs/types';

export type ProposalDeliverables = {
  id: number;
  titulo: string;
  ordem: number;
};

export type ProposalMilestones = {
  id: number;
  titulo: string;
  duracao: number;
  ordem: number;
  entregaveis: ProposalDeliverables[];
  referencia?: number;
};

const Milestones = ({
  editable,
  disabledItems,
}: {
  editable: boolean;
  disabledItems?: number[];
}) => {
  const { tx } = useTranslationX('macroentregas', 'proposal');
  const { tx: tx2 } = useTranslationX('components.tableData');

  const form = useForm();
  const pathMilestone = 'macroentregas';
  const data: ProposalMilestones[] | undefined = form.getFieldValue('macroentregas');

  const [milestones, setMilestones] = useState<number>(data ? data.length : 0);

  const updateMilestone = useCallback(
    (milestone: ProposalMilestones) => {
      const path = `${pathMilestone}[${milestone.ordem}]`;

      form.setFieldValue(`${path}.id`, milestone.id, true);
      form.setFieldValue(`${path}.titulo`, milestone.titulo, true);
      form.setFieldValue(`${path}.duracao`, milestone.duracao, true);
      form.setFieldValue(`${path}.entregaveis`, milestone.entregaveis, true);
    },
    [form]
  );

  const handleAddMilestone = useCallback(() => {
    const payload = {
      titulo: `${tx('title')} ${milestones + 1}`,
      duracao: 1,
    };

    form.addListItem(
      pathMilestone,
      milestones,
      (success, res) => {
        if (success && res) {
          const { id, ordem } = res.response as { id: number; ordem: number };

          updateMilestone({ ...payload, id, ordem, entregaveis: [] });
          setMilestones(milestone => milestone + 1);
        }
      },
      payload
    );
  }, [tx, form, milestones, updateMilestone]);

  const handleDeleteMilestone = useCallback(
    (indexMilestone: number) => {
      form.setFieldValue(`${pathMilestone}[${indexMilestone}].entregaveis`, []);
      form.removeListItem(pathMilestone, indexMilestone, success => {
        if (success) {
          setMilestones(milestone => milestone - 1);
        }
      });
    },
    [form]
  );

  const { removedRows, getDiff } = useDiff('macroentregas');

  return (
    <ScrollPanel>
      <div className={styles.milestones}>
        {Array.from(new Array(milestones), (_, index) => {
          const milestoneId = data?.[index].id as number;
          const milestoneReference = data?.[index].referencia as number;
          const isDisabled = disabledItems?.includes(milestoneId);

          const isDeliverablesEditable = () => {
            if (isDisabled) {
              return false;
            }
            return editable;
          };

          const overlayType = getDiff(milestoneId)?.type;

          const hasClientSideDiff = overlayType !== 'added';

          return (
            <FieldSet
              name="macroentregas"
              index={index}
              key={index}
              disabled={isDisabled}
            >
              <HiddenField name="id" />
              <Tippy
                content={tx2('added')}
                className={styles.popup}
                animation="perspective"
                touch={false}
                followCursor="horizontal"
                plugins={[followCursor]}
                disabled={hasClientSideDiff}
              >
                <Grid
                  className={classes(styles.item, styles[overlayType ?? ''])}
                  spacing="20px 28px"
                >
                  <Row width={[0.8, 0.2]}>
                    <TextField
                      name="titulo"
                      label={`${tx('title')} ${index + 1}`}
                      clientSideDiff={hasClientSideDiff}
                    />
                    <TextField
                      name="duracao"
                      label={tx('duration')}
                      align="center"
                      adornment={{ right: tx('months') }}
                      metadata={{ rules: { required: true } }}
                      clientSideDiff={hasClientSideDiff}
                    />
                    <HiddenField omit name="ordem" />
                    <HiddenField omit name="referencia" />
                  </Row>

                  <Deliverables
                    count={
                      !!data && data[index].entregaveis !== undefined
                        ? data[index].entregaveis.length
                        : 0
                    }
                    reference={milestoneReference}
                    pathDeliverables={`${pathMilestone}[${index}].entregaveis`}
                    editable={isDeliverablesEditable()}
                    milestoneIndex={index}
                    overlayType={overlayType}
                  />
                  <Row align="bottom">
                    {editable && !isDisabled && milestones > 3 ? (
                      <div
                        className={styles.deleteMilestone}
                        onClick={() => handleDeleteMilestone(index)}
                      >
                        {tx('delete-milestone')}
                      </div>
                    ) : null}
                  </Row>
                </Grid>
              </Tippy>
            </FieldSet>
          );
        })}

        {Array.from(new Array(removedRows.length), (_, index) => {
          const milestoneData = removedRows[index].data;
          return (
            <FieldSet name="r-macroentregas" index={index} key={index}>
              <HiddenField name="id" />
              <div>
                <Tippy
                  content={tx2('removed')}
                  className={styles.popup}
                  animation="perspective"
                  touch={false}
                  followCursor="horizontal"
                  plugins={[followCursor]}
                >
                  <Grid
                    className={classes(styles.item, styles.removed)}
                    spacing="20px 28px"
                  >
                    <Row width={[0.8, 0.2]}>
                      <TextField
                        name="titulo"
                        label={`${tx('title')} ${index + 1}`}
                        type="readonly"
                        omit
                        value={milestoneData.titulo}
                      />
                      <TextField
                        name="duracao"
                        label={tx('duration')}
                        align="center"
                        adornment={{ right: tx('months') }}
                        metadata={{ rules: { required: true } }}
                        type="readonly"
                        omit
                        value={milestoneData.duracao}
                      />
                      <HiddenField omit name="ordem" />
                      <HiddenField omit name="referencia" />
                    </Row>

                    <Deliverables
                      count={
                        !!milestoneData && milestoneData.entregaveis !== undefined
                          ? (milestoneData.entregaveis as GenericArray)?.length
                          : 0
                      }
                      pathDeliverables={`macroentregas[${index}].entregaveis`}
                      editable={false}
                      milestoneIndex={index}
                      source={milestoneData.entregaveis as ProposalDeliverables[]}
                    />
                  </Grid>
                </Tippy>
              </div>
            </FieldSet>
          );
        })}

        {editable && milestones < 5 && (
          <div className={styles.newMilestone} onClick={handleAddMilestone}>
            <Add size={20} />
            <span>{tx('new-milestone')}</span>
          </div>
        )}
      </div>
    </ScrollPanel>
  );
};

export default Milestones;
