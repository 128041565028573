import React from 'react';

import { useTranslationX } from 'i18n';
import { useFetch } from 'apis';
import { useHistory } from 'react-router-dom';

import { Card, ScrollPanel } from 'components/containers';
import { ArchiveGraduationCap } from 'components/icons';
import { Button, Loader, PercentCardWithIcon } from 'components/elements';
import RecentProjectList from './RecentProjectList';
import { RequestTable } from '../index';
import { Profile } from 'pages/register/profile';

import styles from './Summary.module.scss';

export type Props = {
  profile: string;
};

const Dashboard: React.FC<Props> = ({ profile }: Props) => {
  const { tx } = useTranslationX('dashboard', 'project');
  const history = useHistory();

  const isSupervisor = profile === Profile.SUPERVISOR;

  const { data } = useFetch<any>(`/dashboard/totals`);

  if (!data) {
    return <Loader />;
  }
  return (
    <div className={styles.summary}>
      <div className={styles.header}>
        <div className={styles.totals}>
          <PercentCardWithIcon
            label={tx('percentualCarts.project')}
            icon={ArchiveGraduationCap}
            value={data.projetos}
            onClick={() => history.replace('todos')}
          />
          {data.aprovadas !== undefined ? (
            <PercentCardWithIcon
              label={tx('percentualCarts.proposalApproved')}
              icon={ArchiveGraduationCap}
              value={data.aprovadas}
            />
          ) : null}
        </div>
        <div className={styles.addButton}>
          {isSupervisor && (
            <Button
              onClick={() => history.replace('criar-projeto')}
              style={{ root: { marginTop: 0 } }}
            >
              {tx('add')}
            </Button>
          )}
        </div>
      </div>

      <ScrollPanel>
        <div className={styles.body}>
          <Card
            title={tx('recents.title')}
            width={{
              max: '90%',
            }}
            rightChildren={
              <Button
                type="secondary"
                onClick={() => history.replace('todos')}
                style={{ root: { marginRight: 0 } }}
              >
                {tx('recents.seeAll')}
              </Button>
            }
          >
            <RecentProjectList />
          </Card>
          <RequestTable maxHeight="500px" general />
        </div>
      </ScrollPanel>
    </div>
  );
};

export default Dashboard;
