import React, { useRef } from 'react';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
import { useTranslationX } from 'i18n';
import {
  Checkbox,
  TextField,
  Select,
  HiddenField,
  AutoCompute,
  DatePicker,
} from 'components/form';
import { IconButton } from 'components/elements';
import { XAlt as Remove } from 'components/icons';
import { Action, DataType } from './TableData';
import { CellConfig } from './types';
import { splitValueUnit } from 'utils';

import { RowDiff } from 'components/form/useDiff';

import { classes } from 'utils/components';
import { GenericObject } from 'components/inputs/types';

import styles from './TableDataItem.module.scss';

type Props = {
  seq: string;
  metadata: CellConfig<DataType>[];
  dispatch: (action: Action) => void;
  canRemove: boolean;
  diff?: RowDiff;
  groupBy?: (string | number)[][];
  hiddenFields?: string[];
  populate?: GenericObject;
  immutable?: boolean;
  editable?: boolean;
};

const TableDataItem = (props: Props) => {
  const {
    seq,
    metadata,
    dispatch,
    canRemove,
    diff,
    groupBy,
    hiddenFields,
    populate,
    immutable,
    editable = true,
  } = props;

  const { value: inputHeight } = splitValueUnit(styles.inputHeight);
  const { tx } = useTranslationX('components.tableData');
  const overlayRef = useRef<HTMLTableRowElement>(null);

  const isRemovedItem = diff?.type === 'removed';

  function toggleOverlay() {
    if (!isRemovedItem) {
      if (overlayRef.current) {
        overlayRef.current.style.pointerEvents = 'none';
        reactivateOverlay();
      }
    }
  }

  function reactivateOverlay() {
    setTimeout(() => {
      if (overlayRef.current) {
        overlayRef.current.style.pointerEvents = 'all';
      }
    }, 1000);
  }

  const renderRow = (config: CellConfig<DataType>) => {
    const newProps = {
      ...config,
      fieldProps: {
        ...config.fieldProps,
        ...((immutable && !config.forceMutability) || !editable
          ? { type: 'readonly' }
          : {}),
        ...(populate && config.field
          ? { value: populate[config.field], omit: true }
          : {}),
      },
    };
    if (diff && diff.type === 'modified' && config.field && diff.changes[config.field]) {
      return (
        <td key={config.field}>
          {renderContent({
            ...config,
            fieldProps: {
              ...config.fieldProps,
              overlay: diff.type,
              styles: {
                position: 'relative',
                zIndex: 10,
              },
            },
          })}
        </td>
      );
    }
    return <td key={config.field}>{renderContent(newProps)}</td>;
  };

  return (
    <>
      {diff && diff.complete && (
        <Tippy
          content={tx(diff.type)}
          className={styles.popup}
          animation="perspective"
          touch={false}
          followCursor="horizontal"
          plugins={[followCursor]}
        >
          <tr
            className={classes(styles.overlay, styles[diff.type])}
            ref={overlayRef}
            onClick={toggleOverlay}
          />
        </Tippy>
      )}
      <tr>
        <HiddenField name="id" />
        <HiddenField name="_seq_" value={seq} omit />
        <HiddenField name="removivel" omit />
        <HiddenField name="editavel" omit />
        <HiddenField name="ordem" omit />

        {groupBy ? <HiddenField name={groupBy[0][0] as string} omit /> : null}
        {hiddenFields?.map(field => (
          <HiddenField key={field} name={field} omit />
        ))}
        {metadata.map(config => renderRow(config))}

        <td
          style={{
            position: 'relative',
            zIndex: 10,
          }}
        >
          <div className={styles.button}>
            {canRemove ? (
              <IconButton
                icon={Remove}
                size={inputHeight}
                rate={0.5}
                shape="circle"
                type="danger"
                onClick={() => dispatch('REMOVE')}
                hint={tx('remove')}
              />
            ) : null}
          </div>
        </td>
      </tr>
    </>
  );
};

function renderContent({ field, type, metadata, fieldProps }: CellConfig<DataType>) {
  const name = field!;
  const props = { ...fieldProps, name, metadata, theme: 'compact' };

  switch (type) {
    case 'check':
      return (
        <div className={styles.center}>
          <Checkbox name={name} />
        </div>
      );

    case 'select':
      return <Select {...props} />;

    case 'date':
      return <DatePicker {...props} />;

    case 'computed':
      return <AutoCompute {...props} />;
    case 'hidden':
      return <HiddenField {...props} />;
    default:
      return <TextField {...props} />;
  }
}

export default TableDataItem;
