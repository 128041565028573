import React, { forwardRef, useEffect, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';

import { InputProps } from '../types';
import { Check } from 'components/icons';
import { call, splitValueUnit } from 'utils';

import styles from './ColorPicker.module.scss';

const PALETTE = [
  '#8777D9',
  '#2684FF',
  '#57D9A3',
  '#00C7E6',
  '#FFC400',
  '#FF7452',
  '#6B778C',
  '#5243AA',
  '#0052CC',
  '#00875A',
  '#00A3BF',
  '#FF991F',
  '#DE350B',
  '#253858',
];

type ModProps = Omit<InputProps, 'value'> & {
  value?: string;
};

export type Props = {
  title?: string;
  palette?: string[];
};

const ColorPicker = forwardRef<HTMLDivElement, ModProps & Props>((props, ref) => {
  const { value, title, palette = PALETTE, control, inputProps } = props;

  const randomColor = Math.floor(Math.random() * palette.length);

  const [expanded, setExpanded] = useState(false);
  const [selected, setSelected] = useState(color(value || palette[randomColor]));

  const { value: boxSize } = splitValueUnit(styles.boxSize);
  const { value: boxMargin } = splitValueUnit(styles.boxMargin);

  const anchorRef = useRef<HTMLDivElement>();

  function attachRef(el: HTMLDivElement) {
    anchorRef.current = el;

    if (typeof ref === 'function') {
      ref(el);
    } else if (ref) {
      ref.current = el;
    }
  }

  const { onChange, onBlur, onFocus, ...rest } = inputProps;

  useEffect(() => {
    if (value) {
      setSelected(value);
    }
  }, [value]);

  useEffect(() => {
    onChange(selected);
  }, [selected, onChange]);

  const renderPopupContent = (props: object) => {
    const width = (palette.length / 2) * (boxSize + boxMargin * 2);

    return (
      <div className={styles.palette} {...props} style={{ width }} tabIndex={0}>
        {palette.map(el => (
          <div
            key={el}
            className={styles.box}
            style={{ backgroundColor: el }}
            onClick={() => {
              anchorRef.current?.focus();
              setExpanded(false);
              setSelected(el);
            }}
          >
            {el === selected ? <Check /> : null}
          </div>
        ))}
      </div>
    );
  };

  const renderPopup = () =>
    control.ref.current && expanded ? (
      <Tippy
        appendTo={() => document.body}
        placement="bottom-start"
        offset={[0, 2]}
        render={renderPopupContent}
        interactive={true}
        visible={expanded}
        reference={control.ref}
        onClickOutside={() => {
          setExpanded(false);
          call(onBlur);
        }}
        popperOptions={{
          modifiers: [
            {
              name: 'preventOverflow',
              enabled: false,
            },
          ],
        }}
        aria={{ content: null, expanded: false }}
      />
    ) : null;

  function handleFocus(event: React.FocusEvent) {
    if (anchorRef.current?.contains(event.target) && !expanded) {
      call(event.type === 'blur' ? onBlur : onFocus);
    }
  }

  const handlers = {
    onClick: () => setExpanded(expanded => !expanded),
    onBlur: handleFocus,
    onFocus: handleFocus,
  };

  return (
    <div className={styles.colorPicker}>
      <div
        {...rest}
        ref={attachRef}
        className={styles.anchor}
        role="button"
        aria-haspopup="listbox"
        aria-expanded={expanded}
        {...handlers}
      >
        <div className={styles.box} style={{ backgroundColor: selected }} />
        <div className={styles.label}>{title}</div>
      </div>
      {renderPopup()}
    </div>
  );
});

export default ColorPicker;

function color(value: string) {
  return value.startsWith('#') ? value : `#${value}`;
}
