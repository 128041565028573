import React, { ReactNode } from 'react';

import { FormSectionContext } from './FormContext';

const FormSection: React.FC<{ name: string; children?: ReactNode }> = ({
  name,
  children,
}) => {
  return (
    <FormSectionContext.Provider value={{ name }}>{children}</FormSectionContext.Provider>
  );
};

export default FormSection;
