import { createContext } from 'react';
import {
  FieldSetContextValue,
  FormContextValue,
  FormObserverContextValue,
  FormSectionContextValue,
} from './types';

const empty = () => {};

const FormContext = createContext<FormContextValue>({
  submit: empty,
  display: { error: 'touched', success: 'modified' },
  getFieldValue: () => undefined,
  getLastFieldValue: () => undefined,
  getFieldMetadata: () => ({}),
  getSectionFieldValues: () => [],
  setFieldMetadata: empty,
  setFormValues: empty,
  updateFieldData: empty,
  unmountField: empty,
  clearSection: empty,
  removeRejected: empty,
  addRule: empty,
  setFieldValue: empty,
  clearField: empty,
  stash: empty,
  editable: true,
  showDiff: false,
  setEditable: empty,
  setShowDiff: empty,
  reset: empty,
  addListItem: empty,
  removeListItem: empty,
  getCollection: () => ({}),
  getLastCollection: () => ({}),
  getCurrentCollection: () => ({}),
  reload: empty,
  stealthReload: empty,
  notifySave: empty,
  isValid: empty,
  hasLastValues: () => false,
  getFieldData: () => undefined,
  setDisplay: empty,
});

FormContext.displayName = 'FormContext';

const FormObserverContext = createContext<FormObserverContextValue>({
  subscribe: empty,
  unsubscribe: empty,
  values: {},
  initialValues: {},
  display: { error: 'touched', success: 'modified' },
});

FormObserverContext.displayName = 'FormObserverContext';

const FieldSetContext = createContext<FieldSetContextValue>({ prefix: '' });
FieldSetContext.displayName = 'FieldSetContext';

const FormSectionContext = createContext<FormSectionContextValue>({});
FieldSetContext.displayName = 'FormSectionContext';

export { FormContext, FormObserverContext, FieldSetContext, FormSectionContext };
