import React from 'react';

import { useTranslationX } from 'i18n';
import { Grid } from 'components/containers';
import { getMasked } from 'utils/inputMask';
import ColumnObserver from 'components/containers/TableList/ColumnObserver';

import styles from './TotalLabel.module.scss';

type Props = {
  minWidth?: number;
  maxWidth?: number;
  title?: string;
  spacing?: string;
} & (
  | {
      tableName: string;
      columnName: string;
      value?: never;
      groupBy?: (string | number)[][];
    }
  | {
      tableName?: never;
      columnName?: never;
      value: number;
      groupBy?: (string | number)[][];
    }
);

const TotalLabel = (props: Props) => {
  const { t, tx } = useTranslationX('proposal:financial', ['translation', 'proposal']);
  const {
    minWidth = 235,
    maxWidth = 350,
    title = '',
    groupBy,
    spacing = '11px 0',
  } = props;
  const gridProps = { minWidth, maxWidth, className: styles.box, spacing };

  const totalLabel = tx('total-label');
  const labelText = title ? `${totalLabel} ${title}` : totalLabel;

  return props.tableName ? (
    <ColumnObserver
      tableName={props.tableName}
      columnName={props.columnName}
      groupBy={groupBy}
    >
      {data => {
        let sum = 0;
        for (const val of data) {
          if (typeof val === 'number' && !isNaN(val)) {
            sum += val;
          }
        }

        return (
          <Grid {...gridProps}>
            <span className={styles.legend}>
              {labelText}
              {tx('column')}
            </span>
            <div className={styles.pill}>
              <span>{t('currency')}</span>
              <span>{`${getMasked(sum.toFixed(2), {
                pattern: 'currency',
              })}`}</span>
            </div>
          </Grid>
        );
      }}
    </ColumnObserver>
  ) : (
    <Grid {...gridProps}>
      <span className={styles.legend}>
        {title}
        {tx('column')}
      </span>
      <div className={styles.pill}>
        <span>{t('currency')}</span>
        <span>
          {props.value
            ? getMasked(String(props.value?.toFixed(2)), {
                pattern: 'currency',
              })
            : 0.0}
        </span>
      </div>
    </Grid>
  );
};

export default TotalLabel;
