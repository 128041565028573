import React, { CSSProperties, ReactNode } from 'react';
import { classes } from 'utils/components';

import styles from './Cell.module.scss';

type Props = {
  label?: string;
  name: string;
  onClick?: () => void;
  style?: CSSProperties;
  children?: ReactNode;
};

const Cell: React.FC<Props> = ({ label, name, onClick, style, children }) => {
  return (
    <div
      className={classes(styles.cell, label ? '' : styles.root)}
      onClick={onClick}
      style={{ ...style, cursor: onClick ? 'pointer' : 'default' }}
    >
      {label ? <div className={styles.label}>{label}</div> : null}

      <div
        className={styles.name}
        style={label ? undefined : { justifyContent: 'center' }}
      >
        {name}
      </div>

      {children}
    </div>
  );
};

export default Cell;
