import React from 'react';

const Users = ({ size = 1.2 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none">
    <path
      d="M6.54526 8.34565C6.53841 7.95694 6.60993 7.57085 6.75554 7.21038C6.90116 6.84992 7.11789 6.52249 7.3928 6.24761C7.66772 5.97273 7.99519 5.75605 8.35567 5.61048C8.71615 5.46492 9.10226 5.39345 9.49096 5.40036C9.87962 5.39351 10.2657 5.46501 10.6261 5.6106C10.9866 5.75619 11.314 5.97289 11.5888 6.24776C11.8637 6.52263 12.0804 6.85005 12.226 7.21048C12.3716 7.57091 12.4431 7.95698 12.4362 8.34565C12.4431 8.73431 12.3716 9.12038 12.226 9.48081C12.0804 9.84125 11.8637 10.1687 11.5888 10.4435C11.314 10.7184 10.9866 10.9351 10.6261 11.0807C10.2657 11.2263 9.87962 11.2978 9.49096 11.2909C9.10226 11.2978 8.71615 11.2264 8.35567 11.0808C7.99519 10.9352 7.66772 10.7186 7.3928 10.4437C7.11789 10.1688 6.90116 9.84137 6.75554 9.48091C6.60993 9.12045 6.53841 8.73435 6.54526 8.34565ZM9.49055 13.2549C4.97436 13.2549 3.59998 16.4949 3.59998 16.4949V18.1641H15.3828V16.4949C15.3828 16.4949 14.0071 13.2549 9.49096 13.2549H9.49055Z"
      fill="currentColor"
    />
    <path
      d="M15.8684 10.2481C17.2505 10.2481 18.3709 9.16385 18.3709 7.82633C18.3709 6.48881 17.2505 5.40454 15.8684 5.40454C14.4863 5.40454 13.3658 6.48881 13.3658 7.82633C13.3658 9.16385 14.4863 10.2481 15.8684 10.2481Z"
      fill="currentColor"
    />
    <path
      d="M15.873 12.2731C14.8961 12.2343 13.9326 12.5096 13.1238 13.0588C15.3821 14.1388 16.2656 16.0041 16.2656 16.2006V16.2989H20.2909V15.0223C20.2909 14.9233 19.2109 12.2731 15.873 12.2731Z"
      fill="currentColor"
    />
    <path
      d="M15.8684 10.2481C17.2505 10.2481 18.3709 9.16385 18.3709 7.82633C18.3709 6.48881 17.2505 5.40454 15.8684 5.40454C14.4863 5.40454 13.3658 6.48881 13.3658 7.82633C13.3658 9.16385 14.4863 10.2481 15.8684 10.2481Z"
      fill="currentColor"
    />
    <path
      d="M15.873 12.2731C14.8961 12.2343 13.9326 12.5096 13.1238 13.0588C15.3821 14.1388 16.2656 16.0041 16.2656 16.2006V16.2989H20.2909V15.0223C20.2909 14.9233 19.2109 12.2731 15.873 12.2731Z"
      fill="currentColor"
    />
  </svg>
);

export default Users;
