import React, { useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import ScrollPanel from '../ScrollPanel/ScrollPanel';
import { ErrorMessage, Loader } from 'components/elements';
import { call, isNil } from 'utils';
import { classes } from 'utils/components';
import { useWindowSize } from 'components/hooks';

import styles from './PDFViewer.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Props = {
  file: string | ArrayBuffer;
  onLoad?: (success: boolean) => void;
};

const PDFViewer: React.FC<Props> = ({ file, onLoad }) => {
  const [numPages, setNumPages] = useState<number>();
  const [, setPagesLoaded] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const { width: fixedWidth } = useWindowSize();

  const modalWidth = fixedWidth / 2;

  return (
    <div
      ref={ref}
      className={classes(styles.pdfViewer, isNil(numPages) ? '' : styles.fade)}
      style={{ width: modalWidth, minWidth: '50vw' }}
    >
      <ScrollPanel hBar={{ visible: false }}>
        <Document
          className={styles.document}
          file={file}
          loading={<Loader size={60} />}
          error={<ErrorMessage />}
          onLoadSuccess={({ numPages }) => {
            setNumPages(numPages);
            call(onLoad, true);
          }}
          onLoadError={() => call(onLoad, false)}
          externalLinkTarget="_blank"
        >
          {Array.from(new Array(numPages), (_el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={modalWidth}
              loading=" "
              onLoadSuccess={() => setPagesLoaded(pages => pages + 1)}
            />
          ))}
        </Document>
      </ScrollPanel>
    </div>
  );
};

export default PDFViewer;
