import { CellConfig, ColumnConfig, RowConfig } from './types';

export const parseTableConfig = (config: ColumnConfig) => {
  const rows: RowConfig[] = [];

  let x = 0;
  let y = 0;

  const recursive = (config: ColumnConfig, y: number) => {
    for (const item of config) {
      if (!rows[y]) {
        rows[y] = [];
      }

      if ((item as any).field) {
        rows[y][x] = { ...item, rowspan: 1, colspan: 1 };
        ++x;
      } else {
        rows[y][x] = { title: item.title, rowspan: 1, colspan: 1 };
        recursive((item as any).children, y + 1);
      }
    }
  };

  recursive(config, y);

  for (const row of rows) {
    let node = row[0];
    let span = 1;

    for (let j = 1; j < x; ++j) {
      if (row[j]) {
        if (node && !node.field) {
          node.colspan = span;
        }

        node = row[j];
        span = 1;
      } else {
        ++span;
      }
    }

    if (node && !node.field) {
      node.colspan = span;
    }
  }

  const fieldNodes: CellConfig[] = [];

  for (let j = 0; j < x; ++j) {
    let node = rows[0][j];
    let span = 1;

    for (let i = 1; i < rows.length; ++i) {
      if (rows[i][j]) {
        if (node) {
          node.rowspan = span;
        }

        node = rows[i][j];
        span = 1;
      } else {
        ++span;
      }
    }

    if (node) {
      node.rowspan = span;
      fieldNodes.push(node);
    }
  }

  for (let i = 0; i < rows.length; ++i) {
    rows[i] = rows[i].filter(x => x);
  }

  doLayout(fieldNodes);

  return { header: rows, body: fieldNodes };
};

export const doLayout = (config: { weight?: number; width?: number }[]) => {
  let totalWeight = 0;
  let maxWeight = 0;

  let index = 0;
  let maxIndex = 0;

  for (const item of config) {
    const weight: number = item.weight || 1;
    totalWeight += weight;

    if (weight > maxWeight) {
      maxWeight = weight;
      maxIndex = index;
    }

    ++index;
  }

  const rate = 100.0 / totalWeight;
  let totalPercent = 0;

  for (const item of config) {
    item.width = Math.round((item.weight || 1) * rate);
    totalPercent += item.width;
  }

  config[maxIndex].width = (config[maxIndex].width || 0) + (100 - totalPercent);
};
