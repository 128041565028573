import React, { ReactNode } from 'react';

import { findComponentsByGroup } from 'utils/components';
import styles from './Panel.module.scss';

const Content = () => <div></div>;
const Footer = () => <div></div>;

type Props = {
  title?: string;
  style?: object;
  children?: ReactNode;
};

const Panel: React.FC<Props> & { Content: React.FC; Footer: React.FC } = props => {
  const { title, style, children } = props;
  const content = findComponentsByGroup(children, Content);
  const footer = findComponentsByGroup(children, Footer);

  return (
    <div className={styles.panel} style={style}>
      {title ? <span className={styles.title}>{title}</span> : null}
      {content}
      {footer ? <div className={styles.footer}>{footer}</div> : null}
    </div>
  );
};

Panel.Content = Content;
Panel.Footer = Footer;

export default Panel;
