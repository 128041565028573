import React, { useState } from 'react';

import { useTranslationX } from 'i18n';
import { MenuItem } from 'pages/dashboard/types';
import { Tab, Tabs } from 'components/containers';
import { Form } from 'components/form';
import { Info } from 'components/icons';
import { renderComponent } from 'utils/components';
import { SectionProps } from '../ProposalEditor/ProposalEditor';
import { Schedule, Milestones } from '../';

import styles from './MilestoneSection.module.scss';
import { WrapperCard } from 'components/elements';

const items: MenuItem[] = [
  { key: 'schedule', component: Schedule },
  { key: 'milestones', component: Milestones },
];

const MilestoneSection = ({
  state: { id, editable, showErrors, tabErrors, forms, section },
  formProps,
}: SectionProps) => {
  const { tx } = useTranslationX('macroentregas.sections', 'proposal');

  const [currTab, setCurrTab] = useState(0);

  const onChangeTab = (index: number) => {
    setCurrTab(-1);
  };

  const createAdornment = (item: string) => {
    let adornment = <></>;

    if (tabErrors && tabErrors['metas-macro'] && tabErrors['metas-macro'][item]) {
      let className = !!tabErrors['metas-macro'][item].warnings
        ? styles.adornmentWarn
        : '';

      className = !!tabErrors['metas-macro'][item].errors
        ? styles.adornmentError
        : className;

      adornment = (
        <div className={className}>
          <Info />
        </div>
      );
    }

    return adornment;
  };

  return (
    <Form baseUrl={`/proposta/${id}/milestones`} {...formProps} name={formProps.name}>
      <WrapperCard padding="5px 15px 15px" margin="0 0 1rem" responsive>
        <Tabs selectedIndex={currTab} onChangeTab={onChangeTab}>
          {items.map(({ key, component }) => (
            <Tab
              title={tx(key)}
              key={key}
              adornment={showErrors ? createAdornment(key) : undefined}
            >
              {renderComponent(component, {
                editable,
                instance: section ? forms[section] : null,
                setCurrTab,
              })}
            </Tab>
          ))}
        </Tabs>
      </WrapperCard>
    </Form>
  );
};

export default MilestoneSection;
