import React from 'react';

import FieldAdapter, { Props as FieldProps } from './FieldAdapter';
import Element, { Props } from 'components/elements/FileUploader/FileUploader';

const FileUploader = (props: Props & FieldProps) => (
  <FieldAdapter {...props} dataType="file">
    {props => <Element {...props} />}
  </FieldAdapter>
);

FileUploader.displayName = 'FileUploaderField';
export default FileUploader;
