import React, { useMemo } from 'react';

import { useTranslationX } from 'i18n';
import { TableData } from 'components/containers';
import { TotalLabel } from 'pages/proposal';

import styles from './Financial.module.scss';

type Props = {
  parteId: number;
  fonteRecursoStatus: number;
};

const Others = (props: Props) => {
  const { tx } = useTranslationX('financial', 'update_wp');
  const { parteId, fonteRecursoStatus } = props;
  const groupBy = useMemo(
    (): (string | number)[][] => [
      ['fonteRecurso', Number(parteId)],
      ['fonteRecursoStatus', Number(fonteRecursoStatus)],
    ],
    [parteId, fonteRecursoStatus]
  );
  return (
    <div
      className={styles.financial}
      style={{
        width: '55vw',
      }}
    >
      <TotalLabel tableName="outros" columnName="valor" groupBy={groupBy} />

      <div className={styles.table}>
        <TableData
          name="outros"
          groupBy={groupBy}
          hiddenFields={[
            'valorExecutado',
            'valorBruto',
            'mesInicio',
            'mesExecucao',
            'fonteRecursoStatus',
          ]}
          textSize="small"
          style={{ table: { width: '50vw' } }}
          config={[
            {
              title: tx('description'),
              field: 'descricao',
              align: 'left',
              forceMutability: true,
            },
            {
              title: tx('total-value'),
              field: 'valor',
              type: 'computed',
              metadata: {
                rules: {
                  mask: { pattern: 'currency' },
                  minWithExpression: 'valorExecutado + valorAnalise + valorAprovado',
                  showErrors: false,
                },
              },
              fieldProps: {
                align: 'right',
                expression:
                  'valorDisponivel + valorExecutado + valorAnalise + valorAprovado',
              },
              align: 'right',
              weight: 0.5,
            },
            {
              title: tx('value-available'),
              field: 'valorDisponivel',
              forceMutability: true,
              metadata: {
                rules: {
                  mask: { pattern: 'currency' },
                  min: 0,
                },
              },
              fieldProps: { align: 'right' },
              align: 'right',
              weight: 0.5,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Others;
