import React, { createRef } from 'react';
import { useHistory } from 'react-router-dom';

import { Card, Grid, Row } from 'components/containers';
import {
  DatePicker,
  Form,
  HiddenField,
  SubmitButton,
  TextArea,
  TextField,
} from 'components/form';
import { Button } from 'components/elements';
import { useProjectEditor } from 'pages/project/ProjectEditor/contexts';
import { RoleItem } from 'pages/project/types';
import { FormInstance } from 'components/form/types';
import { useTranslationX } from 'i18n';
import { useMediaQuery } from 'components/hooks';

import styles from './TransferPersonForm.module.scss';

const TransferPersonForm = () => {
  const history = useHistory();
  const { state } = useProjectEditor();
  const { tx } = useTranslationX('request.form', 'project');
  const form = createRef<FormInstance>();

  const isCLT = state.contract?.projetoRH?.vinculo === 'CELETISTA';
  const remuneration = (state?.item as RoleItem)?.remuneracao;
  const contractRemuneration = state.contract?.projetoRH?.remuneracao;

  const matchesRemuneration = remuneration === contractRemuneration;

  const matches = useMediaQuery(`(min-width: 1920px)`);

  const gridMaxWidth = matches ? 1200 : 900;

  const renderRowWidth = () => {
    if (!(!matchesRemuneration && !isCLT)) {
      return [4, 0, 3, 6];
    }
    return [3, 3, 3];
  };

  const datePickerLabel = isCLT ? tx('transferDate') : tx('scholarshipStart');

  return (
    <Form
      ref={form}
      submit={{ format: 'multipart', method: 'post' }}
      baseUrl={`/projeto/${state?.id}/execucao/recursos-humanos/solicitacoes/transferencia-rh/${state.contract?.projetoRHId}/${state?.item?.id}`}
      onDone={() => history.push(`/dashboard/projetos/${state?.id}`)}
    >
      <div className={styles.card}>
        <h2>{tx('title.transfer-person')}</h2>
        <Card width={{ max: '600px', min: '600px' }}>
          <div className={styles.container}>
            <Grid maxWidth={600}>
              <Row width={[5]}>
                <TextField
                  name="nome"
                  type="readonly"
                  value={state.contract?.pessoa.nome}
                  label={tx('name.label')}
                  placeholder={tx('name.placeholder')}
                />
              </Row>
            </Grid>
            <Grid maxWidth={600}>
              <Row width={[5]}>
                <TextArea
                  name="justificativa"
                  minRows={3}
                  label={tx('justificativa.label')}
                  placeholder={tx('justificativa.placeholder')}
                />
              </Row>
            </Grid>
            <Grid width={gridMaxWidth} maxWidth={600}>
              {!isCLT && <h1>{tx('scholarshipData')}</h1>}
              <Row width={renderRowWidth()}>
                <DatePicker name="dataInicio" theme="classic" label={datePickerLabel} />

                {!matchesRemuneration && !isCLT && (
                  <TextField name="cargaHoraria" label={tx('monthlyWorkload')} />
                )}

                {!isCLT && <TextField name="duracao" label={tx('durationMonths')} />}

                {isCLT && (
                  <HiddenField name="duracao" value={(state?.item as RoleItem).duracao} />
                )}
              </Row>
            </Grid>
            <Grid>
              <div className={styles.buttonContainer}>
                <Button type="secondary" onClick={() => history.goBack()}>
                  {tx('cancel')}
                </Button>
                <SubmitButton style={{ root: { width: 250 } }}>
                  {tx('transfer-person')}
                </SubmitButton>
              </div>
            </Grid>
          </div>
        </Card>
      </div>
    </Form>
  );
};
export default TransferPersonForm;
