import React, { useRef } from 'react';

import { call } from 'utils';
import { classes } from 'utils/components';
import { Ripple } from '..';

import styles from './Switch.module.scss';

type Props = {
  on: boolean;
  onChange?: (on: boolean) => void;
  type?: 'disabled';
  label?: string;
};

const Switch: React.FC<Props> = ({ on, type, onChange, label }) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  const onClick = () => call(onChange, !on);

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === ' ') {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <div
      className={classes(
        styles.switch,
        on ? styles.active : null,
        type ? styles[type] : null
      )}
      onClick={onClick}
      onKeyDown={onKeyDown}
      aria-disabled={type === 'disabled'}
      ref={rootRef}
      tabIndex={type === 'disabled' ? -1 : 0}
    >
      {label ? <div className={styles.label}>{label}</div> : null}
      <div className={styles.control} tabIndex={-1}>
        <div className={styles.track}></div>
        <div className={styles.button} ref={buttonRef}>
          <div className={styles.thumb}></div>

          {type !== 'disabled' ? (
            <Ripple parentRef={rootRef} boundingRef={buttonRef} actionKey=" "></Ripple>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Switch;
