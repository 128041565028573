import React from 'react';

const ArrowUp = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none">
    <path
      d="m9.89 10.7v9.29c0 1.11 0.949 2.02 2.11 2.02 1.16 0 2.11-0.911 2.11-2.02v-9.29h4.85c0.928 0 1.39-1.09 0.738-1.72l-6.94-6.66c-0.422-0.405-1.08-0.405-1.5 0l-6.94 6.66c-0.675 0.628-0.211 1.72 0.738 1.72z"
      clipRule="evenodd"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default ArrowUp;
