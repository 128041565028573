import React from 'react';
import { BiWorld } from 'react-icons/bi';

import { useTranslationX } from 'i18n';
import { HStack, Modal, VStack } from 'components/containers';
import { Form, SubmitButton, TextField } from 'components/form';
import { Button, Spacer } from 'components/elements';
import { useVisible } from 'components/hooks';
import { UI_BUTTON_SPACING } from 'consts';

type Props = {
  visible: boolean;
  onConfirm: (url: string) => void;
  onCancel: () => void;
  onRemove: () => void;
  value?: string;
};

const LinkingModal = (props: Props) => {
  const { onConfirm, onCancel, onRemove, value } = props;
  const { visible, setVisible } = useVisible(props.visible);

  const { tx } = useTranslationX('components.richEditor.link-modal');

  return (
    <Modal
      visible={visible}
      onBlur={() => {
        setVisible(false);
        onCancel();
      }}
      width={500}
    >
      <VStack spacing={32} align="stretch">
        <Form
          display={{ error: 'touched' }}
          onDone={({ requestBody }) => onConfirm(requestBody.url)}
        >
          <TextField
            name="url"
            label={tx('input-label')}
            icon={BiWorld}
            value={value}
            metadata={{ rules: { required: true, url: true } }}
          />

          <HStack align="right">
            <Button type="secondary" onClick={onCancel}>
              {tx('cancel-button')}
            </Button>

            {!!value && (
              <>
                <Spacer length={UI_BUTTON_SPACING} />
                <Button type="secondary" onClick={onRemove}>
                  {tx('remove-button')}
                </Button>
              </>
            )}

            <Spacer length={UI_BUTTON_SPACING} />
            <SubmitButton autoDisable>{tx('confirm-button')}</SubmitButton>
          </HStack>
        </Form>
      </VStack>
    </Modal>
  );
};

export default LinkingModal;
