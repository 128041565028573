import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslationX } from 'i18n';
import { Button } from 'components/elements';
import { Form, HiddenField, SubmitButton, TextField } from 'components/form';
import { ErrorDialog, SuccessDialog } from 'components/containers';

import styles from './ResetPasswordForm.module.scss';

const ResetPasswordForm = () => {
  const [status, setStatus] = useState<'error' | 'success'>();
  const { tx } = useTranslationX('login.reset');
  const history = useHistory();

  return (
    <div className={styles.reset}>
      <Form
        baseUrl="/login/reset"
        onError={(error, instance) => {
          if (error.response?.status === 404) {
            instance.context.reset();
            setStatus('error');
            return 'handled';
          }
        }}
        onDone={() => setStatus('success')}
      >
        <div className={styles.title}>{tx('title')}</div>
        <div className={styles.subtitle}>{tx('subtitle')}</div>

        <HiddenField name="host" value={window.location.origin} />

        <div style={{ padding: '30px 0' }}>
          <TextField name="cpf" label={tx('id')} />
        </div>

        <div className={styles.buttons}>
          <Button
            style={{ root: { width: 145 } }}
            type="secondary"
            onClick={() => history.push('/login')}
          >
            {tx('button.cancel')}
          </Button>

          <SubmitButton>{tx('button.submit')}</SubmitButton>
        </div>
      </Form>

      <ErrorDialog
        visible={status === 'error'}
        message={tx('failed')}
        onClose={() => setStatus(undefined)}
      />

      <SuccessDialog
        visible={status === 'success'}
        message={tx('success')}
        onClose={() => history.push('/login')}
      />
    </div>
  );
};

export default ResetPasswordForm;
