import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Logo, WrapperCard } from 'components/elements';
import { useTranslationX } from 'i18n';

import { version } from '../../../../package.json';

import { useFetch } from 'apis';
import { classes } from 'utils/components';
import Tippy from '@tippyjs/react';

import styles from './VersionControl.module.scss';

export type Version = {
  app_version: string;
  app_revision?: string;
};

type VersionResponse = {
  api: Version;
  web: Version;
};

type Props = {
  type?: 'full' | 'footer';
};

const VersionControl: React.FC<Props> = props => {
  const { type = 'full' } = props;
  const { t, tx } = useTranslationX('components.versionControl', 'translation');

  const [localVersion, setLocalVersion] = useState<Version>({
    app_version: version,
    app_revision: '...',
  });

  const { data } = useFetch<VersionResponse>('/version/');

  const remoteVersion = data?.web;

  const apiVersion = data?.api;

  const fetchVc = useCallback(async () => {
    try {
      const response = await fetch('/vc.json');
      const data = await response.json();
      setLocalVersion(data);
    } catch (e) {
      console.error('Failed to fetch version information');
    }
  }, []);

  const handleRefresh = async () => {
    if (caches) {
      caches.keys().then(names => {
        for (let name of names) caches.delete(name);
      });
    }

    window.location.reload();
  };

  const needsUpdate = useMemo(() => {
    if (data?.web) {
      const remoteWebVersion = data.web.app_version;
      const remoteWebRevision = data.web.app_revision;
      const localWebVersion = localVersion.app_version;
      const localWebRevision = localVersion.app_revision;

      return (
        localWebRevision !== remoteWebRevision || localWebVersion !== remoteWebVersion
      );
    }
    return false;
  }, [data, localVersion]);

  useEffect(() => {
    fetchVc();
  }, [fetchVc]);

  const renderWebVersion = useCallback(() => {
    return (
      <Tippy
        className={classes(styles.popup)}
        disabled={remoteVersion === undefined}
        content={
          <p>
            {tx('expectedVersion')}
            <b>
              {tx('formatted', {
                version: remoteVersion?.app_version,
                revision: remoteVersion?.app_revision,
              })}
            </b>
          </p>
        }
        animation="perspective"
        touch={false}
      >
        <p>
          {tx('version')}
          <b>
            {tx('formatted', {
              version: localVersion.app_version,
              revision: localVersion.app_revision,
            })}
          </b>
        </p>
      </Tippy>
    );
  }, [localVersion, remoteVersion, tx]);

  if (type === 'footer') {
    return <div className={styles.footer}>{renderWebVersion()}</div>;
  }

  return (
    <div className={styles.versionControl}>
      <WrapperCard minWidth="500px" height="300px" padding="20px">
        <div className={styles.container}>
          <div className={styles.header}>
            <Logo />
            <h2>{t('sippi')}</h2>
          </div>
          <div className={styles.body}>
            {renderWebVersion()}
            {apiVersion ? (
              <p>
                {tx('apiVersion')}
                <b>
                  {tx('formattedNoRevision', {
                    version: apiVersion.app_version,
                  })}
                </b>
              </p>
            ) : null}
            <div className={styles.updateAlert}>
              <span>{tx(needsUpdate ? 'existsUpdate' : 'alreadyUpToDate')}</span>
              {needsUpdate ? (
                <Button onClick={handleRefresh} theme="classic">
                  {tx('update')}
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </WrapperCard>
    </div>
  );
};

export default VersionControl;
