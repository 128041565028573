import { ScrollPanel } from 'components/containers';
import React, { Fragment, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import ItemTopSubMenu from './ItemTopSubMenu/ItemTopSubMenu';

import styles from './TopSubMenu.module.scss';

export type TopSubMenuNode = {
  label?: string;
  path: string;
  adornment?: JSX.Element;
  icon: React.FC<{ size: number }>;
  hasChanges?: boolean;
};

type Props = {
  blocked?: boolean;
  child: TopSubMenuNode[];
};

const TopSubMenu: React.FC<Props> = ({ child, blocked = false }) => {
  const { url } = useRouteMatch();
  const [selected, setSelected] = useState<string>(child[0].path);

  return (
    <div className={styles.subMenuHorizontal}>
      <div className={styles.wrapper}>
        <ScrollPanel>
          <ul>
            {child.map(({ label, path, adornment, icon, hasChanges }, index) => {
              const blockedNode = selected !== path && blocked;
              const Item = (
                <ItemTopSubMenu
                  key={index}
                  onClick={() => setSelected(path)}
                  icon={icon}
                  blocked={blockedNode}
                  label={label || ''}
                  selected={selected === path}
                  adornment={adornment}
                  hasChanges={hasChanges}
                />
              );
              return (
                <Fragment key={index}>
                  {blockedNode && <div style={{ cursor: 'not-allowed' }}>{Item}</div>}
                  {!blockedNode && <Link to={`${url}/${path}`}>{Item}</Link>}
                </Fragment>
              );
            })}
          </ul>
        </ScrollPanel>
      </div>
    </div>
  );
};
export default TopSubMenu;
