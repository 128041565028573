import React from 'react';

import { useFetch } from 'apis';
import { useTranslationX } from 'i18n';

import { Button, GoBackButton } from 'components/elements';

import { IconType, People } from '../types';
import { Redirect, Email } from 'components/icons';
import { ReadOnlyTableData, ReadOnlyTableDataConfig } from 'components/containers';
import { useParams } from 'react-router-dom';
import { uniqueId } from 'lodash';

import styles from './PeopleEditor.module.scss';

type Props = {
  id: string;
  lattes: string;
  categoria: 'Estudante' | 'Colaborador' | 'Pesquisador' | 'Supervisor';
  email: string;
};

type ActionProps = {
  text: string;
  icon: (size: IconType) => JSX.Element;
  onClick?: () => void;
};

const PeopleEditor: React.FC<Props> = ({ email, lattes, categoria }) => {
  const { id } = useParams<{
    id?: string;
  }>();
  const { tx } = useTranslationX('detail', 'people');

  const { data } = useFetch<People>(`/pessoa/${id}`);

  const buttons: ActionProps[] = [
    ...(lattes !== null
      ? [
          {
            text: tx('actions.lattes'),
            icon: Redirect,
            onClick: () => {
              window.open(lattes, '_blank');
            },
          },
        ]
      : []),
    ...(email !== null
      ? [
          {
            text: tx('actions.contact'),
            icon: Email,
            onClick: () => {
              window.open(`mailto:${email}`, '_blank');
            },
          },
        ]
      : []),
  ];

  const emptyItem = (key: string) => {
    return {
      title: '',
      field: uniqueId(key),
      width: 25,
      parse: () => <></>,
    };
  };

  const tableConfig: ReadOnlyTableDataConfig[] = [
    {
      title: tx('personal_data.title'),
      tableRows: [
        [
          {
            title: tx('personal_data.nome'),
            field: 'nome',
            width: 25,
          },
          emptyItem('0'),
          emptyItem('1'),
          {
            title: tx('personal_data.cpf'),
            field: 'cpf',
            width: 25,
          },
        ],
        [
          {
            title: tx('personal_data.email'),
            field: 'email',
            width: 25,
          },
          ...(data?.genero
            ? [
                {
                  title: tx('personal_data.sexo'),
                  field: 'genero',
                  width: 25,
                },
              ]
            : [emptyItem('2')]),
          {
            title: tx('personal_data.telefone'),
            field: 'telefone',
            width: 25,
          },
          emptyItem('3'),
        ],
        [
          ...(data?.dataNascimento
            ? [
                {
                  title: tx('personal_data.data_nascimento'),
                  field: 'dataNascimento',
                  width: 25,
                  type: 'date' as const,
                },
              ]
            : [emptyItem('4')]),

          ...(data?.rg
            ? [
                {
                  title: tx('personal_data.rg'),
                  field: 'rg',
                  width: 25,
                },
              ]
            : [emptyItem('5')]),

          ...(data?.rgEmissor
            ? [
                {
                  title: tx('personal_data.orgao_emissor'),
                  field: 'rgEmissor',
                  width: 25,
                },
              ]
            : [emptyItem('6')]),

          ...(data?.rgUf
            ? [
                {
                  title: tx('personal_data.uf'),
                  field: 'rgUf',
                  width: 25,
                },
              ]
            : [emptyItem('7')]),
        ],
        [
          {
            title: tx('personal_data.endereco.cep'),
            field: 'endereco.cep',
            width: 25,
          },
          {
            title: tx('personal_data.endereco.logradouro'),
            field: 'endereco.logradouro',
            width: 25,
          },
          {
            title: tx('personal_data.endereco.numero'),
            field: 'endereco.numero',
            width: 25,
          },
          emptyItem('8'),
        ],
        [
          {
            title: tx('personal_data.endereco.bairro'),
            field: 'endereco.bairro',
            width: 25,
          },
          {
            title: tx('personal_data.endereco.cidade'),
            field: 'endereco.cidade',
            width: 25,
          },
          {
            title: tx('personal_data.endereco.estado'),
            field: 'endereco.uf',
            width: 25,
          },
          {
            title: tx('personal_data.endereco.complemento'),
            field: 'endereco.complemento',
            defaultValue: tx('personal_data.none_data'),
            width: 25,
          },
        ],
      ],
    },
  ];

  if (categoria === 'Estudante' && data?.aluno) {
    tableConfig.push({
      title: tx('estudante.title'),
      tableRows: [
        [
          {
            title: tx('estudante.instituicao'),
            field: 'aluno.instituicao',
            width: 30,
          },
          {
            title: tx('estudante.matricula'),
            field: 'aluno.matricula',
            width: 0,
          },
          {
            title: tx('estudante.areaFormacao'),
            field: 'aluno.areaFormacao',
            width: 0,
          },
          {
            title: tx('estudante.titulacao'),
            field: 'aluno.titulacao',
            width: 0,
          },
        ],
        [
          {
            title: tx('estudante.curso'),
            field: 'aluno.curso',
            width: 5,
          },
          {
            title: tx('estudante.nivelCurso'),
            field: 'aluno.nivelCurso',
            width: 5,
          },
        ],
        [
          {
            title: tx('estudante.areaPesquisa'),
            field: 'aluno.areaPesquisa',
            width: 0,
          },
        ],
        [
          {
            title: tx('estudante.arquivos'),
            field: 'aluno.arquivos',
            width: 0,
            type: 'archives',
          },
        ],
      ],
    });
  }
  if (categoria === 'Pesquisador' && data?.pesquisador) {
    tableConfig.push({
      title: tx('pesquisador.title'),
      tableRows: [
        [
          {
            title: tx('pesquisador.areaFormacao'),
            field: 'pesquisador.areaFormacao',
            width: 0,
          },
          {
            title: tx('pesquisador.titulacao'),
            field: 'pesquisador.titulacao',
            width: 0,
          },
          {
            title: tx('pesquisador.campus'),
            field: 'pesquisador.campus',
            width: 15,
          },
          {
            title: tx('pesquisador.siape'),
            field: 'pesquisador.siape',
            width: 15,
          },
        ],
        [
          {
            title: tx('pesquisador.areaPesquisa'),
            field: 'pesquisador.areaPesquisa',
            width: 0,
          },
        ],
        [
          {
            title: tx('pesquisador.arquivos'),
            field: 'pesquisador.arquivos',
            width: 0,
            type: 'archives',
          },
        ],
      ],
    });
  }
  if (categoria === 'Colaborador' && data?.colaborador) {
    tableConfig.push({
      title: tx('colaborador.title'),
      tableRows: [
        [
          {
            title: tx('colaborador.areaFormacao'),
            field: 'colaborador.areaFormacao',
            width: 0,
          },
          {
            title: tx('colaborador.titulacao'),
            field: 'colaborador.titulacao',
            width: 0,
          },
          {
            title: tx('colaborador.campus'),
            field: 'colaborador.campus',
            width: 15,
          },
          {
            title: tx('colaborador.siape'),
            field: 'colaborador.siape',
            width: 15,
          },
        ],
        [
          {
            title: tx('colaborador.instituicao'),
            field: 'colaborador.instituicao',
            width: 0,
          },
          {
            title: tx('colaborador.areaPesquisa'),
            field: 'colaborador.areaPesquisa',
            width: 0,
          },
        ],
        [
          {
            title: tx('colaborador.arquivos'),
            field: 'colaborador.arquivos',
            width: 0,
            type: 'archives',
          },
        ],
      ],
    });
  }

  return (
    <div className={styles.peopleView}>
      <div className={styles.peopleViewHeader}>
        <GoBackButton />
        <div>
          <h2>{tx('sub_title')}</h2>
          <h1>{tx('title')}</h1>
        </div>
        <div className={styles.infoButtons}>
          {buttons.map((data, index) => (
            <Button
              style={{
                root: {
                  marginLeft: 13,
                  minWidth: 215,
                },
                text: { fontFamily: 'Roboto', fontWeight: 500 },
              }}
              icon={data.icon}
              theme="light"
              key={index}
              onClick={() => data.onClick && data.onClick()}
            >
              {data.text}
            </Button>
          ))}
        </div>
      </div>
      <ReadOnlyTableData<People> data={data} config={tableConfig} />
    </div>
  );
};

export default PeopleEditor;
