import React, { useState, useEffect } from 'react';

import { splitValueUnit } from 'utils';
import styles from './Ripple.module.scss';

type Props = {
  shape?: 'square' | 'circle';
  size?: string;
  folowCursor?: boolean;
  parentRef: React.RefObject<HTMLElement>;
  boundingRef?: React.RefObject<HTMLElement>;
  actionKey?: string;
};

type Animation = {
  key: number;
  visible?: boolean;
  exiting?: boolean;
  bounds?: {
    top: number;
    left: number;
    width: number;
    height: number;
  };
};

const Ripple: React.FC<Props> = props => {
  const {
    shape = 'circle',
    size = '1w',
    folowCursor,
    parentRef,
    boundingRef,
    actionKey = 'Enter',
  } = props;

  const [animation, setAnimation] = useState<Animation>({ key: 0 });

  useEffect(() => {
    const parent = parentRef.current;

    if (!parent) {
      return;
    }

    const bounding = boundingRef?.current || parent;

    const enter = (event?: { clientX: any; clientY: any }) => {
      const bounds = getBounds(
        size,
        bounding.getBoundingClientRect(),
        folowCursor && event ? { x: event.clientX, y: event.clientY } : undefined
      );

      parent.setAttribute('data-active', 'true');
      setAnimation(animation => ({
        visible: true,
        key: (animation.key % 2) + 1,
        bounds,
      }));
    };

    const leave = () => {
      parent.removeAttribute('data-active');
      setAnimation(animation => ({ ...animation, exiting: true }));
    };

    const keyDown = (event: { key: string }) => {
      if (event.key === actionKey) {
        enter();

        setTimeout(() => {
          leave();
        }, 300);
      }
    };

    parent.addEventListener('mousedown', enter);
    parent.addEventListener('mouseup', leave);
    parent.addEventListener('mouseleave', leave);
    parent.addEventListener('keydown', keyDown);

    return () => {
      parent.removeEventListener('mousedown', enter);
      parent.removeEventListener('mouseup', leave);
      parent.removeEventListener('mouseleave', leave);
      parent.removeEventListener('keydown', keyDown);
    };
  }, [size, parentRef, folowCursor, boundingRef, actionKey]);

  return (
    <div
      key={animation.key}
      className={styles.ripple}
      style={animation.bounds}
      data-visible={animation.visible || null}
      data-exiting={animation.exiting || null}
    >
      <div className={styles[shape]}></div>
    </div>
  );
};

function getBounds(pattern: string, rect: DOMRect, center?: { x: number; y: number }) {
  const { value, unit } = splitValueUnit(pattern);
  const size = value * (unit === 'w' ? rect.width : unit === 'h' ? rect.height : 1);

  if (center) {
    center.x -= rect.left;
    center.y -= rect.top;
  } else {
    center = {
      x: rect.width / 2,
      y: rect.height / 2,
    };
  }

  return {
    left: center.x - size / 2,
    top: center.y - size / 2,
    width: size,
    height: size,
  };
}

export default Ripple;
