import React, { useCallback, useEffect, useReducer } from 'react';

import { useTranslationX } from 'i18n';
import { Modal } from 'components/containers';
import { Button, ErrorMessage, Loader } from 'components/elements';
import { useVisible } from 'components/hooks';
import { XCircle } from 'components/icons';
import { Counter, Form, SubmitButton, TextArea, useForm } from 'components/form';
import { Action as EapAction, Entry, ModalConfig } from './types';

import styles from './EditDialog.module.scss';

type Props = {
  config: ModalConfig;
  dispatch: React.Dispatch<EapAction>;
  editable: boolean;
};

type DataCollect = { nome: string; descricao: string; criterios: string };
type DataCreate = DataCollect & { pai?: number | null; index: number };

type Action =
  | { type: 'CREATE'; payload: DataCreate }
  | { type: 'DELETE'; payload: { index: number } }
  | { type: 'ERROR' };

type State = {
  status: 'init' | 'creating' | 'deleting' | 'error';
  createData?: DataCreate;
  deleteData?: { index: number };
  loading?: boolean;
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'CREATE':
      return { status: 'creating', createData: action.payload, loading: true };

    case 'DELETE':
      return { status: 'deleting', deleteData: action.payload, loading: true };

    case 'ERROR':
      return { status: 'error' };

    default:
      return state;
  }
};

const EditDialog = ({ config, dispatch: parentDispatch, editable }: Props) => {
  const [state, dispatch] = useReducer(reducer, { status: 'init' });
  const form = useForm();

  const { tx } = useTranslationX('eap.dialog', 'update_wp');
  const { visible, setVisible } = useVisible(true);

  const listName = 'eap';
  const editItem =
    config.mode === 'edit'
      ? form.getFieldValue<Entry>(`${listName}[${config.index}]`)
      : undefined;

  const lastEditItem =
    config.mode === 'edit' && form.showDiff
      ? form.getLastFieldValue<Entry>(`${listName}[${config.index}]`)
      : undefined;

  const update = useCallback(
    (index: number, data: Entry, create?: boolean) => {
      const path = `${listName}[${index}]`;

      form.setFieldValue(`${path}.id`, data.id, create);
      form.setFieldValue(`${path}.ordem`, data.ordem, create);
      form.setFieldValue(`${path}.criterios`, data.criterios, create);
      form.setFieldValue(`${path}.pai`, data.pai, create);
      form.setFieldValue(`${path}.nome`, data.nome, create);
      form.setFieldValue(`${path}.descricao`, data.descricao, create);

      setVisible(false);
    },
    [form, setVisible]
  );

  useEffect(() => {
    if (state.status === 'creating') {
      if (!state.createData) {
        dispatch({ type: 'ERROR' });
        return;
      }

      const { pai, index, nome, descricao, criterios } = state.createData;

      form.addListItem(
        listName,
        index,
        (success, res) => {
          if (success && res) {
            const { id, ordem } = res.response as { id: number; ordem: number };
            update(index, { id, ordem, pai, nome, descricao, criterios }, true);
          } else {
            dispatch({ type: 'ERROR' });
          }
        },
        { nome, descricao, criterios, pai }
      );
    } else if (state.status === 'deleting') {
      if (state.deleteData) {
        form.removeListItem(listName, state.deleteData.index, success => {
          if (success) {
            setVisible(false);
            form.reload();
          } else {
            dispatch({ type: 'ERROR' });
          }
        });
      }
    }
  }, [form, parentDispatch, setVisible, state, update]);

  const isAdding = config.mode === 'add';

  function SubmitForm(requestBody: object) {
    if (config.mode === 'add') {
      dispatch({
        type: 'CREATE',
        payload: {
          ...(requestBody as DataCollect),
          pai: config.pai,
          index: config.index,
        },
      });
    } else if (editItem) {
      const { nome, descricao, criterios } = requestBody as DataCollect;
      const { id, ordem, pai } = editItem;

      update(config.index, {
        id,
        ordem,
        criterios,
        pai,
        nome,
        descricao,
      });
    } else {
      dispatch({ type: 'ERROR' });
    }
  }

  const renderContent = () => {
    switch (state.status) {
      case 'init': {
        const metadata = form.getFieldMetadata('eap');

        return (
          <Form
            editable={editable}
            metadata={{ rules: metadata.rules }}
            onSubmit={({ requestBody }) => SubmitForm(requestBody)}
          >
            {['nome', 'descricao', 'criterios'].map(el => {
              const isNome = el === 'nome';

              return (
                <div className={styles.box} key={el}>
                  <TextArea
                    name={el}
                    label={tx(el)}
                    minRows={isNome ? 1 : 10}
                    maxRows={isNome ? 1 : 10}
                    value={editItem ? (editItem as any)[el] : undefined}
                    lastValue={lastEditItem ? (lastEditItem as any)[el] : undefined}
                    style={{
                      root: {
                        marginBottom: isNome ? 10 : 20,
                      },
                    }}
                    clientSideDiff={config.mode === 'edit'}
                  />
                  <Counter
                    target={el}
                    style={{
                      marginLeft: 0,
                    }}
                  />
                </div>
              );
            })}

            <div className={styles.buttons}>
              <SubmitButton autoDisable={!editable}>{tx(config.mode)}</SubmitButton>
              {isAdding && (
                <Button type="secondary" onClick={() => setVisible(false)}>
                  {tx('cancel')}
                </Button>
              )}
              {!isAdding && (
                <div
                  className={styles.delete}
                  style={{
                    cursor: editable ? 'pointer' : 'not-allowed',
                  }}
                  onClick={
                    editable
                      ? () =>
                          dispatch({ type: 'DELETE', payload: { index: config.index } })
                      : undefined
                  }
                >
                  <div className={styles.icon}>
                    <XCircle />
                  </div>

                  <span>{tx('delete')}</span>
                </div>
              )}
            </div>
          </Form>
        );
      }

      case 'creating':
      case 'deleting':
        return <Loader />;

      case 'error':
        return <ErrorMessage />;

      default:
        return null;
    }
  };

  return (
    <Modal
      visible={visible}
      onClose={() => parentDispatch({ type: 'SET_MODAL', payload: null })}
      onBlur={() => (state.loading ? null : setVisible(false))}
    >
      <div className={styles.editor}>{renderContent()}</div>
    </Modal>
  );
};

export default EditDialog;
