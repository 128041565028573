import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';

import { useTranslation } from 'i18n';
import { Bars } from 'components/icons';
import { classes, renderComponent } from 'utils/components';
import { DashBoardConfig, MenuItemIcon } from '../types';
import { useAuthState } from 'store/auth';

import styles from './SideMenu.module.scss';

type Props = {
  config: DashBoardConfig;
};

const SideMenu = ({
  config: { sideMenu, footer, showTerms, checkSelectedRoute },
}: Props) => {
  const [selected, setSelected] = useState<string>();
  const [visibility, setVisibility] = useState<'visible' | 'hidden'>();

  const { url } = useRouteMatch();
  const location = useLocation();
  const state = useAuthState();
  const { t } = useTranslation(state.profile);

  useEffect(() => {
    setSelected(location.pathname);
  }, [location.pathname]);

  const isRouteSelected = (
    key: string,
    pathname: string,
    path?: string,
    subMenu?: boolean
  ) => {
    if (key !== 'home') {
      if (subMenu) {
        return checkSelectedRoute?.(selected as string, key, pathname, path);
      }
      return selected?.startsWith(pathname);
    }

    return selected === url || selected?.startsWith(`${url}/${path || key}`);
  };

  const buildMenu = (data: MenuItemIcon[]) =>
    data.map(({ key, icon, path, home, helper, subMenu }, index) => {
      const pathname = key !== 'home' ? `${url}/${home ? home : path || key}` : url;

      return (
        <li
          key={index}
          onClick={() => setSelected(pathname)}
          className={classes(
            isRouteSelected(key, pathname, path, subMenu) ? styles.selected : '',
            subMenu ? styles.subMenu : ''
          )}
        >
          <Link to={pathname}>
            <div className={styles.icon}>{renderComponent(icon)}</div>
            <div className={styles.description}>
              <span>{t(`menu.${key}.title`)}</span>
              {helper && <span className={styles.helper}>{helper}</span>}
            </div>
          </Link>
        </li>
      );
    });

  return (
    <div className={classes(styles.sideMenu, styles[visibility || ''])}>
      <div
        className={styles.trigger}
        onClick={() =>
          setVisibility(status => (status === 'visible' ? undefined : 'visible'))
        }
      >
        <Bars />
      </div>

      <ul>{buildMenu(sideMenu)}</ul>

      <div className={styles.footer}>
        {footer && <ul>{buildMenu(footer)}</ul>}

        {showTerms && (
          <div className={styles.buttons}>
            <Link to="#">{t('sidebar.privacy')}</Link>
            <Link to="#">{t('sidebar.term')}</Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default SideMenu;
