import React, { useMemo } from 'react';
import Tippy from '@tippyjs/react';
import { AiOutlineCheckCircle } from 'react-icons/ai';

import { renderComponent } from 'utils/components';
import { ItemCard } from 'components/containers';
import { KeyValueRow, SpeedometerProgress, Tag } from 'components/elements';
import { useTranslationX } from 'i18n';
import { appendDots, convertHexColorToRGBA } from 'utils/stringUtils';
import { MilestoneReduced, milestoneStatusColors } from '../types';

import styles from './CardMilestone.module.scss';

interface Props {
  data: MilestoneReduced;
  showTermGeneration?: boolean;
  onItemSelected: () => void;
  startDate: number;
}

const CardMilestone: React.FC<Props> = ({
  data,
  onItemSelected,
  showTermGeneration = false,
  startDate,
}) => {
  const { titulo, status, mesInicio, mesFim, conclusao, alteracaoExclusao } = data;
  const { tx } = useTranslationX('milestonesCards', 'project');

  const getCardAction = () => {
    if (showTermGeneration) {
      return {
        label: tx('generateTerm'),
        icon: AiOutlineCheckCircle,
        onClick: onItemSelected,
      };
    }
  };

  const milestoneEndMonth = useMemo(
    () =>
      mesInicio === 0 || mesInicio === 1 ? startDate : startDate + mesFim - mesInicio,
    [mesFim, mesInicio, startDate]
  );

  const milestoneIsExpected = milestoneEndMonth === new Date().getMonth() + 1;

  const renderCardBorders = () => {
    if (status === 'ATR') {
      return `3px solid ${milestoneStatusColors[status]}`;
    }

    if (milestoneIsExpected && status === 'EXE') {
      return `3px solid ${milestoneStatusColors[status]}`;
    }
  };

  const action = getCardAction();

  const renderCardAction = () => {
    return (
      action && (
        <div
          className={styles.order}
          onClick={e => {
            e.stopPropagation();
            if (action.onClick) action.onClick();
          }}
        >
          {renderComponent(action.icon)}
          <span className={styles.label}>{action.label}</span>
        </div>
      )
    );
  };

  return (
    <ItemCard
      width="385px"
      title={
        <Tippy
          content={titulo + tx("alteracao_exclusao")}
          animation="perspective"
          touch={false}
          className={styles.popup}
        >
          <div>
            <KeyValueRow value={appendDots(titulo + (alteracaoExclusao ? tx("alteracao_exclusao") : ""), 30)} size="medium" />
          </div>
        </Tippy>
      }
      style={{
        border: renderCardBorders(),
        opacity: alteracaoExclusao ? 0.5 : 1
      }}
      onClick={alteracaoExclusao ? undefined : onItemSelected}
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <div>
            <KeyValueRow label={tx('startMonth')} value={mesInicio} margin="small" />
            <KeyValueRow label={tx('finishMonth')} value={mesFim} margin="small" />
          </div>
          <div className={styles.status}>
            <Tag
              padding="3px 8px"
              text={tx(`status.${status}`)}
              color={milestoneStatusColors[status]}
            />
          </div>
        </div>
        <div className={styles.extras}>
          <div className={styles.graph}>
            <SpeedometerProgress
              value={conclusao || 0}
              colorGradient={[
                convertHexColorToRGBA(milestoneStatusColors[status], 0.65),
                milestoneStatusColors[status],
              ]}
            />
          </div>
          {renderCardAction()}
        </div>
      </div>
    </ItemCard>
  );
};

export default CardMilestone;
