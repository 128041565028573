import React from 'react';

const Sei = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size * 3}em`} viewBox="0 0 512 512" fill="none">
    <g
      transform="translate(0.000000,530.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M3871 3983 l-22 -18 -5 -755 c-2 -415 -1 -768 2 -783 11 -49 44 -57
223 -56 146 2 162 4 178 21 17 19 18 68 20 788 2 641 0 770 -12 786 -12 17
-28 19 -132 21 -65 1 -143 4 -174 8 -45 4 -60 2 -78 -12z"
      />
      <path
        d="M3468 3971 c-42 -9 -97 -47 -132 -94 -49 -66 -46 -200 8 -270 39 -51
77 -74 147 -87 72 -14 126 1 183 51 36 32 48 52 66 110 l21 71 -21 67 c-38
125 -135 178 -272 152z"
      />
      <path
        d="M2450 3391 c-98 -23 -118 -32 -203 -88 -168 -110 -296 -317 -336
-545 -60 -338 22 -655 215 -839 90 -85 147 -114 314 -161 63 -18 102 -21 236
-21 163 0 196 5 354 49 106 30 122 45 110 99 -5 22 -16 76 -25 120 -9 44 -22
86 -28 93 -10 10 -28 8 -92 -8 -375 -97 -639 2 -694 259 -6 29 -11 56 -11 61
0 25 79 30 485 30 396 0 423 1 438 18 14 15 17 42 17 153 0 209 -28 338 -104
487 -49 95 -95 149 -181 212 -123 91 -317 122 -495 81z m218 -296 c43 -19 107
-77 129 -120 38 -72 55 -199 31 -223 -9 -9 -80 -12 -258 -12 -301 0 -291 -4
-259 111 46 166 139 257 267 258 29 1 69 -6 90 -14z"
      />
      <path
        d="M1280 3364 c-154 -41 -287 -139 -344 -253 -45 -91 -70 -196 -61 -263
8 -67 25 -129 40 -148 6 -8 20 -32 30 -53 39 -75 156 -162 337 -251 86 -42
147 -79 165 -99 86 -98 52 -236 -64 -265 -122 -29 -191 -22 -359 39 -104 38
-111 37 -123 -8 -39 -145 -59 -236 -55 -247 8 -21 133 -74 219 -93 109 -24
314 -24 415 0 261 61 414 250 412 511 0 49 -5 93 -11 99 -6 6 -11 18 -11 27 0
34 -51 121 -101 172 -80 80 -110 100 -269 179 -80 39 -158 83 -173 98 -81 75
-76 172 11 231 32 23 45 25 136 24 86 -1 112 -6 176 -31 41 -16 83 -27 92 -25
11 2 22 19 28 45 6 23 15 53 20 67 7 21 17 63 35 152 3 19 -35 45 -95 67 -112
40 -344 53 -450 25z"
      />
      <path
        d="M3345 3366 c-20 -14 -20 -26 -20 -795 0 -626 3 -783 13 -793 10 -10
59 -13 195 -13 l181 0 12 24 c19 37 14 1555 -4 1573 -20 20 -350 24 -377 4z"
      />
      <path
        d="M3945 2214 c-149 -77 -181 -278 -61 -388 44 -40 87 -56 151 -56 208
0 302 255 148 403 -22 20 -55 42 -74 47 -51 15 -130 12 -164 -6z"
      />
    </g>
  </svg>
);

export default Sei;
