import React, { FC, ReactNode } from 'react';

import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  Entity,
  EditorState,
  EntityInstance,
  SelectionState,
} from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';

export function getKeyInSelection(editorState: EditorState): string | undefined {
  const contentState = editorState.getCurrentContent();
  const startKey = editorState.getSelection().getStartKey();
  const startOffset = editorState.getSelection().getStartOffset();
  const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);

  return blockWithLinkAtBeginning.getEntityAt(startOffset);
}

export function getEntityByKey(editorState: EditorState, key: string): EntityInstance {
  return editorState.getCurrentContent().getEntity(key);
}

export function findSelectionByKey(state: EditorState, key: string): SelectionState {
  const contentState = state.getCurrentContent();
  const currentSelection = state.getSelection();
  const startKey = currentSelection.getStartKey();
  const endKey = currentSelection.getEndKey();
  const selectedBlocks = getSelectedBlocks(contentState, startKey, endKey);

  let fullSelection = currentSelection;

  selectedBlocks.forEach(block => {
    block.findEntityRanges(
      character => {
        const entityKey = character.getEntity();
        return entityKey === key;
      },
      (start, end) => {
        fullSelection = fullSelection.merge({
          focusOffset: start,
          anchorOffset: end,
        });
      }
    );
  });

  return fullSelection;
}

export const getSelectedText = (state: EditorState) => {
  const selectionState = state.getSelection();
  const anchorKey = selectionState.getAnchorKey();
  const currentContent = state.getCurrentContent();
  const currentContentBlock = currentContent.getBlockForKey(anchorKey);
  const start = selectionState.getStartOffset();
  const end = selectionState.getEndOffset();

  return currentContentBlock.getText().slice(start, end);
};

export const load = (html?: string) => {
  if (!html) {
    return EditorState.createEmpty(decorator);
  }

  const contentState = convertFromHTML({
    htmlToEntity: (nodeName, node) => {
      switch (nodeName) {
        case 'img':
          return Entity.create('image', 'IMMUTABLE', { src: node.src });

        case 'a':
          return Entity.create('LINK', 'MUTABLE', { url: node.href });
      }
    },
    htmlToBlock: nodeName => {
      switch (nodeName) {
        case 'figure':
          return 'atomic';
      }
    },
  })(html);

  return EditorState.createWithContent(contentState, decorator);
};

export const save = (state: EditorState) => {
  const contentState = state.getCurrentContent();

  const htmlToText = convertToHTML({
    entityToHTML: (entity, originalText) => {
      switch (entity.type) {
        case 'LINK':
          return <a href={entity.data.url}>{originalText}</a>;

        default:
          return originalText;
      }
    },
    blockToHTML: block => {
      if (block.type === 'atomic') {
        const contentBlock = contentState.getBlockMap().get(block.key);
        const entityKey = contentBlock.getEntityAt(0);

        if (entityKey) {
          const entity = Entity.get(entityKey);

          if (entity.getType() === 'image') {
            return (
              <figure>
                <img
                  alt=""
                  src={entity.getData().src}
                  style={{ maxWidth: '500px', maxHeight: '500px' }}
                />
              </figure>
            );
          }
        }
      }
    },
  })(contentState);

  if (['<p></p>'].includes(htmlToText)) {
    return null;
  }

  return htmlToText;
};

const Link: FC<{
  contentState: ContentState;
  entityKey: string;
  children?: ReactNode;
}> = props => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return <a href={url}>{props.children}</a>;
};

const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
]);

function findLinkEntities(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
}

function getSelectedBlocks(
  contentState: ContentState,
  anchorKey: string,
  focusKey: string
): ContentBlock[] {
  const isSameBlock = anchorKey === focusKey;
  const startingBlock = contentState.getBlockForKey(anchorKey);
  const selectedBlocks = [startingBlock];

  if (!isSameBlock) {
    let blockKey = anchorKey;

    while (blockKey !== focusKey) {
      const nextBlock = contentState.getBlockAfter(blockKey);
      if (nextBlock) {
        selectedBlocks.push(nextBlock);
        blockKey = nextBlock.getKey();
      }
    }
  }

  return selectedBlocks;
}
