import React, { useRef, useState } from 'react';

import { useTranslationX } from 'i18n';
import { Button, PlainField, Spacer } from 'components/elements';
import { Dialog, DialogRef, Grid, HStack, Row, VStack } from 'components/containers';
import {
  DatePicker,
  FileUploader,
  Form,
  HiddenField,
  SubmitButton,
  TextArea,
  TextField,
} from 'components/form';
import { NotificationData } from '../types';
import { UI_BUTTON_SPACING } from 'consts';

type InternshipData = {
  startDate: string;
  hourlyRate: number;
  duration: number;
  remuneration: number;
};

type Props = {
  data: NotificationData;
  accept: boolean;
  needFile?: boolean;
  execution?: boolean;
  valorExecutado?: number;
  needValorExecutado?: boolean;
  onClose: (processed?: boolean) => void;
  isWpCancel?: boolean;
  isInternship?: boolean;
  internshipData?: InternshipData;
};

type DoneProps = {
  execution?: boolean;
  onClick: () => void;
};

const ConfirmDialog = ({
  data,
  accept,
  onClose,
  valorExecutado = 0,
  needFile = true,
  execution = false,
  needValorExecutado = true,
  isWpCancel = false,
  isInternship = false,
  internshipData,
}: Props) => {
  const [done, setDone] = useState(false);
  const dialog = useRef<DialogRef>(null);

  const { t, tx } = useTranslationX('supervisor:homologacao.dialog', [
    'translation',
    'supervisor',
  ]);

  const repeatedLabel =
    t(`notification.${data.type}`).toUpperCase() === data.title.toUpperCase();

  function closeModalOnError() {
    onClose();
  }

  return (
    <Dialog
      ref={dialog}
      title={tx('title')}
      titleAlign="center"
      visible={!!data}
      onClose={onClose}
      closeButton
      width={676}
    >
      {done && (
        <DoneContent execution={execution} onClick={() => dialog.current?.close(true)} />
      )}
      {!done && (
        <Form
          baseUrl="/homologacao"
          submit={{ format: 'multipart', method: 'post' }}
          onError={closeModalOnError}
          onDone={() => setDone(true)}
        >
          {isInternship ? (
            <InternshipForm
              data={data}
              accept={accept}
              internshipData={internshipData}
              onClose={onClose}
            />
          ) : (
            <>
              <Grid spacing={5}>
                <Row align="top">
                  <div>
                    <PlainField
                      label={tx('type')}
                      value={
                        !repeatedLabel
                          ? `${t(`notification.${data.type}`)}: ${data.title}`
                          : `${t(`notification.${data.type}`)}`
                      }
                    />

                    <PlainField
                      label={tx('status.label')}
                      value={tx(accept ? 'status.accept' : 'status.refuse')}
                    />
                  </div>
                  {needFile && (
                    <div style={{ padding: '0 29px' }}>
                      <FileUploader name="fileResponse" type="sneaky" />
                    </div>
                  )}
                </Row>

                <HiddenField name="notificationId" value={data.id} />
                <HiddenField name="estado" value={data.state} />
                <HiddenField name="tipo" value={data.type} />
                <HiddenField name="accept" value={accept} />
                {accept && data.type.includes('alteration-pt') && !isWpCancel && (
                  <div style={{ padding: '0 10px' }}>
                    <TextArea
                      theme="classic"
                      label={tx('justificativa')}
                      minRows={4}
                      name="justificativa"
                    />
                  </div>
                )}
                {!accept && data.type.indexOf('project') !== -1 && (
                  <div style={{ padding: '0 10px' }}>
                    <TextArea
                      theme="classic"
                      label={tx('motivoRejeicao')}
                      minRows={4}
                      name="textResponse"
                    />
                  </div>
                )}
              </Grid>
              <Grid spacing={20}>
                <Row align="top">
                  {execution && (
                    <>
                      <DatePicker
                        metadata={{ rules: { required: true } }}
                        theme="classic"
                        label={tx('executionDate')}
                        name="executionDate"
                      />
                      {needValorExecutado ? (
                        <TextField
                          metadata={{
                            rules: {
                              min: '0.00',
                              minlength: 1,
                              maxlength: 14,
                              mask: {
                                pattern: 'currency',
                              },
                              required: true,
                            },
                          }}
                          theme="classic"
                          label={tx('valorExecutado')}
                          value={valorExecutado}
                          name="executionValue"
                        />
                      ) : null}
                    </>
                  )}
                </Row>
              </Grid>
            </>
          )}

          <HStack align="center" spacing={30}>
            <Button
              type="secondary"
              onClick={() => dialog.current?.close()}
              style={{ root: { minWidth: 120 } }}
            >
              {t('button.cancel')}
            </Button>
            <Spacer length={UI_BUTTON_SPACING} />
            <SubmitButton autoDisable={needFile} style={{ root: { minWidth: 120 } }}>
              {t('button.confirm')}
            </SubmitButton>
          </HStack>
        </Form>
      )}
    </Dialog>
  );
};

const DoneContent = ({ onClick, execution }: DoneProps) => {
  const { t, tx } = useTranslationX('supervisor:homologacao.dialog', [
    'translation',
    'supervisor',
  ]);

  return (
    <VStack align="center" spacing={30} style={{ paddingTop: '5rem' }}>
      <span
        style={{
          padding: '5px',
          fontFamily: 'Open sans',
          color: '#333',
          fontSize: '14px',
        }}
      >
        {execution ? tx('executionSuccess') : tx('success')}
      </span>

      <Spacer length={UI_BUTTON_SPACING} />
      <Button onClick={onClick} style={{ text: { textTransform: 'uppercase' } }}>
        {t('button.ok')}
      </Button>
    </VStack>
  );
};

const InternshipForm = ({ data, accept, internshipData }: Props) => {
  const { t, tx } = useTranslationX('supervisor:homologacao.dialog', [
    'translation',
    'supervisor',
  ]);

  const repeatedLabel =
    t(`notification.${data.type}`).toUpperCase() === data.title.toUpperCase();

  return (
    <Grid spacing={30}>
      <Row align="top">
        <PlainField
          label={tx('type')}
          value={
            !repeatedLabel
              ? `${t(`notification.${data.type}`)}: ${data.title}`
              : `${t(`notification.${data.type}`)}`
          }
        />

        <PlainField
          label={tx('status.label')}
          value={tx(accept ? 'status.accept' : 'status.refuse')}
        />
      </Row>
      <Row align="top" width={[6, 6]}>
        <DatePicker
          name="data.dataInicio"
          label={tx('startDate')}
          align="left"
          value={internshipData?.startDate}
        />
        <TextField
          name="data.cargaHoraria"
          label={tx('hourlyRate')}
          value={internshipData?.hourlyRate}
        />
      </Row>
      <Row align="top" width={[6, 6]}>
        <TextField
          name="data.remuneracao"
          label={tx('remuneration')}
          type={'readonly'}
          value={internshipData?.remuneration}
        />
        <TextField
          name="data.duracao"
          label={tx('duration')}
          value={internshipData?.duration}
        />
      </Row>

      <HiddenField name="notificationId" value={data.id} />
      <HiddenField name="estado" value={data.state} />
      <HiddenField name="tipo" value={data.type} />
      <HiddenField name="accept" value={accept} />
    </Grid>
  );
};

export default ConfirmDialog;
