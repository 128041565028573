import React from 'react';

import { classes, renderComponent } from 'utils/components';
import { Check } from 'components/icons';
import styles from './Toggle.module.scss';

type Props = {
  active?: boolean;
};

const Toggle = ({ active }: Props) => {
  return (
    <div className={classes(styles.box, active ? styles.active : null)}>
      {active && renderComponent(Check)}
    </div>
  );
};

export default Toggle;
