import React from 'react';

import { useTranslationX } from 'i18n';
import { CircularProgress, Loader } from 'components/elements';
import { MultiSelect, useFormObserver } from 'components/form';
import { DistItem } from './types';

import styles from './RelationshipMonitor.module.scss';

type Props = {
  data?: DistItem[];
  loading: boolean;
};

const RelationshipMonitor: React.FC<Props> = ({ data, loading }) => {
  const { tx } = useTranslationX('budget-description', 'proposal');

  const grupo1 = useFormObserver('monitor.group1');
  const grupo2 = useFormObserver('monitor.group2');

  const percentage = computePercentage(
    grupo1?.data?.value as number[],
    grupo2?.data?.value as number[],
    data
  );

  const options = Array.isArray(data)
    ? data.map(({ nome: text, id: value }) => ({ text, value }))
    : undefined;

  return (
    <div className={styles.monitor}>
      <span className={styles.title}>{tx('monitor')}</span>
      <div className={styles.loader}>{loading && <Loader type="spin" />}</div>
      <div className={styles.content}>
        <div className={styles.options}>
          <MultiSelect
            name="monitor.group1"
            omit
            metadata={{ options }}
            displayOptions="dropdown"
            label={tx('parts')}
            theme="classic"
            forceActive
          />
        </div>
        <span>"/"</span>
        <div className={styles.options}>
          <MultiSelect
            name="monitor.group2"
            omit
            metadata={{ options }}
            displayOptions="dropdown"
            label={tx('parts')}
            theme="classic"
            forceActive
          />
        </div>
        <span>"="</span>
        {percentage ? (
          <div className={styles.progress}>
            <div>
              <CircularProgress size={28} value={percentage || 0} />
            </div>

            {typeof percentage === 'number' && (
              <span>
                {percentage}
                <span>"%"</span>
              </span>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default RelationshipMonitor;

function computePercentage(group1?: number[], group2?: number[], dist?: DistItem[]) {
  if (!Array.isArray(group1) || !Array.isArray(group2) || !Array.isArray(dist)) {
    return;
  }

  let num = 0;
  let den = 0;

  for (const { id, total } of dist) {
    if (group1.includes(id)) {
      num += total;
    }

    if (group2.includes(id)) {
      den += total;
    }
  }

  if (den) {
    return Number(((num / den) * 100).toFixed(2));
  }
}
