import React from 'react';

const Warning = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24">
    <path
      d="m2.11 18.1 1.44 2.45c0.0736 0.125 0.18 0.228 0.307 0.3 0.128 0.0718 0.272 0.109 0.419 0.109h15.4c0.147 5.54e-4 0.292-0.037 0.419-0.109 0.128-0.0719 0.234-0.175 0.307-0.3l1.44-2.45c0.0733-0.124 0.112-0.265 0.112-0.409s-0.0386-0.285-0.112-0.409l-7.72-13.9c-0.0736-0.125-0.18-0.228-0.307-0.3-0.128-0.0718-0.272-0.109-0.419-0.109h-2.89c-0.15-4.02e-4 -0.298 0.0389-0.427 0.114-0.129 0.0749-0.236 0.183-0.308 0.312l-7.72 13.9c-0.069 0.123-0.104 0.262-0.103 0.402 0.00146 0.14 0.0398 0.278 0.111 0.4zm9.05-9.41c0-0.217 0.088-0.425 0.245-0.579 0.157-0.153 0.369-0.24 0.591-0.24 0.222 0 0.434 0.0862 0.591 0.24 0.157 0.153 0.245 0.362 0.245 0.579v5.73c0 0.217-0.088 0.425-0.245 0.579-0.157 0.153-0.369 0.24-0.591 0.24-0.221 0-0.434-0.0862-0.591-0.24-0.157-0.153-0.245-0.362-0.245-0.579zm0.835 7.36c0.165 0 0.327 0.048 0.464 0.138 0.137 0.0899 0.244 0.218 0.308 0.367 0.0632 0.149 0.0797 0.314 0.0475 0.473-0.0322 0.159-0.112 0.304-0.229 0.419-0.117 0.114-0.266 0.192-0.428 0.224-0.162 0.0316-0.33 0.0154-0.483-0.0466-0.153-0.0619-0.283-0.167-0.375-0.301-0.0918-0.135-0.141-0.293-0.141-0.455 0-0.217 0.088-0.425 0.245-0.579 0.157-0.153 0.369-0.24 0.591-0.24z"
      fill="currentColor"
    />
  </svg>
);

export default Warning;
