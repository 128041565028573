import { useSelector } from 'react-redux';
import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { DashBoardConfig, MenuItem, MenuItemIcon } from 'pages/dashboard/types';
import { getProfileConfig } from 'pages/dashboard/helpers';

type Project = {
  id: number;
  versao: number;
  titulo: string;
  valorFinanceiro: number;
  valorTotal: number;
  sigla: string;
  duracao: number;
  anoInicio: number;
  estado: string;
  gerente: {
    id: number;
    nome: string;
    ativado: boolean;
  };
};

export type State = {
  notificationCount?: number;
  dashboardConfig?: DashBoardConfig | Error;
  managedProjects?: Project[];
};

type EditSideMenuProps = {
  sideMenu: MenuItemIcon[];
  checkSelectedRoute: (
    selected: string,
    key: string,
    pathname: string,
    path?: string
  ) => boolean;
};

const selector = (state: RootState) => state.profile;
export const useProfileState = () => useSelector(selector);

export const initProfile = createAction<State>('SET_DATA');
export const setNotificationCount = createAction<number>('SET_NOTIFICATION_COUNT');
export const setDashboardConfig = createAction<DashBoardConfig | Error>(
  'SET_DASHBOARD_CONFIG'
);
export const editSideMenu = createAction<EditSideMenuProps>('EDIT_SIDE_MENU');

export const editGadgets = createAsyncThunk(
  'EDIT_GADGETS',
  async ({ profile, gadgets }: { profile: string; gadgets: MenuItem[] }) => {
    const config = ((await getProfileConfig(profile)) as DashBoardConfig).gadgets;
    if (config) {
      return [...gadgets, ...config];
    }
    return [...gadgets];
  }
);

export const resetConfig = createAsyncThunk(
  'RESET_CONFIG',
  async (data: string) => await getProfileConfig(data)
);

export const resetGadgets = createAsyncThunk(
  'RESET_GADGETS',
  async (data: string) => ((await getProfileConfig(data)) as DashBoardConfig).gadgets
);

export default createReducer<State>({}, builder => {
  builder
    .addCase(initProfile, (_state, action) => action.payload)
    .addCase(setNotificationCount, (state, action) => {
      state.notificationCount = action.payload;
    })
    .addCase(setDashboardConfig, (state, action) => {
      state.dashboardConfig = action.payload;
    })
    .addCase(editSideMenu, (state, action) => {
      if (state.dashboardConfig) {
        state.dashboardConfig = {
          ...state.dashboardConfig,
          sideMenu: action.payload.sideMenu,
          checkSelectedRoute: action.payload.checkSelectedRoute,
        };
      }
    })
    .addCase(editGadgets.fulfilled, (state, action) => {
      if (state.dashboardConfig) {
        state.dashboardConfig = {
          ...state.dashboardConfig,
          gadgets: action.payload,
        };
      }
    })
    .addCase(resetConfig.fulfilled, (state, action) => {
      state.dashboardConfig = action.payload;
    })
    .addCase(resetGadgets.fulfilled, (state, action) => {
      if (state.dashboardConfig) {
        state.dashboardConfig = {
          ...state.dashboardConfig,
          gadgets: action.payload,
        };
      }
    });
});
