import React from 'react';

import { useTranslationX } from 'i18n';
import { TableData } from 'components/containers';
import TotalLabel from '../TotalLabel/TotalLabel';

import styles from './Financial.module.scss';

const Consumables = () => {
  const { tx } = useTranslationX('financial', 'proposal');

  return (
    <div className={styles.financial}>
      <TotalLabel tableName="materiais" columnName="valor" />

      <div className={styles.table}>
        <TableData
          name="materiais"
          textSize="small"
          style={{ table: { minWidth: 680, maxWidth: 900 } }}
          config={[
            { title: tx('description'), field: 'descricao', align: 'left' },
            {
              title: tx('month'),
              field: 'mesExecucao',
              align: 'center',
              fieldProps: { align: 'center' },
              weight: 0.3,
            },
            {
              title: tx('value'),
              field: 'valor',
              fieldProps: { align: 'right' },
              align: 'right',
              weight: 0.3,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Consumables;
