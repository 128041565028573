import { configureStore, combineReducers } from '@reduxjs/toolkit';

import auth, { persistor } from './auth';
import profile from './profile';
import message from './message';

const rootReducer = combineReducers({ auth, profile, message });

export type RootState = ReturnType<typeof rootReducer>;

export default configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ['profile.dashboardConfig'],
        ignoredActions: [
          'SET_DASHBOARD_CONFIG',
          'EDIT_SIDE_MENU',
          'RESET_SIDE_MENU/fulfilled',
          'EDIT_GADGETS/fulfilled',
          'RESET_GADGETS/fulfilled',
          'RESET_CONFIG/fulfilled',
          'ADD_MESSAGE',
        ],
      },
    }).concat(persistor),
  devTools: process.env.NODE_ENV !== 'production',
});
