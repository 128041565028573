import React, {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import ScrollBar, { ScrollBarRef } from './ScrollBar';
import { classes } from 'utils/components';
import { splitValueUnit } from 'utils';

import styles from './ScrollPanel.module.scss';

export type Clip = { top: number; right: number; bottom: number; left: number };

type BarProp = {
  position?: 'start' | 'end';
  overlay?: boolean;
  visible?: boolean;
  start?: number;
  end?: number;
  style?: { root?: CSSProperties; track?: CSSProperties; thumb?: CSSProperties };
  placeholder?: boolean;
};

type Props = {
  style?: { root?: CSSProperties; content?: CSSProperties };
  vBar?: BarProp;
  hBar?: BarProp;
  refreshesOn?: number;
  loading?: boolean;
  setLoading?: (loading: boolean) => void;
  onNeedRefresh?: () => void;
  onClipChange?: (clip: Clip) => void;
  className?: string;
  children?: ReactNode;
};

const ScrollPanel: React.FC<Props> = props => {
  const {
    style,
    vBar,
    hBar,
    onClipChange,
    onNeedRefresh,
    refreshesOn,
    setLoading,
    loading,
    className,
    children,
  } = props;

  const [, setState] = useState({
    offsetHeight: 0,
    scrollHeight: 0,
    offsetWidth: 0,
    scrollWidth: 0,
  });

  const contentRef = useRef<HTMLDivElement>(null);
  const vbarRef = useRef<ScrollBarRef>(null);
  const hbarRef = useRef<ScrollBarRef>(null);

  const { value: barWidth } = splitValueUnit(styles.barWidth);

  const needRefresh = useCallback(() => {
    if (onNeedRefresh && contentRef.current && refreshesOn) {
      if (contentRef.current?.scrollHeight > 0 && contentRef.current?.scrollTop > 0) {
        const scrollPositionPercent =
          (contentRef.current?.scrollTop /
            (contentRef.current.scrollHeight - contentRef.current?.offsetHeight)) *
          100;
        if (scrollPositionPercent >= refreshesOn && !loading && setLoading) {
          onNeedRefresh();
          setLoading(true);
        }
      }
    }
  }, [contentRef, loading, onNeedRefresh, refreshesOn, setLoading]);

  const clipChanged = useCallback(() => {
    if (onClipChange && contentRef.current) {
      const r = contentRef.current.getBoundingClientRect();

      onClipChange({
        top: contentRef.current.scrollTop,
        right: contentRef.current.scrollLeft + r.width,
        bottom: contentRef.current.scrollTop + r.height,
        left: contentRef.current.scrollLeft,
      });
    }
  }, [contentRef, onClipChange]);

  const refresh = useCallback(
    () =>
      setState(state => {
        if (contentRef.current) {
          const { offsetHeight, scrollHeight, offsetWidth, scrollWidth } =
            contentRef.current;

          if (
            offsetHeight !== state.offsetHeight ||
            scrollHeight !== state.scrollHeight ||
            offsetWidth !== state.offsetWidth ||
            scrollWidth !== state.scrollWidth
          ) {
            return { offsetHeight, scrollHeight, offsetWidth, scrollWidth };
          }
        }

        return state;
      }),
    [contentRef]
  );

  useEffect(() => {
    const ro = new ResizeObserver(() => {
      refresh();
      clipChanged();
      needRefresh();
    });

    if (contentRef.current) {
      ro.observe(contentRef.current);
    }

    return () => ro.disconnect();
  }, [clipChanged, needRefresh, contentRef, refresh]);

  useEffect(refresh, [children, refresh]);

  const onChange = useCallback(
    (scrollPosition: number, attr: 'scrollTop' | 'scrollLeft') => {
      if (contentRef.current) {
        contentRef.current[attr] = scrollPosition;
      }
    },
    [contentRef]
  );

  const c = contentRef;

  const vPosition =
    ((c.current &&
      vBar?.visible !== false &&
      c.current?.offsetHeight < c.current?.scrollHeight) ||
      undefined) &&
    (vBar?.position || 'end');

  const hPosition =
    ((c.current &&
      hBar?.visible !== false &&
      c.current?.offsetWidth < c.current?.scrollWidth) ||
      undefined) &&
    (hBar?.position || 'end');

  const paddings: CSSProperties = {};

  if ((!vBar?.overlay && vPosition) || vBar?.placeholder) {
    vPosition === 'start'
      ? (paddings.paddingLeft = barWidth)
      : (paddings.paddingRight = barWidth);
  }

  if ((!hBar?.overlay && hPosition) || hBar?.placeholder) {
    hPosition === 'start'
      ? (paddings.paddingTop = barWidth)
      : (paddings.paddingBottom = barWidth);
  }

  const renderBars = () => {
    if (!c) {
      return null;
    }

    const bars = [];
    const r = c.current?.getBoundingClientRect();

    if (vPosition) {
      bars.push(
        <ScrollBar
          key="vbar"
          type="vertical"
          ref={vbarRef}
          visibleSize={Number(style?.content?.height) || r!.height}
          totalSize={c.current?.scrollHeight || 0}
          scrollPosition={c.current?.scrollTop}
          onChange={onChange}
          position={vPosition}
          start={vBar?.start || (hBar?.overlay && hPosition === 'start' && barWidth) || 0}
          end={vBar?.end || (hBar?.overlay && hPosition === 'end' && barWidth) || 0}
          overlay={vBar?.overlay}
          style={vBar?.style}
        />
      );
    }

    if (hPosition) {
      bars.push(
        <ScrollBar
          key="hbar"
          type="horizontal"
          ref={hbarRef}
          visibleSize={r!.width}
          totalSize={c.current?.scrollWidth || 0}
          scrollPosition={c.current?.scrollLeft}
          onChange={onChange}
          position={hPosition}
          start={hBar?.start || (vBar?.overlay && vPosition === 'start' && barWidth) || 0}
          end={hBar?.end || (vBar?.overlay && vPosition === 'end' && barWidth) || 0}
          overlay={hBar?.overlay}
          style={hBar?.style}
        />
      );
    }

    return bars;
  };

  return (
    <div
      className={classes(className, styles.scrollPanel)}
      onMouseMove={refresh}
      style={{ ...style?.root, ...paddings }}
    >
      <div
        ref={contentRef}
        className={styles.content}
        onScroll={() => {
          vbarRef.current?.setScrollPosition(contentRef.current?.scrollTop || 0);
          hbarRef.current?.setScrollPosition(contentRef.current?.scrollLeft || 0);
          refresh();
          clipChanged();
          needRefresh();
        }}
        style={style?.content}
      >
        {children}
      </div>
      {renderBars()}
    </div>
  );
};

export default ScrollPanel;
