import React from 'react';

import { useTranslation, useTranslationX } from 'i18n';
import { Grid, Row } from 'components/containers';
import { PlainField } from 'components/elements';
import { HiringStatus } from 'pages/project/types';
import { formatDateToBrazilian } from 'utils/stringUtils';

import styles from './execution.module.scss';

type Data = {
  agenciaConta: string;
  banco: string;
  bolsaCargaHorariaMensal: number;
  bolsaDuracao: number;
  bolsaInicio: Date;
  estado: HiringStatus;
  fonte: string;
  gpf: string;
  justificativa: string;
  nascimento: Date;
  nome: string;
  numeroConta: string;
  projetoCoordenador: string;
  projetoSigla: string;
  projetoTitulo: string;
  rg: string;
  rgEmissor: string;
  rgEmissorUf: string;
  sexo: string;
  tipo: string;
  titulo: string;
  vinculoContratacao: string;
  vinculoEmpregaticio: boolean;
  valorHora: number;
  origem: Vaga;
  destino: Vaga;
  contrato: Contrato;
  duracao: number;
  cargaHoaria: number;
  dataInicio: string;
  data: string;
};

type Contrato = {
  bolsaCargaHorariaMensal: number;
  bolsaDuracao: number;
  nascimento: Date;
  nome: string;
  rg: string;
  rgEmissor: string;
  rgEmissorUf: string;
  sexo: string;
};

type Vaga = {
  fonteRecurso: string;
  remuneracao: number;
  cargo: string;
  perfil: string;
  vinculoContratacao: string;
  valorHora: number;
};

const Transfer = ({ data }: { data: Data }) => {
  const { tx } = useTranslationX('request', 'project');
  const { t } = useTranslation('translation');

  const {
    titulo,
    projetoTitulo,
    projetoSigla,
    projetoCoordenador,
    tipo,
    fonte,
    gpf,
    estado,
    origem,
    destino,
    contrato,
    duracao,
    cargaHoaria,
    dataInicio,
    justificativa,
    data: dataSolicitacao,
  } = data;

  return (
    <Grid spacing={0} maxWidth="800px">
      <Row width={[6, 4]} align="top">
        <PlainField
          label={tx(`form.tipoNotificacao.label`)}
          value={tx(`form.tipoNotificacao.${estado}`)}
        />
        <PlainField label={tx('form.itemName')} value={titulo} />
        <PlainField label={tx('list.requestType')} value={tx(`type.${tipo}`)} />
      </Row>
      <Row width={[6, 4]} align="top">
        <PlainField label={tx('form.projectTitle')} value={projetoTitulo} />
        <PlainField label={tx('form.projectInitials')} value={projetoSigla} />
      </Row>
      <Row width={[6, 4]} align="top">
        <PlainField label={tx('form.projectCoordinator')} value={projetoCoordenador} />
        <PlainField label={tx('list.source')} value={fonte} />
        <PlainField label={tx('list.gpf')} value={gpf} />
      </Row>
      <Row width={[6, 4]} align="top">
        <PlainField label={tx('form.name.label')} value={contrato.nome} />
        <PlainField
          label={tx('form.birth')}
          value={formatDateToBrazilian(contrato.nascimento)}
        />
        <PlainField
          label={tx('form.sex.label')}
          value={tx(`form.sex.${contrato.sexo}`)}
        />
      </Row>
      <Row width={[6, 4]} align="top">
        <PlainField label={tx('form.rg')} value={contrato.rg} />
        <PlainField label={tx('form.orgaoEmissor')} value={contrato.rgEmissor} />
        <PlainField label={tx('form.uf')} value={contrato.rgEmissorUf} />
      </Row>
      <Row width={[6, 4]} align="top">
        <PlainField
          label={tx('list.requestDate')}
          value={formatDateToBrazilian(new Date(dataSolicitacao))}
        />
        <PlainField
          label={tx('form.transferDate')}
          value={formatDateToBrazilian(new Date(dataInicio))}
        />
      </Row>
      <div className={styles.documents}>
        <Row width={[6, 4]} align="top">
          <span className={styles.documentsTitle} style={{ fontSize: '16px  ' }}>
            {tx('form.dadosOrigem')}
          </span>
        </Row>
      </div>
      <Row width={[6, 4]} align="top">
        <PlainField label={tx('form.cargo')} value={origem.cargo} />
        <PlainField label={tx('form.fonte')} value={origem.fonteRecurso} />
        <PlainField
          label={tx('form.vinculoContratacao')}
          value={origem.vinculoContratacao}
        />
      </Row>
      <Row width={[6, 4]} align="top">
        <PlainField label={tx('form.duracao')} value={String(contrato.bolsaDuracao)} />

        <PlainField
          label={tx('form.remunaration')}
          value={`${t('currency')} ${origem.remuneracao}`}
        />
        <PlainField
          label={tx('form.valueHour')}
          value={`${t('currency')} ${origem.valorHora}`}
        />
      </Row>
      <Row width={[6, 4]} align="top">
        <PlainField
          label={tx('form.workload')}
          value={String(contrato.bolsaCargaHorariaMensal)}
        />
      </Row>
      <div className={styles.documents}>
        <Row width={[6, 4]} align="top">
          <span className={styles.documentsTitle} style={{ fontSize: '16px  ' }}>
            {tx('form.dadosDestino')}
          </span>
        </Row>
      </div>

      <Row width={[6, 4]} align="top">
        <PlainField label={tx('form.cargo')} value={destino.cargo} />
        <PlainField label={tx('form.fonte')} value={destino.fonteRecurso} />
        <PlainField
          label={tx('form.vinculoContratacao')}
          value={destino.vinculoContratacao}
        />
      </Row>
      <Row width={[6, 4]} align="top">
        <PlainField label={tx('form.duracao')} value={String(duracao)} />

        <PlainField
          label={tx('form.remunaration')}
          value={`${t('currency')} ${destino.remuneracao}`}
        />
        <PlainField
          label={tx('form.valueHour')}
          value={`${t('currency')} ${destino.valorHora}`}
        />
      </Row>
      <Row width={[6, 4]} align="top">
        <PlainField label={tx('form.workload')} value={String(cargaHoaria)} />
      </Row>
      <Row align="top">
        <PlainField label={tx('form.justificativa.label')} value={justificativa} />
      </Row>
    </Grid>
  );
};

export default Transfer;
