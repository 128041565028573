import React from 'react';

const BulletList = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none">
    <path
      d="m2 5.51c0-0.897 0.724-1.62 1.62-1.62s1.62 0.724 1.62 1.62c0 0.897-0.724 1.62-1.62 1.62s-1.62-0.724-1.62-1.62zm0 6.49c0-0.897 0.724-1.62 1.62-1.62s1.62 0.724 1.62 1.62c0 0.897-0.724 1.62-1.62 1.62s-1.62-0.724-1.62-1.62zm1.62 4.86c-0.897 0-1.62 0.735-1.62 1.62 0 0.886 0.735 1.62 1.62 1.62s1.62-0.735 1.62-1.62c0-0.886-0.724-1.62-1.62-1.62zm17.3 2.7h-13c-0.595 0-1.08-0.486-1.08-1.08 0-0.595 0.486-1.08 1.08-1.08h13c0.595 0 1.08 0.486 1.08 1.08 0 0.595-0.486 1.08-1.08 1.08zm-13-6.49h13c0.595 0 1.08-0.486 1.08-1.08 0-0.595-0.486-1.08-1.08-1.08h-13c-0.595 0-1.08 0.486-1.08 1.08 0 0.595 0.486 1.08 1.08 1.08zm0-6.49c-0.595 0-1.08-0.486-1.08-1.08s0.486-1.08 1.08-1.08h13c0.595 0 1.08 0.486 1.08 1.08s-0.486 1.08-1.08 1.08z"
      clipRule="evenodd"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default BulletList;
