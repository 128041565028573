/* eslint-disable react/style-prop-object */
import React from 'react';




const ThirdPartyServices = ({ size = 1 }: { size?: number }) => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_10259_1120"  maskUnits="userSpaceOnUse" x="3" y="7" width="18" height="10">
<path d="M3.49252 9.11375L11.0825 16.7038C11.4725 17.0938 12.1025 17.0938 12.4925 16.7038L18.7925 10.4038V14.0038C18.7925 14.5538 19.2425 15.0038 19.7925 15.0038C20.3425 15.0038 20.7925 14.5538 20.7925 14.0038V8.00375C20.7925 7.45375 20.3425 7.00375 19.7925 7.00375H13.7925C13.2425 7.00375 12.7925 7.45375 12.7925 8.00375C12.7925 8.55375 13.2425 9.00375 13.7925 9.00375H17.3825L11.7925 14.5938L4.90252 7.70375C4.71569 7.5165 4.46204 7.41127 4.19752 7.41127C3.933 7.41127 3.67935 7.5165 3.49252 7.70375C3.11252 8.09375 3.11252 8.73375 3.49252 9.11375Z" fill="black"/>
</mask>
<g mask="url(#mask0_10259_1120)">
<rect width="24" height="24" fill="#37A64A"/>
</g>
</svg>


);

export default ThirdPartyServices;





