import { isNil } from 'utils';

export const isNotDeepEquals = (obj1: any, obj2: any) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const isNotNull = (obj: any) => {
  return !isNil(obj);
};

export const tokenize = (path: string) => {
  if (!path || /^[.]|[.]$/.test(path)) {
    throw new Error('invalid path: ' + path);
  }

  const regex = /"([^"]*)"|([^.]+)/g;
  const tokens = [];

  let match;

  while ((match = regex.exec(path))) {
    const token = match[1] || match[2];

    if (!token || /^[.]|[.]$|"/.test(token)) {
      throw new Error('invalid path: ' + path);
    }

    tokens.push(token);
  }

  if (tokens.length) {
    return tokens;
  }

  throw new Error('invalid path: ' + path);
};

export const sanitizeValue = (value: any) => {
  if (value === undefined || value === null || Object.values(value).length === 0) {
    return undefined;
  }
  return value;
};

export const sanitizeObject = (obj: { [key: string]: any }) => {
  Object.keys(obj).forEach(key =>
    obj[key] === undefined || Object.values(obj[key]).length === 0 ? delete obj[key] : {}
  );
  return obj;
};

export const flattenObject = (
  obj: { [key: string]: any },
  prefix?: string
): { [key: string]: any } => {
  return Object.keys(obj).reduce((acc, key) => {
    let flatKey = prefix ? `${prefix}.${key}` : key;
    // Convert .number. to [number].

    flatKey = flatKey.replace(
      /\.\d+\./g,
      (match: string) => `[${match.replace(/\./g, '')}].`
    );

    if (typeof obj[key] === 'object' && obj[key] !== null) {
      const flatObj = flattenObject(obj[key], flatKey);
      return { ...acc, ...flatObj };
    } else {
      return { ...acc, [flatKey]: obj[key] };
    }
  }, {});
};
export const readAttr = (
  attrName: string,
  srcObj?: { [key: string]: any },
  fallbackValue?: any,
  validate?: (value: any) => boolean
) => {
  if (!srcObj) {
    return fallbackValue;
  }

  const tokens = tokenize(attrName);

  for (const token of tokens) {
    if (!srcObj) {
      return fallbackValue;
    }

    const listIndex = token.indexOf('[');

    if (listIndex > 0) {
      const name = token.substring(0, listIndex);
      const index = token.substring(listIndex + 1, token.indexOf(']', listIndex));

      srcObj = srcObj[name];

      if (srcObj) {
        srcObj = srcObj[index];
      }
    } else {
      srcObj = srcObj[token];
    }
  }

  if (validate) {
    return validate(srcObj) ? srcObj : fallbackValue;
  }

  return isNil(srcObj) ? fallbackValue : srcObj;
};

export const writeAttr = (
  attrName: string,
  attrValue: any,
  dstObj: { [key: string]: any },
  spread?: boolean,
  parseList?: boolean
) => {
  const tokens = tokenize(attrName);

  attrName = tokens[0];

  for (let i = 0; i < tokens.length - 1; ) {
    if (parseList && attrName.indexOf('[') > 0) {
      const listIndex = attrName.indexOf('[');

      const name = attrName.substring(0, listIndex);
      const index = attrName.substring(listIndex + 1, attrName.indexOf(']', listIndex));

      if (!dstObj[name]) {
        dstObj[name] = [];
      }

      dstObj = dstObj[name];

      if (!dstObj[index]) {
        dstObj[index] = {};
      }

      dstObj = dstObj[index];
    } else {
      if (!dstObj[attrName]) {
        dstObj[attrName] = {};
      }

      dstObj = dstObj[attrName];
    }

    attrName = tokens[++i];
  }

  if (parseList && attrName.indexOf('[') > 0) {
    const listIndex = attrName.indexOf('[');

    const name = attrName.substring(0, listIndex);
    const index = attrName.substring(listIndex + 1, attrName.indexOf(']', listIndex));

    if (!dstObj[name]) {
      dstObj[name] = [];
    }

    dstObj = dstObj[name];

    if (attrValue === null && attrName.endsWith(']')) {
      dstObj.splice(index, 1);
    } else {
      dstObj[index] = attrValue;
    }
  } else {
    dstObj[attrName] = spread ? { ...dstObj[attrName], ...attrValue } : attrValue;
  }
};
