import React from 'react';

import { FieldSet, HiddenField } from 'components/form';
import { Action, Node, NodeConfig, TreeNode } from './types';
import Cell from './Cell';
import Joint from './Joint';
import Button from './Button';
import { WrapperCard } from 'components/elements';

import styles from './Grid.module.scss';

const MAX_CHILDREN = 5;
const MAX_LEVEL = 2;

type Props = {
  name: string;
  root: string;
  data: Node[];
  dispatch: React.Dispatch<Action>;
  editable: boolean;
  lastVersion?: boolean;
};

const Grid = ({ name, root, data, dispatch, editable, lastVersion = false }: Props) => {
  const count = { val: 0 };

  const renderAddButton = (id: number | null, numChildren: number) => (
    <div className={styles.button}>
      <Joint middle="dashed" top={numChildren ? 'dashed' : undefined} />

      <div className={styles.wrapper}>
        <Button
          onClick={() =>
            dispatch({
              type: 'SET_MODAL',
              payload: { mode: 'add', index: count.val, pai: id },
            })
          }
        />
      </div>
    </div>
  );

  const renderFields = (node: Node) => (
    <FieldSet name={name} index={node.index}>
      <HiddenField name="id" value={node.id} />
      <HiddenField name="ordem" value={node.ordem} />
      <HiddenField name="criterios" value={node.criterios} />
      <HiddenField name="pai" value={node.pai} />
      <HiddenField name="nome" value={node.nome} />
      <HiddenField name="descricao" value={node.descricao} />
    </FieldSet>
  );

  const renderOffset = (config?: NodeConfig) => {
    const h = (config?.offsets || 0) * 49;
    const stroke = config && config.position < config.count - 1 ? 'solid' : 'dashed';

    return (
      <div
        className={styles.offset}
        style={h ? { minHeight: h, maxHeight: h } : undefined}
      >
        {config?.level && (stroke !== 'dashed' || config.count < MAX_CHILDREN) ? (
          <Joint top={stroke} bottom={stroke} />
        ) : null}
      </div>
    );
  };

  const renderNode = (node: TreeNode) => {
    const valid = node.id >= 0;

    if (valid) {
      ++count.val;
    }

    return (
      <div className={styles.container} key={node.id}>
        <div className={styles.parent}>
          <div className={styles.fill}>
            {valid ? <Joint {...node.config?.joint} /> : null}

            <div className={styles.wrapper}>
              <Cell
                lastVersion={lastVersion}
                name={node.nome}
                label={node.config?.label}
                onClick={
                  valid && node.index >= 0
                    ? () =>
                        dispatch({
                          type: 'SET_MODAL',
                          payload: { mode: 'edit', index: node.index },
                        })
                    : undefined
                }
                style={{ maxWidth: (node.config?.level || 0) > 1 ? 401 : 208 }}
              >
                {valid ? renderFields(node as Node) : null}
              </Cell>
            </div>

            {node.config?.isBranch ? (
              <Joint
                middle={
                  node.config?.numChildren ? 'solid' : editable ? 'dashed' : undefined
                }
              />
            ) : null}
          </div>

          {renderOffset(node.config)}
        </div>

        {node.config?.isBranch ? (
          <div className={styles.children}>
            {node.config.numChildren ? (
              <div className={styles.fill}>
                {node.children!.map(el => renderNode(el))}
              </div>
            ) : null}

            {node.config.canAdd
              ? renderAddButton(valid ? node.id : null, node.config.numChildren)
              : null}
          </div>
        ) : null}
      </div>
    );
  };

  const rootNode: TreeNode = {
    id: -1,
    nome: root,
    children: data,
    index: -1,
  };

  compile(rootNode, 0, data.length, -1, editable);

  return (
    <WrapperCard padding="80px 30px" minHeight="100px" responsive>
      <div className={styles.grid}>{renderNode(rootNode)}</div>
    </WrapperCard>
  );
};

function compile(
  node: TreeNode,
  level: number,
  count: number,
  index: number,
  editable: boolean,
  label?: string
) {
  const numChildren = node.children?.length || 0;
  const isBranch = level < MAX_LEVEL;
  const canAdd = editable && isBranch && numChildren < MAX_CHILDREN;

  let offsets = canAdd && numChildren ? 1 : 0;

  node.children?.forEach((el, idx) =>
    compile(
      el,
      level + 1,
      numChildren,
      idx,
      editable,
      label ? `${label}.${idx + 1}` : `${idx + 1}`
    )
  );

  if (numChildren && node.children![numChildren - 1].config?.offsets) {
    ++offsets;
  }

  node.config = {
    label,
    numChildren,
    canAdd,
    isBranch,
    offsets,
    level,
    joint:
      index < 0
        ? {}
        : {
            top: index > 0 ? 'solid' : undefined,
            middle: node.id >= 0 ? 'solid' : undefined,
            bottom:
              index < count - 1
                ? 'solid'
                : index === count - 1 && count < MAX_CHILDREN
                ? 'dashed'
                : undefined,
          },
    count,
    position: index,
  };
}

export default Grid;
