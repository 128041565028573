import { useEffect, useState } from 'react';

import { ibge, useRequest } from 'apis';
import { useForm, useFormFieldObserver } from '.';
import { AsyncSource } from 'utils';

type StateApiResponse = {
  id: number;
  sigla: string;
}[];

type CityApiResponse = {
  nome: string;
}[];

type Props = {
  state: string;
  city?: string;
  prefix?: string;
};

const Localidades = ({ state: s, city: c, prefix }: Props) => {
  const [stateIds, setStateIds] = useState<{ [key: string]: number }>({});

  const state = prefix ? `${prefix}.${s}` : s;
  const city = prefix && c ? `${prefix}.${c}` : c;

  const request = useRequest(ibge);
  const form = useForm();
  const result = useFormFieldObserver(state);

  const stateId = result.valid && result.value && stateIds[result.value as string];

  useEffect(() => {
    form.setFieldMetadata(state, { options: new AsyncSource() });

    const source = request<StateApiResponse>({
      url: '/localidades/estados',

      onSuccess: data => {
        data.sort((a, b) => a.sigla.localeCompare(b.sigla));

        const ids: { [key: string]: number } = {};
        const options = data.map(({ id, sigla }) => {
          ids[sigla] = id;
          return { text: sigla, value: sigla };
        });

        form.setFieldMetadata(state, { options });
        setStateIds(ids);
      },

      onError: () => form.setFieldMetadata(state, { options: [] }),
      onCancel: () => form.setFieldMetadata(state, { options: [] }),
    });

    return () => source.cancel();
  }, [form, request, state]);

  useEffect(() => {
    if (city && typeof stateId === 'number') {
      form.setFieldMetadata(city, { options: new AsyncSource() });

      const source = request<CityApiResponse>({
        url: `/localidades/estados/${stateId}/municipios`,

        onSuccess: data => {
          data.sort((a, b) => a.nome.localeCompare(b.nome));
          const options = data.map(({ nome }) => ({ text: nome, value: nome }));
          form.setFieldMetadata(city, { options });
        },

        onError: () => form.setFieldMetadata(city, { options: [] }),
        onCancel: () => form.setFieldMetadata(city, { options: [] }),
      });

      return () => source.cancel();
    }
  }, [form, city, request, stateId]);

  return null;
};

export default Localidades;
