import React, { createRef, useEffect, useRef, useState } from 'react';

import { Card, ScrollPanel } from 'components/containers';
import { Form, HiddenField, RadioGroup, SubmitButton, TextArea } from 'components/form';

import { Button, Loader, Logo } from 'components/elements';
import { FormInstance, Pageable } from 'components/form/types';
import { useTranslation, useTranslationX } from 'i18n';
import { useHistory } from 'react-router-dom';
import { QuestionType } from 'components/containers/Questions/Questions';
import { CancelTokenSource, useFetch } from 'apis';
import { useRequest } from 'apis';
import { Question, Success } from 'components/icons';

import logo_ifce from 'assets/images/logo_ifce.svg';
import logo_embrapii from 'assets/images/logo_embrapii.png';

import styles from './ScholarshipTermination.module.scss';

type Footer = {
  nome: string;
  endereco: string;
  telefone: string;
};

const ScholarshipTerminationForm: React.FC = () => {
  const { tx } = useTranslationX('request.form', 'project');
  const { t } = useTranslation();

  const [questions, setQuestions] = useState<Pageable<QuestionType>>();
  const [status, setStatus] = useState<string>('init');
  const { data: footer } = useFetch<Footer>('/login/metadata', { resource: 'footer' });

  const [token, setToken] = useState<string>('');
  const [psdrh, setPsdrh] = useState<number>(0);

  const history = useHistory();
  const request = useRequest();
  const source = useRef<CancelTokenSource>();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(history.location.search);
    if (urlSearchParams.get('token') === null || urlSearchParams.get('psdrh') === null) {
      history.replace('/login');
    } else {
      setToken(String(urlSearchParams.get('token')));
      setPsdrh(Number(urlSearchParams.get('psdrh')));
    }
  }, [history]);

  useEffect(() => {
    source.current = request<Pageable<QuestionType>>({
      url: 'bolsista/avaliacao/perguntas',
      onSuccess: setQuestions,
    });

    return () => source.current?.cancel();
  }, [request]);

  const verifyCheck = (response: any) => {
    if (response?.status === 'failed') {
      if (response?.rejected?.error === 'Form already responded') {
        setStatus('again');
      }
    }
  };

  useEffect(() => {
    source.current = request<any>({
      url: `bolsista/check/${psdrh}`,
      params: { token },
      onSuccess: verifyCheck,
    });

    return () => source.current?.cancel();
  }, [request, psdrh, token]);

  if (!questions) {
    return <Loader size={60} />;
  }

  const form = createRef<FormInstance>();

  const getContent = () => {
    if (status === 'done') {
      return (
        <div className={styles.success}>
          <Success size={5} />
          <span>{tx('scholarshipDone.title')}</span>
          <span>{tx('scholarshipDone.message')}</span>
        </div>
      );
    } else if (status === 'again') {
      return (
        <div className={styles.success}>
          <Question size={5} />
          <span>{tx('scholarshipAgain.title')}</span>
          <span>{tx('scholarshipAgain.message')}</span>
        </div>
      );
    } else {
      return (
        <Form
          ref={form}
          baseUrl={`bolsista/avaliacao/${psdrh}`}
          onDone={() => setStatus('done')}
        >
          <ScrollPanel>
            <div className={styles.card}>
              <h2>{tx('scholarship.title')}</h2>
              <Card width={{ max: '100%' }}>
                <div className={styles.container}>
                  <HiddenField name="token" value={token} />
                  {questions.content.map(question => {
                    if (question.tipo === 'MULTIPLE_CHOICE') {
                      return (
                        <section key={question.id}>
                          <RadioGroup
                            label={question.pergunta}
                            name={'question_' + question.id}
                            columns={1}
                            autosave={false}
                          />
                        </section>
                      );
                    } else {
                      return (
                        <section key={question.id}>
                          <TextArea
                            minRows={3}
                            label={question.pergunta}
                            name={'question_' + question.id}
                          />
                        </section>
                      );
                    }
                  })}

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: '20px',
                    }}
                  >
                    <Button type="secondary" onClick={() => history.goBack()}>
                      {tx('cancel')}
                    </Button>
                    <SubmitButton style={{ root: { width: 250 } }}>
                      {tx('scholarship.submit')}
                    </SubmitButton>
                  </div>
                </div>
              </Card>
            </div>
          </ScrollPanel>
        </Form>
      );
    }
  };

  return (
    <div className={styles.login}>
      <div>
        <div className={styles.formContainer}>
          <div className={styles.form}>
            <Logo />
            {getContent()}
            <div className={styles.logos}>
              <img src={logo_ifce} alt="logo ifce" />
              <span />
              <img src={logo_embrapii} alt="logo embrapii" />
            </div>
          </div>

          <p className={styles.footer}>
            {`${footer?.nome}: ${footer?.endereco} - ${t(
              'login.footer.phone'
            )}: ${footer?.telefone}`}
          </p>
        </div>
      </div>
    </div>
  );
};
export default ScholarshipTerminationForm;
