import React from 'react';

import FieldAdapter, { Props as FieldProps } from './FieldAdapter';
import Editor, { Props as EditorProps } from 'components/elements/TextEditor/TextEditor';

const TextEditor = (props: EditorProps & FieldProps) => (
  <FieldAdapter {...props}>{props => <Editor {...props} />}</FieldAdapter>
);

TextEditor.displayName = 'TextEditorField';
export default TextEditor;
