import React from 'react';

import FieldAdapter, { Props as FieldProps } from './FieldAdapter';
import Element, { Props } from 'components/elements/RadioGroup/RadioGroup';

const RadioGroup = (props: Props & FieldProps) => (
  <FieldAdapter {...props}>{props => <Element {...props} />}</FieldAdapter>
);

RadioGroup.displayName = 'RadioGroupField';
export default RadioGroup;
