import React from 'react';

import Field, { Props as FieldProps } from '../Field/Field';
import Input, { Props as InputProps } from 'components/inputs/AutoCompute/AutoCompute';

const AutoCompute: React.FC<FieldProps & InputProps> = props => (
  <Field {...props} component={Input} type="readonly" />
);

AutoCompute.displayName = 'AutoCompute';
export default AutoCompute;
