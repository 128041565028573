import React, { ReactElement, useMemo, useState } from 'react';
import { Change } from './History';
import { useTranslationX } from 'i18n';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
import { Accordion } from 'components/containers';
import { formatFromMetadata } from 'components/form/helpers';

import styles from './HistoryItem.module.scss';

type Props = {
  parentSection: string;
  translationKey?: string;
  element: Change;
};
const History = (props: Props) => {
  const { element, parentSection, translationKey } = props;
  const { type, fonteRecursoStatus, fonteRecurso, fonteRecursoId } = element;
  const [open, setOpen] = useState(false);

  // Create a code to remove [x] from elementSection using regex pattern
  const hasSection = element.name.split('.').length > 1 || element.name.match(/\[\d+\]/g);
  const elementSection = element.name.split('.')?.[0]?.replace(/\[\d+\]/g, '');

  const { tx, t } = useTranslationX('editor', 'update_wp');
  const { tx: tx2 } = useTranslationX('components.tableData', 'translation');

  const sectionPath = useMemo(() => {
    if (element.path) {
      return element.path
        ?.map((item, i) =>
          translationKey && i > 0
            ? t(`${translationKey}.sections.${item}`)
            : tx(`sections.${item}`)
        )
        .join(' > ');
    }
    const list = [];
    const fonteRecursoMap = new Map();
    if (parentSection !== elementSection) {
      list.push(parentSection);
    }

    if (fonteRecurso) {
      fonteRecursoMap.set(
        fonteRecursoId,
        `${fonteRecurso}${
          fonteRecursoStatus
            ? ` ${t(`${translationKey}.sections.${fonteRecursoStatus}`)}`
            : ''
        }`
      );

      list.push(fonteRecursoId);
    }

    if (elementSection && hasSection) list.push(elementSection);
    return list
      .map((item, idx) => {
        if (idx !== 0) {
          if (fonteRecursoId === item) {
            return fonteRecursoMap.get(fonteRecursoId);
          }

          return translationKey
            ? t(`${translationKey}.sections.${item}`)
            : tx(`sections.${item}`);
        }
        return tx(`sections.${item}`);
      })
      .join(' > ');
  }, [
    element.path,
    parentSection,
    elementSection,
    fonteRecurso,
    hasSection,
    translationKey,
    t,
    tx,
    fonteRecursoId,
    fonteRecursoStatus,
  ]);

  const path = useMemo(() => {
    const parts = element.name.split('.');
    return parts
      .filter(part => part !== parentSection)
      .map(part => {
        if (element.replacers && part in element.replacers) {
          return element.replacers[part];
        }
        const regex = /^(\w+)(?:\[(\d+)\])?$/;
        const field = part.match(regex)?.[1];
        const idx = part.match(regex)?.[2];
        if (idx) {
          if (['suportes'].includes(elementSection)) {
            return `${t(`${elementSection}.${field}`)}`;
          }
          if (field === elementSection) {
            return `${t(`${elementSection}.${field}`)} #${Number(idx) + 1}`;
          }
          return `${t(`${elementSection}.${field}`)} #${Number(idx) + 1}`;
        }
        if (hasSection) {
          return t(`${elementSection}.${part}`);
        }

        return t(`${parentSection}.${part}`);
      })
      .join(' - ');
  }, [element.name, element.replacers, elementSection, hasSection, parentSection, t]);

  const lastValued: ValuedComponent = useMemo(() => {
    if (type !== 'modified') {
      return {
        value: tx2(type),
        component: <p className={styles.last}>{tx2(type)}</p>,
      };
    }
    const value = convertIfIsHTML(
      formatFromMetadata(element.from, element.metadata, '-/-') ?? element.display
    );

    return {
      component: <p className={styles.last}>{value}</p>,
      value,
    };
  }, [element.display, element.from, element.metadata, tx2, type]);

  const currValued: ValuedComponent = useMemo(() => {
    if (type !== 'modified') {
      return {
        value: element.display || tx2(type),
        component: <p className={styles.current}>{element.display || tx2(type)}</p>,
      };
    }
    const value = convertIfIsHTML(
      formatFromMetadata(element.to, element.metadata, '-/-') ?? element.display
    );
    return {
      component: <p className={styles.current}>{value}</p>,
      value,
    };
  }, [element.display, element.metadata, element.to, tx2, type]);

  return (
    <div className={styles.item}>
      <div className={styles.sectionPath}>{sectionPath}</div>
      <div className={styles.title}>
        {tx(type, {
          value: path,
        })}
      </div>
      <ItemAccordion
        type={type}
        current={currValued}
        last={lastValued}
        onOpen={expanded => setOpen(expanded)}
        open={open}
      />
    </div>
  );
};

const convertIfIsHTML = (value: unknown): string => {
  if (typeof value === 'string') {
    return value.replace(/<(?:.|\n)*?>/gm, '');
  }
  return value as string;
};

type ValuedComponent = {
  component: ReactElement;
  value: any;
};

type AccordionProps = {
  last: ValuedComponent;
  current: ValuedComponent;
  open: boolean;
  onOpen: (expanded: boolean) => void;
  type: Change['type'];
};

const ItemAccordion = ({ open, onOpen, last, current, type }: AccordionProps) => {
  return (
    <Accordion
      title={
        <Tippy
          content={current.value}
          className={styles.popup}
          zIndex={1100000}
          animation="perspective"
          touch={false}
          followCursor="horizontal"
          plugins={[followCursor]}
        >
          {current.component}
        </Tippy>
      }
      visible={open}
      onChange={onOpen}
      theme="shades-of-gray"
      type={type}
    >
      <Tippy
        content={last.value}
        className={styles.popup}
        zIndex={1100000}
        animation="perspective"
        touch={false}
        followCursor="horizontal"
        plugins={[followCursor]}
      >
        {last.component}
      </Tippy>
    </Accordion>
  );
};

export default History;
