import React from 'react';

import { useTranslationX } from 'i18n';
import { TableData } from 'components/containers';
import TotalLabel from '../TotalLabel/TotalLabel';
import { FieldOption, FormContextValue } from 'components/form/types';

import styles from './Financial.module.scss';

// Vinculo de instituição = Outros.
const VALUE_DEFAULT_VINCULO_INSTITUICAO = 2;

const HumanResources = () => {
  const { tx } = useTranslationX('hr', 'proposal');

  const onObservedFieldChange = (
    id: string,
    value: any,
    form: FormContextValue,
    _: string[] | undefined,
    ...stateFunctions: React.Dispatch<React.SetStateAction<any>>[]
  ) => {
    const strings = id.split('.');

    if (strings.length > 0) {
      const vinculoTrabalhoFieldName =
        strings.slice(0, strings.length - 1).join('.') + '.vinculoTrabalho';
      const metadata = form.getFieldMetadata(vinculoTrabalhoFieldName);
      const options = metadata.options as FieldOption[];

      if (
        options.find(
          (o: FieldOption) =>
            o.value === value &&
            (o.params?.split(',')[3] === 'true' || o.value === 4) /* CLTs e Estagio */
        )
      ) {
        if (
          strings &&
          strings.length > 0 &&
          strings[strings.length - 1] === 'categoriaBolsa'
        ) {
          stateFunctions[0](true);
          form.setFieldValue(id, undefined, true);
        }

        if (
          strings &&
          strings.length > 0 &&
          strings[strings.length - 1] === 'vinculoInstituicao'
        ) {
          stateFunctions[0](true);
          form.setFieldValue(id, VALUE_DEFAULT_VINCULO_INSTITUICAO, true);
        }
      } else {
        stateFunctions[0](false);
      }
    }
  };

  return (
    <div
      className={styles.financial}
      style={{
        minWidth: '83vw',
        maxWidth: '83vw',
      }}
    >
      <TotalLabel tableName="rh" columnName="total" />

      <div className={styles.table}>
        <TableData
          name="rh"
          textSize="small"
          config={[
            {
              title: tx('institution'),
              field: 'vinculoInstituicao',
              align: 'left',
              type: 'select',
              weight: 0.8,
              fieldProps: {
                onObservedFieldChange,
                observeFields: ['vinculoTrabalho'],
              },
            },
            { title: tx('position'), field: 'cargo', weight: 1.2 },
            {
              title: tx('relationship'),
              field: 'vinculoTrabalho',
              type: 'select',
              weight: 0.8,
            },
            {
              title: tx('category'),
              field: 'categoriaBolsa',
              type: 'select',
              weight: 1.2,
              fieldProps: {
                onObservedFieldChange,
                observeFields: ['vinculoTrabalho'],
              },
            },
            {
              title: tx('salary'),
              field: 'remuneracao',
              fieldProps: { align: 'right' },
              weight: 0.7,
              align: 'right',
            },
            {
              title: tx('taxes'),
              field: 'encargos',
              type: 'computed',
              align: 'right',
              fieldProps: {
                align: 'right',
                expression: 'remuneracao * vinculoTrabalho[0] / 100 + vinculoTrabalho[1]',
              },
              weight: 0.7,
            },
            {
              title: tx('duration'),
              field: 'duracao',
              align: 'center',
              fieldProps: { align: 'center' },
              weight: 0.6,
            },
            {
              title: tx('month'),
              field: 'mesExecucao',
              align: 'center',
              fieldProps: { align: 'center' },
              weight: 0.7,
            },
            {
              title: tx('total'),
              field: 'total',
              type: 'computed',
              align: 'right',
              metadata: { rules: { mask: { pattern: 'currency' } } },
              fieldProps: {
                expression: '(remuneracao + encargos) * duracao',
                align: 'right',
                omit: true,
              },
              weight: 0.7,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default HumanResources;
