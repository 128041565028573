import React, { ReactNode } from 'react';

type Props = {
  title?: string;
  adornment?: JSX.Element;
  children?: ReactNode;
  changed?: boolean;
};

const Tab: React.FC<Props> = ({ children }) => <div>{children}</div>;

export default Tab;
