import React from 'react';
import { classes } from 'utils/components';
import { convertHexColorToRGBA } from 'utils/stringUtils';
import styles from './Tag.module.scss';

type Props = {
  text: string;
  color: string;
  fontSize?: number;
  padding?: number | string;
  radius?: number;
  dotSize?: number | string;
  onClick?: () => void;
  onHover?: () => void;
  onMouseLeave?: () => void;
  theme?: 'default' | 'white';
};
export const DEFAULT_TAG_COLOR = '#828181';

const Tag = ({
  text,
  color = DEFAULT_TAG_COLOR,
  fontSize = 10,
  padding = '5px 8px',
  onClick,
  onHover,
  onMouseLeave,
  radius = 10,
  dotSize = '6px',
  theme = 'default',
}: Props) => {
  return (
    <div
      onClick={onClick}
      onMouseEnter={onHover}
      onMouseLeave={onMouseLeave}
      className={styles.tag}
      style={{
        backgroundColor:
          theme === 'white' ? '#e1e1e1' : convertHexColorToRGBA(color, 0.3),
        fontSize: `${fontSize}px`,
        borderRadius: radius,
        padding: padding,
        ['--color' as any]: color,
        ['--size' as any]: dotSize,
      }}
    >
      <div
        className={
          theme === 'white' ? classes(styles.whiteThemeText, styles.text) : styles.text
        }
      >
        {text}
      </div>
    </div>
  );
};

export default Tag;
