import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
import React, { ReactNode } from 'react';
import { classes, renderComponent } from 'utils/components';
import Loader from '../Loader/Loader';

import styles from './HorizontalBarChart.module.scss';
import { Chart } from 'components/icons';
import { useTranslationX } from 'i18n';
import { NotFoundMessage } from '..';

export type GraphItem = {
  label: string;
  value: number;
  color: string;
};

type Props = {
  icon: React.ElementType;
  title: string;
  graphs?: GraphItem[];
  width?: number | string;
  filter?: ReactNode;
  maxRange?: number;
};

const splitMultipleNumbers = (
  max: number,
  numbers: number,
  multiple: number
): number[] => {
  const maxRange = Math.ceil(max / multiple) * multiple;
  const splitTimes = numbers - 1;
  const interval = maxRange / splitTimes;
  const range = [0, ...Array.from(Array(splitTimes).keys()).map(i => interval * (i + 1))];
  return range;
};

const getMaxRange = (graphs: GraphItem[]) => {
  return graphs.reduce((max, graph) => Math.max(max, graph.value), 0);
};

const HorizontalBarChart: React.FC<Props> = props => {
  const {
    graphs,
    filter,
    maxRange = props.graphs ? getMaxRange(props.graphs) : 0,
  } = props;

  const ceilMaxRange = Math.ceil(maxRange / 5) * 5;

  const { tx } = useTranslationX('components.horizontal_bar_chart');

  if (!graphs) {
    return (
      <div
        className={styles.container}
        style={{ width: props.width, justifyContent: 'center', alignItems: 'center' }}
      >
        <Loader size={60} />
      </div>
    );
  }
  return (
    <div className={styles.container} style={{ width: props.width }}>
      <div className={styles.header}>
        <div className={styles.title}>
          {renderComponent(props.icon, { size: 2 })} <h3>{props.title}</h3>
        </div>
        {filter && <div className={styles.filter}>{filter}</div>}
      </div>
      {graphs.length > 0 ? (
        <>
          {maxRange !== 0 && (
            <div className={styles.graph}>
              {graphs.map(item => {
                return (
                  <div key={item.label} className={styles.barGraph}>
                    <p className={styles.label}>{item.label}</p>
                    <Tippy
                      className={classes(styles.popup)}
                      content={`${item.value}`}
                      animation="perspective"
                      touch={false}
                      followCursor="horizontal"
                      plugins={[followCursor]}
                    >
                      <div
                        className={styles.bar}
                        style={{
                          backgroundColor: item.color,
                          width: (item.value / ceilMaxRange) * 100 + '%',
                        }}
                      />
                    </Tippy>
                    <hr />
                  </div>
                );
              })}
            </div>
          )}
          <div className={styles.footer}>
            {splitMultipleNumbers(ceilMaxRange, 6, 5).map((value, index) => {
              return (
                <p
                  key={index}
                  style={{
                    left: index * ((ceilMaxRange / 5 / ceilMaxRange) * 100) + '%',
                  }}
                >
                  {value}
                </p>
              );
            })}
          </div>
        </>
      ) : (
        <NotFoundMessage
          title={tx('not_found_title')}
          description={tx('not_found_description')}
          icon={Chart}
          iconProps={{ size: 2 }}
        />
      )}
    </div>
  );
};

export default HorizontalBarChart;
