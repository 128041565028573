import React, { useCallback, useRef, useState } from 'react';

import { useTranslationX } from 'i18n';
import { Button, Spacer, FileUploader } from 'components/elements';
import { Dialog, DialogRef, VStack } from 'components/containers';

import { messageService } from 'services';
import { NotificationData } from '../../types';
import { useRequest } from 'apis';
import { UI_BUTTON_SPACING } from 'consts';

import styles from './execution.module.scss';

type Props = {
  data: NotificationData;
  content: any;
  dispatch: any;
  onClose: (processed?: boolean) => void;
  reload: (data: NotificationData) => void;
};

const HiringDocsDialog = ({ data, content, onClose, reload }: Props) => {
  const [done] = useState(false);
  const dialog = useRef<DialogRef>(null);

  const { t, tx } = useTranslationX('request.form', 'project');
  const { tx: t3 } = useTranslationX('counterpartPanel', 'project');

  const [submitedArchive, setSubmitedArchive] = useState<string[]>([]);

  const request = useRequest();

  const handleSubmitArchives = useCallback(
    (files: File[], documentName: string) => {
      if (files.length === 0) {
        return;
      }
      const formData = new FormData();
      formData.set('file', files[0]);

      const source = request({
        method: 'POST',
        url: `/projeto/${content.projetoId}/recursos-humanos/${content.prhId}/documento/set_doc_${documentName}`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onSuccess: () => {
          if (
            !content[
              'doc' +
                documentName
                  .split('_')
                  .map(t => t.charAt(0).toUpperCase() + t.substr(1))
                  .join('')
            ] &&
            !submitedArchive?.includes(documentName)
          ) {
            messageService.success(
              tx('archiveSuccess', {
                documentName: tx(`documentsUpload.${documentName}`),
              }),
              {
                duration: 2000,
              }
            );
            reload(data);
            setSubmitedArchive(state => [...state, documentName]);
          }
        },
      });
      return () => source.cancel();
    },
    [content, request, tx, data, reload, setSubmitedArchive, submitedArchive]
  );

  return (
    <Dialog
      ref={dialog}
      title={tx('title.upload-docs')}
      titleAlign="center"
      visible={!!data}
      onClose={onClose}
      closeButton={!done}
      width={676}
    >
      {done ? (
        <VStack align="center" spacing={30}>
          <span style={{ padding: '5px', fontFamily: 'Roboto' }}>{tx('success')}</span>
          <Spacer length={UI_BUTTON_SPACING} />
          <Button onClick={() => dialog.current?.close(true)}>{t('button.ok')}</Button>
        </VStack>
      ) : (
        <div className={styles.popup}>
          <FileUploader
            message={t3('termoOutorga')}
            metadata={{ rules: { accept: ['pdf'], maxfiles: 1 } }}
            onChange={file => handleSubmitArchives(file, 'outorga_e_concessao_assinado')}
            hasFilesUploaded={!!content.docOutorgaEConcessaoAssinado}
          />
          <FileUploader
            message={t3('autorizacaoPolo')}
            metadata={{ rules: { accept: ['pdf'], maxfiles: 1 } }}
            onChange={file => handleSubmitArchives(file, 'autorizacao_polo')}
            hasFilesUploaded={!!content.docAutorizacaoPolo}
          />
        </div>
      )}
    </Dialog>
  );
};

export default HiringDocsDialog;
