import React from 'react';

import useFetch from 'apis/useFetch';

import ProjectLatestCard, { ProjectLatestItem } from './ProjectLatestCard';
import { Loader, NotFoundMessage } from 'components/elements';

import styles from './RecentProjectList.module.scss';

const RecentProjectList: React.FC = () => {
  const { data } = useFetch<ProjectLatestItem[]>(`/projetos/recentes/?limit=6`);

  if (!data) {
    return <Loader size={60} delay type="spin" />;
  }

  return data.length > 0 ? (
    <div className={styles.projectList}>
      {data.map((project: ProjectLatestItem) => (
        <ProjectLatestCard key={project.id} project={project} />
      ))}
    </div>
  ) : (
    <NotFoundMessage />
  );
};

export default RecentProjectList;
