import React, { useState, forwardRef } from 'react';

import { IconButton } from 'components/elements';
import { Eye, EyeSlash } from 'components/icons';
import { readAttr } from 'utils/object';
import { classes } from 'utils/components';
import { InputProps } from '../types';
import { splitValueUnit } from 'utils';

import styles from './Password.module.scss';
import { useEffect } from 'react';

const Password = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { value = '', metadata, control, inputProps } = props;
  const [visible, setVisible] = useState(false);
  const { value: inputHeight } = splitValueUnit(styles.inputHeight);

  useEffect(() => setVisible(false), [control.type]);

  return (
    <div
      className={classes(
        styles.passwordInput,
        styles[control.type || ''],
        control.active ? styles.active : ''
      )}
    >
      <input
        {...inputProps}
        className={styles.input}
        type={visible ? 'text' : 'password'}
        ref={ref}
        value={value}
        maxLength={readAttr('rules.maxlength', metadata)}
      />
      {control.type !== 'readonly' && !control.loading && (
        <IconButton
          type={control.type === 'disabled' ? control.type : undefined}
          icon={visible ? Eye : EyeSlash}
          onClick={() => setVisible(visible => !visible)}
          shape="circle"
          color="#858c94"
          size={inputHeight}
          rate={0.5}
        />
      )}
    </div>
  );
});

Password.displayName = 'PasswordInput';
export default Password;
