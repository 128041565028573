export class Queue<T = any> {
  private elements: T[] = [];

  enqueue(val: T) {
    this.elements.push(val);
  }

  dequeue() {
    return this.elements.shift();
  }

  isEmpty() {
    return this.elements.length === 0;
  }

  peek() {
    return !this.isEmpty() ? this.elements[0] : undefined;
  }

  size() {
    return this.elements.length;
  }
}
