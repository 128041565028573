import React, { useEffect, useState } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { useRequest } from 'apis';
import { Loader, Spacer } from 'components/elements';
import { CardsTabs, RequestForm, RequestTable } from 'pages/project';
import { PartData, SolicitationFinality } from 'pages/project/types';
import { useProjectEditor } from 'pages/project/ProjectEditor/contexts';
import { ProjectEditorContextProps } from 'pages/project/ProjectEditor/contexts/types';
import ContextRoute from '../ContextRoute/ContextRoute';

type Props = {
  requestTable?: boolean;
  requestType?: SolicitationFinality;
  requestForm?: boolean;
};

const SolicitationSection: React.FC<Props & ProjectEditorContextProps> = props => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ContextRoute exact path={`${path}`} {...props}>
        <Dashboard {...props} />
      </ContextRoute>
      {props.requestTable || props.requestForm ? (
        <ContextRoute exact path={`${path}/solicitacao`} {...props}>
          <RequestForm {...props} type={props.requestType || 'servico'} />
        </ContextRoute>
      ) : null}
    </Switch>
  );
};

const Dashboard = ({ requestTable, requestType, ...props }: Props) => {
  const { state } = useProjectEditor();
  const request = useRequest();
  const [data, setData] = useState<PartData[]>();

  useEffect(() => {
    request({
      url: `/projeto/${state.id}/execucao/${state.section}`,
      onSuccess: (res: PartData[]) => {
        setData(res);
      },
    });
  }, [state.updateCount, request, state.id, state.section]);

  if (!data) {
    return <Loader type="spin" />;
  }

  return (
    <>
      <CardsTabs {...props} area={data} />
      {requestTable && <Spacer length={50} />}
      {requestTable && <RequestTable />}
    </>
  );
};

export default SolicitationSection;
