import React from 'react';

const DownloadMacro = ({ size = 2.3 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none">
    <path
      d="M14 10H2V12H14V10ZM14 6H2V8H14V6ZM2 16H10V14H2V16ZM21.5 11.5L23 13L16.01 20L11.5 15.5L13 14L16.01 17L21.5 11.5Z"
      fill="currentColor"
    />
  </svg>
);

export default DownloadMacro;