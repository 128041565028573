import React, { useEffect, useReducer, useState } from 'react';

import { useTranslationX } from 'i18n';
import { MenuItem } from 'pages/dashboard/types';
import { Tab, Tabs } from 'components/containers';
import { renderComponent } from 'utils/components';
import { SectionProps } from '../ProposalEditor/ProposalEditor';
import { useRequest } from 'apis';
import { useAuthState } from 'store/auth';
import { AlertMessage, Loader, WrapperCard } from 'components/elements';

import { ProposalStatus } from '../types';
import { ActionButtons, BudgetSummary } from '.';
import { DisbursementSchedule } from './DisbursementSchedule';

import styles from './SummaryAndActions.module.scss';

export type ProposalSummary = {
  titulo: string;
  estado: ProposalStatus;
  simulacao: boolean;
  total: number;
  partes: string[];
  composicao: any;
  estimativa: any;
  cronograma: any;
  error?: string;
};

const items: MenuItem[] = [
  { key: 'budget-summary', component: BudgetSummary },
  { key: 'disbursement-schedule', component: DisbursementSchedule },
];

const SummaryAndActions = ({ state, dispatch, formProps }: SectionProps) => {
  const { id, showErrors, hasErrors, editable } = state;
  const [data, setData] = useState<ProposalSummary>();

  const [updateKey, forceUpdate] = useReducer(x => x + 1, 0);

  const { tx } = useTranslationX('proposal:financial', ['translation', 'proposal']);
  const { profile } = useAuthState();
  const request = useRequest();

  useEffect(() => {
    request<ProposalSummary>({
      url: `/proposta/${id}/budget-summary`,
      method: 'GET',
      onSuccess: data => {
        setData(data);
      },
    });
  }, [id, request, updateKey]);

  if (!data) {
    return <Loader />;
  }

  if (data.error !== undefined) {
    return (
      <div className={styles.invalid}>
        <AlertMessage title={tx('error.modality')} message={data.error} />
      </div>
    );
  }

  const newFormProps = {
    ...formProps,
    editable: profile !== 'pesquisador' && !!editable,
  };
  const { titulo, estado, simulacao } = data;

  return data.total > 0 ? (
    <div className={styles.section}>
      <ActionButtons
        state={estado}
        simulacao={simulacao}
        id={id}
        title={titulo}
        dispatch={dispatch}
        showErrors={showErrors}
        hasErrors={hasErrors}
      />
      <WrapperCard padding="5px 15px 25px 5px" minWidth="50rem" width="fit-content">
        <Tabs selectedIndex={0} cascade="cascade">
          {items.map(({ key, component }) => (
            <Tab title={tx(key)} key={key}>
              {renderComponent(component, {
                profile,
                state,
                formProps: newFormProps,
                data,
                forceUpdate,
                updateKey,
              })}
            </Tab>
          ))}
        </Tabs>
      </WrapperCard>
    </div>
  ) : (
    <AlertMessage title={tx('titleAlertMessage')} message={tx('alertMessage')} />
  );
};

export default SummaryAndActions;
