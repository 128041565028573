import { FieldType } from 'components/form/types';

type useDescriptionProps = {
  historicData: {
    [key: string]: any;
  };
  showHistoric: boolean;
};

type DescritiveHistoric = {
  name: string;
  type: string;
  from: string;
  to: string;
  metadata: {
    rules: {
      [key: string]: any;
    };
  };
};

export const useHistoricDescription = ({
  historicData,
  showHistoric,
}: useDescriptionProps) => {
  const getTextEditorType = (item: string, aditivo: boolean): FieldType | undefined =>
    item === 'propriedadeIntelectual' && aditivo ? 'readonly' : undefined;

  const hasHistoric = historicData?.descritivo?.length > 0 && showHistoric;

  const descritiveHistoric = hasHistoric
    ? (historicData?.descritivo?.map(
        (item: DescritiveHistoric) => item
      ) as DescritiveHistoric[])
    : [];

  function findSectionChanges(key: string) {
    const currentItem = descritiveHistoric.find(
      (item: DescritiveHistoric) => item.name === `descritivo.${key}`
    );

    return !!currentItem;
  }

  function getSectionHistoric(item: string) {
    const sectionKey = `descritivo.${item}`;
    const currentItem = descritiveHistoric.find(
      (item: DescritiveHistoric) => item.name === sectionKey
    );

    if (currentItem) {
      return currentItem;
    }
  }

  return {
    getSectionHistoric,
    findSectionChanges,
    getTextEditorType,
  };
};
