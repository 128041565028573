import React from 'react';
import styles from './PizzaProgress.module.scss';

type Props = {
  value: number;
  size?: number;
};

const PizzaProgress = ({ value, size = 30 }: Props) => {
  value = Math.min(Math.max(value, 0), 100);

  const radius = 80;
  const circ = Math.PI * (radius * 2);
  const percent = ((100 - value / 2) / 100) * circ;

  return (
    <div className={styles.pizzaProgress}>
      <svg width={size} viewBox="0 0 200 200" style={{ strokeDasharray: circ }}>
        <circle
          r={radius}
          cx="100"
          cy="100"
          shapeRendering="geometricPrecision"
          fill="#696969"
        />
        <circle
          className={styles.bar}
          r={radius / 2}
          cx="100"
          cy="100"
          style={{ strokeDashoffset: `${percent}px` }}
          stroke="url(#gradient)"
          strokeWidth={radius}
          shapeRendering="geometricPrecision"
          fill="transparent"
        />

        <defs>
          <linearGradient id="gradient" cx="0" cy="0" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#29966B" />
            <stop offset="0.5" stopColor="#3FC75C" />
            <stop offset="0.85" stopColor="#56E388" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default PizzaProgress;
