import React from 'react';

const Success = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24">
    <path
      d="m24 12c0-6.62-5.38-12-12-12s-12 5.38-12 12 5.38 12 12 12 12-5.38 12-12z"
      fill="#35a247"
    />
    <path
      d="m19.4 2.62c1.6 2.04 2.55 4.6 2.55 7.38 0 6.6-5.35 12-12 12-2.79 0-5.35-0.951-7.38-2.55 2.19 2.79 5.59 4.59 9.41 4.59 6.6 0 12-5.35 12-12 0-3.82-1.79-7.22-4.59-9.41z"
      fill="#2a8d3b"
    />
    <g transform="scale(.125)" fill="#c4f7cf" strokeWidth="8">
      <path
        transform="translate(44,54)"
        d="m88.6 0-52.5 53.4-21.6-21.8-14.4 14.5 36 36.8 67-68z"
        strokeWidth="17"
      />
    </g>
  </svg>
);

export default Success;
