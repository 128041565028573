import React, { CSSProperties } from 'react';

import { XRect, Warning, Check } from 'components/icons';

import styles from './HeaderMessage.module.scss';

type Props = {
  text: string;
  type?: 'error' | 'warning' | 'success' | 'info';
  style?: CSSProperties;
};

const HeaderMessage: React.FC<Props> = ({ text, type, style }: Props) => {
  const renderIcon = (type: string) => {
    if (type === 'error') {
      return (
        <span className={styles.errorIcon}>
          <XRect />
        </span>
      );
    } else if (type === 'warning') {
      return (
        <span className={styles.warnIcon}>
          <Warning />
        </span>
      );
    } else if (type === 'success') {
      return (
        <span className={styles.successIcon}>
          <Check />
        </span>
      );
    }
  };

  const classColor =
    type === 'warning'
      ? styles.warningColor
      : type === 'success'
      ? styles.successColor
      : styles.dangerColor;

  return (
    <div style={style} className={`${styles.headerMessage} ${classColor}`}>
      {type !== undefined ? renderIcon(type) : null}
      <span className={styles.content}>{text}</span>
    </div>
  );
};

export default HeaderMessage;
