import React, { useState } from 'react';

import { useFetch } from 'apis';
import { API_SERVER_URL } from 'consts';
import { useTranslation } from 'i18n';
import { ErrorMessage, Loader } from 'components/elements';
import { Checkbox, HiddenField } from 'components/form';
import { PDFViewer } from 'components/containers';
import { isNotNil } from 'utils';
import { SectionProps } from '../types';
import { getPath } from '../profile';

import styles from './Agreement.module.scss';

const Agreement = ({ state }: SectionProps) => {
  const [loaded, setLoaded] = useState<boolean>();

  const perfil = getPath(state.profile);
  const ifce = state.ifce || false;

  const { data: id, error } = useFetch<number>('/cadastro/editais', { perfil, ifce });
  const { t } = useTranslation('register');

  const status = loaded
    ? 'ready'
    : loaded === false
    ? 'load_error'
    : error
    ? 'fetch_error'
    : isNotNil(id)
    ? 'loading'
    : 'fetching';

  const locked = status === 'fetching' || status === 'loading';

  return (
    <div className={styles.content}>
      {status === 'fetch_error' && <ErrorMessage message={error?.message} />}

      {isNotNil(id) && (
        <div className={styles.viewer}>
          <PDFViewer file={`${API_SERVER_URL}/public/arquivo/${id}`} onLoad={setLoaded} />
        </div>
      )}

      {status === 'fetching' && <Loader />}

      <HiddenField name="agreement_lock" valid={!locked} omit />

      {!locked && (
        <div className={styles.footer}>
          <Checkbox name="agreement" label={t('agreement.label')} checkRequired omit />
        </div>
      )}
    </div>
  );
};

export default Agreement;
