import React from 'react';

import { Checkbox } from '..';
import { CheckTypes } from '../Check/Check';
import { FieldOption } from 'components/form/types';
import styles from './Checklist.module.scss';

type Props = {
  options: FieldOption[];
  selectedValues: (string | number)[];
  columns?: number;
  checkType?: CheckTypes;
  onChange?: (checked: boolean, value: string | number) => void;
  parseOption?: (value: any) => any;
};

const Checklist: React.FC<Props> = props => {
  const {
    options,
    selectedValues,
    columns = 3,
    checkType = 'filled',
    onChange,
    parseOption = x => x,
  } = props;

  const rows: FieldOption[][] = [];

  options.forEach((option, index) => {
    const row = Math.floor(index / columns);

    if (rows[row]) {
      rows[row].push(option);
    } else {
      rows[row] = [option];
    }
  });

  return (
    <table className={styles.table}>
      <tbody>
        {rows.map((row, index) => (
          <tr key={index}>
            {row.map(({ text, value }, index) => (
              <td key={index}>
                <div>
                  <Checkbox
                    key={value}
                    label={parseOption(text)}
                    value={value}
                    type={checkType}
                    selected={selectedValues.includes(value)}
                    onChange={onChange}
                  />
                </div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Checklist;
