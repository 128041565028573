import { useEffect, useState } from 'react';

const useBuffer = <T>(data: T, delay: number) => {
  const [stored, setStored] = useState<T | null>(data);

  useEffect(() => {
    if (data) {
      setStored(data);
    } else {
      const timer = setTimeout(() => setStored(null), delay);
      return () => clearTimeout(timer);
    }
  }, [data, delay]);

  return stored;
};

export default useBuffer;
