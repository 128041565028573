import React from 'react';

import { useTranslationX } from 'i18n';
import { Grid, Row } from 'components/containers';
import { TextField, HiddenField, FieldSet } from 'components/form';

const Company = () => {
  const { tx } = useTranslationX('company', 'register');

  return (
    <Grid>
      <FieldSet name="empresa">
        <HiddenField name="id" />

        <Row>
          <TextField name="cnpj" label={tx('id')} />
          <TextField name="cnae" label={tx('cnae')} />
        </Row>

        <TextField name="razaoSocial" label={tx('name')} />
      </FieldSet>
    </Grid>
  );
};

export default Company;
