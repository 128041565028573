import React, { ReactNode } from 'react';
import styles from './Section.module.scss';

type Props = {
  title?: string;
  adornment?: React.ReactNode;
  children?: ReactNode;
};

const Section: React.FC<Props> = ({ title, adornment, children }) => {
  return (
    <div className={styles.section}>
      {title || adornment ? (
        <div className={styles.header}>
          {title ? <span>{title}</span> : null}
          {adornment}
        </div>
      ) : null}
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Section;
