import React from 'react';

const OpenPadlock = ({ size = 1 }: { size?: number }) => (
  <svg
    width={`${size}em`}
    viewBox="0 0 11 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 10.125C6.1875 10.125 6.75 9.5625 6.75 8.875C6.75 8.1875 6.1875 7.625 5.5 7.625C4.8125 7.625 4.25 8.1875 4.25 8.875C4.25 9.5625 4.8125 10.125 5.5 10.125ZM9.25 4.5H8.625V3.25C8.625 1.525 7.225 0.125 5.5 0.125C3.775 0.125 2.375 1.525 2.375 3.25H3.5625C3.5625 2.18125 4.43125 1.3125 5.5 1.3125C6.56875 1.3125 7.4375 2.18125 7.4375 3.25V4.5H1.75C1.0625 4.5 0.5 5.0625 0.5 5.75V12C0.5 12.6875 1.0625 13.25 1.75 13.25H9.25C9.9375 13.25 10.5 12.6875 10.5 12V5.75C10.5 5.0625 9.9375 4.5 9.25 4.5ZM9.25 12H1.75V5.75H9.25V12Z"
      fill="currentColor"
    />
  </svg>
);

export default OpenPadlock;
