import React, { useMemo } from 'react';
import { useTranslationX } from 'i18n';
import { useLocation } from 'react-router-dom';
import { useDynamicHeader } from '.';

import styles from './DynamicHeader.module.scss';

const DynamicHeader: React.FC = () => {
  const { pathname } = useLocation();
  const { tx } = useTranslationX('breadcrumb', 'translation');

  const { visible, footer, breadcrumbs } = useDynamicHeader();
  const parts = useMemo(() => {
    return breadcrumbs !== undefined
      ? breadcrumbs
      : pathname
          .split('/')
          .filter(part => !part.match(/^[0-9]+$/))
          .slice(1);
  }, [breadcrumbs, pathname]);

  // Check if string has pattern [key] using regex
  const ignoreTranslation = (str: string) => {
    const pattern = /\[([^\]]+)\]/;
    return pattern.test(str);
  };

  if (!visible) return null;
  return (
    <div className={styles.header}>
      <div className={styles.breadcrumb}>
        {parts.map((part, index) => (
          <span key={index}>
            {ignoreTranslation(part) ? part.replace('[', '').replace(']', '') : tx(part)}
          </span>
        ))}
      </div>
      {footer && <h3 className={styles.footer}>{footer}</h3>}
    </div>
  );
};

export default DynamicHeader;
