import React, { CSSProperties, ReactNode } from 'react';

import { classes } from 'utils/components';
import styles from './VStack.module.scss';

type Props = {
  align?: 'top' | 'center' | 'bottom' | 'stretch';
  spacing?: number;
  fill?: 'width' | 'height' | 'all' | 'none';
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
};

const VStack: React.FC<Props> = props => {
  const { align = 'top', spacing, fill = 'none', className, children } = props;

  const style: CSSProperties = { ...props.style };

  if (spacing) {
    style.padding = spacing;
  }

  return (
    <div
      className={classes(className, styles.vstack, styles[align], styles[fill])}
      style={style}
    >
      {children}
    </div>
  );
};

export default VStack;
