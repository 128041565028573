import React from 'react';
import { useDispatch } from 'react-redux';

import { ErrorMessage, Button } from 'components/elements';
import { logout } from 'store/auth';

const ProfileError = ({ profile }: { profile?: string }) => {
  const dispatch = useDispatch();

  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <ErrorMessage message={`O perfil "${profile}" ainda não foi implementado!`}>
        <Button type="danger" onClick={() => dispatch(logout())}>
          OK
        </Button>
      </ErrorMessage>
    </div>
  );
};

export default ProfileError;
