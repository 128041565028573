import React, { useRef, useEffect, forwardRef } from 'react';

import { readAttr } from 'utils/object';
import { InputProps } from '../types';
import { splitValueUnit } from 'utils';
import useUpperCase from '../useUpperCase';

import styles from './TextArea.module.scss';

export type Props = {
  minRows?: number;
  maxRows?: number;
  placeholder?: string;
  forceActive?: boolean;
};

const TextArea = forwardRef<HTMLTextAreaElement, InputProps & Props>((props, ref) => {
  const {
    metadata,
    inputProps,
    minRows = 1,
    maxRows = 4,
    placeholder,
    forceActive,
  } = props;

  const value = useUpperCase(props.value || '', metadata);
  const inputRef = useRef<HTMLTextAreaElement>();

  useEffect(() => {
    if (inputRef.current) {
      const style = window.getComputedStyle(inputRef.current);

      const { value: lh } = splitValueUnit(style.lineHeight);
      const { value: pt } = splitValueUnit(style.paddingTop);
      const { value: pb } = splitValueUnit(style.paddingBottom);
      const p = pt + pb;

      inputRef.current.style.minHeight = `${lh * minRows + p}px`;
      inputRef.current.style.maxHeight = `${lh * maxRows + p}px`;

      inputRef.current.style.height = inputRef.current.style.minHeight;
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
      if (forceActive) {
        inputRef.current.focus();
      }
    }
  }, [forceActive, maxRows, minRows]);

  function attachRef(el: HTMLTextAreaElement) {
    inputRef.current = el;

    if (typeof ref === 'function') {
      ref(el);
    } else if (ref) {
      ref.current = el;
    }
  }

  const type = props.control.type;

  return (
    <div className={styles.textarea}>
      <textarea
        {...inputProps}
        className={styles.input}
        value={value}
        placeholder={type !== 'disabled' && type !== 'readonly' ? placeholder : undefined}
        ref={attachRef}
        maxLength={readAttr('rules.maxlength', metadata)}
      />
    </div>
  );
});

TextArea.displayName = 'TextArea';
export default TextArea;
