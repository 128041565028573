import React from 'react';
import { RequestForm } from 'pages/project';
import { Switch, useRouteMatch } from 'react-router-dom';
import { ProjectEditorContextProps } from 'pages/project/ProjectEditor/contexts/types';
import ContextRoute from '../shared/ContextRoute/ContextRoute';
import { HRDashboard, PeopleView, TerminationForm, TransferPersonForm } from '.';

const HumanResources: React.FC<ProjectEditorContextProps> = props => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <ContextRoute exact path={`${path}`} {...props}>
        <HRDashboard />
      </ContextRoute>

      <ContextRoute exact path={`${path}/:id/pessoa`} {...props}>
        <PeopleView hasButtons />
      </ContextRoute>

      <ContextRoute exact path={`${path}/:id/pessoa/desligamento`} {...props}>
        <TerminationForm />
      </ContextRoute>

      <ContextRoute exact path={`${path}/:id/pessoa/transferencia`} {...props}>
        <TransferPersonForm />
      </ContextRoute>

      <ContextRoute exact path={`${path}/:id/pessoa/solicitacao`} {...props}>
        <TransferPersonForm />
      </ContextRoute>

      <ContextRoute exact path={`${path}/solicitacao`} {...props}>
        <RequestForm type="rh" />
      </ContextRoute>
    </Switch>
  );
};

export default HumanResources;
