import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { FormObserverContext } from './FormContext';
import { FieldData, FormObserverResult } from './types';
import { readAttr } from 'utils/object';

type FieldObserverResult = {
  name: string;
  changed: boolean;
  value?: string | number | any[] | boolean;
  valid?: boolean;
  data?: FieldData;
};

const useFormFieldObserver = (name: string): FieldObserverResult => {
  const [count, setCount] = useState(0);

  const form = useContext(FormObserverContext);
  const ref = useRef<{ count: number; result: FieldObserverResult }>();

  const callback = useCallback(
    (res: FormObserverResult) => {
      const { data } = res as FieldObserverResult;
      if (
        ref.current &&
        (ref.current.result.value !== data?.value ||
          !!ref.current.result.valid !== !!data?.valid ||
          (ref.current.result.valid === undefined && data?.valid !== undefined))
      ) {
        ref.current.result = {
          name,
          changed: true,
          value: data?.value,
          valid: data?.valid,
          data,
        };

        setCount(c => c + 1);
      }
    },
    [name]
  );

  if (!ref.current) {
    const data = form.values[name] || readAttr(name, form.initialValues) || {};

    ref.current = {
      count,
      result: { name, changed: false, value: data.value, valid: data.valid, data },
    };
    form.subscribe(callback, name);
  }

  useEffect(() => () => form.unsubscribe(callback), [callback, form]);

  ref.current.result.changed = ref.current.count !== count;
  ref.current.count = count;

  return ref.current.result;
};

export default useFormFieldObserver;
