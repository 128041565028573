import React from 'react';

const Trash = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24">
    <path
      d="m22 6.17c0-0.221-0.105-0.433-0.293-0.589-0.188-0.156-0.442-0.244-0.707-0.244h-5v-0.833c0-0.663-0.316-1.3-0.879-1.77-0.563-0.469-1.33-0.732-2.12-0.732h-2c-0.796 0-1.56 0.263-2.12 0.732-0.563 0.469-0.879 1.1-0.879 1.77v0.833h-5c-0.265 0-0.52 0.0878-0.707 0.244-0.188 0.156-0.293 0.368-0.293 0.589 0 0.221 0.105 0.433 0.293 0.589s0.442 0.244 0.707 0.244h18c0.265 0 0.52-0.0878 0.707-0.244 0.188-0.156 0.293-0.368 0.293-0.589z"
      fill="currentColor"
    />
    <path
      d="m4 7.83c-0.137-2.75e-4 -0.273 0.023-0.4 0.0685-0.126 0.0455-0.24 0.112-0.333 0.196-0.0938 0.0837-0.166 0.183-0.212 0.29-0.0459 0.108-0.0647 0.223-0.055 0.337l1 12.5c0.0177 0.211 0.131 0.408 0.317 0.552s0.43 0.224 0.683 0.223h14c0.254 5e-4 0.498-0.0792 0.684-0.223 0.186-0.144 0.299-0.341 0.317-0.552l1-12.5c0.0096-0.114-0.0091-0.229-0.0551-0.337-0.0458-0.108-0.118-0.207-0.212-0.29-0.0939-0.0837-0.207-0.15-0.334-0.196-0.126-0.0455-0.262-0.0688-0.4-0.0685zm5 8.33c0 0.221-0.105 0.433-0.293 0.589-0.188 0.156-0.442 0.244-0.707 0.244-0.265 0-0.52-0.0879-0.707-0.244-0.188-0.156-0.293-0.368-0.293-0.589v-3.33c0-0.221 0.105-0.433 0.293-0.589 0.188-0.156 0.442-0.244 0.707-0.244 0.265 0 0.52 0.0878 0.707 0.244 0.188 0.156 0.293 0.368 0.293 0.589zm4 0c0 0.221-0.105 0.433-0.293 0.589-0.188 0.156-0.442 0.244-0.707 0.244-0.265 0-0.52-0.0879-0.707-0.244-0.188-0.156-0.293-0.368-0.293-0.589v-3.33c0-0.221 0.105-0.433 0.293-0.589 0.188-0.156 0.442-0.244 0.707-0.244 0.265 0 0.52 0.0878 0.707 0.244 0.188 0.156 0.293 0.368 0.293 0.589zm4 0c0 0.221-0.105 0.433-0.293 0.589-0.188 0.156-0.442 0.244-0.707 0.244-0.265 0-0.52-0.0879-0.707-0.244-0.188-0.156-0.293-0.368-0.293-0.589v-3.33c0-0.221 0.105-0.433 0.293-0.589 0.188-0.156 0.442-0.244 0.707-0.244 0.265 0 0.52 0.0878 0.707 0.244 0.188 0.156 0.293 0.368 0.293 0.589z"
      fill="currentColor"
    />
  </svg>
);

export default Trash;
