import React, { ReactNode, useRef } from 'react';
import { useResizeObserver } from 'components/hooks';

import styles from './WrapperCard.module.scss';

type Props = {
  padding?: string;
  boxShadow?: string;
  maxWidth?: string;
  maxHeight?: string;
  minWidth?: string;
  margin?: string;
  width?: string;
  height?: string;
  minHeight?: string;
  childrenWidth?: string;
  responsive?: boolean;
  overflow?: string;
  borderRadius?: string;
  children?: ReactNode;
  elevation?: boolean;
};

const WrapperCard: React.FC<Props> = ({
  padding,
  maxWidth = 'fit-content',
  minWidth,
  maxHeight = 'auto',
  height = 'auto',
  minHeight = 'auto',
  children,
  margin,
  width,
  boxShadow,
  borderRadius,
  childrenWidth = 'fit-content',
  responsive = false,
  elevation = true,
}) => {
  const parent = useRef<HTMLDivElement>(null);
  const child = useRef<HTMLDivElement>(null);

  useResizeObserver({
    ref: child,
    onResize: ({ width }) => {
      if (parent.current) {
        if (width !== Number(parent.current.style.width) && responsive) {
          parent.current.style.width = `${width}px`;
        }
      }
    },
  });

  return responsive ? (
    <div
      style={{
        height: '100%',
      }}
    >
      <div
        className={styles.wrapperCardResponsive}
        ref={parent}
        style={{
          margin: margin,
          padding: padding,
          minWidth: minWidth,
          maxWidth: maxWidth !== 'fit-content' ? maxWidth : undefined,
          height: height,
          minHeight: minHeight,
          boxShadow,
          borderRadius,
          filter: elevation ? 'inherit' : 'none',
        }}
      >
        <div
          ref={child}
          style={{
            width: childrenWidth,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  ) : (
    <div
      className={styles.wrapperCard}
      style={{
        padding: padding,
        maxWidth: maxWidth,
        maxHeight: maxHeight,
        minWidth: minWidth,
        width: width,
        height: height,
        minHeight: minHeight,
        margin: margin,
        boxShadow,
        borderRadius,
        filter: elevation ? 'inherit' : 'none',
      }}
    >
      {children}
    </div>
  );
};

export default WrapperCard;
