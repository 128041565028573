import React, { ElementType } from 'react';

import { IconButton, NamedUserBadge, Tag, Toggle } from 'components/elements';
import { Pencil } from 'components/icons';
import { CellConfig } from './types';
import { ListType } from './TableList';
import DOMPurify from 'dompurify';

import styles from './TableListItem.module.scss';
import Status from 'components/elements/Status/Status';
import { appendDots, capitalize, formatDateToBrazilian } from 'utils/stringUtils';
import { getMasked } from 'utils/inputMask';
import { useTranslation } from 'i18n';
import { classes } from 'utils/components';
import Tippy from '@tippyjs/react';

type Props = {
  data: { [key: string]: any };
  metadata: CellConfig<ListType>[];
  onClick?: () => void;
  editIcon?: ElementType | null;
  actions?: JSX.Element | null;
};

export type TableAction = {
  icon?: React.ElementType;
  onClick?: () => void;
  identifier?: string;
  label: string;
  color?: string;
  rate?: number;
};

const TableListItem = ({ data, metadata, onClick, editIcon, actions }: Props) => {
  const props =
    editIcon === null
      ? { onClick, style: onClick ? { cursor: 'pointer' } : {} }
      : undefined;
  const { t } = useTranslation('translation');
  return (
    <tr
      {...props}
      className={classes(
        actions ? styles.actionItem : '',
        editIcon === null && onClick ? styles.hoverable : ''
      )}
    >
      {metadata.map(config => (
        <td
          key={config.field}
          style={config.align ? { textAlign: config.align } : undefined}
        >
          {renderContent(data, config, t)}
        </td>
      ))}

      {actions && (
        <td className={styles.actionContainer}>
          <div>{actions}</div>
          <span />
        </td>
      )}

      {editIcon !== null ? (
        <td style={{ textAlign: 'left' }}>
          <IconButton
            icon={editIcon || Pencil}
            rate={0.5}
            onClick={onClick}
            type="dark"
          />
        </td>
      ) : null}
    </tr>
  );
};

function renderContent(
  data: { [key: string]: any },
  config: CellConfig<ListType>,
  t: (key: string) => string
) {
  let props: any;

  if (config.parse) {
    const res = config.parse(data[config.field!], data);

    if (typeof res === 'object') {
      props = res;
    } else {
      props = { value: res };
    }
  } else {
    props = { value: data[config.field!] };
  }

  switch (config.type) {
    case 'status':
      return <Status active={props.value} />;
    case 'toggle':
      return <Toggle active={props.value} />;

    case 'tag':
      return <Tag text={props.value} color={props.bgColor} />;

    case 'badge':
      return (
        <NamedUserBadge
          name={
            config.capitalize && props.value
              ? capitalize(props.value.toUpperCase())
              : props.value
          }
        />
      );
    case 'icon':
      return (
        <IconButton
          hint={config.title}
          icon={props.icon}
          rate={props.rate}
          onClick={props.onClick}
        />
      );

    case 'html':
      return (
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.value) }} />
      );
    case 'tippy':
      return (
        <Tippy
          className={styles.popup}
          content={props.value}
          placement="top-start"
          animation="perspective"
          offset={[0, 5]}
          delay={[100, 0]}
          hideOnClick={false}
        >
          <span>
            <strong>{appendDots(props.value, 20)}</strong>
          </span>
        </Tippy>
      );
    case 'actions':
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          {props
            .filter((action: TableAction) => action.icon !== undefined)
            .map((action: TableAction, index: number) => {
              return (
                <IconButton
                  key={index}
                  icon={action.icon!}
                  size={28}
                  rate={0.8}
                  onClick={action.onClick}
                  children={<div style={{ color: action.color }}>{action.label}</div>}
                  color={action.color}
                />
              );
            })}
        </div>
      );
    case 'money':
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          <>
            <strong>
              {typeof props.value == 'number'
                ? getMasked(Number(props.value).toFixed(2), { pattern: 'currency' })
                : props.value}
            </strong>
            {typeof props.value == 'number' && <span>{t('currency')}</span>}
          </>
        </div>
      );
    case 'toBuild':
      return config.columnBuilder ? config.columnBuilder(data) : null;
    case 'date':
      return isNaN(Date.parse(props.value))
        ? props.value
        : formatDateToBrazilian(props.value);
    default:
      return config.capitalize && props.value
        ? capitalize(String(props.value).toUpperCase())
        : props.value;
  }
}

export default TableListItem;
