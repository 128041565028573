import { useTranslationX } from 'i18n';
import React from 'react';
import { getMasked } from 'utils/inputMask';
import { WrapperCard } from '..';

import styles from './TotalCard.module.scss';

type Props = {
  label: string;
  value: number;
};

const TotalCard: React.FC<Props> = ({ label, value }) => {
  const { t } = useTranslationX('translation');
  return (
    <WrapperCard>
      <div className={styles.totalCard}>
        <h1>{label}</h1>
        <p>{`${t('currency')} ${getMasked(Number(value).toFixed(2), {
          pattern: 'currency',
        })}`}</p>
      </div>
    </WrapperCard>
  );
};

export default TotalCard;
