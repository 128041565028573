import React, { useMemo } from 'react';
import { useTranslationX } from 'i18n';
import { getMasked } from 'utils/inputMask';

import styles from './CompositionTable.module.scss';

type Props = {
  composicao: any;
  type: 'composition' | 'estimation';
};

const CompositionTable = ({ composicao, type }: Props) => {
  const { tx } = useTranslationX('financial', 'update_wp');
  const { t } = useTranslationX('translation');

  let i = 0;

  const partLabelVal = useMemo(
    () => (
      <>
        <tr key={i++}>
          <th key={i++}>
            <h4>{tx(`${type}.title`)}</h4>
          </th>
        </tr>
        <tr key={i++}>
          <th key={i++}></th>

          <React.Fragment key={i++}>
            <td key={i++}>{`${tx('composition.currency')} (${t('currency')})`}</td>
            <td key={i++}>{tx('composition.percent')}</td>
          </React.Fragment>
        </tr>
      </>
    ),
    [i, t, tx, type]
  );

  const labelKeys = useMemo(
    () =>
      type === 'composition'
        ? ['modalidadeEmbrapii', 'outros', 'total']
        : [
            'recursos-humanos',
            'material',
            'equipamentos',
            'servicos',
            'passagens',
            'diarias',
            'outros',
            'suporte',
            'total',
          ],
    [type]
  );

  let j = 0;

  const groupedValues = useMemo(
    () =>
      labelKeys.map(label => {
        return [composicao[label], (composicao[label] / composicao.total) * 100];
      }),
    [composicao, labelKeys]
  );

  const compositionList = useMemo(() => {
    return groupedValues.map(value => {
      return (
        <tr key={i++}>
          <td key={i++} className={styles.labelKeys}>
            {tx(`${type}.${labelKeys[j++]}`)}
          </td>

          <td key={i++}>
            {getMasked(value[0].toFixed(2), {
              pattern: 'currency',
            })}
          </td>
          <td key={i++}>
            {getMasked(value[1].toFixed(2), {
              pattern: 'currency',
            })}
          </td>
        </tr>
      );
    });
  }, [groupedValues, i, j, labelKeys, tx, type]);
  return (
    <>
      <div className={styles.summary}>
        <table className={styles.financial}>
          <thead>{partLabelVal}</thead>
          <tbody>{compositionList}</tbody>
        </table>
      </div>
    </>
  );
};

export default CompositionTable;
