import React from 'react';

import { useTranslation } from 'i18n';

import { InfoDialog } from 'components/containers';

export type Props = {
  visible: boolean;
  onClose: () => void;
  message: string;
};

const ReadOnlyInfoDialog = (props: Props) => {
  const { visible, onClose, message } = props;

  const { t } = useTranslation();

  return (
    <InfoDialog
      icon={InfoDialog}
      visible={visible}
      onClose={onClose}
      message={message}
      buttonLabel={t('button.ok')}
    />
  );
};

export default ReadOnlyInfoDialog;
