import React, {
  CSSProperties,
  ElementType,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';

import { VscNewFile } from 'react-icons/vsc';

import { RequestConfig, useRequest } from 'apis';
import { splitValueUnit } from 'utils';
import { classes } from 'utils/components';
import { Pageable } from 'components/form/types';
import { Button, IconButton, Loader, NotFoundMessage } from 'components/elements';

import { ScrollPanel } from '..';
import {
  ActionFilter,
  ColumnConfig,
  FilterManagerConfig,
  FilterType,
  StateFilter,
  TextSize,
} from './types';
import { parseTableConfig } from './helpers';
import HeaderCell from './HeaderCell';
import Edit from './Edit';
import TableListItem from './TableListItem';
import { useTranslationX } from 'i18n';
import { XAlt } from 'components/icons';
import { isNotDeepEquals, readAttr, sanitizeObject, writeAttr } from 'utils/object';

import { NotFoundProps } from 'components/elements/NotFoundMessage/NotFoundMessage';

import styles from './TableList.module.scss';

type Entry = {
  id: number | string;
};

export type Instance = {
  reload: () => void;
  reset: () => void;
  resetAfterDelete: () => void;
};

export type Action = 'remove' | 'save';
export type ListType =
  | 'text'
  | 'status'
  | 'tag'
  | 'toggle'
  | 'html'
  | 'icon'
  | 'actions'
  | 'money'
  | 'date'
  | 'toBuild'
  | 'tippy'
  | 'badge';

const reducer = (state: StateFilter, action: ActionFilter): StateFilter => {
  switch (action.type) {
    case 'FILTER_UPDATE':
      if (isNotDeepEquals(state.filters, action.payload)) {
        return state;
      }
      return {
        ...state,
        status: 'update',
        filters: sanitizeObject({ ...state.filters, ...action.payload }),
      };

    case 'FILTER_RESET':
      return { ...state, status: 'clean', filters: {} };

    case 'UPDATE_SORT':
      return { ...state, status: 'update', sort: action.payload };

    case 'FINISH_UPDATE':
      return { ...state, status: 'idle' };

    default:
      return state;
  }
};

export type Props<T extends Entry> = {
  baseUrl: string;
  editUrl?: string;
  config: ColumnConfig<ListType>;
  filterConfig?: FilterManagerConfig;
  editRender?: (entry: T) => JSX.Element;
  editAction?: (entry: T) => void | boolean;
  keyComposer?: (entry: T, index?: number) => string;
  validateUrl?: string | ((entry: T) => string);
  listHeader?: {
    title?: string;
    subtitle?: string;
    position?: 'outside' | 'inside';
  };
  addButton?: {
    label?: string;
    type?: 'default' | 'green';
  };
  boldHeaders?: boolean;
  dialog?: boolean;
  deleteButton?: boolean;
  editTitle?: string | JSX.Element | ((entry: T) => string | JSX.Element);
  editIcon?: ElementType | null;
  notFoundProps?: NotFoundProps;
  theme?: 'classic' | 'light';
  populate?: boolean;
  style?: {
    root?: CSSProperties;
    table?: CSSProperties;
    edit?: CSSProperties;
    card?: CSSProperties;
    content?: CSSProperties;
  };
  autoDisable?: boolean;
  textSize?: TextSize;
  actionBuilder?: (entry: T, instance: Instance) => JSX.Element | undefined | null;
  reference?: React.MutableRefObject<Instance | undefined>;
  filter?: boolean;
  filterFixed?: any;
  infinityScroll?: boolean;
  refreshesOn?: number;
  pageSize?: number;
  isAdmin?: boolean;
  defaultSearchProps?: {
    sort: string;
    filters?: FilterType;
  };
};

const TableList = <T extends Entry>(props: Props<T>) => {
  const { header, body } = parseTableConfig(props.config);
  const {
    baseUrl,
    editUrl,
    filterConfig,
    editRender,
    editAction,
    validateUrl,
    listHeader = {},
    keyComposer,
    infinityScroll,
    pageSize,
    refreshesOn,
    filterFixed,
    addButton,
    boldHeaders,
    isAdmin,
    dialog,
    deleteButton,
    editTitle,
    editIcon,
    theme = 'classic',
    textSize,
    actionBuilder,
    reference,
    filter,
    defaultSearchProps = {
      filters: {},
      sort: `${body[0].field},asc`,
    },
  } = props;

  const { populate, style = {} } = props;

  const { type: addButtonType = 'default', label: addButtonLabel } = addButton || {};

  const { tx } = useTranslationX('components', 'translation');

  const [data, setData] = useState<Pageable<T>>();
  const [editItem, setEditItem] = useState<T>();
  const [headerH, setHeaderH] = useState(0);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [lastPage, setLastPage] = useState(false);

  const [stateFilter, dispatchFilter] = useReducer(reducer, {
    status: 'init',
    ...defaultSearchProps,
  });

  const request = useRequest();

  const pushData = useCallback(
    (dt: Pageable<T>) => {
      if (dt.content.length === 0) {
        setLastPage(true);
      }
      if (refresh === 0) {
        setData(dt);
      } else {
        setData(data => {
          if (data !== null && data !== undefined)
            dt.content = [...data.content, ...dt.content];
          return dt;
        });
      }
      setLoading(false);
    },
    [refresh]
  );

  const loadData = useCallback(() => {
    if (lastPage) {
      return;
    }
    const sort = stateFilter.sort;
    const options: RequestConfig<Pageable<T>> = {
      url: baseUrl,
      onSuccess: infinityScroll ? pushData : setData,

      params: infinityScroll
        ? { sort, ...{ size: pageSize || 20, page: refresh } }
        : { sort },
    };

    if (filter) {
      options.method = 'post';
      options.data = { ...filterFixed, ...stateFilter.filters };
    }

    const source = request<Pageable<T>>(options);
    return () => source.cancel();
  }, [
    baseUrl,
    filter,
    infinityScroll,
    pageSize,
    pushData,
    refresh,
    filterFixed,
    request,
    stateFilter.filters,
    stateFilter.sort,
    lastPage,
  ]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const hasFilters = () => {
    return Object.entries(stateFilter.filters || {}).length > 0;
  };

  const reload = useCallback(() => {
    setRefresh(c => c + 1);
  }, []);

  const reset = useCallback(() => {
    setLastPage(false);
    setRefresh(() => 0);
  }, []);

  const resetAfterDelete = useCallback(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    reset();
  }, [reset, stateFilter]);

  const instance = useRef<Instance>({ reload, reset, resetAfterDelete });

  if (reference) {
    reference.current = instance.current;
  }

  if (!data) {
    return <Loader size={60} delay />;
  }

  const editContent = editItem && editRender ? editRender(editItem) : null;

  const dispatch = (action: Action) => {
    if (!editItem) {
      throw new Error();
    }

    switch (action) {
      case 'remove':
        data.content = data.content.filter(item => item.id !== editItem.id);
        setData(data);
        reset();
        break;

      case 'save':
        reload();
        break;

      default:
        throw new Error();
    }
  };

  const edit = (item: T) => {
    if (typeof editAction === 'function') {
      if (editAction(item)) {
        setEditItem(item);
      }
    } else {
      setEditItem(item);
    }
  };

  const showEditContent = (data: T) => {
    if (editContent) {
      const title = typeof editTitle === 'function' ? editTitle(data) : editTitle;

      return (
        <Edit
          baseUrl={editUrl || baseUrl}
          validateUrl={validateUrl}
          data={data}
          dispatch={dispatch}
          deleteButton={deleteButton}
          title={title}
          onClose={() => setEditItem(undefined)}
          populate={populate}
          dialog={dialog}
          style={style?.edit}
          autoDisable={props.autoDisable}
        >
          {editContent}
        </Edit>
      );
    }

    return null;
  };

  const onClickEdit = (item: T) => {
    if (editIcon === null) {
      return editAction && (() => edit(item));
    } else {
      return () => edit(item);
    }
  };

  if (editItem && !dialog) {
    return showEditContent(editItem);
  }
  const { value: barWidth } = splitValueUnit(styles.barWidth);
  const editColWidth = editIcon === null ? 0 : 68 + barWidth;

  const renderTitle = () => {
    const position = getListHeaderPosition();

    if (listHeader) {
      const { title, subtitle } = listHeader;
      return title || addButtonLabel ? (
        <div
          className={styles.header}
          style={
            position === 'inside'
              ? {
                  padding: '20px 20px 0',
                }
              : {}
          }
        >
          <div className={styles.titles}>
            {subtitle && <h2>{subtitle}</h2>}
            {title && <h1>{title}</h1>}
          </div>
          {addButtonLabel ? (
            addButtonType === 'default' ? (
              <div className={styles.addButton} onClick={() => edit({} as any)}>
                <VscNewFile size={30} />
                <span>{addButtonLabel}</span>
              </div>
            ) : (
              <Button onClick={() => edit({} as any)}>{addButtonLabel}</Button>
            )
          ) : null}
        </div>
      ) : null;
    }
  };

  const getListHeaderPosition = () => {
    if (listHeader) {
      return listHeader.position === undefined ? 'outside' : listHeader.position;
    } else {
      return 'outside';
    }
  };

  return (
    <div
      className={classes(
        styles.tableList,
        styles[theme],
        styles[textSize || ''],
        isAdmin ? styles.adminTable : ''
      )}
      style={style.root}
    >
      {getListHeaderPosition() === 'outside' ? renderTitle() : null}
      <div
        className={classes(hasFilters() || filterConfig ? styles.filtersManager : null)}
      >
        {filter && (
          <>
            {hasFilters() && (
              <div className={styles.iconButton}>
                <IconButton
                  size={25}
                  type="dark"
                  shape="square"
                  icon={XAlt}
                  onClick={() => {
                    dispatchFilter({ type: 'FILTER_RESET' });
                    reset();
                  }}
                >
                  <div className={styles.buttonLabel}>{tx('tableList.cleanFilters')}</div>
                </IconButton>
              </div>
            )}
            {filterConfig &&
              filterConfig.map((node, i) => (
                <div className={styles.iconButton} key={i}>
                  <IconButton
                    key={i}
                    size={node.size || 25}
                    type={
                      readAttr('categoryParam', stateFilter.filters) === node.field
                        ? 'primary'
                        : 'dark'
                    }
                    shape="square"
                    icon={node.icon}
                    onClick={() => {
                      reset();
                      const filters = { ...stateFilter.filters };
                      writeAttr('categoryParam', node.field, filters);
                      dispatchFilter({
                        type: 'FILTER_UPDATE',
                        payload: filters,
                      });
                    }}
                  >
                    <div className={styles.buttonLabel}>{node.label}</div>
                  </IconButton>
                </div>
              ))}
          </>
        )}
      </div>

      <ScrollPanel
        vBar={{ start: headerH, overlay: true }}
        refreshesOn={refreshesOn}
        setLoading={setLoading}
        loading={loading}
        style={{
          ...style,
          root: {
            paddingBottom: '12px',
            paddingRight: '14px',
            backgroundColor: '#FFF',
            borderRadius: 12,
            boxShadow: '27px 0px 20px rgba(0, 0, 0, 0.05)',
            height: 'min-content',
            ...style.card,
          },
        }}
        onNeedRefresh={infinityScroll ? () => reload() : undefined}
      >
        {getListHeaderPosition() === 'inside' ? renderTitle() : null}
        <table>
          <colgroup>
            {body.map(item => (
              <col
                key={item.field}
                style={
                  typeof item.width === 'number' ? { width: `${item.width}%` } : undefined
                }
              />
            ))}

            {editColWidth ? <col style={{ width: editColWidth }} /> : null}
          </colgroup>

          <thead ref={ref => (ref ? setHeaderH(ref.offsetHeight) : null)}>
            {header.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <HeaderCell
                    key={cellIndex}
                    dispatchFilter={dispatchFilter}
                    stateFilter={stateFilter}
                    level={rowIndex}
                    config={cell}
                    boldHeaders={boldHeaders}
                  />
                ))}
                {rowIndex || editIcon === null ? null : (
                  <HeaderCell
                    level={rowIndex}
                    config={{
                      colspan: 2,
                      rowspan: 2,
                      title: tx('tableList.editTitle'),
                      headerBold: true,
                    }}
                    boldHeaders={boldHeaders}
                  />
                )}
              </tr>
            ))}
          </thead>

          <tbody>
            {data.content &&
              data.content.map((item, index) => (
                <TableListItem
                  key={keyComposer ? keyComposer(item, index) : item.id}
                  metadata={body}
                  data={item}
                  onClick={onClickEdit(item)}
                  editIcon={editIcon}
                  actions={
                    typeof actionBuilder === 'function'
                      ? actionBuilder(item, instance.current)
                      : undefined
                  }
                />
              ))}
          </tbody>
        </table>
        {(data.content === undefined || data.content.length === 0) &&
          props.notFoundProps && <NotFoundMessage {...props.notFoundProps} />}
      </ScrollPanel>

      {editItem && dialog ? showEditContent(editItem) : null}
    </div>
  );
};

export default TableList;
