import React from 'react';

import { useTranslationX } from 'i18n';
import { Grid, Row } from 'components/containers';
import { Form, HiddenField, RadioGroup, TextField } from 'components/form';

import { SectionProps } from 'pages/project/update-wp/UpdateWpEditor';

import { Spacer, WrapperCard } from 'components/elements';

import styles from './Identification.module.scss';

const Identification = ({ state: { id }, formProps, type }: SectionProps) => {
  const { tx } = useTranslationX('identification', 'update_wp');

  const aditivo = type === 'aditivo';

  return (
    <Form baseUrl={`/projeto/${id}/alteracao-pt/identification`} {...formProps}>
      <div className={styles.identification}>
        <Grid minWidth={640} maxWidth={900}>
          <WrapperCard padding="25px 30px" minWidth="730px">
            <Row width={[0.8, 0.2]}>
              <TextField
                name="titulo"
                label={tx('title')}
                placeholder={tx('title')}
                type="readonly"
              />
              <TextField
                type="readonly"
                name="sigla"
                label={tx('initials')}
                placeholder={tx('initials')}
              />
            </Row>

            <Row width={[0.4, 0.12, 0.48]}>
              <TextField name="coordenador.nome" label={tx('manager')} type="readonly" />
              <TextField
                name="duracao"
                label={tx('duration')}
                placeholder="00"
                adornment={{ right: tx('months') }}
                type={aditivo ? undefined : 'readonly'}
                clientSideDiff
              />
            </Row>
            <HiddenField name="modalidade" />

            <RadioGroup
              name="modalidade"
              label={tx('modality')}
              columns={2}
              autosave={false}
              type="readonly"
            />
          </WrapperCard>
          <Spacer length={23} />

          <Spacer length={23} />
          <WrapperCard padding="0px" minWidth="740px">
            <div className={styles.radioGroup}>
              <RadioGroup
                name="maturidade"
                label={tx('trl')}
                parseOption={op => `${op.value} - ${op.text}`}
                type={aditivo ? undefined : 'readonly'}
              />
            </div>
          </WrapperCard>
        </Grid>
      </div>
    </Form>
  );
};

export default Identification;
