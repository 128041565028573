import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import crypto from 'crypto';
import _, { cloneDeep } from 'lodash';
import { useForm } from '.';
import { GenericObject } from 'components/inputs/types';

export type UseDiffReturn = {
  diff: RowDiff[];
  removedRows: RowDiff[];
  getDiff: (id: number) => RowDiff | undefined;
  getRespectiveRow: (ordem: number) => RowDiff | undefined;
  getAloneRows: () => RowDiff[];
  getRespectiveRowByFonteRecurso: (
    fonteRecurso: number,
    fonteRecursoStatus: number
  ) => RowDiff[];
};

export type DiffType = 'added' | 'removed' | 'unchanged';

export type Diff = {
  type: DiffType;
};

export type RowDiff =
  | (Diff & {
      reference: number | null;
      data: IdentifiableRow;
      complete?: boolean;
    })
  | {
      type: 'modified';
      data: never;
      reference: number | null;
      changes: {
        [key: string]: {
          from: any;
          to: any;
        };
      };
      complete?: boolean;
    };

type IdentifiableRow = GenericObject & {
  id: number;
  referencia?: number | null;
  ordem: number;
  fonteRecursoStatus?: number;
  fonteRecurso?: number;
};

type IdentifiableRowMapper = {
  [key: number]: IdentifiableRow;
};

const getRowsId = (rows: IdentifiableRow[]) => {
  return rows.map(row => row.id);
};

const getRowsOrdem = (rows: IdentifiableRow[]) => {
  return rows.map(row => row.ordem);
};

const getRowsReference = (rows: IdentifiableRow[]) => {
  return rows.map(row => row.referencia);
};

const insertInTable = (data: IdentifiableRow[]) => {
  const table = {} as IdentifiableRowMapper;
  if (!data) return undefined;

  data.forEach(row => {
    table[row?.id] = row;
  });

  return table;
};
const insertInTableByReference = (data: IdentifiableRow[]) => {
  const table = {} as IdentifiableRowMapper;
  if (!data) return table;

  data.forEach(row => {
    if (!row.referencia) return;
    table[row.referencia] = row;
  });

  return table;
};

const cleanupRow = (row: IdentifiableRow, ignore: string[]) => {
  Object.keys(row).forEach(field => {
    if (row.hasOwnProperty(field)) {
      if (typeof row[field] === 'object' && row[field] !== null) {
        // Se o valor do campo for um objeto, chama a função recursivamente
        cleanupRow(row[field] as IdentifiableRow, ignore);
      } else if (ignore.includes(field)) {
        delete row[field];
      }
    }
  });
};

const hashRow = (row: IdentifiableRow) => {
  const newRow = cloneDeep(row);
  cleanupRow(newRow, ['id', 'referencia', 'ordem']);

  return crypto.createHash('sha256').update(JSON.stringify(newRow)).digest('hex');
};

export const hashValue = (value: unknown) => {
  return crypto.createHash('sha256').update(JSON.stringify(value)).digest('hex');
};

const detectInsertionDeletion = (
  data: IdentifiableRowMapper,
  reference: IdentifiableRowMapper
): [number[], RowDiff[]] => {
  // Get the ids of the rows that were removed and get row

  const removedIds = _.difference(
    getRowsId(Object.values(reference)),
    getRowsReference(Object.values(data))
  )
    .filter(id => id !== null)
    .map(id => id as number);

  const newIds = Object.values(data)
    .filter(row => row.referencia === null || row.referencia === undefined)
    .map(row => row.id);

  const removedRows = removedIds.map(id => {
    const oldRow = reference[id];
    return {
      type: 'removed',
      reference: id,
      data: oldRow,
      complete: true,
    };
  }) as RowDiff[];

  const newRows = newIds.map(id => {
    const newRow = data[id];
    return {
      type: 'added', // If row is null, it means that it was removed, otherwise it was added
      reference: id,
      data: newRow,
      complete: true,
    };
  }) as RowDiff[];

  return [
    [...removedIds, ...newIds],
    [...removedRows, ...newRows],
  ];
};

const detectModification = (
  data: IdentifiableRowMapper,
  reference: IdentifiableRowMapper,
  blacklist: number[]
): RowDiff[] => {
  // Get ids of rows that were modified from the reference
  const modifiedCandidatesIds = getRowsId(Object.values(reference)).filter(
    id => !blacklist.includes(id)
  );

  const dataTableByReference = insertInTableByReference(Object.values(data));

  // Using hashing detect if exists any change in the row

  const modifiedIds = modifiedCandidatesIds.filter(id => {
    const oldRow = reference[id];
    const newRow = dataTableByReference[id];

    const oldHash = hashRow(oldRow);
    const newHash = hashRow(newRow);

    return oldHash !== newHash;
  });

  const modifiedRows = modifiedIds.map(id => {
    const oldRow = reference[id];
    const newRow = dataTableByReference[id];

    const excludedKeys = ['id', 'referencia', 'ordem'];

    const changes = Object.keys(oldRow).reduce((acc, key) => {
      if (excludedKeys.includes(key)) return acc;

      if (!_.isEqual(oldRow[key], newRow[key])) {
        acc[key] = {
          from: oldRow[key],
          to: newRow[key],
        };
      }
      return acc;
    }, {} as { [key: string]: { from: any; to: any } });
    return {
      type: 'modified',
      reference: id,
      changes,
      complete: false,
    };
  }) as RowDiff[];

  return modifiedRows;
};

const useDiff = (collection: string): UseDiffReturn => {
  const form = useForm();
  const dataCollection = form.getCurrentCollection(collection);
  const lastCollection = form.getLastCollection(collection);

  const previousDataRef = useRef<IdentifiableRow[]>([]);

  const data: IdentifiableRow[] = useMemo(() => {
    const newData = dataCollection[collection];
    if (!_.isEqual(newData, previousDataRef.current)) {
      previousDataRef.current = _.cloneDeep(newData);
    }
    return previousDataRef.current;
  }, [dataCollection, collection]);

  const reference: IdentifiableRow[] = useMemo(
    () => lastCollection[collection],
    [lastCollection, collection]
  );

  const dataTable = useRef<IdentifiableRowMapper | undefined>(insertInTable(data));
  const referenceTable = useRef<IdentifiableRowMapper | undefined>(
    insertInTable(reference)
  );

  const [diff, setDiff] = useState<RowDiff[]>([]);

  useEffect(() => {
    dataTable.current = insertInTable(data);
  }, [data?.length, data]);

  useEffect(() => {
    referenceTable.current = insertInTable(reference);
  }, [reference, reference?.length]);

  useEffect(() => {
    if (form.hasLastValues()) {
      if (form.showDiff && referenceTable.current && dataTable.current) {
        const [blacklist, insertionDeletion] = detectInsertionDeletion(
          dataTable.current,
          referenceTable.current
        );

        const modified = detectModification(
          dataTable.current,
          referenceTable.current,
          blacklist
        );

        const all = [...insertionDeletion, ...modified];
        setDiff(prev => {
          if (!_.isEqual(prev, all)) {
            return all;
          }
          return prev;
        });
      } else {
        setDiff([]);
      }
    }
  }, [collection, data, form, form.showDiff, reference, data]);

  const getDiff = (id: number) => {
    return diff.find(row => row.reference === id);
  };

  const getRespectiveRow = useCallback(
    (ordem: number) => {
      const removedRows = diff.filter(row => row.type === 'removed');

      return removedRows.find(row => row.data.ordem === ordem);
    },
    [diff]
  );

  const getRespectiveRowByFonteRecurso = useCallback(
    (fonteRecurso: number, fonteRecursoStatus: number) => {
      const removedRows = diff.filter(row => row.type === 'removed');

      return removedRows
        .filter(row => {
          const rowFonteRecurso = row.data.fonteRecurso;
          const rowFonteRecursoStatus = row.data.fonteRecursoStatus;

          const matchFonteRecurso = rowFonteRecurso === fonteRecurso;
          const matchFonteRecursoStatus = rowFonteRecursoStatus === fonteRecursoStatus;

          if (matchFonteRecurso && matchFonteRecursoStatus) {
            return row;
          }

          return null;
        })
        .filter(Boolean);
    },
    [diff]
  );

  const getAloneRows = useCallback(() => {
    const existentOrders = getRowsOrdem(Object.values(dataTable.current || {}));
    const removedRows = diff.filter(row => row.type === 'removed');
    return removedRows.filter(row => !existentOrders.includes(row.data.ordem));
  }, [diff]);

  const removedRows = useMemo(() => {
    return diff.filter(row => row.type === 'removed');
  }, [diff]);

  return {
    diff,
    getDiff,
    removedRows,
    getRespectiveRow,
    getAloneRows,
    getRespectiveRowByFonteRecurso,
  };
};

export default useDiff;
