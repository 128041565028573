import { useCallback, useEffect, useRef } from 'react';

import { useRequest } from 'apis';
import { useTranslation } from 'i18n';
import { call, toString } from 'utils';
import { useForm, useFormFieldObserver } from 'components/form';
import { Availability } from './types';
import { getPath, Profile } from './profile';

type Response = {
  status?: 'available' | 'invalid' | 'pending' | 'registered';
  id?: number;
  initials?: string;
  value?: string;
};

type State = {
  availability: Availability;
  profile?: Profile;
};

const useAvailability = (
  fieldName: string,
  rule: 'cpf' | 'cnpj',
  state: State,
  dispatch: React.Dispatch<{ type: 'AVAILABILITY'; payload: Availability }>,
  url?: string
) => {
  const result = useFormFieldObserver(fieldName);
  const request = useRequest();
  const form = useForm();

  const { t } = useTranslation('register');

  const availability = useRef(state.availability);
  availability.current = state.availability;

  if (!url) {
    if (!state.profile) {
      throw new Error('url or state.profile required');
    }

    url = `/cadastro/${getPath(state.profile)}/availability`;
  }

  const onSuccess = useCallback(
    ({ status, id, initials, value }: Response) => {
      switch (status) {
        case 'available':
          break;

        case 'invalid':
          form.addRule(fieldName, 'rejected', { value, rule });
          break;

        default:
          form.addRule(fieldName, 'rejected', {
            value,
            message: t(`validation.availability.${status}`, {
              field: rule.toUpperCase(),
            }),
          });

          break;
      }

      dispatch({
        type: 'AVAILABILITY',
        payload: {
          status: status === 'available' ? status : 'unavailable',
          value,
          person: id && initials ? { id, initials } : undefined,
        },
      });
    },
    [dispatch, fieldName, form, rule, t]
  );

  useEffect(() => {
    const value = toString(result.value);

    if (value === availability.current.value) {
      return;
    }

    if (result.valid && value) {
      call(result.data?.setLoading, true);
      dispatch({ type: 'AVAILABILITY', payload: { status: 'checking', value } });

      const source = request<Response>({
        url,
        method: 'post',
        data: { id: value },
        onSuccess,
        onError: () =>
          dispatch({ type: 'AVAILABILITY', payload: { status: 'error', value } }),
        onComplete: () => call(result.data?.setLoading, false),
      });

      return () => source.cancel();
    } else {
      dispatch({ type: 'AVAILABILITY', payload: { status: 'unavailable', value } });
    }
  }, [dispatch, onSuccess, request, result, url]);
};

export default useAvailability;
