import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import sippi from 'apis/sippi';
import store from 'store';
import { logout } from 'store/auth';
import App from './App';
import { Loader } from 'components/elements';
import MessagesProvider from 'components/containers/MessagesProvider/MessagesProvider';
import { messageService } from 'services';

sippi.interceptors.response.use(
  config => config,
  err => {
    const res = err.response;

    if (res?.status === 401 || res?.status === 403) {
      store.dispatch(logout());
      if (res.status === 403) {
        messageService.error('Você não tem permissão para utilizar este recurso', {
          duration: 5000,
        });
      }
    }

    return Promise.reject(err);
  }
);

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Loader type="bubbles" />}>
      <App />
      <MessagesProvider />
    </Suspense>
  </Provider>,
  document.getElementById('root')
);
