import React, { useEffect } from 'react';

import { XRect, Check, WarningAlt } from 'components/icons';

import { classes } from 'utils/components';
import { messageService } from 'services';

import styles from './NotificationMessage.module.scss';
import { Message } from 'store/message';
import { call } from 'utils';

type Props = {
  message: Message;
};

const NotificationMessage: React.FC<Props> = React.memo(({ message }) => {
  const { duration, id, type, hidden, text, position, onTimeout, onClose } = message;

  useEffect(() => {
    if (duration !== undefined) {
      const timerHide = window.setTimeout(() => {
        messageService.hide(id);
        call(onTimeout);
      }, duration);
      const timerRemove = window.setTimeout(() => {
        messageService.remove(id);
        call(onClose);
      }, duration + 250);

      return () => {
        window.clearTimeout(timerHide);
        window.clearTimeout(timerRemove);
      };
    }
  }, [duration, id, onTimeout, onClose]);

  const renderIcon = (type: string) => {
    if (type === 'error') {
      return (
        <span className={styles.errorIcon}>
          <XRect />
        </span>
      );
    } else if (type === 'warning') {
      return (
        <span className={styles.warnIcon}>
          <WarningAlt />
        </span>
      );
    } else if (type === 'success') {
      return (
        <span className={styles.successIcon}>
          <Check />
        </span>
      );
    }
  };

  const classColor =
    type === 'warning'
      ? styles.warningColor
      : type === 'success'
      ? styles.successColor
      : styles.dangerColor;

  return (
    <div
      className={classes(
        styles.notificationMessage,
        classColor,
        hidden ? styles.hide : null,
        position === 'top' ? styles.top : styles.bottom
      )}
      style={{
        ['--in-translate' as any]: position === 'top' ? '-150%' : '15%',
        ['--out-translate' as any]: position === 'top' ? '-200%' : '200%',
      }}
    >
      {type !== undefined ? renderIcon(type) : null}
      <span className={styles.content}>{text}</span>
    </div>
  );
});

export default NotificationMessage;
