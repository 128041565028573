import React, { useCallback, useEffect, useState } from 'react';

import { useTranslationX, useTranslation } from 'i18n';
import {
  Form,
  TextField,
  Select,
  SubmitButton,
  TableSelect,
  RadioGroup,
  TextArea,
} from 'components/form';
import {
  Grid,
  InfoDialog,
  Modal,
  ModalPDFViewer,
  Row,
  ScrollPanel,
  TableData,
} from 'components/containers';
import { Button, Loader, WrapperCard } from 'components/elements';
import {
  FieldObserverResult,
  FieldOption,
  FormInstance,
  FormObserverResult,
} from 'components/form/types';
import { ColumnConfig } from 'components/containers/TableList/types';
import { transparencyPortal, useFetch, useRequest } from 'apis';
import { CHAVE_API_TRANSPARENCIA } from 'consts';
import { useHistory, useParams } from 'react-router';

import styles from './CounterpartForm.module.scss';
import { createArrayFrom1ToN } from 'utils';
type ModalProps = {
  title?: string;
  label?: string;
  placeholder?: string;
  onPopulateData: any;
};

type TransparencyPortal = {
  remuneracoesDTO: Remuneracao[];
  servidor: object;
};

type Remuneracao = {
  remuneracaoBasicaBruta: string;
  impostoRetidoNaFonte: string;
};

type Cpf = {
  cpf?: string;
};

type ProjetoData = {
  duracao: number;
  anoInicioExecucao: number;
  mesInicioExecucao: number;
};

const CounterpartForm: React.FC = () => {
  const { tx } = useTranslationX('create', 'project');
  const { tx: tx2 } = useTranslationX('request', 'project');

  const { t } = useTranslation('project');
  const { id } = useParams<{ id: string; contrapartidaId: string }>();
  const { tx: tx3 } = useTranslationX('components.month_year_picker');

  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [pdfData, setPdfData] = useState<string | ArrayBuffer | null>(null);

  const request = useRequest();
  const history = useHistory();

  const { data: projetoData } = useFetch<ProjetoData>(`/projetos/${id}/projeto`);

  const downloadDeclaracao = (id: number, route = '/pdf') => {
    request<any>({
      url: `/projeto/${id}/contrapartidas/${id}${route}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
      onSuccess: (blob: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          let base64data = reader.result;
          setPdfData(base64data);
        };
      },
      onError: data => console.error(data),
    });
  };

  const form = React.createRef<FormInstance>();

  const transparencyRequest = useRequest(transparencyPortal);

  const changeRemunerationValue = useCallback(
    (data: TransparencyPortal[]) => {
      const response = data[0].remuneracoesDTO[0];
      const remuneracao =
        parseFloat(response.remuneracaoBasicaBruta.replace(',', '')) * 1000;
      const currentForm = form.current?.context;
      const cargaHorariaProjeto = currentForm?.getFieldValue('cargaHoraria');
      const cargaHorariaServidorStatus =
        currentForm?.getFieldValue('cargaHorariaServidor');

      if (cargaHorariaProjeto && cargaHorariaServidorStatus !== undefined) {
        const cargaHorariaServidorValue = cargaHorariaServidorStatus ? 40 : 20;
        const cargaHorariaProjetoValue =
          parseInt(cargaHorariaProjeto) <= 80 ? parseInt(cargaHorariaProjeto) : 0;

        const remuneracaoProjeto =
          (remuneracao / cargaHorariaServidorValue) * cargaHorariaProjetoValue;
        currentForm?.setFieldValue('currency', remuneracaoProjeto);
      }
    },
    [form]
  );

  const getRemunerationInfo = useCallback(
    (cpf?: Cpf) => {
      const date = new Date();
      const searchYear = date.getFullYear();
      const searchMonth = date.getMonth() - 3;
      transparencyRequest<TransparencyPortal[]>({
        url: `/api-de-dados/servidores/remuneracao?cpf=${cpf}&mesAno=${searchYear}0${searchMonth}`,
        headers: {
          'chave-api-dados': CHAVE_API_TRANSPARENCIA,
        },

        onSuccess: changeRemunerationValue,
      });
    },
    [transparencyRequest, changeRemunerationValue]
  );

  useEffect(() => {
    if (form) {
      const currentForm = form?.current;

      const cargaHoraria = (result: FormObserverResult) => {
        result = result as FieldObserverResult;
        if (result.data?.value) {
          const userCpf = currentForm?.context
            ?.getFieldValue('cpf')
            ?.replaceAll('.', '')
            .replaceAll('-', '');
          getRemunerationInfo(userCpf);
        }
      };

      currentForm?.observer.subscribe(cargaHoraria, { field: 'cargaHoraria' });

      const cargaHorariaServidor = (result: FormObserverResult) => {
        result = result as FieldObserverResult;

        if (result?.data) {
          const userCpf = currentForm?.context
            ?.getFieldValue('cpf')
            ?.replaceAll('.', '')
            .replaceAll('-', '');
          getRemunerationInfo(userCpf);
        }
      };

      currentForm?.observer.subscribe(cargaHorariaServidor, {
        field: 'cargaHorariaServidor',
      });

      const cpf = (result: FormObserverResult) => {
        result = result as FieldObserverResult;
        if (result.data?.value) {
          const userCpf = result.data.value.replaceAll('.', '').replaceAll('-', '');

          getRemunerationInfo(userCpf);
        }
      };

      currentForm?.observer.subscribe(cpf, { field: 'cpf' });

      return () => {
        currentForm?.observer.unsubscribe(cargaHoraria);
        currentForm?.observer.unsubscribe(cargaHorariaServidor);
        currentForm?.observer.unsubscribe(cpf);
      };
    }
  }, [form, transparencyRequest, getRemunerationInfo]);

  const onPopulateData = (data: any) => {
    if (data) {
      form.current?.context.setFieldValue('name', data.nome);
      form.current?.context.setFieldValue('cpf', data.cpf);
    }
  };

  if (!projetoData) {
    return <Loader size={60} />;
  }

  const renderDuration = (relativeMonth: number) => {
    const currentYear = projetoData.anoInicioExecucao;

    const month = projetoData.mesInicioExecucao - 1 + relativeMonth;

    const currentMonth = month > 12 ? month % 12 : month;
    const year = currentYear + Math.floor((month - 1) / 12);

    return `${relativeMonth} - ${tx3(`months.${currentMonth}`).toLowerCase()}/${year}`;
  };

  return (
    <ScrollPanel>
      <div className={styles.rolesList}>
        <h1 className={styles.header}>{tx('newContract')}</h1>
        <WrapperCard minWidth="55vw">
          <div className={styles.scrollContent}>
            <Form
              ref={form}
              baseUrl={`projeto/${id}/contrapartidas`}
              onDone={({ responseBody }) => downloadDeclaracao(responseBody.id)}
            >
              <Grid maxWidth="250px">
                <Row>
                  <CounterPartModal
                    onPopulateData={onPopulateData}
                    title={t('request.form.person.title')}
                    label={tx('selectPerson')}
                    placeholder={tx('select')}
                  />
                </Row>
              </Grid>
              <Grid maxWidth="900px">
                <Row width={[4, 3, 2, 1]}>
                  <TextField
                    label={tx('name')}
                    name="name"
                    type="readonly"
                    placeholder={tx('professionalName')}
                  />
                </Row>
              </Grid>
              <Grid>
                <Row>
                  <h3 className={styles.formPeriod}>{tx('atuationPeriod')}</h3>
                </Row>
              </Grid>
              <Grid maxWidth="680px">
                <Row width={[5, 5, 2]}>
                  <Select
                    name="mesInicio"
                    label={tx('contractStartMonth')}
                    metadata={{
                      options: createArrayFrom1ToN(projetoData.duracao).map(value => {
                        return { text: renderDuration(value), value: value };
                      }),
                    }}
                  />
                  <Select
                    name="mesFim"
                    label={tx('contractEndMonth')}
                    metadata={{
                      options: createArrayFrom1ToN(projetoData.duracao).map(
                        (value: number) => {
                          return {
                            text: renderDuration(value),
                            value: value,
                          };
                        }
                      ),
                    }}
                  />

                  <TextField
                    label={tx('workload')}
                    name="chMes"
                    placeholder="00"
                    adornment={{ right: tx('hours') }}
                    metadata={{
                      rules: {
                        mask: {
                          pattern: '0##',
                        },
                      },
                    }}
                  />
                </Row>
              </Grid>
              <Grid>
                <Row>
                  <h3 className={styles.formPeriod}>{tx('vacationPeriod')}</h3>
                </Row>
              </Grid>

              <TableData
                name="ferias"
                textSize="small"
                addLabel={tx('vacationPeriod')}
                style={{ table: { maxWidth: 600 } }}
                config={[
                  {
                    title: tx('contractStartDate'),
                    field: 'dataInicio',
                    type: 'date',
                    align: 'left',
                  },
                  {
                    title: tx('contractEndDate'),
                    field: 'dataFim',
                    type: 'date',
                    align: 'left',
                  },
                ]}
              />

              <Grid maxWidth="500px">
                <Row width={[0.3, 0.5]} align="baseline">
                  <RadioGroup
                    label={tx('contractType')}
                    name="regime"
                    columns={2}
                    parseOption={(value: FieldOption) => tx(`${value.text}`)}
                    metadata={{
                      rules: {
                        required: true,
                      },
                    }}
                  />

                  <TextField
                    label={tx('remuneration')}
                    name="remuneracaoBruta"
                    adornment={{ left: tx('currency') }}
                  />
                </Row>
              </Grid>

              <Grid maxWidth="500px">
                <Row width={[0.3, 0.5]} align="baseline">
                  <RadioGroup
                    label={tx('tipoServidor')}
                    name="tipoServidor"
                    columns={2}
                    parseOption={(value: FieldOption) => tx(`${value.text}`)}
                    metadata={{
                      options: ['TAE', 'DOC'].map(value => {
                        return { text: value, value };
                      }),
                      rules: {
                        required: true,
                      },
                    }}
                  />
                </Row>
              </Grid>

              <Grid maxWidth="600px" className={styles.teste}>
                <Row>
                  <TextArea label={tx('activities')} minRows={5} name="atividades" />
                </Row>
              </Grid>

              <div className={styles.formButtons}>
                <Button
                  onClick={() => history.goBack()}
                  type="secondary"
                  style={{ root: { width: 224 } }}
                >
                  {tx('cancel')}
                </Button>
                <SubmitButton>{tx('generateContract')}</SubmitButton>
              </div>
            </Form>
          </div>
        </WrapperCard>
        <Modal
          visible={pdfData !== null}
          width="auto"
          onBlur={() => setShowInfoDialog(true)}
          onClose={() => setShowInfoDialog(true)}
        >
          <div className={styles.popup}>
            <ModalPDFViewer pdfData={pdfData} />
          </div>
        </Modal>
        <InfoDialog
          visible={showInfoDialog}
          onClose={() => {
            setShowInfoDialog(false);
            history.goBack();
          }}
          title={tx2('list.counterpartWarning')}
          width={550}
          buttonLabel={tx2('list.ok')}
          volatile
        />
      </div>
    </ScrollPanel>
  );
};

export const CounterPartModal: React.FC<ModalProps> = props => {
  const { title, label, placeholder } = props;
  const { id } = useParams<{ id: string }>();

  const { tx } = useTranslationX('detail.personal_data', 'people');

  const config: ColumnConfig = [
    {
      title: tx('nome'),
      field: 'nome',
      weight: 1.2,
      align: 'left',
      search: true,
      observerField: 'select.nome',
    },
    {
      title: tx('perfil'),
      field: 'perfil',
      align: 'center',
      weight: 0.4,
    },
    {
      title: tx('titulacao'),
      field: 'titulacao',
      align: 'center',
      weight: 0.4,
    },
  ];

  return (
    <TableSelect
      name="pessoa_id"
      label={label}
      title={title}
      onPopulate={props.onPopulateData}
      placeholder={placeholder}
      metadata={{ rules: { required: true } }}
      formatOption={option => option.nome}
      fetchUrl={`/projeto/${id}/contrapartidas/candidatos`}
      tableConfig={config}
    />
  );
};

export default CounterpartForm;
