import React, { useCallback, useEffect } from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import {
  Counterpart,
  Milestones,
  ProjectDashboard,
  ProjectEditor,
  ProjectListView,
  Summary,
} from 'pages/project';
import { Profile } from 'pages/register/profile';
import RouteData from 'pages/utils/RouteData';
import RequestDetails from 'pages/project/Requests/RequestDetails/RequestDetails';
import { UpdateWpEditor } from 'pages/project/update-wp';
import { resetConfig } from 'store/profile';
import { useAuthState } from 'store/auth';
import { useDispatch } from 'react-redux';

const Projeto = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();
  const { profile } = useAuthState();

  const handleResetSideMenu = useCallback(() => {
    if (profile !== undefined) {
      dispatch(resetConfig(profile));
    }
  }, [dispatch, profile]);

  useEffect(() => {
    return () => {
      handleResetSideMenu();
    };
  }, [handleResetSideMenu]);

  useEffect(() => {
    if (location.pathname === '/dashboard/projetos/resumo') {
      handleResetSideMenu();
    }
  }, [location.pathname, handleResetSideMenu]);

  return (
    <Switch>
      <Route exact path={`${path}/resumo`}>
        <Summary profile={Profile.RESEARCHER} />
      </Route>

      <Route exact path={`${path}/solicitacoes/:id`}>
        <RouteData>
          {props => <RequestDetails {...props} profile={Profile.RESEARCHER} />}
        </RouteData>
      </Route>

      <Route exact path={`${path}/todos`}>
        <ProjectListView />
      </Route>
      <Route path={`${path}/:id/alteracao-pt`}>
        <RouteData>
          {props => <UpdateWpEditor {...props} profile={Profile.RESEARCHER} />}
        </RouteData>
      </Route>
      <Route path={`${path}/:id/aditivo`}>
        <RouteData>
          {props => (
            <UpdateWpEditor {...props} profile={Profile.RESEARCHER} type="aditivo" />
          )}
        </RouteData>
      </Route>

      <Route path={`${path}/:id/macroentregas`}>
        <RouteData>
          {props => <Milestones {...props} profile={Profile.RESEARCHER} />}
        </RouteData>
      </Route>

      <Route path={`${path}/:id/contrapartida`}>
        <RouteData>
          {props => <Counterpart {...props} profile={Profile.RESEARCHER} />}
        </RouteData>
      </Route>

      <Route exact path={`${path}/:id`}>
        <RouteData>{props => <ProjectDashboard {...props} />}</RouteData>
      </Route>

      <Route path={`${path}/:id`}>
        <RouteData>
          {props => <ProjectEditor {...props} profile={Profile.RESEARCHER} />}
        </RouteData>
      </Route>
    </Switch>
  );
};

export default Projeto;
