import { useEffect, useRef, useState } from 'react';
import { call } from 'utils';

const useTrigger = <T>(callback?: (...args: any[]) => any) => {
  const [args, setArgs] = useState<T>();
  const lastArgs = useRef<T>();

  useEffect(() => {
    if (args && args !== lastArgs.current) {
      lastArgs.current = args;
      call(callback, args);
    }
  }, [args, callback]);

  return setArgs;
};

export default useTrigger;
