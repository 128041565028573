import React from 'react';

const PlusCircle = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 15 15" aria-hidden="true" focusable="false">
    <path
      fill="currentColor"
      d="M12.4498 12.6165C13.4287 11.6375 14.0954 10.3902 14.3655 9.03238C14.6356 7.67452 14.497 6.26705 13.9672 4.98797C13.4374 3.70888 12.5402 2.61563 11.389 1.84646C10.2379 1.07729 8.88449 0.666751 7.50002 0.666752C6.11555 0.666751 4.76217 1.07729 3.61103 1.84646C2.45989 2.61563 1.56268 3.70888 1.03286 4.98797C0.503051 6.26705 0.364428 7.67452 0.634524 9.03238C0.90462 10.3902 1.57131 11.6375 2.55027 12.6165C3.86303 13.9293 5.6435 14.6667 7.50002 14.6667C9.35654 14.6667 11.137 13.9293 12.4498 12.6165ZM3.9002 8.30572C3.73074 8.30572 3.56822 8.2384 3.44839 8.11857C3.32856 7.99874 3.26124 7.83621 3.26124 7.66675C3.26124 7.49729 3.32856 7.33476 3.44839 7.21493C3.56822 7.0951 3.73074 7.02778 3.9002 7.02778L6.86555 7.03228L6.86555 4.07143C6.87792 3.91163 6.95012 3.76239 7.06775 3.65351C7.18537 3.54464 7.33974 3.48416 7.50002 3.48416C7.6603 3.48416 7.81467 3.54464 7.93229 3.65351C8.04992 3.76239 8.12212 3.91163 8.13449 4.07143L8.13449 7.03228L11.0953 7.03228C11.1828 7.02552 11.2707 7.0369 11.3535 7.06572C11.4363 7.09453 11.5123 7.14016 11.5767 7.19974C11.6411 7.25932 11.6924 7.33156 11.7275 7.41192C11.7627 7.49229 11.7808 7.57904 11.7808 7.66675C11.7808 7.75445 11.7627 7.84121 11.7275 7.92158C11.6924 8.00194 11.6411 8.07418 11.5767 8.13376C11.5123 8.19334 11.4363 8.23897 11.3535 8.26778C11.2707 8.2966 11.1828 8.30798 11.0953 8.30122H8.13449L8.13899 11.2666C8.12662 11.4264 8.05442 11.5756 7.93679 11.6845C7.81917 11.7934 7.6648 11.8538 7.50452 11.8538C7.34424 11.8538 7.18987 11.7934 7.07225 11.6845C6.95462 11.5756 6.88241 11.4264 6.87005 11.2666L6.86555 8.30122L3.9002 8.30572Z"
    />
  </svg>
);

export default PlusCircle;
