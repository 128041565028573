import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { PercentCardWithIcon } from 'components/elements';
import { Domain, GraduationCap } from 'components/icons';

import { useTranslation } from 'i18n';

import styles from './CredentialsForm.module.scss';

const CredentialsForm = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={styles.loginForm}>
      <div className={styles.cards}>
        <div
          className={styles.cardItem}
          onClick={() => history.push('/cadastro', { isIFCE: true })}
        >
          <PercentCardWithIcon label={t('login.servidor')} icon={GraduationCap} />
        </div>
        <div
          className={styles.cardItem}
          onClick={() => history.push('/cadastro', { isIFCE: false })}
        >
          <PercentCardWithIcon label={t('login.notServidor')} icon={Domain} />
        </div>
      </div>
      <div className={styles.link}>
        <span>{t('login.link.title3')}</span>

        <Link
          to={{
            pathname: '/login',
            state: { getCredentials: false, resetPassword: false },
          }}
        >
          <span>{t('login.link.title4')}</span>
        </Link>
      </div>
    </div>
  );
};

export default CredentialsForm;
