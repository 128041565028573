import React from 'react';

const Bell = ({ size = 1.2 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none">
    <path
      d="M18 16V11C18 7.93 16.36 5.36 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.63 5.36 6 7.92 6 11V16L4 18V19H20V18L18 16ZM13 16H11V14H13V16ZM13 12H11V8H13V12ZM12 22C13.1 22 14 21.1 14 20H10C10 21.1 10.89 22 12 22Z"
      fill="currentColor"
    />
  </svg>
);

export default Bell;
