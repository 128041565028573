import React from 'react';

import { useTranslationX } from 'i18n';
import { Grid, Row } from 'components/containers';
import { PlainField } from 'components/elements';

type Data = {
  titulo: string;
  tipo: string;
  estado: string;
  fonte: string;
  gpf: string;
  valor: number;
  projetoTitulo: string;
  projetoSigla: string;
  projetoCoordenador: string;
  valorSolicitado: number;
  justificativa: string;
  aditivo: boolean;
  versaoAnterior: number;
  projetoId: number;
};

type Solicitation = {
  id: number;
  type: string;
};

const AlterationPT = ({ data }: { data: Data; solicitation: Solicitation }) => {
  const { tx } = useTranslationX('request', 'project');
  const {
    projetoTitulo,
    projetoSigla,
    projetoCoordenador,
    justificativa,
    versaoAnterior,
  } = data;

  return (
    <Grid spacing={0}>
      <Row width={[6, 4]} align="top">
        <PlainField label={tx('form.projectTitle')} value={projetoTitulo} />
        <PlainField label={tx('form.projectCoordinator')} value={projetoCoordenador} />
      </Row>
      <Row width={[6, 4]} align="top">
        <PlainField label={tx('form.projectInitials')} value={projetoSigla} />
        <PlainField label={tx('form.versaoAnterior')} value={String(versaoAnterior)} />
      </Row>
      <Row align="top">
        <PlainField label={tx(`form.justificativa.label`)} value={justificativa} />
      </Row>
    </Grid>
  );
};

export default AlterationPT;
