import React, { useEffect, useState } from 'react';

import { Switch } from 'components/elements';
import useField from '../Field/useField';
import { FieldType } from '../types';

type Props = {
  name: string;
  label?: string;
  type?: FieldType;
  value?: boolean;
};

const SwitchField: React.FC<Props> = props => {
  const { name, type, value, form, section, refreshType } = useField(props);
  const { label } = props;

  const [selected, setSelected] = useState(!!value || false);

  useEffect(() => {
    form.updateFieldData(name, {
      value: selected,
      valid: true,
      section,
      setValue: value => setSelected(value || false),
      refreshType,
      disabled: type === 'disabled',
    });
  }, [form, name, refreshType, section, selected, type]);

  return (
    <Switch
      on={selected}
      label={label}
      onChange={setSelected}
      type={type === 'disabled' ? type : undefined}
    />
  );
};

export default SwitchField;
