import React, { CSSProperties, ReactNode } from 'react';

import { classes } from 'utils/components';
import styles from './HStack.module.scss';

type Props = {
  align?: 'left' | 'center' | 'right';
  spacing?: number;
  children?: ReactNode;
};

const HStack: React.FC<Props> = ({ align = 'left', spacing, children }) => {
  const style: CSSProperties = {};

  if (spacing) {
    style.padding = spacing;
  }

  return (
    <div className={classes(styles.hstack, styles[align])} style={style}>
      {children}
    </div>
  );
};

export default HStack;
