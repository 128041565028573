import React, { ReactElement, useState } from 'react';
import Tippy from '@tippyjs/react';
import { Placement } from '@popperjs/core';

type Props = {
  actions: ReactElement;
  placement?: Placement;
  children?: ReactElement;
  offset?: [number, number];
  visible?: boolean;
  reference?: Element | React.RefObject<Element> | null;
  triggerTarget?: Element | Element[] | null;
  delay?: number | [number, number];
};

const Controls = (props: Props) => {
  const {
    actions,
    placement = 'right-start',
    offset,
    visible,
    children,
    reference,
    triggerTarget,
    delay,
  } = props;

  const [showControls, setShowControls] = useState(false);

  const renderControls = (attrs: object) => {
    if (!showControls) {
      return null;
    }

    return (
      <div {...attrs} onClick={e => e.stopPropagation()}>
        {actions}
      </div>
    );
  };

  return visible ? (
    <Tippy
      offset={offset}
      interactive={true}
      render={renderControls}
      reference={reference}
      triggerTarget={triggerTarget}
      delay={delay}
      animation={false}
      maxWidth="none"
      placement={placement}
      appendTo="parent"
      hideOnClick={false}
      onShow={() => setShowControls(true)}
      onHide={() => setShowControls(false)}
      popperOptions={{
        modifiers: [
          {
            name: 'preventOverflow',
            options: { mainAxis: false },
          },
        ],
      }}
    >
      {children}
    </Tippy>
  ) : (
    children || null
  );
};

export default Controls;
