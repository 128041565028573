import React from 'react';

const Menu = ({ size = 1 }: { size?: number }) => {
  return (
    <svg
      width={`${size - 0.2}em`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_11808_8071"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="5"
        width="18"
        height="14"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 9H6.5C7.05 9 7.5 8.55 7.5 8V6C7.5 5.45 7.05 5 6.5 5H4.5C3.95 5 3.5 5.45 3.5 6V8C3.5 8.55 3.95 9 4.5 9ZM6.5 14H4.5C3.95 14 3.5 13.55 3.5 13V11C3.5 10.45 3.95 10 4.5 10H6.5C7.05 10 7.5 10.45 7.5 11V13C7.5 13.55 7.05 14 6.5 14ZM6.5 19H4.5C3.95 19 3.5 18.55 3.5 18V16C3.5 15.45 3.95 15 4.5 15H6.5C7.05 15 7.5 15.45 7.5 16V18C7.5 18.55 7.05 19 6.5 19ZM19.5 14H9.5C8.95 14 8.5 13.55 8.5 13V11C8.5 10.45 8.95 10 9.5 10H19.5C20.05 10 20.5 10.45 20.5 11V13C20.5 13.55 20.05 14 19.5 14ZM9.5 19H19.5C20.05 19 20.5 18.55 20.5 18V16C20.5 15.45 20.05 15 19.5 15H9.5C8.95 15 8.5 15.45 8.5 16V18C8.5 18.55 8.95 19 9.5 19ZM8.5 8V6C8.5 5.45 8.95 5 9.5 5H19.5C20.05 5 20.5 5.45 20.5 6V8C20.5 8.55 20.05 9 19.5 9H9.5C8.95 9 8.5 8.55 8.5 8Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0_11808_8071)">
        <rect width="24" height="24" fill="#858C94" />
      </g>
    </svg>
  );
};

export default Menu;
