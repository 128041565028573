import React from 'react';

const InternalHardware = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16.125 6.75C16.7463 6.75 17.25 6.24632 17.25 5.625C17.25 5.00368 16.7463 4.5 16.125 4.5C15.5037 4.5 15 5.00368 15 5.625C15 6.24632 15.5037 6.75 16.125 6.75Z'
      fill='currentColor' />
    <path
      d='M10.8105 15H6.75V10.9395L11.2725 6.417C11.2578 6.27849 11.2503 6.1393 11.25 6C11.25 5.10999 11.5139 4.23996 12.0084 3.49994C12.5029 2.75991 13.2057 2.18314 14.0279 1.84254C14.8502 1.50195 15.755 1.41283 16.6279 1.58647C17.5008 1.7601 18.3026 2.18869 18.932 2.81802C19.5613 3.44736 19.9899 4.24918 20.1635 5.1221C20.3372 5.99501 20.2481 6.89981 19.9075 7.72208C19.5669 8.54434 18.9901 9.24715 18.2501 9.74162C17.51 10.2361 16.64 10.5 15.75 10.5C15.6107 10.4997 15.4715 10.4922 15.333 10.4775L10.8105 15ZM8.25 13.5H10.1895L14.817 8.8725L15.2055 8.94375C15.3849 8.97933 15.5672 8.99816 15.75 9C16.3773 9.00772 16.9916 8.82102 17.5085 8.46556C18.0254 8.11009 18.4196 7.6033 18.6369 7.01479C18.8541 6.42628 18.8839 5.78493 18.722 5.17884C18.5601 4.57276 18.2145 4.03166 17.7327 3.62989C17.2509 3.22812 16.6565 2.98539 16.0312 2.93503C15.4059 2.88468 14.7803 3.02917 14.2404 3.34866C13.7005 3.66816 13.2728 4.14698 13.016 4.71935C12.7592 5.29172 12.6859 5.92956 12.8063 6.54525L12.8775 6.933L8.25 11.5605V13.5Z'
      fill='currentColor' />
    <path
      d='M21 15H14.25V16.5H21V21H3V16.5H4.5V15H3C2.6023 15.0004 2.221 15.1586 1.93978 15.4398C1.65856 15.721 1.5004 16.1023 1.5 16.5V21C1.5004 21.3977 1.65856 21.779 1.93978 22.0602C2.221 22.3414 2.6023 22.4996 3 22.5H21C21.3977 22.4996 21.779 22.3414 22.0602 22.0602C22.3414 21.779 22.4996 21.3977 22.5 21V16.5C22.4996 16.1023 22.3414 15.721 22.0602 15.4398C21.779 15.1586 21.3977 15.0004 21 15Z'
      fill='currentColor' />
    <path
      d='M5.25 19.5C5.66421 19.5 6 19.1642 6 18.75C6 18.3358 5.66421 18 5.25 18C4.83579 18 4.5 18.3358 4.5 18.75C4.5 19.1642 4.83579 19.5 5.25 19.5Z'
      fill='currentColor' />

    <g mask='url(#mask0)'>
      <rect width={`${size}em`} fill='currentColor' />
    </g>
  </svg>
);

export default InternalHardware;





