import React, { useState, useRef, useEffect } from 'react';
import { BsFileEarmark as FileIcon, BsXCircleFill as RemoveIcon } from 'react-icons/bs';

import { IconButton } from '..';
import { splitName } from 'utils/file';

import styles from './File.module.scss';
import { classes } from 'utils/components';

type Props = {
  file: File;
  onRemove: () => void;
  hasFilesUploaded?: boolean;
};

type FilenameInfo = { name: string[]; ext?: string };

const File: React.FC<Props> = ({ file, onRemove, hasFilesUploaded = false }) => {
  const [info, setInfo] = useState<FilenameInfo>({ name: [] });

  const ref = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (labelRef.current) {
      setInfo(getInfo(file.name, labelRef.current));
    }
  }, [file]);

  return (
    <div className={styles.file} ref={ref}>
      <div>
        <div className={styles.badge}>
          <FileIcon />
          <span>{info.ext}</span>
          <i>
            <IconButton
              icon={RemoveIcon}
              size={30}
              shape="circle"
              overflow={true}
              type="primary"
              onClick={onRemove}
            />
          </i>
        </div>
        <div
          className={classes(styles.label, hasFilesUploaded ? styles.uploadedFiles : '')}
          title={file.name}
          ref={labelRef}
        >
          {info.name.map((text, index) => (
            <div key={index}>{text.trim()}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

const splitOnChars = ['.', '-', '–', '—', ' '];
const maxLines = 3;

function getInfo(filename: string, el: HTMLDivElement) {
  const { name, ext } = splitName(filename);
  return { name: clamp(name.replace(/\s+/g, ' ').trim(), el), ext };
}

function clamp(text: string, el: HTMLDivElement) {
  const content = el.innerHTML;
  const maxWidth = el.getBoundingClientRect().width;
  const children: HTMLSpanElement[] = [];

  let word = '';

  const newChild = () => {
    if (word) {
      const child = document.createElement('span');
      child.append(word);
      children.push(child);
      el.append(child);
      word = '';
    }
  };

  el.innerHTML = '';

  for (const c of text) {
    if (splitOnChars.includes(c)) {
      newChild();
      word = c;
      newChild();
    } else {
      word += c;
    }
  }

  newChild();

  let line = '';
  let lineWidth = 0;
  const lines: string[] = [];

  const newLine = () => {
    if (line) {
      if (lines.length < maxLines) {
        lines.push(line);
      } else {
        lines[maxLines - 1] += line;
      }

      line = '';
      lineWidth = 0;
    }
  };

  for (const child of children) {
    const w = child.getBoundingClientRect().width;

    if (lineWidth + w > maxWidth) {
      newLine();
    }

    line += child.innerHTML;
    lineWidth += w;
  }

  newLine();

  el.innerHTML = content;
  return lines;
}

export default File;
