import React, { ReactNode } from 'react';

import { AngleDown } from 'components/icons';
import { classes } from 'utils/components';

import { Instance } from 'components/containers/TableList/TableList';
import styles from './TableSortLabel.module.scss';
import { DispatchFilter, StateFilter } from 'components/containers/TableList/types';

type Props = {
  field: string;
  stateFilter: StateFilter;
  dispatchFilter: DispatchFilter;
  instance?: React.MutableRefObject<Instance>;
  initialDirection?: 'asc' | 'desc';
  children?: ReactNode;
};

const TableSortLabel: React.FC<Props> = props => {
  const { field, stateFilter, dispatchFilter, instance, initialDirection, children } =
    props;
  const [fieldName, direction] = stateFilter.sort.split(',');
  return (
    <div
      className={styles.tableSortLabel}
      onClick={() => {
        dispatchFilter({
          type: 'UPDATE_SORT',
          payload:
            fieldName === field
              ? `${field},${direction === 'asc' ? 'desc' : 'asc'}`
              : `${field},${initialDirection || 'asc'}`,
        });
        instance?.current.reset();
      }}
    >
      {children}

      {fieldName === field && (
        <div className={classes(styles.arrow, styles[direction])} key={fieldName}>
          <AngleDown />
        </div>
      )}
    </div>
  );
};

export default TableSortLabel;
