import React from 'react';

const DownloadDone = ({ size = 1 }: { size?: number }) => (
  <svg
    width={`${size}em`}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clip-rule="evenodd"
      d="M8.19 13.6075C8.97 14.3875 10.23 14.3875 11.01 13.6175L18.01 6.6875C18.56 6.1475 18.56 5.2675 18.02 4.7275L17.98 4.6875C17.44 4.1475 16.57 4.1475 16.03 4.6875L9.6 11.1175L7.95 9.4675C7.43 8.9475 6.57 8.9275 6.03 9.4475C5.46 9.9775 5.45 10.8675 6 11.4175L8.19 13.6075ZM18 17.7175H6C5.45 17.7175 5 18.1675 5 18.7175C5 19.2675 5.45 19.7175 6 19.7175H18C18.55 19.7175 19 19.2675 19 18.7175C19 18.1675 18.55 17.7175 18 17.7175Z"
      fill="currentColor"
    />
    <g mask="url(#mask0)">
      <rect width={`${size}em`} fill="#currentColor" />
    </g>
  </svg>
);

export default DownloadDone;
