import React from 'react';

const Circle = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_11401_1938"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="20"
        height="20"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12ZM19.93 11C19.48 7.4 16.61 4.52 13 4.07V10C13 10.55 13.45 11 14 11H19.93ZM4 12C4 7.94 7.07 4.56 11 4.07V19.94C7.07 19.44 4 16.06 4 12ZM13 14V19.93C16.61 19.48 19.48 16.61 19.93 13H14C13.45 13 13 13.45 13 14Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0_11401_1938)">
        <rect width="24" height="24" fill="currentColor" />
      </g>
    </svg>
  );
};

export default Circle;
