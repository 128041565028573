import { useContext } from 'react';
import { FormContext, FieldSetContext, FormSectionContext } from './FormContext';

export const useForm = () => useContext(FormContext);
export const useFieldSet = () => useContext(FieldSetContext);
export const useFormSection = () => useContext(FormSectionContext);

export { default as Counter } from './Counter/Counter';
export { default as Field } from './Field/Field';

export { default as Autocomplete } from './modules/AutocompleteField';
export { default as AutoCompute } from './modules/AutoCompute';
export { default as Checklist } from './modules/Checklist';
export { default as Checkbox } from './modules/Checkbox';
export { default as ColorPicker } from './modules/ColorPicker';
export { default as FileUploader } from './modules/FileUploader';
export { default as HiddenField } from './modules/HiddenField';
export { default as MultiSelect } from './modules/MultiSelect';
export { default as Password } from './modules/Password';
export { default as RadioGroup } from './modules/RadioGroup';
export { default as Select } from './modules/Select';
export { default as TableSelect } from './modules/TableSelect';
export { default as StashButton } from './modules/StashButton';
export { default as SubmitButton } from './modules/SubmitButton';
export { default as Switch } from './modules/Switch';
export { default as TextArea } from './modules/TextArea';
export { default as TextEditor } from './modules/TextEditor';
export { default as TextField } from './modules/TextField';
export { default as ReadOnlyInfoDialog } from './modules/ReadOnlyInfoDialog';
export { default as MonthYearPicker } from './modules/MonthYearPicker';
export { default as DatePicker } from './modules/DatePicker';

export { default as CEP } from './CEP';
export { default as FieldSearch } from './FieldSearch';
export { default as FieldSet } from './FieldSet';
export { default as Form } from './Form';
export { default as EditableConfigForm } from './EditableConfigForm/EditableConfigForm';
export { default as FormSection } from './FormSection';
export { default as HiddenSection } from './HiddenSection/HiddenSection';
export { default as Localidades } from './Localidades';
export { default as useFormObserver } from './useFormObserver';
export { default as useFormFieldObserver } from './useFormFieldObserver';
export { default as useField } from './Field/useField';
export { default as useDiff } from './useDiff';
