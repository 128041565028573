import React, { useEffect } from 'react';
import { useFetch } from 'apis';
import { useTranslationX } from 'i18n';
import { Loader } from 'components/elements';
import { TriangleBars } from 'components/icons';
import { Form, MultiSelect, useFormObserver } from 'components/form';
import { Dispatch } from './types';
import { FieldOption } from 'components/form/types';

import styles from './Filters.module.scss';

type FilterData = {
  type: string;
  granted: boolean;
};

const Filters = ({ dispatch }: { dispatch: Dispatch }) => {
  const { data } = useFetch<FilterData[]>('/homologacao/filtros');

  return (
    <div
      className={styles.filters}
      style={{ alignSelf: data ? 'flex-start' : 'stretch' }}
    >
      <Form>
        {data ? <Content data={data} dispatch={dispatch} /> : <Loader size={44} />}
      </Form>
    </div>
  );
};

const Content = ({ data, dispatch }: { data: FilterData[]; dispatch: Dispatch }) => {
  const { tx: t1 } = useTranslationX('notification');
  const { tx: t2 } = useTranslationX('homologacao', 'supervisor');

  const result = useFormObserver('filters');
  const filters = result?.data?.value;

  useEffect(() => {
    if (Array.isArray(filters)) {
      dispatch({ type: 'FILTER_UPDATE', payload: filters });
    }
  }, [dispatch, filters]);

  return (
    <MultiSelect
      label={t2('filters')}
      name="filters"
      metadata={{
        options: data.map(({ type }) => ({ text: t1(type), value: type })),
      }}
      selectionShortcuts={[
        {
          label: t2('myNotifications'),
          default: true,
          filter: (option: FieldOption) => {
            const { granted } = data.find(({ type }) => type === option.value) || {};
            return !!granted;
          },
        },
      ]}
      displayOptions="dropdown"
      theme="button"
      showBoxes={false}
      fitWidth
      buttonIcon={TriangleBars}
    />
  );
};

export default Filters;
