import React, { useEffect } from 'react';
import { classes } from 'utils/components';

import styles from './SpeedometerProgress.module.scss';

type Props = {
  value: number;
  radius?: number;
  colorGradient?: string[];
  theme?: 'default' | 'white';
};

const SpeedometerProgress = ({
  value,
  radius = 120,
  colorGradient = ['#0E8154', '#3FC75C'],
  theme = 'default',
}: Props) => {
  value = Math.min(Math.max(value, 0), 100);

  const gradientId = `gradient-${Math.random()}`;

  const [percentage, setPercentage] = React.useState(0);

  const circ = Math.PI * (radius * 2);
  const percent = ((100 - percentage / 2) / 100) * circ;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPercentage(percentage + 1 > value ? value : percentage + 1);
    }, 10);
    return () => clearTimeout(timeout);
  }, [percentage, value]);

  return (
    <div className={styles.circularProgress} style={{ height: radius * 0.65 }}>
      <div className={styles.legend}>
        <strong className={classes(value === 0 ? styles.zero : null)}>
          {percentage > 0 ? percentage.toFixed(1).replace('.0', '') : '00'}
        </strong>
        <span>%</span>
      </div>
      <svg
        width="170px"
        height="333px"
        viewBox="0 0 340 333"
        style={{ strokeDasharray: circ }}
      >
        <defs>
          <linearGradient
            id={gradientId}
            x1="24"
            y1="0.5"
            x2="-0.821048"
            y2="9.24826"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorGradient[0]} />
            <stop offset="1" stopColor={colorGradient[1]} />
          </linearGradient>
        </defs>
        <g transform="translate(115, 100)">
          <circle
            r={radius}
            className={theme === 'white' ? styles.bar : ''}
            cx="50"
            cy="0"
            style={{ strokeDashoffset: `${0.5 * circ}px` }}
            shapeRendering="geometricPrecision"
            strokeLinecap="round"
            stroke={theme === 'white' ? '#fff' : '#BDBDBD'}
          />
          <circle
            className={styles.bar}
            r={radius}
            cx="50"
            cy="0"
            style={{ strokeDashoffset: `${percent}px` }}
            stroke={`url(#${gradientId})`}
            strokeLinecap="round"
            shapeRendering="geometricPrecision"
          />
        </g>
      </svg>
    </div>
  );
};

export default SpeedometerProgress;
