import { useCallback } from 'react';

import { useValidation } from './validate';
import { useField } from '.';
import { isValidField } from './helpers';
import { readAttr } from 'utils/object';
import { renderComponent } from 'utils/components';

export type Props = {
  name: string;
  omit?: boolean;
  autosave?: boolean;
};

const withField = (component: React.ElementType, dataType?: string) => {
  return ({ name: n, omit, autosave, ...rest }: Props) => {
    const {
      name,
      type,
      value,
      form,
      section,
      metadata,
      setMetadata,
      refreshType,
      validationToken,
      refreshValidation,
    } = useField({ name: n });

    const { validate } = useValidation();
    const rules = readAttr('rules', metadata);

    const onChange = useCallback(
      (value: any) => {
        const issue = validate(value, rules);

        form.updateFieldData(name, {
          value,
          valid: isValidField(issue),
          issue,
          section,
          omit,
          dataType,
          autosave,
          setMetadata,
          refreshType,
          refreshValidation,
          disabled: type === 'disabled',
        });
      },
      [
        validate,
        rules,
        form,
        name,
        section,
        omit,
        autosave,
        setMetadata,
        refreshType,
        refreshValidation,
        type,
      ]
    );

    return renderComponent(component, {
      ...rest,
      value,
      metadata,
      onChange,
      validationToken,
    });
  };
};

export default withField;
