import React from 'react';

import { useTranslationX } from 'i18n';
import { Tab, Tabs } from 'components/containers';
import { SectionProps } from 'pages/project/update-wp/UpdateWpEditor';
import { Loader, Spacer, WrapperCard } from 'components/elements';

import { ActionButtons, CompositionTable } from '.';
import { TotalLabel } from 'pages/proposal';
import { useFetch } from 'apis';
import { UpdateWPInEdition } from 'pages/project/Sections/Milestones/types';

import styles from './SummaryAndActions.module.scss';

export type ProjectSummary = {
  titulo: string;
  total: number;
  partes: string[];
  composicao: Record<string, unknown>;
  estimativa: Record<string, unknown>;
  cronograma: Record<string, unknown>;
};

const SummaryAndActions = ({ state, dispatch }: SectionProps) => {
  const { tx } = useTranslationX('update_wp:financial', ['translation', 'update_wp']);

  const { id, showErrors, hasErrors } = state;

  const { data } = useFetch<{
    current: ProjectSummary;
    last?: ProjectSummary;
  }>(`/projeto/${id}/alteracao-pt/budget-summary`);

  const { data: edition } = useFetch<UpdateWPInEdition>(`/projetos/${id}/em-edicao`);

  if (!data || !data.current || !edition?.solicitacao) {
    return <Loader />;
  }

  const { titulo, total, partes, composicao, estimativa } = data.current;

  return (
    <div className={styles.section}>
      <ActionButtons
        id={id}
        title={titulo}
        dispatch={dispatch}
        showErrors={showErrors}
        hasErrors={hasErrors}
        state={state}
        solicitacao={edition.solicitacao}
      />
      <WrapperCard padding="5px 15px 25px 5px" minWidth="600px">
        <TotalLabel
          value={total}
          title={tx('composition.total-value')}
          spacing="11px 20px"
        />
        <Tabs selectedIndex={0} cascade="cascade">
          {partes?.map(parte => (
            <Tab key={parte} title={parte}>
              <CompositionTable composicao={composicao[parte]} type="composition" />
              <Spacer length={30} />
              <CompositionTable composicao={estimativa[parte]} type="estimation" />
            </Tab>
          ))}
        </Tabs>
      </WrapperCard>
    </div>
  );
};

export default SummaryAndActions;
