import React from 'react';
import { classes } from 'utils/components';

import styles from './ItemTopSubMenu.module.scss';

type Props = {
  label: string;
  icon: React.FC<{ size: number }>;
  adornment?: JSX.Element;
  selected: boolean;
  blocked: boolean;
  onClick: () => void;
  hasChanges?: boolean;
};

const ItemTopSubMenu: React.FC<Props> = ({
  label,
  icon: IconComponent,
  selected,
  blocked,
  adornment,
  onClick,
  hasChanges,
}) => {
  return (
    <li
      onClick={blocked ? undefined : onClick}
      className={classes(
        styles.item,
        blocked ? styles.blocked : null,
        selected ? styles.selected : null
      )}
    >
      <div className={classes(styles.container, hasChanges ? styles.hasChanges : null)}>
        <div className={styles.icon}>
          <IconComponent size={1.6} />
        </div>
        <span className={styles.label}>{label}</span>
        {adornment && <div className={styles.adornment}>{adornment}</div>}
      </div>
    </li>
  );
};

export default ItemTopSubMenu;
