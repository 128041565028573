import React from 'react';
import { uniqueId } from 'lodash';

import { FileDownloader } from 'components/elements';

import styles from './ArchiveList.module.scss';

type Props = {
  label?: string;
  values: { nome: string; id: number }[];
};

const ArchiveList = ({ label, values }: Props) => {
  return (
    <div className={styles.fileList}>
      {label && <span className={styles.label}>{label}</span>}

      {values &&
        Object.values(values).map(({ id, nome }) => (
          <FileDownloader key={uniqueId()} id={id}>
            <div className={styles.link}>{nome}</div>
          </FileDownloader>
        ))}
    </div>
  );
};

export default ArchiveList;
