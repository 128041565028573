import React, { useEffect, useRef, useState } from 'react';

import { useTranslationX } from 'i18n';
import { useRequest } from 'apis';
import { Form, TextField, useForm, useFormObserver } from 'components/form';
import { SectionProps } from '../ProposalEditor/ProposalEditor';
import { PatchResponse } from 'components/form/types';
import TotalLabel from '../TotalLabel/TotalLabel';

import { WrapperCard } from 'components/elements';
import useDebounce from 'components/hooks/useDebounce';
import { useFirstRender } from 'components/hooks';

import styles from './Counterpart.module.scss';
import { Dispatch } from '../ProposalEditor/types';

type State = {
  cf: number;
  ct: number;
  vse: number;
};

const Counterpart = ({ state: { id }, formProps, dispatch }: SectionProps) => {
  return (
    <div className={styles.wrapper}>
      <Form baseUrl={`/proposta/${id}/counterpart`} {...formProps}>
        <WrapperCard padding="10px 24px">
          <FormContent id={id} dispatch={dispatch} />
        </WrapperCard>
      </Form>
    </div>
  );
};

const FormContent = ({ id, dispatch }: { id: number; dispatch: Dispatch }) => {
  const form = useForm();
  const request = useRequest();
  const firstRender = useFirstRender();

  const [state, setState] = useState<State>({
    cf: form.getFieldValue('valorFinanceiro') || 0,
    ct: form.getFieldValue('valorTotal') || 0,
    vse: 0,
  });

  const { tx } = useTranslationX('counterpart', 'proposal');

  const result = useFormObserver('contrapartidaRH', true);
  const rhe = result?.data?.value;
  const lastValue = useRef(rhe);

  const debouncedResult = useDebounce(rhe, 300);

  useEffect(() => {
    if (lastValue.current === debouncedResult) {
      return;
    }
    if (!firstRender && debouncedResult !== undefined && debouncedResult !== null) {
      lastValue.current = debouncedResult;
      dispatch({ type: 'LOCK_NAVIGATION', payload: true });
      const source = request<PatchResponse<State>[]>({
        method: 'PATCH',
        url: `/proposta/${id}/counterpart`,
        data: [{ type: 'replace', path: 'contrapartidaRH', value: debouncedResult }],
        onSuccess: res => {
          const { status, response } = res[0];

          if (status === 'success' && response) {
            setState(response);
            form.setFieldValue('suporteOperacional', response.vse);
            form.setFieldValue('totalIf', response.vse + debouncedResult);
            form.notifySave();
          }
        },
        onComplete: () => {
          dispatch({ type: 'LOCK_NAVIGATION', payload: false });
        },
      });

      return () => {
        source.cancel();
        dispatch({ type: 'LOCK_NAVIGATION', payload: false });
      };
    }
  }, [debouncedResult, request, id, form, firstRender, dispatch]);

  return (
    <div className={styles.counterpart}>
      <div className={styles.total}>
        <TotalLabel value={state.cf} title={tx('financial-cost')} />
        <TotalLabel value={state.ct} title={tx('total-value')} />
      </div>

      <TextField name="contrapartidaRH" label={tx('rh')} omit />

      <TextField
        name="suporteOperacional"
        label={tx('operational-support')}
        type="readonly"
        omit
      />

      <TextField name="totalIf" label={tx('total-value-ifce')} type="readonly" omit />
    </div>
  );
};

export default Counterpart;
