import React from 'react';

import { useTranslationX } from 'i18n';
import Dialog, { DialogProps } from './Dialog';
import { useVisible } from 'components/hooks';
import { Button } from 'components/elements';
import { XCircle } from 'components/icons';

import styles from './ErrorDialog.module.scss';

type Props = {
  visible: boolean;
  buttonLabel?: string;
};

const ErrorDialog: React.FC<Props & DialogProps> = props => {
  const { buttonLabel, ...rest } = props;
  const { visible, setVisible } = useVisible(props.visible);

  const { tx } = useTranslationX('button');

  return (
    <Dialog
      {...rest}
      visible={visible}
      icon={XCircle}
      iconProps={{ className: styles.error }}
      actions={
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={() => setVisible(false)}>{buttonLabel || tx('ok')}</Button>
        </div>
      }
    />
  );
};

export default ErrorDialog;
