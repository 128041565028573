import React, { ReactNode } from 'react';

import styles from './Card.module.scss';

type Props = {
  title?: string;
  titleType?: number;
  rightChildren?: React.ReactNode;
  width: {
    max?: number | string;
    min?: number | string;
  };
  height?: number | string;
  children?: ReactNode;
};

const Card: React.FC<Props> = props => {
  return (
    <div
      className={styles.card}
      style={{
        minWidth: props.width.min,
        maxWidth: props.width.max,
        height: props.height,
      }}
    >
      <div className={styles.header}>
        {props.title !== undefined &&
          (props.titleType || props.titleType === undefined) && (
            <div className={styles.title}>{props.title}</div>
          )}
        {props.rightChildren}
      </div>

      <div className={styles.body}>{props.children}</div>
    </div>
  );
};

export default Card;
