import React, { useEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import { useTranslationX } from 'i18n';
import { STEP_W, MARGIN } from './consts';
import { classes } from 'utils/components';
import { Action, GridConfig } from './types';

import styles from './Grid.module.scss';
import { DeadlineMarker } from 'components/icons';

type Props = {
  config: GridConfig;
  steps: number;
  dispatch: (action: Action) => void;
  active: boolean;
  reference?: HTMLDivElement | null;
  completion: number;
  percentage?: number;
  editable: boolean;
};

const Grid = (props: Props) => {
  const { config, percentage, steps, dispatch, active, reference, completion, editable } =
    props;

  const [shadow, setShadow] = useState<{ target: number; x: number; w: number }>();
  const [height, setHeight] = useState<number>();

  const state = useRef({ hover: 0, active: 0, target: 0 });
  const ref = useRef<HTMLDivElement>(null);

  const { tx } = useTranslationX('schedule', 'proposal');

  const safeAreaWidth = steps * STEP_W + MARGIN;
  const width = (steps + completion) * STEP_W + MARGIN * 2;

  const relativeSafeAreaWidth = (safeAreaWidth * (percentage || 0)) / 100;

  useEffect(() => {
    if (reference) {
      const ro = new ResizeObserver(() =>
        setHeight(reference.getBoundingClientRect().height)
      );

      ro.observe(reference);
      return () => ro.disconnect();
    }
  }, [reference]);

  useEffect(() => {
    setShadow(undefined);
  }, [config]);

  useEffect(() => {
    if (!editable) {
      return;
    }

    const down = () => {
      if (state.current.hover) {
        state.current.active = state.current.hover;
        dispatch({ type: 'SET_OPERATION', payload: 'move_marker' });
      }
    };

    const move = (event: MouseEvent) => {
      if (ref.current && state.current.active) {
        const rec = ref.current.getBoundingClientRect();
        const shadow = computeShadow(event.pageX - rec.x, state.current.active, config);

        state.current.target = shadow.target;
        setShadow(old => {
          if (
            old &&
            old.target === shadow.target &&
            old.w === shadow.w &&
            old.x === shadow.x
          ) {
            return old;
          }

          return shadow;
        });
      }
    };

    const up = () => {
      const { active, target } = state.current;

      if (active && target) {
        const payload = { oldValue: active, newValue: target };
        state.current.active = state.current.target = 0;

        dispatch({ type: 'UPDATE_MARKER', payload });
      }
    };

    window.addEventListener('mousedown', down);
    window.addEventListener('mousemove', move);
    window.addEventListener('mouseup', up);

    return () => {
      window.removeEventListener('mousedown', down);
      window.removeEventListener('mousemove', move);
      window.removeEventListener('mouseup', up);
    };
  }, [config, dispatch, editable]);

  const renderLines = () => {
    const svgData = [];
    const markers = Object.keys(config).map(key => parseInt(key));
    const max = steps + completion + 1;

    for (let i = 1, x = MARGIN + STEP_W; i < max; ++i, x += STEP_W) {
      if (i <= steps && markers.includes(i)) {
        const handlers =
          i < steps
            ? {
                onMouseEnter: () => (state.current.hover = i),
                onMouseLeave: () => (state.current.hover = 0),
              }
            : undefined;

        svgData.push(
          <g key={`m${i}`} className={styles.anchor} {...handlers}>
            <line x1={`${x}px`} y1="0" x2={`${x}px`} y2="100% " />
            <rect x={`${x - 2.5}px`} y="0" width="5px" height="100%" />
          </g>
        );
      } else if (shadow?.target === i) {
        svgData.push(
          <line
            className={styles.shadow}
            key={`l${i}`}
            x1={`${x}px`}
            y1="0"
            x2={`${x}px`}
            y2="100%"
          />
        );
      } else {
        svgData.push(<line key={`l${i}`} x1={`${x}px`} y1="0" x2={`${x}px`} y2="100%" />);
      }
    }

    return svgData;
  };

  const renderMonths = () => {
    const labels = [];
    const max = steps + completion;

    for (let i = 0; i < max; ++i) {
      labels.push(
        <span key={i} style={{ width: STEP_W }}>
          {tx('month', { val: String(i + 1).padStart(2, '0') })}
        </span>
      );
    }

    return labels;
  };

  return (
    <div
      className={classes(
        styles.grid,
        active ? styles.active : '',
        editable ? '' : styles.readonly
      )}
      ref={ref}
      style={{ minWidth: width, minHeight: height }}
    >
      <svg width={`${width}px`} height="100%">
        {shadow ? (
          <rect
            className={styles.shadow}
            x={`${shadow.x}px`}
            y="0"
            width={`${shadow.w}px`}
            height="100%"
          />
        ) : null}
        <line x1={`${MARGIN}px`} y1="0" x2={`${MARGIN}px`} y2="100%" />
        {renderLines()}
        {percentage && (
          <g className={styles.deadline}>
            <line
              x1={`${relativeSafeAreaWidth}px`}
              x2={`${relativeSafeAreaWidth}px`}
              y2="100%"
              stroke="#37A64A"
              strokeWidth="3"
            />
          </g>
        )}
      </svg>

      <div className={styles.months}>
        {percentage !== undefined && (
          <div
            style={{
              left: relativeSafeAreaWidth - 8,
            }}
          >
            <DeadlineMarker size={1.4} />
          </div>
        )}
        <div
          style={{
            left: MARGIN,
            width: (steps + completion) * STEP_W,
          }}
        >
          {renderMonths()}
        </div>
      </div>
    </div>
  );
};

export default Grid;

function computeShadow(x: number, active: number, config: GridConfig) {
  const activeConfig = config[active];

  const minX = activeConfig.min * STEP_W + MARGIN;
  const maxX = activeConfig.max * STEP_W + MARGIN;

  const x1 = Math.min(Math.max(minX, x), maxX);
  const x2 = active * STEP_W + MARGIN;

  return {
    target: Math.min(
      Math.max(Math.round((x - MARGIN) / STEP_W), activeConfig.min),
      activeConfig.max
    ),
    x: Math.min(x1, x2),
    w: Math.abs(x1 - x2),
  };
}
