import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  RouteProps,
} from 'react-router-dom';

import { Location } from 'history';
import { useDispatch } from 'react-redux';

import Dashboard from 'pages/dashboard';
import Register from 'pages/register/Register/Register';
import Login from 'pages/auth/Login/Login';

import { refreshToken, setProfile, useAuthState } from 'store/auth';
import { Loader } from 'components/elements';
import { ScholarshipTermination } from './pages/project/Sections/HumanResources';

const redirect = (pathname: string, from?: Location) => (
  <Redirect
    to={{
      pathname,
      state: { from },
    }}
  />
);

const AnonimousRoute: React.FC<RouteProps> = props => {
  const { token } = useAuthState();

  if (token) {
    return redirect('/', props.location);
  }

  return <Route {...props} />;
};

const AuthenticatedRoute: React.FC<RouteProps> = props => {
  const { status } = useAuthState();

  if (status === 'authorized') {
    return <Route {...props} />;
  }

  return redirect('/', props.location);
};

const RouteGuard: React.FC<RouteProps> = props => {
  const dispatch = useDispatch();
  const { status, token, profile, pid, profiles, prefs } = useAuthState();

  useEffect(() => {
    if (token) {
      if (status === 'authenticated') {
        dispatch(
          setProfile(
            profile && pid
              ? { profileName: profile, profileId: pid }
              : profiles!.find(p => prefs.profile && p.profileName === prefs.profile) ||
                  profiles![0]
          )
        );
      } else if (status === 'loggedOut') {
        dispatch(refreshToken());
      }
    }
  }, [dispatch, pid, prefs.profile, profile, profiles, status, token]);

  if (token) {
    if (status === 'authorized') {
      return redirect('/dashboard', props.location);
    }

    return <Loader size={60} />;
  }

  return redirect('/login', props.location);
};

const App = () => {
  return (
    <Router>
      <Switch>
        <RouteGuard path="/" exact />
        <AnonimousRoute path="/login" component={Login} />
        <AnonimousRoute path="/cadastro" component={Register} />
        <AnonimousRoute path="/bolsista/autoavaliacao" component={ScholarshipTermination} />
        <AuthenticatedRoute path="/dashboard" component={Dashboard} />
        <Route path="/token/:token" component={Login} />
        <Redirect to="/" />
      </Switch>
    </Router>
  );
};

export default App;
