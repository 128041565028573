import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { ContentBlock, convertToRaw, Editor, EditorState, Modifier } from 'draft-js';
import { Entity, RichUtils } from 'draft-js';

import { useRequest } from 'apis';
import { useTranslation, useTranslationX } from 'i18n';
import { ScrollPanel } from 'components/containers';
import { FieldMetadata, FieldType } from 'components/form/types';
import { load, save } from './helpers';
import { readAttr } from 'utils/object';
import { call } from 'utils';
import ToolBar, { ToolBarOption } from './ToolBar';
import { messageService } from 'services';
import { Button } from 'components/elements';
import { Replay } from 'components/icons';

import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';

import 'draft-js/dist/Draft.css';
import styles from './TextEditor.module.scss';

type DescritiveHistoric = {
  name: string;
  type: string;
  from: string;
  to: string;
  metadata: {
    rules: {
      [key: string]: any;
    };
  };
};

export type Props = {
  value?: string;
  lastValue?: string;
  form?: any;
  label?: string;
  width?: string | number;
  onChange?: (value?: string) => void;
  options?: ToolBarOption[];
  maxLength?: number;
  metadata?: FieldMetadata;
  type?: FieldType;
  hasDiff?: boolean;
  name?: string;
  editorStyle?: React.CSSProperties;
  toolBarStyle?: React.CSSProperties;
  historic?: DescritiveHistoric;
};

const Image = ({ block }: { block: ContentBlock }) => {
  if (block.getEntityAt(0) !== null) {
    const entity = Entity.get(block.getEntityAt(0));
    return <img alt="" style={{ textAlign: 'center' }} src={entity.getData()} />;
  }
  return <></>;
};

const TextEditor = (props: Props) => {
  const {
    onChange,
    editorStyle,
    toolBarStyle,
    options,
    type,
    value,
    hasDiff,
    name,
    form,
    metadata,
    lastValue,
  } = props;

  const [textLength, setTextLength] = useState(0);
  const [state, setState] = useState<EditorState>();
  const [hasChanged, setHasChanged] = useState(false);
  const isFirstType = useRef(true);
  const request = useRequest();
  const { t } = useTranslation();

  const required = readAttr('rules.required', metadata, false);
  const showOptional = useMemo(
    () => readAttr('rules.required', metadata) !== undefined && form?.display?.optional,
    [form.display.optional, metadata]
  );

  const { tx } = useTranslationX('components.richEditor');

  const isIntelectualProperty = useMemo(
    () => name === 'descritivo.propriedadeIntelectual',
    [name]
  );

  const hadleIntelectualWarnings = useCallback(() => {
    if (hasChanged) {
      if (isIntelectualProperty && !hasDiff) {
        messageService.warning(tx('intelectualWarning'), {
          duration: 2000,
        });
      }
      isFirstType.current = false;
      setHasChanged(false);
    }
  }, [hasChanged, hasDiff, tx, isIntelectualProperty]);

  useEffect(() => {
    setState(state => {
      const loadedValue = load(value);
      return state !== undefined ? EditorState.moveFocusToEnd(loadedValue) : loadedValue;
    });
  }, [value]);

  useEffect(() => {
    if (state) {
      setTextLength(state.getCurrentContent().getPlainText().length);
    }
  }, [state]);

  useEffect(() => {
    if (state) {
      call(onChange, save(state));
    }
  }, [state, onChange, hasDiff, name]);

  useEffect(() => {
    hadleIntelectualWarnings();
  }, [hadleIntelectualWarnings]);

  const handleResetIntelectualProperty = useCallback(() => {
    const source = request<string | undefined>({
      method: 'GET',
      url: '/propostas/propriedade-intelectual',
      onSuccess: data => {
        setState(state => {
          const loadedValue = load(data);
          return state !== undefined
            ? EditorState.moveFocusToEnd(loadedValue)
            : loadedValue;
        });
      },
    });

    isFirstType.current = true;

    return () => source.cancel();
  }, [request]);

  if (!state) {
    return null;
  }

  const getBlockStyle = (block: ContentBlock) =>
    block.getType() !== 'atomic' ? 'justified' : '';

  const blockRenderer = (block: ContentBlock) =>
    block.getType() === 'atomic'
      ? {
          component: Image,
          editable: false,
        }
      : null;

  const handleKeyCommand = (command: string, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setState(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  const maxLength = getMaxLength(props);

  const handleBeforeInput = (text: string) => {
    const allText = state.getCurrentContent().getPlainText() + text;
    if (allText.length > maxLength) {
      return 'handled';
    } else {
      return 'not-handled';
    }
  };

  const handlePastedText = (text: string) => {
    const allText = state.getCurrentContent().getPlainText() + text;
    if (allText.length > maxLength) {
      const newContent = Modifier.replaceText(
        state.getCurrentContent(),
        state.getSelection(),
        text.substring(0, maxLength - state.getCurrentContent().getPlainText().length)
      );
      setState(EditorState.push(state, newContent, 'insert-characters'));
      return 'handled';
    } else {
      return 'not-handled';
    }
  };

  const handleEditorChange = (newEditorState: EditorState) => {
    const content = convertToRaw(newEditorState.getCurrentContent());

    if (
      isFirstType.current &&
      JSON.stringify(content) !== JSON.stringify(initialContent)
    ) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
    setState(newEditorState);
  };

  const editable = type !== 'readonly' && type !== 'disabled';

  const initialContent = convertToRaw(state.getCurrentContent());

  const label = props.label;

  const textValue = load(value).getCurrentContent().getPlainText();
  const textLastValue = load(lastValue).getCurrentContent().getPlainText();

  return (
    <>
      {isIntelectualProperty && (
        <div className={styles.resetButton}>
          <Button theme="classic" icon={Replay} onClick={handleResetIntelectualProperty}>
            {tx('resetIntelectualToDefault')}
          </Button>
        </div>
      )}

      <div className={styles.editor} style={{ width: props.width }}>
        {label && (
          <label>
            {label + ' '}

            {required && <span className={styles.required}>*</span>}

            {!required && showOptional && (
              <span className={styles.optional}>
                {`(${t('components.form.field.optional')})`}
              </span>
            )}
          </label>
        )}

        {editable && (
          <ToolBar
            state={state}
            setState={setState}
            options={options}
            left={maxLength - textLength}
            toolBarStyle={toolBarStyle}
          />
        )}
        <ScrollPanel
          vBar={{ overlay: true, placeholder: true }}
          style={{ root: editorStyle }}
        >
          <Editor
            blockRendererFn={blockRenderer}
            editorState={state}
            onChange={handleEditorChange}
            blockStyleFn={getBlockStyle}
            handleKeyCommand={handleKeyCommand}
            handlePastedText={handlePastedText}
            handleBeforeInput={handleBeforeInput}
            placeholder={tx('describe')}
            preserveSelectionOnBlur
            readOnly={!editable}
          />

          {hasDiff && <DiffEditor value={textValue} lastValue={textLastValue} />}
        </ScrollPanel>
      </div>
    </>
  );
};

const DiffEditor = React.memo(({ value, lastValue }: Props) => {
  const newStyles = {
    line: {
      display: 'flex',
      flex: 1,
    },
    marker: {
      visibility: 'hidden' as 'hidden',
      position: 'absolute' as 'absolute',
    },

    wordAdded: {
      borderRadius: '6px ',
      padding: '0px 2px',
    },
    wordRemoved: {
      background: '#fdb8c0',
      borderRadius: '6px ',
      padding: '0px 2px',
    },
    diffRemoved: {
      borderRadius: '6px 0px 0px 6px',
      padding: '0.25rem',
    },
    diffAdded: {
      borderRadius: '0px 6px 6px 0px',
      padding: '0.25rem',
    },
    contentText: {
      fontFamily: '"Open Sans", sans-serif',
      fontSize: '1rem',
    },
  };

  const currentValueContent = load(value).getCurrentContent().getPlainText();
  const lastValueContent = load(lastValue).getCurrentContent().getPlainText();

  return (
    <ReactDiffViewer
      newValue={currentValueContent}
      oldValue={lastValueContent}
      compareMethod={DiffMethod.WORDS_WITH_SPACE}
      styles={newStyles}
      hideLineNumbers
      renderContent={source => (
        <div
          dangerouslySetInnerHTML={{
            __html: load(source).getCurrentContent().getPlainText(),
          }}
        />
      )}
    />
  );
});

export default TextEditor;

function getMaxLength({ maxLength, metadata }: Props) {
  if (typeof maxLength === 'number') {
    return maxLength;
  }

  const maxlength =
    readAttr('rules.htmlmax', metadata) || readAttr('rules.maxlength', metadata);

  if (typeof maxlength === 'number') {
    return maxlength;
  }

  return 5000;
}
