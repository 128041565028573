import React from 'react';
import { ScrollPanel } from 'components/containers';

import ReadOnlyTableDataSection, {
  ReadOnlyTableDataSectionRowConfig,
} from './ReadOnlyTableDataSection';

import styles from './ReadOnlyTableData.module.scss';
import { Spacer } from 'components/elements';

type Entry = {
  id: number | string;
};
export type ReadOnlyTableDataRows = ReadOnlyTableDataSectionRowConfig[];

export type ReadOnlyTableDataConfig = {
  title?: string;
  tableRows: ReadOnlyTableDataRows[];
};

type Props<T> = {
  data?: T;
  config: ReadOnlyTableDataConfig[];
  shadow?: boolean;
};

const ReadOnlyTableData = <T extends Entry>(props: Props<T>) => {
  const { config, data, shadow = true } = props;
  return (
    <ScrollPanel vBar={{ overlay: true, placeholder: true }}>
      <ul className={styles.readOnlyTable} style={shadow ? {} : { boxShadow: 'none' }}>
        {config.map(({ title, tableRows }, index) => (
          <li key={index}>
            {title ? <h3>{title}</h3> : index !== 0 ? <Spacer length={20} /> : null}
            {tableRows.map((rows, rowIndex) => (
              <ReadOnlyTableDataSection data={data} config={rows} key={rowIndex} />
            ))}
          </li>
        ))}
      </ul>
    </ScrollPanel>
  );
};

export default ReadOnlyTableData;
