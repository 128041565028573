import React from 'react';
import { useTranslationX } from 'i18n';
import { getMasked } from 'utils/inputMask';

import styles from './CompositionTable.module.scss';

type Props = {
  partes: string[];
  composicao: any;
};

const CompositionTable = ({ partes, composicao }: Props) => {
  const { t, tx } = useTranslationX('proposal:financial', ['translation', 'proposal']);

  let i = 0;

  const partList = partes.map(p =>
    i === partes.length - 1 ? (
      <th key={i++} colSpan={2} className={styles.partItem}>
        {`${p} (${tx('composition.economic')})`}
      </th>
    ) : (
      <th key={i++} colSpan={2} className={styles.partItem}>
        {p}
      </th>
    )
  );

  const partLabelVal = (
    <>
      <tr key={i++}>
        <th key={i++}></th>
        {partList}
      </tr>
      <tr key={i++}>
        <th key={i++}></th>
        {partes.map(p => (
          <React.Fragment key={i++}>
            <td key={i++}>{`${tx('composition.currency')} (${t('currency')})`}</td>
            <td key={i++}>{tx('composition.percent')}</td>
          </React.Fragment>
        ))}
      </tr>
    </>
  );

  const compositionKeys = ['finEmbrapii', 'finOutros', 'total'];
  const labelKeys = ['financial', 'others', 'total'];

  let j = 0;
  let value_perc = false;
  const compositionList = compositionKeys.map((key: string) => {
    let elements = composicao[key];

    return (
      <tr key={i++}>
        <td className={styles.labelKeys} key={i++}>
          {tx(`composition.${labelKeys[j++]}`)}
        </td>
        {elements.map((e: number) => {
          if (key === 'total') {
            value_perc = !value_perc;
            if (value_perc) {
              return (
                <td key={i++}>
                  {e !== 0
                    ? getMasked(e.toFixed(2), {
                        pattern: 'currency',
                      })
                    : ''}
                </td>
              );
            } else {
              return <td key={i++} />;
            }
          } else {
            return (
              <td key={i++}>
                {e !== 0
                  ? getMasked(e.toFixed(2), {
                      pattern: 'currency',
                    })
                  : ''}
              </td>
            );
          }
        })}
      </tr>
    );
  });

  return (
    <>
      <h4>{tx('composition.title')}</h4>

      <table className={styles.financial}>
        <thead>{partLabelVal}</thead>
        <tbody>{compositionList}</tbody>
      </table>
    </>
  );
};

export default CompositionTable;
