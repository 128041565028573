import React, { useEffect } from 'react';

import { useRequest } from 'apis';
import { ErrorMessage } from 'components/elements';
import { Data, ScrollPanel } from 'components/containers';
import Notification from './Notification';
import { Dispatch, NotificationData, State } from './types';

import styles from './NotificationList.module.scss';

type Props = {
  state: State;
  dispatch: Dispatch;
};

const NotificationList = ({ state, dispatch }: Props) => {
  const { status, filters, notifications, selected, error } = state;
  const request = useRequest();

  useEffect(() => {
    if (status === 'loading_list' && Array.isArray(filters)) {
      const source = request<NotificationData[]>({
        url: '/homologacao/notificacoes',
        params: filters.length ? { types: filters.join(',') } : undefined,
        onSuccess: data => dispatch({ type: 'LIST_UPDATE', payload: data }),
        onError: value => dispatch({ type: 'ERROR', payload: { key: 'list', value } }),
      });

      return () => source.cancel();
    }
  }, [dispatch, filters, request, status]);

  return (
    <div className={styles.notificationList}>
      {error?.key === 'list' ? (
        <ErrorMessage />
      ) : (
        <Data data={notifications} loading={status === 'loading_list'}>
          {data => (
            // fetch by data
            <ScrollPanel vBar={{ overlay: true }}>
              {data.map(item => {
                return (
                  <Notification
                    key={`${item.id}${item.type}`}
                    data={item}
                    selected={selected?.id === item.id && selected.type === item.type}
                    onClick={() => dispatch({ type: 'SELECT', payload: item })}
                  />
                );
              })}
            </ScrollPanel>
          )}
        </Data>
      )}
    </div>
  );
};

export default NotificationList;
