import React from 'react';

import { Grid, Row } from 'components/containers';
import { classes } from 'utils/components';

import styles from './Stepper.module.scss';

type Props = {
  selected: number;
  steps: string[];
};

const Stepper: React.FC<Props> = ({ selected, steps }) => {
  const getStatusClass = (index: number) => {
    return index === selected
      ? styles.selected
      : index < selected
      ? styles.done
      : styles.disabled;
  };

  return (
    <div className={styles.stepper}>
      <Grid>
        <Row>
          {steps.map((title, index) => (
            <div key={index} className={classes(styles.step, getStatusClass(index))}>
              <div className={styles.number}>
                <span>{index + 1}</span>
              </div>
              <span className={styles.title}>{title}</span>
            </div>
          ))}
        </Row>
      </Grid>
    </div>
  );
};

export default Stepper;
