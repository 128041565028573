import React from 'react';

import styles from './PercentCardWithIcon.module.scss';

interface PercentProps {
  label: string;
  value?: number;
  percent?: number;
  icon: React.ElementType;
  onClick?: () => void;
}

const PercentCardWithIcon: React.FC<PercentProps> = ({
  label,
  value,
  percent,
  icon: Icon,
  onClick,
}) => {
  return (
    <div className={styles.percentProposalContainer} onClick={onClick}>
      <div>
        <p>{label}</p>
        <div>
          <p>{value}</p>
          {percent && <span className={styles.percent}>+ {percent}% </span>}
        </div>
      </div>
      <div className={styles.percentLogo}>
        <Icon size={2.5} />
      </div>
    </div>
  );
};

export default PercentCardWithIcon;
