import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import TableList, { ListType } from 'components/containers/TableList/TableList';
import { ColumnConfig } from 'components/containers/TableList/types';
import { useTranslation, useTranslationX } from 'i18n';
import {
  HiringStatus,
  hiringStatusColors,
  RoleItem,
  RoleType,
} from 'pages/project/types';
import { useProjectEditor } from 'pages/project/ProjectEditor/contexts';
import { useFetch } from 'apis';
import { Loader } from 'components/elements';
import useWindowSize from 'components/hooks/useWindowSize';
import { getMasked } from 'utils/inputMask';

type People = {
  id: number;
  solicitacaoId: number;
  nome: string;
  vinculo: RoleType;
  categoria: string;
  cargo: string;
  duracao: number;
  estado: HiringStatus;
  remuneracao: number;
};

type FiltersData = {
  categoriaBolsa: Item[];
  vinculoTrabalho: Item[];
  fonteRecurso: Item[];
};

type Item = {
  id: number;
  nome: string;
};

const ListPeople: React.FC = () => {
  const { tx, t } = useTranslationX('list', 'project');
  const { t: t2 } = useTranslation();
  const { state, dispatch } = useProjectEditor();
  const { id: projetoId } = useParams<{ id: string }>();

  const history = useHistory();

  const { width } = useWindowSize();

  const isHome = !state.section;

  const { data: filtersData } = useFetch<FiltersData>(
    `/projeto/${isHome ? projetoId : state.id}/recursos-humanos/filter-options`
  );

  const config: ColumnConfig<ListType> = [
    {
      weight: 1.1,
      title: tx('columns.name'),
      field: 'nome',
      type: 'badge',
      capitalize: true,
      align: 'left',
      search: true,
    },
    {
      weight: 0.8,
      title: tx('columns.type'),
      field: 'vinculo',
      align: 'center',
      search: true,
      searchType: 'status',
      metadata: {
        options: filtersData?.vinculoTrabalho.map(vinculo => {
          return {
            text: vinculo.nome,
            value: vinculo.nome,
          };
        }),
      },
    },
    {
      weight: 0.7,
      title: tx('columns.source'),
      field: 'fonte',
      align: 'center',
      search: true,
      searchType: 'status',
      metadata: {
        options: filtersData?.fonteRecurso.map(bolsa => {
          return {
            text: bolsa.nome,
            value: bolsa.nome,
          };
        }),
      },
    },
    {
      weight: 0.6,
      title: tx('columns.category'),
      field: 'categoria',
      align: 'center',
      search: true,
      searchType: 'status',
      metadata: {
        options: filtersData?.categoriaBolsa.map(bolsa => {
          return {
            text: bolsa.nome,
            value: bolsa.nome,
          };
        }),
      },
    },
    {
      weight: 0.6,
      title: tx('columns.role'),
      field: 'cargo',
      align: 'center',
      search: true,
    },
    {
      weight: 0.5,
      title: tx('columns.duration'),
      field: 'duracao',
      align: 'center',
      searchType: 'range',
      pattern: '09',
      search: true,
    },
    {
      title: tx('columns.status'),
      field: 'estado',
      type: 'tag',
      weight: 0.5,
      search: true,
      metadata: {
        options: Object.keys(hiringStatusColors).map(status => {
          return {
            value: status,
            text: t(`rolesCards.status.${status}`),
          };
        }),
      },
      searchType: 'status',
      parse: status => {
        return {
          value: t(`rolesCards.status.${status}`),
          bgColor: hiringStatusColors[status as HiringStatus],
        };
      },
    },
    {
      weight: 0.4,
      title: tx('columns.remuneration'),
      field: 'remuneracao',
      align: 'center',
      type: 'html',
      searchType: 'range',
      search: true,
      parse: value =>
        `<strong>${
          t2('currency') + ' ' + getMasked(value.toFixed(2), { pattern: 'currency' })
        }</strong>`,
    },
  ];

  if (!filtersData) {
    return <Loader size={60} />;
  }

  return (
    <TableList<People>
      baseUrl={`/projeto/${state.id}/recursos-humanos/contratacoes`}
      listHeader={{
        title: tx('person.title'),
        subtitle: tx('person.subtitle'),
        position: 'inside',
      }}
      config={config}
      style={{
        root: {
          maxWidth: width < 1600 ? '1500px' : '1700px',
          maxHeight: 'calc(80vh - 150px)',
        },
      }}
      textSize="small"
      theme="light"
      editIcon={null}
      editAction={({ id, ...rest }) => {
        const payload: RoleItem = {
          id: rest.solicitacaoId,
          cargo: rest.cargo,
          duracao: rest.duracao,
          categoria: rest.categoria,
          tipo: rest.vinculo,
          remuneracao: rest.remuneracao,
          status: rest.estado,
          alteracao_exclusao: false,
          ordem: 0,
          vinculo: rest.vinculo,
        };
        if (id !== undefined) {
          dispatch({ type: 'SET_ITEM', payload });
          history.push(`${history.location.pathname}/${id}/pessoa`, { ...rest, id });
        }
      }}
      refreshesOn={95}
      notFoundProps={{}}
      filter
      infinityScroll
    />
  );
};

export default ListPeople;
