import React from 'react';

import { useFetch } from 'apis';
import { useTranslationX } from 'i18n';
import { Loader, WrapperCard } from 'components/elements';
import { Form, useForm } from 'components/form';
import { SectionProps } from '../ProposalEditor/ProposalEditor';
import BudgetTable from './BudgetTable';
import AlertMessage from 'components/elements/AlertMessage/AlertMessage';

import BudgetInfoContainer from './BudgetInfoContainer';
import { ScrollPanel } from 'components/containers';

import styles from './BudgetDescription.module.scss';

type BudgetDescriptionError = {
  error?: string;
};

const BudgetDescription = ({
  state: { id, forms, section },
  formProps,
  dispatch,
}: SectionProps) => {
  const { tx } = useTranslationX('proposal:budget-description', [
    'translation',
    'proposal',
  ]);

  const { data } = useFetch<BudgetDescriptionError>(`/proposta/${id}/budget-description`);

  if (!data) {
    return <Loader />;
  }

  if (data.error !== undefined) {
    return (
      <div className={styles.invalid}>
        <AlertMessage title={tx('error.modality')} message={data.error} />
      </div>
    );
  }

  return (
    <WrapperCard maxWidth="80%" maxHeight="100%" padding="0">
      <Form baseUrl={`/proposta/${id}/budget-description`} {...formProps}>
        <div className={styles.budget}>
          <BudgetInfoContainer
            id={id}
            form={forms[section || 'budget-description']}
            dispatch={dispatch}
          />
          <ScrollPanel>
            <Budget />
          </ScrollPanel>
        </div>
      </Form>
    </WrapperCard>
  );
};

const Budget = () => {
  const form = useForm();
  const groups = form.getFieldValue<string[]>('grupos') || [];

  return (
    <>
      {groups.map(el => (
        <BudgetTable key={el} groupName={el} />
      ))}
    </>
  );
};

export default BudgetDescription;
