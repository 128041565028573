import React, { useReducer } from 'react';

import { Form, useForm } from 'components/form';
import { ScrollPanel } from 'components/containers';
import { SectionProps } from '../ProposalEditor/ProposalEditor';
import { Action, Entry, Node, State } from './types';
import Grid from './Grid';
import EditDialog from './EditDialog';

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_MODAL':
      return { modal: action.payload || undefined };

    default:
      return state;
  }
};

const Eap = ({ state: { id, editable }, formProps }: SectionProps) => {
  return (
    <Form baseUrl={`/proposta/${id}/eap`} {...formProps}>
      <FormContent editable={editable} />
    </Form>
  );
};

const FormContent = ({ editable }: { editable: boolean }) => {
  const [state, dispatch] = useReducer(reducer, {});

  const form = useForm();
  const eap = form.getFieldValue('eap') as Entry[];
  const sigla = form.getFieldValue('sigla') as string;

  const tree: Node[] = Array.isArray(eap) ? buildTree(eap) : [];

  return (
    <ScrollPanel>
      <Grid name="eap" root={sigla} data={tree} dispatch={dispatch} editable={editable} />
      {state.modal ? (
        <EditDialog editable={editable} config={state.modal} dispatch={dispatch} />
      ) : null}
    </ScrollPanel>
  );
};

function buildTree(entries: Entry[]) {
  const map: { [key: number]: Partial<Node> } = {};
  const tree: Node[] = [];

  for (let index = 0; index < entries.length; ++index) {
    const { id, pai, ...rest } = entries[index];

    if (map[id]) {
      Object.assign(map[id], { id, pai, index, ...rest });
    } else {
      map[id] = { id, pai, index, ...rest };
    }

    if (pai === null || pai === undefined) {
      tree.push(map[id] as Node);
    } else {
      if (!map[pai]) {
        map[pai] = { id: pai, children: [] };
      }

      if (!map[pai].children) {
        map[pai].children = [];
      }

      map[pai].children!.push(map[id] as Node);
    }
  }

  return tree;
}

export default Eap;
