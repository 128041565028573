import React from 'react';

import { Warning } from 'components/icons';

import styles from './AlertLine.module.scss';

type Props = {
  text: string;
};

const AlertLine: React.FC<Props> = ({ text }) => {
  return (
    <div className={styles.container}>
      <Warning size={2} />
      <span>{text}</span>
    </div>
  );
};
export default AlertLine;
