import { useFetch } from 'apis';
import { ModalidadeParte, Partes } from '../type';
import { useEffect, useMemo, useState } from 'react';
import { MenuItem } from 'pages/dashboard/types';
import { useTranslationX } from 'i18n';

type useFinancialProps = {
  id: number;
  historicData: {
    [key: string]: any;
  };
  sections: MenuItem[];
};

const financialHistoric = {
  hr: 'rh',
  consumables: 'material',
  tickets: 'passagem',
  dailys: 'diaria',
  services: 'servico',
  other: 'outros',
  equipments: 'equipamentos',
  support: 'suporte',
};

type SectionChange = {
  fonteRecursoId?: number;
  fonteRecursoStatus?: number;
  key?: string;
};

export const useFinancial = ({ id, historicData, sections }: useFinancialProps) => {
  const { data } = useFetch<Partes>(`/projeto/${id}/alteracao-pt/partes`);

  const { tx } = useTranslationX('financial', 'update_wp');

  const [parteId, setParteId] = useState<string | undefined>();
  const [fonteRecursoStatus, setFonteRecursoStatus] = useState<number>();

  const partes = useMemo(() => {
    if (data) {
      return data.partes;
    }
  }, [data]);

  const duplicatedPartes = useMemo(() => {
    return partes
      ?.concat(
        partes
          ?.filter(item => item.outrosCustos)
          .map(item => ({
            ...item,
            nome: `${item.nome} ${tx('sections.2')}`,
            fonteRecursoStatus: 2,
          }))
      )
      .sort((a, b) => {
        if (a.nome < b.nome) return -1;
        if (a.nome > b.nome) return 1;
        return 0;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partes]);

  useEffect(() => {
    if (partes) {
      setParteId(partes[0].id.toString());
    }
  }, [partes]);

  const updateParteId = (id: string) => {
    setParteId(id);
  };

  const updateFonteRecursoStatus = (status: number) => {
    setFonteRecursoStatus(status);
  };

  const changedSections = sections
    .map(({ key }) => {
      const sectionName = financialHistoric[key as keyof typeof financialHistoric];

      const comparedData = historicData[sectionName];

      const sectionsData = comparedData?.map((data: { [key: string]: any }) => {
        const { fonteRecursoId, fonteRecursoStatus } = data;

        return {
          fonteRecursoId,
          fonteRecursoStatus,
          key,
        };
      }) as SectionChange[];

      return sectionsData;
    })
    .flat();

  const findChangedSections = (key: string) =>
    changedSections.some(
      (item?: SectionChange) =>
        item?.fonteRecursoId === Number(parteId) &&
        item?.fonteRecursoStatus === fonteRecursoStatus &&
        item?.key === key
    );
  const changedParts = sections
    .map(({ key }) => {
      const sectionName = financialHistoric[key as keyof typeof financialHistoric];

      const sectionData = historicData[sectionName]?.map(
        (data: { [key: string]: any }) => {
          const { fonteRecursoId, fonteRecursoStatus } = data;

          return {
            fonteRecursoId,
            fonteRecursoStatus,
          };
        }
      ) as SectionChange[];

      const filteredData = sectionData?.filter(item => !!item.fonteRecursoId);
      const mappedData = filteredData?.map(item => ({
        fonteRecursoStatus: item.fonteRecursoStatus,
        fonteRecursoId: item.fonteRecursoId,
      }));

      return mappedData;
    })
    .flat();

  const findChangedParts = (parte: ModalidadeParte) => {
    if (changedParts) {
      return changedParts?.some(
        (sectionPart?: SectionChange) =>
          sectionPart?.fonteRecursoId === parte.id &&
          sectionPart?.fonteRecursoStatus === (parte.fonteRecursoStatus ?? 1)
      );
    }

    return false;
  };

  return {
    duplicatedPartes,
    parteId,
    partes,
    fonteRecursoStatus,
    updateFonteRecursoStatus,
    updateParteId,
    findChangedParts,
    findChangedSections,
  };
};
