import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';

import { FieldSet, HiddenField, useForm, useFormObserver } from 'components/form';

import { Pencil } from 'components/icons';
import Tippy from '@tippyjs/react';
import { IconButton } from 'components/elements';
import { isNil, splitValueUnit } from 'utils';
import { Action as ScheduleAction, Transit } from './types';
import { STEP_W, INNER_MARGIN } from './consts';
import Controls from './Controls';
import { classes } from 'utils/components';

import styles from './Activity.module.scss';

type Props = {
  name: string;
  maxSize: number;
  onEdit: () => void;
  dispatch: (action: ScheduleAction) => void;
  active: boolean;
  transit?: Transit;
  editable: boolean;
};

type Bounds = { mesInicio: number; mesFim: number };

type State = {
  params: Bounds & { maxSize: number };
  shadow?: { sl: number; sr: number; tl: number; tr: number };
  update?: Bounds & { done?: boolean };
  grabX?: number;
};

type Action =
  | { type: 'INIT'; payload: Bounds & { maxSize: number } }
  | {
      type: 'MOVE';
      payload: { x: number; active: number };
    }
  | { type: 'UPDATE' }
  | { type: 'GRAB'; payload: number };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'INIT':
      if (
        state.params.mesInicio !== action.payload.mesInicio ||
        state.params.mesFim !== action.payload.mesFim ||
        state.params.maxSize !== action.payload.maxSize
      ) {
        return { params: action.payload };
      }

      return state;

    case 'GRAB':
      return { ...state, grabX: action.payload };

    case 'MOVE': {
      const { x, active } = action.payload;
      const { mesInicio, mesFim, maxSize } = state.params;
      const { inicio, fim, left, width } = computeBounds(mesInicio, mesFim, maxSize);

      let sl, sr, tl, tr, i, mi, mf;

      switch (active) {
        case 1: {
          sl = Math.min(Math.max(x + left, 0), left + width - STEP_W);
          sr = left + width;

          i = Math.round(sl / STEP_W);
          tl = i * STEP_W - (i ? INNER_MARGIN : 0);
          tr = sr;

          mi = i + 1;
          mf = mesFim;

          break;
        }

        case 2: {
          sl = left;
          sr = Math.min(
            Math.max(x + left, left + STEP_W - (inicio === 1 ? INNER_MARGIN : 0)),
            maxSize * STEP_W - INNER_MARGIN * 2
          );

          i = Math.round(sr / STEP_W);
          tl = sl;
          tr = i * STEP_W - INNER_MARGIN - (i === maxSize ? INNER_MARGIN : 0);

          mi = mesInicio;
          mf = i;

          break;
        }

        default: {
          const dx = state.grabX || 0;

          sl = Math.min(
            Math.max(x + left - dx, 0),
            maxSize * STEP_W - INNER_MARGIN * 2 - width
          );
          sr = Math.min(sl + width, maxSize * STEP_W - INNER_MARGIN);

          i = Math.round(sl / STEP_W);
          tl = i * STEP_W - (i ? INNER_MARGIN : 0);

          mi = i + 1;
          mf = mi + (fim - inicio);
          tr = mf * STEP_W - INNER_MARGIN - (mf === maxSize ? INNER_MARGIN : 0);
        }
      }

      if (
        state.shadow &&
        state.update &&
        state.shadow.sl === sl &&
        state.shadow.sr === sr &&
        state.shadow.tl === tl &&
        state.shadow.tr === tr &&
        state.update.mesInicio === mi &&
        state.update.mesFim === mf
      ) {
        return state;
      }

      return {
        ...state,
        shadow: { sl, sr, tl, tr },
        update: { mesInicio: mi, mesFim: mf },
      };
    }

    case 'UPDATE':
      return state.update
        ? { params: state.params, update: { ...state.update, done: true } }
        : state;

    default:
      return state;
  }
};

const Activity = ({ name, editable, ...props }: Props) => {
  const form = useForm();
  const value = form.getFieldValue(name);
  const parentDispatch = props.dispatch;

  useFormObserver(`${name}.mesInicio`);
  useFormObserver(`${name}.mesFim`);

  const [state, dispatch] = useReducer(reducer, {
    params: {
      mesInicio: value?.mesInicio || 1,
      mesFim: value?.mesFim || 1,
      maxSize: props.maxSize,
    },
  });

  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const hover = useRef(0);
  const active = useRef(0);

  const { value: height } = splitValueUnit(styles.height);
  const { value: border } = splitValueUnit(styles.borderWidth);
  const { value: animDuration } = splitValueUnit(styles.animDuration);

  const updateBounds = useCallback(
    (bounds: Bounds, maxSize?: number) => {
      let data = bounds;

      if (maxSize) {
        const { inicio, fim } = computeBounds(bounds.mesInicio, bounds.mesFim, maxSize);
        data = { mesInicio: inicio, mesFim: fim };
      }

      form.setFieldValue(`${name}.mesInicio`, data.mesInicio);
      form.setFieldValue(`${name}.mesFim`, data.mesFim);
    },
    [form, name]
  );

  useEffect(() => {
    if (!isNil(value?.mesInicio) && !isNil(value?.mesFim)) {
      const payload = {
        mesInicio: value?.mesInicio || 1,
        mesFim: value?.mesFim || 1,
        maxSize: props.maxSize,
      };

      updateBounds(payload, payload.maxSize);
      dispatch({ type: 'INIT', payload });
    }
  }, [props.maxSize, updateBounds, value?.mesFim, value?.mesInicio]);

  useEffect(() => {
    if (state.update?.done) {
      updateBounds(state.update);
    }
  }, [form, name, state.update, updateBounds]);

  useEffect(() => {
    if (!ref || !editable) {
      return;
    }

    const down = (event: MouseEvent) => {
      if (ref && hover.current) {
        const rec = ref.getBoundingClientRect();

        active.current = hover.current;
        dispatch({ type: 'GRAB', payload: event.pageX - rec.x });
        parentDispatch({
          type: 'SET_OPERATION',
          payload:
            active.current === 1
              ? 'resize_e'
              : active.current === 2
              ? 'resize_w'
              : 'move_box',
        });
      }
    };

    const move = (event: MouseEvent) => {
      if (ref && active.current) {
        const rec = ref.getBoundingClientRect();

        dispatch({
          type: 'MOVE',
          payload: { x: event.pageX - rec.x, active: active.current },
        });
      }
    };

    const up = () => {
      if (active.current) {
        active.current = 0;
        dispatch({ type: 'UPDATE' });
        parentDispatch({ type: 'SET_OPERATION', payload: null });
      }
    };

    window.addEventListener('mousedown', down);
    window.addEventListener('mousemove', move);
    window.addEventListener('mouseup', up);

    return () => {
      window.removeEventListener('mousedown', down);
      window.removeEventListener('mousemove', move);
      window.removeEventListener('mouseup', up);
    };
  }, [editable, parentDispatch, ref]);

  if (!value) {
    return null;
  }

  const { titulo, cor } = value;
  const { mesInicio, mesFim, maxSize } = state.params;
  const { inicio, fim, left, width } = computeBounds(mesInicio, mesFim, maxSize);

  const style: CSSProperties = {
    backgroundColor: cor?.startsWith('#') ? cor : `#${cor}`,
    marginLeft: left,
    width,
  };

  const renderShadow = () => {
    const { sl, sr, tl, tr } = state.shadow!;

    return (
      <svg
        width={`${maxSize * STEP_W - INNER_MARGIN * 2}px`}
        height="100%"
        style={{ left: -1 * (inicio - 1) * STEP_W + (inicio > 1 ? INNER_MARGIN : 0) }}
      >
        <rect
          className={styles.shadow}
          x={`${sl}px`}
          y="0"
          width={`${sr - sl}px`}
          height={`${height}px`}
          rx={styles.borderRadius}
          ry={styles.borderRadius}
        />

        <rect
          className={styles.target}
          x={`${tl + border / 2}px`}
          y={`${border / 2}px`}
          width={`${tr - tl - border}px`}
          height={`${height - border}`}
          rx={styles.borderRadius}
          ry={styles.borderRadius}
        />
      </svg>
    );
  };

  const actions = (
    <div className={styles.actions} style={{ backgroundColor: style.backgroundColor }}>
      <IconButton
        icon={Pencil}
        size={25}
        rate={0.5}
        type="invert"
        onClick={props.onEdit}
      />
    </div>
  );

  return (
    <div
      ref={setRef}
      className={classes(
        styles.activity,
        styles[props.transit?.type || ''],
        editable ? '' : styles.readonly
      )}
      style={style}
    >
      {editable && (
        <Controls
          actions={actions}
          visible={!props.active}
          reference={ref}
          offset={fim === maxSize ? [0, 6] : [0, 2]}
          delay={[animDuration * 1000, 0]}
        />
      )}

      <FieldSet name={name}>
        <HiddenField name="id" />
        <HiddenField name="cor" />
        <HiddenField name="mesInicio" />
        <HiddenField name="mesFim" />
        <HiddenField name="titulo" />
      </FieldSet>

      <div
        onMouseEnter={() => (hover.current = 1)}
        onMouseLeave={() => (hover.current = 0)}
      />

      <Tippy
        className={styles.popup}
        content={titulo}
        placement="top-start"
        animation="perspective"
        offset={[0, 5]}
        delay={[100, 0]}
        hideOnClick={false}
      >
        <span
          onMouseEnter={() => (hover.current = 3)}
          onMouseLeave={() => (hover.current = 0)}
          style={fim === maxSize ? { paddingRight: 1 } : undefined}
        >
          {titulo}
        </span>
      </Tippy>

      <div
        onMouseEnter={() => (hover.current = 2)}
        onMouseLeave={() => (hover.current = 0)}
      />

      {state.shadow ? renderShadow() : null}
    </div>
  );
};

export default Activity;

function computeBounds(mesInicio: number, mesFim: number, maxSize: number) {
  const fim = Math.max(Math.min(mesFim, maxSize), 1);
  const inicio = Math.max(Math.min(mesInicio, fim), 1);

  return {
    inicio,
    fim,
    left: Math.max((inicio - 1) * STEP_W - INNER_MARGIN, 0),
    width:
      (fim - inicio + 1) * STEP_W -
      (inicio === 1 ? INNER_MARGIN : 0) -
      (fim === maxSize ? INNER_MARGIN : 0),
  };
}
