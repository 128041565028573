import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';

import { InputProps, IntervalFilter } from '../types';
import { classes, renderComponent } from 'utils/components';

import { Chart, Search, X } from 'components/icons';
import { useTranslationX } from 'i18n';
import { call } from 'utils';

import styles from './ValueRange.module.scss';
import { getMasked, inputMask } from 'utils/inputMask';
import { IconButton } from 'components/elements';

export type Props = {
  align?: 'left' | 'center' | 'right';
  adornment?: string | { left?: string; right?: string };
  icon?: React.ElementType;
  type?: 'currency' | 'number';
  pattern?: string;
};

type ModProps = Omit<InputProps, 'value'> & {
  value?: IntervalFilter;
};

const ValueRange = forwardRef<HTMLDivElement, Props & ModProps>((props, ref) => {
  const {
    control,
    inputProps,
    align = 'left',
    adornment,
    type,
    value,
    pattern = 'currency',
  } = props;

  const { onChange, onBlur, onFocus, ...rest } = inputProps;

  const [selected, setSelected] = useState<IntervalFilter | undefined>(value);
  const [expanded, setExpanded] = useState(false);

  const { tx } = useTranslationX('components', 'translation');

  const anchorRef = useRef<HTMLDivElement>();

  useEffect(() => {
    setSelected(value);
  }, [value]);

  function attachRef(el: HTMLDivElement) {
    anchorRef.current = el;

    if (typeof ref === 'function') {
      ref(el);
    } else if (ref) {
      ref.current = el;
    }
  }

  function adornmentLeft() {
    let val;

    if (adornment) {
      if (typeof adornment === 'string') {
        val = adornment;
      } else if (adornment.left) {
        val = adornment.left;
      }
    }

    return val ? <span className={styles.adornment}>{val}</span> : null;
  }

  function adornmentRight() {
    return typeof adornment === 'object' && adornment.right ? (
      <span className={styles.adornment}>{adornment.right}</span>
    ) : null;
  }

  const fromProps = {
    ...inputProps,
    onChange: (input: number) => {
      setSelected(range => {
        return {
          ...range,
          from: isNaN(Number(input)) ? undefined : Number(input),
        };
      });
    },
  };

  const toProps = {
    ...inputProps,
    onChange: (input: number) => {
      setSelected(range => {
        return {
          ...range,
          to: isNaN(Number(input)) ? undefined : Number(input),
        };
      });
    },
  };

  const validateRange = useCallback(() => {
    if (selected?.from !== undefined && selected?.to !== undefined) {
      if (selected.from !== 0 && selected.to !== 0 && selected.from !== selected.to) {
        if (selected.to < selected.from || selected.from > selected.to) {
          return true;
        }
      }
    }
  }, [selected]);

  const renderInput = (props: object, value: any = 0) => {
    return (
      <div className={styles.inputContainer}>
        <div className={classes(styles.textInput, control.active ? styles.active : '')}>
          <input
            {...inputMask(
              {
                pattern,
              },
              props,
              value
            )}
            placeholder="0,00"
            name="min"
            className={styles.input}
            type="text"
            ref={ref}
            maxLength={15}
            style={{ textAlign: 'left' }}
          />
        </div>
      </div>
    );
  };
  const renderPopupContent = (props: object) => {
    return (
      <div className={styles.valueRangeBox} {...props} tabIndex={0}>
        <h3>{tx('value_range.title')}</h3>
        <div className={styles.inputs}>
          {renderInput(fromProps, selected?.from)}
          <span>{tx('value_range.center_label')}</span>
          {renderInput(toProps, selected?.to)}
          <IconButton
            shape="circle"
            size={25}
            onClick={() => !validateRange() && onChange(selected)}
            icon={Search}
          />
        </div>
        {validateRange() && <div className={styles.error}>{tx('value_range.error')}</div>}
      </div>
    );
  };

  const renderPopup = () =>
    control.ref.current && expanded ? (
      <Tippy
        appendTo={() => document.body}
        placement="bottom-start"
        offset={[0, 2]}
        render={renderPopupContent}
        interactive={true}
        visible={expanded}
        reference={control.ref}
        onClickOutside={() => {
          setExpanded(false);
          call(onBlur);
        }}
        popperOptions={{
          modifiers: [
            {
              name: 'preventOverflow',
              enabled: false,
            },
          ],
        }}
        aria={{ content: null, expanded: false }}
      />
    ) : null;

  function handleFocus(event: React.FocusEvent) {
    if (anchorRef.current?.contains(event.target) && !expanded) {
      call(event.type === 'blur' ? onBlur : onFocus);
    }
  }

  const handlers = {
    onClick: () => setExpanded(expanded => !expanded),
    onBlur: handleFocus,
    onFocus: handleFocus,
  };

  const getValueOrPlaceHolder = (value?: any) => {
    return value !== undefined
      ? type === 'currency'
        ? getMasked(Number(value).toFixed(2), { pattern: 'currency' })
        : Number(value).toFixed(0)
      : '...';
  };

  return (
    <div
      className={classes(
        styles.valueRange,
        styles[control.type || ''],
        control.active ? styles.active : ''
      )}
    >
      <div className={styles.icon}>
        {!selected ? (
          renderComponent(Chart, {
            size: 1.2,
          })
        ) : (
          <Tippy
            className={classes(styles.popup)}
            content={tx('value_range.clear')}
            animation="perspective"
            touch={false}
          >
            <div
              className={styles.popup}
              onClick={() => {
                setSelected(undefined);
                onChange(undefined);
              }}
            >
              {renderComponent(X, {
                size: 1.4,
              })}
            </div>
          </Tippy>
        )}
      </div>
      {adornmentLeft()}

      <div className={styles.input} ref={attachRef}>
        <div
          {...rest}
          aria-haspopup="listbox"
          aria-expanded={expanded}
          {...handlers}
          role="button"
          style={{ textAlign: align }}
          className={classes(
            styles.content,
            selected === undefined ? styles.placeholder : null,
            styles.range
          )}
        >
          <span>
            {tx('value_range.from')}
            <span style={{ marginLeft: '8px' }}>
              {getValueOrPlaceHolder((selected as IntervalFilter)?.from)}
            </span>
          </span>
          <span>
            {tx('value_range.to')}
            <span style={{ marginLeft: '8px' }}>
              {getValueOrPlaceHolder((selected as IntervalFilter)?.to)}
            </span>
          </span>
        </div>
        {renderPopup()}
      </div>

      {adornmentRight()}
    </div>
  );
});

ValueRange.displayName = 'ValueRange';
export default ValueRange;
