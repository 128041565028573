import React from 'react';

import { useTranslationX } from 'i18n';
import { Grid, Row } from 'components/containers';
import {
  DatePicker,
  FieldSet,
  HiddenField,
  Localidades,
  Select,
  TextField,
} from 'components/form';

const Personal = () => {
  const { tx } = useTranslationX('personal', 'register');

  return (
    <Grid minWidth="80%">
      <FieldSet name="pessoa">
        <HiddenField name="id" />

        <Row>
          <TextField name="cpf" label={tx('id')} />
          <TextField name="nome" label={tx('name')} />
          <Select
            name="genero"
            label={tx('gender')}
            metadata={{
              options: [
                { text: tx('genero.male'), value: 'M' },
                { text: tx('genero.female'), value: 'F' },
                { text: tx('genero.other'), value: 'O' },
              ],
              rules: {
                required: true,
              },
            }}
          />
          <DatePicker
            name="dataNascimento"
            label={tx('dataNascimento')}
            showTime={false}
          />
        </Row>

        <Row width={[1, 1, 1]}>
          <TextField name="rg" label={tx('rg')} />
          <TextField name="rgEmissor" label={tx('orgao')} />
          <Select name="rgUf" label={tx('uf')} />
        </Row>

        <Row width={[2, 3]}>
          <TextField name="telefone" label={tx('phone')} />
          <TextField name="email" label={tx('email')} />
        </Row>
      </FieldSet>
      <Localidades state="rgUf" prefix="pessoa" />
    </Grid>
  );
};

export default Personal;
