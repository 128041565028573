import React from 'react';

const Company = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 15.2 13.2">
    <g transform="translate(-64.5 -107)">
      <g transform="matrix(.265 0 0 .265 64.4 107)" fill="currentColor">
        <path
          transform="translate(.399)"
          d="m8.9 4.57h-3.37v7.6h-5.53v37.8h14.4v-37.8h-5.53zm0.915 36.9h-5.2v-3.38h5.2v3.38zm0-6.76h-5.2v-3.38h5.2v3.38zm0-6.76h-5.2v-3.38h5.2v3.38zm0-10.1v3.38h-5.2v-3.38h5.2zm42.2-5.66v-7.6h-3.37v7.6h-5.53v37.8h14.4v-37.8zm0.915 29.3h-5.2v-3.38h5.2zm0-6.76h-5.2v-3.38h5.2v3.38zm0-6.76h-5.2v-3.38h5.2zm0-6.76h-5.2v-3.38h5.2zm-25.9 23.6h3.38v5.07h-3.38z"
        />
        <path
          transform="translate(.399)"
          d="m17.8 49.9h5.92v-8.45h10.1v8.45h5.92v-49.8l-22 5.18v44.7zm12.7-38.9h5.2v3.38h-5.2zm0 6.76h5.2v3.38h-5.2zm0 6.76h5.2v3.38h-5.2zm0 6.76h5.2v3.38h-5.2v-3.38zm-8.58-20.3h5.2v3.38h-5.2zm0 6.76h5.2v3.38h-5.2zm0 6.76h5.2v3.38h-5.2zm0 6.76h5.2v3.38h-5.2v-3.38z"
        />
      </g>
    </g>
  </svg>
);

export default Company;
