import React from 'react';

import Field, { Props } from '../Field/Field';
import { Autocomplete as Input } from 'components/inputs';
import { Props as InputProps } from 'components/inputs/Autocomplete/Autocomplete';

const Autocomplete: React.FC<Props & InputProps> = props => <Field {...props} component={Input} />;

Autocomplete.displayName = 'AutocompleteField';
export default Autocomplete;
