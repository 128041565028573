import React from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { DashBoardConfig } from '../types';

import styles from './Content.module.scss';

type Props = {
  config: DashBoardConfig;
};

const Content = ({ config: { sideMenu, topMenu, gadgets, footer } }: Props) => {
  const { path } = useRouteMatch();

  const render = () => {
    const routes = [];

    for (const { key, component, path: subpath } of sideMenu) {
      if (key !== 'home') {
        routes.push(
          <Route key={key} path={`${path}/${subpath || key}`} component={component} />
        );
      } else {
        routes.unshift(<Route key={key} exact path={path} component={component} />);
      }
    }

    for (const group of [topMenu, gadgets, footer]) {
      if (group) {
        for (const { key, component, path: subpath } of group) {
          routes.push(
            <Route key={key} path={`${path}/${subpath || key}`} component={component} />
          );
        }
      }
    }

    return routes;
  };

  return (
    <div className={styles.content}>
      <div className={styles.wrapper}>
        <Switch>{render()}</Switch>
      </div>
    </div>
  );
};

export default Content;
