import React from 'react';

import { useFetch } from 'apis';
import { useTranslationX } from 'i18n';
import { Grid } from 'components/containers';
import { Loader, WrapperCard } from 'components/elements';
import { SectionProps } from '../ProposalEditor/ProposalEditor';
import { Counter, FieldSet, Form, HiddenField, TextArea } from 'components/form';

import styles from './FormDescription.module.scss';

type Caracterizacao = {
  id: number;
  pergunta: string;
};

const FormDescription = ({ state: { id, editable }, formProps }: SectionProps) => {
  const { data: perguntas } = useFetch<Caracterizacao[]>('/formCaracterizacao');
  const { tx } = useTranslationX('form-description', 'proposal');

  if (!perguntas) {
    return <Loader />;
  }

  return (
    <div className={styles.wrapper}>
      <Form baseUrl={`/proposta/${id}/caracterizacao`} {...formProps}>
        <WrapperCard padding="20px 15px 15px">
          {Array.isArray(perguntas) &&
            perguntas.map(({ pergunta, id }, index) => (
              <Grid className={styles.content} minWidth={400} maxWidth={900} key={id}>
                <FieldSet name="formCaracterizacao" index={index}>
                  <HiddenField name="id" />
                  <TextArea
                    name="resposta"
                    label={pergunta}
                    minRows={7}
                    maxRows={7}
                    placeholder={tx('describe')}
                    style={{
                      header: { label: { fontSize: 14 } },
                      root: { margin: 0, marginTop: 10 },
                    }}
                  />

                  {editable && <Counter target="resposta" />}
                </FieldSet>
              </Grid>
            ))}
        </WrapperCard>
      </Form>
    </div>
  );
};

export default FormDescription;
