import React from 'react';

import { call } from 'utils';
import { CheckBoxFrame, CheckBox, CheckBoxAlt } from 'components/icons';
import { classes, renderComponent } from 'utils/components';

import styles from './StateCheck.module.scss';

const ICONS = [CheckBoxFrame, CheckBox, CheckBoxAlt];

type Props = {
  state: number;
  triple?: boolean;
  onChange?: (state: number) => void;
  editable?: boolean;
};

const StateCheck = ({ state, onChange, editable = true, triple = true }: Props) => {
  const onClick = () => {
    let newStateValue = state === 0 ? 1 : 0;
    if (triple) {
      newStateValue = (state + 1) % 3;
    }
    call(onChange, newStateValue);
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === ' ') {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <div
      className={classes(styles.tsCheck, !editable ? styles.disabled : '')}
      onClick={editable ? onClick : undefined}
      onKeyDown={editable ? onKeyDown : undefined}
      data-state={state}
    >
      <div className={styles.box} tabIndex={0} role="checkbox" aria-checked={!!state}>
        <div tabIndex={-1}>
          {ICONS.map((el, index) => (
            <div className={styles.icon} key={index}>
              {renderComponent(el)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StateCheck;
