import React from 'react';
import { useTranslationX } from 'i18n';
import { getMasked } from 'utils/inputMask';
import { DistItem } from './types';

import styles from './PartsTable.module.scss';

type Props = {
  data: DistItem[] | undefined;
};

const PartsTable = ({ data }: Props) => {
  const { t, tx } = useTranslationX('proposal:budget-description', [
    'translation',
    'proposal',
  ]);

  if (!Array.isArray(data)) {
    return null;
  }

  const valueLabel = `${tx('value')} (${t('currency')})`;

  const h1 = [];
  const h2 = [];
  const b = [];

  for (const { id, nome, total, percent } of data) {
    h1.push(
      <th key={id} colSpan={2}>
        {nome}
      </th>
    );

    h2.push(<td key={id}>{valueLabel}</td>, <td key={`${id}p`}>%</td>);

    b.push(
      <td key={id} style={{ textAlign: 'right' }}>
        {<div>{getMasked(total.toFixed(2), { pattern: 'currency' })}</div>}
      </td>,
      <td key={`${id}p`}>
        <div>{percent.toFixed(2)}</div>
      </td>
    );
  }

  return (
    <div className={styles.row}>
      <table className={styles.partsTable}>
        <thead>
          <tr>{h1}</tr>
          <tr>{h2}</tr>
        </thead>
        <tbody>
          <tr>{b}</tr>
        </tbody>
      </table>
    </div>
  );
};

export default PartsTable;
