import { useState, useEffect } from 'react';

const useDelay = (value: number) => {
  const [delay, setDelay] = useState(value);

  useEffect(() => {
    if (value > 0) {
      setDelay(value);
      const timer = setTimeout(() => setDelay(0), value);

      return () => clearTimeout(timer);
    }
  }, [value]);

  return delay;
};

export default useDelay;
