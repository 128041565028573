import React from 'react';

const Bold = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none">
    <path
      d="m16.6 11.7c1.39-0.957 2.36-2.53 2.36-3.99 0-3.23-2.5-5.71-5.71-5.71h-7.5c-0.786 0-1.43 0.643-1.43 1.43v17.1c0 0.786 0.643 1.43 1.43 1.43h8.26c2.96 0 5.66-2.41 5.67-5.39 0.0143-2.19-1.21-4.06-3.07-4.91zm-8-6.13h4.29c1.19 0 2.14 0.957 2.14 2.14 0 1.19-0.957 2.14-2.14 2.14h-4.29zm0 12.9h5c1.19 0 2.14-0.957 2.14-2.14s-0.957-2.14-2.14-2.14h-5z"
      clipRule="evenodd"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default Bold;
