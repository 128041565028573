import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useTranslation } from 'i18n';
import { Logo } from 'components/elements';
import { Check, Padlock } from 'components/icons';
import { RegisterState } from '../types';

import banner from 'assets/images/cadastro_banner.png';
import styles from './Stepper.module.scss';

const Stepper = ({ state }: { state: Required<RegisterState> }) => {
  const { t } = useTranslation('register');
  const { sections, step } = state;

  const location = useLocation<{ isIFCE: boolean }>();

  const isIFCE = location.state.isIFCE;

  return (
    <div className={styles.stepper}>
      <div style={{ backgroundImage: `url(${banner})` }}>
        <div className={styles.header}>
          <Link to="/">
            <Logo color1="#fffdfd" color2="#fffdfd" />
          </Link>

          <p>{t('steps.title')}</p>
          <p>{t(`steps.subtitle${isIFCE ? 1 : 2}`)}</p>
        </div>

        <div className={styles.body}>
          <div className={styles.steps}>
            <div
              className={styles.slider}
              style={{
                top: step * 60,
                visibility: sections.length ? 'visible' : 'hidden',
              }}
            />

            {sections.map((section, index) => (
              <div
                className={styles.step}
                key={section.name}
                style={{ opacity: section.visited ? 1 : 0.3 }}
              >
                <div>
                  {section.locked || section.done ? (
                    <Check size={1.3} />
                  ) : (
                    `0${index + 1}`
                  )}
                </div>
                {section.locked ? (
                  <i className={styles.lock}>
                    <Padlock size={0.8} />
                  </i>
                ) : null}
                <span>{t(`${section.name}.title`)}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stepper;
