import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Loader, SpeedometerProgress, TotalCard, WrapperCard } from 'components/elements';
import { useTranslation, useTranslationX } from 'i18n';
import { useFetch } from 'apis';
import { CounterpartState } from '../Dashboard/types';
import { getMasked } from 'utils/inputMask';

import styles from './CounterpartCard.module.scss';

type CounterpartEvolution = {
  comprovados: number;
  valorTotal: number;
  duracao: number;
  inicioRelativo: string;
  porcentagem: number;
  porcentagemPrevisto: number;
  total: number;
};

const CounterPartCardGroup = ({ state }: { state: CounterpartState }) => {
  const { tx } = useTranslationX('contrapartida', 'project');
  const { t } = useTranslation();

  const location = useLocation();

  const pathnames = location.pathname.split('/');
  const projectId = pathnames[pathnames.length - 2];
  const { data, reload } = useFetch<CounterpartEvolution>(
    `projeto/${projectId}/contrapartidas/evolucao`
  );

  useEffect(() => {
    reload();
  }, [state.lastUpdate, reload]);

  if (!data) {
    return <Loader />;
  }

  const { porcentagem, total, porcentagemPrevisto } = data;

  return (
    <div className={styles.counterpartCard}>
      <h1 className={styles.header}>{tx('title')}</h1>
      <TotalCard value={total} label={tx('totalValue')} />
      <div className={styles.graphsArea}>
        <WrapperCard padding="21px" maxWidth="600px">
          <div className={styles.graph}>
            <SpeedometerProgress
              value={porcentagem}
              colorGradient={['#c98cff', '#e1bfff']}
              radius={140}
            />

            <span>{tx('proven')}</span>
            <p>{tx('rhComprovedValue')}</p>
            <p>
              <strong>
                {`${t('currency')} ${getMasked(((porcentagem / 100) * total).toFixed(2), {
                  pattern: 'currency',
                })}`}
                {/* {t('currency')} {((porcentagem / 100) * total).toFixed(2)} */}
              </strong>
            </p>
          </div>
        </WrapperCard>
        <WrapperCard padding="21px" maxWidth="600px">
          <div className={styles.graph}>
            <SpeedometerProgress
              value={porcentagemPrevisto}
              colorGradient={['#b0ff8c', '#00912b']}
              radius={140}
            />

            <span>{tx('previsto')}</span>
            <p>{tx('rhPrevistoValue')}</p>
            <p>
              <strong>
                {`${t('currency')} ${getMasked(
                  ((porcentagemPrevisto / 100) * total).toFixed(2),
                  {
                    pattern: 'currency',
                  }
                )}`}
              </strong>
            </p>
          </div>
        </WrapperCard>
      </div>
    </div>
  );
};

export default CounterPartCardGroup;
