import React from 'react';

const Contributor = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24">
    <g transform="matrix(1.16 0 0 1.17 -70.5 -122)" strokeWidth=".858">
      <g
        transform="matrix(.265 0 0 .265 62.1 106)"
        fill="currentColor"
        strokeWidth=".858"
      >
        <path
          transform="translate(.558)"
          d="m32.9 8.21c1.01 0 1.83-0.887 1.83-1.98v-4.24c0-1.09-0.819-1.98-1.83-1.98-1.01 0-1.83 0.887-1.83 1.98v4.24c0 1.09 0.818 1.98 1.83 1.98zm-20.9 15.5h4.25c1.1 0 1.98-0.817 1.98-1.82s-0.89-1.82-1.98-1.82h-4.25c-1.1 0-1.99 0.816-1.99 1.82s0.89 1.82 1.99 1.82zm34.6-1.82c0 1.01 0.89 1.82 1.98 1.82h4.25c1.1 0 1.98-0.817 1.98-1.82s-0.889-1.82-1.98-1.82h-4.25c-1.1 0-1.98 0.816-1.98 1.82zm-26.9-9.65c0.71 0.709 1.86 0.71 2.57 0 0.711-0.709 0.711-1.86 0-2.57l-2.76-2.75c-0.71-0.71-1.86-0.71-2.58-1e-3 -0.71 0.71-0.71 1.86 0 2.57l2.76 2.75zm25.4 0 2.76-2.75c0.71-0.71 0.71-1.86 0-2.57-0.711-0.708-1.86-0.708-2.58 1e-3l-2.76 2.75c-0.71 0.709-0.71 1.86 0 2.57 0.711 0.709 1.86 0.71 2.58 0zm-31.4 37c4.54 0 8.22-3.68 8.22-8.21s-3.69-8.2-8.22-8.2-8.22 3.68-8.22 8.2 3.69 8.21 8.22 8.21z"
        />
        <path
          transform="translate(.558)"
          d="m14.2 48.3c-7.82 0-14.2 6.48-14.2 14.5 0 1.08 0.854 1.95 1.91 1.95h24.5c1.05 0 1.91-0.872 1.91-1.95 0-7.99-6.34-14.5-14.2-14.5zm28.8-7.29c0 4.52 3.69 8.2 8.22 8.2s8.22-3.68 8.22-8.21-3.69-8.2-8.22-8.2-8.22 3.68-8.22 8.2z"
        />
        <path
          transform="translate(.558)"
          d="m50.7 48.3c-7.82 0-14.2 6.48-14.2 14.5 0 1.08 0.854 1.95 1.91 1.95h24.5c1.05 0 1.91-0.872 1.91-1.95 0-7.99-6.34-14.5-14.2-14.5zm-25.5-18.4c1.05 1.01 1.74 2.35 1.96 3.78h10.6c0.22-1.43 0.906-2.76 1.96-3.78 2.11-2 3.27-4.7 3.27-7.61 0-5.79-4.71-10.5-10.5-10.5s-10.5 4.71-10.5 10.5c0 2.87 1.19 5.64 3.28 7.6zm2.2 7.43c0.46 2.6 2.54 4.56 5.03 4.56 2.51 0 4.57-1.98 5.03-4.56z"
        />
      </g>
    </g>
  </svg>
);

export default Contributor;
