import React from 'react';
import styles from './PlaceHolder.module.scss';

type Props = { title: string; bgColor?: string; color?: string };

const PlaceHolder = ({ title, bgColor, color }: Props) => {
  return (
    <div className={styles.activity} style={{ backgroundColor: bgColor, color }}>
      <span>{title}</span>
    </div>
  );
};

export default PlaceHolder;
