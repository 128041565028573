import React from 'react';

import { useTranslationX } from 'i18n';
import { Password } from 'components/form';

import styles from './ResetPasswordToken.module.scss';

const ResetPasswordToken = () => {
  const { tx } = useTranslationX('login.reset.form');

  return (
    <div className={styles.reset}>
      <span className={styles.title}>{tx('title')}</span>

      <Password name="newPassword" label={tx('password')} />
      <Password name="newPasswordConfirm" label={tx('confirm')} omit />
    </div>
  );
};

export default ResetPasswordToken;
