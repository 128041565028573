import React, { useEffect, useMemo } from 'react';

import { TotalLabel } from 'pages/proposal';
import { useTranslationX } from 'i18n';

import styles from './Financial.module.scss';
import { TableData } from 'components/containers';
import { useForm } from 'components/form';

type Props = {
  parteId: number;
  fonteRecursoStatus: number;
};

const OperationSupport = (props: Props) => {
  const { tx } = useTranslationX('financial', 'update_wp');
  const { parteId, fonteRecursoStatus } = props;
  const groupBy = useMemo(
    (): (string | number)[][] => [
      ['fonteRecurso', Number(parteId)],
      ['fonteRecursoStatus', Number(fonteRecursoStatus)],
    ],
    [parteId, fonteRecursoStatus]
  );

  const form = useForm();

  useEffect(() => {
    form.setEditable(false);
  }, [form]);

  return (
    <div
      className={styles.financial}
      style={{
        width: '55vw',
      }}
    >
      <TotalLabel
        tableName="suportes"
        columnName="suporteOperacional"
        groupBy={groupBy}
      />

      <div className={styles.table}>
        <TableData
          name="suportes"
          hiddenFields={['fonteRecursoStatus']}
          groupBy={groupBy}
          textSize="small"
          style={{ table: { width: '50vw' } }}
          config={[{ title: tx('support'), field: 'suporteOperacional', align: 'left' }]}
          fixedList
        />
      </div>
    </div>
  );
};

export default OperationSupport;
