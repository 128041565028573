import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { HorizontalBarChart } from 'components/elements';
import { Handshake } from 'components/icons';
import { RequestConfig, useRequest } from 'apis';
import { useTranslationX } from 'i18n';
import { Form, MonthYearPicker, useForm, useFormObserver } from 'components/form';
import { readAttr } from 'utils/object';
import { GraphItem } from 'components/elements/HorizontalBarChart/HorizontalBarChart';

type ResponseType = {
  aprovadas: number;
  devolvidas: number;
  reprovadas: number;
  contratadas: number;
  total: number;
};

type Props = {
  url: string;
};

const GRAPHS = {
  aprovadas: {
    color: '#FB8832',
    label: 'approved',
  },
  devolvidas: {
    color: '#574696',
    label: 'returned',
  },
  reprovadas: {
    color: '#007AFF',
    label: 'reproved',
  },
  contratadas: {
    color: '#60abfc',
    label: 'contracted',
  },
};

const ProposalTotalChart: React.FC<Props> = props => {
  const request = useRequest();
  const { tx } = useTranslationX('dashboard.proposal.charts.totals');
  const [responseData, setResponseData] = useState<ResponseType>();
  const [filter, setFilter] = useState<{ month: number; year: number }>({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });

  const items = responseData
    ? (Object.entries(GRAPHS)
        .filter(([key, _]) => readAttr(key, responseData) !== undefined)
        .filter(([key, _]) => Number(readAttr(key, responseData)) > 0)
        .map(([key, value]) => {
          return {
            label: tx(`items.${value.label}`),
            color: value.color,
            value: readAttr(key, responseData),
          } as GraphItem;
        }) as GraphItem[])
    : undefined;

  useEffect(() => {
    if (filter !== undefined) {
      const options: RequestConfig<any> = {
        url: props.url,
        onSuccess: value => setResponseData(value),
        params: filter ? filter : {},
      };

      const source = request<any>(options);
      return () => source.cancel();
    }
  }, [request, filter, props.url]);

  const onFilterChange = useCallback(
    (value: { requestBody: object; section: string }) => {
      const requestBody: any = value.requestBody;
      const now = new Date();
      const monthYear = requestBody.monthYear || {
        month: now.getMonth() + 1,
        year: now.getFullYear(),
      };
      if (_.isEqual(filter, monthYear)) {
        return;
      }
      setFilter(monthYear);
    },
    [filter]
  );

  return (
    <HorizontalBarChart
      width="30%"
      icon={Handshake}
      title={tx(`title`)}
      graphs={items}
      filter={
        <Form onSubmit={onFilterChange}>
          <MonthPickerFilters />
        </Form>
      }
    />
  );
};

const MonthPickerFilters = React.memo(() => {
  const form = useForm();

  const observer = useFormObserver(
    {
      field: 'monthYear',
      prop: 'value',
    },
    false,
    false,
    false
  );

  useEffect(() => {
    if (
      observer !== undefined &&
      observer.data !== undefined &&
      observer.data.value !== null &&
      observer.data.controlState?.modified
    ) {
      form.submit();
    }
  }, [form, observer]);

  return (
    <MonthYearPicker
      name="monthYear"
      theme="sneaky"
      value={{ month: new Date().getMonth() + 1, year: new Date().getFullYear() }}
    />
  );
});

export default ProposalTotalChart;
