import React from 'react';

const Email = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none">
    <path
      fill="currentColor"
      d="m3.82 5.29c0.476 0.363 7.09 5.57 7.33 5.76 0.279 0.181 0.581 0.275 0.887 0.275s0.608-0.0938 0.887-0.275c0.247-0.187 6.86-5.39 7.33-5.76 0.178-0.131 0.476-0.369 0.541-0.637 0.113-0.475-0.0087-0.656-0.489-0.656h-16.5c-0.48 0-0.601 0.187-0.489 0.656 0.0646 0.274 0.363 0.506 0.541 0.637z"
    />
    <path
      fill="currentColor"
      d="m20.6 5.57c-0.357 0.262-3.54 3.54-5.65 5.51l3.56 5.78c0.0864 0.125 0.126 0.275 0.0793 0.35-0.0464 0.075-0.165 0.0315-0.255-0.0876l-4.27-5.2c-0.645 0.6-1.1 1.01-1.18 1.08-0.279 0.181-0.581 0.275-0.887 0.275-0.306 0-0.608-0.0938-0.887-0.275-0.082-0.0624-0.532-0.475-1.18-1.08l-4.27 5.2c-0.0864 0.125-0.203 0.163-0.255 0.0876-0.0519-0.075-0.0131-0.225 0.0737-0.35l3.55-5.78c-2.11-1.97-5.33-5.24-5.68-5.51-0.381-0.281-0.402 0.0498-0.402 0.306v12.8c0.0538 0.344 0.181 0.655 0.363 0.889 0.182 0.234 0.411 0.38 0.654 0.417h16.1c0.232-0.0415 0.449-0.192 0.617-0.427 0.168-0.236 0.278-0.544 0.313-0.879v-12.8c-0.0015-0.262 0.0246-0.588-0.361-0.306z"
    />
  </svg>
);

export default Email;
