import React, { useEffect, useState } from 'react';
import { useFormFieldObserver } from 'components/form';
import { Issue } from 'components/form/types';
import { BiCommentError as ErrorIcon } from 'react-icons/bi';
import styles from './InputControlAdapter.module.scss';
import { classes } from '../../../../utils/components';

export type Props = {
  field: string;
};

const InputControlAdapter = (props: Props) => {
  const observer = useFormFieldObserver(props.field);
  const issueObserver = observer.data?.issue;
  const [issue, setIssue] = useState<Issue | undefined>();

  useEffect(() => {
    setIssue(issueObserver);
  }, [issueObserver]);

  return (
    <div className={classes(styles.body, issue?.rule !== 'rejected' ? styles.noPadding : null)}>
      {issue !== undefined &&
        (issue.rule === 'rejected') && (
          <div className={styles.error}>
            <ErrorIcon />
            <span>{issue.message}</span>
          </div>
        )}
    </div>
  );
};

export default InputControlAdapter;
