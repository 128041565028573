import React, { Children, CSSProperties, ReactNode } from 'react';

import styles from './ZStack.module.scss';

type Props = { selected?: number; style?: CSSProperties; children?: ReactNode };

const ZStack: React.FC<Props> = ({ selected = 0, style, children }) => {
  return (
    <div className={styles.zstack} style={style}>
      {Children.map(children, (el, index) => (
        <div className={index === selected ? styles.selected : ''}>{el}</div>
      ))}
    </div>
  );
};

export default ZStack;
