import React from 'react';

const XCircle = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 22 23" aria-hidden="true" focusable="false">
    <path
      fill="currentColor"
      d="M11 22.8125C13.1756 22.8125 15.3023 22.1674 17.1113 20.9587C18.9202 19.75 20.3301 18.032 21.1627 16.022C21.9952 14.012 22.2131 11.8003 21.7886 9.66651C21.3642 7.53272 20.3166 5.57271 18.7782 4.03433C17.2398 2.49595 15.2798 1.4483 13.146 1.02387C11.0122 0.599429 8.80047 0.817266 6.79048 1.64983C4.78049 2.48239 3.06253 3.89229 1.85383 5.70123C0.645139 7.51017 0 9.63691 0 11.8125C0 14.7299 1.15893 17.5278 3.22183 19.5907C5.28473 21.6536 8.08262 22.8125 11 22.8125ZM6.29 8.5225C6.1017 8.3342 5.99591 8.07881 5.99591 7.8125C5.99591 7.5462 6.1017 7.29081 6.29 7.1025C6.4783 6.9142 6.7337 6.80841 7 6.80841C7.2663 6.80841 7.5217 6.9142 7.71 7.1025L11 10.4025L14.29 7.1125C14.4813 6.94868 14.7274 6.86307 14.9791 6.87279C15.2307 6.88251 15.4695 6.98685 15.6476 7.16494C15.8257 7.34304 15.93 7.58178 15.9397 7.83345C15.9494 8.08513 15.8638 8.3312 15.7 8.5225L12.41 11.8125L15.7 15.1025C15.8047 15.1922 15.8897 15.3025 15.9497 15.4265C16.0097 15.5506 16.0435 15.6857 16.0488 15.8235C16.0541 15.9612 16.0309 16.0985 15.9806 16.2268C15.9304 16.3552 15.8541 16.4717 15.7567 16.5692C15.6592 16.6666 15.5426 16.7429 15.4143 16.7931C15.286 16.8434 15.1487 16.8666 15.0109 16.8613C14.8732 16.856 14.7381 16.8223 14.614 16.7622C14.49 16.7022 14.3796 16.6172 14.29 16.5125L11 13.2225L7.71 16.5225C7.5187 16.6863 7.27262 16.7719 7.02095 16.7622C6.76927 16.7525 6.53053 16.6482 6.35244 16.4701C6.17434 16.292 6.07001 16.0532 6.06029 15.8016C6.05057 15.5499 6.13617 15.3038 6.3 15.1125L9.59 11.8125L6.29 8.5225Z"
    />
  </svg>
);

export default XCircle;
