import React, { useReducer, useState } from 'react';

import { Form, useForm } from 'components/form';
import { Row, Grid as RowGrid, ScrollPanel } from 'components/containers';

import { SectionProps } from 'pages/project/update-wp/UpdateWpEditor';
import { Action, Entry, Node, State } from './types';

import Grid from './Grid';
import EditDialog from './EditDialog';
import { useTranslationX } from 'i18n';
import { Switch } from 'components/elements';

import styles from './Eap.module.scss';

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_MODAL':
      return { modal: action.payload || undefined };

    default:
      return state;
  }
};

const Eap = ({ state: { id, editable }, formProps }: SectionProps) => {
  return (
    <Form baseUrl={`/projeto/${id}/alteracao-pt/eap`} hotReload {...formProps}>
      <FormContent editable={editable} />
    </Form>
  );
};

const FormContent = ({ editable }: { editable: boolean }) => {
  const { tx } = useTranslationX('eap', 'update_wp');

  const [state, dispatch] = useReducer(reducer, {});

  const [showLastVersion, setShowLastVersion] = useState(true);

  const form = useForm();
  const eap = form.getFieldValue('eap') as Entry[];
  const sigla = form.getFieldValue('sigla') as string;

  const lastEap = form.getLastFieldValue('eap') as Entry[];
  const lastSigla = form.getLastFieldValue('sigla') as string;

  const tree: Node[] = Array.isArray(eap) ? buildTree(eap) : [];
  const lastTree: Node[] = Array.isArray(lastEap) ? buildTree(lastEap) : [];

  const hasLastValues = form.showDiff && form.hasLastValues();

  const displayLastEap = hasLastValues && lastEap && lastSigla;
  const displayLastGrid = displayLastEap && showLastVersion;

  return (
    <ScrollPanel>
      <RowGrid>
        {displayLastEap && (
          <Row>
            <Switch
              on={showLastVersion}
              onChange={setShowLastVersion}
              label={tx('showLastVersion')}
            />
          </Row>
        )}

        <Row width={displayLastGrid ? [5, 5] : [0, 12]} space={2} align="top">
          {displayLastGrid && (
            <div className={styles.eap}>
              <span className={styles.eapTitle}>{tx('lastVersion')}</span>
              <Grid
                name="eap-last"
                root={lastSigla}
                data={lastTree}
                dispatch={dispatch}
                editable={false}
                lastVersion
              />
            </div>
          )}
          <div className={styles.eap}>
            {displayLastEap && (
              <span className={styles.eapTitle}>{tx('currentVersion')}</span>
            )}
            <Grid
              name="eap"
              root={sigla}
              data={tree}
              dispatch={dispatch}
              editable={editable}
            />
          </div>
        </Row>
      </RowGrid>

      {state.modal && (
        <EditDialog editable={editable} config={state.modal} dispatch={dispatch} />
      )}
    </ScrollPanel>
  );
};

function buildTree(entries: Entry[]) {
  const map: { [key: number]: Partial<Node> } = {};
  const tree: Node[] = [];
  for (let index = 0; index < entries.length; ++index) {
    if (!entries[index]) continue;
    const { id, pai, ...rest } = entries[index];

    if (map[id]) {
      Object.assign(map[id], { id, pai, index, ...rest });
    } else {
      map[id] = { id, pai, index, ...rest };
    }

    if (pai === null || pai === undefined) {
      tree.push(map[id] as Node);
    } else {
      if (!map[pai]) {
        map[pai] = { id: pai, children: [] };
      }

      if (!map[pai].children) {
        map[pai].children = [];
      }

      map[pai].children!.push(map[id] as Node);
    }
  }

  return tree;
}

export default Eap;
