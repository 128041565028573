import React from 'react';

import Button, { ButtonProps } from 'components/elements/Button/Button';
import { useForm } from '..';
import { FormStashOp } from '../types';

const StashButton: React.FC<
  ButtonProps & { stash: FormStashOp; needsRefresh?: boolean }
> = ({ stash, needsRefresh, ...rest }) => {
  const form = useForm();

  return (
    <Button
      {...rest}
      onClick={() => {
        if (stash === 'push') {
          form.stash('push');
          form.setEditable(true);
          if (needsRefresh !== undefined) {
            form.reload();
          }
        } else {
          form.stash('pop');
          form.setEditable(false);
        }
      }}
    />
  );
};

export default StashButton;
