import React from 'react';

import Field, { Props as FieldProps } from '../Field/Field';
import Input, { Props as InputProps } from 'components/inputs/Select/MultiSelect';

const MultiSelect: React.FC<FieldProps & InputProps> = props => (
  <Field {...props} component={Input} />
);

MultiSelect.displayName = 'MultiSelectField';
export default MultiSelect;
