import { ResponseExtras } from 'apis';

export const getKey = (file: File) => `_${file.name}_${file.size}_${file.lastModified}_`;

export const splitName = (filename: string) => {
  const idx = filename.lastIndexOf('.');

  if (idx < 0) {
    return { name: filename, ext: '' };
  }

  return { name: filename.substring(0, idx), ext: filename.substring(idx + 1) };
};

export const downloadBlob = (
  blob: Blob,
  defaultFilename: string,
  extras?: ResponseExtras
) => {
  if (extras) {
    const contentDisposition = extras.headers['content-disposition'] as string;

    const a = window.document.createElement('a');

    a.href = URL.createObjectURL(blob);
    let fileName = defaultFilename;
    if (contentDisposition) {
      const matches = /filename="?([^"]*)"?/.exec(contentDisposition);
      if (matches) {
        fileName = matches[1];
      }
    }

    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

export const acceptedList = (types: string[], operator: string) => {
  const buf = [];

  for (let i = 0; i < types.length; ++i) {
    buf.push(types[i].toUpperCase());

    if (i < types.length - 2) {
      buf.push(', ');
    } else if (i < types.length - 1) {
      buf.push(` ${operator} `);
    }
  }

  return buf.join('');
};

export const formatSize = (size: number, locale?: string) => {
  const units = ['B', 'kB', 'MB', 'GB'];

  for (let i = 0; i < units.length; ++i) {
    if (size < 1024 || i === units.length - 1) {
      return `${parseFloat(String(size)).toLocaleString(locale, {
        maximumFractionDigits: 2,
      })}${units[i]}`;
    }

    size /= 1024;
  }
};
