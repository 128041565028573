import { FieldMetadata } from 'components/form/types';
import { readAttr } from 'utils/object';
import { Value } from './types';

const useUpperCase = (value?: Value, metadata?: FieldMetadata) => {
  return readAttr('rules.case', metadata) === 'upper' && typeof value === 'string'
    ? value.toUpperCase()
    : value;
};

export default useUpperCase;
