import React from 'react';

const Evaluation = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none">
    <path
      d="m22.4 9.96-2.88 2.52 2e-6 2.31 4.21-3.66c0.154-0.157 0.234-0.358 0.225-0.564-0.0091-0.206-0.107-0.401-0.275-0.547-0.167-0.146-0.392-0.231-0.628-0.239-0.236-0.00796-0.468 0.0621-0.647 0.196z"
      fill="currentColor"
    />
    <path
      d="m14.8 18.5c-0.187 8.94e-4 -0.369-0.0466-0.525-0.136-0.156-0.0898-0.277-0.218-0.348-0.368-0.0716-0.15-0.0902-0.315-0.0535-0.475 0.0367-0.159 0.127-0.306 0.26-0.42l5.37-4.66v-7.03c0-0.651-0.297-1.28-0.826-1.74-0.529-0.46-1.25-0.719-1.99-0.719h-9.4c-0.748 0-1.46 0.259-1.99 0.719-0.529 0.46-0.826 1.08-0.826 1.74v13.1c0 0.651 0.297 1.28 0.826 1.74 0.529 0.46 1.25 0.719 1.99 0.719h9.4c0.748 0 1.46-0.259 1.99-0.719 0.529-0.46 0.826-1.08 0.826-1.74v-3.76l-4.03 3.51c-0.0871 0.0773-0.191 0.139-0.305 0.181-0.114 0.0421-0.237 0.0641-0.362 0.0646zm-6.58-12.3h7.52c0.249 0 0.488 0.0862 0.664 0.24 0.176 0.153 0.275 0.362 0.275 0.579 0 0.217-0.099 0.425-0.275 0.579-0.176 0.153-0.415 0.24-0.664 0.24h-7.52c-0.249 0-0.488-0.0862-0.664-0.24-0.176-0.153-0.275-0.362-0.275-0.579 0-0.217 0.099-0.425 0.275-0.579 0.176-0.153 0.415-0.24 0.664-0.24zm0 3.27h7.52c0.249 0 0.488 0.0862 0.664 0.24 0.176 0.153 0.275 0.362 0.275 0.579 0 0.217-0.099 0.425-0.275 0.579-0.176 0.153-0.415 0.24-0.664 0.24h-7.52c-0.249 0-0.488-0.0862-0.664-0.24-0.176-0.153-0.275-0.362-0.275-0.579 0-0.217 0.099-0.425 0.275-0.579 0.176-0.153 0.415-0.24 0.664-0.24zm-0.94 4.09c0-0.217 0.099-0.425 0.275-0.579 0.176-0.153 0.415-0.24 0.664-0.24h7.52c0.249 0 0.488 0.0862 0.664 0.24 0.176 0.153 0.275 0.362 0.275 0.579 0 0.217-0.099 0.425-0.275 0.579-0.176 0.153-0.415 0.24-0.664 0.24h-7.52c-0.249 0-0.488-0.0862-0.664-0.24-0.176-0.153-0.275-0.362-0.275-0.579z"
      fill="currentColor"
    />
  </svg>
);

export default Evaluation;
