import { useRef, useCallback, useEffect } from 'react';
import axios, { AxiosInstance, CancelTokenSource } from 'axios';

import { sippi, RequestConfig, RequestError } from '.';
import { call } from 'utils';

const useRequest = (
  api?: AxiosInstance
): (<T>(config: RequestConfig<T>) => CancelTokenSource) => {
  const sources = useRef<CancelTokenSource[]>([]);

  const request = useCallback(
    <T>(config: RequestConfig<T>) => {
      const instance = api || sippi;
      const source = axios.CancelToken.source();
      const { onSuccess, onError, onCancel, onComplete, onStarted, ...rest } = config;

      sources.current.push(source);
      onStarted?.();

      instance
        .request<T>({ ...rest, cancelToken: source.token })
        .then(({ data, ...rest }) => {
          try {
            call(onSuccess, data, rest);
          } catch (_err) {}
        })
        .catch((err: RequestError<T>) => {
          if (axios.isCancel(err)) {
            call(onCancel, err.message !== 'unmount');
          } else {
            call(onError, err);
          }
        })
        .finally(() => {
          sources.current.splice(sources.current.indexOf(source), 1);
          call(onComplete);
        });

      return source;
    },
    [api]
  );

  useEffect(
    () => () => {
      for (const source of sources.current) {
        source.cancel('unmount');
      }
    },
    []
  );

  return request;
};

export default useRequest;
