import React from 'react';

const Link = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none">
    <path
      d="m3.95 11.4c0.28-1.47 1.66-2.48 3.16-2.48h2.93c0.52 0 0.95-0.43 0.95-0.95s-0.43-0.95-0.95-0.95h-2.83c-2.61 0-4.94 1.91-5.19 4.51-0.29 2.98 2.05 5.49 4.97 5.49h3.05c0.52 0 0.95-0.43 0.95-0.95 0-0.52-0.43-0.95-0.95-0.95h-3.05c-1.91 0-3.42-1.74-3.04-3.72zm5.04 1.62h6c0.55 0 1-0.45 1-1s-0.45-1-1-1h-6c-0.55 0-1 0.45-1 1s0.45 1 1 1zm4.95-6h2.83c2.61 0 4.95 1.91 5.2 4.51 0.28 2.98-2.06 5.49-4.98 5.49h-3.05c-0.52 0-0.95-0.43-0.95-0.95 0-0.52 0.43-0.95 0.95-0.95h3.05c1.91 0 3.42-1.74 3.04-3.72-0.28-1.47-1.66-2.48-3.16-2.48h-2.93c-0.52 0-0.95-0.43-0.95-0.95s0.43-0.95 0.95-0.95z"
      clipRule="evenodd"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default Link;
