import React, { ReactNode, useCallback, useState } from 'react';
import { Props as FormProps } from '../Form';
import { useTranslation, useTranslationX } from 'i18n';
import { Dialog, Grid, SuccessDialog } from 'components/containers';
import {
  SubmitButton,
  StashButton,
  Form,
  HiddenField,
  FileUploader,
} from 'components/form';
import { UI_BUTTON_SPACING } from 'consts';

import { SubmitProps } from '../Form';
import { Button } from 'components/elements';

import { useRequest } from 'apis';

import styles from './EditableConfigForm.module.scss';

type Props = {
  baseUrl: string;
  minWidth?: string | number;
  maxWidth?: string | number;
  admin?: boolean;
  controls?: (editable: boolean) => ReactNode;
  footer?: (editable: boolean) => ReactNode;
  overrideEditable?: boolean | undefined;
  children?: ReactNode;
  onSuccess?: () => void;
  onPopulate?: FormProps['onPopulate'];
  successMessage?: string;
  okText?: string;
  submitProps?: SubmitProps;
  archiveConfirmation?: {
    title: string;
    message: string;
    url: (identifier?: string | number) => string;
    rules: { [key: string]: any };
  };
};

type Status = 'init' | 'done' | 'confirmation';

const EditableConfigForm: React.FC<Props> = props => {
  const {
    baseUrl,
    minWidth,
    maxWidth,
    controls,
    children,
    admin,
    overrideEditable = undefined,
    onSuccess,
    successMessage,
    footer,
    okText,
    archiveConfirmation,
    onPopulate,
  } = props;
  const { t } = useTranslation('translation');
  const { tx } = useTranslationX('components.form', 'translation');
  const [editable, setEditable] = useState(false);
  const [status, setStatus] = useState<Status>('init');

  const [outputId, setOutputId] = useState<number | string>();

  const request = useRequest();

  const handleStash = () => {
    if (overrideEditable !== undefined) {
      if (overrideEditable) {
        return 'push';
      }
      return 'clear';
    }
    return 'push';
  };

  const onSendConfirmation = (formData?: FormData) => {
    if (!formData) {
      setStatus('done');
      return;
    }
    if (!archiveConfirmation) {
      return;
    }
    handleSubmitArchives(formData);
  };

  const handleSubmitArchives = useCallback(
    (formData?: FormData) => {
      const source = request({
        method: 'PUT',
        url: archiveConfirmation?.url(outputId),
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onComplete: () => {
          setStatus('done');
        },
      });
      return () => source.cancel();
    },
    [request, archiveConfirmation, outputId]
  );

  return (
    <>
      <Dialog
        title={archiveConfirmation?.title}
        titleAlign="center"
        volatile
        visible={status === 'confirmation'}
        onClose={onSendConfirmation}
        closeButton
        width={500}
      >
        <Form
          submit={{ format: 'multipart' }}
          onSubmit={({ requestBody }) => {
            const formData: FormData = requestBody as FormData;
            onSendConfirmation(formData);
          }}
        >
          <div style={{ padding: 20 }}>
            <FileUploader
              name="arquivos"
              label="arquivos"
              message={archiveConfirmation?.message}
              type="sneaky"
              metadata={{
                rules: archiveConfirmation?.rules,
              }}
            />
          </div>
          <div className={styles.dialogButtons}>
            <Button
              onClick={onSendConfirmation}
              type="secondary"
              style={{ root: { width: 124 } }}
            >
              {t('button.cancel')}
            </Button>
            <SubmitButton>{t('button.send')}</SubmitButton>
          </div>
        </Form>
      </Dialog>
      <SuccessDialog
        onClose={() => {
          setStatus('init');
          onSuccess?.();
        }}
        visible={status === 'done'}
        title={successMessage ?? tx('dialog.done')}
        buttonLabel={t('button.ok')}
      />
      <Form
        baseUrl={baseUrl}
        populate={{}}
        submit={{ ignoreUrlId: true, ...props.submitProps }}
        editable={false}
        onPopulate={onPopulate}
        onStash={({ editable }) => setEditable(editable)}
        onDone={({ responseBody }) => {
          if (archiveConfirmation) {
            setStatus('confirmation');
          } else {
            setStatus('done');
          }
          if (responseBody['id']) {
            setOutputId(responseBody['id']);
          }
        }}
      >
        <Grid minWidth={minWidth || 400} maxWidth={maxWidth || 600}>
          {admin ? <HiddenField name="id" value="1" /> : <HiddenField name="id" />}
          {children}
          {footer?.(editable)}
          <div className={styles.buttons}>
            {typeof controls === 'function' ? (
              <div className={styles.customControls}>{controls(editable)}</div>
            ) : null}

            {editable ? (
              <div className={styles.buttonsForm}>
                <StashButton type="secondary" stash="pop">
                  {t('button.cancel')}
                </StashButton>

                <SubmitButton
                  clearStash
                  style={{ root: { marginLeft: UI_BUTTON_SPACING } }}
                >
                  {okText ?? t('button.save')}
                </SubmitButton>
              </div>
            ) : (
              overrideEditable !== false && (
                <StashButton
                  type="primary"
                  stash={handleStash()}
                  needsRefresh={overrideEditable}
                >
                  {t('button.edit')}
                </StashButton>
              )
            )}
          </div>
        </Grid>
      </Form>
    </>
  );
};

export default EditableConfigForm;
