import React from 'react';

const GraduationCap = ({ size = 1 }: { size?: number }) => (
  <svg height={`${size}em`} viewBox="0 0 24 24">
    <defs>
      <filter id="bg4y74xjxa" x="-.072" y="-.131" width="1.14" height="1.26">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix
          result="shadowMatrixOuter1"
          values="0 0 0 0 0.786288496 0 0 0 0 0.786288496 0 0 0 0 0.786288496 0 0 0 0.5 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g transform="matrix(.897 0 0 .886 -51.1 -90.4)" strokeWidth="1.12">
      <g
        transform="matrix(.265 0 0 .265 28 97.8)"
        fill="currentColor"
        filter="url(#bg4y74xjxa)"
        strokeWidth="1.12"
      >
        <path
          transform="translate(123,45)"
          d="m36.4 31.6-24.6-8.78 0.084 16.1s11.5 5.79 24.5 5.79c13.6 0 24.6-6.83 24.6-6.83l-0.103-15-24.5 8.77z"
        />
        <path
          transform="translate(123,45)"
          d="m68.3 16.7 4.58-1.7-36.4-15-36.4 15 36.4 13.6 29.1-10.8v15.8h-1.84v7.58h6.1v-7.58h-1.5z"
        />
      </g>
    </g>
  </svg>
);

export default GraduationCap;
