import { useCallback, useEffect, useRef } from 'react';

import { useRequest, viacep } from 'apis';
import { useForm, useFormFieldObserver } from '.';
import { toString } from 'utils';
import { FormContextValue } from './types';

type Props = {
  cep: string;
  logradouro?: string;
  bairro?: string;
  cidade?: string;
  uf?: string;
  prefix?: string;
};

type Response = Omit<Props, 'cidade'> & {
  erro?: boolean;
  localidade?: string;
};

const CEP = ({ cep, logradouro, bairro, cidade, uf, prefix }: Props) => {
  const result = useFormFieldObserver(pre(cep, prefix));
  const request = useRequest(viacep);
  const form = useForm();
  const term = useRef<string>();

  const onSuccess = useCallback(
    (data: Response) => {
      if (data.erro) {
        term.current = undefined;
        return;
      }

      update(logradouro, data.logradouro, prefix, form);
      update(bairro, data.bairro, prefix, form);
      update(uf, data.uf, prefix, form);
      update(cidade, data.localidade, prefix, form);

      term.current = data.cep;
    },
    [logradouro, bairro, uf, cidade, form, prefix]
  );

  useEffect(() => {
    const value = toString(result.value);

    if (value === term.current) {
      return;
    }

    if (result.valid && value) {
      const source = request<Response>({
        url: `/${value}/json`,
        onSuccess,
        onError: () => (term.current = undefined),
      });

      return () => source.cancel();
    } else {
      term.current = undefined;
    }
  }, [form, request, onSuccess, result]);

  return null;
};

export default CEP;

function update(
  name: string | undefined,
  value: string | undefined,
  prefix: string | undefined,
  form: FormContextValue
) {
  if (name && value) {
    form.setFieldValue(pre(name, prefix), value);
  }
}

function pre(name: string, prefix?: string) {
  return prefix ? `${prefix}.${name}` : name;
}
