import React from 'react';

// import { Container } from './styles';

const Search = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 14 14" fill="none">
    <path
      d="M9.3868 9.91751H9.21534L9.07996 10.0227C8.01157 10.8531 6.66705 11.2448 5.32018 11.1182C3.9733 10.9915 2.72526 10.356 1.83019 9.34094C0.935116 8.32589 0.460338 7.00762 0.502597 5.65456C0.544857 4.3015 1.10097 3.01547 2.05763 2.05832C3.01429 1.10118 4.29954 0.544872 5.65169 0.502598C7.00384 0.460324 8.3213 0.935259 9.3358 1.83077C10.3503 2.72628 10.9856 3.97505 11.1122 5.32283C11.2388 6.67061 10.8472 8.01597 10.0172 9.08496L9.74634 9.43388L10.0592 9.74573L13.4326 13.1082C13.5224 13.1977 13.5225 13.3431 13.4329 13.4328C13.3432 13.5225 13.1978 13.5224 13.1083 13.4326L9.74804 10.0644L9.60153 9.91751H9.39407H9.3868ZM5.82341 10.6847C6.46211 10.6847 7.09455 10.5588 7.68461 10.3143C8.27467 10.0697 8.8108 9.71132 9.26239 9.2595C9.71398 8.80768 10.0722 8.2713 10.3166 7.681C10.561 7.0907 10.6867 6.45803 10.6867 5.81912C10.6867 5.1802 10.561 4.54753 10.3166 3.95723C10.0722 3.36693 9.71398 2.83055 9.26239 2.37873C8.8108 1.92691 8.27467 1.5685 7.68461 1.32396C7.09454 1.07942 6.46211 0.953554 5.82341 0.953554C4.53349 0.953554 3.29646 1.46624 2.38443 2.37873C1.47241 3.29122 0.960082 4.52877 0.960082 5.81912C0.960082 7.10946 1.47241 8.34701 2.38443 9.2595C3.29646 10.172 4.53349 10.6847 5.82341 10.6847Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
);

export default Search;
