import React, { forwardRef } from 'react';

import { InputProps } from '../types';
import { isNil } from 'utils';
import { readAttr } from 'utils/object';
import { classes, renderComponent } from 'utils/components';
import { inputMask } from 'utils/inputMask';
import useUpperCase from '../useUpperCase';

import styles from './TextInput.module.scss';

export type Props = {
  align?: 'left' | 'center' | 'right';
  placeholder?: string;
  adornment?: string | { left?: string; right?: string };
  icon?: React.ElementType;
};

const TextInput = forwardRef<HTMLInputElement, InputProps & Props>((props, ref) => {
  const {
    metadata,
    placeholder,
    control,
    inputProps,
    align = 'left',
    adornment,
    icon,
    name,
  } = props;
  const value = useUpperCase(isNil(props.value) ? '' : props.value, metadata);

  function adornmentLeft() {
    let val;

    if (adornment) {
      if (typeof adornment === 'string') {
        val = adornment;
      } else if (adornment.left) {
        val = adornment.left;
      }
    }

    return val ? <span className={styles.adornment}>{val}</span> : null;
  }

  function adornmentRight() {
    return typeof adornment === 'object' && adornment.right ? (
      <span className={styles.adornment}>{adornment.right}</span>
    ) : null;
  }

  const mask = readAttr('rules.mask', metadata);

  return (
    <div
      className={classes(
        styles.textInput,
        styles[control.type || ''],
        control.active ? styles.active : ''
      )}
    >
      {icon ? (
        <div className={styles.icon}>
          {renderComponent(icon, {
            size: 1.2,
          })}
        </div>
      ) : null}
      {adornmentLeft()}
      <input
        {...inputMask(mask, inputProps, value)}
        className={styles.input}
        placeholder={placeholder}
        type="text"
        ref={ref}
        disabled={control.type}
        key={name}
        maxLength={readAttr('rules.maxlength', metadata)}
        style={{ textAlign: align }}
      />
      {adornmentRight()}
    </div>
  );
});

TextInput.displayName = 'TextInput';
export default TextInput;
