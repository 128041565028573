import React, { useCallback, useState, useEffect } from 'react';

import { MdCircle as Radio } from 'react-icons/md';

import { Row, ScrollPanel } from 'components/containers';
import { FieldSet, HiddenField, TextField, useForm } from 'components/form';
import { IconButton } from 'components/elements';
import { XAlt } from 'components/icons';

import { renderComponent } from 'utils/components';
import { useTranslationX } from 'i18n';
import { ProposalDeliverables } from './Milestones';

import styles from './Milestones.module.scss';

type Props = {
  count: number;
  pathDeliverables: string;
  editable: boolean;
};

const Deliverables = ({ count, pathDeliverables, editable }: Props) => {
  const { tx } = useTranslationX('macroentregas', 'proposal');
  const form = useForm();

  const [deliverables, setDeliverables] = useState(count);

  const [focusIndex, setFocusIndex] = useState<number | undefined>();

  useEffect(() => {
    setDeliverables(count);
  }, [count]);

  const updateDeliverables = useCallback(
    (deliverable: ProposalDeliverables) => {
      const path = `${pathDeliverables}[${deliverable.ordem}]`;

      form.setFieldValue(`${path}.id`, deliverable.id, true);
      form.setFieldValue(`${path}.titulo`, deliverable.titulo, true);
    },
    [form, pathDeliverables]
  );

  const handleAddDeliverable = useCallback(() => {
    const payload = { titulo: tx('text-deliverable') };
    setFocusIndex(deliverables);
    form.addListItem(
      pathDeliverables,
      deliverables,
      (success, res) => {
        if (success && res) {
          const { id, ordem } = res.response as { id: number; ordem: number };

          updateDeliverables({ ...payload, id, ordem });
          setDeliverables(deliverable => deliverable + 1);
        }
      },
      payload
    );
  }, [tx, form, deliverables, updateDeliverables, pathDeliverables]);

  const handleDeleteDeliverables = useCallback(
    (indexDeliverable: number) => {
      let i;

      for (i = indexDeliverable + 1; i < deliverables; i++) {
        const id = form.getFieldValue(`${pathDeliverables}[${i - 1}].id`);
        const tit = form.getFieldValue(`${pathDeliverables}[${i}].titulo`);

        if (i > 0) {
          updateDeliverables({ id: id, titulo: tit, ordem: i - 1 });
        }
      }

      form.removeListItem(pathDeliverables, i - 1, success => {
        if (success) {
          setDeliverables(deliverable => deliverable - 1);
        }
      });
    },
    [deliverables, form, pathDeliverables, updateDeliverables]
  );

  if (!editable && !deliverables) {
    return null;
  }

  return (
    <>
      <span className={styles.title}>{tx('deliverables')}</span>

      <ScrollPanel
        style={{
          root: {
            height: deliverables * 46,
            maxHeight: '48%',
          },
        }}
      >
        <ul className={styles.deliverables}>
          {Array.from(new Array(deliverables), (_, index) => (
            <FieldSet name="entregaveis" index={index} key={index}>
              <HiddenField name="id" />

              <li
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    handleAddDeliverable();
                  }
                }}
              >
                <div className={styles.icon}>{renderComponent(Radio)}</div>
                <Row>
                  <div
                    ref={ref => {
                      if (index === focusIndex) {
                        setFocusIndex(undefined);
                        ref?.querySelector('input')?.focus();
                      }
                    }}
                  >
                    <TextField name="titulo" theme="transparent" />
                  </div>
                </Row>

                {editable && (
                  <IconButton
                    icon={XAlt}
                    onClick={() => handleDeleteDeliverables(index)}
                    shape="circle"
                    type="danger"
                    rate={0.43}
                  />
                )}
              </li>
            </FieldSet>
          ))}
        </ul>
      </ScrollPanel>

      {editable && (
        <div className={styles.newDeliverable} onClick={() => handleAddDeliverable()}>
          {tx('new-deliverable')}
        </div>
      )}
    </>
  );
};

export default Deliverables;
