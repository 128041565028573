import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ReadOnlyTableData, ReadOnlyTableDataConfig } from 'components/containers';
import { useTranslationX } from 'i18n';
import { useAuthState } from 'store/auth';
import { useFetch } from 'apis';
import { capitalize, formatDateToBrazilian } from 'utils/stringUtils';
import { useProjectEditor } from 'pages/project/ProjectEditor/contexts';
import { ActionButtons } from '..';
import { Loader } from 'components/elements';

import { Profile } from 'pages/register/profile';
import { Contract } from 'pages/project/types';

import styles from './PeopleView.module.scss';

type Props = {
  hasButtons?: boolean;
};

const PeopleView: React.FC<Props> = ({ hasButtons = false }) => {
  const { state, dispatch } = useProjectEditor();
  const { profile } = useAuthState();
  const { id } = useParams<{
    id?: string;
  }>();

  const { data, reload } = useFetch<Contract>(
    `/projeto/${state?.id}/recursos-humanos/${id}/contrato`
  );
  const { t, tx } = useTranslationX('rh.person.manage', 'project');
  const { tx: tx2 } = useTranslationX('request.form', 'project');

  useEffect(() => {
    if (data) {
      dispatch({ type: 'SET_CONTRACT', payload: data });
    }
  }, [data, dispatch]);

  if (!data) {
    return <Loader />;
  }

  const showActions =
    profile !== Profile.SUPERVISOR &&
    (['ANS', 'ETC', 'ECT'].indexOf(data?.projetoRH.estado) === -1 ||
      (data.projetoRH.vinculoTrabalho === 4 && data.projetoRH.estado === 'ECT'));

  const tableConfigScholarship: ReadOnlyTableDataConfig[] = [
    {
      tableRows: [
        [
          {
            title: tx2('name.label'),
            field: 'pessoa.nome',
            width: 40,
            parse: (value: string) => value && capitalize(value),
          },
          {
            title: tx2('birth'),
            field: 'pessoa.nascimento',
            width: 40,
            parse: value => formatDateToBrazilian(value),
          },
          {
            title: tx2('sex.label'),
            field: 'pessoa.genero',
            width: 40,
            parse: value => tx2(`sex.${value}`),
          },
        ],
        [
          {
            title: tx2('rg'),
            field: 'pessoa.rg',
            width: 40,
          },
          {
            title: tx2('orgaoEmissor'),
            field: 'pessoa.rgEmissor',
            width: 40,
          },
          {
            title: tx2('uf'),
            field: 'pessoa.rgUf',
            width: 40,
          },
        ],
      ],
    },
    {
      title: `${tx2('dataBank')}:`,
      tableRows: [
        [
          {
            field: 'banco.banco',
            title: tx2('bank'),
            width: 40,
          },
          {
            field: 'banco.agencia',
            title: tx2('agency'),
            width: 40,
          },
          {
            field: 'banco.conta',
            title: tx2('cc'),
            width: 40,
          },
        ],
        [
          {
            field: 'pessoa.vinculoEmpregaticio',
            title: tx2('vinculo'),
            width: 40,
            parse: value => t(`request.boolean.${Number(value)}`),
          },
          {
            field: 'pessoa.localTrabalho',
            title: tx2('workplace'),
            width: 40,
          },
          {
            field: 'pessoa.teste',
            title: '',
            width: 40,
            parse: () => <></>,
          },
        ],
      ],
    },
    {
      title: `${tx2('placeActivities')}:`,
      tableRows: [
        [
          {
            field: 'atividades.instituicao',
            title: tx2('institution'),
            width: 40,
          },
          {
            field: 'atividades.orgao',
            title: tx2('orgao'),
            width: 40,
          },
          {
            field: 'atividades.local',
            title: tx2('place'),
            width: 40,
          },
        ],
      ],
    },
    {
      tableRows: [
        [
          {
            field: 'atividades.cep',
            title: tx2('cep'),
            width: 40,
          },
        ],
        [
          {
            field: 'atividades.logradouro',
            title: tx2('street'),
            width: 40,
            parse: (value: string) => value && capitalize(value),
          },
          {
            field: 'atividades.numero',
            title: tx2('number'),
            width: 40,
          },
          {
            field: 'atividades.local',
            title: tx2('place'),
            width: 40,
          },
        ],
        [
          {
            field: 'atividades.bairro',
            title: tx2('district'),
            width: 40,
            parse: (value: string) => value && capitalize(value),
          },
          {
            field: 'atividades.cidade',
            title: tx2('city'),
            width: 40,
            parse: (value: string) => value && capitalize(value),
          },
          {
            field: 'atividades.complemento',
            title: tx2('complement'),
            width: 40,
            parse: (value: string) => value && capitalize(value),
          },
        ],
      ],
    },
    {
      title: `${tx2('scholarshipData')}:`,
      tableRows: [
        [
          {
            field: 'bolsa.dataInicio',
            title: tx2('scholarshipStart'),
            width: 40,
            parse: value => formatDateToBrazilian(value),
          },

          {
            field: 'bolsa.chMes',
            title: tx2('monthlyWorkload'),
            width: 40,
            parse: value => `${value} ${tx2('hourspermonth')}`,
          },
          {
            field: 'bolsa.duracao',
            title: tx2('durationMonths'),
            width: 40,
            parse: value => `${value} ${tx2('months')}`,
          },
        ],
      ],
    },
  ];

  const tableConfigCLT: ReadOnlyTableDataConfig[] = [
    {
      tableRows: [
        [
          {
            title: tx2('name.label'),
            field: 'pessoa.nome',
            width: 40,
            parse: (value: string) => value && capitalize(value),
          },
          {
            title: tx2('birth'),
            field: 'pessoa.nascimento',
            width: 40,
            parse: value => formatDateToBrazilian(value),
          },
          {
            title: tx2('sex.label'),
            field: 'pessoa.genero',
            width: 40,
            parse: value => tx2(`sex.${value}`),
          },
        ],
        [
          {
            title: tx2('rg'),
            field: 'pessoa.rg',
            width: 40,
          },
          {
            title: tx2('orgaoEmissor'),
            field: 'pessoa.rgEmissor',
            width: 40,
          },
          {
            title: tx2('uf'),
            field: 'pessoa.rgUf',
            width: 40,
          },
        ],
        [
          {
            title: tx2('naturalidade'),
            field: 'pessoa.naturalidade',
            width: 40,
          },
          {
            title: tx2('estadoCivil.title'),
            field: 'pessoa.estadoCivil',
            width: 40,
            parse: value => tx2(`estadoCivil.${value}`),
          },
          {
            title: tx2('gender.label'),
            field: 'pessoa.genero',
            width: 40,
            parse: value => tx2(`gender.${value}`),
          },
        ],
        [
          {
            title: tx2('grauInstrucao.title'),
            field: 'pessoa.formacao',
            width: 40,
            parse: value => tx2(`grauInstrucao.${value}`),
          },
          {
            title: tx2('carteiraProfissional'),
            field: 'pessoa.carteiraProfissional',
            width: 40,
          },
          {
            title: tx2('carteiraProfissionalSerie'),
            field: 'pessoa.carteiraProfissionalSerie',
            width: 40,
          },
        ],
        [
          {
            title: tx2('tituloEleitor'),
            field: 'pessoa.tituloEleitor',
            width: 40,
          },
          {
            title: tx2('tituloEleitorZona'),
            field: 'pessoa.tituloEleitorZona',
            width: 40,
          },
          {
            title: tx2('tituloEleitorSecao'),
            field: 'pessoa.tituloEleitorSecao',
            width: 40,
          },
        ],
        [
          {
            title: tx2('certificadoMilitarN'),
            field: 'pessoa.certificadoMilitarN',
            width: 40,
          },
          {
            title: tx2('certificadoMilitarSerie'),
            field: 'pessoa.certificadoMilitarSerie',
            width: 40,
          },
          {
            title: tx2('certificadoMilitarCategoria'),
            field: 'pessoa.certificadoMilitarCategoria',
            width: 40,
          },
        ],
        [
          {
            title: tx2('pis'),
            field: 'pessoa.pis',
            width: 40,
          },
          {
            title: tx2('pisDataCadastro'),
            field: 'pessoa.pisDataCadastro',
            width: 40,
            parse: value => formatDateToBrazilian(value),
          },
          {
            title: tx2('dependentes'),
            field: 'pessoa.dependentes',
            width: 40,
          },
        ],
        [
          {
            title: tx2('cbo'),
            field: 'pessoa.cargoCbo.titulo',
            width: 80,
          },
          {
            title: tx2('remuneracao'),
            field: 'projetoRH.remuneracao',
            width: 40,
          },
        ],
        [
          {
            title: tx2('monthlyWorkload'),
            field: 'bolsa.chMes',
            width: 40,
          },
          {
            title: tx2('dataAdmissao'),
            field: 'bolsa.dataInicio',
            width: 40,
            parse: value => formatDateToBrazilian(value),
          },
          {
            title: tx2('duracao'),
            field: 'bolsa.duracao',
            width: 40,
          },
        ],
      ],
    },
    {
      title: `${tx2('dataBank')}:`,
      tableRows: [
        [
          {
            field: 'banco.banco',
            title: tx2('bank'),
            width: 40,
          },
          {
            field: 'banco.agencia',
            title: tx2('agency'),
            width: 40,
          },
          {
            field: 'banco.conta',
            title: tx2('cc'),
            width: 40,
          },
        ],
      ],
    },
    {
      title: `${tx2('endereco')}:`,
      tableRows: [
        [
          {
            field: 'atividades.cep',
            title: tx2('cep'),
            width: 40,
          },
        ],
        [
          {
            field: 'atividades.logradouro',
            title: tx2('street'),
            width: 40,
            parse: (value: string) => value && capitalize(value),
          },
          {
            field: 'atividades.numero',
            title: tx2('number'),
            width: 40,
          },
          {
            field: 'atividades.local',
            title: tx2('place'),
            width: 40,
          },
        ],
        [
          {
            field: 'atividades.bairro',
            title: tx2('district'),
            width: 40,
            parse: (value: string) => value && capitalize(value),
          },
          {
            field: 'atividades.cidade',
            title: tx2('city'),
            width: 40,
            parse: (value: string) => value && capitalize(value),
          },
          {
            field: 'atividades.complemento',
            title: tx2('complement'),
            width: 40,
            parse: (value: string) => value && capitalize(value),
          },
        ],
      ],
    },
  ];

  const tableConfigDesligamentoCLT: ReadOnlyTableDataConfig[] = [
    {
      title: `${tx2('tipoNotificacao.RQF')}:`,
      tableRows: [
        [
          {
            title: tx2('dataDesligamento'),
            field: 'desligamento.data.dataDesligamento',
            parse: formatDateToBrazilian,
            width: 33,
          },
          {
            title: tx2('avisoPrevio'),
            field: 'desligamento.data.avisoPrevio',
            width: 33,
          },
          {
            title: tx2('iniciativa'),
            field: 'desligamento.data.iniciativa',
            width: 33,
          },
        ],
        [
          {
            title: tx2('justificativa.label'),
            field: 'desligamento.data.justificativa',
            width: 100,
          },
        ],
      ],
    },
  ];

  let tableConfig;
  switch (data.projetoRH.vinculo) {
    case 'BOLSISTA':
      tableConfig = tableConfigScholarship;
      break;
    case 'CELETISTA':
      tableConfig = [...tableConfigCLT, ...tableConfigDesligamentoCLT];
      break;
    default:
      tableConfig = tableConfigScholarship;
  }

  return (
    <div className={styles.peopleView}>
      <div className={styles.peopleViewHeader}>
        <div className={styles.actions}>
          {showActions && (
            <ActionButtons reload={reload} data={data} hasButtons={hasButtons} />
          )}
        </div>
        <div>
          <h2>{tx('title')}</h2>
        </div>
      </div>
      <ReadOnlyTableData<Contract> data={data} config={tableConfig} />
    </div>
  );
};
export default PeopleView;
