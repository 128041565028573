import React from 'react';

import { MenuItem, TopMenuItemIcon } from '../types';
import { ScrollPanel } from 'components/containers';
import ContentSwitcher from '../ContentSwitcher/ContentSwitcher';
import TopSubMenu from '../TopSubMenu/TopSubMenu';

import styles from './ConfigTopPanel.module.scss';
import { GoBackButton } from 'components/elements';

type Props = {
  items: TopMenuItemIcon[];
  forwardProps?: object | ((item: MenuItem) => object);
  showBackButton?: boolean;
  onChange?: (item?: MenuItem) => void;
};

const ConfigTopPanel: React.FC<Props> = ({
  onChange,
  items,
  forwardProps,
  showBackButton,
}) => {
  return (
    <div className={styles.configPanel}>
      <div className={styles.topMenu}>
        {showBackButton && <GoBackButton />}
        <TopSubMenu
          child={items.map(item => ({
            icon: item.icon,
            label: item.title,
            path: item.path || item.key,
          }))}
        />
      </div>

      <div className={styles.content}>
        <ScrollPanel>
          <ContentSwitcher
            items={items}
            onChange={onChange}
            forwardProps={forwardProps}
          />
        </ScrollPanel>
      </div>
    </div>
  );
};

export default ConfigTopPanel;
