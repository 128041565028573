import React, { useState, useRef, Children, useEffect, ReactNode } from 'react';
import { ZStack } from '..';

import { classes } from 'utils/components';
import { WrapperCard } from 'components/elements';

import styles from './Tabs.module.scss';

type Props = {
  selectedIndex?: number;
  theme?: 'default' | 'system' | 'spaced';
  onChangeTab?: (selectedIndex: number) => void;
  type?: 'ghost' | 'floating' | 'default';
  keepState?: boolean;

  children?: ReactNode;
  cascade?: 'cascade' | '';
};

const Tabs: React.FC<Props> = ({
  selectedIndex,
  children,
  theme = 'default',
  onChangeTab,
  type = 'default',
  keepState = false,
  cascade = '',
}) => {
  const [selected, setSelected] = useState(selectedIndex);

  useEffect(() => {
    if (selectedIndex !== undefined && selectedIndex >= 0) {
      setSelected(selectedIndex);
    }
  }, [selectedIndex]);

  const persistTab = (index: number) => {
    if (keepState) {
      localStorage.setItem('@sippi-keep-tab', JSON.stringify(index));
    }
  };

  const tabRef = useRef<HTMLDivElement>(null);

  const onKeyDown = (event: React.KeyboardEvent, index: number) => {
    if (event.key === 'Enter') {
      setSelected(index);
    }
  };

  const handleClick = (index: number) => {
    setSelected(index);
    onChangeTab?.(index);
    persistTab(index);
  };

  const renderTabs = () => {
    return Children.map(children, (child: any, index) => {
      const active = index === selected;

      return (
        <div
          className={styles[theme]}
          key={index}
          ref={active ? tabRef : null}
          role="presentation"
          onClick={() => handleClick(index)}
          onKeyDown={event => onKeyDown(event, index)}
          tabIndex={index}
        >
          <div role="tab" tabIndex={index} aria-selected={active}>
            <div
              data-active={active ? 'active' : null}
              tabIndex={index}
              className={styles.titleContainer}
              data-changes={child.props.changed}
            >
              {child.props.title}
              {child.props.adornment && (
                <div className={styles.adornment}>{child.props.adornment}</div>
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  const renderHeader = () => {
    const headerClasses =
      type === 'ghost'
        ? classes(styles.header, styles[theme], styles.styledHeader)
        : classes(styles.header, styles[theme]);

    return (
      <div className={headerClasses} role="tablist">
        {renderTabs()}
      </div>
    );
  };

  const isFloating = type === 'floating';

  return (
    <div
      className={classes(
        styles.tab,
        type === 'ghost' ? styles.ghost : null,
        styles[theme]
      )}
    >
      {isFloating && (
        <WrapperCard maxWidth="calc(100% - 100px)" padding="8px">
          {renderHeader()}
        </WrapperCard>
      )}

      {!isFloating && renderHeader()}

      <div className={classes(styles.body, styles[cascade])}>
        <ZStack selected={selected}>
          {Children.map(children, (child: any) => (
            <div className={styles.content} role="tabpanel">
              {child.props.children}
            </div>
          ))}
        </ZStack>
      </div>
    </div>
  );
};

export default Tabs;
