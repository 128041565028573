import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { AtomicBlockUtils, EditorState, Entity, RichUtils } from 'draft-js';

import { useTranslationX } from 'i18n';
import { Bold, Italic, Underline, BulletList } from 'components/icons';
import { NumberedList, Link, Image } from 'components/icons';
import {
  findSelectionByKey,
  getEntityByKey,
  getKeyInSelection,
  getSelectedText,
} from './helpers';

import { classes } from 'utils/components';
import ImageUploadModal from './ImageUploadModal';
import LinkingModal from './LinkingModal';

import styles from './ToolBar.module.scss';

export type ToolBarOption =
  | 'bold'
  | 'italic'
  | 'underline'
  | 'bulletList'
  | 'orderedList'
  | 'image'
  | 'undo'
  | 'redo'
  | 'link'
  | 'divider';

type Props = {
  state: EditorState;
  setState: (state: EditorState) => void;
  options?: ToolBarOption[];
  left?: number;
  toolBarStyle?: React.CSSProperties;
};

type ButtonConfig = { style?: string; type?: string; selection?: boolean } & (
  | {
      option: ToolBarOption;
      action: () => void;
      icon: ReactNode;
    }
  | { option: 'divider' }
);

const defaultOptions: ToolBarOption[] = [
  'bold',
  'italic',
  'underline',
  'divider',
  'bulletList',
  'orderedList',
  // 'divider',
  // 'image',
  'divider',
  'link',
];

const ToolBar = (props: Props) => {
  const { state, setState, options = defaultOptions, left, toolBarStyle } = props;

  const { tx } = useTranslationX('components.richEditor');
  const [currentStyles, setCurrentStyles] = useState<string[]>([]);
  const [currentType, setCurrentType] = useState('');
  const [imageUploding, setImageUploading] = useState(false);
  const [isLinking, setIsLinking] = useState(false);
  const [selectedLink, setSelectedLink] = useState('');

  useEffect(() => {
    const currentStyle = state.getCurrentInlineStyle();

    setCurrentStyles(currentStyle.toArray());
    setCurrentType(RichUtils.getCurrentBlockType(state));
  }, [state]);

  const toggleLink = useCallback(() => {
    const linkKey = getKeyInSelection(state);
    const url = linkKey ? getEntityByKey(state, linkKey).getData().url : '';

    setSelectedLink(url);
    setIsLinking(true);
  }, [setSelectedLink, setIsLinking, state]);

  const insertLink = useCallback(
    (url: string) => {
      const contentState = state.getCurrentContent();
      const linkKey = getKeyInSelection(state);

      if (linkKey) {
        const newContent = contentState.replaceEntityData(linkKey, { url });
        const newEditorState = EditorState.set(state, {
          currentContent: newContent,
        });

        const selection = findSelectionByKey(newEditorState, linkKey);
        const stateWithLink = RichUtils.toggleLink(newEditorState, selection, linkKey);

        setState(stateWithLink);
      } else {
        const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
          url,
        });

        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(state, {
          currentContent: contentStateWithEntity,
        });

        const stateWithLink = RichUtils.toggleLink(
          newEditorState,
          newEditorState.getSelection(),
          entityKey
        );

        setState(stateWithLink);
      }

      setIsLinking(false);
    },
    [setState, setIsLinking, state]
  );

  const removeLink = useCallback(() => {
    const contentState = state.getCurrentContent();
    const linkKey = getKeyInSelection(state);

    if (linkKey) {
      const newContent = contentState.replaceEntityData(linkKey, { url: null });
      const newEditorState = EditorState.set(state, { currentContent: newContent });
      const selection = findSelectionByKey(newEditorState, linkKey);
      const stateWithoutLink = RichUtils.toggleLink(newEditorState, selection, null);

      setState(stateWithoutLink);
      setIsLinking(false);
    }
  }, [setState, setIsLinking, state]);

  const insertImage = useCallback(
    (src: string) => {
      if (imageUploding) {
        const entityKey = Entity.create('image', 'IMMUTABLE', { src });
        const newState = AtomicBlockUtils.insertAtomicBlock(state, entityKey, '\n');
        setImageUploading(false);
        setState(newState);
      }
    },
    [setState, setImageUploading, state, imageUploding]
  );

  const config = useMemo<ButtonConfig[]>(
    () => [
      { option: 'divider' },
      {
        option: 'bold',
        action: () => setState(RichUtils.toggleInlineStyle(state, 'BOLD')),
        icon: <Bold size={0.8} />,
        style: 'BOLD',
      },
      {
        option: 'italic',
        action: () => setState(RichUtils.toggleInlineStyle(state, 'ITALIC')),
        icon: <Italic size={0.8} />,
        style: 'ITALIC',
      },
      {
        option: 'underline',
        action: () => setState(RichUtils.toggleInlineStyle(state, 'UNDERLINE')),
        icon: <Underline size={0.8} />,
        style: 'UNDERLINE',
      },
      {
        option: 'bulletList',
        action: () => setState(RichUtils.toggleBlockType(state, 'unordered-list-item')),
        icon: <BulletList />,
        type: 'unordered-list-item',
      },
      {
        option: 'orderedList',
        action: () => setState(RichUtils.toggleBlockType(state, 'ordered-list-item')),
        icon: <NumberedList />,
        type: 'ordered-list-item',
      },
      {
        option: 'link',
        action: toggleLink,
        icon: <Link />,
        type: 'LINK',
        selection: true,
      },
      {
        option: 'image',
        action: () => setImageUploading(true),
        icon: <Image />,
      },
    ],

    [toggleLink, setState, state]
  );

  const isSelected = (option: ButtonConfig) => {
    const currentKey = getKeyInSelection(state);

    if (currentKey) {
      const type = getEntityByKey(state, currentKey).getType();

      if (type === option.type) {
        return true;
      }
    }

    return (
      (option.style && currentStyles.includes(option.style)) ||
      (option.type && currentType === option.type)
    );
  };

  const isActive = (option: ButtonConfig) =>
    !option.selection || isSelected(option) || getSelectedText(state);

  const renderButtons = () => {
    const data = [];
    let count = 0;

    for (const option of options) {
      const item = config.find(el => el.option === option);

      if (item) {
        if (item.option === 'divider') {
          data.push(<span className={styles.divider} key={`divider${count++}`} />);
        } else {
          data.push(
            <div
              key={item.option}
              className={classes(
                styles.button,
                isSelected(item) ? styles.selected : '',
                isActive(item) ? '' : styles.disabled
              )}
              title={tx(item.option)}
              onClick={item.action}
            >
              {item.icon}
            </div>
          );
        }
      }
    }

    return data;
  };

  const renderCharLeft = () => {
    if (typeof left === 'number') {
      return (
        <span className={styles.left}>
          {left < 0
            ? tx('max-length-warning', {
                exceeding: Math.abs(left),
              })
            : tx('remaining-chars', { charCount: left })}
        </span>
      );
    }

    return null;
  };

  return (
    <div className={styles.toolBar} style={toolBarStyle}>
      {renderCharLeft()}
      {renderButtons()}

      <ImageUploadModal
        visible={imageUploding}
        onChange={insertImage}
        onClose={() => setImageUploading(false)}
      />

      <LinkingModal
        value={selectedLink}
        visible={isLinking}
        onCancel={() => setIsLinking(false)}
        onConfirm={insertLink}
        onRemove={removeLink}
      />
    </div>
  );
};

export default ToolBar;
