import React, { ReactNode } from 'react';

import { isNil } from 'utils';
import { useFieldSet } from '.';
import { FieldSetContext } from './FormContext';

type Props = {
  name?: string;
  index?: number;
  disabled?: boolean;
  children?: ReactNode;
};

const FieldSet: React.FC<Props> = ({ name, index, disabled, children }) => {
  const fieldSet = useFieldSet();

  const prefix = `${fieldSet.prefix}${name ? name.replace(/[.]+$/, '') : ''}${
    isNil(index) ? '.' : `[${index}].`
  }`;

  return (
    <FieldSetContext.Provider value={{ prefix, disabled: disabled || fieldSet.disabled }}>
      {children}
    </FieldSetContext.Provider>
  );
};

export default FieldSet;
