import React from 'react';

const Redirect = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 17 17" fill="none">
    <path
      d="M14.3438 10.625H13.2812C13.1404 10.625 13.0052 10.681 12.9056 10.7806C12.806 10.8802 12.75 11.0154 12.75 11.1562V14.875H2.125V4.25H6.90625C7.04715 4.25 7.18227 4.19403 7.2819 4.0944C7.38153 3.99477 7.4375 3.85965 7.4375 3.71875V2.65625C7.4375 2.51535 7.38153 2.38023 7.2819 2.2806C7.18227 2.18097 7.04715 2.125 6.90625 2.125H1.59375C1.17106 2.125 0.765685 2.29291 0.466799 2.5918C0.167912 2.89068 0 3.29606 0 3.71875L0 15.4062C0 15.8289 0.167912 16.2343 0.466799 16.5332C0.765685 16.8321 1.17106 17 1.59375 17H13.2812C13.7039 17 14.1093 16.8321 14.4082 16.5332C14.7071 16.2343 14.875 15.8289 14.875 15.4062V11.1562C14.875 11.0154 14.819 10.8802 14.7194 10.7806C14.6198 10.681 14.4846 10.625 14.3438 10.625ZM16.2031 0H11.9531C11.2436 0 10.889 0.860293 11.3887 1.36133L12.575 2.54768L4.48242 10.6373C4.40813 10.7113 4.34919 10.7993 4.30897 10.8961C4.26875 10.993 4.24804 11.0969 4.24804 11.2017C4.24804 11.3066 4.26875 11.4105 4.30897 11.5073C4.34919 11.6042 4.40813 11.6922 4.48242 11.7662L5.23514 12.5176C5.30917 12.5919 5.39714 12.6508 5.494 12.691C5.59086 12.7313 5.69471 12.752 5.79959 12.752C5.90447 12.752 6.00832 12.7313 6.10518 12.691C6.20204 12.6508 6.29001 12.5919 6.36404 12.5176L14.4527 4.42664L15.6387 5.61133C16.1367 6.10938 17 5.76074 17 5.04688V0.796875C17 0.585531 16.916 0.382842 16.7666 0.233399C16.6172 0.0839562 16.4145 0 16.2031 0V0Z"
      fill="currentColor"
    />
  </svg>
);

export default Redirect;
