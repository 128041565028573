import { ElementType } from 'react';

import Address from './modules/Address';
import Agreement from './modules/Agreement';
import Company from './modules/Company';
import Contacts from './modules/Contacts';
import Contributor from './modules/Contributor';
import Documents from './modules/Documents';
import Personal from './modules/Personal';
import Researcher from './modules/Researcher';
import Student from './modules/Student';
import * as icons from 'components/icons';
import * as edit from './edit';

const address = { name: 'address', component: Address };
const agreement = { name: 'agreement', component: Agreement, fillPage: true };
const company = { name: 'company', component: Company };
const contact = { name: 'company.contacts', component: Contacts };
const contributor = { name: 'contributor', component: Contributor };
const documents = { name: 'documents', component: Documents };
const personal = { name: 'personal', component: Personal };
const researcher = { name: 'researcher', component: Researcher };
const student = { name: 'student', component: Student };

type SectionMap = {
  name: string;
  component: React.ElementType;
  fillPage?: boolean;
};

type ProfileMap = {
  path: string;
  sections: SectionMap[];
  icon: ElementType;
  editForm: ElementType;
};

export enum Profile {
  STUDENT = 'student',
  RESEARCHER = 'researcher',
  CONTRIBUTOR = 'contributor',
  COMPANY = 'company',
  SUPERVISOR = 'supervisor',
}

export const RemappedProfile: {
  [key: string]: Profile;
} = {
  aluno: Profile.STUDENT,
  pesquisador: Profile.RESEARCHER,
  colaborador: Profile.CONTRIBUTOR,
  empresa: Profile.COMPANY,
  supervisor: Profile.SUPERVISOR,
};

const profileData: { [key in Profile]: ProfileMap } = {
  [Profile.STUDENT]: {
    path: 'aluno',
    sections: [personal, address, student, agreement, documents],
    icon: icons.GraduationCap,
    editForm: edit.Student,
  },
  [Profile.RESEARCHER]: {
    path: 'pesquisador',
    sections: [personal, address, researcher, agreement, documents],
    icon: icons.Researcher,
    editForm: edit.Researcher,
  },
  [Profile.CONTRIBUTOR]: {
    path: 'colaborador',
    sections: [personal, address, contributor, agreement, documents],
    icon: icons.Contributor,
    editForm: edit.Contributor,
  },
  [Profile.COMPANY]: {
    path: 'empresa',
    sections: [company, address, contact],
    icon: icons.Company,
    editForm: edit.Company,
  },
  [Profile.SUPERVISOR]: {
    path: 'supervisor',
    sections: [personal],
    icon: icons.Supervisor,
    editForm: edit.Supervisor,
  },
};

export function getPath(profile: Profile) {
  return profileData[profile].path;
}

export function getSections(profile?: Profile): SectionMap[] {
  return find(profile).sections || [];
}

export function getIcon(profile?: Profile) {
  return find(profile).icon;
}

export function getEditForm(profile?: Profile) {
  return find(profile).editForm || null;
}

export function fromPath(path: string) {
  for (const key in profileData) {
    if ((profileData as any)[key].path === path) {
      return key;
    }
  }
}

function find(profile?: string) {
  for (const key in profileData) {
    if (key === profile || (profileData as any)[key].path === profile) {
      return (profileData as any)[key] || {};
    }
  }

  return {};
}
