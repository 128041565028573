import React from 'react';

import { useTranslation } from 'i18n';
import { InfoDialog } from 'components/containers';
import { acceptedList, formatSize } from 'utils/file';
import styles from './ErrorDialog.module.scss';

type Props = {
  errors?: Set<string>;
  rules: { [key: string]: any };
  onClose: () => void;
};

const ErrorDialog: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { errors, rules, onClose } = props;
  const messages: React.ReactNode[] = [];

  if (errors) {
    errors.forEach((error, index) => {
      let text;

      switch (error) {
        case 'maxfiles':
          text = t('components.uploader.validation.maxfiles', {
            count: rules.maxfiles,
          });

          break;

        case 'filesize':
          text = t('components.uploader.validation.filesize', {
            size: formatSize(rules.filesize),
          });

          break;

        case 'accept':
          text = t('components.uploader.validation.accept', {
            types: acceptedList(rules.accept, t('logic.or')),
          });

          break;

        default:
          break;
      }

      messages.push(<li key={index}>{text}</li>);
    });
  }

  return (
    <InfoDialog
      visible={messages.length > 0}
      buttonLabel={t('components.uploader.dialog.button')}
      onClose={onClose}
      title={t('components.uploader.dialog.message')}
      width={400}
    >
      <div className={styles.errorDialog}>
        <ul>{messages}</ul>
      </div>
    </InfoDialog>
  );
};

export default ErrorDialog;
