import React from 'react';

const BellAlt = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none">
    <path
      d="m2.5 16c-0.552 0-1 0.448-1 1 0 0.552 0.448 1 1 1zm3-7h-1zm14 0h1zm-5.27 12 0.865 0.502c0.179-0.309 0.18-0.691 0.0015-1-0.178-0.31-0.509-0.501-0.866-0.501zm-3.46 0v-1c-0.358 0-0.688 0.191-0.866 0.501-0.178 0.31-0.178 0.692 0.0015 1zm11.7-5h-20v2h20zm-20 2c2.21 0 4-1.79 4-4h-2c0 1.1-0.895 2-2 2zm4-4v-5h-2v5zm0-5c0-3.31 2.69-6 6-6v-2c-4.42 0-8 3.58-8 8zm6-6c3.31 0 6 2.69 6 6h2c0-4.42-3.58-8-8-8zm6 6v5h2v-5zm0 5c0 2.21 1.79 4 4 4v-2c-1.1 0-2-0.896-2-2zm-5.14 6.5c-0.179 0.308-0.508 0.498-0.865 0.498v2c1.07 0 2.06-0.57 2.6-1.49zm-0.865 0.498c-0.356 0-0.686-0.19-0.865-0.498l-1.73 1c0.537 0.925 1.53 1.49 2.6 1.49zm-1.73 1h3.46v-2h-3.46z"
      fill="currentColor"
    />
  </svg>
);

export default BellAlt;
