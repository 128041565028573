import React from 'react';

const Calendar = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 20 20" aria-hidden="true" focusable="false">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8333 3.33341H15V2.50008C15 2.04175 14.625 1.66675 14.1667 1.66675C13.7083 1.66675 13.3333 2.04175 13.3333 2.50008V3.33341H6.66667V2.50008C6.66667 2.04175 6.29167 1.66675 5.83333 1.66675C5.375 1.66675 5 2.04175 5 2.50008V3.33341H4.16667C3.24167 3.33341 2.50833 4.08341 2.50833 5.00008L2.5 16.6667C2.5 17.5834 3.24167 18.3334 4.16667 18.3334H15.8333C16.75 18.3334 17.5 17.5834 17.5 16.6667V5.00008C17.5 4.08341 16.75 3.33341 15.8333 3.33341ZM15.8333 15.8334C15.8333 16.2917 15.4583 16.6667 15 16.6667H5C4.54167 16.6667 4.16667 16.2917 4.16667 15.8334V7.50008H15.8333V15.8334ZM7.5 10.8334V9.16675H5.83333V10.8334H7.5ZM9.16667 9.16675H10.8333V10.8334H9.16667V9.16675ZM14.1667 9.16675H12.5V10.8334H14.1667V9.16675Z"
      fill="currentColor"
    />
  </svg>
);

export default Calendar;
