import React from 'react';

const NumberedList = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none">
    <path
      d="m2.53 4.63h0.526v2.63c0 0.295 0.232 0.526 0.526 0.526 0.295 0 0.526-0.232 0.526-0.526v-3.16c0-0.295-0.232-0.526-0.526-0.526h-1.05c-0.295 0-0.526 0.232-0.526 0.526s0.232 0.526 0.526 0.526zm18.4 2.11h-12.6c-0.579 0-1.05-0.474-1.05-1.05s0.474-1.05 1.05-1.05h12.6c0.579 0 1.05 0.474 1.05 1.05s-0.474 1.05-1.05 1.05zm-12.6 10.5h12.6c0.579 0 1.05 0.474 1.05 1.05 0 0.579-0.474 1.05-1.05 1.05h-12.6c-0.579 0-1.05-0.474-1.05-1.05 0-0.579 0.474-1.05 1.05-1.05zm12.6-6.32h-12.6c-0.579 0-1.05 0.474-1.05 1.05 0 0.579 0.474 1.05 1.05 1.05h12.6c0.579 0 1.05-0.474 1.05-1.05 0-0.579-0.474-1.05-1.05-1.05zm-16.3 5.26c0.295 0 0.526 0.232 0.526 0.526v3.16c0 0.295-0.232 0.526-0.526 0.526h-2.11c-0.295 0-0.526-0.232-0.526-0.526s0.232-0.526 0.526-0.526h1.58v-0.526h-0.526c-0.295 0-0.526-0.232-0.526-0.526 0-0.295 0.232-0.526 0.526-0.526h0.526v-0.526h-1.58c-0.295 0-0.526-0.232-0.526-0.526s0.232-0.526 0.526-0.526zm-2.63-5.79c0-0.295 0.232-0.526 0.526-0.526h2.11c0.295 0 0.526 0.232 0.526 0.526v0.232c0 0.116-0.0421 0.242-0.126 0.337l-1.77 2.06h1.37c0.295 0 0.526 0.232 0.526 0.526s-0.232 0.526-0.526 0.526h-2.11c-0.295 0-0.526-0.232-0.526-0.526v-0.232c0-0.116 0.0421-0.242 0.126-0.337l1.77-2.06h-1.37c-0.295 0-0.526-0.232-0.526-0.526z"
      clipRule="evenodd"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default NumberedList;
