import React, { useRef, useState, useEffect, useMemo } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { BsCloudUpload as UploadIcon } from 'react-icons/bs';
import { MdLibraryAdd as AddIcon } from 'react-icons/md';

import { useTranslation } from 'i18n';
import { IconButton } from '..';
import { FieldMetadata } from 'components/form/types';
import { classes } from 'utils/components';
import { getKey, splitName } from 'utils/file';
import { call } from 'utils';
import { readAttr } from 'utils/object';
import File from './File';
import ErrorDialog from './ErrorDialog';
import { Eye, Upload, X } from 'components/icons';
import { Button } from 'components/elements';
import { useForm } from 'components/form';
import { Modal, ModalPDFViewer } from 'components/containers';

import styles from './FileUploader.module.scss';
import { isEqual } from 'lodash';

export type Props = {
  value?: File[];
  message?: string;
  metadata?: FieldMetadata;
  onChange?: (value: File[]) => void;
  label?: string;
  type?: 'classic' | 'sneaky' | 'button';
  buttonProps?: {
    autoSend?: boolean;
    cancelLabel?: string;
    showFileLabel?: string;
    submitLabel?: string;
  };
  hasFilesUploaded?: boolean;
};

type Rules = {
  maxfiles: number;
  filesize: number;
  accept: string[];
};

const FileUploader: React.FC<Props> = props => {
  const form = useForm();

  const {
    value,
    message,
    metadata,
    onChange,
    type,
    label,
    buttonProps,
    hasFilesUploaded = false,
  } = props;

  const { cancelLabel, showFileLabel, submitLabel, autoSend } = buttonProps || {};

  const [files, setFiles] = useState<File[]>(value || []);
  const [filesArrayBuffer, setFilesArrayBuffer] = useState<ArrayBuffer[]>([]);
  const [highlight, setHighlight] = useState(false);
  const [errors, setErrors] = useState<Set<string>>();
  const [showPdfModal, setShowPdfModal] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const lastFilesRef = useRef<File[]>(files);

  const { t } = useTranslation();

  const rules: Rules = readAttr('rules', metadata, {});
  const { maxfiles = 0, accept = [], filesize = 0 } = rules;
  const enabled = maxfiles <= 0 || maxfiles > files.length;

  const hasFiles = files.length > 0;

  const required = readAttr('rules.required', metadata, false);
  const showOptional = useMemo(
    () => readAttr('rules.required', metadata) !== undefined && form?.display?.optional,
    [form.display.optional, metadata]
  );

  useEffect(() => {
    if (!isEqual(files, lastFilesRef.current)) {
      call(onChange, files);
    }
    lastFilesRef.current = files;
  }, [files, onChange]);

  const append = (list: File[], files: FileList) => {
    const newList = [...list];
    const errors = new Set<string>();

    for (let i = 0; i < files.length; ++i) {
      if (maxfiles && newList.length >= maxfiles) {
        errors.add('maxfiles');
        break;
      }

      const file = files.item(i);

      if (file) {
        if (accept.length && !accept.includes(splitName(file.name).ext)) {
          errors.add('accept');
        } else if (filesize && file.size > filesize) {
          errors.add('filesize');
        } else if (!containsFile(newList, file)) {
          newList.push(file);
        }
      }
    }

    if (errors.size) {
      setErrors(errors);
    }

    return newList;
  };

  const onFilesAdded = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files; // important

    if (files) {
      setFiles(state => append(state, files));
    }
    if (files && type === 'button') {
      const arrayFilesPromise = [];
      for (let i = 0; i < files.length; i++) {
        arrayFilesPromise.push(files[i].arrayBuffer());
      }
      Promise.all(arrayFilesPromise).then(list => {
        setFilesArrayBuffer(list);
      });

      if (autoSend) {
        form.submit();
      }
    }
  };

  const onDragOver = (event: React.DragEvent) => {
    event.preventDefault();

    if (enabled && !highlight) {
      setHighlight(true);
    }
  };

  const onDragLeave = (event: React.DragEvent) => {
    if (listRef.current) {
      const { clientX: x, clientY: y } = event;
      const r = listRef.current.getBoundingClientRect();

      if (x < r.left || x > r.right || y < r.top || y > r.bottom) {
        setHighlight(false);
      }
    }
  };

  const onDrop = (event: React.DragEvent) => {
    event.preventDefault();

    if (enabled) {
      const files = event.dataTransfer.files;
      setFiles(state => append(state, files));
      setHighlight(false);
    }
  };

  const Label = (): JSX.Element => {
    if (type === 'sneaky') {
      return (
        <label className={styles.label}>
          {(label ? label : '') + ' '}
          {required ? (
            <span className={styles.required}>*</span>
          ) : (
            showOptional && (
              <span className={styles.optional}>
                {`(${t('components.form.field.optional')})`}
              </span>
            )
          )}
        </label>
      );
    } else if (type === 'classic') {
      return (
        <div className={styles.header}>
          <div className={styles.icon}>
            <UploadIcon />
          </div>
          <div className={styles.info}>
            <div>
              <span>{t('components.uploader.info')}</span>
              <IconButton
                icon={AddIcon}
                type={enabled ? 'primary' : 'disabled'}
                size={36}
                onClick={() => {
                  if (inputRef.current) {
                    inputRef.current.click();
                  }
                }}
              />
            </div>
            <span>{message}</span>
          </div>
        </div>
      );
    }
    return <React.Fragment />;
  };

  const Input = (): JSX.Element => {
    if (type === 'sneaky' || type === 'classic') {
      return (
        <>
          <input
            ref={inputRef}
            type="file"
            multiple
            accept={accept.map(item => `.${item}`).join(',')}
            value=""
            onChange={onFilesAdded}
          />

          {Boolean(!hasFiles && type === 'sneaky') && (
            <>
              <div
                className={styles.icon}
                style={{
                  color: hasFiles ? '#fff' : '#868686',
                }}
              >
                <Upload />
              </div>
              <p>{message}</p>
            </>
          )}
        </>
      );
    }

    return (
      <>
        <div
          className={styles.icon}
          style={{
            color: hasFiles ? '#fff' : '#868686',
          }}
        >
          <Upload color={hasFilesUploaded ? '#fff' : 'currentColor'} />
        </div>
        <input
          ref={inputRef}
          type="file"
          multiple
          accept={accept.map(item => `.${item}`).join(',')}
          value=""
          onChange={onFilesAdded}
        />
        <p
          className={
            hasFiles
              ? classes(
                  styles.buttonLabel,
                  styles.uploaded,
                  hasFilesUploaded ? styles.uploadedFiles : ''
                )
              : hasFilesUploaded
              ? classes(styles.buttonLabel, styles.uploadedFiles)
              : styles.buttonLabel
          }
        >
          {Number(filesArrayBuffer?.length) > 0 && buttonProps?.submitLabel
            ? submitLabel
            : message}
        </p>
      </>
    );
  };

  const ButtonUpload = (): JSX.Element => {
    return (
      <div className={styles.buttonFile}>
        <Button type={!hasFiles ? 'secondary' : 'primary'}>
          <div className={styles.fileUploader}>
            <label className={styles.label}>{label}</label>

            <div
              className={styles.buttonFileList}
              ref={listRef}
              onDragOver={onDragOver}
              onDragLeave={onDragLeave}
              onDrop={onDrop}
            >
              <div
                className={classes(styles.fileInput, styles[type || 'classic'])}
                onClick={() => {
                  if (hasFiles) {
                    form.submit();
                  } else if (inputRef.current) {
                    inputRef.current.click();
                  }
                }}
              >
                <Input />
              </div>
            </div>

            <ErrorDialog
              errors={errors}
              rules={rules}
              onClose={() => setErrors(undefined)}
            />
          </div>
        </Button>
        {Number(filesArrayBuffer?.length) > 0 && !autoSend ? (
          <>
            <div className={styles.buttonToolsArea}>
              <IconButton
                icon={Eye}
                onClick={() => setShowPdfModal(true)}
                type="dark"
                size={28}
                children={<div>{showFileLabel}</div>}
              />
              <IconButton
                icon={X}
                onClick={() => {
                  setFiles(state => state.filter(item => item !== files[0]));
                  setFilesArrayBuffer(state =>
                    state?.filter(item => item !== filesArrayBuffer[0])
                  );
                }}
                type="danger"
                size={28}
                children={<div>{cancelLabel}</div>}
              />
            </div>
            <Modal
              visible={showPdfModal}
              onBlur={() => setShowPdfModal(false)}
              width="auto"
              onClose={() => setShowPdfModal(false)}
            >
              <div>
                <ModalPDFViewer
                  pdfData={filesArrayBuffer ? filesArrayBuffer[0] : undefined}
                />
              </div>
            </Modal>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  };

  return type === 'button' ? (
    <ButtonUpload />
  ) : (
    <div className={styles.fileUploader}>
      <Label />
      <div
        className={classes(
          styles.fileList,
          highlight ? styles.highlight : null,
          styles[type || 'classic']
        )}
        ref={listRef}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <TransitionGroup
          className={classes(
            styles.transition,
            hasFilesUploaded ? styles.hasUploadedTransition : ''
          )}
        >
          {!hasFiles ? (
            <div
              className={classes(styles.fileInput, styles[type || 'classic'])}
              onClick={() => {
                if (hasFiles) {
                  form.submit();
                } else if (inputRef.current) {
                  inputRef.current.click();
                }
              }}
            >
              <Input />
            </div>
          ) : (
            files.map(file => {
              if (hasFiles && hasFilesUploaded) {
                setTimeout(() => {
                  setFiles(state => state.filter(item => item !== file));
                }, 2000);
              }
              return (
                <CSSTransition timeout={300} key={getKey(file)}>
                  <File
                    file={file}
                    onRemove={() =>
                      setFiles(state => state.filter(item => item !== file))
                    }
                    hasFilesUploaded={hasFilesUploaded}
                  />
                </CSSTransition>
              );
            })
          )}
        </TransitionGroup>
      </div>

      <ErrorDialog errors={errors} rules={rules} onClose={() => setErrors(undefined)} />
    </div>
  );
};

function containsFile(list: File[], file: File) {
  const key = getKey(file);

  for (let i = 0; i < list.length; ++i) {
    if (key === getKey(list[i])) {
      return true;
    }
  }
}

export default FileUploader;
