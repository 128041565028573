import React from 'react';

const TableArrow = ({ size = 1 }: { size?: number }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      width={`${size}em`}
      style={{ marginTop: '2px' }}
    >
      <path d="M4 3h14a2 2 0 012 2v7.08a6.01 6.01 0 00-4.32.92H12v4h1.08c-.11.68-.11 1.35 0 2H4a2 2 0 01-2-2V5c0-1.1.9-2 2-2m0 4v4h6V7H4m8 0v4h6V7h-6m-8 6v4h6v-4H4m15.44 8v-2h-4v-2h4v-2l3 3-3 3" />
    </svg>
  );
};

export default TableArrow;
