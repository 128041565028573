import React from 'react';
import styles from './UserBadge.module.scss';

type Props = {
  name?: string;
  onClick?: () => void;
  size?: number;
};

const UserBadge = ({ name, onClick, size = 40 }: Props) => {
  let initials;

  if (name) {
    const parts = name.replace(/\s+/g, ' ').trim().split(' ');

    if (parts.length) {
      initials = parts[0].charAt(0).toUpperCase();
    }

    if (parts.length === 1) {
      initials += parts[0].substring(1, 2).toUpperCase();
    }

    if (parts.length > 1) {
      initials += parts[parts.length - 1].charAt(0).toUpperCase();
    }
  }

  return (
    <div
      className={styles.userBadge}
      onClick={onClick}
      style={{
        width: size,
        height: size,
        borderRadius: size,
        fontSize: size * 0.4,
        minWidth: size,
        minHeight: size,
      }}
    >
      {initials}
    </div>
  );
};

export default UserBadge;
