import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { CounterpartDashboard, CounterpartEditor, CounterpartForm } from '.';
import { useDynamicHeader, withDynamicHeader } from 'components/containers/DynamicHeader';
import { useTranslation } from 'react-i18next';
import { ProjectStatus } from 'pages/project/types';

export type Props = {
  id: number;
  sigla: string;
  titulo: string;
  status: ProjectStatus;
  profile: 'supervisor' | 'pesquisador';
};

const Counterpart: React.FC<Props> = props => {
  const { path } = useRouteMatch();

  const { t } = useTranslation('project');

  const { setFooter } = useDynamicHeader();

  useEffect(() => {
    setFooter(t('editor.footer', { titulo: props.titulo, sigla: props.sigla }));
  }, [props, t, setFooter]);

  return (
    <Switch>
      <Route exact path={`${path}`} {...props}>
        <CounterpartDashboard />
      </Route>
      <Route exact path={`${path}/nova-contratacao`} {...props}>
        <CounterpartForm />
      </Route>
      <Route exact path={`${path}/:contrapartidaId`} {...props}>
        <CounterpartEditor />
      </Route>
    </Switch>
  );
};

export default withDynamicHeader(Counterpart);
