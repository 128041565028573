import React from 'react';

import { useTranslationX } from 'i18n';
import styles from './Button.module.scss';

type Props = {
  onClick?: () => void;
};

const Button = ({ onClick }: Props) => {
  const { tx } = useTranslationX('eap', 'proposal');

  return (
    <div className={styles.button} onClick={onClick}>
      <svg width="100%" height="100%">
        <rect x="1px" y="1px" width="118px" height="33px" rx="4px" />
      </svg>

      <span>{tx('add-button')}</span>
    </div>
  );
};

export default Button;
