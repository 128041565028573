import React from 'react';
import { UserBadge } from '..';

import styles from './NamedUserBadge.module.scss';

type Props = {
  name: string;
  cargo?: string;
};

const NamedUserBadge: React.FC<Props> = ({ name, cargo }) => {
  return (
    <div className={styles.container}>
      <div className={styles.user}>
        <UserBadge name={name} size={40} />
        <strong>{name}</strong>
      </div>
      {cargo && (
        <div className={styles.cargo}>
          <span>{cargo}</span>
        </div>
      )}
    </div>
  );
};

export default NamedUserBadge;
