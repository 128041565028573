export const STEP_W = 58;
export const MARGIN = STEP_W * 0.5;
export const INNER_MARGIN = 3;

export const COLORS = [
  '#8777D9',
  '#2684FF',
  '#57D9A3',
  '#00C7E6',
  '#FFC400',
  '#FF7452',
  '#6B778C',
  '#5243AA',
  '#0052CC',
  '#00875A',
  '#00A3BF',
  '#FF991F',
  '#DE350B',
  '#253858',
];
