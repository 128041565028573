import { ScrollPanel } from 'components/containers';
import React from 'react';
import { ConclusionPanel, MilestonesList, ScheduleList } from '..';

import styles from './Dashboard.module.scss';

const Dashboard = () => {
  return (
    <div className={styles.dashboard}>
      <ScrollPanel>
        <div className={styles.content}>
          <MilestonesList />
          <ConclusionPanel />
          <ScheduleList />
        </div>
      </ScrollPanel>
    </div>
  );
};

export default Dashboard;
