const sequence =
  '1qaz2wsx3edc4rfv5tgb6yhn7ujm8ik9ol0p1QAZ2WSX3EDC4RFV5TGB6YHN7UJM8IK9OL0P';

export const randomKey = (length: number) => {
  let key = '';

  for (let i = 0; i < length; ++i) {
    key += sequence.charAt(Math.random() * sequence.length);
  }

  return key;
};

export const randomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const createArrayFrom1ToN = (n: number) => {
  return Array.from({ length: n }, (_, i) => i + 1);
};

export const isNil = (value: any) => value === null || value === undefined;
export const isNotNil = (value: any) => value !== null && value !== undefined;

export const splitValueUnit = (value: number | string, defaultUnit = 'px') => {
  switch (typeof value) {
    case 'number':
      return { value, unit: defaultUnit, join: `${value}${defaultUnit}` };

    case 'string': {
      const unit = value.replace(/[0-9.]/g, '') || defaultUnit;
      value = Number(value.replace(/[^0-9.]/g, ''));

      return { value, unit, join: `${value}${unit}` };
    }

    default:
      break;
  }

  throw new Error(`invalid input: ${value}`);
};

export const call = (func?: (...args: any[]) => any, ...args: any[]) => {
  if (typeof func === 'function') {
    return func(...args);
  }
};

export const getValue = (object: { [key: string]: any }, key: string) =>
  object.hasOwnProperty(key) ? object[key] : null;

export const array2Object = (array?: any[]) => {
  const obj: { [key: number]: any } = {};

  if (array) {
    for (let i = 0; i < array.length; ++i) {
      if (!isNil(array[i])) {
        obj[i] = array[i];
      }
    }
  }

  return obj;
};

export interface AsyncSource {}
export class AsyncSource {}

export function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}

export const toString = (val: any) => (typeof val === 'string' ? val : String(val));
