import React from 'react';

const Person = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24">
    <g
      transform="matrix(.769 0 0 .769 2 2)"
      fill="none"
      strokeWidth="1.3"
      fillRule="evenodd"
    >
      <g fill="currentColor" strokeWidth="1.3" fillRule="nonzero">
        <g transform="translate(7)">
          <circle cx="6" cy="6" r="6" strokeWidth="1.3" />
        </g>
        <path d="m14.3 14h-2.62c-3.11 0.01-6.09 1.22-8.28 3.35s-3.42 5.03-3.41 8.05c0 0.331 0.277 0.6 0.62 0.6h24.8c0.357-0.031 0.628-0.325 0.62-0.672-0.014-6.25-5.24-11.3-11.7-11.3z" />
      </g>
    </g>
  </svg>
);

export default Person;
