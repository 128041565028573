import React, { Fragment, useEffect } from 'react';

import { useTranslationX } from 'i18n';

import { Grid, Row } from 'components/containers';
import { Spacer } from 'components/elements';
import {
  Autocomplete,
  CEP,
  DatePicker,
  FieldSet,
  HiddenField,
  Localidades,
  Select,
  TableSelect,
  TextArea,
  TextField,
  useForm,
} from 'components/form';
import { Search } from 'components/icons';

import { CardRoleHire } from 'pages/project';
import { RoleItem } from 'pages/project/types';

import { ContractAndPeople } from './RequestForm';

import styles from './RequestForm.module.scss';

type CLTFormProps = {
  item?: RoleItem;
  profileId?: number;
  data?: ContractAndPeople;
  projectId: number;
  onPopulateData: (data: any) => void;
};

export const CLTForm = ({
  item,
  profileId,
  data,
  projectId,
  onPopulateData,
}: CLTFormProps) => {
  const { tx } = useTranslationX('request.form', 'project');
  const form = useForm();

  useEffect(() => {
    form.setFieldValue('contrato.cargaHoraria', 220);
  }, [form]);

  return (
    <Grid maxWidth="970px" minWidth="970px">
      <HiddenField name="id" value={item?.id} />
      <HiddenField name="perfilId" value={profileId} />
      <FieldSet name="pessoa">
        <Row width={[0.4, 0.1, 0.5]} align="baseline">
          <TableSelect
            name="id"
            label={tx('person.select')}
            title={tx('person.title')}
            placeholder={tx('person.placeholder')}
            metadata={{ rules: { required: true } }}
            onPopulate={onPopulateData}
            value={
              data
                ? {
                    id: data?.pessoa.id,
                    nome: data?.pessoa.nome,
                  }
                : undefined
            }
            formatOption={option => option.nome}
            fetchUrl={`/projeto/${projectId}/recursos-humanos/${item?.id}/candidatos`}
            tableConfig={[
              {
                title: tx('name.label'),
                field: 'nome',
                observerField: 'pessoa.select.nome',
                align: 'left',
                search: true,
                weight: 1.2,
              },
              {
                title: tx('profile'),
                field: 'perfil',
                align: 'center',
                weight: 0.4,
              },
              {
                title: tx('titration'),
                field: 'titulacao',
                align: 'center',
                weight: 0.4,
              },
              { title: tx('link'), field: 'vinculo', align: 'center', weight: 0.4 },
            ]}
            style={{
              root: {
                margin: 0,
              },
            }}
          />
          <Fragment />
          <CardRoleHire
            role={item as RoleItem}
            cardStyle={{
              margin: '0px 0px 9px',
            }}
          />
        </Row>
      </FieldSet>
      <Spacer length={10} />
      <Row width={[0.3, 0.3, 0.3]}>
        <TextField
          name="pessoa.nome"
          label={tx('name.label')}
          placeholder={tx('name.placeholder')}
          align="left"
          type="readonly"
        />
        <TextField
          name="pessoa.cpf"
          label={tx('cpf')}
          placeholder={'000.000.000-00'}
          align="left"
          metadata={{
            rules: {
              required: true,
            },
          }}
          type="readonly"
        />
        <DatePicker name="contrato.dataNascimento" label={tx('birth')} align="left" />
      </Row>

      <FieldSet name="contrato">
        <Row width={[0.3, 0.3, 0.3]}>
          <TextField
            name="naturalidade"
            label={tx('naturalidade')}
            placeholder={tx('naturalidade')}
            align="left"
            metadata={{
              rules: {
                required: true,
              },
            }}
          />
          <Select
            name="estadoCivil"
            label={tx('estadoCivil.title')}
            metadata={{
              options: [
                { text: tx('estadoCivil.SO'), value: 'SO' },
                { text: tx('estadoCivil.CA'), value: 'CA' },
                { text: tx('estadoCivil.SE'), value: 'SE' },
                { text: tx('estadoCivil.DI'), value: 'DI' },
                { text: tx('estadoCivil.VI'), value: 'VI' },
              ],
              rules: {
                required: true,
              },
            }}
          />
          <Select
            name="genero"
            label={tx('gender.label')}
            metadata={{
              options: [
                { text: tx('gender.male'), value: 'M' },
                { text: tx('gender.female'), value: 'F' },
                { text: tx('gender.other'), value: 'O' },
              ],
            }}
          />
        </Row>

        <HiddenField name="tipo" value={item?.tipo} />

        <Spacer length={10} />
        <Row width={[0.3, 0.3, 0.3]}>
          <TextField name="rg" label={tx('rg')} placeholder={tx('rg')} align="left" />
          <TextField
            name="rgEmissor"
            label={tx('orgaoEmissor')}
            placeholder={tx('orgaoEmissor')}
            align="left"
          />
          <Select name="rgUf" label={tx('uf')} />
          <Localidades state="rgUf" prefix="contrato" />
        </Row>
        <Row width={[0.3, 0.3, 0.3]}>
          <Select
            name="formacao"
            label={tx('grauInstrucao.title')}
            metadata={{
              options: [
                { text: tx('grauInstrucao.analfabeto'), value: 'analfabeto' },
                { text: tx('grauInstrucao.basicoIncompleto'), value: 'basicoIncompleto' },
                { text: tx('grauInstrucao.basicoCompleto'), value: 'basicoCompleto' },
                {
                  text: tx('grauInstrucao.fundamentalIncompleto'),
                  value: 'fundamentalIncompleto',
                },
                {
                  text: tx('grauInstrucao.fundamentalCompleto'),
                  value: 'fundamentalCompleto',
                },
                { text: tx('grauInstrucao.medioIncompleto'), value: 'medioIncompleto' },
                { text: tx('grauInstrucao.medioCompleto'), value: 'medioCompleto' },
                {
                  text: tx('grauInstrucao.superiorIncompleto'),
                  value: 'superiorIncompleto',
                },
                { text: tx('grauInstrucao.superiorCompleto'), value: 'superiorCompleto' },
                { text: tx('grauInstrucao.mestre'), value: 'mestre' },
                { text: tx('grauInstrucao.doutor'), value: 'doutor' },
                { text: tx('grauInstrucao.ignorado'), value: 'ignorado' },
              ],
              rules: {
                required: true,
              },
            }}
          />
          <TextField
            name="carteiraProfissional"
            label={tx('carteiraProfissional')}
            placeholder={tx('carteiraProfissional')}
            align="left"
            metadata={{
              rules: {
                required: true,
              },
            }}
          />
          <TextField
            name="carteiraProfissionalSerie"
            label={tx('carteiraProfissionalSerie')}
            placeholder={tx('carteiraProfissionalSerie')}
            align="left"
            metadata={{
              rules: {
                required: true,
              },
            }}
          />
        </Row>
        <Row width={[0.3, 0.3, 0.3]}>
          <TextField
            name="tituloEleitor"
            label={tx('tituloEleitor')}
            placeholder={tx('tituloEleitor')}
            align="left"
            metadata={{ rules: { mask: { pattern: '#' } } }}
          />
          <TextField
            name="tituloEleitorZona"
            label={tx('tituloEleitorZona')}
            placeholder={tx('tituloEleitorZona')}
            align="left"
            metadata={{ rules: { mask: { pattern: '#' } } }}
          />
          <TextField
            name="tituloEleitorSecao"
            label={tx('tituloEleitorSecao')}
            placeholder={tx('tituloEleitorSecao')}
            align="left"
            metadata={{ rules: { mask: { pattern: '#' } } }}
          />
        </Row>
        <Row width={[0.3, 0.3, 0.3]}>
          <TextField
            name="certificadoMilitarN"
            label={tx('certificadoMilitarN')}
            placeholder={tx('certificadoMilitarN')}
            align="left"
            metadata={{ rules: { mask: { pattern: '#' } } }}
          />
          <TextField
            name="certificadoMilitarSerie"
            label={tx('certificadoMilitarSerie')}
            placeholder={tx('certificadoMilitarSerie')}
            align="left"
            metadata={{ rules: { mask: { pattern: '#' } } }}
          />
          <TextField
            name="certificadoMilitarCategoria"
            label={tx('certificadoMilitarCategoria')}
            placeholder={tx('certificadoMilitarCategoria')}
            align="left"
            metadata={{ rules: { mask: { pattern: '#' } } }}
          />
        </Row>

        <Row width={[0.4, 0.3]}>
          <TextField
            name="pis"
            label={tx('pis')}
            placeholder={tx('pis')}
            align="left"
            metadata={{ rules: { required: true, mask: { pattern: '#' } } }}
          />
          <DatePicker
            name="pisDataCadastro"
            label={tx('pisDataCadastro')}
            align="left"
            metadata={{
              rules: {
                required: true,
              },
            }}
          />
        </Row>

        <Row width={[0.4, 0.3]}>
          <TextField
            name="dependentes"
            label={tx('dependentes')}
            placeholder={tx('dependentes')}
            align="left"
            value={0}
            metadata={{ rules: { required: true, mask: { pattern: '#' } } }}
          />
        </Row>
        <Spacer length={10} />
      </FieldSet>

      <FieldSet name="contrato.localAtividades.endereco">
        <div className={styles.container}>
          <h1>{tx('endereco')}</h1>

          <Row width={[0.2, 0.6, 0.2]}>
            <TextField
              name="cep"
              label={tx('code')}
              metadata={{
                rules: {
                  required: true,
                },
              }}
            />

            <TextField name="logradouro" label={tx('street')} />

            <TextField name="numero" label={tx('n')} />
          </Row>
          <Row width={[0.45, 0.1, 0.45]}>
            <TextField name="bairro" label={tx('district')} />
            <Select
              name="uf"
              label={tx('uf')}
              metadata={{
                rules: {
                  required: true,
                },
              }}
            />
            <Select
              name="cidade"
              label={tx('city')}
              metadata={{
                rules: {
                  required: true,
                },
              }}
            />
          </Row>
          <Row width={[1]}>
            <TextField name="complemento" label={tx('complement')} />
          </Row>
          <Localidades
            city="cidade"
            prefix="contrato.localAtividades.endereco"
            state="uf"
          />
          <CEP
            cep="cep"
            uf="uf"
            logradouro="logradouro"
            bairro="bairro"
            cidade="cidade"
            prefix="contrato.localAtividades.endereco"
          />
        </div>
      </FieldSet>

      <FieldSet name="contrato">
        <div className={styles.container}>
          <h1>{tx('roleInformation')}</h1>
          <Row>
            <TextArea
              name="descricaoAtividades"
              minRows={3}
              label={tx('activitiesDescription')}
            />
          </Row>
          <Row width={[0.6, 0.4]}>
            <Autocomplete
              name="cargoCbo"
              placeholder={tx('cbo')}
              label={tx('cbo')}
              adornment={{ right: Search }}
              value={data?.contrato?.cargoCbo?.id}
              url="projetos/cbos"
              method="post"
              pageable={true}
              fieldQuery={['titulo']}
              formatOption={option => option.codigo + ' - ' + option.titulo}
              metadata={{
                rules: {
                  required: true,
                },
              }}
            />
            <TextField
              name="remuneracao"
              label={tx('remuneracao')}
              value={item?.remuneracao}
              metadata={{
                rules: {
                  required: true,
                  mask: { pattern: 'currency' },
                },
              }}
              type="readonly"
            />
          </Row>

          <Row width={[0.3, 0.3, 0.4]}>
            <TextField
              name="cargaHoraria"
              label={tx('monthlyWorkload')}
              placeholder="00"
              adornment={{ right: tx('hourspermonth') }}
            />
            <DatePicker
              name="dataInicio"
              label={tx('dataAdmissao')}
              metadata={{
                rules: {
                  required: true,
                },
              }}
            />
          </Row>
        </div>

        <FieldSet name="localAtividades">
          <HiddenField name="instituicao" value={tx('institution')} />
          <HiddenField name="orgao" value={tx('orgao')} />
          <HiddenField name="local" value={tx('place')} />
        </FieldSet>

        <FieldSet name="conta">
          <div className={styles.container}>
            <h1>{tx('bankData')}</h1>
            <Row width={[0.3, 0.2, 0.35]}>
              <TextField
                name="banco"
                label={tx('bank')}
                metadata={{
                  rules: {
                    required: true,
                  },
                }}
              />
              <TextField name="agencia" label={tx('agency')} />
              <TextField name="numero" label={tx('account')} />
            </Row>
          </div>
        </FieldSet>
      </FieldSet>
    </Grid>
  );
};
