import React, {useEffect, useState} from 'react';

import {Modal, ModalPDFViewer} from 'components/containers';
import {NotificationData} from '../../types';
import { useRequest } from 'apis';

type Props = {
    data: NotificationData;
    content: any;
    dispatch: any;
    onClose: (processed?: boolean) => void;
    reload: (data: NotificationData) => void;
    path: string;
};

const HiringDonwloadDeclaration = ({ content, onClose, path }: Props) => {
    const [pdfData, setPdfData] = useState<string | ArrayBuffer | null>(null);

    const request = useRequest();

    useEffect(() => {
        request<any>({
            url: `/projeto/${content?.projetoId}/recursos-humanos/${content?.prhId}/${path}`,
            method: 'GET',
            responseType: 'blob',
            onSuccess: (blob: any) => {
                let reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    let base64data = reader.result;
                    setPdfData(base64data);
                };
            },
            onError: data => console.error(data),
        });
    }, [content, request, path])

    return (
        <Modal
            visible={pdfData !== null}
            width="auto"
            onBlur={onClose}
            onClose={onClose}
        >
            <div>
                <ModalPDFViewer pdfData={pdfData} />
            </div>
        </Modal>
    );
};

export default HiringDonwloadDeclaration;
