import React, { useRef } from 'react';

import { useTranslation } from 'i18n';
import { Button } from 'components/elements';
import { Warning } from 'components/icons';
import { useVisible } from 'components/hooks';
import Dialog, { DialogProps } from './Dialog';

import styles from './ConfirmDialog.module.scss';

type Props = {
  visible: boolean;
  onSelect: (value: boolean) => void;
};

const ConfirmDialog: React.FC<Props & DialogProps> = props => {
  const { onSelect, ...rest } = props;
  const { visible, setVisible } = useVisible(props.visible);
  const { t } = useTranslation();
  const selected = useRef(false);

  return (
    <Dialog
      {...rest}
      width={530}
      visible={visible}
      icon={Warning}
      iconProps={{ className: styles.confirm }}
      onClose={() => onSelect(selected.current)}
      actions={
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => {
              selected.current = false;
              setVisible(false);
            }}
            type="secondary"
          >
            {t('components.dialog.confirm.no') as string}
          </Button>

          <Button
            style={{ root: { marginLeft: 32 } }}
            onClick={() => {
              selected.current = true;
              setVisible(false);
            }}
          >
            {t('components.dialog.confirm.yes') as string}
          </Button>
        </div>
      }
    />
  );
};

export default ConfirmDialog;
