import React from 'react';

const Info = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 20 20">
    <path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V9H11V15ZM11 7H9V5H11V7Z"
      fill="currentColor"
    />
  </svg>
);

export default Info;
