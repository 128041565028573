import React, { useRef, useState } from 'react';

import { useTranslationX } from 'i18n';
import { Button, PlainField, Spacer } from 'components/elements';
import { Dialog, DialogRef, Grid, HStack, Row, VStack } from 'components/containers';
import { Form, HiddenField, SubmitButton } from 'components/form';
import { NotificationData } from '../types';
import { UI_BUTTON_SPACING } from 'consts';

type Props = {
  data: NotificationData;
  accept: boolean;
  onClose: (processed?: boolean) => void;
};

type DoneProps = {
  onClick: () => void;
};

const ConfirmDialog = ({ data, accept, onClose }: Props) => {
  const [done, setDone] = useState(false);
  const dialog = useRef<DialogRef>(null);

  const { t, tx } = useTranslationX('supervisor:homologacao.dialog', [
    'translation',
    'supervisor',
  ]);
  return (
    <Dialog
      ref={dialog}
      title={tx('title')}
      titleAlign="center"
      visible={!!data}
      onClose={onClose}
      closeButton
      width={676}
    >
      {done && <DoneContent onClick={() => dialog.current?.close(true)} />}
      {!done && (
        <Form
          baseUrl="/homologacao"
          submit={{ format: 'multipart' }}
          onDone={() => setDone(true)}
        >
          <Grid spacing={5}>
            <Row align="bottom">
              <div>
                <PlainField
                  label={tx('type')}
                  value={`${t(`notification.${data.type}`)}: ${data.subtitle}`}
                />

                <PlainField
                  label={tx('status.label')}
                  value={tx(accept ? 'status.accept' : 'status.refuse')}
                />
              </div>
            </Row>

            <HiddenField name="notificationId" value={data.id} />
            <HiddenField name="tipo" value={data.type} />
            <HiddenField name="accept" value={accept} />
          </Grid>

          <HStack align="center" spacing={30}>
            <Button
              type="secondary"
              onClick={() => dialog.current?.close()}
              style={{ root: { minWidth: 120 } }}
            >
              {t('button.cancel')}
            </Button>
            <Spacer length={UI_BUTTON_SPACING} />
            <SubmitButton style={{ root: { minWidth: 120 } }}>
              {t('button.confirm')}
            </SubmitButton>
          </HStack>
        </Form>
      )}
    </Dialog>
  );
};

const DoneContent = ({ onClick }: DoneProps) => {
  const { t, tx } = useTranslationX('supervisor:homologacao.dialog', [
    'translation',
    'supervisor',
  ]);

  return (
    <VStack align="center" spacing={30} style={{ paddingTop: '5rem' }}>
      <span
        style={{
          padding: '5px',
          fontFamily: 'Open sans',
          color: '#333',
          fontSize: '14px',
        }}
      >
        {tx('success')}
      </span>

      <Spacer length={UI_BUTTON_SPACING} />
      <Button onClick={onClick} style={{ text: { textTransform: 'uppercase' } }}>
        {t('button.ok')}
      </Button>
    </VStack>
  );
};

export default ConfirmDialog;
