import React, { Children, CSSProperties, ReactNode } from 'react';

import { classes } from 'utils/components';
import styles from './Grid.module.scss';

type Props = {
  width?: number[];
  space?: number;
  align?: 'center' | 'top' | 'bottom' | 'stretch' | 'baseline';
  children?: ReactNode;
};

const Row: React.FC<Props> = ({ width, space = 18, align = 'bottom', children }) => {
  let sum = 0;
  let acc = 0;

  if (width) {
    for (const w of width) {
      sum += w;
    }
  } else {
    sum = React.Children.count(children);
    width = Array(sum).fill(1);
  }

  const computeStyle = (index: number) => {
    const style: CSSProperties = {};

    if (index < width!.length - 1) {
      const w = Math.round((width![index] * 10000) / sum) / 100;
      acc += w;
      style.width = `${w}%`;

      if (space) {
        style.paddingRight = space;
      }
    } else {
      style.width = `${100 - acc}%`;
    }

    return style;
  };

  return (
    <div className={classes(styles.row, styles[align])}>
      {Children.map(children, (child, index) => (
        <div style={computeStyle(index)}>{child}</div>
      ))}
    </div>
  );
};

export default Row;
