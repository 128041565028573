import React, { forwardRef, useEffect, useState, useRef, useMemo } from 'react';
import Tippy from '@tippyjs/react';
import { InputProps, IntervalFilter } from '../types';
import { classes, renderComponent } from 'utils/components';

import { Calendar, X } from 'components/icons';
import { useTranslationX } from 'i18n';
import { formatDateToBrazilian, safeDate } from 'utils/stringUtils';

import DatePickerComponent, {
  DatePickerData,
} from './DatePickerComponent/DatePickerComponent';
import { call } from 'utils';
import { toDate, toSQLTimestamp } from 'utils/calendarUtils';

import styles from './DatePicker.module.scss';

export type Props = {
  align?: 'left' | 'center' | 'right';
  adornment?: string | { left?: string; right?: string };
  icon?: React.ElementType;
  range?: boolean;
  showTime?: boolean;
};

type ModProps = Omit<InputProps, 'value'> & {
  value?: IntervalFilter | string;
};

const DatePicker = forwardRef<HTMLDivElement, Props & ModProps>((props, ref) => {
  const { value, control, inputProps, range, showTime = true } = props;

  const { type } = control;
  const newValue = useMemo(() => {
    if (value && typeof value === 'string') {
      if (value !== toSQLTimestamp(value) && showTime) {
        return toSQLTimestamp(value);
      }

      if (value !== toDate(value) && !showTime) {
        return toDate(value);
      }
    }
    return value;
  }, [value, showTime]);
  const [expanded, setExpanded] = useState(false);
  const [selected, setSelected] = useState<IntervalFilter | string | undefined>(newValue);

  const { tx } = useTranslationX('components', 'translation');

  const anchorRef = useRef<HTMLDivElement>();

  const popupRef = useRef<HTMLDivElement>();

  function attachRef(el: HTMLDivElement) {
    anchorRef.current = el;

    if (typeof ref === 'function') {
      ref(el);
    } else if (ref) {
      ref.current = el;
    }
  }

  function attachDropdownRef(el: HTMLDivElement) {
    popupRef.current = el;

    if (typeof ref === 'function') {
      ref(el);
    } else if (ref) {
      ref.current = el;
    }
  }

  const { onChange, onBlur, onFocus, ...rest } = inputProps;

  useEffect(() => {
    onChange(selected);
  }, [selected, onChange]);

  useEffect(() => {
    setSelected(newValue);
  }, [newValue]);

  const handleBlur = () => {
    setExpanded(false);
    call(onBlur);
  };

  const handleChange = (data: DatePickerData) => {
    if (range) {
      setSelected({
        from: toSQLTimestamp(data.current[0].toISOString()),
        to: toSQLTimestamp(data.current[1].toISOString()),
      });
    } else if (!showTime) {
      setSelected(toDate(data.current[0].toISOString()));
    } else {
      setSelected(toSQLTimestamp(data.current[0].toISOString()));
    }
  };

  const getCurrentValue = () => {
    if (typeof selected === 'string') {
      return [safeDate(selected)];
    } else {
      if (selected && selected.from && selected.to) {
        return [
          safeDate(`${selected.from}T00:00:00`),
          safeDate(`${selected.to}T00:00:00`),
        ];
      }
    }
  };

  const renderPopupContent = (props: object) => {
    return (
      <DatePickerComponent
        tippyProps={props}
        range={range}
        value={getCurrentValue()}
        onChange={handleChange}
        onClose={handleBlur}
        attachDropdownRef={attachDropdownRef}
      />
    );
  };

  const renderPopup = () =>
    control.ref.current && expanded ? (
      <Tippy
        appendTo={() => document.body}
        placement="auto"
        offset={[0, 7]}
        render={renderPopupContent}
        interactive={true}
        visible={expanded}
        reference={control.ref}
        onClickOutside={(_, e) => {
          if (
            !popupRef.current ||
            (popupRef.current && !popupRef.current.contains(e.target as HTMLElement))
          ) {
            handleBlur();
          }
        }}
        popperOptions={{
          modifiers: [
            {
              name: 'preventOverflow',
              enabled: false,
            },
          ],
        }}
        aria={{ content: null, expanded: false }}
      />
    ) : null;

  function handleFocus(event: React.FocusEvent) {
    if (anchorRef.current?.contains(event.target) && !expanded) {
      call(event.type === 'blur' ? onBlur : onFocus);
    }
  }

  const handlers = {
    onClick: () => setExpanded(expanded => !expanded),
    onBlur: handleFocus,
    onFocus: handleFocus,
  };

  const getValueOrPlaceHolder = (value?: any) => {
    return value !== undefined ? formatDateToBrazilian(value) : 'dd/mm/yyyy';
  };

  return (
    <div className={classes(styles.datePicker, styles[control.type || ''])}>
      <div className={styles.icon}>
        {!selected || type === 'readonly' ? (
          renderComponent(Calendar, {
            size: 1.2,
          })
        ) : (
          <Tippy
            className={classes(styles.popup)}
            content={tx('date_picker.clear')}
            animation="perspective"
            touch={false}
          >
            <div
              className={styles.iconRemove}
              onClick={() => {
                setSelected(undefined);
                control.ref.current.value = '';
              }}
            >
              {renderComponent(X, {
                size: 1.4,
              })}
            </div>
          </Tippy>
        )}
      </div>
      <div
        {...rest}
        ref={attachRef}
        className={styles.input}
        role="button"
        aria-haspopup="listbox"
        aria-expanded={expanded}
        {...handlers}
      >
        <div
          className={classes(
            styles.content,
            selected === undefined ? styles.placeholder : null,
            range ? styles.range : null
          )}
        >
          {range ? (
            <>
              <span>{getValueOrPlaceHolder((selected as IntervalFilter)?.from)}</span>
              <span>{getValueOrPlaceHolder((selected as IntervalFilter)?.to)}</span>
            </>
          ) : (
            <span>{getValueOrPlaceHolder(selected)}</span>
          )}
        </div>
      </div>
      {renderPopup()}
    </div>
  );
});

DatePicker.displayName = 'DatePicker';
export default DatePicker;
