import React from 'react';
import ReactApexChart from 'react-apexcharts';

type Props = {
  data: GraphData[];
  parser?: {
    x?: (value: any) => string;
    y?: (value: any) => string;
  };
  scales?: {
    x?: {
      max: number;
      min: number;
      ticks: number;
    };
    y?: {
      max: number;
      min: number;
    };
  };
  style: {
    width?: number | string;
    height?: number | string;
  };
};

export type GraphData = {
  id?: number;
  x: string | number;
  y: string | Date | number;
};

const Smooth2DGraph: React.FC<Props> = ({ data, parser, style, scales }) => {
  const series: ApexAxisChartSeries = [
    {
      data: data.map(item => ({
        x: item.x,
        y: item.y,
      })),
      name: 'Progresso',
    },
  ];

  return (
    <ReactApexChart
      options={{
        colors: ['#2F6F3A'],

        fill: {
          gradient: {
            gradientToColors: ['#37A64A', '#FFFF'],
            opacityTo: 0.3,
            opacityFrom: 0.8,
          },
        },
        stroke: {
          curve: 'smooth',
          width: 1,
          colors: ['#333333'],
          lineCap: 'round',
        },
        yaxis: {
          max: scales?.y?.max,
          min: scales?.y?.min,
          tickAmount: 4,

          labels: {
            formatter: parser?.y || ((value: number) => `${value}%`),
            style: {
              colors: '#9C9C9C',
              fontSize: '14px',
              fontWeight: 400,
              fontFamily: 'Roboto',
            },
          },
        },
        xaxis: {
          max: scales?.x?.max,
          min: scales?.x?.min,
          type: 'numeric',
          tickAmount: (scales?.x?.ticks || 0) - 1,
          labels: {
            formatter(value) {
              if (parser?.x) {
                return parser.x(value);
              } else {
                return new Date(value).toLocaleDateString('pt-BR');
              }
            },

            style: {
              colors: '#9C9C9C',
              fontSize: '14px',
              fontWeight: 400,
              fontFamily: 'Roboto',
            },
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          yaxis: {
            lines: {
              show: false,
            },
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
          borderColor: '#E5E5E5',
          position: 'back',
        },
        tooltip: {
          x: {
            show: true,
          },
          y: {
            formatter(val) {
              return `${val} %`;
            },
          },
        },
      }}
      series={series}
      width={style.width}
      height={style.height}
      type="area"
    />
  );
};

export default Smooth2DGraph;
