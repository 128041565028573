import React from 'react';

const XAlt = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none">
    <path
      fill="currentColor"
      d="m21.5 3.61-1.92-2.11-7.61 8.38-7.61-8.38-1.92 2.11 7.61 8.38-7.61 8.38 1.92 2.12 7.61-8.39 7.61 8.39 1.92-2.12-7.61-8.38z"
    />
  </svg>
);

export default XAlt;
