import React from 'react';

const TriangleBars = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none">
    <path
      d="m2.55 6.75c0 0.577 0.472 1.05 1.05 1.05h16.8c0.578 0 1.05-0.472 1.05-1.05 0-0.578-0.472-1.05-1.05-1.05h-16.8c-0.578 0-1.05 0.472-1.05 1.05zm8.4 11.5h2.1c0.578 0 1.05-0.472 1.05-1.05s-0.472-1.05-1.05-1.05h-2.1c-0.578 0-1.05 0.472-1.05 1.05s0.472 1.05 1.05 1.05zm6.3-5.25h-10.5c-0.577 0-1.05-0.472-1.05-1.05 0-0.578 0.473-1.05 1.05-1.05h10.5c0.577 0 1.05 0.472 1.05 1.05 0 0.577-0.472 1.05-1.05 1.05z"
      clipRule="evenodd"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default TriangleBars;
