import React, { ReactNode, useEffect, useState } from 'react';
import Tippy from '@tippyjs/react';
import ResizeObserver from 'resize-observer-polyfill';

import { ScrollPanel } from '..';
import { call } from 'utils';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/svg-arrow.css';
import 'tippy.js/animations/perspective.css';

import styles from './TreeList.module.scss';

type Title = string | ReactNode | ((branch: Branch) => ReactNode);

type Props = {
  data: Branch[];
  title1?: Title;
  title2?: Title;
  onClick?: (item: Item) => void;
  parseBranch?: (text: string) => string;
  parseItem?: (text: string) => string;
};

export type Branch = {
  text: string;
  children: Item[];
};

type Item = {
  text: string;
  value: number | string;
};

const TreeList = ({ data, title1, title2, onClick, parseBranch, parseItem }: Props) => {
  const [selected, setSelected] = useState(data[0]);
  const [content, setPopupRef] = useState<HTMLDivElement>();
  const [, refresh] = useState({});

  useEffect(() => {
    const ro = new ResizeObserver(refresh);

    if (content) {
      ro.observe(content);
    }

    return () => ro.disconnect();
  }, [content]);

  const renderItems = (items: Item[]) => {
    return (
      <div className={styles.contentList}>
        <ScrollPanel
          style={{
            root: {
              width: content!.offsetWidth - 16,
              maxHeight: content!.offsetHeight - 16,
            },
          }}
          vBar={{ overlay: true }}
        >
          <ul>
            {items.map(el => (
              <li key={el.value} onClick={() => call(onClick, el)}>
                {parseItem ? parseItem(el.text) : el.text}
              </li>
            ))}
          </ul>
        </ScrollPanel>
      </div>
    );
  };

  const renderBranch = (el: Branch, index: number) => {
    const label = (
      <li
        className={el === selected ? styles.selected : ''}
        onClick={() => setSelected(el)}
      >
        {parseBranch ? parseBranch(el.text) : el.text}
      </li>
    );

    return el.children.length ? (
      <Tippy
        key={index}
        className={styles.popup}
        appendTo={() => content || document.body}
        content={renderItems(el.children)}
        allowHTML={true}
        placement="right"
        interactive={true}
        visible={el === selected}
        maxWidth="none"
        offset={[0, 16]}
        popperOptions={{
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                rootBoundary: content,
                tether: false,
              },
            },
          ],
        }}
      >
        {label}
      </Tippy>
    ) : (
      label
    );
  };

  const renderTitle = (title: Title) => {
    if (title) {
      switch (typeof title) {
        case 'string':
          return <span className={styles.title}>{title}</span>;

        case 'function':
          return <span className={styles.title}>{title(selected)}</span>;

        default:
          return title;
      }
    }

    return null;
  };

  return (
    <div className={styles.treeList}>
      <div>
        {renderTitle(title1)}

        <ScrollPanel vBar={{ position: 'start', overlay: true }}>
          <ul>{content ? data.map((el, index) => renderBranch(el, index)) : null}</ul>
        </ScrollPanel>
      </div>

      <div>
        {renderTitle(title2)}
        <div className={styles.container} ref={el => (el ? setPopupRef(el) : null)}></div>
      </div>
    </div>
  );
};

export default TreeList;
