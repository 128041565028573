import { useEffect, useRef, useState } from 'react';

import { useRequest } from 'apis';
import { useFieldSet, useForm, useFormObserver } from '.';
import { isNil } from 'utils';
import { useFirstRender } from 'components/hooks';

type Props<T> = {
  url: string;
  target: string;
  children: (data: Data<T>) => JSX.Element;
  param?: string;
  initialValue?: any;
  initialData?: T;
  errorMessage?: string;
};

type Data<T> = {
  status: 'init' | 'searching' | 'success' | 'invalid' | 'error';
  search: any;
  data?: T;
};

const FieldSearch = function <T = any>(props: Props<T>) {
  const {
    url,
    target,
    children,
    param = 'text',
    initialValue,
    initialData,
    errorMessage,
  } = props;

  const fieldSet = useFieldSet();
  const request = useRequest();
  const form = useForm();

  const fieldName = `${fieldSet.prefix}${target}`;
  const result = useFormObserver(fieldName, !initialValue);
  const firstRender = useFirstRender();

  const value =
    firstRender && initialValue !== undefined ? initialValue : result?.data?.value;
  const valid = result?.data?.valid;

  const [data, setData] = useState<Data<T>>({
    status: 'init',
    search: value,
    data: initialData,
  });

  const search = useRef<any>(value);

  useEffect(() => {
    if (value !== search.current) {
      let ret: (() => void) | undefined;

      if (search.current !== undefined && isNil(value)) {
        setData({ status: 'success', search: value });
      } else if (valid && (value || typeof value === 'number')) {
        setData({ status: 'searching', search: value });

        const source = request<T>({
          url,
          method: 'GET',
          params: { [param]: value },
          onSuccess: data => {
            if (!data && errorMessage) {
              form.addRule(fieldName, 'rejected', {
                value,
                message: errorMessage,
              });
            }

            setData({ status: 'success', search: value, data });
          },
          onError: () => setData({ status: 'error', search: value }),
        });

        ret = () => source.cancel();
      } else {
        setData({ status: 'invalid', search: value });
      }

      search.current = value;
      return ret;
    }
  }, [errorMessage, fieldName, form, param, request, url, valid, value]);

  return children(data);
};

export default FieldSearch;
