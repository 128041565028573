import React from 'react';

import { useTranslationX } from 'i18n';
import { useFieldSet, useForm, useFormObserver } from '..';

import styles from './Counter.module.scss';

type Props = {
  target: string;
  style?: React.CSSProperties;
};

const Counter = ({ target, style }: Props) => {
  const form = useForm();
  const fieldSet = useFieldSet();

  const path = `${fieldSet.prefix}${target}`;
  const maxlength = form.getFieldMetadata(path).rules?.maxlength;
  const value = useFormObserver(path)?.data?.value;

  const { tx } = useTranslationX('components.form.counter', 'translation');

  return (
    <span className={styles.counter} style={style}>
      {tx('remaining', { counter: value ? maxlength - String(value).length : maxlength })}
    </span>
  );
};

export default Counter;
