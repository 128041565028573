import React from 'react';

const ConfigAlt = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none">
    <path
      d="m12 14.6c1.43 0 2.59-1.16 2.59-2.59 0-1.43-1.16-2.59-2.59-2.59-1.43 0-2.59 1.16-2.59 2.59 0 1.43 1.16 2.59 2.59 2.59z"
      clipRule="evenodd"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="m18.4 14.6c-0.235 0.533-0.122 1.16 0.285 1.57l0.0519 0.0519c0.324 0.324 0.507 0.764 0.507 1.22 0 0.458-0.182 0.898-0.507 1.22-0.324 0.324-0.764 0.507-1.22 0.507-0.458 0-0.898-0.182-1.22-0.507l-0.0519-0.0519c-0.416-0.407-1.04-0.52-1.57-0.285-0.522 0.224-0.861 0.736-0.864 1.3v0.147c0 0.954-0.773 1.73-1.73 1.73-0.954 0-1.73-0.773-1.73-1.73v-0.0777c-0.0137-0.585-0.384-1.1-0.933-1.3-0.533-0.235-1.16-0.122-1.57 0.285l-0.0518 0.0519c-0.324 0.324-0.764 0.507-1.22 0.507-0.458 0-0.898-0.182-1.22-0.507-0.324-0.324-0.507-0.764-0.507-1.22s0.182-0.898 0.507-1.22l0.0518-0.0519c0.407-0.416 0.52-1.04 0.285-1.57-0.224-0.522-0.736-0.861-1.3-0.864h-0.147c-0.954 0-1.73-0.773-1.73-1.73 0-0.954 0.773-1.73 1.73-1.73h0.0777c0.585-0.0136 1.1-0.384 1.3-0.933 0.235-0.533 0.122-1.16-0.285-1.57l-0.0518-0.0518c-0.324-0.324-0.507-0.764-0.507-1.22 0-0.458 0.182-0.898 0.507-1.22 0.324-0.324 0.764-0.507 1.22-0.507 0.458 0 0.898 0.182 1.22 0.507l0.0518 0.0518c0.416 0.407 1.04 0.52 1.57 0.285h0.0691c0.522-0.224 0.861-0.736 0.864-1.3v-0.147c0-0.954 0.773-1.73 1.73-1.73 0.954 0 1.73 0.773 1.73 1.73v0.0777c0.0027 0.568 0.342 1.08 0.864 1.3 0.533 0.235 1.16 0.122 1.57-0.285l0.0519-0.0518c0.324-0.324 0.764-0.507 1.22-0.507 0.458 0 0.898 0.182 1.22 0.507 0.324 0.324 0.507 0.764 0.507 1.22 0 0.458-0.182 0.898-0.507 1.22l-0.0519 0.0518c-0.407 0.416-0.52 1.04-0.285 1.57v0.0691c0.224 0.522 0.736 0.861 1.3 0.864h0.147c0.954 0 1.73 0.773 1.73 1.73 0 0.954-0.773 1.73-1.73 1.73h-0.0777c-0.568 0.0027-1.08 0.342-1.3 0.864z"
      clipRule="evenodd"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default ConfigAlt;
