import React from 'react';

import Field, { Props as FieldProps } from '../Field/Field';
import Input, { Props as InputProps } from 'components/inputs/ColorPicker/ColorPicker';

const ColorPicker: React.FC<FieldProps & InputProps> = ({ label, ...rest }) => {
  const props = { title: label, ...rest };
  return <Field {...props} component={Input} theme="sneaky" />;
};

ColorPicker.displayName = 'ColorPickerField';
export default ColorPicker;
