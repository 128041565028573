/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Loader, Smooth2DGraph, WrapperCard } from 'components/elements';
import { useTranslationX } from 'i18n';
import { Form, Select, useForm, useFormObserver } from 'components/form';
import { AsyncSource } from 'utils';
import { useRequest } from 'apis';

import { Milestone, MilestoneEvolution } from 'pages/project/Sections/Milestones/types';
import { getYearAndMonth, readDate } from 'utils/calendarUtils';

import styles from './MilestonesEvolution.module.scss';

type Props = {
  id?: number;
};

const MilestonesEvolution = (props: Props) => {
  return (
    <WrapperCard padding="10px 20px" maxWidth="100%">
      <Form baseUrl="/projetos/projeto">
        <FormContent {...props} />
      </Form>
    </WrapperCard>
  );
};

type MilestoneWithEvolution = Milestone & {
  evolucao: MilestoneEvolution[];
};
const FormContent = (props: Props) => {
  const { id } = props;
  const [milestone, setMilestone] = useState<MilestoneWithEvolution | undefined>();

  const form = useForm();
  const request = useRequest();

  const { tx } = useTranslationX('dashboard', 'project');

  const observer = useFormObserver('milestone');

  const milestoneId = observer?.data?.value;
  // Set day 15 of the month

  const timestampStart = readDate(milestone?.inicio).set('date', 15).toDate().getTime();
  const timestampEnd = readDate(milestone?.dataEntrega)
    .set('date', 15)
    .toDate()
    .getTime();

  const fetchMilestone = useCallback(() => {
    if (milestoneId !== undefined) {
      request<Milestone>({
        url: `/projeto/${id}/macroentregas/${milestoneId}`,
        onSuccess: (data: Milestone) => {
          setMilestone(milestone => {
            if (milestone) {
              return { ...milestone, ...data };
            }
            return { ...data, evolucao: [] };
          });
        },
      });

      if (milestoneId === -1) {
        request<MilestoneEvolution[]>({
          url: `/projeto/${id}/macroentregas/${milestoneId}/evolucao`,
          onSuccess: (data: MilestoneEvolution[]) => {
            setMilestone(milestone => {
              if (milestone) {
                return { ...milestone, evolucao: data };
              }
              return { evolucao: data, ...({} as Milestone) };
            });
          },
        });
      }
    }
  }, [id, milestoneId, request]);

  useEffect(() => {
    fetchMilestone();
  }, [fetchMilestone]);

  useEffect(() => {
    form.setFieldMetadata('milestone', { options: new AsyncSource() });

    const source = request<Milestone[]>({
      url: `/projeto/${id}/macroentregas`,

      onSuccess: data => {
        const options = data.map(item => ({
          value: item.id,
          text: item.titulo,
        }));
        options.unshift({ value: -1, text: tx('allMilestones') });
        form.setFieldMetadata('milestone', { options });
        form.setFieldValue('milestone', options[0].value);
      },

      onError: () => form.setFieldMetadata('milestone', { options: [] }),
      onCancel: () => form.setFieldMetadata('milestone', { options: [] }),
    });
    return () => source.cancel();
  }, [form, id, request]);

  return (
    <>
      <Select label="Macroentrega" name="milestone" />
      <h2 className={styles.sectionTitle}>{tx('progressHistory')}</h2>
      {milestone ? (
        <Smooth2DGraph
          data={milestone.evolucao
            .map(e => ({
              y: e.conclusao,
              id: e.id,
              x: readDate(e.data).set('date', 15).set('hour', 12).toDate().getTime(),
            }))
            .sort((a, b) => a.x - b.x)}
          scales={{
            y: {
              min: 0,
              max: 100,
            },
            x: {
              ticks: milestone.duracao,
              min: timestampStart || 0,
              max: timestampEnd || 0,
            },
          }}
          parser={{
            x(value) {
              return getYearAndMonth(value);
            },
          }}
          style={{
            height: 292,
          }}
        />
      ) : (
        <Loader />
      )}
    </>
  );
};

export default MilestonesEvolution;
