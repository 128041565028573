import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { useRequest } from 'apis';
import { API_SERVER_URL } from 'consts';

type Props = {
  id: number;
  publicFile?: boolean;
  children?: ReactNode;
};

type Status = 'idle' | 'requesting';

const FileDownloader: React.FC<Props> = ({ id, publicFile = false, children }) => {
  const [token, setToken] = useState<string>();

  const status = useRef<Status>('idle');
  const ref = useRef<HTMLAnchorElement>(null);
  const request = useRequest();

  useEffect(() => {
    if (token) {
      ref.current?.click();
      setToken(undefined);
    }
  }, [token]);

  const onClick = () => {
    if (status.current === 'idle') {
      status.current = 'requesting';

      request<any>({
        url: publicFile ? `/public/arquivo/${id}` : `/arquivo/${id}`,
        onSuccess: setToken,
        onError: () => setToken(undefined),
        onComplete: () => (status.current = 'idle'),
      });
    }
  };

  return (
    <div>
      <div style={{ display: 'inline' }} onClick={onClick}>
        {children}
      </div>

      <a
        ref={ref}
        style={{ display: 'none' }}
        href={
          publicFile
            ? `${API_SERVER_URL}/public/arquivo/${id}`
            : `${API_SERVER_URL}/resource/${token}`
        }
        download
      >
        download
      </a>
    </div>
  );
};

export default FileDownloader;
