import Dashboard from './Dashboard/Dashboard';

export { default as ConfigPanel } from './ConfigPanel/ConfigPanel';
export { default as ContentSwitcher } from './ContentSwitcher/ContentSwitcher';
export { default as SubMenu } from './SubMenu/SubMenu';
export { default as ConfigTopPanel } from './ConfigTopPanel/ConfigTopPanel';
export { default as TopSubMenu } from './TopSubMenu/TopSubMenu';
export { default as ProposalTotalChart } from './ProposalTotalChart/ProposalTotalChart';

export default Dashboard;
