import { useForm, useFormObserver } from 'components/form';

type Props<T> = {
  tableName: string;
  columnName: string;
  groupBy?: GroupBy;
  children?: (data: T[]) => JSX.Element;
};

type GroupBy = (string | number)[][];

const ColumnObserver = function <T = any>({
  tableName,
  columnName,
  children,
  groupBy,
}: Props<T>) {
  const form = useForm();
  const data = form.getCollection(tableName);

  useFormObserver(new RegExp(`^${tableName}\\[\\d+\\].${columnName}$`), false, true);

  const values: T[] = [];

  const groupByValue = groupBy as GroupBy;

  if (data && Array.isArray(data[tableName])) {
    for (const item of data[tableName]) {
      if (
        !item ||
        item[groupByValue?.[0][0]] !== groupByValue?.[0][1] ||
        item[groupByValue?.[1][0]] !== groupByValue?.[1][1]
      ) {
        continue;
      }

      values.push(item[columnName]);
    }
  }

  return typeof children === 'function' ? children(values) : null;
};

export default ColumnObserver;
