import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Button,
  GoBackButton,
  Loader,
  Spacer,
  WrapperCard,
  FileUploader,
} from 'components/elements';
import {
  Dialog,
  DialogRef,
  InfoDialog,
  Modal,
  ModalPDFViewer,
  ReadOnlyTableData,
  ReadOnlyTableDataConfig,
  Row,
  ScrollPanel,
  VStack,
} from 'components/containers';
import { ConfirmDialog } from 'pages/supervisor/homologacao/project';
import { useTranslation, useTranslationX } from 'i18n';
import { Profile } from 'pages/register/profile';
import { getMasked } from 'utils/inputMask';
import { messageService } from 'services';
import { ResponseExtras, useFetch, useRequest } from 'apis';

import { Check, Download, Eye, PDF, Upload, X } from 'components/icons';

import { useDynamicHeader, withDynamicHeader } from 'components/containers/DynamicHeader';
import { TableAction } from 'components/containers/TableList/TableListItem';
import { Solicitation } from 'pages/project/types';
import { EditorState } from 'pages/project/ProjectEditor/types';
import Tippy from '@tippyjs/react';
import { appendDots, formatDateToBrazilian } from 'utils/stringUtils';
import { getMonthNameShort, getYearShort } from 'utils/calendarUtils';
import { UI_BUTTON_SPACING } from 'consts';
import { Form } from 'components/form';
import { FormInstance } from 'components/form/types';
import { followCursor } from 'tippy.js';

import styles from './RequestDetails.module.scss';
import { downloadBlob } from 'utils/file';

type Props = EditorState & {
  actions: TableAction[];
  solicitation: Solicitation;
};

type ConfigType = {
  title: string;
  field: string;
  width: number;
  parse?: (value?: string) => string;
  type?: 'value' | 'archive' | 'archives' | 'date' | 'list' | 'link' | 'title' | 'html';
};

type RequestType = ConfigType[][];

type RequestTypes = {
  servico: RequestType;
  compra: RequestType;
  pagamento: RequestType;
  diaria: RequestType;
  passagem: RequestType;
  rh: RequestType;
  aceiteMacro: RequestType;
  desligamento: RequestType;
  ferias: RequestType;
  transferencia: RequestType;
  prorrogacao: RequestType;
  'alteracao-pt': RequestType;
};

type RhArchives = {
  docRg: number | null;
  docComprovanteResidencia: number | null;
  docDeclaracaoMatricula: number | null;
  docHistoricoEscolar: number | null;
  docComprovanteContaCorrente: number | null;
  docDescricaoProcessoSeletivo: number | null;
  docSolicitacaoDeContratacaoDeBolsa: number | null;
  docOutorgaEConcessao: number | null;
  docAutorizacaoPolo: number | null;
  docTermoConfidencialidade: number | null;
  docContracheque: number | null;
  docDeclaracaoVencimentos: number | null;
  docDeclaracaoPi: number | null;
  docComprovanteTitulacaoFormacao: number | null;
  docCurriculo: number | null;
  docCtps: number | null;
  docCpf: number | null;
  docReservista: number | null;
  docTituloEleitor: number | null;
  docFoto: number | null;
  docCertidaoCasamento: number | null;
  docFormularioContratacao: number | null;
};

type OpenAvaliacaoModal = {
  coordenador: boolean;
  open: boolean;
};

const RequestDetails = (props: Props) => {
  const { id, section, solicitation, actions, profile } = props;
  const history = useHistory();

  const [requestSelected, setRequestSelected] = useState<Solicitation>();
  const [showRequestForm, setShowRequestForm] = useState<boolean>(false);
  const [pdfData, setPdfData] = useState<string | ArrayBuffer | null>(null);
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [showHrDialog, setShowHrDialog] = useState(false);

  const [showInfoRefuseDialog, setShowInfoRefuseDialog] = useState(false);
  const [accept, setAccept] = useState<boolean>(true);
  const [openAvaliacaoModal, setOpenAvaliacaoModal] = useState<OpenAvaliacaoModal>({
    open: false,
    coordenador: false,
  });

  const isSupervisor = profile === Profile.SUPERVISOR;

  const { setFooter, setBreadcrumbs } = useDynamicHeader();

  const { tx } = useTranslationX('request', 'project');
  const { tx: tx2 } = useTranslationX('editor', 'project');
  const { tx: tx3 } = useTranslationX('request.form', 'project');

  const { t } = useTranslation('translation');

  const request = useRequest();

  const { data, reload } = useFetch<Solicitation>(
    `/projetos/solicitacao-data/${solicitation.id}`
  );

  const isIntern = data?.vinculoContratacaoId === 4;

  const { data: solicitationType } = useFetch<{ notification_type: string }>(
    `/projetos/solicitacao/${solicitation.id}`
  );

  const cancelRequest = useCallback(() => {
    const formData = new FormData();
    formData.append('notificationId', String(solicitation.id));
    formData.append('tipo', String(solicitation.tipo));
    formData.append('accept', String(false));
    formData.append('isCancel', String(true));
    formData.append('nonCanceled', String(solicitation.estado !== 'ANC'));
    formData.append('estado', String(solicitation.estado));
    request<any>({
      url: `/homologacao/cancelar`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
      onSuccess: () => {
        messageService.success(tx('success.cancelRequest'), {
          duration: 7000,
        });
      },
      onError: error => {
        messageService.error(tx('error.cancelRequest'), {
          duration: 7000,
        });
        console.error(error);
      },
      onComplete: () => {
        history.goBack();
      },
    });
  }, [history, request, solicitation.estado, solicitation.id, solicitation.tipo, tx]);

  const onRequestRequired = useCallback(() => {
    if (solicitationType) {
      setRequestSelected({
        ...solicitation,
        notification_type: solicitationType.notification_type,
      });
      setShowRequestForm(true);
    }
  }, [solicitationType, solicitation]);

  const updateRequest = useCallback(
    (accept: boolean) => {
      const formData = new FormData();
      formData.append('notificationId', solicitation.id.toString());
      formData.append('tipo', solicitationType?.notification_type as string);
      formData.append('accept', String(accept));
      formData.append('nonCanceled', String(solicitation.estado !== 'ANC'));
      request<{ error: string }>({
        url: `/homologacao`,
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onSuccess: _ => {
          messageService.success(tx('success.updateRequest'), {
            duration: 7000,
          });
        },
        onError: error => {
          if (error.response?.data.error) {
            messageService.error(error.response.data.error, {
              duration: 7000,
            });
            return;
          }

          messageService.error(tx('error.updateRequest'), {
            duration: 7000,
          });
        },
        onComplete: () => {
          history.goBack();
        },
      });
    },
    [history, request, solicitation.estado, solicitation.id, tx, solicitationType]
  );

  const getOficio = useCallback(() => {
    if (!data?.estado || !data?.tipo) {
      return;
    }
    const { estado, tipo } = data;
    const path = section ?? 'servicos';
    request<any>({
      url: `/projeto/${id}/execucao/${path}/solicitacao/${solicitation.id}/pdf`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
      onSuccess: (blob: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          let base64data = reader.result;
          setPdfData(base64data);
        };

        if (!isSupervisor && tipo === 'rh' && estado !== 'EXE') {
          setTimeout(() => {
            setShowInfoDialog(true);
          }, 1000);
        }
      },
      onError: data => console.error(data),
    });
  }, [data, section, request, id, solicitation.id, isSupervisor]);

  const getAceite = useCallback(() => {
    request<any>({
      url: `/projeto/${props.solicitation.projetoId}/execucao/${
        props.section ? props.section : 'servicos'
      }/solicitacao/${props.solicitation.id}/pdf`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
      onSuccess: (blob: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          let base64data = reader.result;
          setPdfData(base64data);
        };
      },
      onError: data => console.error(data),
    });
  }, [request, props]);

  const downloadDocs = useCallback(() => {
    request<Blob>({
      url: `/projeto/${id}/recursos-humanos/${solicitation.id}/documentos`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
      onSuccess: (blob: Blob, extras?: ResponseExtras) => {
        downloadBlob(blob, 'documentos.zip', extras);
      },
      onError: data => console.error(data),
    });
  }, [request, id, solicitation]);

  const handleActions = useCallback(
    (action: TableAction) => {
      if (data?.estado === 'REF' && action.identifier === 'updateRequestAccept') {
        setRequestSelected(data);
        setShowInfoRefuseDialog(true);
      } else if (action.identifier === 'getOficio') {
        if (data?.tipo === 'rh') {
          downloadDocs();
          getOficio();
        } else {
          getOficio();
        }
      } else if (action.identifier === 'cancelRequest' && !isSupervisor) {
        cancelRequest();
      } else if (action.identifier === 'getAceite') {
        getAceite();
      }
      if (profile === Profile.SUPERVISOR) {
        if (action.identifier === 'updateRequestAccept' && data?.estado !== 'REF') {
          updateRequest(true);
        } else if (action.identifier === 'updateRequestRefuse') {
          if (data?.estado === 'ANS') {
            setAccept(false);
            onRequestRequired();
          } else {
            updateRequest(false);
          }
        } else if (action.identifier === 'onRequestRequired') {
          setAccept(true);
          onRequestRequired();
        } else if (data?.tipo === 'alteracao-pt' && action.identifier === 'seeChanges') {
          const updateWpPath = data.aditivo ? 'aditivo' : 'alteracao-pt';

          history.push(`/dashboard/projetos/${data.projetoId}/${updateWpPath}`);
        }
      }
    },
    [
      data,
      isSupervisor,
      profile,
      downloadDocs,
      getOficio,
      cancelRequest,
      getAceite,
      updateRequest,
      onRequestRequired,
      history,
    ]
  );

  const handleIcons = useCallback((identifier: string): React.ElementType => {
    const icons: { [key: string]: React.ElementType } = {
      cancelRequest: X,
      updateRequestRefuse: X,
      updateRequestAccept: Check,
      getOficio: PDF,
      seeChanges: Eye,
    };

    return identifier in icons ? icons[identifier] : Check;
  }, []);

  useEffect(() => {
    if (data) {
      const path = data.aditivo ? 'aditivo' : data.tipo;
      const solicitationType = data.tipo === 'alteracao-pt' ? path : data.tipo;

      setBreadcrumbs([
        'dashboard',
        'projetos',
        'solicitacoes',
        `[${tx(`type.${solicitationType}`)}]`,
      ]);

      setFooter(
        tx2('footer', {
          titulo: data.projetoTitulo,
          sigla: data.projetoSigla,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setBreadcrumbs, setFooter]);

  const archives = useMemo<RhArchives>(() => {
    return {
      docRg: data?.docRg!,
      docComprovanteResidencia: data?.docComprovanteResidencia!,
      docDeclaracaoMatricula: data?.docDeclaracaoMatricula!,
      docHistoricoEscolar: data?.docHistoricoEscolar!,
      docComprovanteContaCorrente: data?.docComprovanteContaCorrente!,
      docDescricaoProcessoSeletivo: data?.docDescricaoProcessoSeletivo!,
      docSolicitacaoDeContratacaoDeBolsa: data?.docSolicitacaoDeContratacaoDeBolsa!,
      docOutorgaEConcessao: data?.docOutorgaEConcessao!,
      docAutorizacaoPolo: data?.docAutorizacaoPolo!,
      docTermoConfidencialidade: data?.docTermoConfidencialidade!,
      docContracheque: data?.docContracheque!,
      docDeclaracaoVencimentos: data?.docDeclaracaoVencimentos!,
      docDeclaracaoPi: data?.docDeclaracaoPi!,
      docComprovanteTitulacaoFormacao: data?.docComprovanteTitulacaoFormacao!,
      docCurriculo: data?.docCurriculo!,
      docCtps: data?.docCtps!,
      docCpf: data?.docCpf!,
      docReservista: data?.docReservista!,
      docTituloEleitor: data?.docTituloEleitor!,
      docFoto: data?.docFoto!,
      docCertidaoCasamento: data?.docCertidaoCasamento!,
      docFormularioContratacao: data?.docFormularioContratacao!,
    };
  }, [data]);

  const uploadedArchives: RhArchives = useMemo(() => {
    const archivesArray = Object.entries(archives);

    return archivesArray.reduce((acc, [key, value]) => {
      if (value !== null) {
        acc[key as keyof RhArchives] = value;
      }
      return acc;
    }, {} as RhArchives);
  }, [archives]);

  if (!data || !solicitationType) {
    return <Loader type="spin" />;
  }

  const configTypes: RequestTypes = {
    servico: [
      [
        {
          title: tx(`requestType`),
          field: 'tipo',
          width: 50,
          parse: () => tx(`type.${data.tipo}`) + `-${data.tipo.toUpperCase()}`,
        },
        {
          title: tx('estado'),
          field: 'estado',
          width: 30,
          parse: () => tx(`status.${data.estado}`),
        },
      ],
      [
        {
          title: tx('titulo'),
          field: 'projetoTitulo',
          width: 50,
        },
        {
          title: tx('sigla'),
          field: 'projetoSigla',
          width: 30,
        },
      ],
      [
        {
          title: tx('name'),
          field: 'titulo',
          width: 50,
        },
        {
          title: tx('list.gpf'),
          field: 'gpf',
          width: 30,
        },
      ],
      [
        {
          title: tx('list.source'),
          field: 'fonte',
          width: 50,
        },
        {
          title: tx('list.requestDate'),
          field: 'data',
          type: 'date',
          width: 30,
        },
      ],
      [
        {
          title: tx('list.value'),
          field: 'valor',
          width: 50,
          parse: (value?: string) => `${t('currency')} 
          
          ${getMasked(Number(value).toFixed(2), {
            pattern: 'currency',
          })}`,
        },
        {
          title: tx('solicitedValue'),
          field: 'valorSolicitado',
          width: 30,
          parse: (value?: string) => `${t('currency')} 
          
          ${getMasked(Number(value).toFixed(2), {
            pattern: 'currency',
          })}`,
        },
      ],
    ],
    compra: [
      [
        {
          title: tx(`requestType`),
          field: 'tipo',
          width: 50,
          parse: () => tx(`type.${data.tipo}`),
        },
        {
          title: tx('estado'),
          field: 'estado',
          width: 30,
          parse: () => tx(`status.${data.estado}`),
        },
      ],
      [
        {
          title: tx('name'),
          field: 'titulo',
          width: 50,
        },
        {
          title: tx('list.requestDate'),
          field: 'data',
          type: 'date',
          width: 30,
        },
      ],
      [
        {
          title: tx('sigla'),
          field: 'projetoSigla',
          width: 50,
        },
        {
          title: tx('titulo'),
          field: 'projetoTitulo',
          width: 30,
        },
      ],
      [
        {
          title: tx('list.gpf'),
          field: 'gpf',
          width: 50,
        },
        {
          title: tx('list.source'),
          field: 'fonte',
          width: 30,
        },
      ],
      [
        {
          title: tx('list.value'),
          field: 'valor',
          parse: (value?: string) => `${t('currency')} 
          
          ${getMasked(Number(value).toFixed(2), {
            pattern: 'currency',
          })}`,
          width: 50,
        },
        {
          title: tx('solicitedValue'),
          field: 'valorSolicitado',
          parse: (value?: string) => `${t('currency')} 
          
          ${getMasked(Number(value).toFixed(2), {
            pattern: 'currency',
          })}`,
          width: 30,
        },
      ],
      [
        {
          title: tx('list.descricao'),
          field: 'descricao',
          type: 'html',
          width: 50,
        },
      ],
      [
        {
          title: tx('list.justificativa'),
          field: 'justificativa',
          type: 'html',
          width: 80,
        },
      ],
      [
        {
          title: tx('form.addressDelivery'),
          field: 'endereco.bairro',
          parse: () => '',
          width: 30,
        },
      ],
      [
        {
          title: tx('form.cep'),
          field: 'endereco.cep',
          width: 50,
        },
        {
          title: tx('form.uf'),
          field: 'endereco.uf',
          width: 30,
        },
      ],
      [
        {
          title: tx('form.city'),
          field: 'endereco.cidade',
          width: 50,
        },
        {
          title: tx('form.logradouro'),
          field: 'endereco.logradouro',
          width: 30,
        },
      ],
      [
        {
          title: tx('form.number'),
          field: 'endereco.numero',
          width: 50,
        },
        {
          title: tx('form.complement'),
          field: 'endereco.complemento',
          width: 30,
        },
      ],
      [
        {
          title: tx('form.neighborhood'),
          field: 'endereco.bairro',
          width: 50,
        },
      ],
    ],
    pagamento: [
      [
        {
          title: tx(`requestType`),
          field: 'tipo',
          width: 50,
          parse: () => tx(`type.${data.tipo}`),
        },
        {
          title: tx('estado'),
          field: 'estado',
          width: 30,
          parse: () => tx(`status.${data.estado}`),
        },
      ],
      [
        {
          title: tx('name'),
          field: 'titulo',
          width: 50,
        },
        {
          title: tx('list.requestDate'),
          field: 'data',
          type: 'date',
          width: 30,
        },
      ],
      [
        {
          title: tx('sigla'),
          field: 'projetoSigla',
          width: 50,
        },
        {
          title: tx('titulo'),
          field: 'projetoTitulo',
          width: 30,
        },
      ],
      [
        {
          title: tx('list.gpf'),
          field: 'gpf',
          width: 50,
        },
        {
          title: tx('list.source'),
          field: 'fonte',
          width: 30,
        },
      ],
      [
        {
          title: tx('list.value'),
          field: 'valor',
          width: 50,
          parse: (value?: string) => `${t('currency')} 
          
          ${getMasked(Number(value).toFixed(2), {
            pattern: 'currency',
          })}`,
        },
        {
          title: tx('solicitedValue'),
          field: 'valorSolicitado',
          width: 30,
          parse: (value?: string) => `${t('currency')} 
          
          ${getMasked(Number(value).toFixed(2), {
            pattern: 'currency',
          })}`,
        },
      ],
      [
        {
          title: tx('list.monthYear'),
          field: 'mesPagamento',
          width: 50,
          parse: () =>
            `${getMonthNameShort(Number((data as any).mesPagamento - 1))}/${getYearShort(
              (data as any).anoPagamento
            )}`,
        },
      ],
    ],
    diaria: [
      [
        {
          title: tx(`requestType`),
          field: 'tipo',
          width: 50,
          parse: () => tx(`type.${data.tipo}`),
        },
        {
          title: tx('estado'),
          field: 'estado',
          width: 30,
          parse: () => tx(`status.${data.estado}`),
        },
      ],
      [
        {
          title: tx('name'),
          field: 'titulo',
          width: 50,
        },
        {
          title: tx('list.requestDate'),
          field: 'data',
          type: 'date',
          width: 30,
        },
      ],
      [
        {
          title: tx('titulo'),
          field: 'projetoTitulo',
          width: 50,
        },
        {
          title: tx('sigla'),
          field: 'projetoSigla',
          width: 30,
        },
      ],
      [
        {
          title: tx('list.gpf'),
          field: 'gpf',
          width: 50,
        },
        {
          title: tx('list.source'),
          field: 'fonte',
          width: 30,
        },
      ],
      [
        {
          title: tx('valorDiaria'),
          field: 'valorDiaria',
          width: 50,
          parse: (value?: string) => `${t('currency')} 
          
            ${getMasked(Number(value).toFixed(2), {
              pattern: 'currency',
            })}`,
        },
        {
          title: tx('solicitedValue'),
          field: 'valorSolicitado',
          width: 30,
          parse: (value?: string) => `${t('currency')} 
          
          ${getMasked(Number(value).toFixed(2), {
            pattern: 'currency',
          })}`,
        },
      ],
      [
        {
          title: tx('personName'),
          field: 'nome',
          width: 50,
        },

        {
          title: tx('cpf'),
          field: 'cpf',
          width: 30,
        },
      ],
      [
        {
          title: tx('objective'),
          field: 'objetivo',
          width: 80,
          type: 'html',
          parse: value => `<span style="word-break: break-all;">${value}</span>`,
        },
      ],
      [
        {
          title: tx('cargo'),
          field: 'cargo',
          width: 80,
        },
      ],
      [
        {
          title: tx('destination'),
          field: 'destino',
          width: 50,
        },
      ],
      [
        {
          title: tx('viagemIda'),
          field: 'dataIda',
          type: 'date',
          width: 50,
        },
        {
          title: tx('viagemVolta'),
          field: 'dataVolta',
          type: 'date',
          width: 30,
        },
      ],
    ],
    passagem: [
      [
        {
          title: tx(`requestType`),
          field: 'tipo',
          width: 50,
          parse: () => tx(`type.${data.tipo}`),
        },
        {
          title: tx('estado'),
          field: 'estado',
          width: 30,
          parse: () => tx(`status.${data.estado}`),
        },
      ],
      [
        {
          title: tx('name'),
          field: 'titulo',
          width: 50,
        },
        {
          title: tx('list.requestDate'),
          field: 'data',
          type: 'date',
          width: 30,
        },
      ],
      [
        {
          title: tx('titulo'),
          field: 'projetoTitulo',
          width: 50,
        },
        {
          title: tx('sigla'),
          field: 'projetoSigla',
          width: 30,
        },
      ],
      [
        {
          title: tx('list.gpf'),
          field: 'gpf',
          width: 50,
        },
        {
          title: tx('list.source'),
          field: 'fonte',
          width: 30,
        },
      ],
      [
        {
          title: tx('valorDiaria'),
          field: 'valorDiaria',
          width: 50,
          parse: (value?: string) => `${t('currency')} 
          
            ${getMasked(Number(value).toFixed(2), {
              pattern: 'currency',
            })}`,
        },
        {
          title: tx('solicitedValue'),
          field: 'valorSolicitado',
          width: 30,
          parse: (value?: string) => `${t('currency')} 
          
          ${getMasked(Number(value).toFixed(2), {
            pattern: 'currency',
          })}`,
        },
      ],
      [
        {
          title: tx('personName'),
          field: 'nome',
          width: 50,
        },

        {
          title: tx('cpf'),
          field: 'cpf',
          width: 30,
        },
      ],
      [
        {
          title: tx('passport'),
          field: 'passaporte',
          width: 50,
        },
        {
          title: tx('itinerary'),
          field: 'itinerario',
          width: 30,
        },
      ],
      [
        {
          title: tx('viagemIda'),
          field: 'dataIda',
          type: 'date',
          width: 50,
        },
        {
          title: tx('viagemVolta'),
          field: 'dataVolta',
          type: 'date',
          width: 30,
        },
      ],
    ],
    rh: [
      [
        {
          title: tx(`requestType`),
          field: 'tipo',
          width: 50,
          parse: () => tx(`type.${data.tipo}`) + `-${data.tipo.toUpperCase()}`,
        },
        {
          title: tx('estado'),
          field: 'estado',
          width: 30,
          parse: () => tx(`status.${data.estado}`),
        },
      ],
      [
        {
          title: tx('name'),
          field: 'titulo',
          width: 50,
        },
        {
          title: isIntern ? tx('estagioCarga') : tx('bolsaCarga'),
          field: 'bolsaCargaHorariaMensal',
          width: 30,
          parse: (value?: string) => `${value}h`,
        },
      ],
      [
        {
          title: tx('titulo'),
          field: 'projetoTitulo',
          width: 50,
        },
        {
          title: tx('sigla'),
          field: 'projetoSigla',
          width: 30,
        },
      ],
      [
        {
          title: tx('list.gpf'),
          field: 'gpf',
          width: 50,
        },
        {
          title: tx('list.source'),
          field: 'fonte',
          width: 30,
        },
      ],
      [
        {
          title: tx('list.requestDate'),
          field: 'data',
          type: 'date',
          width: 50,
        },
        {
          title: isIntern ? tx('estagioDuracao') : tx('bolsaDuracao'),
          field: 'bolsaDuracao',
          width: 30,
          parse: (value?: string) => t('months', { months: value }),
        },
      ],
      [
        {
          title: tx('dataNascimento'),
          field: 'nascimento',
          width: 50,
          type: 'date',
        },
        {
          title: tx('rg'),
          field: 'rg',
          width: 30,
        },
      ],
      [
        {
          title: tx('rgEmissor'),
          field: 'rgEmissor',
          width: 50,
        },
        {
          title: tx('rgUf'),
          field: 'rgUf',
          width: 30,
          type: 'date',
        },
      ],
      [
        {
          title: tx('form.encargos'),
          field: 'encargos',
          width: 50,
          parse: value =>
            `${t('currency')} ${getMasked(Number(value).toFixed(2), {
              pattern: 'currency',
            })}`,
        },
        {
          title: tx('remuneracao'),
          field: 'remuneracao',
          width: 30,
          parse: value =>
            `${t('currency')} ${getMasked(Number(value).toFixed(2), {
              pattern: 'currency',
            })}`,
        },
      ],
      [
        {
          title: tx('dataInicio'),
          field: 'bolsaInicio',
          type: 'date',
          width: 50,
        },
        {
          title: tx('valorHora'),
          field: 'valorHora',
          width: 30,
          parse: value =>
            `${t('currency')} ${getMasked(Number(value).toFixed(2), {
              pattern: 'currency',
            })}`,
        },
      ],
    ],
    aceiteMacro: [
      [
        {
          title: tx(`requestType`),
          field: 'tipo',
          width: 50,
          parse: () => tx(`type.${data.tipo}`),
        },
        {
          title: tx('estado'),
          field: 'estado',
          width: 30,
          parse: () => tx(`status.${data.estado}`),
        },
      ],
      [
        {
          title: tx('typeAceite'),
          field: 'tipoAcao',
          width: 50,
          parse: value => tx(`list.${value}`),
        },
        {
          title: tx('list.requestDate'),
          field: 'data',
          type: 'date',
          width: 30,
        },
      ],
      [
        {
          title: tx('titulo'),
          field: 'projetoTitulo',
          width: 50,
        },

        {
          title: tx('sigla'),
          field: 'projetoSigla',
          width: 30,
        },
      ],
    ],
    desligamento: [
      [
        {
          title: tx(`requestType`),
          field: 'tipo',
          width: 50,
          parse: () => tx(`type.${data.tipo}`),
        },
        {
          title: tx('estado'),
          field: 'estado',
          width: 30,
          parse: () => tx(`status.${data.estado}`),
        },
      ],
      [
        {
          title: tx('titulo'),
          field: 'projetoTitulo',
          width: 50,
        },
        {
          title: tx('sigla'),
          field: 'projetoSigla',
          width: 30,
        },
      ],
      [
        {
          title: tx('personName'),
          field: 'nome',
          width: 50,
        },
      ],
      [
        {
          title: tx('list.gpf'),
          field: 'gpf',
          width: 50,
        },
        {
          title: tx('list.source'),
          field: 'fonte',
          width: 30,
        },
      ],
      [
        {
          title: tx('list.requestDate'),
          field: 'data',
          type: 'date',
          width: 50,
        },
        {
          title: tx('bolsaDuracao'),
          field: 'bolsaDuracao',
          width: 30,
          parse: (value?: string) => t('months', { months: value }),
        },
      ],
      [
        {
          title: tx('bolsaCarga'),
          field: 'bolsaCargaHorariaMensal',
          width: 50,
          parse: (value?: string) => `${value}h`,
        },
        {
          title: tx('rg'),
          field: 'rg',
          width: 30,
        },
      ],
      [
        {
          title: tx('remuneracao'),
          field: 'remuneracao',
          width: 50,
          parse: value =>
            `${t('currency')} ${getMasked(Number(value).toFixed(2), {
              pattern: 'currency',
            })}`,
        },
        {
          title: tx('form.encargos'),
          field: 'encargos',
          parse: value =>
            `${t('currency')} ${getMasked(Number(value).toFixed(2), {
              pattern: 'currency',
            })}`,
          width: 30,
        },
      ],
      [
        {
          title: tx('dataInicio'),
          field: 'bolsaInicio',
          type: 'date',
          width: 50,
        },
        {
          title: tx('valorHora'),
          field: 'valorHora',
          parse: value =>
            `${t('currency')} ${getMasked(Number(value).toFixed(2), {
              pattern: 'currency',
            })}`,
          width: 30,
        },
      ],
    ],
    ferias: [
      [
        {
          title: tx(`requestType`),
          field: 'tipo',
          width: 40,
          parse: () => tx(`type.${data.tipo}`),
        },
        {
          title: tx('estado'),
          field: 'estado',
          width: 30,
          parse: () => tx(`status.${data.estado}`),
        },
      ],
      [
        {
          title: tx('name'),
          field: 'titulo',
          width: 30,
        },
        {
          title: tx('sigla'),
          field: 'projetoSigla',
          width: 30,
        },
        {
          title: tx('titulo'),
          field: 'projetoTitulo',
          width: 30,
        },
      ],
      [
        {
          title: tx('list.gpf'),
          field: 'gpf',
          width: 30,
        },
        {
          title: tx('list.source'),
          field: 'fonte',
          width: 40,
        },
      ],
      [
        {
          title: tx('list.requestDate'),
          field: 'data',
          type: 'date',
          width: 30,
        },
        {
          title: tx('bolsaDuracao'),
          field: 'bolsaDuracao',
          width: 40,
          parse: (value?: string) => t('months', { months: value }),
        },
        {
          title: tx('bolsaCarga'),
          field: 'bolsaCargaHorariaMensal',
          width: 40,
          parse: (value?: string) => `${value}h`,
        },
      ],
      [
        {
          title: tx('personName'),
          field: 'nome',
          width: 30,
        },

        {
          title: tx('rg'),
          field: 'rg',
          width: 30,
        },
      ],
      [
        {
          title: tx('dataInicio'),
          field: 'bolsaInicio',
          type: 'date',
          width: 40,
        },
        {
          title: tx('valorHora'),
          field: 'valorHora',
          width: 40,
          parse: value =>
            `${t('currency')} ${getMasked(Number(value).toFixed(2), {
              pattern: 'currency',
            })}`,
        },
      ],
    ],
    transferencia: [
      [
        {
          title: tx(`requestType`),
          field: 'tipo',
          width: 50,
          parse: () => tx(`type.${data.tipo}`),
        },
        {
          title: tx('estado'),
          field: 'estado',
          width: 10,
          parse: () => tx(`status.${data.estado}`),
        },
      ],
      [
        {
          title: tx('titulo'),
          field: 'projetoTitulo',
          width: 50,
        },
        {
          title: tx('sigla'),
          field: 'projetoSigla',
          width: 10,
        },
      ],
      [
        {
          title: tx('personName'),
          field: 'titulo',
          width: 50,
        },

        {
          title: tx('list.gpf'),
          field: 'gpf',
          width: 10,
        },
      ],
      [
        {
          title: tx('bolsaCarga'),
          field: 'cargaHoaria',
          width: 50,
          parse: (value?: string) => `${value}h`,
        },

        {
          title: tx('list.source'),
          field: 'fonte',
          width: 10,
        },
      ],
      [
        {
          title: tx('list.requestDate'),
          field: 'data',
          type: 'date',
          width: 50,
        },
        {
          title: tx('dataInicio'),
          field: 'contrato.bolsaInicio',
          type: 'date',
          width: 10,
        },
      ],
      [
        {
          title: tx('justification'),
          width: 60,
          field: 'justificativa',
        },
      ],
      [
        {
          title: tx('form.dadosOrigem'),
          width: 50,
          field: 'projetoSigla',
          type: 'title',
          parse: () => '',
        },
      ],
      [
        {
          title: tx('cargo'),
          field: 'origem.cargo',
          width: 50,
        },

        {
          title: tx('list.source'),
          field: 'origem.fonteRecurso',
          width: 10,
        },
      ],
      [
        {
          title: tx('remuneracao'),
          field: 'origem.remuneracao',
          parse: (value?: string) => `${t('currency')} 
          
          ${getMasked(Number(value).toFixed(2), {
            pattern: 'currency',
          })}`,

          width: 50,
        },

        {
          title: tx('valorHora'),
          field: 'origem.valorHora',
          width: 10,
          parse: (value?: string) => `${t('currency')} 
          
          ${getMasked(Number(value).toFixed(2), {
            pattern: 'currency',
          })}`,
        },
      ],
      [
        {
          title: tx('bolsaDuracao'),
          field: 'contrato.bolsaDuracao',
          width: 50,
          parse: (value?: string) => t('months', { months: value }),
        },
      ],
      [
        {
          title: tx('form.dadosDestino'),

          width: 12,
          field: 'projetoSigla',
          type: 'title',
          parse: () => '',
        },
      ],
      [
        {
          title: tx('cargo'),
          field: 'destino.cargo',
          width: 50,
        },

        {
          title: tx('list.source'),
          field: 'destino.fonteRecurso',
          width: 10,
        },
      ],
      [
        {
          title: tx('remuneracao'),
          field: 'destino.remuneracao',
          parse: (value?: string) => `${t('currency')} 
          
          ${getMasked(Number(value).toFixed(2), {
            pattern: 'currency',
          })}`,
          width: 50,
        },

        {
          title: tx('valorHora'),
          field: 'destino.valorHora',
          width: 10,
          parse: (value?: string) => `${t('currency')} 
          
          ${getMasked(Number(value).toFixed(2), {
            pattern: 'currency',
          })}`,
        },
      ],
      [
        {
          title: tx('bolsaDuracao'),
          field: 'duracao',
          width: 50,
          parse: (value?: string) => t('months', { months: value }),
        },
      ],
    ],
    prorrogacao: [
      [
        {
          title: tx(`requestType`),
          field: 'tipo',
          width: 50,
          parse: () => tx(`type.${data.tipo}`),
        },
        {
          title: tx('estado'),
          field: 'estado',
          width: 30,
          parse: () => tx(`status.${data.estado}`),
        },
      ],
      [
        {
          title: tx('name'),
          field: 'titulo',
          width: 50,
        },
        {
          title: tx('list.requestDate'),
          field: 'data',
          type: 'date',
          width: 30,
        },
      ],
      [
        {
          title: tx('titulo'),
          field: 'projetoTitulo',
          width: 50,
        },
        {
          title: tx('sigla'),
          field: 'projetoSigla',
          width: 30,
        },
      ],
      [
        {
          title: tx('list.gpf'),
          field: 'gpf',
          width: 50,
        },
        {
          title: tx('list.source'),
          field: 'fonte',
          width: 30,
        },
      ],
      [
        {
          title: tx('bolsaDuracao'),
          field: 'bolsaDuracao',
          width: 50,
          parse: (value?: string) => t('months', { months: value }),
        },
        {
          title: tx('bolsaCarga'),
          field: 'bolsaCargaHorariaMensal',
          width: 30,
          parse: (value?: string) => `${value}h`,
        },
      ],
      [
        {
          title: tx('personName'),
          field: 'nome',
          width: 50,
        },
        {
          title: tx('rg'),
          field: 'rg',
          width: 30,
        },
      ],
      [
        {
          title: tx('dataInicio'),
          field: 'bolsaInicio',
          type: 'date',
          width: 50,
        },
        {
          title: tx('valorHora'),
          field: 'valorHora',
          width: 30,
          parse: value =>
            `${t('currency')} ${getMasked(Number(value).toFixed(2), {
              pattern: 'currency',
            })}`,
        },
      ],
      [
        {
          title: tx('mesesProrrogacao'),
          field: 'mesesProrrogacao',
          width: 50,
          parse: (value?: string) => t('months', { months: value }),
        },
      ],
      [
        {
          title: tx('justificativa'),
          field: 'justificativa',
          width: 80,
        },
      ],
    ],
    'alteracao-pt': [
      [
        {
          title: tx('estado'),
          field: 'estado',
          width: 50,
          parse: () => tx(`status.${data.estado}`),
        },
        {
          title: tx('list.requestDate'),
          field: 'data',
          type: 'date',
          width: 30,
        },
      ],
      [
        {
          title: tx('titulo'),
          field: 'projetoTitulo',
          width: 50,
        },
        {
          title: tx('sigla'),
          field: 'projetoSigla',
          width: 30,
        },
      ],
      [
        {
          title: tx('list.justificativa'),
          field: 'justificativa',
          type: 'html',
          width: 80,
        },
      ],
    ],
  };

  if (data?.isCLT) {
    configTypes.desligamento = [
      [
        {
          title: tx(`requestType`),
          field: 'tipo',
          width: 50,
          parse: () => tx(`type.${data.tipo}`),
        },
        {
          title: tx('estado'),
          field: 'estado',
          width: 30,
          parse: () => tx(`status.${data.estado}`),
        },
      ],
      [
        {
          title: tx('titulo'),
          field: 'projetoTitulo',
          width: 50,
        },
        {
          title: tx('sigla'),
          field: 'projetoSigla',
          width: 30,
        },
      ],
      [
        {
          title: tx('form.dataDesligamento'),
          field: 'dataDesligamento',
          parse: dataDesligamento => formatDateToBrazilian(dataDesligamento as any),
          width: 33,
        },
        {
          title: tx('form.avisoPrevio'),
          field: 'avisoPrevio',
          width: 33,
        },
        {
          title: tx('form.iniciativa'),
          field: 'iniciativa',
          width: 33,
        },
      ],
      [
        {
          title: tx('form.justificativa.label'),
          field: 'justificativa',
          width: 100,
          parse: justificativa => justificativa || tx('na'),
        },
      ],
      [
        {
          title: tx('sectionPersonalData'),
          width: 50,
          field: 'sectionPersonalData',
          type: 'title',
          parse: () => '',
        },
      ],
      [
        {
          title: tx('form.name.label'),
          field: 'nome',
          width: 33,
        },
        {
          title: tx('form.birth'),
          field: 'nascimento',
          parse: nascimento => formatDateToBrazilian(nascimento as any),
          width: 33,
        },
        {
          title: tx('form.naturalidade'),
          field: 'naturalidade',
          width: 33,
        },
      ],
      [
        {
          title: tx('form.grauInstrucao.title'),
          field: 'formacao',
          parse: formacao => tx(`form.grauInstrucao.${formacao}`),
          width: 33,
        },
        {
          title: tx('form.carteiraProfissional'),
          field: 'carteiraProfissional',
          width: 33,
        },
        {
          title: tx('form.carteiraProfissionalSerie'),
          field: 'carteiraProfissionalSerie',
          width: 33,
        },
      ],
      [
        {
          title: tx('form.cpf'),
          field: 'cpf',
          width: 33,
        },
        {
          title: tx('form.estadoCivil.title'),
          field: 'estadoCivil',
          parse: estadoCivil => tx('form.estadoCivil.' + estadoCivil),
          width: 33,
        },
        {
          title: tx('form.sex.label'),
          field: 'sexo',
          parse: sexo => tx('form.sex.' + sexo),
          width: 33,
        },
      ],
      [
        {
          title: tx('form.rg'),
          field: 'rg',
          width: 33,
        },
        {
          title: tx('form.orgaoEmissor'),
          field: 'rgEmissor',
          width: 33,
        },
        {
          title: tx('form.uf'),
          field: 'rgEmissorUf',
          width: 33,
        },
      ],
      [
        {
          title: tx('form.tituloEleitor'),
          field: 'tituloEleitor',
          width: 33,
        },
        {
          title: tx('form.tituloEleitorZona'),
          field: 'tituloEleitorZona',
          width: 33,
        },
        {
          title: tx('form.tituloEleitorSecao'),
          field: 'tituloEleitorSecao',
          width: 33,
        },
      ],
      [
        {
          title: tx('form.dependentes'),
          field: 'dependentes',
          width: 33,
        },
        {
          title: tx('form.pis'),
          field: 'pis',
          width: 33,
        },
        {
          title: tx('form.pisDataCadastro'),
          field: 'pisDataCadastro',
          parse: pisDataCadastro => formatDateToBrazilian(pisDataCadastro as any),
          width: 33,
        },
      ],

      [
        {
          title: tx('sectionAddress'),
          width: 50,
          field: 'sectionAddress',
          type: 'title',
          parse: () => '',
        },
      ],
      [
        {
          title: tx('form.cep'),
          field: 'localAtividadesCEP',
          width: 33,
        },
        {
          title: tx('form.street'),
          field: 'localAtividadesLogradouro',
          width: 33,
        },
        {
          title: tx('form.number'),
          field: 'localAtividadesNumero',
          width: 33,
        },
      ],
      [
        {
          title: tx('form.district'),
          field: 'localAtividadesBairro',
          width: 33,
        },
        {
          title: tx('form.city'),
          field: 'localAtividadesCidade',
          width: 33,
        },
        {
          title: tx('form.uf'),
          field: 'localAtividadesUF',
          width: 33,
        },
      ],
      [
        {
          title: tx('form.complement'),
          field: 'localAtividadesComplemento',
          width: 100,
          parse: localAtividadesComplemento => localAtividadesComplemento || tx('na'),
        },
      ],

      [
        {
          title: tx('sectionRole'),
          width: 50,
          field: 'sectionRole',
          type: 'title',
          parse: () => '',
        },
      ],
      [
        {
          title: tx('form.cbo'),
          field: 'cbo',
          width: 33,
        },
        {
          title: tx('form.dataAdmissao'),
          field: 'bolsaInicio',
          parse: bolsaInicio => formatDateToBrazilian(bolsaInicio as any),
          width: 33,
        },
        {
          title: tx('form.monthlyWorkload'),
          field: 'bolsaCargaHorariaMensal',
          parse: bolsaCargaHorariaMensal =>
            `${bolsaCargaHorariaMensal} ${tx('form.hourspermonth')}`,
          width: 33,
        },
      ],
      [
        {
          title: tx('form.valueHour'),
          field: 'valorHora',
          parse: (value: any) =>
            t('currency') +
            ' ' +
            getMasked(Number(value).toFixed(2), { pattern: 'currency' }),
          width: 33,
        },
        {
          title: tx('form.remuneration'),
          field: 'remuneracao',
          parse: (value: any) =>
            t('currency') +
            ' ' +
            getMasked(Number(value).toFixed(2), { pattern: 'currency' }),
          width: 33,
        },
        {
          title: tx('form.encargos'),
          field: 'encargos',
          parse: (value: any) =>
            t('currency') +
            ' ' +
            getMasked(Number(value).toFixed(2), { pattern: 'currency' }),
          width: 33,
        },
      ],
      [
        {
          title: tx('form.activitiesDescription'),
          field: 'descricaoAtividades',
          width: 100,
        },
      ],

      [
        {
          title: tx('sectionBankData'),
          width: 50,
          field: 'sectionBankData',
          type: 'title',
          parse: () => '',
        },
      ],
      [
        {
          title: tx('form.banco'),
          field: 'banco',
          width: 33,
        },
        {
          title: tx('form.agencia'),
          field: 'agenciaConta',
          width: 33,
        },
        {
          title: tx('form.conta'),
          field: 'numeroConta',
          width: 33,
        },
      ],
    ];
  }

  let config: ReadOnlyTableDataConfig[] = [
    {
      title: tx('request'),
      tableRows: configTypes[data.tipo as keyof RequestTypes],
    },
  ];

  const getArchive = (id: number) => {
    request<string>({
      url: `/arquivo/${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'string',
      },
      onSuccess: handleDownload,
    });
  };

  const handleDownload = (token: string) => {
    request<any>({
      url: `/resource/${token}`,
      method: 'GET',
      responseType: 'blob',
      onSuccess: (blob: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          let base64data = reader.result;
          setPdfData(base64data);
        };
      },
      onError: data => console.error(data),
    });
  };

  if (data.tipo === 'rh' && data.isCLT) {
    config[0].tableRows = [
      [
        {
          title: tx(`requestType`),
          field: 'tipo',
          width: 40,
          parse: () => tx(`type.${data.tipo}`) + `-${data.tipo.toUpperCase()}`,
        },
        {
          title: tx('estado'),
          field: 'estado',
          width: 30,
          parse: () => tx(`status.${data.estado}`),
        },
        {
          title: tx('name'),
          field: 'titulo',
          width: 30,
        },
      ],
      [
        {
          title: tx('titulo'),
          field: 'projetoTitulo',
          width: 40,
        },
        {
          title: tx('sigla'),
          field: 'projetoSigla',
          width: 30,
        },
        {
          title: tx('bolsaCarga'),
          field: 'bolsaCargaHorariaMensal',
          width: 30,
          parse: (value?: string) => `${value}h`,
        },
      ],

      [
        {
          title: tx('list.gpf'),
          field: 'gpf',
          width: 40,
        },
        {
          title: tx('list.source'),
          field: 'fonte',
          width: 30,
        },
        {
          title: tx('list.requestDate'),
          field: 'data',
          type: 'date',
          width: 30,
        },
      ],
      [
        {
          title: tx('cpf'),
          field: 'cpf',
          width: 40,
          type: 'date',
        },
        {
          title: tx('rg'),
          field: 'rg',
          width: 30,
        },
        {
          title: tx('bolsaDuracao'),
          field: 'bolsaDuracao',
          width: 30,
          parse: (value?: string) => t('months', { months: value }),
        },
      ],
      [
        {
          title: tx('rgUf'),
          field: 'rgEmissorUf',
          width: 40,
        },
        {
          title: tx('rgEmissor'),
          field: 'rgEmissor',
          width: 30,
        },
        {
          title: tx('genero'),
          field: 'sexo',
          width: 30,
        },
      ],
      [
        {
          title: tx('formacao'),
          field: 'formacao',
          width: 40,
          parse: value => tx(`form.grauInstrucao.${value}`),
        },
        {
          title: tx('carteiraProfissional'),
          field: 'carteiraProfissional',
          width: 30,
        },
        {
          title: tx('carteiraProfissionalSerie'),
          field: 'carteiraProfissionalSerie',
          width: 30,
        },
      ],
      [
        {
          title: tx('tituloEleitor'),
          field: 'tituloEleitor',
          width: 40,
        },
        {
          title: tx('tituloEleitorSecao'),
          field: 'tituloEleitorSecao',
          width: 30,
        },
        {
          title: tx('tituloEleitorZona'),
          field: 'tituloEleitorZona',
          width: 30,
        },
      ],
      [
        {
          title: tx('pis'),
          field: 'pis',
          width: 40,
        },
        {
          title: tx('pisDataCadastro'),
          field: 'pisDataCadastro',
          width: 30,
          type: 'date',
        },
        {
          title: tx('dependentes'),
          field: 'dependentes',
          width: 30,
        },
      ],
      [
        {
          title: tx('cbo'),
          field: 'cbo',
          width: 40,
        },
        {
          title: tx('form.encargos'),
          field: 'encargos',
          width: 30,
          parse: value =>
            `${t('currency')} ${getMasked(Number(value).toFixed(2), {
              pattern: 'currency',
            })}`,
        },
        {
          title: tx('remuneracao'),
          field: 'remuneracao',
          width: 30,
          parse: value =>
            `${t('currency')} ${getMasked(Number(value).toFixed(2), {
              pattern: 'currency',
            })}`,
        },
      ],
      [
        {
          title: tx('dataInicio'),
          field: 'bolsaInicio',
          type: 'date',
          width: 40,
        },
        {
          title: tx('valorHora'),
          field: 'valorHora',
          width: 30,
          parse: value =>
            `${t('currency')} ${getMasked(Number(value).toFixed(2), {
              pattern: 'currency',
            })}`,
        },
        {
          title: '',
          field: 'valorHora',
          width: 30,
          parse: () => ``,
        },
      ],
    ];
  }

  return (
    <ScrollPanel>
      <div className={styles.content}>
        <Modal
          visible={pdfData !== null}
          width="auto"
          onBlur={() => setPdfData(null)}
          onClose={() => setPdfData(null)}
        >
          <div className={styles.popup}>
            <ModalPDFViewer pdfData={pdfData} />
          </div>
        </Modal>
        <InfoDialog
          visible={showInfoDialog}
          onClose={() => setShowInfoDialog(false)}
          title={tx('list.solicitationWarning')}
          width={550}
          buttonLabel={tx('list.ok')}
          volatile
        />
        <InfoDialog
          visible={showInfoRefuseDialog}
          onClose={() => setShowInfoRefuseDialog(false)}
          title={requestSelected?.motivoRejeicao}
          width={550}
          buttonLabel={tx('list.ok')}
          volatile
        />

        {showRequestForm && (
          <ConfirmDialog
            data={{
              id: requestSelected?.id || 0,
              type: String(requestSelected?.notification_type),
              granted: true,
              title: 'Confirmar execução de solicitação',
              createdAt: new Date(),
              read: false,
              state: requestSelected?.estado,
            }}
            needFile={false}
            accept={accept}
            execution={accept}
            needValorExecutado={
              String(requestSelected?.tipo).indexOf('rh') === -1 &&
              String(requestSelected?.tipo).indexOf('transferencia') === -1 &&
              String(requestSelected?.tipo).indexOf('prorrogacao') === -1 &&
              String(requestSelected?.tipo).indexOf('desligamento') === -1 &&
              String(requestSelected?.tipo).indexOf('ferias') === -1 &&
              String(requestSelected?.tipo).indexOf('alteracao-pt') === -1 &&
              String(requestSelected?.tipo).indexOf('aceiteMacro') === -1
            }
            valorExecutado={Number(requestSelected?.valor)}
            onClose={(processed?: boolean) => {
              if (processed) {
                messageService.success(tx('success.requestRequired'), {
                  duration: 7000,
                });
                history.goBack();
              }
              setShowRequestForm(false);
            }}
          />
        )}
        <div className={styles.backButton}>
          <GoBackButton />
          {actions
            .filter(action => action.identifier !== undefined)
            .map(action => {
              const icon = handleIcons(action.identifier!);

              const buttonDisabled =
                data.tipo === 'rh' &&
                (!data.docAutorizacaoPolo || !data.docOutorgaEConcessaoAssinado) &&
                action.identifier !== 'updateRequestRefuse' &&
                profile === Profile.SUPERVISOR &&
                data.estado === 'ANS' &&
                data.vinculoContratacao === 'Bolsa';

              return (
                <Tippy
                  content={tx('form.disabledDocuments')}
                  className={styles.popup}
                  animation="perspective"
                  touch={false}
                  followCursor="horizontal"
                  plugins={[followCursor]}
                  key={action.label}
                  disabled={!buttonDisabled}
                >
                  <div>
                    <Button
                      theme="light"
                      style={{
                        text: {
                          color: action.color,
                        },
                      }}
                      icon={icon}
                      iconProps={{ color: action.color }}
                      onClick={() => handleActions(action)}
                      type={buttonDisabled ? 'disabled' : undefined}
                    >
                      {action.label}
                    </Button>
                  </div>
                </Tippy>
              );
            })}
        </div>
        <ReadOnlyTableData config={config} data={data} />

        {(data.tipo === 'servico' || data.tipo === 'compra') && (
          <div className={styles.uploadedDocuments}>
            <strong>{tx('list.term')}</strong>

            {data.termo_id && (
              <Row width={[3]} align="top">
                <Button
                  theme="light"
                  icon={Eye}
                  onClick={() => getArchive(data.termo_id)}
                >
                  {tx('form.term')}
                </Button>
              </Row>
            )}

            {!!data.propostas.length && (
              <>
                <strong>{tx('list.proposals')}</strong>

                <div>
                  <div>
                    {data.propostas.map(proposta => (
                      <Row width={[3]} align="top">
                        <Button
                          theme="light"
                          icon={Eye}
                          onClick={() => getArchive(proposta.id)}
                          style={{
                            root: {
                              margin: 0,
                            },
                          }}
                        >
                          <Tippy
                            className={styles.popup}
                            content={proposta.nome}
                            placement="top-start"
                            animation="perspective"
                            offset={[0, 5]}
                            delay={[100, 0]}
                            hideOnClick={false}
                          >
                            <span onClick={() => getArchive(proposta.id)}>
                              <strong>{appendDots(proposta.nome, 10)}</strong>
                            </span>
                          </Tippy>
                        </Button>
                      </Row>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        {data.tipo === 'desligamento' &&
          data.avaliacaoBolsista &&
          data.avaliacaoBolsista.length > 0 &&
          data.avaliacaoCoordenador &&
          data.avaliacaoCoordenador.length > 0 && (
            <div>
              <Row width={[3, 3, 3]} align="top">
                <Button
                  theme="light"
                  onClick={() => {
                    setOpenAvaliacaoModal(() => ({
                      open: true,
                      coordenador: true,
                    }));
                  }}
                  icon={Eye}
                >
                  {tx('form.avaliacaoCoordenador')}
                </Button>
                <Button
                  theme="light"
                  onClick={() => {
                    setOpenAvaliacaoModal(() => ({
                      open: true,
                      coordenador: false,
                    }));
                  }}
                  icon={Eye}
                >
                  {tx('form.avaliacaoBolsista')}
                </Button>
              </Row>

              <Modal
                visible={openAvaliacaoModal?.open}
                width="auto"
                onBlur={() => setOpenAvaliacaoModal({ open: false, coordenador: false })}
                onClose={() => setOpenAvaliacaoModal({ open: false, coordenador: false })}
              >
                <div className={styles.modalContent}>
                  <WrapperCard maxWidth="600px">
                    {openAvaliacaoModal.coordenador ? (
                      <div className={styles.avaliacao}>
                        <div className={styles.content}>
                          <span className={styles.titulo}>
                            {tx('form.respostas') + tx('form.avaliacaoCoordenador')}
                          </span>
                          {data.avaliacaoCoordenador?.map(item => (
                            <Row align="top" key={item.id}>
                              <div className={styles.item}>
                                <span className={styles.pergunta}>{item.pergunta}</span>
                                <span className={styles.resposta}>{item.resposta}</span>
                              </div>
                            </Row>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className={styles.avaliacao}>
                        <div className={styles.content}>
                          <span className={styles.titulo}>
                            {tx('form.respostas') + tx('form.avaliacaoBolsista')}
                          </span>
                          {data.avaliacaoBolsista?.map(item => (
                            <Row align="top" key={item.id}>
                              <div className={styles.item}>
                                <span className={styles.pergunta}>{item.pergunta}</span>
                                <span className={styles.resposta}>{item.resposta}</span>
                              </div>
                            </Row>
                          ))}
                        </div>
                      </div>
                    )}
                  </WrapperCard>
                </div>
              </Modal>
            </div>
          )}

        {data.tipo === 'aceiteMacro' && (
          <div className={styles.uploadedDocuments}>
            <strong>{tx('list.term')}</strong>

            {data.arquivoTermo && (
              <Row width={[3]} align="top">
                <Button
                  theme="light"
                  icon={Eye}
                  onClick={() => getArchive(data.arquivoTermo as number)}
                >
                  {tx('form.term')}
                </Button>
              </Row>
            )}
          </div>
        )}

        {data.tipo === 'rh' &&
          profile === Profile.SUPERVISOR &&
          data.vinculoContratacao === 'Bolsa' && (
            <div className={styles.uploadedDocuments}>
              <div className={styles.documentsContent}>
                {uploadedArchives && (
                  <Row align="top">
                    <span className={styles.documentsTitle}>
                      {tx('form.uploadedDocuments')}
                    </span>
                  </Row>
                )}
                <div className={styles.documents}>
                  {uploadedArchives &&
                    data.tipo === 'rh' &&
                    Object.keys(uploadedArchives).map(archive => (
                      <Row width={[3]} align="top" key={archive}>
                        <div className={styles.document}>
                          <Download size={1.5} />
                          <span
                            onClick={() =>
                              getArchive(
                                uploadedArchives[archive as keyof RhArchives] as number
                              )
                            }
                          >
                            <strong>{`${tx3(`${archive}`)}`} </strong>
                          </span>
                        </div>
                      </Row>
                    ))}
                </div>
              </div>
              {data.estado === 'ANS' && (
                <>
                  <Row width={[3]} align="top">
                    <Button
                      theme="light"
                      icon={Upload}
                      onClick={() => setShowHrDialog(true)}
                    >
                      {tx(`form.documents`)}
                    </Button>
                  </Row>

                  <HiringDocsDialog
                    content={data}
                    reload={reload}
                    visible={showHrDialog}
                    onClose={() => {
                      setShowHrDialog(false);
                      reload();
                    }}
                  />
                </>
              )}
            </div>
          )}

        {data.tipo === 'rh' && profile === Profile.SUPERVISOR && data.isCLT && (
          <div className={styles.uploadedDocuments}>
            <div className={styles.documentsContent}>
              {uploadedArchives && (
                <Row align="top">
                  <span className={styles.documentsTitle}>
                    {tx('form.uploadedDocuments')}
                  </span>
                </Row>
              )}
              <div className={styles.documents}>
                {uploadedArchives &&
                  data.tipo === 'rh' &&
                  Object.keys(uploadedArchives).map(archive => (
                    <Row width={[3]} align="top" key={archive}>
                      <div className={styles.document}>
                        <Download size={1.5} />
                        <span
                          onClick={() =>
                            getArchive(
                              uploadedArchives[archive as keyof RhArchives] as number
                            )
                          }
                        >
                          <strong>{`${tx3(`${archive}`)}`} </strong>
                        </span>
                      </div>
                    </Row>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </ScrollPanel>
  );
};

type DialogProps = {
  content: any;
  visible: boolean;
  onClose: (processed?: boolean) => void;
  reload: () => void;
};

const HiringDocsDialog = ({ visible, content, onClose, reload }: DialogProps) => {
  const form = React.createRef<FormInstance>();
  const [done] = useState(false);
  const dialog = useRef<DialogRef>(null);

  const { t, tx } = useTranslationX('request.form', 'project');
  const { tx: t3 } = useTranslationX('counterpartPanel', 'project');

  const [submitedArchive, setSubmitedArchive] = useState<string[]>([]);

  const request = useRequest();

  const handleSubmitArchives = useCallback(
    (files: File[], documentName: string) => {
      if (files.length === 0) {
        return;
      }
      const formData = new FormData();
      formData.set('file', files[0]);

      const source = request({
        method: 'POST',
        url: `/projeto/${content.projetoId}/recursos-humanos/${content.prhId}/documento/set_doc_${documentName}`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onSuccess: () => {
          if (
            !content[
              'doc' +
                documentName
                  .split('_')
                  .map(t => t.charAt(0).toUpperCase() + t.substr(1))
                  .join('')
            ] &&
            !submitedArchive?.includes(documentName)
          ) {
            messageService.success(
              tx('archiveSuccess', {
                documentName: tx(`documentsUpload.${documentName}`),
              }),
              {
                duration: 2000,
              }
            );
            reload();
            setSubmitedArchive(state => [...state, documentName]);
          }
        },
      });
      return () => source.cancel();
    },
    [content, request, tx, reload, setSubmitedArchive, submitedArchive]
  );

  return (
    <Dialog
      ref={dialog}
      title={tx('title.upload-docs')}
      titleAlign="center"
      visible={visible}
      onClose={onClose}
      closeButton={!done}
      width={676}
    >
      {done ? (
        <VStack align="center" spacing={30}>
          <span style={{ padding: '5px', fontFamily: 'Roboto' }}>{tx('success')}</span>
          <Spacer length={UI_BUTTON_SPACING} />
          <Button onClick={() => dialog.current?.close(true)}>{t('button.ok')}</Button>
        </VStack>
      ) : (
        <div className={styles.rhModal}>
          <Form ref={form}>
            <FileUploader
              message={t3('termoOutorga')}
              metadata={{ rules: { accept: ['pdf'], maxfiles: 1 } }}
              onChange={file =>
                handleSubmitArchives(file, 'outorga_e_concessao_assinado')
              }
              hasFilesUploaded={!!content.docOutorgaEConcessaoAssinado}
            />
            <FileUploader
              message={t3('autorizacaoPolo')}
              metadata={{ rules: { accept: ['pdf'], maxfiles: 1 } }}
              onChange={file => handleSubmitArchives(file, 'autorizacao_polo')}
              hasFilesUploaded={!!content.docAutorizacaoPolo}
            />
          </Form>
        </div>
      )}
    </Dialog>
  );
};

export default withDynamicHeader((props: Props) => {
  if (props.solicitation === undefined) {
    return <Loader type="spin" />;
  }
  return <RequestDetails {...props} />;
});
