import React from 'react';

import { useTranslationX } from 'i18n';
import { Grid, Row } from 'components/containers';
import { PlainField, Spacer } from 'components/elements';
import { HiringStatus } from 'pages/project/types';
import { formatDateToBrazilian } from 'utils/stringUtils';
import { getMasked } from 'utils/inputMask';

type Data = {
  agenciaConta: string;
  banco: string;
  bolsaCargaHorariaMensal: number;
  bolsaDuracao: number;
  bolsaInicio: Date;
  estado: HiringStatus;
  fonte: string;
  gpf: string;
  periodo: number;
  dataInicio: Date;
  dataFinal: Date;
  localAtividadesBairro: string;
  localAtividadesCidade: string;
  localAtividadesComplemento: string | null;
  localAtividadesInstituicao: string;
  localAtividadesLocal: string;
  localAtividadesLogradouro: string;
  localAtividadesNumero: string;
  localAtividadesOrgao: string;
  localTrabalho: string | null;
  nascimento: Date;
  nome: string;
  numeroConta: string;
  projetoCoordenador: string;
  projetoSigla: string;
  projetoTitulo: string;
  rg: string;
  rgEmissor: string;
  rgEmissorUf: string;
  sexo: string;
  tipo: string;
  titulo: string;
  vinculoContratacao: string;
  vinculoEmpregaticio: boolean;
  valorHora: number;
};

const Vacation = ({ data }: { data: Data }) => {
  const { tx } = useTranslationX('request', 'project');
  const {
    titulo,
    projetoTitulo,
    projetoSigla,
    projetoCoordenador,
    tipo,
    fonte,
    gpf,
    estado,
    nascimento,
    nome,
    sexo,
    rg,
    rgEmissor,
    rgEmissorUf,
    banco,
    agenciaConta,
    numeroConta,
    vinculoEmpregaticio,
    localTrabalho,
    localAtividadesInstituicao,
    localAtividadesLocal,
    localAtividadesOrgao,
    localAtividadesLogradouro,
    localAtividadesNumero,
    localAtividadesComplemento,
    localAtividadesBairro,
    localAtividadesCidade,
    bolsaInicio,
    bolsaCargaHorariaMensal,
    bolsaDuracao,
    valorHora,
    periodo,
    dataFinal,
    dataInicio,
  } = data;

  return (
    <Grid spacing={0} maxWidth="800px">
      <Row width={[6, 4]} align="top">
        <PlainField
          label={tx(`form.tipoNotificacao.label`)}
          value={tx(`form.tipoNotificacao.${estado}`)}
        />
        <PlainField label={tx('form.itemName')} value={titulo} />
        <PlainField label={tx('list.requestType')} value={tx(`type.${tipo}`)} />
      </Row>
      <Row width={[6, 4]} align="top">
        <PlainField label={tx('form.name.label')} value={nome} />
        <PlainField label={tx(`form.periodo`)} value={String(periodo)} />
      </Row>
      <Row width={[6, 4]} align="top">
        <PlainField
          label={tx(`form.dataInicio`)}
          value={formatDateToBrazilian(dataInicio)}
        />
        <PlainField
          label={tx(`form.dataFinal`)}
          value={formatDateToBrazilian(dataFinal)}
        />
      </Row>
      <Row width={[6, 4]} align="top">
        <PlainField label={tx('form.projectTitle')} value={projetoTitulo} />
        <PlainField label={tx('form.projectInitials')} value={projetoSigla} />
      </Row>
      <Row width={[6, 4]} align="top">
        <PlainField label={tx('form.projectCoordinator')} value={projetoCoordenador} />
      </Row>
      <Row width={[6, 4]} align="top">
        <PlainField label={tx('list.source')} value={fonte} />
        <PlainField label={tx('list.gpf')} value={gpf} />
      </Row>
      <Row width={[6, 4]} align="top">
        <PlainField label={tx('form.birth')} value={formatDateToBrazilian(nascimento)} />
        <PlainField label={tx('form.sex.label')} value={tx(`form.sex.${sexo}`)} />
      </Row>
      <Row width={[6, 4]} align="top">
        <PlainField label={tx('form.rg')} value={rg} />
        <PlainField label={tx('form.orgaoEmissor')} value={rgEmissor} />
        <PlainField label={tx('form.uf')} value={rgEmissorUf} />
      </Row>
      <Row width={[6, 4]} align="top">
        <PlainField label={tx('form.bank')} value={banco} />
        <PlainField label={tx('form.agency')} value={agenciaConta} />
        <PlainField label={tx('form.cc')} value={numeroConta} />
      </Row>
      <Spacer length={20} />
      <Row width={[6, 4]} align="top">
        <PlainField
          label={tx('form.vinculo')}
          value={tx(`boolean.${Number(vinculoEmpregaticio)}`)}
        />
        <PlainField label={tx('form.workplace')} value={localTrabalho || tx('na')} />
      </Row>
      <Spacer length={20} />
      <Row width={[6, 4]} align="top">
        <PlainField label={tx('form.institution')} value={localAtividadesInstituicao} />
        <PlainField label={tx('form.orgao')} value={localAtividadesOrgao} />
        <PlainField label={tx('form.place')} value={localAtividadesLocal} />
      </Row>
      <Spacer length={20} />
      <Row width={[6, 4]} align="top">
        <PlainField label={tx('form.street')} value={localAtividadesLogradouro} />
        <PlainField label={tx('form.number')} value={localAtividadesNumero} />
        <PlainField
          label={tx('form.complement')}
          value={localAtividadesComplemento || tx('na')}
        />
      </Row>
      <Row width={[6, 4]} align="top">
        <PlainField label={tx('form.district')} value={localAtividadesBairro} />
        <PlainField label={tx('form.city')} value={localAtividadesCidade} />
      </Row>
      <Spacer length={20} />
      <Row width={[6, 4]} align="top">
        <PlainField
          label={tx('form.scholarshipStart')}
          value={formatDateToBrazilian(bolsaInicio)}
        />
        <PlainField
          label={tx('form.monthlyWorkload')}
          value={`${bolsaCargaHorariaMensal} ${tx('form.hourspermonth')}`}
        />
        <PlainField label={tx('form.durationMonths')} value={bolsaDuracao.toFixed(0)} />
      </Row>
      <Spacer length={20} />
      <Row width={[6, 4]} align="top">
        <PlainField
          label={tx('form.valueHour')}
          value={getMasked(valorHora.toFixed(2), { pattern: 'currency' })}
        />
      </Row>
    </Grid>
  );
};

export default Vacation;
