import React from 'react';

import { useTranslationX } from 'i18n';
import { Grid, Row } from 'components/containers';
import { PlainField } from 'components/elements';
import { HiringStatus } from 'pages/project/types';

type Data = {
  estado: HiringStatus;
  fonte: string;
  gpf: string;
  projetoCoordenador: string;
  projetoSigla: string;
  projetoTitulo: string;
  tipo: string;
  titulo: string;
};

const Payment = ({ data }: { data: Data }) => {
  const { tx } = useTranslationX('request', 'project');
  const {
    titulo,
    projetoTitulo,
    projetoSigla,
    projetoCoordenador,
    tipo,
    fonte,
    gpf,
    estado,
  } = data;

  return (
    <Grid spacing={0} maxWidth="800px">
      <Row width={[6, 4]} align="top">
        <PlainField
          label={tx(`form.tipoNotificacao.label`)}
          value={tx(`form.tipoNotificacao.${estado}`)}
        />
        <PlainField label={tx('form.itemName')} value={titulo} />
        <PlainField label={tx('list.requestType')} value={tx(`type.${tipo}`)} />
      </Row>
      <Row width={[6, 4]} align="top">
        <PlainField label={tx('form.projectTitle')} value={projetoTitulo} />
        <PlainField label={tx('form.projectInitials')} value={projetoSigla} />
      </Row>
      <Row width={[6, 4]} align="top">
        <PlainField label={tx('form.projectCoordinator')} value={projetoCoordenador} />
      </Row>
      <Row width={[6, 4]} align="top">
        <PlainField label={tx('list.source')} value={fonte} />
        <PlainField label={tx('list.gpf')} value={gpf} />
      </Row>
    </Grid>
  );
};

export default Payment;
