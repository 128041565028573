import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useTranslationX } from 'i18n';
import { Button } from 'components/elements';
import { Check } from 'components/icons';
import { classes, renderComponent } from 'utils/components';
import { Profile, getIcon } from '../profile';
import { RegisterAction } from '../types';

import styles from './ProfileSelector.module.scss';

const ProfileSelector = ({ dispatch }: { dispatch: React.Dispatch<RegisterAction> }) => {
  const [selected, setSelected] = useState<Profile>();
  const { tx } = useTranslationX('profile', 'register');

  const location = useLocation<{ isIFCE: boolean }>();
  const history = useHistory();

  const isIFCE = location.state.isIFCE;

  const card = (profile: Profile) => {
    return (
      <div
        className={classes(styles.card, profile === selected ? styles.selected : '')}
        onClick={() => setSelected(profile)}
      >
        <div className={styles.badge}>{renderComponent(getIcon(profile))}</div>
        <div className={styles.label}>{tx(profile)}</div>
        <div className={styles.check}>
          <Check />
        </div>
      </div>
    );
  };

  const renderCards = () => {
    if (isIFCE) {
      return (
        <>
          {card(Profile.RESEARCHER)}
          {card(Profile.CONTRIBUTOR)}
        </>
      );
    }

    return (
      <>
        {card(Profile.STUDENT)}
        {card(Profile.CONTRIBUTOR)}
        {card(Profile.COMPANY)}
      </>
    );
  };

  return (
    <div className={styles.selector}>
      <div className={styles.header}>
        <div>{tx('title')}</div>
        <div>{tx('subtitle')}</div>
      </div>

      <div className={styles.content}>{renderCards()}</div>

      <div className={styles.footer}>
        <Button
          type={selected ? 'primary' : 'disabled'}
          onClick={() => dispatch({ type: 'INIT', payload: selected })}
        >
          {tx('button')}
        </Button>
        <span onClick={() => history.push('/login', { getCredentials: true })}>
          {tx('cancel')}
        </span>
      </div>
    </div>
  );
};

export default ProfileSelector;
