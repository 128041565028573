import React from 'react';

import Field, { Props as FieldProps } from '../Field/Field';
import Input, { Props as InputProps } from 'components/inputs/DatePicker/DatePicker';

const DatePicker: React.FC<FieldProps & InputProps> = ({ ...rest }) => {
  const dataType = rest.range ? 'dateRange' : !rest.showTime ? undefined : 'date';
  const props = { ...rest, dataType };
  return <Field {...props} component={Input} />;
};

DatePicker.displayName = 'DatePicker';
export default DatePicker;
