import React from 'react';
// import { MdCircle as UncheckIcon } from 'react-icons/md';

import { Radio as DotIcon } from 'components/icons';

import Check, { CheckProps } from '../Check/Check';

const Radio: React.FC<CheckProps> = props => (
  <Check
    {...props}
    check={{
      size: 0.4,
      icon: DotIcon,
      unselectedColor: '#37A64A',
      selectedColor: '#FFFF',
    }}
    uncheck={{
      size: 1,
      icon: DotIcon,
    }}
  />
);

export default Radio;
