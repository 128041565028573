import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import HeaderCell from 'components/containers/TableList/HeaderCell';
import { DispatchFilter, StateFilter } from 'components/containers/TableList/types';

import { bindTableFixedBehavior, classes } from 'utils/components';
import { FaArrowLeft, FaArrowRight, FaRegIdCard } from 'react-icons/fa';

import { MilestoneConclusions } from '../types';
import { IconButton, Loader, NotFoundMessage } from 'components/elements';
import { useTranslationX } from 'i18n';

import { useFetch } from 'apis';

import { ScrollPanel } from 'components/containers';
import { useFormObserver } from 'components/form';

import styles from './ConclusionPlanner.module.scss';
import { ConclusionRow } from '.';

type Props = {
  dispatchFilter?: DispatchFilter;
  stateFilter?: StateFilter;
  maxHeight?: string | number;
};

const ConclusionPlanner: React.FC<Props> = ({
  dispatchFilter,
  maxHeight = '50vh',
  stateFilter,
}) => {
  const [offset, setOffset] = useState(0);
  const [headerH, setHeaderH] = useState(0);

  const { id: projectId } = useParams<{ id: string }>();

  const { data, reload } = useFetch<MilestoneConclusions>(
    `/projeto/${projectId}/macroentregas/conclusao`
  );

  const { tx } = useTranslationX('milestoneConclusionPanel', 'project');
  const observableRange = useFormObserver('range', true);
  const range = observableRange?.data?.value || 12;

  const handleUpdateConclusion = useCallback(() => {
    reload();
  }, [reload]);

  if (!data) {
    return <Loader type="spin" />;
  }
  const totalMonths = Math.min(range, data.duracao);

  const renderHeaderMonthNode = (relativeMonth: number) => {
    return (
      <div>
        <span>
          {tx('table.month', {
            month: relativeMonth,
          })}
        </span>
        <span>{`${data.meses ? data.meses[relativeMonth - 1] : '...'}`}</span>
      </div>
    );
  };

  if (data.macroentregas.length === 0) {
    return (
      <NotFoundMessage
        title={tx('notFound.title')}
        description={tx('notFound.description')}
        icon={FaRegIdCard}
      />
    );
  }
  return (
    <div className={classes(styles.conclusionPlanner)}>
      <ScrollPanel
        vBar={{ start: headerH, overlay: true }}
        style={{
          root: {
            maxHeight,
          },
        }}
      >
        <table>
          <thead ref={ref => (ref ? setHeaderH(ref.offsetHeight) : null)}>
            <tr>
              <HeaderCell
                level={0}
                dispatchFilter={dispatchFilter}
                stateFilter={stateFilter}
                config={{
                  title: tx('table.name'),
                  field: 'name',
                  colspan: 1,
                  rowspan: 1,
                  align: 'left',
                  sortDir: 'asc',
                  sort: true,
                  search: true,
                }}
              />
              <td ref={bindTableFixedBehavior}>
                <IconButton
                  rate={0.4}
                  icon={FaArrowLeft}
                  shape="circle"
                  onClick={() => setOffset(offset => (offset - 1 < 0 ? 0 : offset - 1))}
                  type={offset === 0 ? 'disabled' : 'dark'}
                />
              </td>
              {Array.from(new Array(totalMonths), (_el, index) => (
                <th ref={bindTableFixedBehavior} key={index}>
                  {renderHeaderMonthNode(index + offset + 1)}
                </th>
              ))}
              <td ref={bindTableFixedBehavior}>
                <IconButton
                  rate={0.4}
                  icon={FaArrowRight}
                  shape="circle"
                  type={offset + totalMonths < data.duracao ? 'dark' : 'disabled'}
                  onClick={() =>
                    setOffset(offset =>
                      offset + totalMonths < data.duracao ? offset + 1 : offset
                    )
                  }
                />
              </td>
            </tr>
          </thead>
          <tbody>
            {data.macroentregas.map(macroentrega => (
              <ConclusionRow
                key={macroentrega.id}
                milestoneConclusion={macroentrega}
                maxMonth={totalMonths}
                offset={offset}
                inicioRelativo={data.inicioRelativo}
                onUpdateConclusion={handleUpdateConclusion}
                meses={data.meses}
              />
            ))}
          </tbody>
        </table>
      </ScrollPanel>
    </div>
  );
};

export default ConclusionPlanner;
