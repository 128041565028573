import { useTranslationX } from 'i18n';
import React from 'react';

import { classes } from 'utils/components';
import styles from './Status.module.scss';

type Props = {
  active?: boolean;
};

const Status = ({ active }: Props) => {
  const { tx } = useTranslationX('components', 'translation');
  return (
    <div className={classes(styles.toggle, active ? styles.active : null)}>
      {tx(`toggle.${active ? 'on' : 'off'}`)}
    </div>
  );
};

export default Status;
