import React from 'react';
import { useHistory } from 'react-router';
import { useTranslationX } from 'i18n';

import { ItemCard } from 'components/containers';
import { KeyValueRow, Tag } from 'components/elements';
import { capitalize } from 'utils/stringUtils';
import { ProjectStatus, projectStatusColors } from '../types';

type Props = {
  project: ProjectLatestItem;
};

export type ProjectLatestItem = {
  id: number;
  sigla: string;
  coordenador: string;
  titulo: string;
  areas: string;
  maturidade: string;
  status: ProjectStatus;
};

const ProjectLatestCard: React.FC<Props> = ({ project }) => {
  const history = useHistory();
  const { tx, t } = useTranslationX('dashboard', 'project');

  return (
    <ItemCard
      title={`Projeto ${project.sigla}`}
      key={'prj-' + project.id}
      leftChildren={
        <Tag
          text={t(`status.${project.status}`)}
          color={projectStatusColors[project.status]}
        />
      }
      onClick={() =>
        history.push(`/dashboard/projetos/${project.id}`, {
          ...project,
        })
      }
    >
      <KeyValueRow
        label={tx('recents.coordinator')}
        value={capitalize(project.coordenador)}
      />
      <KeyValueRow label={tx('recents.areas')} value={capitalize(project.areas)} />
      <KeyValueRow label={tx('recents.trl')} value={project.maturidade} />
    </ItemCard>
  );
};

export default ProjectLatestCard;
