import React from 'react';

import Field, { Props as FieldProps } from '../Field/Field';
import Input, {
  Props as InputProps,
} from 'components/inputs/MonthYearPicker/MonthYearPicker';

const MonthYearPicker: React.FC<FieldProps & InputProps> = props => {
  if (props.theme) {
    return <Field {...props} component={Input} />;
  } else {
    return <Field {...props} label={undefined} component={Input} theme="sneaky" />;
  }
};

MonthYearPicker.displayName = 'MonthYearPicker';
export default MonthYearPicker;
