import React, { ReactNode } from 'react';

import { AngleDown } from 'components/icons';
import Collapse from '../Collapse/Collapse';
import { classes } from 'utils/components';

import styles from './Accordion.module.scss';

type Props = {
  title: string | ReactNode;
  visible: boolean;
  onChange: (expanded: boolean) => void;
  controls?: React.ReactNode;
  appear?: boolean;
  collapsible?: boolean;
  theme?: 'classic' | 'shades-of-gray';
  type?: string;
  children?: ReactNode;
  fixedHeight?: string;
  ignoreContentInsets?: boolean;
};

const Accordion: React.FC<Props> = props => {
  const {
    visible,
    onChange,
    collapsible = true,
    ignoreContentInsets = false,
    appear,
    children,
    theme = 'classic',
    type,
    fixedHeight,
  } = props;

  const getClasses = () => {
    if (theme === 'classic') {
      return {
        accordion: styles.accordion,
        header: styles.header,
        title: styles.title,
        controls: styles.controls,
        icon: classes(styles.icon, visible ? styles.visible : ''),
      };
    }

    return {
      accordion: styles.grayAccordion,
      header: styles.grayHeader,
      title: classes(styles.grayTitle, type ? styles[type] : ''),
      controls: styles.grayControls,
      icon: classes(styles.grayIcon, visible ? styles.grayVisible : ''),
    };
  };

  const stylesCSS = getClasses();

  return (
    <div className={stylesCSS.accordion}>
      <div
        className={stylesCSS.header}
        style={collapsible ? undefined : { cursor: 'default' }}
        onClick={() => onChange(!visible)}
      >
        <div className={stylesCSS.title}>{props.title}</div>
        <div className={stylesCSS.controls}>{props.controls}</div>
        <div
          className={stylesCSS.icon}
          style={collapsible ? undefined : { visibility: 'hidden' }}
        >
          <AngleDown />
        </div>
      </div>

      <Collapse
        visible={visible}
        appear={appear}
        fixedHeight={fixedHeight}
        ignoreContentInsets={ignoreContentInsets}
      >
        {children}
      </Collapse>
    </div>
  );
};

export default Accordion;
