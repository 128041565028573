import React, { ReactNode, useEffect, useRef, useState } from 'react';
import Tippy from '@tippyjs/react/headless';

import { ScrollPanel } from 'components/containers';
import { FieldOptions, FieldOption } from 'components/form/types';
import { call, splitValueUnit } from 'utils';

import styles from './Dropdown.module.scss';

type Props = {
  visible: boolean;
  anchor: HTMLElement;
  options: FieldOptions;
  renderOption: (option: FieldOption) => ReactNode;
  onBlur?: () => void;
  onMount?: () => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  fitWidth?: boolean;
  attachRef?: (el: HTMLDivElement) => void;
};

const Dropdown = (props: Props) => {
  const {
    visible,
    anchor,
    options,
    renderOption,
    onBlur,
    onMount,
    onKeyDown,
    fitWidth,
    attachRef,
  } = props;

  const [height, setHeight] = useState<string | number>('auto');
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setHeight(visible ? Math.min(contentRef.current?.offsetHeight || 0, 240) : 'auto');
  }, [visible]);

  if (!anchor || !Array.isArray(options) || !options.length) {
    return null;
  }

  const renderContent = (props: object) => {
    const { width } = anchor.getBoundingClientRect();
    const { value: border } = splitValueUnit(styles.borderWidth);

    return (
      <div
        ref={contentRef}
        className={styles.dropdown}
        {...props}
        style={{
          width: fitWidth ? 'auto' : width - border * 2,
          height,
          marginLeft: border,
        }}
        onKeyDown={onKeyDown}
        tabIndex={0}
      >
        <ScrollPanel vBar={{ overlay: false }}>
          <div className={styles.content}>
            {Array.isArray(options)
              ? options.map(el => (
                  <div className={styles.option} key={el.value}>
                    {renderOption(el)}
                  </div>
                ))
              : null}
          </div>
        </ScrollPanel>
      </div>
    );
  };

  return (
    <Tippy
      appendTo={() => document.body}
      placement="bottom-start"
      offset={[0, 7]}
      ref={attachRef}
      render={renderContent}
      interactive={true}
      visible={visible}
      reference={anchor}
      onMount={() => {
        if (attachRef && contentRef.current !== null) {
          attachRef(contentRef.current);
        }
        contentRef.current?.focus();
        call(onMount);
      }}
      onClickOutside={() => call(onBlur)}
      popperOptions={{
        modifiers: [
          {
            name: 'preventOverflow',
            enabled: false,
          },
        ],
      }}
      aria={{ content: null, expanded: false }}
    />
  );
};

export default Dropdown;
