import React from 'react';

import { classes } from 'utils/components';

import styles from './PlainField.module.scss';

type Props = {
  label: string;
  value: string;
  type?: 'text' | 'url' | 'html';
  style?: React.CSSProperties;
  padding?: 'normal' | 'none';
};

const PlainField = ({ label, value, type = 'text', padding = 'normal' }: Props) => {
  return (
    <div className={classes(styles.plainField, styles[padding])}>
      <span className={styles.label}>{label}</span>

      {type === 'html' && (
        <span className={styles.value} dangerouslySetInnerHTML={{ __html: value }} />
      )}

      {type === 'text' && <span className={styles.value}>{value}</span>}

      {type === 'url' && (
        <a href={value} target="_blank" rel="noopener noreferrer">
          <span className={styles.value}>{value}</span>
        </a>
      )}
    </div>
  );
};

export default PlainField;
