import React, { useState } from 'react';

import { Tab, Tabs } from 'components/containers';
import { renderComponent } from 'utils/components';
import { Form, useDiff, useFormObserver } from 'components/form';
import { SectionProps } from 'pages/project/update-wp/UpdateWpEditor';
import { Checkbox, Loader, WrapperCard } from 'components/elements';
import { useTranslationX } from 'i18n';
import { Profile } from 'pages/register/profile';
import { getDataFromObserver } from 'components/form/helpers';
import { RangedMilestone } from 'pages/project/types';
import { ScheduleTab } from '.';

const DisbursementSchedule = (props: SectionProps) => {
  const { formProps, state, dispatch, profile } = props;

  const { tx } = useTranslationX('disbursementSchedule', 'update_wp');
  const isSupervisor = profile === Profile.SUPERVISOR;

  const [currTab, setCurrTab] = useState(0);
  const [milestoneTab, setMilestoneTab] = useState(0);

  function onChangeTab(index: number) {
    setCurrTab(index);
  }

  function onChangeMilestoneTab(index: number) {
    setMilestoneTab(index);
  }

  return (
    <Form
      {...formProps}
      baseUrl={`/projeto/${state.id}/alteracao-pt/aporte`}
      name={`${formProps.name}.aporte`}
      hotReload
    >
      <WrapperCard maxWidth="40vw" minWidth="700px" padding="0px 20px 20px 10px">
        {!isSupervisor && (
          <Checkbox
            value={state.declaration}
            label={tx('declarationOk')}
            selected={!!state.declaration}
            onChange={checked =>
              dispatch({ type: 'TOGGLE_DECLARATION', payload: Number(checked) })
            }
          />
        )}
        <Content
          forwardProps={props}
          currTab={currTab}
          milestoneTab={milestoneTab}
          onChangeMilestoneTab={onChangeMilestoneTab}
          onChangeTab={onChangeTab}
        />
      </WrapperCard>
    </Form>
  );
};

type ContentProps = {
  forwardProps: SectionProps;
  currTab: number;
  onChangeTab: (index: number) => void;
  milestoneTab: number;
  onChangeMilestoneTab: (index: number) => void;
};

const Content = ({
  forwardProps,
  currTab,
  onChangeTab,
  milestoneTab,
  onChangeMilestoneTab,
}: ContentProps) => {
  const parts = getDataFromObserver<string[]>(useFormObserver('partes', true), []);
  const milestones = getDataFromObserver<RangedMilestone[]>(
    useFormObserver('macroentregas', true),
    []
  );

  const isValid = parts && milestones;

  const { removedRows, getDiff } = useDiff('aportes');

  if (!isValid) return <Loader />;

  return (
    <Tabs selectedIndex={currTab} onChangeTab={onChangeTab} cascade="cascade">
      {parts?.map((key, index) => (
        <Tab title={key} key={key}>
          {renderComponent(ScheduleTab, {
            parts,
            milestones,
            removedRows,
            getDiff,
            partIdx: index,
            currTab: milestoneTab,
            onChangeTab: onChangeMilestoneTab,
            ...forwardProps,
          })}
        </Tab>
      ))}
    </Tabs>
  );
};

export default DisbursementSchedule;
