import React from 'react';

const Institution = ({ size = 1.2 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none">
    <path
      d="m19 7h-6.28l-0.54-1.63c-0.132-0.399-0.387-0.746-0.728-0.992-0.341-0.246-0.75-0.378-1.17-0.378h-5.28c-0.263-4.8e-5 -0.523 0.0516-0.765 0.152-0.243 0.1-0.463 0.248-0.649 0.434-0.186 0.186-0.333 0.406-0.434 0.649-0.1 0.243-0.152 0.503-0.152 0.765v12c-4.8e-5 0.263 0.0516 0.523 0.152 0.765 0.1 0.243 0.248 0.463 0.434 0.649s0.406 0.333 0.649 0.434c0.243 0.1 0.503 0.152 0.765 0.152h14c0.263 0 0.523-0.0517 0.765-0.152 0.243-0.1 0.463-0.248 0.649-0.434 0.186-0.186 0.333-0.406 0.434-0.649 0.1-0.243 0.152-0.503 0.152-0.765v-9c0-0.263-0.0516-0.523-0.152-0.765-0.1-0.243-0.248-0.463-0.434-0.649-0.186-0.186-0.406-0.333-0.649-0.434-0.243-0.1-0.503-0.152-0.765-0.152zm-4.11 9.15-3.24 1.62-3.24-1.62v-2.88l3.24 1.62 3.24-1.62zm3.26 2.69h-0.811v-6.9l-5.69 2.84-6.5-3.25 6.5-3.25 6.5 3.25z"
      fill="currentColor"
    />
  </svg>
);

export default Institution;
