import React from 'react';
import _ from 'lodash';
import {
  Conclusion,
  conclusionStatusColors,
  DispatchButton,
  StateButton,
} from '../../types';
import { CheckBox as CheckIcon, CheckBoxFrame as UncheckIcon } from 'components/icons';
import { classes } from 'utils/components';

import styles from './ConclusionNode.module.scss';
import { useAuthState } from 'store/auth';
import { Profile } from 'pages/register/profile';

type Props = {
  conclusion: Conclusion;
  icon?: React.ElementType;
  type?: 'LAT' | 'SOL' | 'REJ' | 'PLA';
  state: StateButton;
  dispatch: DispatchButton;
  conclusionDate: Date;
};

const ConclusionNode: React.FC<Props> = ({
  conclusion,
  conclusionDate,
  dispatch,
  state,
}) => {
  const { estado } = conclusion;

  const { profile } = useAuthState();

  const isSelected = _.some(state.conclusions, { id: conclusion.id });

  const toggleConclusion = () => {
    isSelected
      ? dispatch({
          type: 'REMOVE_CONCLUSION',
          payload: {
            conclusions: state.conclusions.filter(value => value !== conclusion),
            validDate: removeValidDate(),
          },
        })
      : dispatch({
          type: 'ADD_CONCLUSION',
          payload: {
            conclusions: [...state.conclusions, conclusion],
            validDate: isValidDate(),
          },
        });
  };

  const removeValidDate = () => {
    return state.validDate.filter(value => Object.keys(value)[0] !== conclusion.id);
  };

  const isValidDate = () => {
    if (estado === 'PLA' && conclusionDate) {
      const today = new Date();
      if (conclusionDate > today) {
        return [...state.validDate, { [conclusion.id]: false }];
      }
    }
    return [...state.validDate, { [conclusion.id]: true }];
  };

  const getCheckBox = () => {
    if (isSelected) {
      return <CheckIcon size={1.2} />;
    } else {
      return <UncheckIcon size={1.2} />;
    }
  };

  const isClickable = (() => {
    if (['REJ', 'LAT', 'PLA'].includes(estado) && profile !== Profile.SUPERVISOR) {
      return true;
    }
  })();

  return (
    <div
      className={classes(styles.conclusionNode, isClickable ? styles.clickable : '')}
      style={{
        backgroundColor: conclusionStatusColors[estado],
      }}
      onClick={isClickable ? toggleConclusion : undefined}
    >
      <div className={classes(styles.checkbox, isSelected ? styles.selected : '')}>
        {getCheckBox()}
      </div>
    </div>
  );
};

export default ConclusionNode;
