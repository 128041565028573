import React, { useState } from 'react';

import { useTranslationX } from 'i18n';
import { Grid, Modal, ModalPDFViewer, Row } from 'components/containers';

import { Button, PlainField } from 'components/elements';
import { formatDateToBrazilian } from 'utils/stringUtils';
import { useRequest } from 'apis';
import { Eye } from 'components/icons';

import styles from './Accept.module.scss';

type Data = {
  arquivoTermo: number;
  data: string;
  estado: string;
  projetoCoordenador: string;
  projetoSigla: string;
  projetoTitulo: string;
  tipo: string;
  tipoAcao: string;
  titulo: string;
  valorSolicitado: any;
  nomeMacroentrega: string;
  numeroMacroentrega: number;
  previsaoConclusao: string;
};

const Accept = ({ data: props }: { data: Data }) => {
  const { tx } = useTranslationX('request', 'project');
  const { tx: tx2 } = useTranslationX('request.form', 'project');

  const [pdfData, setPdfData] = useState<string | ArrayBuffer | null>(null);

  const {
    arquivoTermo,
    data,
    projetoCoordenador,
    projetoSigla,
    projetoTitulo,
    tipo,
    tipoAcao,
    nomeMacroentrega,
    numeroMacroentrega,
    previsaoConclusao,
  } = props;

  const request = useRequest();

  const getArchive = (id: number) => {
    request<string>({
      url: `/arquivo/${id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'string',
      },
      onSuccess: handleDownload,
    });
  };

  const handleDownload = (token: string) => {
    request<any>({
      url: `/resource/${token}`,
      method: 'GET',
      responseType: 'blob',
      onSuccess: (blob: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          let base64data = reader.result;
          setPdfData(base64data);
        };
      },
      onError: data => console.error(data),
    });
  };

  return (
    <Grid spacing={0} maxWidth="800px">
      <Row width={[3, 3]} align="top">
        <PlainField label={tx('list.requestType')} value={tx(`type.${tipo}`)} />
        <PlainField label={tx('form.projectTitle')} value={projetoTitulo} />
        <PlainField label={tx('form.projectInitials')} value={projetoSigla} />
      </Row>

      <Row width={[3, 3]} align="top">
        <PlainField
          label={tx('form.milestoneActionType')}
          value={tx(`form.tipoNotificacao.${tipoAcao}`)}
        />
        <PlainField label={tx('form.projectCoordinator')} value={projetoCoordenador} />
        <PlainField
          label={tx('form.date')}
          value={formatDateToBrazilian(new Date(data))}
        />
      </Row>
      <Row width={[3, 3]} align="top">
        <PlainField label={tx('form.milestoneName')} value={nomeMacroentrega} />
        <PlainField
          label={tx('form.milestoneNumber')}
          value={String(numeroMacroentrega)}
        />
        <PlainField
          label={tx('form.previsaoConclusao')}
          value={formatDateToBrazilian(new Date(previsaoConclusao))}
        />
      </Row>

      <div className={styles.documents}>
        <Row align="top">
          <span className={styles.documentsTitle}>{tx2('uploadedDocuments')}</span>
        </Row>
        <div className={styles.uploadedDocuments}>
          {arquivoTermo && (
            <Row width={[3]} align="top">
              <Button theme="light" icon={Eye} onClick={() => getArchive(arquivoTermo)}>
                {tx('form.term')}
              </Button>
            </Row>
          )}
        </div>
      </div>

      <Modal
        visible={pdfData !== null}
        width="auto"
        onBlur={() => setPdfData(null)}
        onClose={() => setPdfData(null)}
      >
        <div>
          <ModalPDFViewer pdfData={pdfData} />
        </div>
      </Modal>
    </Grid>
  );
};

export default Accept;
