import React from 'react';

const Question = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none">
    <path
      d="m11.1 17.4h1.8v-1.8h-1.8zm0.9-14.4c-1.18 0-2.35 0.233-3.44 0.685-1.09 0.452-2.08 1.12-2.92 1.95-1.69 1.69-2.64 3.98-2.64 6.36 0 2.39 0.948 4.68 2.64 6.36 0.836 0.836 1.83 1.5 2.92 1.95 1.09 0.452 2.26 0.685 3.44 0.685 2.39 0 4.68-0.948 6.36-2.64 1.69-1.69 2.64-3.98 2.64-6.36 0-1.18-0.233-2.35-0.685-3.44-0.452-1.09-1.12-2.08-1.95-2.92-0.836-0.836-1.83-1.5-2.92-1.95-1.09-0.452-2.26-0.685-3.44-0.685zm0 16.2c-3.97 0-7.2-3.23-7.2-7.2 0-3.97 3.23-7.2 7.2-7.2 3.97 0 7.2 3.23 7.2 7.2 0 3.97-3.23 7.2-7.2 7.2zm0-12.6c-0.955 0-1.87 0.379-2.55 1.05-0.675 0.675-1.05 1.59-1.05 2.55h1.8c0-0.477 0.19-0.935 0.527-1.27s0.795-0.527 1.27-0.527 0.935 0.19 1.27 0.527 0.527 0.795 0.527 1.27c0 1.8-2.7 1.58-2.7 4.5h1.8c0-2.02 2.7-2.25 2.7-4.5 0-0.955-0.379-1.87-1.05-2.55-0.675-0.675-1.59-1.05-2.55-1.05z"
      fill="currentColor"
    />
  </svg>
);

export default Question;
