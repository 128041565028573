import React, { useState } from 'react';

import { Link, useRouteMatch } from 'react-router-dom';

import { ScrollPanel } from 'components/containers';

import styles from './SubMenu.module.scss';

type Props = {
  title: String;
  child: { text: string; path: string; adornment?: JSX.Element }[];
};

const SubMenu: React.FC<Props> = ({ title, child }) => {
  const { url } = useRouteMatch();
  const [selected, setSelected] = useState<string>(child[0].path);

  return (
    <div className={styles.subMenu}>
      <div>
        <span>{title}</span>

        <ScrollPanel>
          <div className={styles.wrapper}>
            <ul>
              {child.map(({ text, path, adornment }, index) => (
                <Link key={index} to={`${url}/${path}`}>
                  <li
                    onClick={() => setSelected(path)}
                    className={` ${selected === path ? styles.selected : ''}`}
                  >
                    {text}
                    {adornment && <div className={styles.adornment}>{adornment}</div>}
                  </li>
                </Link>
              ))}
            </ul>
            <div className={styles.margin} />
          </div>
        </ScrollPanel>
      </div>
    </div>
  );
};

export default SubMenu;
