import React, { useCallback, useState } from 'react';

import { Grid, Modal, Row, ScrollPanel, Tab, Tabs } from 'components/containers';
import CardPurchaseOrder from '../CardPurchaseOrder/CardPurchaseOrder';

import { useHistory } from 'react-router';
import { Button, NotFoundMessage, WrapperCard } from 'components/elements';
import { useAuthState } from 'store/auth';
import { PartData, CostUnitItem, RequestItem } from 'pages/project/types';
import { useProjectEditor } from 'pages/project/ProjectEditor/contexts';
import { useTranslationX } from 'i18n';
import { Form, TextField, useForm, DatePicker } from 'components/form';
import { useRequest } from 'apis';
import { Profile } from 'pages/register/profile';
import { messageService } from 'services';

import styles from './CardsTabs.module.scss';

type Props = {
  area: PartData[];
  maxWidth?: string;
  customAction?(item: RequestItem): void;
};

type ExecuteModalProps = {
  visible: boolean;
  onClose: () => any;
  projectId: number;
  ucId: number;
};

type DateMonth = {
  year: number;
  month: number;
};

const CardsTabs: React.FC<Props> = props => {
  const history = useHistory();
  const { profile } = useAuthState();
  const { dispatch, state } = useProjectEditor();
  const { maxWidth = undefined } = props;
  const emptyAreas = props.area.length === 0;

  const { tx } = useTranslationX('request.not_found_areas', 'project');

  const isResponsible = profile !== Profile.SUPERVISOR;

  const showPurchaseButton = isResponsible && state.section === 'outros';

  const [showExecuteModal, setShowExecuteModal] = useState(false);
  const [currentUc, setCurrentUc] = useState<number>();

  const lastTab = localStorage.getItem('@sippi-keep-tab') ?? 0;

  return (
    <WrapperCard
      padding="5px 15px 15px"
      maxWidth={emptyAreas ? '100%' : maxWidth}
      width={emptyAreas ? '100%' : 'fit-content'}
    >
      {!emptyAreas ? (
        <Tabs selectedIndex={parseInt(lastTab as string)} keepState>
          {props.area.map((item: PartData, indexArea: number) => {
            return (
              <Tab title={item.nome} key={indexArea}>
                <ScrollPanel
                  vBar={{ overlay: true }}
                  style={{ content: { maxHeight: '460px' } }}
                >
                  <div className={styles.cardsContainer}>
                    {(item.ucs as CostUnitItem[]).map((card, indexItem) => (
                      <CardPurchaseOrder
                        showPurchaseOrder={!showPurchaseButton}
                        key={indexItem}
                        data={card}
                        onItemSelected={() => {
                          dispatch({ type: 'SET_ITEM', payload: card });
                          history.push(history.location.pathname + '/solicitacao');
                        }}
                        onExecuteItem={() => {
                          setShowExecuteModal(true);
                          setCurrentUc(card.id);
                        }}
                      />
                    ))}
                  </div>
                </ScrollPanel>
                {!showPurchaseButton && (
                  <Form>
                    <ExecuteModal
                      visible={showExecuteModal}
                      onClose={() => {
                        setShowExecuteModal(false);
                        dispatch({
                          type: 'SET_UPDATE_COUNT',
                          payload: state.updateCount ? state.updateCount + 1 : 1,
                        });
                      }}
                      projectId={state.id}
                      ucId={currentUc as number}
                    />
                  </Form>
                )}
              </Tab>
            );
          })}
        </Tabs>
      ) : (
        <NotFoundMessage title={tx('title')} description={tx('description')} />
      )}
    </WrapperCard>
  );
};

const ExecuteModal = (props: ExecuteModalProps) => {
  const { visible, onClose, projectId, ucId } = props;
  const { tx } = useTranslationX('request.not_found_areas', 'project');

  const form = useForm();

  const request = useRequest();

  const handleExecutedValue = useCallback(() => {
    const executedValue = form.getFieldValue('executedValue');
    const executedDate = form.getFieldValue('executedDate') as DateMonth;
    if (executedDate && executedValue) {
      const requestBody = {
        uc_id: ucId,
        valor: executedValue,
        data_execucao: executedDate,
      };

      const source = request<{ error: string }>({
        method: 'POST',
        url: `projeto/${projectId}/execucao/outras-despesas`,
        data: requestBody,
        onSuccess: onClose(),
        onError: error => messageService.error(error.response?.data.error as string),
      });

      return () => source.cancel();
    }
  }, [form, projectId, request, onClose, ucId]);

  return (
    <Modal visible={visible} onBlur={onClose} onClose={onClose} width={600}>
      <div className={styles.executedModal}>
        <span>{tx('executedValueLabel')}</span>
        <Grid>
          <Row width={[6, 6]}>
            <TextField
              name="executedValue"
              label={tx('executedValue')}
              metadata={{
                rules: {
                  mask: {
                    pattern: 'currency',
                  },
                },
              }}
            />
            <DatePicker name="executedDate" label={tx('executionDate')} />
          </Row>
        </Grid>

        <Button onClick={handleExecutedValue}>{tx('confirmExecutedValue')}</Button>
      </div>
    </Modal>
  );
};

export default CardsTabs;
