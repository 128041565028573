import React from 'react';

const CheckBox = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 18 18">
    <g transform="translate(1.9 1.9)">
      <path
        d="m2 0c-1.11 0-2 0.892-2 2v10.2c0 1.11 0.892 2 2 2h10.2c1.11 0 2-0.892 2-2v-10.2c0-1.11-0.892-2-2-2zm9.39 2.6a0.9 0.9 0 0 1 0.498 0.162 0.9 0.9 0 0 1 0.223 1.25l-2.71 3.89-2.47 3.54a0.9 0.9 0 0 1-1.39 0.0996l-3.37-3.6a0.9 0.9 0 0 1 0.041-1.27 0.9 0.9 0 0 1 1.27 0.041l2.61 2.79 1.83-2.63 2.71-3.88a0.9 0.9 0 0 1 0.756-0.385z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default CheckBox;
