import React from 'react';

import { Dialog, Grid, Row } from 'components/containers';
import { Button, PlainField, WrapperCard } from 'components/elements';
import { Form, SubmitButton, TextField } from 'components/form';
import { useTranslationX } from 'i18n';
import { Milestone } from '../../../types';

import styles from './ConclusionRow.module.scss';

type ProgressModalProps = {
  visble: boolean;
  onClose: () => void;
  milestone: Milestone;
  onSubmit: (body: object) => () => void;
  currentConclusion: {
    value?: number;
    dates: {
      relativeDate: string;
      realDate: string;
    };
  };
};

export function ProgressModal({
  visble,
  onClose,
  milestone,
  onSubmit,
  currentConclusion,
}: ProgressModalProps) {
  const { tx } = useTranslationX('milestoneConclusionPanel.progressModal', 'project');

  return (
    <Dialog
      visible={visble}
      onBlur={onClose}
      onClose={onClose}
      title={tx('milestoneExecution')}
      closeButton
      titleAlign="center"
    >
      <WrapperCard minWidth="500px" height="100%" padding="20px 15px 20px">
        <Form
          display={{
            optional: false,
          }}
          onSubmit={({ requestBody }) => {
            const body = requestBody as {
              conclusao: string;
            };
            onSubmit({
              conclusao: Number(body.conclusao),
            });
          }}
        >
          <Grid spacing={0}>
            <Row align="top">
              <PlainField label={tx('title')} value={milestone?.titulo as string} />

              <PlainField
                type="html"
                label={tx('month')}
                value={`<div style="display: flex; flex-direction: column; text-transform: uppercase;">
                <span >${currentConclusion.dates.relativeDate}</span><span >${currentConclusion.dates.realDate}</span>
                </div> `}
              />
            </Row>
            <Row width={[12]}>
              <TextField
                name="conclusao"
                label={tx('conclusionPercentage')}
                adornment={{
                  right: '%',
                }}
                placeholder="00"
                style={{
                  body: {
                    maxWidth: '50%',
                  },
                  root: {
                    padding: '10px 0px 0px 10px',
                  },
                }}
                value={currentConclusion.value || 0}
                metadata={{
                  rules: {
                    mask: { pattern: '##0' },

                    max: 100,
                    min: 0,
                  },
                }}
              />
            </Row>
            <div className={styles.progressModalActions}>
              <Button onClick={onClose} type="secondary" style={{ root: { width: 124 } }}>
                {tx('cancel')}
              </Button>
              <SubmitButton>{tx('updateConclusion')}</SubmitButton>
            </div>
          </Grid>
        </Form>
      </WrapperCard>
    </Dialog>
  );
}
