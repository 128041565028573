import React, { useReducer } from 'react';

import NotificationList from './NotificationList';
import Content from './Content';
import Filters from './Filters';
import { Action, State } from './types';
import { useTranslationX } from 'i18n';
import banner from 'assets/images/office.jpg';

import styles from './Homologacao.module.scss';

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'FILTER_UPDATE':
      return { status: 'loading_list', filters: action.payload };

    case 'LIST_UPDATE':
      return { ...state, status: 'idle', notifications: action.payload };

    case 'SELECT': {
      const { filters, notifications, selected } = state;

      return selected !== action.payload
        ? { status: 'loading_data', selected: action.payload, filters, notifications }
        : state;
    }

    case 'CONTENT_UPDATE':
      if (action.payload && state.selected) {
        state.selected.read = true;
      }
      return { ...state, status: 'idle', content: action.payload };

    case 'MODAL_OPEN':
      return { ...state, modal: action.payload };

    case 'RELOAD':
      return action.payload
        ? { status: 'loading_list', filters: state.filters }
        : { ...state, modal: undefined };

    case 'ERROR':
      return { ...state, status: 'idle', error: action.payload };

    default:
      return state;
  }
};

const Homologacao = () => {
  const [state, dispatch] = useReducer(reducer, { status: 'init' });

  const isEmpty = state.status === 'idle' && !state.notifications?.length;

  return (
    <div className={styles.homologacao}>
      <Filters dispatch={dispatch} />
      {isEmpty && <EmptyScreen />}
      {!isEmpty && (
        <div className={styles.columns}>
          <NotificationList state={state} dispatch={dispatch} />
          <Content state={state} dispatch={dispatch} />
        </div>
      )}
    </div>
  );
};

export const EmptyScreen = () => {
  const { tx: t1 } = useTranslationX('homologacao', 'supervisor');

  return (
    <div className={styles.emptyBanner}>
      <img src={banner} alt="banner" />
      <div className={styles.empty}>{t1('empty')}</div>
    </div>
  );
};

export default Homologacao;
