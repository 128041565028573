import React, { ReactNode } from 'react';
import { FaGrimace as ErrorIcon } from 'react-icons/fa';

import { useTranslation } from 'i18n';
import styles from './ErrorMessage.module.scss';

type Props = {
  message?: string;
  children?: ReactNode;
};

const ErrorMessage: React.FC<Props> = ({ message, children }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.errorMessage}>
      <ErrorIcon />
      <span>{t(message || 'components.error.default')}</span>
      {children}
    </div>
  );
};

export default ErrorMessage;
