import React from 'react';

const Underline = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none">
    <path
      d="m18.7 10.7c0 3.39-2.42 6.41-5.79 6.84-4.07 0.522-7.54-2.64-7.54-6.61v-7.5c0-0.767 0.622-1.39 1.39-1.39 0.767 0 1.39 0.622 1.39 1.39v7.5c0 2.41 2.2 4.32 4.7 3.8 1.82-0.367 3.08-2.06 3.08-3.91v-7.39c0-0.767 0.622-1.39 1.39-1.39 0.767 0 1.39 0.622 1.39 1.39zm-13.3 11.3c-0.611 0-1.11-0.5-1.11-1.11s0.5-1.11 1.11-1.11h13.3c0.611 0 1.11 0.5 1.11 1.11s-0.5 1.11-1.11 1.11z"
      clipRule="evenodd"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default Underline;
