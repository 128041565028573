import React from 'react';

const DeadlineMarker = ({ size = 1 }: { size?: number }) => {
  return (
    <svg
      width={`${size}em`}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0713 3L8.07129 0L5.07129 3H0V18H16V3H11.0713Z"
        fill="#37A64A"
      />
    </svg>
  );
};

export default DeadlineMarker;
