import React, { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import _ from 'lodash';
import { useTranslationX } from 'i18n';
import { ScrollPanel } from 'components/containers';
import { ErrorMessage } from 'components/elements';
import { ReadOnlyInfoDialog } from 'components/form';
import { Props as FormProps } from 'components/form/Form';
import { FormInstance } from 'components/form/types';
import { ContentSwitcher, TopSubMenu } from 'pages/dashboard';
import { TopMenuItemIcon } from 'pages/dashboard/types';
import { Profile } from 'pages/register/profile';
import { editGadgets, resetGadgets } from 'store/profile';
import SectionHeader from 'pages/proposal/ProposalEditor/SectionHeader';
import * as icons from 'components/icons';
import { withDynamicHeader } from 'components/containers/DynamicHeader';

import {
  Counterpart,
  Description,
  DisbursementSchedule,
  Eap,
  Financial,
  Identification,
  MilestoneSection,
  SummaryAndActions,
} from '..';

import { Dispatch, EditorAction, EditorState } from './types';

import { UpdateWpValidator, History, FixedButtons } from '.';
import { useDispatch } from 'react-redux';
import ItemTopSubMenu from 'pages/dashboard/TopSubMenu/ItemTopSubMenu/ItemTopSubMenu';

import styles from './UpdateWpEditor.module.scss';

const allSections: TopMenuItemIcon[] = [
  { key: 'identificacao', component: Identification, icon: icons.Chart },
  { key: 'descricao', component: Description, icon: icons.ThirdPartyServices },
  { key: 'eap', component: Eap, icon: icons.Travel },
  // { key: 'originalidade', component: Originalidade, icon: icons.CardChecklist },
  { key: 'metas-macro', component: MilestoneSection, icon: icons.AirportShuttle },
  { key: 'orcamento', component: Financial, icon: icons.TableArrow },
  { key: 'contrapartida', component: Counterpart, icon: icons.Expenditure },
  { key: 'cronograma', component: DisbursementSchedule, icon: icons.TableCheck },
  { key: 'resumo', component: SummaryAndActions, icon: icons.Percentage },
];

const specialCompareSections = {
  orcamento: {
    sections: [
      'rh',
      'passagem',
      'equipamentos',
      'outros',
      'servico',
      'material',
      'suporte',
      'diaria',
    ],
  },
  'metas-macro': {
    sections: ['macroentrega'],
  },
  cronograma: {
    sections: ['aporte'],
  },
  contrapartida: {
    sections: ['economico'],
  },
  descricao: {
    sections: ['descritivo'],
  },
};

export type EditorType = 'alteracao-pt' | 'aditivo';

type BackwardProps = {
  type: EditorType;
  profile: Profile;
  sigla: string;
  titulo: string;
  id: number;
};

export type SectionProps = BackwardProps & {
  formProps: FormProps;
  state: EditorState;
  dispatch: Dispatch;
};

const reducer = (state: EditorState, action: EditorAction): EditorState => {
  switch (action.type) {
    case 'SET_SECTION': {
      const section = action.payload;
      const forms: { [key: string]: FormInstance } = {};
      if (section) {
        for (const key in state.forms) {
          if (key.startsWith(section)) {
            forms[key] = state.forms[key];
          }
        }
      }
      return { ...state, section, forms };
    }

    case 'FORM_UPDATE': {
      const { editable, name, form } = action.payload;
      return {
        ...state,
        editable,
        forms: { ...state.forms, [name]: form },
      };
    }
    case 'TOGGLE_DIFF': {
      return {
        ...state,
        showDiff: action.payload,
      };
    }
    case 'TOGGLE_DECLARATION': {
      return {
        ...state,
        declaration: action.payload,
      };
    }

    case 'TOGGLE_HISTORY': {
      return {
        ...state,
        historyVisible: action.payload,
      };
    }

    case 'CLOSE_HISTORY': {
      return {
        ...state,
        historyVisible: false,
      };
    }

    case 'SET_HISTORIC_DATA': {
      return {
        ...state,
        historicData: action.payload,
      };
    }

    case 'SET_ERRORS': {
      return { ...state, errors: action.payload };
    }

    case 'SHOW_ERRORS': {
      return { ...state, showErrors: action.payload };
    }

    case 'HAS_ERRORS': {
      return { ...state, hasErrors: action.payload };
    }
    case 'SHOW_READONLY': {
      return { ...state, showReadOnly: action.payload };
    }
    case 'SET_TAB_ERRORS': {
      return { ...state, tabErrors: action.payload };
    }
    case 'LOCK_NAVIGATION': {
      return { ...state, lockNavigation: action.payload };
    }

    default:
      return state;
  }
};

const UpdateWpEditor = (props: BackwardProps) => {
  const { profile } = props;
  const [state, dispatch] = useReducer(reducer, {
    ...props,
    editable: false,
    showDiff: false,
    historyVisible: false,
    forms: {},
    declaration: 0,
    historicData: {},
  });

  const reduxDispatch = useDispatch();

  const { t, tx } = useTranslationX('editor', 'update_wp');

  const toggleDiff = useCallback(() => {
    const newShowDiff = !state.showDiff;
    dispatch({
      type: 'TOGGLE_DIFF',
      payload: newShowDiff,
    });
    Object.values(state.forms).forEach(form => {
      form.context.setShowDiff(newShowDiff);
    });
  }, [state.forms, state.showDiff]);

  const toggleHistory = useCallback(() => {
    const newHistoryVisible = state.showDiff ? false : !state.historyVisible;
    dispatch({ type: 'TOGGLE_HISTORY', payload: newHistoryVisible });
  }, [state.historyVisible, state.showDiff]);

  const onPopulate = useCallback(
    (values: { [key: string]: any }, form: FormInstance) => {
      if (form.name) {
        const editable = !!values['editavel'];
        form.context.setEditable(editable);
        form.context.setShowDiff(state.showDiff);
        dispatch({
          type: 'FORM_UPDATE',
          payload: {
            editable,
            name: form.name,
            form,
          },
        });
      }
    },
    [state.showDiff]
  );

  const isSupervisor = profile === Profile.SUPERVISOR;

  const populate = useRef({});

  const sections = useMemo(() => {
    return _.filter(allSections, section => {
      if (section.key === 'observacoes') {
        return false;
      }
      return isSupervisor || section.key !== 'divisao-orcamento';
    });
  }, [isSupervisor]);

  const toggleDiffAndHistory = useCallback(() => {
    toggleDiff();
    toggleHistory();
  }, [toggleDiff, toggleHistory]);

  useEffect(() => {
    if (isSupervisor) {
      const selected = state.showDiff;
      reduxDispatch(
        editGadgets({
          profile,
          gadgets: [
            {
              key: 'showDiff',
              component: ItemTopSubMenu,
              itemProps: {
                label: tx('changes_history'),
                onClick: toggleDiffAndHistory,
                selected,
                icon: selected ? icons.X : icons.Menu,
              },
            },
          ],
        })
      );
    }
    return () => {
      reduxDispatch(resetGadgets(profile));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, reduxDispatch, state.showDiff, toggleDiffAndHistory]);

  if (!sections) {
    return <ErrorMessage />;
  }

  const onFormClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const eventTarget = event.target;
    const matchFieldsPrefix = ['check', 'textinput', 'textarea'];

    if (eventTarget instanceof Element) {
      const target = eventTarget as Element;
      let find = Object.values(target.querySelectorAll('div'))
        .splice(0, 3)
        .some(element => {
          return matchFieldsPrefix.some(fieldName =>
            element.className.toLowerCase().startsWith(fieldName.toLowerCase())
          );
        });

      if (!find && target.tagName === 'SPAN') {
        if (!!target.getAttribute('data-text')) {
          find = true;
        }
      }

      const isEmpty = Object.keys(state.forms).length === 0;

      if (!state.editable && !isEmpty && find) {
        dispatch({ type: 'SHOW_READONLY', payload: true });
      }
    }
  };

  const createAdornment = (section: string) => {
    if (state.showErrors && state.errors) {
      const { errors, warnings } = state.errors[section] || {};

      if (errors || warnings) {
        return (
          <div className={styles.errorAdornment}>
            {!!errors && <span className={styles.checkErrorUp}>{errors}</span>}
            {!!warnings && <span className={styles.checkErrorDown}>{warnings}</span>}
          </div>
        );
      }
    }

    return undefined;
  };

  const forwardProps: SectionProps = {
    ...props,
    formProps: {
      populate: populate.current,
      autosave: true,
      onPopulate,
      display: { error: state.showErrors ? 'always' : 'touched' },
    },
    state,
    dispatch,
  };

  const getForms = (match: string) => {
    return Object.values(state.forms).filter(form => form.name?.startsWith(match));
  };

  const findSectionChanges = (key: string) => {
    const commonSection = Object.keys(state.historicData).find(
      section => section === key
    );
    if (!commonSection) {
      return specialCompareSections[
        key as keyof typeof specialCompareSections
      ]?.sections.some(section => {
        return state.historicData[section]?.length > 0 && state.showDiff;
      });
    }

    return state.historicData[key]?.length > 0;
  };

  const formInstances = state.section ? getForms(state.section) : [];

  return (
    <div className={styles.editor}>
      <History state={state} dispatch={dispatch} instances={formInstances} />
      <div className={styles.menu}>
        <TopSubMenu
          blocked={state.lockNavigation}
          child={sections.map(({ key, path, icon }) => ({
            label: tx(`sections.${key}`),
            icon: icon,
            path: path || key,
            adornment: createAdornment(key),
            ...(state.showDiff && {
              hasChanges: findSectionChanges(key),
            }),
          }))}
        />
        <FixedButtons state={state} />
      </div>

      <ScrollPanel>
        <div className={styles.content} onClick={onFormClick}>
          <SectionHeader
            title={props.titulo}
            acronym={props.sigla}
            form={state.section ? state.forms[state.section] : null}
            showInfoButton={false}
          />

          <UpdateWpValidator
            state={state}
            dispatch={dispatch}
            instances={formInstances}
          />
          <ReadOnlyInfoDialog
            visible={!!state.showReadOnly}
            onClose={() => dispatch({ type: 'SHOW_READONLY', payload: false })}
            message={t('actions.error-blocked-message') || ''}
          />
          <div className={styles.body}>
            <ScrollPanel vBar={{ overlay: true, placeholder: true }}>
              <ContentSwitcher
                items={sections}
                forwardProps={({ key }) => ({
                  ...forwardProps,
                  formProps: { ...forwardProps.formProps, name: key },
                  state,
                  dispatch,
                })}
                onChange={item => {
                  dispatch({ type: 'SET_SECTION', payload: item?.key });
                }}
              />
            </ScrollPanel>
          </div>
        </div>
      </ScrollPanel>
    </div>
  );
};

export default withDynamicHeader(UpdateWpEditor);
