import React, { useEffect } from 'react';
import { createContext, useContext } from 'react';
import { DynamicHeaderProps } from '../types';

export const useDynamicHeader = () => useContext(DynamicHeaderContext);

export const withDynamicHeader = (Component: React.FC<any>) => (props: any) => {
  const { setVisibility, setFooter } = useDynamicHeader();

  useEffect(() => {
    setVisibility(true);
    setFooter(undefined);
    return () => setVisibility(false);
  }, [setFooter, setVisibility]);

  return <Component {...props} />;
};

const empty = () => {};

const DynamicHeaderContext = createContext<DynamicHeaderProps>({
  footer: '',
  visible: false,
  setFooter: empty,
  setBreadcrumbs: empty,
  appendBreadcrumb: empty,
  setVisibility: empty,
  clear: empty,
});

DynamicHeaderContext.displayName = 'DynamicHeaderContext';

export { DynamicHeaderContext };
