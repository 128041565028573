import React from 'react';
import { Form } from 'components/form';
import { Schedule } from 'pages/proposal';
import { WrapperCard } from 'components/elements';
import { useParams } from 'react-router-dom';
import { useTranslationX } from 'i18n';

import styles from './ScheduleList.module.scss';

const ScheduleList = () => {
  const { id: projectId } = useParams<{ id: string }>();

  const { tx } = useTranslationX('milestoneSchedule', 'project');

  return (
    <WrapperCard width="fit-content" padding="15px 20px 60px" minHeight="530px">
      <div className={styles.title}>
        <span>{tx('title')}</span>
        <strong>{tx('subtitle')}</strong>
      </div>

      <Form
        baseUrl={`/projeto/${projectId}/macroentregas/atividades`}
        populate={{}}
        editable={false}
      >
        <Schedule editable={false} />
      </Form>
    </WrapperCard>
  );
};

export default ScheduleList;
