import { CheckAlt } from 'components/icons';
import React, { ReactNode } from 'react';
import { classes } from 'utils/components';

import styles from './PaymentButton.module.scss';

type Props = {
  disabled?: boolean;
  onClick?: () => void;
  children?: ReactNode;
};

const PaymentButton: React.FC<Props> = ({ disabled, onClick, children }) => {
  return (
    <div
      className={classes(styles.button, disabled ? styles.disabled : '')}
      onClick={!disabled ? onClick : undefined}
    >
      <CheckAlt />
      <span>{children}</span>
    </div>
  );
};

export default PaymentButton;
