import React, { useCallback, useState } from 'react';
import { MdAdd as Add } from 'react-icons/md';
import { useTranslationX } from 'i18n';
import { Grid, Row, ScrollPanel } from 'components/containers';
import { FieldSet, HiddenField, TextField, useForm } from 'components/form';

import Deliverables from './Deliverables';

import styles from './Milestones.module.scss';

export type ProposalDeliverables = {
  id: number;
  titulo: string;
  ordem: number;
};

type ProposalMilestones = {
  id: number;
  titulo: string;
  duracao: number;
  ordem: number;
  entregaveis: ProposalDeliverables[];
};

const Milestones = ({ editable }: { editable: boolean }) => {
  const { tx } = useTranslationX('macroentregas', 'proposal');

  const form = useForm();
  const pathMilestone = 'macroentregas';
  const data: ProposalMilestones[] | undefined = form.getFieldValue('macroentregas');

  const [milestones, setMilestones] = useState<number>(data ? data.length : 0);

  const updateMilestone = useCallback(
    (milestone: ProposalMilestones) => {
      const path = `${pathMilestone}[${milestone.ordem}]`;

      form.setFieldValue(`${path}.id`, milestone.id, true);
      form.setFieldValue(`${path}.titulo`, milestone.titulo, true);
      form.setFieldValue(`${path}.duracao`, milestone.duracao, true);
      form.setFieldValue(`${path}.entregaveis`, milestone.entregaveis, true);
    },
    [form]
  );

  const handleAddMilestone = useCallback(() => {
    const payload = {
      titulo: `${tx('title')} ${milestones + 1}`,
      duracao: 1,
    };

    form.addListItem(
      pathMilestone,
      milestones,
      (success, res) => {
        if (success && res) {
          const { id, ordem } = res.response as { id: number; ordem: number };

          updateMilestone({ ...payload, id, ordem, entregaveis: [] });
          setMilestones(milestone => milestone + 1);
        }
      },
      payload
    );
  }, [tx, form, milestones, updateMilestone]);

  const handleDeleteMilestone = useCallback(
    (indexMilestone: number) => {
      form.setFieldValue(`${pathMilestone}[${indexMilestone}].entregaveis`, []);
      form.removeListItem(pathMilestone, indexMilestone, success => {
        if (success) {
          setMilestones(milestone => milestone - 1);
        }
      });
    },
    [form]
  );

  return (
    <ScrollPanel>
      <div className={styles.milestones}>
        {Array.from(new Array(milestones), (_, index) => (
          <FieldSet name="macroentregas" index={index} key={index}>
            <HiddenField name="id" />

            <Grid className={styles.item} spacing="20px 28px">
              <Row width={[0.8, 0.2]}>
                <TextField name="titulo" label={`${tx('title')} ${index + 1}`} />
                <TextField
                  name="duracao"
                  label={tx('duration')}
                  align="center"
                  adornment={{ right: tx('months') }}
                />
              </Row>

              <Deliverables
                count={
                  !!data && data[index].entregaveis !== undefined
                    ? data[index].entregaveis.length
                    : 0
                }
                pathDeliverables={`${pathMilestone}[${index}].entregaveis`}
                editable={editable}
              />
              <Row align="bottom">
                {editable && milestones > 3 ? (
                  <div
                    className={styles.deleteMilestone}
                    onClick={() => handleDeleteMilestone(index)}
                  >
                    {tx('delete-milestone')}
                  </div>
                ) : null}
              </Row>
            </Grid>
          </FieldSet>
        ))}
        {editable && milestones < 5 && (
          <div className={styles.newMilestone} onClick={() => handleAddMilestone()}>
            <Add size={20} />
            <span>{tx('new-milestone')}</span>
          </div>
        )}
      </div>
    </ScrollPanel>
  );
};

export default Milestones;
