import { DEFAULT_TIMEZONE } from 'consts';
import moment from 'moment';

export const getMonthYear = (startDate: Date | string, relativeMonth: number) => {
  const date = moment(startDate).endOf('day');
  date.add(relativeMonth - 1, 'months');
  date.utcOffset(DEFAULT_TIMEZONE);

  return {
    month: date.get('month'),
    year: date.get('year'),
  };
};

export const getDateWithOffset = (date: Date | string, offset: number) => {
  const newDate = moment(date).startOf('month');
  newDate.utcOffset(DEFAULT_TIMEZONE);
  newDate.add(offset, 'months');
  return newDate;
};

export const toSQLTimestamp = (date?: Date | string) => {
  // Set date to hours 00:00:00
  if (date) {
    return moment(date)
      .startOf('day')
      .utcOffset(DEFAULT_TIMEZONE)
      .format('YYYY-MM-DD HH:mm:ss');
  }
  return moment()
    .startOf('day')
    .utcOffset(DEFAULT_TIMEZONE)
    .format('YYYY-MM-DD HH:mm:ss');
};

export const toDate = (date?: Date | string) => {
  return moment(date).utcOffset(DEFAULT_TIMEZONE).format('YYYY-MM-DD');
};

export const toDateDDMMYYY = (date?: Date | string) => {
  return moment(date).format('DD/MM/YYYY');
};

export const getYearAndMonth = (date: number) => {
  return moment(date).format('MMM-YYYY');
};

export const extractYearAndMonth = (date: number) => {
  return `${date.toString().substring(0, 3)}-${date.toString().substring(3)}`;
};

export const getMonthName = (month: number) => {
  return moment.months(month);
};

export const getMonthNameShort = (month: number) => {
  return moment.monthsShort(month);
};

export const getYearShort = (year: number) => {
  return year.toString().substring(-2);
};

export const readDate = (date?: Date) => {
  const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSST';

  return moment(date, DATE_FORMAT).utcOffset(-3);
};
