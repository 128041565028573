import React from 'react';

const ThirdPartyServices = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.16101 4.46901C4.32108 3.30874 5.87701 2.6301 7.51661 2.56926C9.15621 2.50843 10.7582 3.0699 12.001 4.14101C13.2428 3.07168 14.8426 2.51084 16.4802 2.57077C18.1179 2.6307 19.6724 3.30698 20.8327 4.46422C21.993 5.62145 22.6733 7.17424 22.7375 8.8117C22.8017 10.4492 22.2451 12.0505 21.179 13.295L13.414 21.085C13.0573 21.4419 12.5788 21.6507 12.0745 21.6696C11.5703 21.6886 11.0775 21.5162 10.695 21.187L10.585 21.086L2.82101 13.295C1.7555 12.0515 1.19859 10.4517 1.26163 8.81539C1.32466 7.17907 2.003 5.62683 3.16101 4.46901ZM4.57501 5.88301C3.75725 6.701 3.28587 7.80245 3.25868 8.95878C3.23149 10.1151 3.6506 11.2375 4.42901 12.093L4.57501 12.247L12 19.672L17.303 14.368L13.768 10.833L12.708 11.893C12.4295 12.1717 12.0988 12.3928 11.7348 12.5436C11.3708 12.6945 10.9807 12.7722 10.5867 12.7723C9.791 12.7725 9.0278 12.4565 8.46501 11.894C7.90222 11.3315 7.58595 10.5684 7.58576 9.77272C7.58557 8.977 7.90149 8.2138 8.46401 7.65101L10.566 5.54801C9.72948 4.88039 8.68242 4.5327 7.6127 4.56734C6.54297 4.60198 5.5206 5.01667 4.72901 5.73701L4.57501 5.88301ZM13.061 8.71101C13.2485 8.52354 13.5028 8.41823 13.768 8.41823C14.0332 8.41823 14.2875 8.52354 14.475 8.71101L18.717 12.953L19.425 12.247C20.2565 11.4161 20.7298 10.2929 20.7438 9.11757C20.7579 7.94222 20.3114 6.80803 19.5 5.95757C18.6886 5.1071 17.5767 4.60788 16.402 4.56667C15.2272 4.52545 14.083 4.94551 13.214 5.73701L13.061 5.88301L9.87901 9.06501C9.70568 9.23823 9.60199 9.46905 9.58761 9.71368C9.57323 9.9583 9.64917 10.1997 9.80101 10.392L9.87901 10.479C10.0522 10.6523 10.2831 10.756 10.5277 10.7704C10.7723 10.7848 11.0137 10.7089 11.206 10.557L11.293 10.479L13.061 8.71101Z" fill="currentColor"/>
    <g mask="url(#mask0)">
      <rect width={`${size}em`} fill="currentColor"/>
    </g>
  </svg>

);

export default ThirdPartyServices;





