import React, { useCallback } from 'react';

import { readAttr } from 'utils/object';
import { useField } from '..';
import { isValidField } from '../helpers';
import { FieldMetadata } from '../types';
import { useValidation } from '../validate';

import InputControlAdapter from './InputControlAdapter/InputControlAdapter';

export type Props = {
  name: string;
  omit?: boolean;
  autosave?: boolean;
};

type OwnProps<T> = {
  dataType?: string;
  children: (props: T & ForwardProps) => JSX.Element;
};

type ForwardProps = {
  value: any;
  lastValue: any;
  metadata: FieldMetadata;
  onChange: (value: any) => void;
  validationToken: any;
};

const FieldAdapter = function <T>(props: T & Props & OwnProps<T>) {
  const { name: n, children, omit, autosave, dataType, ...rest } = props;

  const {
    name,
    type,
    value,
    lastValue,
    form,
    section,
    metadata,
    setMetadata,
    refreshType,
    validationToken,
    refreshValidation,
  } = useField({ name: n, ...rest });

  const { validate } = useValidation();

  const rules = readAttr('rules', metadata);

  const hasDiff = !!lastValue && lastValue !== value;

  const onChange = useCallback(
    (value: unknown) => {
      const issue = validate(value, rules);

      form.updateFieldData(name, {
        value,
        valid: isValidField(issue),
        issue,
        section,
        omit,
        dataType,
        autosave,
        setMetadata,
        refreshType,
        refreshValidation,
        disabled: type === 'disabled',
      });
    },
    [
      validate,
      rules,
      form,
      name,
      type,
      section,
      omit,
      dataType,
      autosave,
      setMetadata,
      refreshType,
      refreshValidation,
    ]
  );

  return (
    <>
      <InputControlAdapter field={name} />
      {children({
        ...(rest as unknown as T),
        value,
        form,
        lastValue,
        metadata,
        onChange,
        validationToken,
        hasDiff,
        name,
        type,
      })}
    </>
  );
};

export default FieldAdapter;
