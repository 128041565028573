import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import _ from 'lodash';
import HeaderCell from 'components/containers/TableList/HeaderCell';
import { DispatchFilter, StateFilter } from 'components/containers/TableList/types';

import { bindTableFixedBehavior, classes } from 'utils/components';
import { FaArrowLeft, FaArrowRight, FaRegIdCard } from 'react-icons/fa';

import {
  DispatchButton,
  CounterpartConclusion,
  CounterpartConclusions,
  StateButton,
} from '../types';
import { IconButton, Loader, NotFoundMessage } from 'components/elements';
import { useTranslationX } from 'i18n';
import { capitalize } from 'utils/stringUtils';

import { ScrollPanel } from 'components/containers';
import { useFormObserver } from 'components/form';
import { useFetch } from 'apis';
import moment from 'moment';

import ConclusionNode from './ConclusionNode/ConclusionNode';

import styles from './ConclusionPlanner.module.scss';
import { DEFAULT_TIMEZONE } from 'consts';

export type Instance = {
  reload: () => void;
};

type Props = {
  dispatchFilter?: DispatchFilter;
  stateFilter?: StateFilter;
  dispatchButton: DispatchButton;
  stateButton: StateButton;
  reference?: React.MutableRefObject<Instance | undefined>;
};

const ConclusionPlanner: React.FC<Props> = ({
  dispatchFilter,
  stateFilter,
  dispatchButton,
  stateButton,
  reference,
}) => {
  const [offset, setOffset] = useState(0);
  const [headerH, setHeaderH] = useState(0);

  const { id: projectId } = useParams<{ id: string }>();
  const { data, reload } = useFetch<CounterpartConclusions>(
    `/projeto/${projectId}/contrapartidas/meses`
  );

  const { tx } = useTranslationX('paymentPanel', 'project');
  const observableRange = useFormObserver('range', true);
  const range = observableRange?.data?.value || 12;

  const instance = useRef<Instance>({ reload });

  if (reference) {
    reference.current = instance.current;
  }

  if (!data) {
    return <Loader type="spin" />;
  }
  const totalMonths = Math.min(range, data.duracao);

  const renderHeaderMonthNode = (relativeMonth: number) => {
    return (
      <div>
        <span>
          {tx('month', {
            month: relativeMonth,
          })}
        </span>
        <span>{`${data.meses ? data.meses[relativeMonth - 1] : '...'}`}</span>
      </div>
    );
  };

  if (data.contratos?.length === 0) {
    return (
      <NotFoundMessage
        title={tx('notFound.teamTitle')}
        description={tx('notFound.description')}
        icon={FaRegIdCard}
      />
    );
  }
  return (
    <div className={classes(styles.conclusionPlanner)}>
      <ScrollPanel
        vBar={{ start: headerH, overlay: true }}
        style={{
          root: {
            maxHeight: '50vh',
          },
        }}
      >
        <table>
          <thead ref={ref => (ref ? setHeaderH(ref.offsetHeight) : null)}>
            <tr>
              <HeaderCell
                level={0}
                dispatchFilter={dispatchFilter}
                stateFilter={stateFilter}
                config={{
                  title: tx('table.name'),
                  field: 'name',
                  colspan: 1,
                  rowspan: 1,
                  align: 'left',
                  sortDir: 'asc',
                  sort: true,
                  search: true,
                }}
              />
              <td ref={bindTableFixedBehavior}>
                <IconButton
                  rate={0.4}
                  icon={FaArrowLeft}
                  shape="circle"
                  onClick={() => setOffset(offset => (offset - 1 < 0 ? 0 : offset - 1))}
                  type={offset === 0 ? 'disabled' : 'dark'}
                />
              </td>
              {Array.from(new Array(totalMonths), (_el, index) => (
                <th ref={bindTableFixedBehavior} key={index}>
                  {renderHeaderMonthNode(index + offset + 1)}
                </th>
              ))}
              <td ref={bindTableFixedBehavior}>
                <IconButton
                  rate={0.4}
                  icon={FaArrowRight}
                  shape="circle"
                  type={offset + totalMonths < data.duracao ? 'dark' : 'disabled'}
                  onClick={() =>
                    setOffset(offset =>
                      offset + totalMonths < data.duracao ? offset + 1 : offset
                    )
                  }
                />
              </td>
            </tr>
          </thead>
          <tbody>
            {data.contratos
              .filter(c => {
                if (!stateFilter?.filters?.name) return true;
                return (
                  c.nome
                    .toLowerCase()
                    .trim()
                    .indexOf(
                      (stateFilter?.filters?.name as string)?.toLowerCase().trim()
                    ) !== -1
                );
              })
              .map(macroentrega => (
                <Row
                  key={macroentrega.id}
                  milestoneConclusion={macroentrega}
                  maxMonth={totalMonths}
                  offset={offset}
                  state={stateButton}
                  dispatch={dispatchButton}
                  inicioRelativo={data.inicioRelativo}
                />
              ))}
          </tbody>
        </table>
      </ScrollPanel>
    </div>
  );
};

type RowProps = {
  milestoneConclusion: CounterpartConclusion;
  maxMonth?: number;
  offset: number;
  state: StateButton;
  dispatch: DispatchButton;
  inicioRelativo?: Date;
};

const Row: React.FC<RowProps> = ({
  milestoneConclusion,
  maxMonth,
  offset,
  state,
  dispatch,
  inicioRelativo,
}) => {
  const { nome, pagamentos } = milestoneConclusion;

  const getConclusionByMonth = (mes: number) => {
    return _.find(pagamentos, { mes });
  };

  const renderConclusionNode = (month: number) => {
    const conclusion = getConclusionByMonth(month);
    const relativeMonth = month + offset;

    if (!conclusion) {
      return null;
    }

    const conclusionDate = moment(inicioRelativo)
      .endOf('day')
      .add(relativeMonth - 1, 'months')
      .utcOffset(DEFAULT_TIMEZONE)
      .toDate();

    return (
      <ConclusionNode
        conclusion={conclusion}
        conclusionDate={conclusionDate}
        dispatch={dispatch}
        state={state}
      />
    );
  };

  return (
    <tr>
      <td
        style={{
          height: '60px',
        }}
      >
        <div className={styles.container}>
          <strong>{capitalize(nome)}</strong>
        </div>
      </td>
      <td />
      {Array.from(new Array(maxMonth), (_el, index) => (
        <td
          key={index}
          style={{
            fontSize: '10px',
          }}
        >
          {renderConclusionNode(index + offset + 1)}
        </td>
      ))}
      <td />
    </tr>
  );
};

export default ConclusionPlanner;
