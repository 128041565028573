import React, { useMemo } from 'react';
import { NotificationMessage } from 'components/elements';
import { useMessageState } from 'store/message';

import { classes } from 'utils/components';

import styles from './MessagesProvider.module.scss';

const MessagesProvider: React.FC = () => {
  const state = useMessageState();
  const { messages } = state;

  const bottomMessages = useMemo(() => {
    return messages.filter(message => message.position === 'bottom');
  }, [messages]);

  const topMessages = useMemo(() => {
    return messages.filter(message => message.position === 'top');
  }, [messages]);

  const hiddenMessages = useMemo(() => {
    return messages.filter(message => message.hidden);
  }, [messages]);

  return (
    <>
      <div
        className={classes(styles.messages, styles.top)}
        style={{
          transform: `translateY(-${hiddenMessages.length * 45}px)`,
        }}
      >
        {topMessages.map(message => (
          <NotificationMessage key={message.id} message={message} />
        ))}
      </div>
      <div className={classes(styles.messages, styles.bottom)}>
        {bottomMessages.map(message => (
          <NotificationMessage key={message.id} message={message} />
        ))}
      </div>
    </>
  );
};

export default MessagesProvider;
