import React from 'react';

const Dummy = (props: any) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Componente ainda não implementado!
      {Object.keys(props).length ? <pre>{JSON.stringify(props, null, 2)}</pre> : null}
    </div>
  );
};

export default Dummy;
