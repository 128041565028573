import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'i18n';

import { Button } from 'components/elements';
import { FormSection, SubmitButton, useForm, useFormObserver } from 'components/form';
import { renderComponent } from 'utils/components';
import { getSections } from '../profile';
import { SectionProps } from '../types';

import styles from './Section.module.scss';

const Section = ({ state, dispatch }: SectionProps) => {
  const { profile, step, sections, direction } = state;
  const { name, component, fillPage } = getSections(profile)[step];
  const { t } = useTranslation('register');

  const result = useFormObserver({ section: name, prop: 'valid' }, true);
  const form = useForm();
  const history = useHistory();

  const valid = result?.valid;
  const locked = sections[step].locked;

  useEffect(() => {
    if (valid === false) {
      dispatch({ type: 'INVALIDATE_SECTION', payload: step });
    }
  }, [dispatch, step, valid]);

  useEffect(() => {
    if (locked) {
      form.clearSection(name);
      dispatch({ type: direction === 'next' ? 'NEXT_SECTION' : 'PREVIOUS_SECTION' });
    }
  }, [direction, dispatch, form, locked, name]);

  if (locked) {
    return null;
  }

  return (
    <div className={styles.section}>
      <div className={styles.header}>
        <div>
          {t('section.step', {
            profile: t(`profile.${profile}`),
            step: step + 1,
            total: sections.length,
          })}
        </div>
        <div>{t(`${name}.title`)}</div>
      </div>

      <FormSection name={name}>
        <div className={styles.content} style={fillPage ? { flex: 1 } : undefined}>
          {renderComponent(component, { state, dispatch })}
        </div>
      </FormSection>

      <div
        className={styles.footer}
        style={step ? undefined : { justifyContent: 'center' }}
      >
        {step ? (
          <Button type="secondary" onClick={() => dispatch({ type: 'PREVIOUS_SECTION' })}>
            {t('section.previous')}
          </Button>
        ) : null}

        {step < sections.length - 1 ? (
          <div className={styles.footerButtons}>
            <Button
              type={valid ? 'primary' : 'disabled'}
              onClick={() => dispatch({ type: 'NEXT_SECTION' })}
            >
              {t('section.next')}
            </Button>
            <span onClick={() => history.push('/login', { getCredentials: true })}>
              {t('button.cancel')}
            </span>
          </div>
        ) : (
          <SubmitButton type={valid ? 'primary' : 'disabled'}>
            {t('section.finish')}
          </SubmitButton>
        )}
      </div>
    </div>
  );
};

export default Section;
