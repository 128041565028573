import React, { useEffect, useRef, useState } from 'react';

import { useTranslationX } from 'i18n';
import { IconButton, Spacer } from 'components/elements';
import { PlusCircle, Triangle } from 'components/icons';
import { FieldSet, HiddenField, useDiff, useForm } from 'components/form';
import { Collapse } from 'components/containers';
import { classes } from 'utils/components';
import { STEP_W, MARGIN, INNER_MARGIN } from './consts';
import {
  Action,
  Activity as ActivityType,
  Activity as ActivityData,
  Transit,
} from './types';
import Activity from './Activity';

import styles from './Milestone.module.scss';

type Props = {
  index: number;
  size: number;
  offset: number;
  dispatch: (action: Action) => void;
  active: boolean;
  transit?: Transit;
  editable: boolean;
};

const Milestone = (props: Props) => {
  const [expanded, setExpanded] = useState(false);

  const { index, size, offset, active, dispatch, transit, editable } = props;
  const { tx } = useTranslationX('schedule', 'proposal');

  const form = useForm();

  const path = `macroentregas[${index}].atividades`;
  const value: ActivityData[] | undefined = form.getFieldValue(path);

  const milestoneFieldValue = form.getFieldValue(`macroentregas[${index}]`);

  const { diff } = useDiff(path);

  const removedItems = diff.filter(item => item.type === 'removed');

  const milestoneTitle =
    milestoneFieldValue !== undefined && milestoneFieldValue['titulo'] !== undefined
      ? milestoneFieldValue['titulo']
      : tx('milestone.label', { number: index + 1 });

  const firstRender = useRef<boolean>();
  firstRender.current = firstRender.current === undefined;

  useEffect(() => {
    if (!firstRender.current) {
      setExpanded(!!value?.length);
    }
  }, [value?.length]);

  const allValues = [...(value ?? []), removedItems.map(removeItem => removeItem.data)];

  return (
    <div className={classes(styles.milestone, active && styles.active)}>
      <div
        className={classes(styles.header, allValues?.length && styles.interactive)}
        onClick={() => setExpanded(val => !val)}
      >
        <div className={classes(styles.arrow, expanded && styles.visible)}>
          <Triangle />
        </div>
        <span>{milestoneTitle}</span>

        {editable ? (
          <div>
            <IconButton
              icon={PlusCircle}
              shape="circle"
              type="dark"
              size={32}
              rate={0.5}
              hint={tx('milestone.addActivity')}
              onClick={() =>
                dispatch({
                  type: 'SET_MODAL',
                  payload: { mode: 'add', milestone: index },
                })
              }
            />
          </div>
        ) : (
          <Spacer length={32} />
        )}

        <FieldSet name={`macroentregas[${index}]`}>
          <HiddenField name="id" />
          <HiddenField name="titulo" />
          <HiddenField name="duracao" value={size} />
        </FieldSet>
      </div>

      <div className={styles.body}>
        <div
          className={styles.container}
          style={{
            width: size * STEP_W - INNER_MARGIN * 2,
            marginLeft: offset * STEP_W + MARGIN + INNER_MARGIN,
          }}
        >
          {allValues?.length && (
            <Collapse visible={expanded} key={allValues.length}>
              <div>
                {value?.map((el, i) => {
                  const diffItem = diff.find(item => {
                    if (item.type === 'modified') {
                      return item.reference === el.referencia;
                    }

                    return item?.data?.id === el.id;
                  });

                  return (
                    <Activity
                      key={el.id}
                      active={active}
                      dispatch={dispatch}
                      name={`${path}[${i}]`}
                      maxSize={size}
                      transit={el.id === transit?.data.id ? transit : undefined}
                      editable={editable}
                      onEdit={() =>
                        dispatch({
                          type: 'SET_MODAL',
                          payload: { mode: 'edit', milestone: index, activity: i },
                        })
                      }
                      diffType={diffItem?.type}
                    />
                  );
                })}
                {removedItems?.map((el, i) => {
                  return (
                    <Activity
                      key={el.data.id}
                      active={false}
                      dispatch={dispatch}
                      name={`r-${path}[${i}]`}
                      populatedValues={el.data as ActivityType}
                      maxSize={size}
                      editable={false}
                      diffType={el.type}
                    />
                  );
                })}
              </div>
            </Collapse>
          )}
        </div>
      </div>
    </div>
  );
};

export default Milestone;
