import React from 'react';

import Field, { Props as FieldProps } from '../Field/Field';
import Input, { Props as InputProps } from 'components/inputs/TextArea/TextArea';

const TextArea: React.FC<FieldProps & InputProps> = props => (
  <Field {...props} component={Input} />
);

TextArea.displayName = 'TextAreaField';
export default TextArea;
