import React, { useCallback, useMemo, useReducer, useRef } from 'react';
import _ from 'lodash';
import { useTranslationX } from 'i18n';
import { useFetch } from 'apis';
import { ScrollPanel } from 'components/containers';
import { ErrorMessage, Loader } from 'components/elements';
import { ReadOnlyInfoDialog } from 'components/form';
import { Props as FormProps } from 'components/form/Form';
import { FormInstance } from 'components/form/types';
import { ContentSwitcher, TopSubMenu } from 'pages/dashboard';
import { TopMenuItemIcon } from 'pages/dashboard/types';
import { ProposalStatus } from '../types';
import { Profile } from 'pages/register/profile';
import { Dispatch, EditorAction, EditorState, ProposalCorrections } from './types';
import SectionHeader from './SectionHeader';
import ProposalValidator from './ProposalValidator';

import {
  BudgetDescription,
  SummaryAndActions,
  Corrections,
  Counterpart,
  Description,
  Eap,
  Financial,
  FormDescription,
  Identification,
  MilestoneSection,
} from '..';
import * as icons from 'components/icons';
import { withDynamicHeader } from 'components/containers/DynamicHeader';
import { classes } from 'utils/components';

import styles from './ProposalEditor.module.scss';

const allSections: TopMenuItemIcon[] = [
  { key: 'observacoes', component: Corrections, icon: icons.Chat },
  { key: 'identificacao', component: Identification, icon: icons.Chart },
  { key: 'formulario', component: FormDescription, icon: icons.NumberedList },
  { key: 'descricao', component: Description, icon: icons.ThirdPartyServices },
  { key: 'eap', component: Eap, icon: icons.Travel },
  { key: 'metas-macro', component: MilestoneSection, icon: icons.AirportShuttle },
  { key: 'orcamento', component: Financial, icon: icons.TableArrow },
  { key: 'contrapartida', component: Counterpart, icon: icons.Expenditure },
  { key: 'resumo', component: SummaryAndActions, icon: icons.Percentage },
  { key: 'divisao-orcamento', component: BudgetDescription, icon: icons.TableCheck },
];

type BackwardProps = {
  id: number;
  estado: ProposalStatus;
  sigla: string;
  titulo: string;
  simulacao: boolean;
  profile: Profile;
};

export type SectionProps = BackwardProps & {
  formProps: FormProps;
  state: EditorState;
  dispatch: Dispatch;
};

const reducer = (state: EditorState, action: EditorAction): EditorState => {
  switch (action.type) {
    case 'SET_SECTION': {
      const section = action.payload;
      const forms: { [key: string]: FormInstance } = {};
      if (section) {
        for (const key in state.forms) {
          if (key.startsWith(section)) {
            forms[key] = state.forms[key];
          }
        }
      }

      return { ...state, section, forms };
    }

    case 'FORM_UPDATE': {
      const { editable, name, form } = action.payload;
      return { ...state, editable, forms: { ...state.forms, [name]: form } };
    }

    case 'SET_ERRORS': {
      return { ...state, errors: action.payload };
    }

    case 'SHOW_ERRORS': {
      return { ...state, showErrors: action.payload };
    }

    case 'HAS_ERRORS': {
      return { ...state, hasErrors: action.payload };
    }
    case 'SHOW_READONLY': {
      return { ...state, showReadOnly: action.payload };
    }
    case 'SET_TAB_ERRORS': {
      return { ...state, tabErrors: action.payload };
    }
    case 'LOCK_NAVIGATION': {
      return { ...state, lockNavigation: action.payload };
    }

    default:
      return state;
  }
};

const ProposalEditor = (props: BackwardProps) => {
  const [state, dispatch] = useReducer(reducer, { ...props, editable: false, forms: {} });
  const { data: corrections } = useFetch<ProposalCorrections>(
    `/proposta/${state.id}/corrections`
  );
  const { t, tx } = useTranslationX('editor', 'proposal');

  const onPopulate = useCallback((values: { [key: string]: any }, form: FormInstance) => {
    if (form.name) {
      const editable = !!values['editavel'];
      form.context.setEditable(editable);
      dispatch({ type: 'FORM_UPDATE', payload: { editable, name: form.name, form } });
    }
  }, []);

  const populate = useRef({});

  const sections = useMemo(() => {
    return _.filter(allSections, section => {
      if (
        section.key === 'observacoes' &&
        corrections &&
        corrections.devolucoes.length === 0
      ) {
        return false;
      }
      return !(
        props.profile === Profile.RESEARCHER && section.key === 'divisao-orcamento'
      );
    });
  }, [corrections, props.profile]);

  if (!sections) {
    return <ErrorMessage />;
  }

  if (!corrections) {
    return <Loader />;
  }

  const onFormClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const eventTarget = event.target;
    const matchFieldsPrefix = ['check', 'textinput', 'textarea'];

    if (eventTarget instanceof Element) {
      const target = eventTarget as Element;
      let find = Object.values(target.querySelectorAll('div'))
        .splice(0, 3)
        .some(element => {
          return matchFieldsPrefix.some(fieldName =>
            element.className.toLowerCase().startsWith(fieldName.toLowerCase())
          );
        });

      if (!find && target.tagName === 'SPAN') {
        if (!!target.getAttribute('data-text')) {
          find = true;
        }
      }

      const isEmpty = Object.keys(state.forms).length === 0;

      if (!state.editable && !isEmpty && find) {
        dispatch({ type: 'SHOW_READONLY', payload: true });
      }
    }
  };

  const createAdornment = (section: string) => {
    if (state.showErrors && state.errors) {
      const { errors, warnings } = state.errors[section] || {};

      if (errors || warnings) {
        return (
          <div className={styles.errorAdornment}>
            {!!errors && <span className={styles.checkErrorUp}>{errors}</span>}
            {!!warnings && <span className={styles.checkErrorDown}>{warnings}</span>}
          </div>
        );
      }
    }

    return undefined;
  };

  const forwardProps: SectionProps = {
    ...props,
    formProps: {
      populate: populate.current,
      autosave: true,
      onPopulate,
      display: { error: state.showErrors ? 'always' : 'touched' },
    },
    state,
    dispatch,
  };

  const getForms = (match: string) => {
    return Object.values(state.forms).filter(form => form.name?.startsWith(match));
  };

  return (
    <div className={styles.proposalEditor}>
      <TopSubMenu
        blocked={state.lockNavigation}
        child={sections.map(({ key, path, icon }) => ({
          label: tx(`sections.${key}`),
          icon: icon,
          path: path || key,
          adornment: createAdornment(key),
        }))}
      />
      <SectionHeader
        title={props.titulo}
        acronym={props.sigla}
        form={state.section ? state.forms[state.section] : null}
        section={state.section}
      />
      <ScrollPanel>
        <div className={styles.content} onClick={onFormClick}>
          <ProposalValidator
            state={state}
            dispatch={dispatch}
            instances={state.section ? getForms(state.section) : []}
          />
          <ReadOnlyInfoDialog
            visible={!!state.showReadOnly}
            onClose={() => dispatch({ type: 'SHOW_READONLY', payload: false })}
            message={t('actions.error-blocked-message') || ''}
          />
          <div
            className={classes(
              styles.body,
              state.section === 'divisao-orcamento' ? styles.filledWidthBody : ''
            )}
          >
            <ScrollPanel
              vBar={{ overlay: true, placeholder: true }}
              style={{ root: { maxWidth: '95%' } }}
            >
              <ContentSwitcher
                items={sections}
                forwardProps={({ key }) => ({
                  ...forwardProps,
                  formProps: { ...forwardProps.formProps, name: key },
                  state,
                  dispatch,
                })}
                onChange={item => {
                  dispatch({ type: 'SET_SECTION', payload: item?.key });
                }}
              />
            </ScrollPanel>
          </div>
        </div>
      </ScrollPanel>
    </div>
  );
};

export default withDynamicHeader(ProposalEditor);
