import React, { forwardRef } from 'react';
import Tippy from '@tippyjs/react';

import {
  BsFillExclamationCircleFill as ErrorIcon,
  BsFillExclamationTriangleFill as WarningIcon,
  BsFillInfoCircleFill as InfoIcon,
} from 'react-icons/bs';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/svg-arrow.css';
import 'tippy.js/animations/perspective.css';

import { classes } from 'utils/components';
import styles from './FloatMessage.module.scss';

type Props = {
  text: string;
  type?: 'error' | 'warning' | 'info';
  position?:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'left'
    | 'left-start'
    | 'left-end';
};

const FloatMessage = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { text, type = 'error', position = 'top-start' } = props;

  if (!text || !text.trim()) {
    return null;
  }

  return (
    <Tippy
      className={classes(styles.popup, styles[type])}
      content={text}
      placement={position}
      animation="perspective"
      offset={[0, 5]}
      delay={[100, 0]}
      hideOnClick={false}
    >
      <div className={classes(styles.floatMessage, styles[type])} tabIndex={0} ref={ref}>
        <div tabIndex={-1}>
          {type === 'error' ? (
            <ErrorIcon />
          ) : type === 'warning' ? (
            <WarningIcon />
          ) : (
            <InfoIcon />
          )}
        </div>
      </div>
    </Tippy>
  );
});

FloatMessage.displayName = 'FloatMessage';
export default FloatMessage;
