import React from 'react';

import Field, { Props as FieldProps } from '../Field/Field';
import Input, { Props as InputProps } from 'components/inputs/Select/Select';

const Select: React.FC<FieldProps & InputProps> = props => (
  <Field {...props} component={Input} />
);

Select.displayName = 'SelectField';
export default Select;
