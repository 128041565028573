import React from 'react';

import { useTranslation } from 'i18n';
import { Grid, Row } from 'components/containers';

import {
  TextField,
  Select,
  MultiSelect,
  Autocomplete,
  HiddenField,
  FieldSet,
} from 'components/form';

const Student = ({ index }: { index: number }) => {
  const { t } = useTranslation('register');

  return (
    <Grid>
      <FieldSet name="aluno" index={index}>
        <HiddenField name="id" />

        <Autocomplete
          url="/cadastro/instituicoes"
          name="instituicao"
          label={t('academic.institution')}
        />

        <Row width={[7, 3]}>
          <TextField name="curso" label={t('student.program')} />
          <Select name="nivelCurso" label={t('student.level')} />
        </Row>

        <Row width={[7, 3]}>
          <TextField name="lattes" label={t('academic.resume')} />
          <TextField name="matricula" label={t('student.id')} />
        </Row>

        <MultiSelect name="areaPesquisa" label={t('academic.areas')} />
      </FieldSet>
    </Grid>
  );
};

export default Student;
