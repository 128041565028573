import React from 'react';

import { useTranslation, useTranslationX } from 'i18n';
import { Grid, Row } from 'components/containers';
import { FileList, PlainField } from 'components/elements';
import Person, { Data as PersonData } from './Person';

type Data = PersonData & {
  matricula: string;
  curso: string;
  nivelCurso: string;
  instituicao: string;

  lattes: string;
  areas: string;
  arquivos: { nome: string; id: number }[];
};

const Student = ({ data }: { data: Data }) => {
  const { tx } = useTranslationX('homologacao', 'supervisor');
  const { t } = useTranslation('register');

  const { arquivos, curso, nivelCurso, matricula, lattes, instituicao, areas } = data;

  return (
    <div>
      <FileList label={tx('docs')} values={arquivos} />
      <Person data={data} />

      <Grid spacing={0}>
        <Row width={[27, 73]} align="top">
          <PlainField label={t('student.program')} value={curso} />
          <PlainField label={t('student.level')} value={nivelCurso} />
        </Row>

        <Row width={[27, 73]} align="top">
          <PlainField label={t('student.id')} value={matricula} />
          <PlainField label={t('academic.resume')} value={lattes} type="url" />
        </Row>

        <PlainField label={t('academic.institution')} value={instituicao} />
        <PlainField label={t('academic.areas')} value={areas} />
      </Grid>
    </div>
  );
};

export default Student;
