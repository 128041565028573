import React from 'react';

const Pencil = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M12.6064 1.14879e-05C12.2918 -0.000965573 11.9801 0.0603918 11.6894 0.18054C11.3987 0.300687 11.1347 0.477244 10.9126 0.700012L10.2876 1.32501L13.6939 4.68752L14.3189 4.06252C14.6467 3.72536 14.868 3.2991 14.955 2.83697C15.0421 2.37483 14.9911 1.89728 14.8084 1.46394C14.6258 1.0306 14.3195 0.660661 13.9279 0.400312C13.5363 0.139963 13.0766 0.000736122 12.6064 1.14879e-05Z"
    />
    <path
      fill="currentColor"
      d="M0.625011 15H3.12501C3.20727 15.0005 3.28881 14.9847 3.36495 14.9536C3.4411 14.9225 3.51036 14.8767 3.56876 14.8188L12.8125 5.56875L9.43127 2.1875L0.181261 11.4313C0.123335 11.4897 0.0775064 11.5589 0.0464035 11.6351C0.0153006 11.7112 -0.000465112 11.7928 1.04464e-05 11.875V14.375C1.04464e-05 14.5408 0.0658585 14.6997 0.183069 14.817C0.300279 14.9342 0.459251 15 0.625011 15Z"
    />
  </svg>
);

export default Pencil;
