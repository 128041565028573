import React, { useEffect, useReducer } from 'react';
import { Switch, Route, useRouteMatch, Prompt } from 'react-router-dom';

import { useTranslationX } from 'i18n';
import { Form } from 'components/form';
import { Success } from 'components/icons';
import { Profile, getPath } from '../profile';
import { RegisterState } from '../types';
import ProfileSelector from '../ProfileSelector/ProfileSelector';
import Stepper from '../Stepper/Stepper';
import Section from '../Section/Section';
import reducer, { initialState } from '../reducer';

import styles from './Register.module.scss';

const Register = (props: { profile?: Profile }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { tx } = useTranslationX('alert', 'register');

  const section = state.sections[state.step];

  useEffect(() => {
    dispatch({ type: 'INIT', payload: props.profile });
    return () => dispatch({ type: 'INIT' });
  }, [dispatch, props.profile]);

  function renderContent() {
    switch (state.status) {
      case 'select':
        return <ProfileSelector dispatch={dispatch} />;

      case 'active':
        return (
          <Form
            baseUrl={`/cadastro/${getPath(state.profile!)}`}
            submit={{ format: 'multipart' }}
            onDone={() => dispatch({ type: 'NEXT_SECTION' })}
          >
            <Prompt message={tx('prompt')} />
            <Section
              key={section.name}
              state={state as Required<RegisterState>}
              dispatch={dispatch}
            />
          </Form>
        );

      case 'done':
        return (
          <div className={styles.success}>
            <Success size={10} />
            <span>{tx('done.title')}</span>
            <span>{tx('done.message')}</span>
          </div>
        );

      case 'error':
        return null;
    }
  }

  return (
    <div className={styles.register}>
      <Stepper state={state as Required<RegisterState>} />
      <div className={styles.content}>{renderContent()}</div>
    </div>
  );
};

const RegisterRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} exact>
        <Register />
      </Route>
      <Route path={`${path}/pesquisador`}>
        <Register profile={Profile.RESEARCHER} />
      </Route>
    </Switch>
  );
};

export default RegisterRouter;
