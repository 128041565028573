import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';

import { useTranslationX } from 'i18n';
import { useRequest } from 'apis';
import { SubmitButton, Form, HiddenField } from 'components/form';
import { Button, Loader, ErrorMessage } from 'components/elements';
import { Dialog } from 'components/containers';
import { Action } from './TableList';
import { classes } from 'utils/components';
import { UI_BUTTON_SPACING } from 'consts';

import styles from './Edit.module.scss';

type Props = {
  data?: Data;
  baseUrl: string;
  validateUrl?: string | ((entry: any) => string);
  dispatch: (action: Action, payload?: any) => void;
  onClose: () => void;
  dialog?: boolean;
  title?: string | JSX.Element;
  deleteButton?: boolean;
  populate?: boolean;
  width?: number;
  style?: CSSProperties;
  autoDisable?: boolean;
  children?: ReactNode;
};

type Status = 'init' | 'loading' | 'closing' | 'error';
type Data = {
  id?: number | string;
  vinculos?: number;
};

const Edit: React.FC<Props> = props => {
  const { data, baseUrl, validateUrl, dispatch, onClose, dialog } = props;
  const { title, deleteButton, populate, width = 800, style, children } = props;

  const [status, setStatus] = useState<Status>('init');
  const request = useRequest();
  const { tx } = useTranslationX('components.tableList');

  const editMode = !!data?.id;

  useEffect(() => {
    if (data) {
      setStatus('init');
    }
  }, [data]);

  const close = () => {
    setStatus('closing');

    if (!dialog) {
      onClose();
    }
  };

  const remove = () => {
    if (data?.id) {
      setStatus('loading');

      request({
        url: `${baseUrl}/${data.id}`,
        method: 'DELETE',
        onSuccess: () => {
          dispatch('remove');
          close();
        },
        onError: () => setStatus('error'),
      });
    }
  };

  const content = () => (
    <div
      className={classes(styles.edit, dialog ? styles.dialog : '')}
      style={{ ...style, visibility: status === 'init' ? 'visible' : 'hidden' }}
    >
      <Form
        baseUrl={baseUrl}
        validateUrl={typeof validateUrl === 'function' ? validateUrl(data) : validateUrl}
        populate={editMode && populate ? data?.id : undefined}
        onDone={data => {
          dispatch('save', data);
          close();
        }}
      >
        {editMode && <HiddenField name="id" value={data?.id} />}

        {children}

        <div className={styles.buttons}>
          {editMode && deleteButton && !data?.vinculos ? (
            <Button type="danger" onClick={remove} style={{ root: { width: 110 } }}>
              {tx('remove')}
            </Button>
          ) : (
            <div></div>
          )}

          <div style={{ alignSelf: 'flex-end' }}>
            <Button
              type="secondary"
              onClick={close}
              style={{ root: { width: 110, marginRight: UI_BUTTON_SPACING } }}
            >
              {tx('cancel')}
            </Button>

            <SubmitButton
              style={{ root: { width: 110 } }}
              autoDisable={props.autoDisable}
            >
              {tx(editMode ? 'edit' : 'create')}
            </SubmitButton>
          </div>
        </div>
      </Form>

      {status !== 'init' ? (
        <div className={styles.status}>
          {status === 'loading' ? (
            <Loader type="spin" />
          ) : status === 'error' ? (
            <ErrorMessage />
          ) : null}
        </div>
      ) : null}
    </div>
  );

  return dialog ? (
    <Dialog
      title={
        typeof title === 'string'
          ? `${tx(editMode ? 'editTitle' : 'createTitle')} ${title}`
          : title
      }
      visible={!!data && status !== 'closing'}
      onClose={onClose}
      width={width}
      closeButton
      volatile
    >
      {content()}
    </Dialog>
  ) : (
    content()
  );
};

export default Edit;
