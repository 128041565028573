import React from 'react';

const Italic = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none">
    <path
      d="m9.14 4.14c0 1.19 0.957 2.14 2.14 2.14h1.01l-4.89 11.4h-1.84c-1.19 0-2.14 0.957-2.14 2.14 0 1.19 0.957 2.14 2.14 2.14h7.14c1.19 0 2.14-0.957 2.14-2.14 0-1.19-0.957-2.14-2.14-2.14h-1.01l4.89-11.4h1.84c1.19 0 2.14-0.957 2.14-2.14 0-1.19-0.957-2.14-2.14-2.14h-7.14c-1.19 0-2.14 0.957-2.14 2.14z"
      fill="currentColor"
    />
  </svg>
);

export default Italic;
