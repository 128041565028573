import React, { useState } from 'react';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';

import { useTranslationX } from 'i18n';
import { FieldSet, HiddenField, useForm, useFormObserver } from 'components/form';
import { StateCheck } from 'components/elements';
import { Part, UC } from './types';
import { getMasked } from 'utils/inputMask';
import { appendDots } from 'utils/stringUtils';

import styles from './BudgetTable.module.scss';

type Props = {
  groupName: string;
};

const BudgetTable = ({ groupName }: Props) => {
  const { tx } = useTranslationX('proposal:budget-description', [
    'translation',
    'proposal',
  ]);

  const form = useForm();
  const partes = form.getFieldValue<Part[]>('partes') || [];
  const data = form.getFieldValue<UC[]>(groupName) || [];
  const editable = form.editable;

  if (!partes.length || !data.length) {
    return null;
  }

  return (
    <div className={styles.budget}>
      <span>{tx(`groups.${groupName}`)}</span>

      <table>
        <thead>
          <tr>
            <th></th>
            <th></th>
            {partes.map(({ id, nome }) => (
              <Tippy
                content={nome}
                animation="perspective"
                touch={false}
                followCursor={'horizontal'}
                plugins={[followCursor]}
                className={styles.popup}
              >
                <th key={id} style={{ width: `${10 / partes.length}%` }}>
                  {appendDots(nome, 11)}
                </th>
              </Tippy>
            ))}
          </tr>

          <Total name={groupName} parts={partes} />
        </thead>

        <tbody>
          {data.map((el, index) => (
            <FieldSet key={el.id} name={groupName} index={index}>
              <Item data={el} parts={partes} editable={editable} />
            </FieldSet>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Item = ({
  data,
  parts,
  editable,
}: {
  data: UC;
  parts: Part[];
  editable: boolean;
}) => {
  const { id, descricao, valor, fonteRecurso, fonteRecursoStatus } = data;
  const [state, setState] = useState({ fonteRecurso, fonteRecursoStatus });
  const { tx } = useTranslationX('proposal:budget-description', [
    'translation',
    'proposal',
  ]);

  return (
    <tr>
      <HiddenField name="id" value={id} omit />
      <HiddenField name="descricao" value={descricao} omit />
      <HiddenField name="valor" value={valor} omit />

      <HiddenField name="fonteRecurso" value={state.fonteRecurso} />
      <HiddenField name="fonteRecursoStatus" value={state.fonteRecursoStatus} />

      {descricao ? (
        <>
          {descricao.length > 30 ? (
            <Tippy
              content={descricao}
              animation="perspective"
              touch={false}
              followCursor="horizontal"
              plugins={[followCursor]}
              className={styles.popup}
            >
              <td>{`${descricao.substring(0, 27)}...`}</td>
            </Tippy>
          ) : (
            <td>{descricao}</td>
          )}
        </>
      ) : (
        <td
          style={{
            fontStyle: 'italic',
          }}
        >
          {tx('no-description')}
        </td>
      )}
      <td>{getMasked((valor || 0).toFixed(2), { pattern: 'currency' })}</td>

      {parts.map(({ id, outrosCustos }) => (
        <td key={id}>
          <StateCheck
            state={id === state.fonteRecurso ? state.fonteRecursoStatus : 0}
            editable={editable}
            triple={outrosCustos}
            onChange={
              editable
                ? status =>
                    setState({
                      fonteRecurso: status ? id : null,
                      fonteRecursoStatus: status,
                    })
                : undefined
            }
          />
        </td>
      ))}
    </tr>
  );
};

const Total = ({ name, parts }: { name: string; parts: Part[] }) => {
  const { t, tx } = useTranslationX('proposal:budget-description', [
    'translation',
    'proposal',
  ]);

  const form = useForm();
  const data = form.getFieldValue<UC[]>(name) || [];

  useFormObserver(new RegExp(`${name}.*\\.fonteRecurso`));

  return (
    <tr>
      <th rowSpan={2}>{tx('description')}</th>
      <th rowSpan={2}>{`${tx('value')} (${t('currency')})`}</th>
      {parts.map(({ id }) => {
        let total = 0;

        for (const uc of data) {
          if (uc.fonteRecurso === id) {
            total += uc.valor;
          }
        }

        return <td key={id}>{getMasked(total.toFixed(2), { pattern: 'currency' })}</td>;
      })}
    </tr>
  );
};

export default BudgetTable;
