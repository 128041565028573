import React from 'react';

const Copy = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24">
    <path
      d="m13.7 6.35h-7.83c-1.44 0-2.61 1.17-2.61 2.61v10.4c0 1.44 1.17 2.61 2.61 2.61h7.83c1.44 0 2.61-1.17 2.61-2.61v-10.4c0-1.44-1.17-2.61-2.61-2.61z"
      fill="currentColor"
    />
    <path
      d="m18.1 2h-7.83c-0.692 0-1.36 0.275-1.84 0.764-0.489 0.489-0.764 1.15-0.764 1.84h6.09c1.15 0 2.26 0.458 3.07 1.27 0.815 0.815 1.27 1.92 1.27 3.07v8.7c0.692 0 1.36-0.275 1.84-0.764s0.764-1.15 0.764-1.84v-10.4c0-0.692-0.275-1.36-0.764-1.84s-1.15-0.764-1.84-0.764z"
      fill="currentColor"
    />
  </svg>
);

export default Copy;
