import React from 'react';

const Config = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none">
    <path
      d="m18.4 13.1c0.036-0.288 0.063-0.576 0.063-0.882 0-0.306-0.027-0.594-0.063-0.882l1.9-1.49c0.171-0.135 0.216-0.378 0.108-0.576l-1.8-3.11c-0.108-0.198-0.351-0.27-0.549-0.198l-2.24 0.9c-0.468-0.36-0.972-0.657-1.52-0.882l-0.342-2.38c-0.027-0.216-0.216-0.378-0.441-0.378h-3.6c-0.225 0-0.414 0.162-0.441 0.378l-0.342 2.38c-0.549 0.225-1.05 0.531-1.52 0.882l-2.24-0.9c-0.207-0.081-0.441 0-0.549 0.198l-1.8 3.11c-0.117 0.198-0.063 0.441 0.108 0.576l1.9 1.49c-0.036 0.288-0.063 0.585-0.063 0.882 0 0.297 0.027 0.594 0.063 0.882l-1.9 1.48c-0.171 0.135-0.216 0.378-0.108 0.576l1.8 3.11c0.108 0.198 0.351 0.27 0.549 0.198l2.24-0.9c0.468 0.36 0.972 0.657 1.52 0.882l0.342 2.39c0.027 0.216 0.216 0.378 0.441 0.378h3.6c0.225 0 0.414-0.162 0.441-0.378l0.342-2.39c0.549-0.225 1.05-0.531 1.52-0.882l2.24 0.9c0.207 0.081 0.441 0 0.549-0.198l1.8-3.11c0.108-0.198 0.063-0.441-0.108-0.576zm-6.69 2.27c-1.74 0-3.15-1.41-3.15-3.15 0-1.74 1.41-3.15 3.15-3.15 1.74 0 3.15 1.41 3.15 3.15 0 1.74-1.41 3.15-3.15 3.15z"
      fill="currentColor"
    />
  </svg>
);

export default Config;
