import { SearchIcon } from 'consts/icons';
import { useTranslationX } from 'i18n';
import React, { ElementType } from 'react';
import { renderComponent } from 'utils/components';
import styles from './NotFoundMessage.module.scss';

export type NotFoundProps = {
  icon?: ElementType;
  title?: string;
  description?: string;
  iconProps?: object;
};
const NotFoundMessage: React.FC<NotFoundProps> = ({
  title,
  description,
  icon,
  iconProps,
}) => {
  const { tx } = useTranslationX('components', 'translation');

  return (
    <div className={styles.notFoundMessage}>
      <div className={styles.icon}>
        {icon !== undefined ? (
          renderComponent(icon, iconProps || { size: 40 })
        ) : (
          <SearchIcon size={40} />
        )}
      </div>
      <h1>{title || tx('not_found.title')}</h1>
      <p>{description || tx('not_found.description')}</p>
    </div>
  );
};

export default NotFoundMessage;
