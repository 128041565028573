import React from 'react';

const Unarchive = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none">
    <path
      d="m20.6 5.22-1.39-1.68c-0.28-0.33-0.69-0.54-1.16-0.54h-12c-0.47 0-0.88 0.21-1.15 0.55l-1.39 1.67c-0.29 0.35-0.46 0.79-0.46 1.28v12.5c0 1.1 0.89 2 2 2h14c1.1 0 2-0.9 2-2v-12.5c0-0.49-0.17-0.93-0.45-1.28zm-8.2 4.63 5.15 5.15h-3.5v2h-4v-2h-3.5l5.15-5.15c0.19-0.19 0.51-0.19 0.7 0zm-6.41-5.85-0.82 1h13.8l-0.93-1z"
      clipRule="evenodd"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default Unarchive;
