import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { MilestoneDashboard, MilestoneEditor } from '.';

import { useDynamicHeader, withDynamicHeader } from 'components/containers/DynamicHeader';
import { useTranslation } from 'i18n';
import { ProjectStatus } from 'pages/project/types';

export type Props = {
  id: number;
  sigla: string;
  titulo: string;
  status: ProjectStatus;
  profile: 'supervisor' | 'pesquisador';
};

const Milestones: React.FC<Props> = props => {
  const { path } = useRouteMatch();

  const { t } = useTranslation('project');

  const { setFooter } = useDynamicHeader();

  useEffect(() => {
    setFooter(t('editor.footer', { titulo: props.titulo, sigla: props.sigla }));
  }, [props, t, setFooter]);
  return (
    <Switch>
      <Route exact path={`${path}`}>
        <MilestoneDashboard />
      </Route>
      <Route exact path={`${path}/:milestoneId`}>
        <MilestoneEditor />
      </Route>
    </Switch>
  );
};

export default withDynamicHeader(Milestones);
