import React from 'react';

const Image = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none">
    <path
      d="m21 5v14c0 1.1-0.9 2-2 2h-14c-1.1 0-2-0.9-2-2v-14c0-1.1 0.9-2 2-2h14c1.1 0 2 0.9 2 2zm-10 11.5-2.1-2.53c-0.21-0.25-0.59-0.24-0.78 0.02l-2.49 3.2c-0.26 0.33-0.03 0.81 0.39 0.81h12c0.41 0 0.65-0.47 0.4-0.8l-3.51-4.68c-0.2-0.27-0.6-0.27-0.8-0.01z"
      clipRule="evenodd"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default Image;
