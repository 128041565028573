import React from 'react';

import Field, { Props as FieldProps } from '../Field/Field';
import Input, { Props as InputProps } from 'components/inputs/ValueRange/ValueRange';

const CurrencyRange: React.FC<FieldProps & InputProps> = ({ label, ...rest }) => {
  const props = { title: label, ...rest };
  return <Field {...props} component={Input} theme="sneaky" />;
};

CurrencyRange.displayName = 'CurrencyRange';
export default CurrencyRange;
