import React, { CSSProperties } from 'react';

import styles from './Spacer.module.scss';

type Props = {
  length?: number;
  minLen?: number;
  maxLen?: number;
  direction?: 'vertical' | 'horizontal' | 'both';
};

const Spacer = ({ length, minLen, maxLen, direction }: Props) => {
  const style: CSSProperties = {};

  const setupWidth = () => {
    style.width = length;
    style.minWidth = coalesce(minLen, length);
    style.maxWidth = coalesce(maxLen, length);
  };

  const setupHeight = () => {
    style.height = length;
    style.minHeight = coalesce(minLen, length);
    style.maxHeight = coalesce(maxLen, length);
  };

  switch (direction) {
    case 'vertical':
      setupHeight();
      break;

    case 'horizontal':
      setupWidth();
      break;

    default:
      setupWidth();
      setupHeight();
      break;
  }

  return <div className={styles.spacer} style={style}></div>;
};

export default Spacer;

const coalesce = (val1?: number, val2?: number) =>
  typeof val1 === 'number' ? val1 : val2;
