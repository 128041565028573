import React from 'react';

const Researcher = ({ size = 1 }: { size?: number }) => (
  <svg width={`${size}em`} viewBox="0 0 51 50" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.0188 25.4442C9.73743 25.0658 10.6266 25.3413 11.0061 26.0599L16.8463 37.1433C17.3551 36.9629 17.9021 36.8648 18.4717 36.8648H22.3502C22.3737 32.9286 25.5816 29.7325 29.5208 29.7325H32.1041C31.4737 28.7472 31.108 27.5756 31.108 26.3217V23.458C31.108 19.9237 34.01 17.0534 37.5574 17.1067C41.0431 17.1591 43.812 20.0681 43.812 23.5543V26.3217C43.812 27.5756 43.4463 28.7472 42.8159 29.7325H46.4688C46.9335 29.7325 47.3884 29.7766 47.8296 29.8629V7.82463H3.56487V39.1952C3.56487 39.7367 4.0038 40.1756 4.54527 40.1756H13.8433C13.9943 39.7335 14.207 39.3207 14.4708 38.9452L8.40311 27.4305C8.02468 26.7119 8.30017 25.8227 9.0188 25.4442ZM10.9492 11.907H26.0208C26.8325 11.907 27.4914 12.5648 27.4914 13.3776C27.4914 14.1893 26.8325 14.8482 26.0208 14.8482H10.9492C10.1374 14.8482 9.4786 14.1893 9.4786 13.3776C9.4786 12.5648 10.1374 11.907 10.9492 11.907ZM10.9492 18.7619H26.0208C26.8325 18.7619 27.4914 19.4207 27.4914 20.2325C27.4914 21.0442 26.8325 21.7031 26.0208 21.7031H10.9492C10.1374 21.7031 9.4786 21.0442 9.4786 20.2325C9.4786 19.4207 10.1374 18.7619 10.9492 18.7619Z"
      fill="currentColor"
    />
    <path
      d="M46.4689 32.6735H29.5209C27.1895 32.6735 25.2915 34.5726 25.2915 36.9069V38.3353C25.2915 39.1471 24.6336 39.8059 23.8209 39.8059H18.4719C17.439 39.8059 16.5815 40.6187 16.5248 41.6461C16.4416 42.7015 17.307 43.7127 18.4719 43.7127H27.3493C28.2041 43.7127 28.8954 43.0152 28.8954 42.1647V39.9482C28.8954 39.1559 29.5048 38.4743 30.2964 38.4378C31.1409 38.3989 31.8366 39.072 31.8366 39.9069V49.0196C31.8366 49.5611 32.2755 50 32.817 50H43.0826V39.9482C43.0826 39.1559 43.6921 38.4743 44.4836 38.4378C45.3281 38.3989 46.0238 39.072 46.0238 39.9069V50H49.717C50.2584 50 50.6974 49.5611 50.6974 49.0196V36.9069C50.6974 34.5707 48.7985 32.6735 46.4689 32.6735Z"
      fill="currentColor"
    />
    <path
      d="M34.0492 23.4578V26.3215C34.0492 28.2019 35.5786 29.7323 37.46 29.7323C39.3404 29.7323 40.8708 28.2019 40.8708 26.3215V23.4578C40.8708 21.5774 39.3404 20.047 37.46 20.047C35.5786 20.047 34.0492 21.5774 34.0492 23.4578Z"
      fill="currentColor"
    />
    <path
      d="M0.697266 0.980393V3.90294C0.697266 4.44441 1.13619 4.88334 1.67766 4.88334H49.7169C50.2584 4.88334 50.6973 4.44441 50.6973 3.90294V0.980393C50.6973 0.438922 50.2584 0 49.7169 0H1.67766C1.13619 0 0.697266 0.438922 0.697266 0.980393Z"
      fill="currentColor"
    />
  </svg>
);

export default Researcher;
