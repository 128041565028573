import React, { CSSProperties, ReactNode, useRef } from 'react';
import Tippy from '@tippyjs/react';

import { Ripple } from '..';
import { renderComponent, classes } from 'utils/components';
import { call } from 'utils';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/svg-arrow.css';
import 'tippy.js/animations/perspective.css';

import styles from './IconButton.module.scss';

type Props = {
  icon: React.ElementType;
  iconProps?: object;
  onClick?: () => void;
  size?: number;
  rate?: number;
  color?: string;
  shape?: 'square' | 'circle';
  type?: 'danger' | 'disabled' | 'primary' | 'invert' | 'dark';
  overflow?: boolean;
  hint?: string;
  children?: ReactNode;
  style?: { root?: CSSProperties; text?: CSSProperties };
};

const IconButton: React.FC<Props> = props => {
  const {
    size = 38,
    rate = 0.7,
    shape = 'square',
    type = 'primary',
    children,
    color,
    style,
  } = props;
  const { overflow, hint } = props;

  const ref = useRef<HTMLDivElement>(null);

  const iconStyle = {
    width: size,
    height: size,
    fontSize: Math.round(size * rate),
    color: color,
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && ref.current) {
      ref.current.click();
    }
  };

  const content = (
    <div
      ref={ref}
      className={classes(
        styles.iconButton,
        styles[shape],
        styles[type],
        overflow ? styles.overflow : null
      )}
      onClick={e => {
        e.stopPropagation();
        call(props.onClick);
      }}
      tabIndex={type !== 'disabled' ? 0 : -1}
      onKeyDown={onKeyDown}
      style={style?.root}
    >
      <div style={iconStyle} tabIndex={-1}>
        {renderComponent(props.icon, props.iconProps)}
        <Ripple parentRef={ref} shape={shape} size={overflow ? '1.5w' : '1w'} />
      </div>
      {children}
    </div>
  );

  return hint ? (
    <Tippy
      className={classes(styles.popup, styles[type])}
      content={hint}
      animation="perspective"
      touch={false}
    >
      {content}
    </Tippy>
  ) : (
    content
  );
};

export default IconButton;
