import React from 'react';

import { useTranslation } from 'i18n';
import { Grid, Row } from 'components/containers';
import { TextField, Select, MultiSelect, FieldSet, HiddenField } from 'components/form';

const Researcher = ({ index }: { index: number }) => {
  const { t } = useTranslation('register');

  return (
    <Grid>
      <FieldSet name="pesquisador" index={index}>
        <HiddenField name="id" />

        <Select name="campus" label={t('researcher.campus')} />
        <TextField name="lattes" label={t('academic.resume')} />

        <Row>
          <TextField name="siape" label={t('researcher.id')} />
          <Select name="titulacao" label={t('researcher.level')} />
        </Row>

        <MultiSelect name="areaPesquisa" label={t('academic.areas')} />
      </FieldSet>
    </Grid>
  );
};

export default Researcher;
