import useDebounce from 'components/hooks/useDebounce';
import React, { useEffect } from 'react';
import { Dispatch } from '../ProposalEditor/types';

import PartsTable from './PartsTable';
import RelationshipMonitor from './RelationshipMonitor';
import { BudgetInfo } from './types';
import useDistributionObserver from './useDistributionObserver';

type Props = {
  dispatch: Dispatch;
};

const BudgetInfoContainer = (props: BudgetInfo & Props) => {
  const { dispatch, ...budgetInfo } = props;
  const { data, loading } = useDistributionObserver(budgetInfo);
  const debouncedLoading = useDebounce(loading, 200);

  useEffect(() => {
    dispatch({ type: 'LOCK_NAVIGATION', payload: debouncedLoading });
    // Lock navigation when loading data
  }, [debouncedLoading, dispatch]);

  return (
    <>
      <PartsTable data={data} />
      <RelationshipMonitor data={data} loading={loading} />
    </>
  );
};

export default BudgetInfoContainer;
