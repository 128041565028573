import React from 'react';

import Field, { Props as FieldProps } from '../Field/Field';
import Input, { Props as InputProps } from 'components/inputs/Select/TableSelect';

const TableSelect: React.FC<FieldProps & InputProps> = props => (
  <Field {...props} component={Input} />
);

TableSelect.displayName = 'TableSelectField';
export default TableSelect;
