import React, { useState, useEffect, useMemo } from 'react';

import { Loader, Radio } from 'components/elements';
import { useAsyncOptions } from 'components/hooks';
import { FieldMetadata, FieldOption, FieldType } from 'components/form/types';
import { call } from 'utils';
import { readAttr } from 'utils/object';
import { useTranslation } from 'i18n';

import styles from './RadioGroup.module.scss';

export type Props = {
  form?: any;
  value?: string | number;
  label?: string;
  metadata?: FieldMetadata;
  onChange?: (value: string | number) => void;
  columns?: number;
  parseOption?: (value: FieldOption) => string;
  type?: FieldType;
  needsReload?: boolean;
};

const RadioGroup = (props: Props) => {
  const {
    value,
    metadata,
    onChange,
    columns = 1,
    parseOption = op => op.text,
    form,
    needsReload = false,
  } = props;

  const [selected, setSelected] = useState(value);
  const { options, loading } = useAsyncOptions(metadata);
  const { t } = useTranslation();

  const required = readAttr('rules.required', metadata, false);
  const showOptional = useMemo(
    () => readAttr('rules.required', metadata) !== undefined && form?.display?.optional,
    [form.display.optional, metadata]
  );

  useEffect(() => {
    call(onChange, selected);
  }, [onChange, selected]);

  useEffect(() => {
    if (needsReload) {
      setSelected(value);
    }
  }, [needsReload, value]);

  if (loading) {
    return <Loader />;
  }

  const rows: FieldOption[][] = [];

  Array.isArray(options) &&
    options.forEach((option, index) => {
      const row = Math.floor(index / columns);

      if (rows[row]) {
        rows[row].push(option);
      } else {
        rows[row] = [option];
      }
    });

  return (
    <div className={styles.radioGroup}>
      <label>
        {props.label + ' '}
        {required ? (
          <span className={styles.required}>*</span>
        ) : (
          showOptional && (
            <span className={styles.optional}>
              {`(${t('components.form.field.optional')})`}
            </span>
          )
        )}
      </label>

      <table className={styles.table}>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              {row
                .filter(op => op.params !== 'false' || selected === op.value)
                .map((op, index) => (
                  <td key={index}>
                    <div>
                      <Radio
                        key={op.value}
                        label={parseOption(op)}
                        value={op.value}
                        selected={selected === op.value}
                        onChange={() => setSelected(op.value)}
                        type={op.params === 'false' ? 'disabled' : props.type}
                      />
                    </div>
                  </td>
                ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RadioGroup;
