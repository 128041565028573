import { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useRequest } from 'apis';
import {
  Dispatch,
  EditorState,
  GroupsMenu,
  ProposalError,
  SectionValidationMessage,
  ValidationData,
  ProposalValidateItem,
  ValidationMessage,
  TotalErrorsCount,
  ProposalTabError,
} from 'pages/proposal/ProposalEditor/types';
import { useTranslation } from 'i18n';

import { FormInstance } from 'components/form/types';
import { messageService } from 'services';
import useCallbackDebounce from 'components/hooks/useCallbackDebounce';

type Props = {
  state: EditorState;
  dispatch: Dispatch;
  instances?: FormInstance[];
};

const ProposalValidator = ({ state, dispatch, instances }: Props) => {
  const { t } = useTranslation('proposal');
  const [messages, setMessages] = useState<SectionValidationMessage>();
  const [validationData, setValidationData] = useState<ValidationData>();
  const debounceTime = useMemo(() => {
    return (instances?.length || 0) > 1 ? 400 : 0;
  }, [instances?.length]);

  const request = useRequest();

  const handleData = useCallback(
    (data: ValidationData) => {
      setValidationData(data);
      const validation = sectionValidation(data);
      setMessages(messages => {
        return _.isEqual(validation, messages) ? messages : validation;
      });

      const tabsErrors = verifyTabsError(data);
      const counters = countValidation(data);

      dispatch({
        type: 'HAS_ERRORS',
        payload: counters.totals.errors > 0,
      });
      dispatch({ type: 'SET_ERRORS', payload: counters.erros });
      dispatch({ type: 'SET_TAB_ERRORS', payload: tabsErrors });
    },
    [dispatch]
  );

  const validateCallback = useCallback(() => {
    if (state.showErrors) {
      const source = request<ValidationData>({
        url: `/proposta/${state.id}/validate`,
        onSuccess: handleData,
      });
      return () => source.cancel();
    }
  }, [handleData, request, state.id, state.showErrors]);

  const observerCallback = useCallbackDebounce(validateCallback, debounceTime);

  const currentMessages = useMemo(() => {
    if (!messages || !state.section || !state.showErrors) {
      return [];
    } else {
      return messages[state.section];
    }
  }, [messages, state.section, state.showErrors]);

  useEffect(() => {
    if (state.showErrors && state.section) {
      if (validationData !== undefined) {
        for (const key in state.forms) {
          if (key.startsWith(state.section)) {
            const form = state.forms[key];

            if (form) {
              const gKeys: string[] = groups[state.section];

              for (let i = 0; i < gKeys.length; i++) {
                const gKey = gKeys[i];
                const fields = validationData[gKey].fields;

                if (fields !== undefined) {
                  const rules = createRules(fields);

                  rules.forEach(rule => {
                    rule.rule.message = t(`validation.${rule.rule.message}`);
                    form.context.addRule(rule.key, 'rejected', rule.rule);
                  });
                }
              }
            }
          }
        }
      }
    }
  }, [messages, state.forms, state.section, state.showErrors, t, validationData]);

  useEffect(() => {
    if (instances) {
      instances.forEach(instance =>
        instance.observer.subscribe(observerCallback, { onSave: true })
      );
    }
    return () => {
      instances?.forEach(instance => instance.observer.unsubscribe(observerCallback));
    };
  }, [instances, state.id, observerCallback]);

  useEffect(() => {
    const lateValidation = ['resumo'];
    if (!lateValidation.includes(state.section || '')) {
      validateCallback();
    }
  }, [state.id, state.section, validateCallback]);

  const getMessage = useCallback(
    (message: ValidationMessage) => {
      let text = t(`validation.${message.message}`);

      if (text !== '' && message.data) {
        const keys = Object.keys(message.data);
        for (let i = 0; i < keys.length; i++) {
          text = text?.replace(`{${keys[i]}}`, message.data[keys[i]]);
        }
      }
      return text;
    },
    [t]
  );

  useEffect(() => {
    if (currentMessages) {
      currentMessages.forEach(message => {
        const text = getMessage(message);
        if (text) {
          if (message.type === 'error') {
            messageService.error(text, {
              position: 'bottom',
            });
          } else {
            messageService.warning(text, {
              position: 'bottom',
            });
          }
        }
      });
    }
    return () => {
      messageService.clear();
    };
  }, [currentMessages, getMessage, state.section]);

  return null;
};

export default ProposalValidator;

const groups: GroupsMenu = {
  observacoes: ['corrections'],
  identificacao: ['identification'],
  formulario: ['caracterizacao'],
  descricao: ['description'],
  eap: ['eap'],
  'metas-macro': ['milestones'],
  orcamento: ['hr', 'travels', 'equipments', 'other', 'services', 'consumables'],
  contrapartida: ['counterpart'],
  resumo: ['budget-summary', 'operational-support', 'aporte'],
  'divisao-orcamento': ['budget-description'],
};

const verifyTabsError = (validationData: ValidationData) => {
  const tabsErrors: ProposalTabError = {};
  const sects = Object.keys(groups);

  for (let i = 0; i < sects.length; i++) {
    const subs = groups[sects[i]];

    if (sects[i] === 'identificacao') {
      tabsErrors[sects[i]] = {};
      const validation = validationData[subs[0]];

      if (validation.fields?.errors !== undefined) {
        const companies = Object.keys(validation.fields.errors).filter(item => {
          return item.startsWith('empresas');
        });

        for (let j = 0; j < companies.length; j++) {
          tabsErrors[sects[i]][companies[j]] = {
            errors: true,
          };
        }
      }
    }

    if (sects[i] === 'metas-macro' || sects[i] === 'orcamento') {
      tabsErrors[sects[i]] = {};

      for (let j = 0; j < subs.length; j++) {
        const validation = validationData[subs[j]];

        if (validation.fields?.errors !== undefined) {
          const errorKeys = Object.keys(validation.fields?.errors).length;
          tabsErrors[sects[i]][subs[j]] = { errors: errorKeys > 0 };
        }

        if (validation.fields?.warnings !== undefined) {
          const warnKeys = Object.keys(validation.fields?.warnings).length;
          tabsErrors[sects[i]][subs[j]] = { warnings: warnKeys > 0 };
        }
      }
    }

    if (sects[i] === 'descricao') {
      for (let j = 0; j < subs.length; j++) {
        const validation = validationData[subs[j]];

        if (validation.fields?.errors !== undefined) {
          let errFields = validation.fields.errors;
          errFields = errFields.descritivo;
          const keys = Object.keys(errFields);
          tabsErrors[sects[i]] = {};

          for (let k = 0; k < keys.length; k++) {
            const tab = keys[k];

            if (errFields[tab]) {
              tabsErrors[sects[i]][tab] = { errors: true };
            }
          }
        }

        if (validation.fields?.warnings !== undefined) {
          let warnFields = validation.fields?.warnings;
          warnFields = warnFields.descritivo;
          const keys = Object.keys(warnFields);
          tabsErrors[sects[i]] = {};

          for (let k = 0; k < keys.length; k++) {
            const tab = keys[k];

            if (warnFields[tab]) {
              tabsErrors[sects[i]][tab] = { warnings: true };
            }
          }
        }
      }
    }
  }

  return tabsErrors;
};

const createRules = (fields: ProposalValidateItem) => {
  const errors = fields.errors;

  if (errors === undefined) {
    return [];
  }

  const keys = Object.keys(errors);
  let rules: (Record<string, any> & { key: string })[] = [];

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const obj = errors[key];
    const foundRules = searchRule(obj, key);
    if (!foundRules) {
      continue;
    }
    rules = [...rules, ...foundRules];
  }

  return rules;
};

const searchRule = (
  obj: any,
  prefix: string
): (Record<string, any> & { key: string })[] => {
  const keys = Object.keys(obj);

  const rules: (Record<string, any> & { key: string })[] = [];

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value = obj[key];
    if (value === null) {
      continue;
    }

    if (key === 'message') {
      rules.push({
        key: prefix,
        rule: { value: undefined, message: value.key },
      });
    }

    const newPrefix = prefix ? `${prefix}.${key}` : key;
    const isMessage = value.hasOwnProperty('message');
    if (isMessage) {
      const msg = obj[key].message.key;
      const val = obj[key].value;
      rules.push({
        key: newPrefix,
        rule: { value: val !== null ? val : undefined, message: msg },
      });
      continue;
    }

    if (typeof value === 'object') {
      const foundRules = searchRule(value, newPrefix);
      if (rules) {
        rules.concat(foundRules);
        continue;
      }
    }
  }
  return rules;
};

const countValidation = (validationData: ValidationData) => {
  const data: ProposalError = {};
  const menuKeys = Object.keys(groups);
  const totalErrorsCount: TotalErrorsCount = { errors: 0, warnings: 0 };
  for (let i = 0; i < menuKeys.length; i++) {
    const key = menuKeys[i];
    const group = groups[key];
    data[key] = { errors: 0, warnings: 0 };

    for (let j = 0; j < group.length; j++) {
      const obj = validationData[group[j]];
      let objSecErrors = obj.section?.errors;
      let objSecWarnin = obj.section?.warnings;
      let objFldErrors = obj.fields?.errors;
      let objFldWarnin = obj.fields?.warnings;
      if (key === 'identificacao' && objFldErrors) {
        const deepErrors = Object.entries(objFldErrors)
          .filter(item => {
            return !item[1].hasOwnProperty('rule');
          })
          .reduce((acc, [key, value]) => {
            const values = Object.entries(value).reduce((acc, [deepKey, value]) => {
              return { ...acc, [`${key}.${deepKey}`]: value };
            }, {});
            return { ...acc, ...values };
          }, {} as any);

        objFldErrors = { ...objFldErrors, ...deepErrors };
      }

      if (key === 'descricao') {
        objSecErrors =
          objSecErrors && objSecErrors['descritivo']
            ? objSecErrors['descritivo']
            : objSecErrors;

        objSecWarnin =
          objSecWarnin && objSecWarnin['descritivo']
            ? objSecWarnin['descritivo']
            : objSecWarnin;

        objFldErrors =
          objFldErrors && objFldErrors['descritivo']
            ? objFldErrors['descritivo']
            : objFldErrors;

        objFldWarnin =
          objFldWarnin && objFldWarnin['descritivo']
            ? objFldWarnin['descritivo']
            : objFldWarnin;
      }

      const sectionErrors = objSecErrors ? Object.keys(objSecErrors).length : 0;
      const sectionWarns = objSecWarnin ? Object.keys(objSecWarnin).length : 0;
      const fieldErrors = objFldErrors ? Object.keys(objFldErrors).length : 0;
      const fieldWarns = objFldWarnin ? Object.keys(objFldWarnin).length : 0;

      data[key].errors += sectionErrors + fieldErrors;
      data[key].warnings += sectionWarns + fieldWarns;
      totalErrorsCount.errors += sectionErrors + fieldErrors;
      totalErrorsCount.warnings += sectionWarns + fieldWarns;
    }
  }

  return { erros: data, totals: totalErrorsCount };
};

export const sectionValidation = (validationData: ValidationData) => {
  const data: SectionValidationMessage = {};
  const menuKeys = Object.keys(groups);

  for (let i = 0; i < menuKeys.length; i++) {
    const key: string = menuKeys[i];
    const group = groups[key];

    for (let j = 0; j < group.length; j++) {
      const obj = validationData[group[j]];

      const errors = Object.values(obj.section?.errors || {})
        .filter(error => error.message !== undefined)
        .map(error => error.message);

      const warns = Object.values(obj.section?.warnings || {})
        .filter(warn => warn.message !== undefined)
        .map(warn => warn.message);

      if (errors.length > 0) {
        const newErrors = errors.map(
          error =>
            ({
              message: error.key,
              type: 'error',
              data: error.params && error.params[0] ? error.params[0] : undefined,
            } as ValidationMessage)
        );
        data[key] = [...(data[key] || []), ...newErrors];
      } else if (warns.length > 0) {
        const newWarnings = warns.map(
          warn =>
            ({
              message: warn.key,
              type: 'warning',
              data: warn.params && warn.params[0] ? warn.params[0] : undefined,
            } as ValidationMessage)
        );
        data[key] = [...(data[key] || []), ...newWarnings];
      }
    }
  }
  return data;
};
