import { useEffect } from 'react';

import { useFormFieldObserver } from 'components/form';
import { RegisterAction } from '../types';

const useBond = (dispatch: React.Dispatch<RegisterAction>) => {
  const result = useFormFieldObserver('instituicao').value === 1;

  useEffect(() => {
    dispatch({ type: 'BOUND', payload: result });
  }, [dispatch, result]);

  return result;
};

export default useBond;
