import React from 'react';

import { CheckBox as CheckIcon, CheckBoxFrame as UncheckIcon } from 'components/icons';
import Check, { CheckProps } from '../Check/Check';

const Checkbox: React.FC<CheckProps> = props => (
  <Check
    {...props}
    check={{
      icon: CheckIcon,
    }}
    uncheck={{
      icon: UncheckIcon,
      size: 0.8,
    }}
  />
);

export default Checkbox;
