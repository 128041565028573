import React, { ReactNode } from 'react';
import { RiAlertFill as AlertIcon } from 'react-icons/ri';
import { useTranslation } from 'i18n';
import styles from './AlertMessage.module.scss';

type Props = {
  title?: string;
  message?: string;
  children?: ReactNode;
};

const AlertMessage: React.FC<Props> = ({ title, message, children }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.alertMessage}>
      <h4>{t(title || 'components.alert.title')}</h4>
      <span>{t(message || 'components.alert.default')}</span>
      <i>
        <AlertIcon />
      </i>
      {children}
    </div>
  );
};

export default AlertMessage;
