import React, { ReactNode } from 'react';

import { call } from 'utils';
import { classes, renderComponent } from 'utils/components';

import styles from './Check.module.scss';

export type CheckTypes = 'filled' | 'disabled' | 'readonly';

export interface CheckProps {
  value: string | number;
  selected?: boolean;
  label?: string | ReactNode;
  type?: CheckTypes;
  onChange?: (checked: boolean, value: string | number) => void;
}

interface CheckConfig {
  size?: number;
  icon: React.ElementType;
  unselectedColor?: string;
  selectedColor?: string;
}

interface Props extends CheckProps {
  check: CheckConfig;
  uncheck: CheckConfig;
}

const Check: React.FC<Props> = props => {
  const { selected, label, type, check, uncheck } = props;

  const onClick = () => {
    call(props.onChange, !selected, props.value);
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === ' ') {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <div
      className={classes(
        styles.check,
        selected ? styles.selected : null,
        type ? styles[type] : null
      )}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      <div
        className={styles.box}
        tabIndex={type === 'disabled' || type === 'readonly' ? -1 : 0}
        role="checkbox"
        aria-checked={selected}
        aria-disabled={type === 'disabled'}
        aria-readonly={type === 'readonly'}
      >
        <div tabIndex={-1}>
          <div
            className={styles.icon}
            style={selected ? { color: check.unselectedColor } : {}}
          >
            {renderComponent(uncheck.icon, {
              size: uncheck.size || 1,
            })}
          </div>
          <div
            className={styles.icon}
            style={selected ? { color: check.selectedColor } : {}}
          >
            {renderComponent(check.icon, {
              size: check.size || 1,
            })}
          </div>
        </div>
      </div>

      {label ? <div className={styles.label}>{label}</div> : null}
    </div>
  );
};

export default Check;
