import React from 'react';

import {
  Modal,
  ReadOnlyTableData,
  ReadOnlyTableDataConfig,
  Tab,
  Tabs,
} from 'components/containers';

import { X } from 'components/icons';

import { IconButton, Loader } from 'components/elements';
import { useTranslation, useTranslationX } from 'i18n';

import styles from './ProjectDetails.module.scss';

type Props = {
  visible: boolean;
  handleClose: () => any;
  data: ProjectDetailsType;
};

export type ProjectDetailsType = {
  titulo: string;
  sigla: string;
  duracao: string;
  maturidade: string;
  subarea: string;
  publicacaoDOU: string;
  finalizacao: string;
  coordenador: string;
  emailCoordenador: string;
  telefoneCoordenador: string;
  fontes: Fonte[];
  id: number;
  mesInicioExecucao: number;
};

export type Fonte = {
  nome: string;
  id: number;
  gpf: string;
  conta: {
    id: number;
    banco: string;
    agencia: string;
    numero: string;
  };
};

const ProjectDetails = (props: Props) => {
  const { visible, handleClose, data: projectDetailsData } = props;

  const { tx } = useTranslationX('dashboard.projectDetails', 'project');
  const { t } = useTranslation('translation');

  const tableConfig: ReadOnlyTableDataConfig[] = [
    {
      title: tx('title'),
      tableRows: [
        [
          {
            title: tx('name'),
            field: 'titulo',
            width: 30,
          },
          {
            title: tx('sigla'),
            field: 'sigla',
            width: 30,
          },
          {
            title: tx('duration'),
            field: 'duracao',
            width: 30,
            parse: (value: string) => t('months', { months: value }),
          },
        ],
        [
          {
            title: tx('coordinator'),
            field: 'coordenador',
            width: 0,
          },
          {
            title: tx('coordinatorEmail'),
            field: 'emailCoordenador',
            width: 0,
          },
          {
            title: tx('coordinatorPhone'),
            field: 'telefoneCoordenador',
            width: 0,
          },
        ],
        [
          {
            title: tx('maturidade'),
            field: 'maturidade',
            width: 30,
          },
        ],
        [
          {
            title: tx('subArea'),
            field: 'subarea',
            width: 20,
          },
        ],
        [
          {
            title: tx('publishedDOU'),
            field: 'publicacaoDOU',
            type: 'date',
            width: 40,
          },
          {
            title: tx('finishedDOU'),
            field: 'finalizacao',
            type: 'date',
            width: 40,
          },
        ],
        [
          {
            title: tx('executionStart'),
            field: 'anoInicioExecucao',
            width: 40,
            parse: value => `${projectDetailsData?.mesInicioExecucao}/${value}`,
          },
          {
            title: tx('projectManager'),
            field: 'gerente.nome',
            width: 40,
          },
        ],
      ],
    },
  ];

  const bankConfig: ReadOnlyTableDataConfig[] = [
    {
      tableRows: [
        [
          {
            title: tx('font'),
            field: 'nome',
            width: 30,
          },
          {
            title: tx('gpf'),
            field: 'gpf',
            width: 60,
          },
        ],
        [
          {
            title: tx('banco'),
            field: 'conta.banco',
            width: 30,
          },
          {
            title: tx('agency'),
            field: 'conta.agencia',
            width: 30,
          },
          {
            title: tx('conta'),
            field: 'conta.numero',
            width: 30,
          },
        ],
      ],
    },
  ];

  if (!projectDetailsData) {
    return <Loader />;
  }

  return (
    <Modal visible={visible} onClose={handleClose} onBlur={handleClose} width="auto">
      <div className={styles.projectDetails}>
        <div className={styles.closeIcon}>
          <IconButton icon={X} onClick={handleClose} />
        </div>
        <div>
          <ReadOnlyTableData<ProjectDetailsType>
            data={projectDetailsData}
            config={tableConfig}
            shadow={false}
          />
        </div>
        <div className={styles.fontes}>
          <p>{tx('fontDetails')}</p>

          <Tabs selectedIndex={0}>
            {projectDetailsData.fontes.map(fonte => {
              return (
                <Tab title={fonte.nome} key={fonte.nome}>
                  <ReadOnlyTableData<Fonte>
                    shadow={false}
                    data={fonte}
                    config={bankConfig}
                  />
                </Tab>
              );
            })}
          </Tabs>
        </div>
      </div>
    </Modal>
  );
};

export default ProjectDetails;
