import React from 'react';

const Upload = ({
  size = 1,
  color = 'currentColor',
}: {
  size?: number;
  color?: string;
}) => {
  const id = 'mask_upload-'.concat(Math.random().toString(36).substr(2, 9));
  return (
    <svg
      width={`${size}em`}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id={id}
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="3"
        width="13"
        height="15"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.25 14.2591H8.75002C8.26877 14.2591 7.87502 13.8654 7.87502 13.3841V9.00915H6.48377C5.70502 9.00915 5.32002 8.06415 5.87127 7.5129L9.88752 3.49665C10.051 3.3328 10.2729 3.24072 10.5044 3.24072C10.7358 3.24072 10.9578 3.3328 11.1213 3.49665L15.1375 7.5129C15.6888 8.06415 15.295 9.00915 14.5163 9.00915H13.125V13.3841C13.125 13.8654 12.7313 14.2591 12.25 14.2591ZM15.75 16.0091H5.25C4.76875 16.0091 4.375 16.4029 4.375 16.8841C4.375 17.3654 4.76875 17.7591 5.25 17.7591H15.75C16.2312 17.7591 16.625 17.3654 16.625 16.8841C16.625 16.4029 16.2312 16.0091 15.75 16.0091Z"
          fill="black"
        />
      </mask>
      <g mask={`url(#${id})`}>
        <rect width="21" height="21" fill={color} />
      </g>
    </svg>
  );
};

export default Upload;
