import React from 'react';

import { classes } from 'utils/components';

import styles from './ListField.module.scss';

type Props = {
  label: string;
  values: string[];
  type?: 'text' | 'url';
  padding: 'normal' | 'none';
};

const ListField = ({ label, values, type = 'text', padding = 'normal' }: Props) => {
  return (
    <div className={classes(styles.plainField, styles[padding])}>
      <span className={styles.label}>{label}</span>

      <ul>
        {values.map((value, index) => (
          <li key={index}>
            {type === 'text' ? (
              <span className={styles.value}>{value}</span>
            ) : (
              <a href={value} target="_blank" rel="noopener noreferrer">
                <span className={styles.value}>{value}</span>
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ListField;
