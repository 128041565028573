import React from 'react';

const MenuChecked = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_11401_2178"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="5"
        width="22"
        height="14"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.70374 5.20374H12.7037C13.2537 5.20374 13.7037 5.65374 13.7037 6.20374C13.7037 6.75374 13.2537 7.20374 12.7037 7.20374H2.70374C2.15374 7.20374 1.70374 6.75374 1.70374 6.20374C1.70374 5.65374 2.15374 5.20374 2.70374 5.20374ZM2.70374 9.20374H12.7037C13.2537 9.20374 13.7037 9.65374 13.7037 10.2037C13.7037 10.7537 13.2537 11.2037 12.7037 11.2037H2.70374C2.15374 11.2037 1.70374 10.7537 1.70374 10.2037C1.70374 9.65374 2.15374 9.20374 2.70374 9.20374ZM2.70374 15.2037H8.70374C9.25374 15.2037 9.70374 14.7537 9.70374 14.2037C9.70374 13.6537 9.25374 13.2037 8.70374 13.2037H2.70374C2.15374 13.2037 1.70374 13.6537 1.70374 14.2037C1.70374 14.7537 2.15374 15.2037 2.70374 15.2037ZM22.0037 11.5037L21.9137 11.4137C21.5237 11.0237 20.8837 11.0237 20.5037 11.4237L15.7237 16.2137L13.4237 13.9137C13.0337 13.5237 12.4037 13.5237 12.0137 13.9137L11.9237 14.0037C11.7365 14.1906 11.6312 14.4442 11.6312 14.7087C11.6312 14.9733 11.7365 15.2269 11.9237 15.4137L15.0137 18.5037C15.4037 18.8937 16.0337 18.8937 16.4237 18.5037L22.0037 12.9137C22.3937 12.5237 22.3937 11.8937 22.0037 11.5037Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0_11401_2178)">
        <rect width="24" height="24" fill="#333333" />
      </g>
    </svg>
  );
};

export default MenuChecked;
