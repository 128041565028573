import React, {
  CSSProperties,
  forwardRef,
  ReactNode,
  useImperativeHandle,
  useRef,
} from 'react';

import { IconButton } from 'components/elements';
import { XAlt } from 'components/icons';
import { useVisible } from 'components/hooks';
import { classes, renderComponent } from 'utils/components';
import { call } from 'utils';
import Modal from '../Modal/Modal';

import styles from './Dialog.module.scss';

export type DialogRef = {
  close: (param?: any) => void;
};

export type DialogProps = {
  visible: boolean;
  title?: string | JSX.Element;
  icon?: React.ElementType;
  iconProps?: { color?: string; size?: number; className?: string };
  message?: string;
  onClose?: () => void;
  onBlur?: () => void;
  closeButton?: boolean;
  actions?: React.ReactNode;
  width?: number | 'auto';
  titleAlign?: 'left' | 'center' | 'right';
  volatile?: boolean;
  children?: ReactNode;
};

const Dialog = forwardRef<DialogRef, DialogProps>((props, ref) => {
  const { title, icon, iconProps, message, children } = props;
  const { actions, width, onClose, onBlur } = props;
  const { visible, setVisible } = useVisible(props.visible);

  const closeParam = useRef<any>();

  useImperativeHandle(ref, () => ({
    close: (param?: any) => {
      closeParam.current = param;
      setVisible(false);
    },
  }));

  const renderTitle = () => {
    if (typeof title === 'string') {
      const style: CSSProperties = {};

      if (props.closeButton) {
        style.paddingRight = 93;
      }

      if (props.titleAlign) {
        if (props.titleAlign === 'center') {
          style.justifyContent = 'center';

          if (props.closeButton) {
            style.paddingLeft = 93;
          }
        } else if (props.titleAlign === 'right') {
          style.justifyContent = 'flex-end';
        }
      }

      return (
        <span className={styles.title} style={style}>
          {title}
        </span>
      );
    }

    return title;
  };

  return (
    <Modal
      visible={visible}
      onClose={() => {
        call(onClose, closeParam.current);
        closeParam.current = undefined;
      }}
      width={width}
      onBlur={props.volatile && !onBlur ? () => setVisible(false) : onBlur}
    >
      <div className={styles.dialog} style={width ? { maxWidth: width } : undefined}>
        {icon ? (
          <div className={styles.info}>
            <div>
              <div className={classes(styles.icon, iconProps?.className)}>
                {renderComponent(icon, iconProps)}
              </div>
            </div>
            <span className={styles.title}>
              <span className={styles.centeredTitle}>{title}</span>
            </span>
            {message && <span className={styles.message}>{message}</span>}
          </div>
        ) : (
          <div className={styles.header}>
            {renderTitle()}
            {message && <span className={styles.message}>{message}</span>}
          </div>
        )}

        {props.closeButton && (
          <div className={styles.closeButton}>
            <IconButton
              icon={XAlt}
              onClick={() => setVisible(false)}
              type="dark"
              size={28}
            />
          </div>
        )}

        <div className={styles.content}>{children}</div>

        {actions && <div className={styles.footer}>{actions}</div>}
      </div>
    </Modal>
  );
});

export default Dialog;
