import React, { useEffect, useMemo, useReducer, useState } from 'react';

import { useTranslation } from 'i18n';

import {
  AirportShuttle,
  Chart,
  Expenditure,
  InternalHardware,
  OthersExpenditure,
  ThirdPartyServices,
  Travel,
} from 'components/icons';
import { ConfigTopPanel } from 'pages/dashboard';
import { TopMenuItemIcon } from 'pages/dashboard/types';
import { EditorAction, Sections } from 'pages/project/ProjectEditor/types';
import {
  Consumables,
  Daily,
  Equipments,
  HumanResources,
  Others,
  Services,
  Tickets,
} from '../index';
import { EditorState } from './types';
import { useDynamicHeader, withDynamicHeader } from 'components/containers/DynamicHeader';
import { ProjectStatus } from '../types';
import { useFetch } from 'apis';
import { Loader } from 'components/elements';

type OwnProps = {
  id: number;
  titulo: string;
  sigla: string;
  status: ProjectStatus;
};

type EditorProps = OwnProps & EditorState;

const reducer = (state: EditorState, action: EditorAction): EditorState => {
  switch (action.type) {
    case 'SET_SECTION': {
      const section = action.payload;

      return { ...state, section };
    }
    case 'SET_ITEM': {
      return { ...state, item: action.payload };
    }
    case 'SET_CONTRACT': {
      return { ...state, contract: action.payload };
    }
    case 'SET_SECTIONS': {
      return { ...state, sections: action.payload };
    }
    case 'SET_MONTH': {
      return { ...state, meses: action.payload };
    }
    case 'SET_UPDATE_COUNT': {
      return { ...state, updateCount: action.payload };
    }

    default:
      return state;
  }
};

const ProjectEditor = (props: EditorProps) => {
  const [state, dispatch] = useReducer(reducer, { ...props });
  const [title, setTitle] = useState<string>();
  const [acronym, setAcronym] = useState<string>();

  const { t } = useTranslation('project');

  const { data: sectionsResponse } = useFetch<Sections>(`/projeto/${state.id}/execucao`);

  const { setFooter } = useDynamicHeader();

  useEffect(() => {
    return () => {
      localStorage.setItem('@sippi-keep-tab', '0');
    };
  }, []);

  useEffect(() => {
    setAcronym(props.sigla);
  }, [props.sigla]);

  useEffect(() => {
    setTitle(props.titulo);
  }, [props.titulo]);

  useEffect(() => {
    setFooter(t('editor.footer', { titulo: title, sigla: acronym }));
  }, [title, acronym, t, setFooter]);

  useEffect(() => {
    if (sectionsResponse) {
      dispatch({ type: 'SET_SECTIONS', payload: sectionsResponse.sections });
      dispatch({ type: 'SET_MONTH', payload: sectionsResponse.meses });
    }
  }, [sectionsResponse]);

  const items: TopMenuItemIcon[] = useMemo(() => {
    const allSections: TopMenuItemIcon[] = [
      {
        key: 'recursos-humanos',
        component: HumanResources,
        icon: OthersExpenditure,
        title: t('editor.sections.recursos-humanos.title'),
      },

      {
        key: 'material',
        component: Consumables,
        icon: Chart,
        title: t('editor.sections.material.title'),
      },
      {
        key: 'equipamentos',
        component: Equipments,
        icon: InternalHardware,
        title: t('editor.sections.equipamentos.title'),
      },
      {
        key: 'servicos',
        component: Services,
        icon: ThirdPartyServices,
        title: t('editor.sections.servicos.title'),
      },
      {
        key: 'diarias',
        component: Daily,
        icon: Travel,
        title: t('editor.sections.diarias.title'),
      },
      {
        key: 'passagens',
        component: Tickets,
        icon: AirportShuttle,
        title: t('editor.sections.passagens.title'),
      },
      {
        key: 'outros',
        component: Others,
        icon: Expenditure,
        title: t('editor.sections.outros.title'),
      },
    ];
    return allSections.map(section => ({
      ...section,
      itemProps: {
        empty: state.sections?.find(s => s.key === section.key)?.empty || false,
      },
    }));
  }, [state.sections, t]);

  if (!state.sections) {
    return <Loader type="spin" message={t('editor.loading')} />;
  }

  return (
    <>
      <ConfigTopPanel
        items={items}
        forwardProps={{ state, dispatch }}
        onChange={(item: any) => {
          dispatch({ type: 'SET_SECTION', payload: item?.key });
        }}
      />
    </>
  );
};

export default withDynamicHeader(ProjectEditor);
