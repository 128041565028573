import React, { useState } from 'react';

import { useTranslationX } from 'i18n';
import { Grid, Row, Accordion } from 'components/containers';
import { IconButton } from 'components/elements';
import { Trash } from 'components/icons';
import { FieldSet, HiddenField, TextField, useForm } from 'components/form';
import { randomKey } from 'utils';

import styles from './Contacts.module.scss';

const MAX_CONTACTS = 3;

const Contacts = () => {
  const form = useForm();
  const contacts = form.getFieldValue<any[]>('contatos');

  const [{ count, selected }, setState] = useState({
    count: contacts?.length || 1,
    selected: 0,
  });

  const { tx } = useTranslationX('company.contacts', 'register');

  const add = () => {
    if (count < MAX_CONTACTS) {
      form.addListItem('contatos', count, () =>
        setState(({ count }) => ({ count: count + 1, selected: count }))
      );
    }
  };

  const remove = (index: number) => {
    if (count > 1) {
      form.clearField(`contatos[${index}]`);

      setState(({ count, selected }) => ({
        count: count - 1,
        selected: index <= selected ? Math.max(0, selected - 1) : selected,
      }));
    }
  };

  const select = (expand: boolean, index: number) =>
    setState(({ count }) => ({
      count,
      selected: expand ? index : (index + 1) % count,
    }));

  return (
    <div className={styles.contacts}>
      <div>
        {Array.from(new Array(count), (_el, index) => {
          const key =
            form.getFieldValue<string>(`contatos[${index}].key`) || randomKey(5);

          return (
            <Accordion
              title={`${tx('subtitle')} 0${index + 1}`}
              key={key}
              visible={index === selected}
              appear={!!index}
              collapsible={count > 1}
              onChange={expand => select(expand, index)}
              controls={
                index ? (
                  <IconButton
                    icon={Trash}
                    rate={0.5}
                    shape="circle"
                    type="danger"
                    onClick={() => remove(index)}
                  />
                ) : undefined
              }
            >
              <Grid className={styles.content}>
                <FieldSet name="contatos" index={index}>
                  <Row>
                    <TextField name="nome" label={tx('name')} />
                    <TextField name="email" label={tx('email')} />
                  </Row>
                  <Row>
                    <TextField name="cargo" label={tx('position')} />
                    <TextField name="telefone" label={tx('phone')} />
                  </Row>

                  <HiddenField name="key" value={key} omit />
                </FieldSet>
              </Grid>
            </Accordion>
          );
        })}
      </div>

      <div
        className={styles.add}
        style={count >= MAX_CONTACTS ? { visibility: 'hidden' } : undefined}
      >
        <span onClick={add}>{tx('add')}</span>
      </div>
    </div>
  );
};

export default Contacts;
