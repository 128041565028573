import React from 'react';

import { useTranslationX } from 'i18n';
import { Grid, Row } from 'components/containers';
import { TextField, HiddenField } from 'components/form';
import { SectionProps } from '../types';
import useAvailability from '../useAvailability';

const Company = ({ state, dispatch }: SectionProps) => {
  useAvailability('cnpj', 'cnpj', state, dispatch);

  const { tx } = useTranslationX('company', 'register');
  const {
    availability: { status },
  } = state;

  const type = status === 'available' ? undefined : 'disabled';

  return (
    <Grid>
      <Row>
        <TextField name="cnpj" label={tx('id')} />
        <TextField name="cnae" label={tx('cnae')} type={type} />
      </Row>

      <TextField name="razaoSocial" label={tx('name')} type={type} />

      <HiddenField
        name="guard"
        value=""
        valid={false}
        type={type ? undefined : 'disabled'}
      />
    </Grid>
  );
};

export default Company;
