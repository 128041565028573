import _ from 'lodash';
import { BudgetInfo, DistItem } from './types';
import { RequestConfig, useRequest } from 'apis';
import { useCallback, useEffect, useState } from 'react';

import { useFormObserver } from '../../../components/form';
import { ProposalSummary } from '../SummaryAndActions/SummaryAndActions';

const useDistributionObserver = ({
  form,
  id,
}: BudgetInfo): { data: DistItem[] | undefined; loading: boolean } => {
  const [data, setData] = useState<DistItem[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const observer = useFormObserver(new RegExp(`.*\\.fonteRecurso`));

  const request = useRequest();

  useEffect(() => {
    setLoading(true);
  }, [observer]);

  const callback = useCallback(() => {
    setLoading(false);
    const options: RequestConfig<ProposalSummary> = {
      url: `proposta/${id}/budget-summary`,
      onSuccess: data => {
        let newData: DistItem[] = [];

        data?.partes?.forEach((parte: string, index: number) => {
          if (index === data.partes.length - 1) return;
          newData.push({
            id: index,
            nome: parte,
            suporte: data.composicao?.finEmbrapii[index],
            total: data.composicao?.finEmbrapii[index * 2],
            percent: data.composicao?.finEmbrapii[index * 2 + 1],
          });
        });

        setData(newData);
      },
      onComplete: () => setLoading(false),
    };

    const source = request<any>(options);
    return () => {
      source.cancel();
      setLoading(false);
    };
  }, [id, request]);

  const debouncedCallback = _.debounce(callback, 10);

  useEffect(() => {
    if (form) {
      form.observer.subscribe(debouncedCallback, { onSave: true });
      return () => form.observer.unsubscribe(debouncedCallback);
    }
  }, [form, debouncedCallback]);

  useEffect(() => {
    callback();
  }, [callback]);

  return { data, loading };
};

export default useDistributionObserver;
