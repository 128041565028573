import React from 'react';

import { useTranslation } from 'i18n';
import { Grid, Row } from 'components/containers';
import { PlainField } from 'components/elements';

type Data = {
  razaoSocial: string;
  cnpj: string;
  endereco: string;
  contatos: { nome: string; cargo?: string; email: string; telefone?: string }[];
  cnae?: string;
};

const Company = ({ data }: { data: Data }) => {
  const { t } = useTranslation('register');
  const { razaoSocial, cnpj, endereco, cnae, contatos } = data;

  return (
    <Grid spacing={0}>
      <Row width={[6, 4]} align="top">
        <PlainField label={t('company.name')} value={razaoSocial} />
        <PlainField label={t('company.id')} value={cnpj} />
      </Row>

      <Row width={[6, 4]} align="top">
        <PlainField label={t('address.title')} value={endereco} />
        <PlainField label={t('company.cnae')} value={cnae || t('na')} />
      </Row>

      <Row width={[1, 1]} align="top">
        <PlainField label={t('company.contacts.name')} value={contatos[0].nome} />
        <PlainField label={t('company.contacts.email')} value={contatos[0].email} />
      </Row>

      <Row width={[1, 1]} align="top">
        <PlainField
          label={t('company.contacts.phone')}
          value={contatos[0].telefone || t('na')}
        />
        <PlainField
          label={t('company.contacts.position')}
          value={contatos[0].cargo || t('na')}
        />
      </Row>
    </Grid>
  );
};

export default Company;
