import { createContext } from 'react';
import { EditorState } from '../types';
import { ProjectEditorContextProps } from './types';

const empty = () => {};

const ProjectEditorContext = createContext<ProjectEditorContextProps>({
  dispatch: empty,
  state: {} as EditorState,
});

ProjectEditorContext.displayName = 'ProjectEditorContext';

export { ProjectEditorContext };
