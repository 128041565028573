import React from 'react';

import { useTranslationX } from 'i18n';

import { Macroentregas, ModalidadeParte } from 'pages/proposal/types';

import {
  DatePicker,
  FieldSet,
  HiddenField,
  MonthYearPicker,
  TextField,
} from 'components/form';

import styles from './ContributionEditor.module.scss';

type Props = {
  macroentregas: Macroentregas[];
  parte: ModalidadeParte;
};

const ContributionEditor: React.FC<Props> = props => {
  const { parte, macroentregas } = props;

  const { tx } = useTranslationX('create', 'project');

  return (
    <div className={styles.container}>
      <table>
        <thead>
          <tr>
            <th>{tx('macroentrega')}</th>
            <th>{tx('aporteStartMonth')}</th>
            <th>{tx('deliveryDate')}</th>
            <th>{tx('aporteValue')}</th>
            <th>{tx('aporteDate')}</th>
          </tr>
        </thead>
        <FieldSet name={String(parte?.id)} key={parte?.id}>
          <tbody>
            {macroentregas.map((macroentrega, macroIndex) => {
              const aportes = macroentrega.aportes;
              return (
                <FieldSet name={macroentrega.id.toString()} key={macroIndex}>
                  {aportes.map((aporte, indexAportes) => {
                    const aporteParte = aporte.partes.find(p => p.parte?.id === parte.id);
                    return (
                      <FieldSet name="aportes" index={indexAportes} key={aporte?.id}>
                        <tr>
                          {indexAportes === 0 ? (
                            <>
                              <td rowSpan={aportes.length}>
                                <TextField
                                  theme="platinum"
                                  label={tx('macroentrega')}
                                  name="macroentrega"
                                  type="readonly"
                                  align="center"
                                  value={(macroentrega?.ordem || 0) + 1}
                                />
                              </td>
                              <td style={{ minWidth: '130px' }} rowSpan={aportes.length}>
                                <MonthYearPicker
                                  metadata={{ rules: { required: true } }}
                                  removeIcon={true}
                                  type="readonly"
                                  label="Mês inicio"
                                  name="mesInicio"
                                />
                              </td>
                            </>
                          ) : (
                            <>
                              <HiddenField
                                name="macroentrega"
                                value={(macroentrega?.ordem || 0) + 1}
                              />
                              <HiddenField name="mesInicio" />
                            </>
                          )}
                          <HiddenField
                            name="index"
                            type="readonly"
                            value={indexAportes}
                          />
                          <td>
                            <DatePicker
                              metadata={{ rules: { required: true } }}
                              theme="sneaky"
                              type="readonly"
                              name="dataEntrega"
                            />
                          </td>
                          <td>
                            <TextField
                              metadata={{
                                rules: {
                                  min: '0.00',
                                  minlength: 1,
                                  maxlength: 14,
                                  mask: {
                                    pattern: 'currency',
                                  },
                                },
                              }}
                              theme="platinum"
                              label={tx('valor')}
                              name="valor"
                              type="readonly"
                              align="right"
                              value={aporteParte?.valor || 0}
                            />
                          </td>
                          <td>
                            <DatePicker
                              metadata={{ rules: { required: true } }}
                              theme="sneaky"
                              name="dataAporte"
                              range={false}
                            />
                          </td>
                        </tr>
                      </FieldSet>
                    );
                  })}
                </FieldSet>
              );
            })}
          </tbody>
        </FieldSet>
      </table>
    </div>
  );
};

export default ContributionEditor;
