import React from 'react';

import { Button } from '../../elements';
import { PDFViewer } from '../';
import { downloadFileFromData } from 'utils/stringUtils';

import styles from './ModalPDFViewer.module.scss';

type Props = {
  pdfDescription?: string;
  onLoad?: () => void;
  pdfData?: string | ArrayBuffer | null;
  type?: 'view' | 'download';
};

const ModalPDFViewer: React.FC<Props> = ({
  pdfData,
  onLoad,
  pdfDescription = 'file',
  type = 'download',
}) => {
  function onDownload() {
    downloadFileFromData(pdfData, pdfDescription);
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.button}>
          {type === 'download' && <Button onClick={onDownload}>Download</Button>}
        </div>
        {pdfData && <PDFViewer file={pdfData} onLoad={onLoad} />}
      </div>
    </>
  );
};

export default ModalPDFViewer;
