import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useTranslationX } from 'i18n';
import { Dialog } from 'components/containers';
import { Loader } from 'components/elements';
import { classes, renderComponent } from 'utils/components';
import { getIcon, Profile } from 'pages/register/profile';
import { profileTransition, setProfile, useAuthState } from 'store/auth';

import styles from './ChangeProfile.module.scss';

const ChangeProfile = () => {
  const state = useAuthState();
  const dispatch = useDispatch();
  const history = useHistory();

  const { tx } = useTranslationX('changeProfile');

  return (
    <Dialog
      visible={!!state.transitionStatus}
      title={tx('title')}
      onBlur={() =>
        state.transitionStatus !== 'loading' ? dispatch(profileTransition()) : null
      }
    >
      <div className={styles.changeProfile}>
        {state.transitionStatus === 'loading' ? (
          <Loader type="spin" size={60} />
        ) : state.transitionStatus === 'selecting' ? (
          state.profiles!.map(({ profileName, profileId }) => (
            <div
              key={`${profileName}${profileId}`}
              className={classes(
                styles.badge,
                profileName === state.profile ? styles.selected : ''
              )}
              onClick={() => {
                if (profileName === state.profile) {
                  dispatch(profileTransition());
                } else {
                  history.push('/dashboard');
                  dispatch(setProfile({ profileName, profileId, transition: 'loading' }));
                }
              }}
            >
              <div>{renderComponent(getIcon(profileName as Profile))}</div>
              <span>{`${profileName.charAt(0).toUpperCase()}${profileName.slice(
                1
              )}`}</span>
            </div>
          ))
        ) : null}
      </div>
    </Dialog>
  );
};

export default ChangeProfile;
