import React, {
  CSSProperties,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { splitValueUnit } from 'utils';
import { classes } from 'utils/components';

import styles from './ScrollBar.module.scss';

export type ScrollBarRef = {
  setScrollPosition: (posiiton: number) => void;
};

type Props = {
  type: 'vertical' | 'horizontal';
  visibleSize: number;
  totalSize: number;
  onChange: (scrollPosition: number, attr: 'scrollTop' | 'scrollLeft') => void;
  scrollPosition?: number;
  position?: 'start' | 'end';
  start?: number;
  end?: number;
  overlay?: boolean;
  style?: { root?: CSSProperties; track?: CSSProperties; thumb?: CSSProperties };
};

type Params = {
  range: number;
  thumbSize: number;
  thumbRange: number;
  scale: number;
  border: number;
  dim: 'height' | 'width';
  start: 'top' | 'left';
  offsetDim: 'offsetHeight' | 'offsetWidth';
  coord: 'clientY' | 'clientX';
  offsetCoord: 'offsetY' | 'offsetX';
  margin: 'marginTop' | 'marginLeft';
};

const ScrollBar = forwardRef<ScrollBarRef, Props>((props, ref) => {
  const {
    type,
    visibleSize,
    totalSize,
    onChange,
    scrollPosition: sPos = 0,
    position = 'end',
    start = 0,
    end = 0,
    overlay,
    style,
  } = props;

  const [params, setParams] = useState<Params>();
  const [scrollPosition, setScrollPosition] = useState(sPos);

  const instance = useRef({ setScrollPosition });
  const track = useRef<HTMLDivElement>(null);
  const thumb = useRef<HTMLDivElement>(null);

  const grab = useRef(0);

  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      if (params && track.current && thumb.current?.matches(':active')) {
        const rect = track.current.getBoundingClientRect();
        const pos = e[params.coord] - rect[params.start];

        const scrollPos = Math.min(
          Math.max(pos - grab.current, 0),
          rect[params.dim] - thumb.current[params.offsetDim]
        );

        onChange(
          scrollPos * params.scale,
          type === 'vertical' ? 'scrollTop' : 'scrollLeft'
        );
      } else {
        window.removeEventListener('mousemove', onMouseMove);
      }
    },
    [onChange, params, type]
  );

  useEffect(() => setScrollPosition(sPos), [sPos]);

  useEffect(
    () => () => window.removeEventListener('mousemove', onMouseMove),
    [onMouseMove]
  );

  useEffect(() => {
    const { value: border } = splitValueUnit(styles.border);

    const params: Partial<Params> = { border };
    const size = visibleSize - start - end - border * 4;

    params.range = totalSize - visibleSize;
    params.thumbSize = Math.max(14, (size / totalSize) * size);
    params.thumbRange = size - params.thumbSize;
    params.scale = params.range / params.thumbRange;

    if (type === 'horizontal') {
      params.dim = 'width';
      params.start = 'left';
      params.offsetDim = 'offsetWidth';
      params.coord = 'clientX';
      params.offsetCoord = 'offsetX';
      params.margin = 'marginLeft';
    } else {
      params.dim = 'height';
      params.start = 'top';
      params.offsetDim = 'offsetHeight';
      params.coord = 'clientY';
      params.offsetCoord = 'offsetY';
      params.margin = 'marginTop';
    }

    setParams(params as Params);
  }, [end, position, start, totalSize, type, visibleSize]);

  useImperativeHandle(ref, () => instance.current);

  if (!params) {
    return null;
  }

  const { range, thumbRange, thumbSize } = params;
  const thumbPosition = (scrollPosition / range) * thumbRange;

  return (
    <div
      className={classes(
        styles.scrollbar,
        styles[type],
        styles[position],
        overlay ? styles.overlay : ''
      )}
      style={{
        ...style?.root,
        [params.start]: start,
        [params.dim]: visibleSize - start - end,
      }}
    >
      <div ref={track} className={styles.track} style={style?.track}>
        <div
          ref={thumb}
          className={styles.thumb}
          style={{
            ...style?.thumb,
            [params.dim]: thumbSize,
            [params.margin]: thumbPosition,
          }}
          onMouseDown={e => {
            if (e.button === 0) {
              grab.current = e.nativeEvent[params.offsetCoord];
              window.addEventListener('mousemove', onMouseMove);
            }
          }}
        />
      </div>
    </div>
  );
});

export default ScrollBar;
