import React from 'react';

const Pdf = ({ size = 1, color = 'currentColor' }: { size?: number; color?: string }) => (
  <svg width={`${size}em`} viewBox="0 0 24 24" fill="none">
    <g fill={color}>
      <path d="m21 9.03h-1.36v-2l-5.02-5.03h-10.3v7.03h-1.36v10h1.36v2.97h15.3v-2.97h1.36zm-6.04-5.03 2.68 2.69h-2.68zm-9.43-0.829h8.26v4.69h4.68v1.17h-12.9zm12.9 17.7h-12.9v-1.8h12.9zm1.36-2.97h-15.7v-7.66h15.7z" />
      <path d="m7.01 15h0.565c0.978 0 1.77-0.797 1.77-1.78 0-0.98-0.795-1.78-1.77-1.78h-1.73v5.31h1.17zm0-2.38h0.565c0.333 0 0.604 0.272 0.604 0.606 0 0.334-0.271 0.605-0.604 0.605h-0.565z" />
      <path d="m14 14.4v-0.625c0-1.29-1.05-2.34-2.34-2.34h-1.17v5.31h1.17c1.29 0 2.34-1.05 2.34-2.34zm-2.34-1.8c0.645 0 1.17 0.526 1.17 1.17v0.625c0 0.646-0.524 1.17-1.17 1.17z" />
      <path d="m16.4 15.1h1.17v-1.17h-1.17v-1.35h1.75v-1.17h-2.92v5.31h1.17z" />
    </g>
  </svg>
);

export default Pdf;
