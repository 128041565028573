import React, { forwardRef, ReactNode, useCallback, useEffect, useState } from 'react';

import { InputProps } from '../types';
import { isNil } from 'utils';
import { classes } from 'utils/components';
import { readAttr } from 'utils/object';
import { inputMask } from 'utils/inputMask';
import Source from './Source';

import { useForm } from 'components/form';
import { FormContextValue } from 'components/form/types';
import styles from './AutoCompute.module.scss';
import { useFirstRender } from 'components/hooks';

export type Props = {
  expression: string;
  relativePath?: boolean;
  align?: 'left' | 'center' | 'right';
  adornment?: string | { left?: string; right?: string };
};

type Data = {
  exp: string;
  map: {
    [key: string]: {
      field: string;
      value?: any;
      params?: { [key: number]: any };
    };
  };
  value?: any;
};

const AutoCompute = forwardRef<HTMLDivElement, Props & InputProps>(
  (
    { expression, relativePath = true, name, align = 'center', adornment, ...rest },
    ref
  ) => {
    const [data, setData] = useState<Data>(() =>
      parseExpression(expression, name, relativePath)
    );

    const form = useForm();

    const firstRender = useFirstRender();

    const onChange = useCallback(
      (id: string, value: any, params?: string[]) => {
        setData(data => {
          const newData = evaluateExpression(form, data, id, value, params);
          if (newData.value !== data.value) {
            const newValue =
              newData.value === Infinity ? 0 : isNaN(newData.value) ? 0 : newData.value;
            return {
              ...newData,
              value: newValue,
            };
          }
          return data;
        });
      },
      [form]
    );

    const mask = readAttr('rules.mask', rest.metadata);
    const filters = inputMask(mask, null, null);

    const value = filters?.save ? filters.save(data.value) : data.value;
    const update = rest.inputProps.onChange;

    useEffect(() => {
      if (firstRender) {
        return;
      }

      let newValue = value;
      if (typeof value === 'string' && !isNil(Number(value))) {
        newValue = Number(value);
      }

      update(newValue);
    }, [firstRender, name, update, value]);

    const sources: ReactNode[] = [];

    for (const key in data.map) {
      const { field, params } = data.map[key];

      sources.push(
        <Source
          key={key}
          id={key}
          fieldName={field}
          onChange={onChange}
          hasParams={!!params}
        />
      );
    }

    function adornmentLeft() {
      let val;

      if (adornment) {
        if (typeof adornment === 'string') {
          val = adornment;
        } else if (adornment.left) {
          val = adornment.left;
        }
      }

      return val ? <span className={styles.adornment}>{val}</span> : null;
    }

    function adornmentRight() {
      return typeof adornment === 'object' && adornment.right ? (
        <span className={styles.adornment}>{adornment.right}</span>
      ) : null;
    }

    return (
      <div
        className={classes(
          styles.autoCompute,
          styles[align],
          styles[rest.control.type || ''],
          styles[rest.control.theme || '']
        )}
        ref={ref}
      >
        {adornmentLeft()}

        {sources}
        <span className={styles.value}>
          {filters?.show ? filters.show(data.value) : data.value}
        </span>
        {adornmentRight()}
      </div>
    );
  }
);

export default AutoCompute;

function parseExpression(exp: string, fieldName: string, relativePath: boolean): Data {
  let path: string | undefined;

  if (relativePath) {
    const index = fieldName.lastIndexOf('.');

    if (index > 0) {
      path = fieldName.substring(0, index);
    }
  }

  let buff: string[] = [];
  const data: Data = { exp, map: {} };

  exp += '*';
  let c: string;

  for (let i = 0; i < exp.length; ++i) {
    c = exp.charAt(i);

    switch (c) {
      case '(':
      case ')':
      case '+':
      case '-':
      case '*':
      case '/':
      case '?':
      case ':':
        if (buff.length > 0) {
          if (buff[0] !== '$') {
            parseAttribute(buff.join(''), data, path);
          }

          buff = [];
        }

        break;

      case ' ':
        break;

      default:
        buff.push(c);
        break;
    }
  }

  return data;
}

function parseAttribute(word: string, data: Data, path?: string) {
  if (isNaN(Number(word))) {
    const listIndex = word.indexOf('[');

    if (listIndex === -1) {
      data.map[word] = { field: path ? `${path}.${word}` : word };
    } else {
      const index = parseInt(word.substring(listIndex + 1, word.length - 1));
      word = word.substring(0, listIndex);

      const item = data.map[word];

      if (item) {
        item.params![index] = null;
      } else {
        data.map[word] = {
          field: path ? `${path}.${word}` : word,
          params: { [index]: null },
        };
      }
    }
  }
}

function evaluateExpression(
  form: FormContextValue,
  data: Data,
  updateId: string,
  updateValue: any,
  updateParams?: string[]
): Data {
  if (data.map[updateId].value === updateValue) {
    return data;
  }

  let exp = data.exp.replace(/[$]/g, '');

  const newData = { ...data };
  newData.value = undefined;

  const updatedEntry = newData.map[updateId];
  updatedEntry.value = updateValue;

  if (updatedEntry.params && updateParams) {
    for (const index in updatedEntry.params) {
      updatedEntry.params[index] = updateParams[index];
    }
  }

  for (const key in newData.map) {
    const { params, field } = newData.map[key];
    const value = String(form.getFieldValue(field) || 0);

    if (isNil(value)) {
      return newData;
    } else if (params) {
      for (const index in params) {
        exp = exp.replace(new RegExp(`${key}\\[${index}\\]`, 'g'), params[index]);
      }
    } else {
      exp = exp.replace(new RegExp(key, 'g'), value);
    }
  }

  try {
    // eslint-disable-next-line no-eval
    newData.value = Number(eval(exp)?.toFixed(2));
  } catch (err) {}

  return newData;
}
