import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useTranslationX } from 'i18n';
import { useRequest } from 'apis';
import {
  FieldSet,
  Form,
  HiddenField,
  TextField,
  useForm,
  useFormObserver,
} from 'components/form';

import { FieldType, PatchResponse } from 'components/form/types';
import { WrapperCard } from 'components/elements';
import { TotalLabel } from 'pages/proposal';
import { SectionProps } from 'pages/project/update-wp/UpdateWpEditor';
import { useAuthState } from 'store/auth';
import { Profile } from 'pages/register/profile';
import useDebounce from 'components/hooks/useDebounce';
import { useFirstRender } from 'components/hooks';
import { EditorState } from '../UpdateWpEditor/types';

import styles from './Counterpart.module.scss';

type Response = {
  economico: Contrapartida;
};

type Contrapartida = {
  contrapartidaRh: number;
  contrapartidaSuporte: number;
  totalIf: number;
  valorFinanceiro: number;
  valorTotal: number;
};

type State = {
  cf: number;
  ct: number;
  vse: number;
};

const Counterpart = ({ state, formProps }: SectionProps) => (
  <div className={styles.wrapper}>
    <Form baseUrl={`/projeto/${state.id}/alteracao-pt/counterpart`} {...formProps}>
      <WrapperCard padding="10px 24px">
        <FormContent formState={state} />
      </WrapperCard>
    </Form>
  </div>
);

const FormContent = ({ formState }: { formState: EditorState }) => {
  const { id, type } = formState;
  const form = useForm();
  const request = useRequest();

  const { profile } = useAuthState();

  const [state, setState] = useState<State>({
    cf: form.getFieldValue('economico.valorFinanceiro') || 0,
    ct: form.getFieldValue('economico.valorTotal') || 0,
    vse: 0,
  });

  const { tx } = useTranslationX('counterpart', 'update_wp');

  const firstRender = useFirstRender();

  const supportResult = useFormObserver('economico.contrapartidaSuporte', true);
  const idResult = useFormObserver('economico.id', true);

  const contrapartidaRhResult = useFormObserver('economico.contrapartidaRh', true);

  const rhEconomico = contrapartidaRhResult?.data?.value;
  const lastRhValue = useRef(rhEconomico);

  const os = Number(supportResult?.data?.value);
  const lastOsValue = useRef(os);

  const rhDebouncedResult = useDebounce(rhEconomico, 300);
  const osDebouncedResult = useDebounce(os, 300);

  const economicId = idResult?.data?.value;

  const updateCounterpart = useCallback(
    (data: any) => {
      const source = request<PatchResponse<Response>[]>({
        method: 'PATCH',
        url: `/projeto/${id}/alteracao-pt/counterpart`,
        data,
        onSuccess: res => {
          const { status, response, needsRefresh } = res[0];

          if (status === 'success' && response) {
            const { economico } = response;

            const newState = {
              cf: economico?.valorFinanceiro || 0,
              ct: economico?.valorTotal || 0,
              vse: 0,
            };

            form.setFieldValue('economico.totalIf', economico?.totalIf || 0);

            setState(newState);

            if (needsRefresh) {
              form.setFormValues({ economico: response.economico });
            }
            form.notifySave();
          }
        },
      });

      return () => source.cancel();
    },
    [form, id, request]
  );

  const updateRhEconomico = useCallback(() => {
    updateCounterpart([
      {
        type: 'replace',
        path: 'economico.contrapartidaRh',
        value: contrapartidaRhResult?.data?.value,
        query: {
          id: economicId,
        },
      },
    ]);
  }, [contrapartidaRhResult, economicId, updateCounterpart]);

  const updateSupport = useCallback(() => {
    updateCounterpart([
      {
        type: 'replace',
        path: 'economico.contrapartidaSuporte',
        value: os,
        query: {
          id: economicId,
        },
      },
    ]);
  }, [os, economicId, updateCounterpart]);

  useEffect(() => {
    const validRhResult = rhDebouncedResult !== undefined && rhDebouncedResult !== null;
    const validOsResult = osDebouncedResult !== undefined && osDebouncedResult !== null;

    if (!firstRender && validRhResult && lastRhValue.current !== rhDebouncedResult) {
      lastRhValue.current = rhDebouncedResult;
      updateRhEconomico();
    }

    if (!firstRender && validOsResult && lastOsValue.current !== osDebouncedResult) {
      lastOsValue.current = osDebouncedResult;
      updateSupport();
    }
  }, [
    updateSupport,
    updateRhEconomico,
    rhDebouncedResult,
    osDebouncedResult,
    firstRender,
  ]);

  const isSupervisor = profile === Profile.SUPERVISOR;
  const isAditivo = type === 'aditivo';

  function getEditableFields() {
    if (!isSupervisor) {
      return {
        rh: (isAditivo ? undefined : 'readonly') as FieldType,
        os: 'readonly' as FieldType,
      };
    }
  }

  const editableFields = getEditableFields();

  return (
    <div className={styles.counterpart}>
      <div className={styles.total}>
        <TotalLabel value={state.cf} title={tx('financial-cost')} />
        <TotalLabel value={state.ct} title={tx('total-value')} />
      </div>

      <FieldSet name="economico">
        <HiddenField name="id" omit />
        <TextField
          name="contrapartidaRh"
          label={tx('rh')}
          omit
          clientSideDiff
          type={editableFields?.rh || undefined}
        />
        <TextField
          name="contrapartidaSuporte"
          label={tx('operational-support')}
          omit
          clientSideDiff
          type={editableFields?.os || undefined}
        />
        <TextField name="totalIf" label={tx('total-value-ifce')} type="readonly" omit />
      </FieldSet>
    </div>
  );
};

export default Counterpart;
